import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const Loading = () => {
  return (
    <div
      style={{
        zIndex: 100000,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <Backdrop open>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  )
}

export default Loading
