import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 380
  },
}));

export default function HoursField(props) {
  function onChange(e) {
    if (props.onChange) {
      props.onChange(e.currentTarget.value);
    }
  }

  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id={props.id}
        label={props.label}
        onChange={onChange}
        type="time"
        className={classes.textField}
        value={props.initialValues ? props.initialValues : null}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        variant='outlined'
        inputVariant='outlined'
        style={{
          width: '100%'

        }}
      />
    </form>
  );
}
