import { date, field, readonly, relation } from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'
import _ from 'lodash'

export const modelToDTOMapping = {
  identical: [],
  renamed: [
    {
      dtoName: 'hormoneDosageValue',
      modelName: 'hormoneDosage_value'
    }
  ],
  references: [
    {
      dtoName: 'day',
      modelName: 'day_id'
    },
    {
      dtoName: 'implantId',
      modelName: 'implant_id'
    },
    {
      dtoName: 'managementId',
      modelName: 'management_id'
    },
    {
      dtoName: 'hormoneTypeId',
      modelName: 'hormoneType_id'
    },
    {
      dtoName: 'hormoneDosageId',
      modelName: 'hormoneDosage_id'
    },
    {
      dtoName: 'managementProtocolId',
      modelName: 'protocol_id'
    }
  ]
}

export default class ManagementProtocols extends Model {
  static table = 'management_protocols'

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Foreign Relations - Domain Values
  @relation('domain_values', 'day_id') day
  @relation('domain_values', 'implant_id') implant
  @relation('domain_values', 'management_id') management
  @relation('domain_values', 'hormoneType_id') hormoneType
  @relation('domain_values', 'hormoneDosage_id') hormoneDosage

  //Extra fields
  @field('hormoneDosage_value') hormoneDosageValue

  //Foreign Relations
  @relation('protocols', 'protocol_id') protocol
}
