import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import { FAZENDA, formatName } from '../../utils/formHelper'
import React, { Component } from 'react'

import Container from '@material-ui/core/Container'
import FarmForm from './components/FarmForm'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { getLoggedUser } from '../../redux/auth/actions'
import track from 'react-tracking'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

@track(() => ({ page: 'Editar Fazenda', date: new Date() }))
class FarmUpdate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deleteConfirmationOpened: false
    }

    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
  }

  onCancel() {
    if (this.props.history.location.state?.from === 'manejo page') {
      this.props.history.goBack()
    } else {
      this.props.history.push('/')
    }
  }

  @track((props, state, farm) => ({ action: 'Editou uma Fazenda', value: farm[0].nome }))
  async onSubmit(payload) {
    // To create
    const usersToAdd = _.difference(
      payload.veterinarios,
      this.props.relatedUsers
    )
    // To delete
    const usersToDelete = _.difference(
      this.props.relatedUsers,
      payload.veterinarios
    )

    //TODO: Check how own user behaves considering Farm__rel__Users
    //(is it omitted, since it's the user who created the farm? or is it added anyway)

    const repository = new Repository(AVAILABLE_ENTITIES.FARMS)
    const request = await repository.update(payload)

    if (request.success) {
      console.log('Farm data updated successfully')

      const farmUserRepository = new Repository(AVAILABLE_ENTITIES.FARM_USERS)
      let success = true
      _.forEach(usersToAdd, async (user) => {
        const req = await farmUserRepository.post({
          idFazenda: this.props.farm.id,
          idUsuario: user.id,
        })
        if (!req.success) {
          console.log(
            `Error while creating relation of farm ${this.props.farm.id} with user ${user.id}`
          )
          success = false
        }
      })

      const existingEntriesReq = await farmUserRepository.getByParam(
        'farm_id',
        this.props.farm.id
      )
      if (
        existingEntriesReq.success &&
        existingEntriesReq.response.length > 0
      ) {
        const existingEntries = existingEntriesReq.response
        _.forEach(usersToDelete, async (user) => {
          //Skipping user with e-mail velocia@admin.com until we have a better understanding of what it is doing
          if (user.email === 'velocia@admin.com') {
            return
          }
          const entry = _.find(existingEntries, { idUsuario: user.id })
          const req = await farmUserRepository.delete(entry.id)
          if (!req.success) {
            console.log(
              `Error while deleting relation of farm ${this.props.farm.id} with user ${user.id}`
            )
            success = false
          }
        })
      }

      if (success) {
        console.log('Farm updated successfully')
      } else {
        console.log(
          `There was an error trying to update farm ${this.props.farm.id}. Please retry.`
        )
      }

      console.log('Farm updated successfully')
      if (this.props.history.location.state?.from === 'manejo page') {
        this.props.history.goBack()
      } else {
        this.props.history.push('/')
      }
    } else {
      console.log(`Update failed: ${request.exception}`)
    }
  }

  @track((props) => ({ action: 'Deletou uma Fazenda', value: props.farm.id }))
  async handleDelete() {
    const repository = new Repository(AVAILABLE_ENTITIES.FARMS)
    const request = await repository.delete(
      this.props.farm && this.props.farm.id
    )

    if (request.success) {
      console.log(`Delete of farm ${this.props.farm.id} successful`)
      this.props.history.push('/')
    } else {
      console.log(
        `Delete of farm ${this.props.farm.id} failed: ${request.exception}`
      )
    }

    this.setState({
      deleteConfirmationOpened: false,
    })
  }

  confirmDelete() {
    this.setState({
      deleteConfirmationOpened: true,
    })
  }

  @track({ action: 'Voltou para o manejo' })
  handleBackToManejo = () => this.props.history.goBack()

  getButtons() {
    if (this.props.history.location.state?.from === 'manejo page') {
      return [
        {
          onClick: this.handleBackToManejo,
          label: `Voltar ao manejo`,
        },
      ]
    } else {
      return [
        {
          onClick: this.confirmDelete,
          label: 'Deletar',
          disabled: this.props.user.roles[0].name === 'Cliente (somente visualização)'
        },
      ]
    }
  }

  render() {
    const initialValues = {
      nomeProprietario: this.props.farm.nomeProprietario,
      nome: this.props.farm.nome,
      inscricaoEstadual: this.props.farm.inscricaoEstadual,
      pais: this.props.farm.pais,
      estado: this.props.farm.estado,
      municipio: this.props.farm.municipio,
      sistemaProducao: this.props.farm.sistemaProducao,
      tamanho: this.props.farm.tamanho,
      retiro: _.find(
        [
          { label: 'Sim', value: true },
          { label: 'Não', value: false },
        ],
        { value: this.props.farm.retiro }
      ),
      inicioEstacaoMonta: this.props.farm.inicioEstacaoMonta,
      fimEstacaoMonta: this.props.farm.fimEstacaoMonta,
      estacaoMonta: this.props.farm.estacaoMonta,
      veterinarios: this.props.relatedUsers || [this.props.user],
    }

    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={formatName(this.props.farm.nome, FAZENDA)}
          buttons={this.getButtons()}
          paths={[
            {
              label: formatName(this.props.farm.nome, FAZENDA),
            },
          ]}
        />
        <MainContainer>
          <FarmForm
            initialValues={initialValues}
            onCancel={this.onCancel}
            onSubmit={this.onSubmit}
            farm={this.props.farm}
            user={this.props.user}
            disabled={this.props.history.location.state?.from === 'manejo page' ? true : false}
          />
          <Container disableGutters>
            <Prompt
              visible={verify()}
              title='Página duplicada!'
              message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
                Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
              buttons={[
                {
                  autoFocus: false,
                },
              ]}
            />

            <Prompt
              visible={this.state.deleteConfirmationOpened}
              title='Confirmar Exclusão'
              message='Você está prestes a deletar esta fazenda. Esta ação não poderá ser desfeita.'
              buttons={[
                {
                  label: 'Cancelar',
                  onClick: () =>
                    this.setState({ deleteConfirmationOpened: false }),
                },
                {
                  label: 'Confirmar',
                  onClick: this.handleDelete,
                },
              ]}
            />
          </Container>
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async (props) => ({
  farm: await utils.getElement(AVAILABLE_ENTITIES.FARMS, props.match.params.id),
  relatedUsers: await utils.getWithRelation(
    AVAILABLE_ENTITIES.USERS,
    'farms__rel__users',
    'farm_id',
    props.match.params.id
  ),
  user: await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    (
      await getLoggedUser()
    ).userId
  ),
})

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(FarmUpdate)
)
