import '../../components/styles/customstyle.scss'

import * as sync from '../../../src/syncWorker'

import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import { Container, Grid } from '@material-ui/core'
import React, { Component } from 'react'
import { USUARIO, formatName } from '../../utils/formHelper'

import Button from '../../components/material/Button'
import _ from 'lodash'
import clearStorage from '../../utils/clearStorage'
import { connect } from 'react-redux'
import { getLoggedUser } from '../../redux/auth/actions'
import { logout } from '../../redux/auth/actions'
import { withRouter } from 'react-router-dom'

class Backup extends Component {
  constructor(props) {
    super(props)


    this.state = {
    }

    this.exitApplication = this.exitApplication.bind(this)


  }

  downloadBackup() {
    sync.downloadBackup(formatName('props.user.nomeCompleto', USUARIO))
  }

  exitApplication() {
    clearStorage()
    this.props.logout()
    this.props.history.push("/login");
  }

  render() {

    return (
      <Container>
        <div style={{ marginTop: '20px' }}>
          <Button label="backup" onClick={() => this.downloadBackup()} />
          <Button style={{ marginLeft: '20px' }} label="sair" onClick={() => this.exitApplication()} />
        </div>

      </Container>
    )
  }
}


const mapDispatchToProps = dispatch => {
  return {
    logout: async () => await dispatch(logout()),
    loggedUser: async () => await getLoggedUser(),
    user: async () => await utils.getElement(
      AVAILABLE_ENTITIES.USERS,
      loggedUser.userId
    )
  }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Backup))
