export function isEmail(str: string) {
  const pattern = /^(?=[^\s]+)(?=(\w+)@([\w.]+[\w]$))/
  return pattern && pattern.test(str)
}

export function sortAlphaNum(field: any = undefined) {
  return function (a: string, b: string) {
    let reA = /[^a-zA-Z]/g
    let reN = /[^0-9]/g
    const aField = field ? a[field] : a
    const bField = field ? b[field] : b
    let aA = aField?.replace(reA, '')
    let bA = bField?.replace(reA, '')

    if (aA === bA) {
      let aN = parseInt(aField.replace(reN, ''))
      let bN = parseInt(bField.replace(reN, ''))
      return aN === bN ? 0 : aN > bN ? 1 : -1
    } else {
      return aA > bA ? 1 : -1
    }
  }
}

export function sortList(data: any[], field: any) {
  let resultItens = data.sort(sortAlphaNum(field))
  return resultItens
}
