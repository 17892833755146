import { NUMBER_TYPES } from './constants'
import { fromMoment, toMoment } from './utils'

import _ from 'lodash'

const identity = x => x

const isUndefined = (object, field) => {
  return typeof object[field] === 'undefined'
}

const composeSanity = func => {
  return args => {
    const val = func(args)
    if (isNaN(val)) {
      return null
    }
    return val
  }
}

const shouldUpdateField = ({ isSync, isNotUndefined, status }) => {
  let shouldUpdate = false
  if (isSync) {
    shouldUpdate =
      (status === 'updated' || status === 'synced') && isNotUndefined
  } else {
    shouldUpdate = isNotUndefined
  }
  return shouldUpdate
}

export const fromDTO = (modelToDTOMapping, dto, entry, isSync = false) => {
  //console.log(`Mapping: ${JSON.stringify(modelToDTOMapping, null, ' ')}`)
  //console.log(`DTO: ${JSON.stringify(dto, null, ' ')}`)
  const before = _.clone(entry)
  _.each(modelToDTOMapping.identical, function (field) {
    if (
      shouldUpdateField({
        isSync,
        isNotUndefined: !isUndefined(dto, field),
        status: entry._raw._status
      })
    ) {
      entry._raw[field] = dto[field]
    }
  })
  _.each(modelToDTOMapping.renamed, function (field) {
    if (
      shouldUpdateField({
        isSync,
        isNotUndefined: !isUndefined(dto, field.dtoName),
        status: entry._raw._status
      })
    ) {
      if (field.isDate) {
        entry._raw[field.modelName] = fromMoment(toMoment(dto[field.dtoName]))
      } else if (field.isNumber) {
        const numberType = field.numberType || NUMBER_TYPES.DEFAULT
        let sanityFunction = composeSanity(identity)
        switch (numberType) {
          case NUMBER_TYPES.INTEGER:
            sanityFunction = composeSanity(parseInt)
            break
          case NUMBER_TYPES.FLOAT:
            sanityFunction = composeSanity(parseFloat)
            break
          case NUMBER_TYPES.DEFAULT:
            sanityFunction = composeSanity(parseFloat)
            break
          default:
            sanityFunction = composeSanity(identity)
            break
        }
        entry._raw[field.modelName] = sanityFunction(dto[field.dtoName])
      } else {
        entry._raw[field.modelName] = dto[field.dtoName]
      }
    }
  })
  _.each(modelToDTOMapping.references, function (field) {
    //console.log(`Model ${field.modelName}`)
    if (
      shouldUpdateField({
        isSync,
        isNotUndefined: !isUndefined(dto, field.dtoName),
        status: entry._raw._status
      })
    ) {
      entry._raw[field.modelName] = dto[field.dtoName]
    }
  })

  if (dto.deleted || dto.isDeleted) {
    entry.isDeleted = true
  }

  // Remove to line deleted with status updated
  if (entry._raw._status === 'updated' && entry._raw._changed === 'isDeleted' && entry.isDeleted) {
    entry._raw._status = 'synced'
    entry._raw._changed = ''
  }

  // Handle conflicts - client wins
  if (isSync && entry._raw._status === 'updated') {
    _.each(before._raw._changed.split(','), function (val) {
      entry._raw[val] = before._raw[val]
    })
  }

  return entry
}

export const toDTO = (modelToDTOMapping, entry) => {
  //console.log(entry)
  let payload = {
    id: entry.id,
    updatedAt: toMoment(entry._raw.updated_at),
    createdAt: toMoment(entry._raw.created_at),
    deleted: entry.isDeleted
  }
  _.each(modelToDTOMapping.identical, function (field) {
    payload[field] = entry._raw[field]
  })
  _.each(modelToDTOMapping.renamed, function (field) {
    if (field.isDate) {
      payload[field.dtoName] = toMoment(entry._raw[field.modelName])
    } else if (field.isNumber) {
      const numberType = field.numberType || NUMBER_TYPES.DEFAULT

      let sanityFunction = composeSanity(identity)
      switch (numberType) {
        case NUMBER_TYPES.INTEGER:
          sanityFunction = composeSanity(parseInt)
          break
        case NUMBER_TYPES.FLOAT:
          sanityFunction = composeSanity(parseFloat)
          break
        case NUMBER_TYPES.DEFAULT:
          sanityFunction = composeSanity(parseFloat)
          break
        default:
          sanityFunction = composeSanity(identity)
          break
      }
      payload[field.dtoName] = sanityFunction(entry._raw[field.dtoName])
    } else {
      payload[field.dtoName] = entry._raw[field.modelName]
    }
  })
  _.each(modelToDTOMapping.references, function (field) {
    payload[field.dtoName] = entry._raw[field.modelName]
  })

  return payload
}
