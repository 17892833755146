import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import { FAZENDA, formatName } from './../../utils/formHelper'
import React, { Component } from 'react'

import BullForm from './components/BullForm'
import { Container } from '@material-ui/core'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'

class BullUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteConfirmationOpened: false
    }

    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
  }

  onCancel() {
    this.props.history.push(`/farms/${this.props.farm.id}/bulls`)
  }

  async onSubmit(payload) {
    // const repository = new Repository(AVAILABLE_ENTITIES.BULLS)
    const repository = new Repository(AVAILABLE_ENTITIES.BULLS_NEW)
    const updateRequest = await repository.update(payload)
    if (updateRequest.success) {
      console.log('Bull updated successfully')
      this.props.history.push(`/farms/${this.props.farm.id}/bulls`)
    } else {
      console.log(
        `There was an error creating bull: ${updateRequest.exception}`
      )
    }
  }

  async handleDelete() {
    // const repository = new Repository(AVAILABLE_ENTITIES.BULLS)
    const repository = new Repository(AVAILABLE_ENTITIES.BULLS_NEW)
    const request = await repository.delete(
      this.props.bull && this.props.bull.id
    )

    if (request.success) {
      console.log(`Delete of bull ${this.props.bull.id} successful`)
      this.props.history.push(`/farms/${this.props.farm.id}/bulls`)
    } else {
      console.log(
        `Delete of bull ${this.props.bull.id} failed: ${request.exception}`
      )
    }

    this.setState({
      deleteConfirmationOpened: false
    })
  }

  confirmDelete() {
    this.setState({
      deleteConfirmationOpened: true
    })
  }

  render() {
    const initialValues = {
      nome: this.props.bull.nome,
      raca: this.props.bull.raca,
      central: this.props.bull.central,
      centralValue: this.props.bull.centralValue,
      partida: this.props.bull.partida,
      observacao: this.props.bull.observacao
    }
    const bullTitle = `${this.props.bull.nome} ${this.props.bull.partida
      ? this.props.bull.partida.format('DD/MM/YYYY')
      : ''
      } ${_.isEmpty(this.props.bull.observacao)
        ? ''
        : '(' + this.props.bull.observacao + ')'
      }`
    return (
      <Container>
        <TopBar title={bullTitle} />
        <TitleBar
          title={`${this.props.bull.nome}`}
          buttons={[
            {
              onClick: this.confirmDelete,
              label: 'Deletar'
            }
          ]}
          paths={[
            {
              route: `/farms/${this.props.farm.id}`,
              label: formatName(this.props.farm.nome, FAZENDA)
            },
            {
              route: `/farms/${this.props.farm.id}/bulls`,
              label: 'Sêmen'
            },
            {
              label: bullTitle
            }
          ]}
        />

        <MainContainer containerTitle={this.props.bull.nome}>
          <Container disableGutters>
            <BullForm
              initialValues={initialValues}
              onCancel={this.onCancel}
              onSubmit={this.onSubmit}
              farm={this.props.farm}
              bull={this.props.bull}
            />
            <Container disableGutters>
              <Prompt
                visible={this.state.deleteConfirmationOpened}
                title='Confirmar Exclusão'
                message='Você está prestes a deletar este sêmen. Esta ação não poderá ser desfeita.'
                buttons={[
                  {
                    label: 'Cancelar',
                    onClick: () =>
                      this.setState({ deleteConfirmationOpened: false })
                  },
                  {
                    label: 'Confirmar',
                    onClick: this.handleDelete
                  }
                ]}
              />
            </Container>
          </Container>
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async props => ({
  farm: await utils.getElement(AVAILABLE_ENTITIES.FARMS, props.match.params.id),
  bull: await utils.getElement(
    // AVAILABLE_ENTITIES.BULLS,
    AVAILABLE_ENTITIES.BULLS_NEW,
    props.match.params.bullId
  )
})

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(BullUpdate)
)
