import * as CONSTANTS from './constants'

const initialState = {
  selected: null,
  list: [],
}

export default function reduce (state = initialState, action) {

    switch (action.type) {
        case CONSTANTS.LIST_PROTOCOLS:
            return {
                ...state,
                list: action.data
            };
        case CONSTANTS.SELECT_PROTOCOL:
            return {
                ...state,
                selected: action.data
            };
        default:
            return state;
    }

}