import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import { FAZENDA, RETIRO, formatName } from '../../utils/formHelper'
import React, { Component } from 'react'

import Alert from '@material-ui/lab/Alert'
import BatchForm from './components/BatchForm'
import Container from '@material-ui/core/Container'
import MainContainer from '../../components/MainContainer'
import Prompt from './../../components/Prompt'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { getLoggedUser } from '../../redux/auth/actions'
import { verify } from './../../utils/verifyDuplicatePages';
import { withRouter } from 'react-router-dom'
import track from 'react-tracking'

@track(() => ({ page: 'Novo Lote', date: new Date() }))
class BatchCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: null
    }

    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onCancel() {
    this.props.farm.retiro
      ? this.props.history.push(
        `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
      )
      : this.props.history.push(`/farms/${this.props.farm.id}`)
  }

  @track((props, state, corral) => ({ action: 'Criou um novo lote', value: corral[0].nomeLote }))
  async onSubmit(payload) {
    const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)
    const result = await this.alredyExist(payload, repository)

    if (result.alredyExist) {
      window.scrollTo(0, 0)

      this.setState({
        errorMessage: result.message
      })

      return
    }
    const createRequest = await repository.post(payload)
    if (createRequest.success) {
      console.log('Batch Created!')
      if (this.props.farm.retiro) {
        this.props.history.push(
          `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
        )
      } else {
        this.props.history.push(`/farms/${this.props.farm.id}`)
      }
    } else {
      console.warn(
        `Ooops... there was an error creating a batch: ${createRequest.exception}`
      )
    }
  }

  async alredyExist(payload, repository) {
    let batches = await repository.getByParam('corral_id', this.props.corral.id)
    let verifyBatches = false

    _.each(batches.response, batch => {
      if (batch.nomeLote === payload.nomeLote) {
        verifyBatches = true
      }
    })

    if (verifyBatches) {
      return { alredyExist: true, message: 'Já existe um lote com esse nome!' }
    }

    return { alredyExist: false, message: null }
  }

  render() {
    const initialValues = {}
    const { errorMessage } = this.state

    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title='Novo Lote'
          paths={[
            {
              route: `/farms/${this.props.farm.id}`,
              label: formatName(this.props.farm.nome, FAZENDA)
            },
            ...(this.props.paths || [])
          ]}
        />

        <MainContainer>
          {errorMessage && (
            <Alert
              severity='error'
              style={{
                width: '100%',
                marginTop: '1em'
              }}
            >
              {errorMessage}
            </Alert>
          )}

          <BatchForm
            initialValues={initialValues}
            onCancel={this.onCancel}
            onSubmit={this.onSubmit}
            farm={this.props.farm}
            corral={this.props.corral}
            user={this.props.user}
            protocols={this.props.protocolos}
            relatedUsers={this.props.relatedUsers}
          />

          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false
              },
            ]}
          />

        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async props => {
  const user = await getLoggedUser()
  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    props.match.params.farmId
  )
  let corral = await utils.getElement(
    AVAILABLE_ENTITIES.CORRALS,
    props.match.params.corralId
  )

  if (!corral && !farm.retiro) {
    const repository = new Repository(AVAILABLE_ENTITIES.CORRALS)

    const { success, response } = await repository.post({
      nome: 'DEFAULT',
      fazendaId: farm.id
    })

    if (success) {
      corral = response
    } else {
      console.error('Error creating default corral')
    }
  }

  let paths = [
    {
      label: `Novo Lote`
    }
  ]

  if (farm.retiro) {
    paths.unshift({
      route: `/farms/${farm.id}/corrals/${corral.id}`,
      label: formatName(corral.nome, RETIRO)
    })
  }

  return {
    protocolos: _.sortBy(
      (await utils.getAll(AVAILABLE_ENTITIES.PROTOCOLS)).filter(
        x => x.veterinary_id === user.userId || !x.veterinary_id
      ),
      [protocol => protocol.name.toLowerCase()]
    ),
    farm,
    relatedUsers: _.sortBy(
      await utils.getWithRelation(
        AVAILABLE_ENTITIES.USERS,
        'farms__rel__users',
        'farm_id',
        props.match.params.farmId
      ),
      [user => user.nomeCompleto.toLowerCase()]
    ),
    user,
    corral,
    paths
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(BatchCreate)
)
