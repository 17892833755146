import { AVAILABLE_ENTITIES, utils } from '../../database'
import React, { Component } from 'react'

import BullFormForAll from './components/BullFormForAll'
import { Container } from '@material-ui/core'
import Prompt from '../../components/Prompt'
import { getLoggedUser } from '../../redux/auth/actions'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

class BullCreateForAll extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const initialValues = {
      nome: null,
      Cod_Touro: null,
      raca: null,
      central: null,
      centralValue: null,
      partida: null,
      observacao: null
    }

    return (
      <Container disableGutters>
        <Prompt
          visible={verify()}
          title='Página duplicada!'
          message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
          buttons={[
            {
              autoFocus: false
            },
          ]}
        />
        <BullFormForAll
          initialValues={initialValues}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          batchId={this.props.batchId}
          management={this.props.management}
          user={this.props.user}
        />
      </Container>

    )
  }
}


const propsFromDatabase = async props => {
  const batchId = props.match.params.batchId.split(';')
  const management = props.match.params.management.split(';')
  const loggedUser = await getLoggedUser()
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    loggedUser.userId
  )

  return {
    batchId,
    management,
    user
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(BullCreateForAll)
)
