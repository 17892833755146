import _ from 'lodash'
import { modelToDTOMapping as batchDTOMapping } from '../models/Batches'
import { modelToDTOMapping as bullDTOMapping } from '../models/Bulls'
import { modelToDTOMapping as bullNewDTOMapping } from '../models/BullsNew'
import { modelToDTOMapping as partidaDTOMapping } from '../models/Partida'
import { modelToDTOMapping as corralDTOMapping } from '../models/Corrals'
import { modelToDTOMapping as d0DTOMapping } from '../models/D0s'
import { modelToDTOMapping as dnDTOMapping } from '../models/DNs'
import { modelToDTOMapping as domainTypeDTOMapping } from '../models/DomainTypes'
import { modelToDTOMapping as domainValueDTOMapping } from '../models/DomainValues'
import { modelToDTOMapping as farmDTOMapping } from '../models/Farms'
import { modelToDTOMapping as farmUserDTOMapping } from '../models/FarmUsers'
import { modelToDTOMapping as iatfDTOMapping } from '../models/IATFs'
import { modelToDTOMapping as managementProtocolDTOMapping } from '../models/ManagementProtocols'
import migrations from '../infra/migrations'
import { modelToDTOMapping as protocolDTOMapping } from '../models/Protocols'
import { modelToDTOMapping as rolesDTOMapping } from '../models/Roles'
import { schemaMigrations } from '@nozbe/watermelondb/Schema/migrations'
import { modelToDTOMapping as termsDTOMapping } from '../models/Terms'
import { modelToDTOMapping as userDTOMapping } from '../models/Users'

export const databaseMigrations = schemaMigrations({ migrations })

export const NUMBER_TYPES = {
  FLOAT: 'FLOAT',
  INTEGER: 'INTEGER',
  DEFAULT: 'DEFAULT',
}

export const AVAILABLE_DTO_MAPPINGS = {
  BATCHES: batchDTOMapping,
  BULLS: bullDTOMapping,
  BULLS_NEW: bullNewDTOMapping,
  PARTIDA: partidaDTOMapping,
  D0S: d0DTOMapping,
  DNS: dnDTOMapping,
  DOMAIN_TYPES: domainTypeDTOMapping,
  DOMAIN_VALUES: domainValueDTOMapping,
  FARM_USERS: farmUserDTOMapping,
  FARMS: farmDTOMapping,
  IATFS: iatfDTOMapping,
  MANAGEMENT_PROTOCOLS: managementProtocolDTOMapping,
  PROTOCOLS: protocolDTOMapping,
  USERS: userDTOMapping,
  ROLES: rolesDTOMapping,
  CORRALS: corralDTOMapping,
  TERMS: termsDTOMapping,
}

export const AVAILABLE_ENTITIES: any = _.reduce(
  Object.keys(AVAILABLE_DTO_MAPPINGS),
  function (prev, curr) {
    return {
      ...prev,
      [curr]: curr,
    }
  },
  {}
)
