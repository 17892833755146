/* eslint-disable array-callback-return */

import * as sync from '../../syncWorker'

import { AVAILABLE_ENTITIES, Repository, format, utils } from '../../database'
import Autocomplete, {
  autocompleteDefaultOnChangeSavingID,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteOptionLabelUsingCustomFieldSavingID,
} from '../../components/material/Autocomplete'
import Button, { COLORS } from '../../components/material/Button'
import {
  CONDITIONAL_REQUIRED_FIELD_TYPE,
  FAZENDA,
  LOTE,
  RETIRO,
  formatName,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from '../../utils/formHelper'
import React, { Component } from 'react'

import Container from '@material-ui/core/Container'
import MainContainer from '../../components/MainContainer'
import Prompt from './../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import fnsformat from 'date-fns/format'
import { getLoggedUser } from '../../redux/auth/actions'
import moment from 'moment'
import ptBr from 'date-fns/locale/pt-BR'
import track from 'react-tracking'
import { verify } from './../../utils/verifyDuplicatePages';
import { withRouter } from 'react-router-dom'

@track(() => ({ page: 'Resync', date: new Date() }))
class Resync extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nextD0day: null,
      nextIatfDay: null,
      modalConfirmation: false,
    }

    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.sortRessincType = this.sortRessincType.bind(this)

    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
    this.autocompleteOptionLabelUsingCustomFieldSavingID =
      autocompleteOptionLabelUsingCustomFieldSavingID.bind(this)

    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
  }

  @track({ action: 'Clicou no botao de cancelar' })
  onCancel() {
    if (this.props.farm.retiro) {
      this.props.history.push(
        `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
      )
    } else {
      this.props.history.push(`/farms/${this.props.farm.id}`)
    }
  }

  sortRessincType() {
    let superPrecoce = this.props.tiposResync.filter(
      (tr) => tr.valor === 'Super Precoce'
    )[0]
    let precoce = this.props.tiposResync.filter(
      (tr) => tr.valor === 'Precoce'
    )[0]
    let tradicional = this.props.tiposResync.filter(
      (tr) => tr.valor === 'Tradicional'
    )[0]

    const sortedTypes = [superPrecoce, precoce, tradicional]
    return sortedTypes
  }

  @track({ action: 'Fez uma ressinc' })
  async onSubmit() {
    let requiredFiedsState = this.requiredFieldsEvaluator()

    if (requiredFiedsState !== null) {
      this.setState(requiredFiedsState)
    } else {
      this.setState({
        diaD0: this.state.diaD0?.id,
        protocoloId: this.state.protocoloId.id,
      })
      const currentIatf = this.props.cows[0].iatf
      let newProtocol =
        this.state.protocoloId.id &&
        (await utils.getElement(
          AVAILABLE_ENTITIES.PROTOCOLS,
          this.state.protocoloId.id
        ))

      let dispositivoEntry =
        newProtocol &&
        _.find(
          _.filter(
            newProtocol.managementProtocols,
            (managementProtocol) => managementProtocol.day === this.props.d0.id
          ),
          (managementProtocol) => managementProtocol.implantId !== null
        )
      let dispositivo = dispositivoEntry && dispositivoEntry.id

      let batchPayload = {
        //TODO: Check values saved
        ..._.pick(this.props.batch, [
          'dataPartosInicio',
          'dataPartosFim',
          'dataIatf',
          'mineral',
          'racaMatriz',
          'ordemMatriz',
          'idadeMatriz',
          'racaBezerro',
          'sexoBezerro',
          'inducaoPuberdade',
          'metodoInducaoPuberdade',
          'momentoSuplementacaoNovilhas',
          'momentoSuplementacaoVacas',
          'vacinaReprodutivaLote',
          'retiroId',
          'horaManejo'
        ]),
        usuarioId: this.state.usuarioId,
        progesteronaPosIATF: this.state.progesteronaPosIATF,
        periodoManejo: this.state.periodoManejo,
        isResync: true,
        isFinalize: false,
        protocoloId: this.state.protocoloId,
        dispositivo,
        parentBatchId: this.props.batch.id,
        originalBatchId:
          this.props.batch.originalBatchId || this.props.batch.id,
        tipoResync: this.state.tipoResync,
        diaInicioResync: this.state.diaD0,
        nomeLote: `${this.props.originalBatch.nomeLote} - IATF ${currentIatf + 2
          }`,
      }

      if (!_.isEmpty(this.props.batch.dataIatf)) {
        let protocol = await utils.getElement(
          AVAILABLE_ENTITIES.PROTOCOLS,
          this.props.batch.protocoloId
        )
        let managementIds = protocol.managementProtocols.map(
          (manejo) => manejo.day
        )
        let daysManagement = await utils.getWithParam(
          AVAILABLE_ENTITIES.DOMAIN_VALUES,
          'id',
          Q.oneOf(managementIds)
        )

        let d0Days = this.state.diaD0
          ? parseInt(
            _.find(this.props.days, { id: this.state.diaD0 }).valor.replace(
              /\D/g,
              ''
            )
          )
          : 30

        let daybatch = this.props.batch.dias
          ? parseInt(
            daysManagement
              .find((m) => m.id === this.props.batch.dias)
              .valor.replace(/\D/g, '')
          )
          : 0
        batchPayload.dataIatf = moment.utc(
          this.props.batch.dataIatf.valueOf() +
          (daybatch + d0Days) * 24 * 3600 * 1000
        )
      }
      if (!_.isEmpty(this.props.batch.dataPartosInicio)) {
        batchPayload.dataPartosInicio = this.props.batch.dataPartosInicio
      }
      if (!_.isEmpty(this.props.batch.dataPartosFim)) {
        batchPayload.dataPartosFim = this.props.batch.dataPartosFim
      }
      const batchRepository = new Repository(AVAILABLE_ENTITIES.BATCHES)
      const cowsRepository = new Repository(AVAILABLE_ENTITIES.D0S)
      const createReq = await batchRepository.post(batchPayload)
      if (createReq.success) {
        const resyncBatch = createReq.response
        let success = true
        let vacas = this.props.cows.filter((x) =>
          !((this.props.deadCowsIds.indexOf(x.id) >= 0) ||
            (x.previousObs?.map(obs => obs.obs).indexOf('Animal morreu') >= 0) ||
            (x.previousObs?.map(obs => obs.obs).indexOf('Matriz Descarte') >= 0))
        )

        await Promise.all(
          vacas
            .filter((cow) => !cow.omit_from_dg_final)
            .map(async (cow) => {
              let cowPayload = {
                ..._.pick(cow, [
                  'codVaca',
                  'codLeitorBastao',
                  'racaMatriz',
                  'ordemMatriz',
                  'racaBezerro',
                  'sexoBezerro',
                  'idade_Nov_Pri',
                  'responsavelId',
                ]),
                loteId: resyncBatch.id,
                iatf: cow.iatf + 1,
              }
              cowPayload.dzeroOriginal = cow.dzeroOriginal || cow.id
              cowPayload.dzeroPai = cow.id

              const createCowReq = await cowsRepository.post(cowPayload)
              if (!createCowReq.success) {
                console.log(
                  `There was a problem transposing cow to resync batch: ${createCowReq.exception}`
                )
                success = false
              }
            })
        )
        if (success) {
          console.log(`Resync scheduled successfully`)
          if (this.props.farm.retiro) {
            this.props.history.push(
              `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
            )
          } else {
            this.props.history.push(`/farms/${this.props.farm.id}`)
          }
        }
      } else {
        console.log(
          `There was a problem creating resync batch: ${createReq.exception}`
        )
      }
    }
    if (requiredFiedsState === null) {
      sync.downloadProofOfManagement({
        values: ['lotes', 'd0s'],
        text: 'Comprovante de manejo Ressinc - ' +
          formatName(this.props.batch.nomeLote, LOTE) +
          ' - ' +
          formatName(this.props.farm.nome, FAZENDA) +
          ' - ' +
          moment.utc().valueOf() +
          '.progerar',
        batchIds: this.props.match.params.id.split(';')
      })
    }
  }

  async calculateNextD0(value) {
    await this.setState({ diaD0: value })
    let thisNextD0day = null
    if (!_.isEmpty(this.props.batch.dataIatf) && this.state.diaD0) {
      let protocol =
        this.props.batch.protocoloId &&
        (await utils.getElement(
          AVAILABLE_ENTITIES.PROTOCOLS,
          this.props.batch.protocoloId
        ))

      let managementIds = protocol.managementProtocols.map(
        (manejo) => manejo.day
      )
      let daysManagement = await utils.getWithParam(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        'id',
        Q.oneOf(managementIds)
      )

      let daybatch = daysManagement
        ? parseInt(
          daysManagement
            .sort(
              (a, b) =>
                parseInt(b.valor.replace(/\D/g, '')) -
                parseInt(a.valor.replace(/\D/g, ''))
            )[0]
            .valor.replace(/\D/g, '')
        )
        : 0
      let d0Days = parseInt(this.state.diaD0.valor.replace(/\D/g, ''))
      thisNextD0day = moment.utc(
        this.props.batch.dataIatf.valueOf() +
        (daybatch + d0Days) * 24 * 3600 * 1000
      )
    }
    this.setState({
      nextD0day: thisNextD0day,
    })
    if (this.state.protocoloId) {
      this.calculateNextIatf(null)
    }
  }

  async calculateNextIatf(value) {
    if (value) {
      await this.setState({ protocoloId: value })
    }
    if (this.state.diaD0) {
      let thisNextIatfDay = null
      if (!_.isEmpty(this.props.batch.dataIatf)) {
        let protocol =
          this.state.protocoloId.id &&
          (await utils.getElement(
            AVAILABLE_ENTITIES.PROTOCOLS,
            this.state.protocoloId.id
          ))

        let managementIds = protocol.managementProtocols.map(
          (manejo) => manejo.day
        )
        let daysManagement = await utils.getWithParam(
          AVAILABLE_ENTITIES.DOMAIN_VALUES,
          'id',
          Q.oneOf(managementIds)
        )
        // let d0Days = parseInt(this.state.diaD0.valor.replace(/\D/g, ''))

        let daybatch = daysManagement
          ? parseInt(
            daysManagement
              .sort(
                (a, b) =>
                  parseInt(b.valor.replace(/\D/g, '')) -
                  parseInt(a.valor.replace(/\D/g, ''))
              )[0]
              .valor.replace(/\D/g, '')
          )
          : 0
        thisNextIatfDay = moment.utc(
          this.state.nextD0day.valueOf() + daybatch * 24 * 3600 * 1000
        )
      }
      this.setState({
        nextIatfDay: thisNextIatfDay,
      })
    }
  }

  onEnterPress = (e) => {
    if (e.key === 'Enter' && e.shiftKey === false && this.props.user.roles[0] !== 'Cliente (somente visualização)') {
      e.preventDefault()
      this.onSubmit()
    }
  }

  getPaths() {
    const extraPath = this.props.farm.retiro
      ? {
        route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`,
        label: formatName(this.props.corral.nome, RETIRO),
      }
      : null

    return _.compact([
      {
        route: `/farms/${this.props.farm.id}`,
        label: formatName(this.props.farm.nome, FAZENDA),
      },
      extraPath,
      {
        route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}/batches/${this.props.batch.id}`,
        label: formatName(this.props.batch.nomeLote, LOTE),
      },
      {
        label: 'Ressincronização',
      },
    ])
  }

  getNextD0DateInfo() {
    const d0Date = this.state.nextD0day.format('DD/MM/YYYY')
    const d0DateFormatted = fnsformat(this.state.nextD0day._i, 'EEEEEEE', {
      locale: ptBr,
    })
    return `Data do próximo D0: ${d0Date} (${d0DateFormatted})`
  }

  getNextIATFDate() {
    const dIATF = this.state.nextIatfDay.format('DD/MM/YYYY')
    const dIATFFormatted = fnsformat(this.state.nextIatfDay._i, 'EEEEEEE', {
      locale: ptBr,
    })
    return `Data da próxima IATF: ${dIATF} (${dIATFFormatted})`
  }

  render() {
    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={`Ressincronização - ${formatName(
            this.props.batch.nomeLote,
            LOTE
          )}`}
          paths={this.getPaths()}
        />

        <MainContainer>
          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
              Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false
              },
            ]}
          />
          <div className='grid grid-resync'>
            <div className='grid-item p-12 t-6 d-4'>
              <Autocomplete
                id='usuarioId'
                options={this.props.relatedUsers}
                value={this.state.usuarioId || null}
                onChange={this.autocompleteDefaultOnChangeSavingID('usuarioId')}
                onKeyDown={this.state.usuarioId ? this.onEnterPress : null}
                getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                  this.props.relatedUsers,
                  'nomeCompleto'
                )}
                useDefaultOptionSelected
                label='Responsável'
                autoFocus
                error={!_.isEmpty(this.state.usuarioId_error)}
                helperText={this.state.usuarioId_error}
                disabled={this.props.viewOnly}
              />
            </div>

            <div className='grid-item p-12 t-6 d-4'>
              <Autocomplete
                id='progesteronaPosIATF'
                options={this.props.mgaPosIatf}
                value={this.state.progesteronaPosIATF || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'progesteronaPosIATF'
                )}
                onKeyDown={
                  this.state.progesteronaPosIATF ? this.onEnterPress : null
                }
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.mgaPosIatf
                )}
                useDefaultOptionSelected
                label='Progesterona Pós IATF'
                error={!_.isEmpty(this.state.progesteronaPosIATF_error)}
                helperText={this.state.progesteronaPosIATF_error}
                disabled={this.props.viewOnly}
              />
            </div>

            <div className='grid-item p-12 t-6 d-4'>
              <Autocomplete
                id='periodoManejo'
                options={this.props.periodoManejo}
                value={this.state.periodoManejo || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'periodoManejo'
                )}
                onKeyDown={this.state.periodoManejo ? this.onEnterPress : null}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.periodoManejo
                )}
                useDefaultOptionSelected
                label='Período do Manejo'
                error={!_.isEmpty(this.state.periodoManejo_error)}
                helperText={this.state.periodoManejo_error}
                disabled={this.props.viewOnly}
              />
            </div>

            <div className='grid-item p-12 t-6 d-4'>
              <Autocomplete
                id='tipoResync'
                options={this.sortRessincType()}
                value={this.state.tipoResync || null}
                onChange={(e, value) => {
                  this.autocompleteDefaultOnChangeSavingID('tipoResync')(
                    e,
                    value
                  )
                  this.setState({
                    diaD0: null,
                    nextD0day: null,
                    nextIatfDay: null,
                    diaD0_error: null,
                  })
                }}
                onKeyDown={this.state.tipoResync ? this.onEnterPress : null}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.tiposResync
                )}
                useDefaultOptionSelected
                label='Ressincronização'
                error={!_.isEmpty(this.state.tipoResync_error)}
                helperText={this.state.tipoResync_error}
              />
            </div>

            <div className='grid-item p-12 t-6 d-4'>
              <Autocomplete
                id='diaD0'
                options={
                  this.state.tipoResync === this.props.traditionalResync.id
                    ? this.props.days.slice(
                      this.props.days.findIndex((x) => x.valor === 'D26'),
                      this.props.days.length
                    )
                    : this.props.days.slice(
                      this.props.days.findIndex((x) => x.valor === 'D11'),
                      this.props.days.findIndex((x) => x.valor === 'D30')
                    )
                }
                value={this.state.diaD0 || null}
                onChange={(e, value) => {
                  this.calculateNextD0(value)
                }}
                onKeyDown={this.state.diaD0 ? this.onEnterPress : null}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.days
                )}
                useDefaultOptionSelected
                label='Dia D0'
                error={!_.isEmpty(this.state.diaD0_error)}
                helperText={this.state.diaD0_error}
              />

              {this.state.nextD0day && this.getNextD0DateInfo()}
            </div>

            <div className='grid-item p-12 t-6 d-4'>
              <Autocomplete
                id='protocoloId'
                options={this.props.protocols}
                value={this.state.protocoloId || null}
                // onChange={this.autocompleteDefaultOnChangeSavingID(
                //   'protocoloId'
                // )}
                onChange={(e, value) => {
                  this.calculateNextIatf(value)
                }}
                onKeyDown={this.state.protocoloId ? this.onEnterPress : null}
                getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                  this.props.protocols,
                  'name'
                )}
                useDefaultOptionSelected
                label='Protocolo'
                error={!_.isEmpty(this.state.protocoloId_error)}
                helperText={this.state.protocoloId_error}
                disabled={this.props.viewOnly}
              />

              {this.state.nextIatfDay && this.getNextIATFDate()}
            </div>

            <div
              className='grid-item p-12 p-display-flex p-justify-space-between'
              style={{ marginTop: '1rem' }}
            >
              <Button
                color={COLORS.GREY}
                label='Cancelar'
                onClick={this.onCancel}
              />
              <Button label='Salvar' onClick={() => {
                let requiredFiedsState = this.requiredFieldsEvaluator()

                if (requiredFiedsState !== null) {
                  this.setState(requiredFiedsState)
                } else {
                  this.setState({ modalConfirmation: true })
                }
              }}
                disabled={this.props.user.roles[0] === 'Cliente (somente visualização)'} />
              {/* <Button label='Salvar' onClick={this.onSubmit} /> */}
            </div>
          </div>
        </MainContainer>

        <Prompt
          visible={this.state.modalConfirmation}
          title='Atenção!'
          message={
            this.props.blockedRessinc
              ? 'Já existe uma ressinc cadastrada para este lote, retorne à tela de listagem de lotes e verifique os próximos manejos.'
              : 'Antes de prosseguir, confirme as informações inseridas, os dados informados não poderão ser alterados após a finalização.'
          }
          buttons={
            this.props.blockedRessinc
              ? [
                {
                  label: 'OK',
                  onClick: () => this.setState({ modalConfirmation: false }),
                },
              ]
              : [
                {
                  label: 'Cancelar',
                  onClick: () => this.setState({ modalConfirmation: false }),
                },
                {
                  label: 'Salvar',
                  onClick: () => {
                    this.onSubmit()
                    this.setState({ modalConfirmation: false })
                  },
                  disabled: !this.state.modalConfirmation && true,
                },
              ]
          }
        />
      </Container>
    )
  }
}

const propsFromDatabase = async (props) => {
  const batchIds = props.match.params.id.split(';')
  const batch = await utils.getElement(
    AVAILABLE_ENTITIES.BATCHES,
    props.match.params.id
  )

  const cows = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    batch.id
  )

  const corral = await utils.getElement(
    AVAILABLE_ENTITIES.CORRALS,
    batch.retiroId
  )
  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    corral.fazendaId
  )

  const batches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'corral_id',
    corral.id
  )

  let filteredBatches = []

  if (batch.isResync) {
    filteredBatches = _.filter(batches, (batchTest) =>
      !_.isEmpty(batchTest.originalBatchId) && batchTest.parentBatchId === batchIds[0]
    )
  } else {
    filteredBatches = _.filter(batches, (batchTest) =>
      !_.isEmpty(batchTest.originalBatchId) && batchTest.originalBatchId === batchIds[0]
    )
  }

  const originalBatch = _.isEmpty(batch.originalBatchId)
    ? batch
    : await utils.getElement(AVAILABLE_ENTITIES.BATCHES, batch.originalBatchId)
  const user = await getLoggedUser()
  const tiposResync = await utils.getDomainValuesBy('Tipo de Ressincronização')
  const relatedUsers = _.sortBy(
    await utils.getWithRelation(
      AVAILABLE_ENTITIES.USERS,
      'farms__rel__users',
      'farm_id',
      farm.id
    ),
    [(user) => user.nomeCompleto.toLowerCase()]
  )

  let deadCowsIds = []
  let vacasIatf = _.filter(
    await utils.getWithParam(
      AVAILABLE_ENTITIES.IATFS,
      'batch_id',
      Q.oneOf(batchIds)
    )
  )

  vacasIatf.map((vaca) => {
    (vaca.observacao === 'Animal morreu' || vaca.observacao === 'Matriz Descarte') && deadCowsIds.push(vaca.vacaId)
  })

  const protocol = await utils.getElement(
    AVAILABLE_ENTITIES.PROTOCOLS,
    batch.protocoloId
  )

  const days = await utils.getDomainValuesBy('Dias')
  const protocolDays = protocol
    ? _.sortBy(
      _.uniq(
        protocol.managementProtocols.map(managementProtocol =>
          _.find(days, { id: managementProtocol.day })
        )
      ),
      [
        dia =>
          dia.valor.startsWith('D') ? parseInt(dia.valor.substring(1)) : 100
      ]
    )
    : []

  const currentDay =
    protocolDays[_.findIndex(protocolDays, { id: batch.dias })]

  for (const vaca of cows) {

    vaca.lote = batch.nomeLote

    let previousObs = []

    if (!_.isEmpty(vaca.observacaoD0) && vaca.observacaoD0 !== '[N/A]') {
      previousObs.push({
        manejo: 'D0',
        obs: format.sanitizeObs(vaca.observacaoD0)
      })
    }
    const previousDns = _.filter(
      await utils.getWithParam(AVAILABLE_ENTITIES.DNS, 'vacaId', vaca.id),
      dn => dn.dia === currentDay.id
    )

    if (previousDns.length > 0) {
      vaca.observacaoDN = format.sanitizeObs(previousDns[previousDns.length - 1].observacao)
    }


    const dns = _.filter(
      await utils.getWithParam(AVAILABLE_ENTITIES.DNS, 'vacaId', vaca.id),
      dn => dn.dia !== currentDay.id
    )
    if (dns.length > 0) {
      const sortedObs = _.sortBy(
        _.compact(
          dns.map(
            dn =>
              !_.isEmpty(dn.observacao) &&
              dn.observacao !== '[N/A]' && {
                manejo: _.find(days, { id: dn.dia }).valor,
                obs: format.sanitizeObs(dn.observacao)
              }
          )
        ),
        [
          obs =>
            obs.manejo.startsWith('D') ? parseInt(obs.manejo.substring(1)) : 100
        ]
      )
      previousObs = previousObs.concat(sortedObs)
    }

    if (previousObs.length > 0) {
      vaca.previousObs = previousObs
    }
  }

  return {
    user,
    requiredFields: ['tipoResync',
      'protocoloId',
      'diaD0',
      'progesteronaPosIATF',
      'usuarioId',
      'periodoManejo'],
    conditionalRequiredFields: [
      {
        field: 'diaD0',
        dependsOn: 'tipoResync',
        //TODO: Find a better way of dealing with this required field, instead of hardcoded ids
        value: '510dd7a8-bdc2-4780-8f5b-cb264bce7e10',
        excludeValue: true,
        type: CONDITIONAL_REQUIRED_FIELD_TYPE.DEPENDENT_ON_FIELD,
      },
    ],
    batch,
    blockedRessinc: filteredBatches.length > 0 ? true : false,
    relatedUsers,
    originalBatch,
    deadCowsIds,
    vacasIatf,
    corral,
    farm,
    periodoManejo: await utils.getDomainValuesBy('Periodo do Manejo'),
    mgaPosIatf: await utils.getDomainValuesBy('Progesterona Pos IATF'),
    d0: _.find(await utils.getDomainValuesBy('Dias'), { valor: 'D0' }),
    cows,
    protocols: (await utils.getAll(AVAILABLE_ENTITIES.PROTOCOLS)).filter(
      (x) => x.veterinary_id === user.userId || !x.veterinary_id
    ),
    days: _.sortBy(await utils.getDomainValuesBy('RessincD0'), [
      (day) => parseInt(day.valor.substring(1)),
    ]),
    tiposResync,
    traditionalResync: _.find(tiposResync, { valor: 'Tradicional' }),
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(Resync)
)
