import { applyMiddleware, compose, createStore } from 'redux'

import { createLogger } from 'redux-logger'
import freeze from 'redux-freeze'
import { reducers } from './reducers'
import thunk from 'redux-thunk'

// add the middlewares
let middlewares = []

middlewares.push(thunk)

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger())
}

// apply the middleware
let middleware = applyMiddleware(...middlewares)

// add the redux dev tools
if (process.env.NODE_ENV !== 'production' && window.devToolsExtension) {
  middleware = compose(middleware, window.devToolsExtension())
}

// create the store
const store = createStore(reducers, middleware)

// export
export { store }
