
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import Container from '@material-ui/core/Container'

import * as sync from '../../../src/syncWorker'
import * as api from '../../redux/api/actions.js'
import { getLoggedUser } from '../../redux/auth/actions'
import { AVAILABLE_ENTITIES, utils } from '../../database'
import { verify } from './../../utils/verifyDuplicatePages'
import hasInternet from '../../utils/recognizeInternetConnection'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import CustomSwitch from '../../components/CustomSwitch.js'
import RegisterGigya from '../auth/RegisterGigya.js'
import UserForm from './components/UserForm.js'

class UserCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      noConnection: false,
      hasError: false,
      text: '',
      checked: false,
    }

    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onCancel() {
    this.props.history.push('/users')
  }

  async onSubmit(payload) {
    await this.props.create(payload).then((result) => {
      if (!result?.hasErrors) {
        if (!this.props.isAdministrator) {
          alert("Usuário criado com sucesso!")
          this.props.history.push('/users')
          this.initSync()
        } else {
          this.props.history.push('/users')
        }
      } else {
        this.setState({
          hasError: true,
          text: result.title
        })
      }
    }
    )
  }

  async initSync() {
    if (hasInternet()) {
      await sync.forceSync(false, false).then(() => this.props.history.push('/users'))

    } else {
      this.setState({ noConnection: true })
    }
  }

  UNSAFE_componentWillMount() {
    this.props.getAvailableRoles()
  }

  render() {
    return (
      <Container>
        <TopBar title='' />
        <TitleBar
          title='Novo Usuário'
          buttons={[
            {
              onClick: () => this.props.history.push('/users'),
              label: `Voltar`,
            },
          ]}
          paths={[
            {
              route: '/users',
              label: 'Usuários'
            },
            {
              label: 'Novo Usuário'
            }
          ]}
        />
        <MainContainer>
          <div className="grid">
            <div className="p-12 p-display-flex p-justify-space-between grid-item">
              <div />
              <CustomSwitch
                fontSize={'16px'}
                onHandleChange={(event) => this.setState({ checked: event.target.checked })}
                checked={this.state.checked}
                text='Criar um usuário sem e-mail'
              />
            </div>
          </div>
          {this.state.checked ? (
            <UserForm
              onCancel={this.onCancel}
              onSubmit={this.onSubmit}
              roles={this.props.availableRoles}
              user={null}
            />
          ) : (
            <RegisterGigya />
          )}

        </MainContainer>
        <Prompt
          visible={verify()}
          title='Página duplicada!'
          message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
          buttons={[
            {
              autoFocus: false
            },
          ]}
        />

        <Prompt
          visible={this.state.hasError}
          title='Erro ao inserir usuário'
          message={this.state.text}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ hasError: false })
            }
          ]}
        />

        <Prompt
          visible={this.state.noConnection}
          title='Não é possível realizar esta ação!'
          message={`Você está sem internet no momento. Verifique sua conexão e tente novamente mais tarde.`}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ noConnection: false })
            }
          ]}
        />
      </Container>
    )
  }
}

const propsFromDatabase = async (props) => {
  const loggedUser = await getLoggedUser()
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    loggedUser.userId
  )
  const isAdministrator = _.find(
    loggedUser.roles,
    (el) => el === 'Administrador'
  )
  return {
    user,
    isAdministrator
  }
}


const mapDispatchToProps = dispatch => {
  return {
    create: async params => api.post(dispatch, 'users', `Account`, params),
    getAvailableRoles: () => api.get(dispatch, 'users', `Profile/`)
  }
}

const mapStateToProps = state => ({
  loading:
    api.getApiLoading(state, `Account`) ||
    api.getApiLoading(state, `Profile/`),
  availableRoles: api.getApiResult(state, `Profile/`) || [],
  error: api.getApiError(state, `Account`),
  success: api.getApiResult(state, `Account`)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.withPropsFromDatabase(propsFromDatabase, withRouter(UserCreate)))