import * as CONSTANTS from './constants'

import { getParsedActionEndpoint } from './utils.js'

const { LOADING, RESULT, ERROR, LAST_SUCCESSFUL_RESULT } = CONSTANTS.STATE_TYPES

const initialState = {}

const reducer = (state = initialState, action) => {
  const endpoint = getParsedActionEndpoint(action.url)

  switch (action.type) {
    case CONSTANTS.API_LOADING:
      return {
        ...state,
        [`${endpoint}.${LOADING}`]: true
      }
    case CONSTANTS.API_ERROR:
      return {
        ...state,
        [`${endpoint}.${RESULT}`]: null,
        [`${endpoint}.${ERROR}`]: action.result,
        [`${endpoint}.${LOADING}`]: false
      }
    case CONSTANTS.API_SUCCESS:
      return {
        ...state,
        [`${endpoint}.${LAST_SUCCESSFUL_RESULT}`]: action.result,
        [`${endpoint}.${RESULT}`]: action.result,
        [`${endpoint}.${ERROR}`]: null,
        [`${endpoint}.${LOADING}`]: false
      }
    case CONSTANTS.API_CLEAR:
      return {
        ...state,
        [`${endpoint}.${LAST_SUCCESSFUL_RESULT}`]: null,
        [`${endpoint}.${RESULT}`]: null,
        [`${endpoint}.${ERROR}`]: null,
        [`${endpoint}.${LOADING}`]: false
      }
    default:
      return state
  }
}

export default reducer
