import React, { Component } from 'react'
import uuid from 'react-uuid'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import TextFileReader from '../components/BackupFileReader'

class Prompt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.id || uuid(),
    }
  }

  render() {
    const dialog = (
      <Dialog
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
        className={this.props.className}
        aria-labelledby='confirmation-dialog-title'
        open={this.props.visible ? this.props.visible : false}
      >
        <DialogTitle id={`title_${this.state.id}`} style={{ color: '#666' }}>
          {this.props.title}
        </DialogTitle>
        <DialogContent style={{ margin: '1em', fontSize: 16 }}>
          {this.props.message}

          {this.props.options &&
            this.props.options.map((option, index) => (
              <li key={index}>{option}</li>
            ))}

          {this.props.image && (
            <>
              <img
                src={this.props.image}
                alt='progerar'
                style={{
                  position: 'relative',
                  left: '5px',
                  top: '10px',
                }}
                width={'30px'}
                height={'30px'}
              />
            </>
          )}
          <br></br>
          {this.props.secondaryMessage && this.props.secondaryMessage}
          <br></br>
          {this.props.checkbox && (
            <>
              <input
                type='checkbox'
                checked={this.props.checkbox.checked}
                onChange={this.props.checkbox.onChange}
              ></input>{' '}
              {this.props.checkbox.label}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: window.innerWidth < 440 ? 'column' : 'row',
              alignItems: window.innerWidth < 440 ? 'end' : 'center',
              width: '100%',
              padding: '4px 8px',
            }}
          >
            {this.props.leftButton ? (
              <Button
                onClick={this.props.leftButton.onClick}
                disabled={this.props.leftButton.disabled}
                color='primary'
                style={{
                  color: 'rgb(234, 93, 13)',
                  fontSize: 16,
                }}
              >
                {this.props.leftButton.label}
              </Button>
            ) : (
              <div />
            )}
            <div
              style={
                window.innerWidth < 440 && this.props.leftButton
                  ? {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                  }
                  : { display: 'flex', flexDirection: 'row' }
              }
            >
              {this.props.buttons.map((button, index) => (
                <Button
                  key={`button_${this.state.id}_${index}`}
                  autoFocus={button.autoFocus != null ? button.autoFocus : true}
                  onClick={button.onClick}
                  disabled={button.disabled}
                  color='primary'
                  style={{ color: 'rgb(234, 93, 13)', fontSize: 16 }}
                >
                  {button.file ? (
                    <Button
                      component='label'
                      color='primary'
                      style={{ color: 'rgb(234, 93, 13)', fontSize: 16 }}
                    >
                      {button.label}
                      <TextFileReader></TextFileReader>
                    </Button>
                  ) : (
                    button.label
                  )}
                </Button>
              ))}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    )

    return this.props.withContainer ? (
      <Container disableGutters>{dialog}</Container>
    ) : (
      dialog
    )
  }
}

export default Prompt
