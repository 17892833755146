import { AVAILABLE_ENTITIES, utils } from '../../../database'
import Autocomplete, {
  autocompleteDefaultOnChange,
  autocompleteDefaultOnChangeSavingID,
  autocompleteDomainValueOptionLabelSavingID,
} from '../../../components/material/Autocomplete'
import Button, { COLORS } from '../../../components/material/Button'
import {
  CONDITIONAL_REQUIRED_FIELD_TYPE,
  REQUIRED_FIELD_MESSAGES,
  findEstado,
  isDateNullOrValid,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from './../../../utils/formHelper'
import DateField, {
  datefieldDefaultOnChange,
} from '../../../components/material/DateField'
import { ESTADOS_E_CIDADES, PAISES } from '../../../utils/constants.ts'
import React, { Component } from 'react'
import TextField, {
  textfieldDefaultOnChange,
} from '../../../components/material/TextField'

import Chip from '@material-ui/core/Chip'
import { FormHelperText } from '@material-ui/core'
import _ from 'lodash'

class FarmForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props.initialValues,
      showMunicipio: null,
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)
    this.datefieldDefaultOnChange = datefieldDefaultOnChange.bind(this)
    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.isDateNullOrValid = isDateNullOrValid.bind(this)
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  onSubmit() {
    let payload = {
      ...this.props.farm,
      ..._.pick(this.state, [
        'estado',
        'municipio',
        'nome',
        'inicioEstacaoMonta',
        'fimEstacaoMonta',
        'nomeProprietario',
        'inscricaoEstadual',
        'pais',
        'sistemaProducao',
        'estacaoMonta',
      ]),
    }

    payload.estacaoMonta = this.state?.estacaoMonta?.id
    payload.tamanho = parseInt(this.state.tamanho)
    payload.usuarioId = this.props.user.id
    payload.retiro = this.state.retiro ? this.state.retiro.value : false
    payload.veterinarios = this.state.veterinarios

    let requiredFieldsState = this.requiredFieldsEvaluator()
    let validDates =
      this.isDateNullOrValid(this.state.inicioEstacaoMonta) &&
      this.isDateNullOrValid(this.state.fimEstacaoMonta)

    if (requiredFieldsState !== null || !validDates) {
      this.setState(requiredFieldsState)
    } else {
      if (this.props.onSubmit) {
        this.props.onSubmit(payload)
      }
    }
  }

  onEnterPress = (e) => {
    if (this.props.user.roles[0].name !== 'Cliente (somente visualização)') {
      if (e.key === 'Enter' && e.shiftKey === false) {
        e.preventDefault()
        this.onSubmit()
      }
    }
  }

  render() {
    return (
      <div className='grid app-farm-form'>
        <div className='grid-item p-12 t-6 d-4 p-margin-top-2'>
          <TextField
            id='nome'
            label='Nome da Fazenda'
            onChange={this.textfieldDefaultOnChange}
            onKeyDown={this.onEnterPress}
            value={this.state.nome || null}
            error={!_.isEmpty(this.state.nome_error)}
            helperText={this.state.nome_error}
            disabled={this.props.disabled}
            autoFocus
          />
        </div>

        <div className='grid-item p-12 t-6 d-4 p-margin-top-2'>
          <TextField
            id='nomeProprietario'
            label='Nome do Proprietário'
            onChange={this.textfieldDefaultOnChange}
            onKeyDown={this.onEnterPress}
            value={this.state.nomeProprietario || null}
            error={!_.isEmpty(this.state.nomeProprietario_error)}
            helperText={this.state.nomeProprietario_error}
            disabled={this.props.disabled}
          />
        </div>

        <div className='grid-item p-12 t-6 d-4 p-margin-top-2'>
          <TextField
            id='inscricaoEstadual'
            label='Inscrição Estadual'
            onChange={this.textfieldDefaultOnChange}
            onKeyDown={this.onEnterPress}
            value={this.state.inscricaoEstadual || null}
            disabled={this.props.disabled}
          />
        </div>

        <div className='grid-item p-12 t-6 d-3 p-margin-top-2'>
          <Autocomplete
            id='pais'
            options={PAISES}
            onChange={(e, value) => {
              this.autocompleteDefaultOnChange('pais')(e, value)
              this.setState({
                estado: null,
                estado_error: null,
                municipio: null,
                showMunicipio: false,
              })
            }}
            onKeyDown={this.state.pais && this.onEnterPress}
            label='País'
            value={this.state.pais || null}
            error={!_.isEmpty(this.state.pais_error)}
            helperText={this.state.pais_error}
            disabled={this.props.disabled}
          />
        </div>

        <div className='grid-item p-12 t-6 d-3 p-margin-top-2'>
          <Autocomplete
            id='estado'
            options={ESTADOS_E_CIDADES}
            getOptionLabel={(option) => {
              if (_.has(option, 'id')) {
                return option.name
              }
              const opt = findEstado(option)
              return opt && opt.name
            }}
            onChange={(e, value) => {
              let newState = {
                estado: value && value.id,
                municipio: null,
                showMunicipio: false,
              }
              if (_.isEmpty(value) || _.isEmpty(value.id)) {
                if (
                  this.props.requiredFields &&
                  this.props.requiredFields.indexOf('estado') >= 0
                ) {
                  newState.estado_error = REQUIRED_FIELD_MESSAGES.DEFAULT
                } else if (this.props.conditionalRequiredFields) {
                  const condition = _.find(
                    this.props.conditionalRequiredFields,
                    {
                      field: 'estado',
                    }
                  )
                  if (
                    condition &&
                    this.state[condition.dependsOn] === condition.value
                  ) {
                    newState.estado_error = REQUIRED_FIELD_MESSAGES.DEFAULT
                  }
                }
              } else {
                newState.estado_error = null
              }
              this.setState(newState)
            }}
            onKeyDown={this.state.estado && this.onEnterPress}
            useDefaultOptionSelected
            label='Estado'
            value={this.state.estado || null}
            error={!_.isEmpty(this.state.estado_error)}
            helperText={this.state.estado_error}
            disabled={this.state.pais !== 'Brasil' || this.props.disabled}
          />
        </div>

        <div className='grid-item p-12 t-6 d-3 p-margin-top-2'>
          {this.state.pais === 'Brasil' && !this.state.showMunicipio ? (
            <Autocomplete
              id='municipio'
              label='Município'
              options={
                this.state.estado
                  ? ['Outro'].concat(
                    ESTADOS_E_CIDADES.filter(
                      (e) => e.id === this.state.estado
                    )[0].cidades
                  )
                  : []
              }
              disabled={!this.state.estado || this.props.disabled}
              onChange={(e, value) => {
                if (value === 'Outro') {
                  this.setState({
                    showMunicipio: true,
                    municipio: null,
                  })
                } else {
                  this.autocompleteDefaultOnChange('municipio')(e, value)
                }
              }}
              onKeyDown={this.state.municipio && this.onEnterPress}
              value={this.state.municipio || null}
              error={!_.isEmpty(this.state.municipio_error)}
              helperText={this.state.municipio_error}
            />
          ) : (
            <TextField
              id='municipio'
              label='Município'
              onChange={this.textfieldDefaultOnChange}
              onKeyDown={this.onEnterPress}
              value={this.state.municipio || null}
              error={!_.isEmpty(this.state.nome_error)}
              helperText={this.state.nome_error}
              disabled={this.props.disabled}
            />
          )}
        </div>

        <div className='grid-item p-12 t-6 d-3 p-margin-top-2'>
          <Autocomplete
            id='sistemaProducao'
            options={this.props.sistemasProducao}
            value={this.state.sistemaProducao || null}
            onChange={this.autocompleteDefaultOnChangeSavingID(
              'sistemaProducao'
            )}
            onKeyDown={this.state.sistemaProducao && this.onEnterPress}
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.sistemasProducao
            )}
            useDefaultOptionSelected
            label='Sistema de Produção'
            error={!_.isEmpty(this.state.sistemaProducao_error)}
            helperText={this.state.sistemaProducao_error}
            disabled={this.props.disabled}
          />
        </div>

        <div className='grid-item p-8 t-3 d-3 p-margin-top-2'>
          <TextField
            id='tamanho'
            label='Tamanho (hectares para pecuária)'
            onChange={this.textfieldDefaultOnChange}
            onKeyDown={this.onEnterPress}
            value={this.state.tamanho || null}
            type='number'
            disabled={this.props.disabled}
          />
        </div>

        <div className='grid-item p-4 t-3 d-3 p-margin-top-2'>
          <Autocomplete
            id='retiro'
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            onChange={this.autocompleteDefaultOnChange('retiro')}
            onKeyDown={this.state.retiro && this.onEnterPress}
            getOptionLabel={(option) => option.label}
            label='Possui Retiro?'
            value={this.state.retiro || null}
            error={!_.isEmpty(this.state.retiro_error)}
            helperText={this.state.retiro_error}
            disabled={!_.isEmpty(this.props.farm) || this.props.disabled}
          />
        </div>

        <div className='grid-item p-12 t-6 d-3 p-margin-top-2'>
          <DateField
            id='inicioEstacaoMonta'
            label='Início da Estação de Monta'
            value={this.state.inicioEstacaoMonta || null}
            onChange={this.datefieldDefaultOnChange('inicioEstacaoMonta')}
            onKeyDown={this.onEnterPress}
            error={
              !_.isEmpty(this.state.inicioEstacaoMonta_error) ||
              !this.isDateNullOrValid(this.state.inicioEstacaoMonta)
            }
            helperText={this.state.inicioEstacaoMonta_error}
            disabled={this.props.disabled}
          />
        </div>

        <div className='grid-item p-12 t-6 d-3 p-margin-top-2'>
          <DateField
            id='fimEstacaoMonta'
            label='Fim da Estação de Monta'
            value={this.state.fimEstacaoMonta || null}
            onChange={this.datefieldDefaultOnChange('fimEstacaoMonta')}
            onKeyDown={this.onEnterPress}
            error={
              !_.isEmpty(this.state.fimEstacaoMonta_error) ||
              !this.isDateNullOrValid(this.state.fimEstacaoMonta)
            }
            helperText={this.state.fimEstacaoMonta_error}
            disabled={this.props.disabled}
          />
        </div>

        <div className='grid-item p-12 t-4 d-3 p-margin-top-2'>
          <Autocomplete
            id={`estacaoMonta`}
            options={this.props.estacaoMonta}
            onChange={this.autocompleteDefaultOnChange('estacaoMonta')}
            onKeyDown={this.state.estacaoMonta && this.onEnterPress}
            value={this.state.estacaoMonta || null}
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.estacaoMonta
            )}
            useDefaultOptionSelected
            label='Estação de Monta'
            error={!_.isEmpty(this.state.estacaoMonta_error)}
            helperText={this.state.estacaoMonta_error}
            disabled={this.props.disabled}
          />
        </div>

        <div className='grid-item p-12 t-8 d-9 p-margin-top-2'>
          <Autocomplete
            multiple
            id='veterinarios'
            options={this.props.vets}
            value={this.state.veterinarios || null}
            onChange={(e, val) => {
              if (!_.find(this.state.veterinarios, this.props.user)) {
                [...new Set(val)].push(this.props.user)
                this.setState({
                  veterinarios: val,
                })
              }
              this.autocompleteDefaultOnChange('veterinarios')(e, val)
            }}
            disableClearable
            getOptionSelected={(option, value) => option.id === value.id}
            onKeyDown={this.state.veterinarios && this.onEnterPress}
            filterOptions={(options) =>
              options.filter(
                (x) => !_.find(this.state.veterinarios, { id: x.id })
              )
            }
            getOptionLabel={(option) => option.nomeCompleto}
            label='Responsáveis'
            disabled={
              this.props.farm
                ? this.props.farm.usuarioId !== this.props.user.id
                : false
            }
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option.nomeCompleto}
                  {...getTagProps({ index })}
                  disabled={
                    this.props.user.id === option.id ||
                    (this.props.farm
                      ? this.props.farm.usuarioId !== this.props.user.id
                      : false)
                  }
                />
              ))
            }
          />
          <FormHelperText id='outlined-weight-helper-text'>
            Usuários Inseminadores adicionados aqui serão exibidos tela de IATF
          </FormHelperText>
        </div>

        <div className='grid-item p-12 p-display-flex p-justify-space-between p-margin-top-2'>
          <Button
            color={COLORS.GREY}
            label='Cancelar'
            onClick={this.onCancel}
          />
          <Button label='Salvar' onClick={this.onSubmit} disabled={this.props.user.roles[0].name === 'Cliente (somente visualização)'} />
        </div>
      </div>
    )
  }
}

const propsFromDatabase = async (props) => ({
  estacaoMonta: await utils.getDomainValuesBy('Estação de Monta'),
  vets: (await utils.getAll(AVAILABLE_ENTITIES.USERS)).filter((vet) =>
    vet.roles.some((role) => role.name !== 'Administrador')
  ),
  sistemasProducao: await utils.getDomainValuesBy('Sistema de Produção'),
  requiredFields: [
    'nomeProprietario',
    'nome',
    'pais',
    'municipio',
    'sistemaProducao',
    'retiro',
    'inicioEstacaoMonta',
    'fimEstacaoMonta',
    'estacaoMonta',
  ],
  conditionalRequiredFields: [
    {
      field: 'estado',
      dependsOn: 'pais',
      value: 'Brasil',
      type: CONDITIONAL_REQUIRED_FIELD_TYPE.DEPENDENT_ON_FIELD,
    },
  ]
})

export default utils.withPropsFromDatabase(propsFromDatabase, FarmForm)
