import React from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, Tooltip } from '@material-ui/core'
import { HelpOutline as HelpOutlineIcon, WhatsApp } from '@material-ui/icons'

const TrainingsButton = ({
  page,
  hasCorral,
  data = {
    color: '#ea5d0d',
    title: 'Página de treinamentos',
    link: '',
    isWhatsApp: false,
  },
}) => {
  const history = useHistory()

  const handleOpenBlankLink = () => {
    window.open(data?.link, '_blank')
  }

  const handleButtonClick = () => {
    history.push('/treinamentos', { page, hasCorral })
  }

  const buttonStyle = {
    backgroundColor: data.color,
    borderRadius: '50%',
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    zIndex: 1000,
  }

  return (
    <Tooltip title={data.title} arrow>
      {data.isWhatsApp ? (
        <IconButton
          onClick={data.link !== '' ? handleOpenBlankLink : handleButtonClick}
          style={buttonStyle}
        >
          <WhatsApp
            style={{ height: '1.5rem', width: '1.5rem', color: '#fff' }}
          />
        </IconButton>
      ) : (
        <IconButton
          onClick={
            !!data && data.link ? handleOpenBlankLink : handleButtonClick
          }
          style={buttonStyle}
        >
          <HelpOutlineIcon
            style={{ height: '1.5rem', width: '1.5rem', color: '#fff' }}
          />
        </IconButton>
      )}
    </Tooltip>
  )
}

export default TrainingsButton
