import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import Autocomplete, {
  autocompleteDefaultOnChange,
  autocompleteDefaultOnChangeSavingID,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteDomainValueOptionLabelSavingIDUsingDescription,
  autocompleteOptionLabelUsingCustomFieldSavingID
} from '../../components/material/Autocomplete'
import Button, { COLORS } from '../../components/material/Button'
import DateField, {
  datefieldDefaultOnChange
} from '../../components/material/DateField'
import React, { Component, Fragment } from 'react'
import ResponsiveTable, { CELL_TYPES } from './../../components/ResponsiveTable'
import TextField, {
  textfieldDefaultOnChange,
} from '../../components/material/TextField'
import {
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from '../../utils/formHelper'

import Chip from '@material-ui/core/Chip'
import { Container } from '@material-ui/core'
import CustomSwitch from '../../components/CustomSwitch'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import SaveIcon from '@material-ui/icons/Save'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { getLoggedUser } from '../../redux/auth/actions'
import {
  isDateNullOrValid,
} from './../../utils/formHelper'
import moment from 'moment'
import track from 'react-tracking'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

@track(() => ({ page: 'Partidas', date: new Date() }))
class Partidas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedData: this.props.partidas,
      // farms: this.props.farms,
      farms: [],
      selectedFarms: this.props.farms,
      selectedFarmsToEdit: this.props.farms,
      checked: false,
      toEditBull: null,
      toEditFarms: [],
      toEditPartida: null,
      toEditCodPartida: null,
      createConfirmation: false,
      editConfirmation: false
    }

    let farmsEditRef = this.state.selectedFarmsToEdit
    let a = []
    a.push(farmsEditRef.map((sfr) => {
      const estacaoMonta = this.props.estacaoMonta.filter((em) => em.id === sfr.estacaoMonta)
        .map((value) => value.valor)
      sfr.nome = `${sfr.nome} - ${estacaoMonta}`
    }))

    this.setState({ selectedFarmsToEdit: a })

    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
    this.autocompleteOptionLabelUsingCustomFieldSavingID =
      autocompleteOptionLabelUsingCustomFieldSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingIDUsingDescription =
      autocompleteDomainValueOptionLabelSavingIDUsingDescription.bind(this)
    this.datefieldDefaultOnChange = datefieldDefaultOnChange.bind(this)
    this.isDateNullOrValid = isDateNullOrValid.bind(this)
    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)

    this.onCancel = this.onCancel.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSave = this.handleSave.bind(this)

    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.renderTitle = this.renderTitle.bind(this)
  }

  onCancel() {
    this.props.history.push(`/bulls/create-for-all/batch/${this.props.batchId}/management/${this.props.management}`)
  }

  handleSearch(e) {
    const val = e.target.value

    if (_.isEmpty(val)) {
      this.setState({ selectedData: this.props.partidas })
    } else {
      const filteredPartidas = this.state.selectedData.filter(
        (x) => x['data']
          ? x['data'].toString().toLowerCase().indexOf(val.toLowerCase()) >= 0
          : x['codigo'].toLowerCase().indexOf(val.toLowerCase()) >= 0

      )

      this.setState({ selectedData: filteredPartidas })
    }
  }

  @track({ action: 'Editou uma Partida' })
  async handleEdit() {
    const partidasRepository = new Repository(AVAILABLE_ENTITIES.PARTIDA)
    const partidas = await partidasRepository.get()
    if (partidas.success) {
      let formatedBulls = partidas.response.filter(
        x => x.fazendaId && x.partidaOriginalId === this.state.toEditBull.id && x.touroId
      )

      let partidaOriginal = partidas.response.filter(
        x => x.id === this.state.toEditBull.id && !x.fazendaId && !x.partidaOriginalId && x.touroId
      )[0]

      let thisBullfarms = formatedBulls.map(fb => fb.fazendaId)
      let toEditBullFarms = this.state.toEditFarms.map(te => te.id)

      let toCreatePartidas = []
      let toDeletePartidas = []

      toEditBullFarms.map(tebf => !(thisBullfarms.indexOf(tebf) >= 0) && toCreatePartidas.push(tebf))
      thisBullfarms.map(tbf => !(toEditBullFarms.indexOf(tbf) >= 0) && toDeletePartidas.push(tbf))

      let payload = {
        ...partidaOriginal,
        // data: this.state.toEditPartida,
        data: moment(this.state.toEditPartida).add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        codigo: this.state.toEditCodPartida,
        fazendaId: null
      }

      let updatePartidaOriginal = await partidasRepository.update(payload)

      if (updatePartidaOriginal.success) {
        //editando partidas antigas
        this.setState({
          selectedData: [...this.state.selectedData.filter((item) => item.id !== this.state.toEditBull.id), updatePartidaOriginal.response]
        })
        for (let toEditPartida of thisBullfarms) {
          let bullToEdit = formatedBulls.filter(fb => fb.fazendaId == toEditPartida)[0]
          let bullToEditPayload = {
            ...bullToEdit,
            data: this.state.toEditPartida,
            codigo: this.state.toEditCodPartida,
          }
          const bullEditRequest = await partidasRepository.update(
            bullToEditPayload
          )
          if (bullEditRequest.success) {

            console.log(`Update of bull successful`)
          } else {
            console.log(
              `Update of bull failed: ${bullEditRequest.exception}`
            )
          }
        }

        //deletando partidas
        for (let toDeletePartida of toDeletePartidas) {
          let bullToDelete = formatedBulls.filter(fb => fb.fazendaId == toDeletePartida)[0].id
          const bullDeleteRequest = await partidasRepository.delete(
            bullToDelete
          )
          if (bullDeleteRequest.success) {
            console.log(`Delete of bull successful`)
          } else {
            console.log(
              `Delete of bull failed: ${bullDeleteRequest.exception}`
            )
          }
        }

        //criando partidas novas
        for (let toCreatePartida of toCreatePartidas) {
          let bullToCreatePayload = {
            ...partidaOriginal,
            id: null,
            partidaOriginal: false,
            partidaOriginalId: partidaOriginal.id,
            fazendaId: toCreatePartida,
            data: this.state.toEditPartida,
            codigo: this.state.toEditCodPartida,
          }
          const bullCreateRequest = await partidasRepository.post(
            bullToCreatePayload
          )
          if (bullCreateRequest.success) {
            console.log(`Create of bull successful`)
          } else {
            console.log(
              `Create of bull failed: ${bullCreateRequest.exception}`
            )
          }
        }
        this.setState({ editConfirmation: true })
      }
    }
    this.setState({ toEditBull: null })
  }

  @track((props, state, partida) => ({ action: 'Deletou uma Partida', value: partida[0] }))
  async handleDelete(selectedPartida) {
    const repository = new Repository(AVAILABLE_ENTITIES.PARTIDA)
    const partidasRepository = await repository.get()
    const partidas = partidasRepository.response.filter(b => b.partidaOriginalId === selectedPartida)

    for (let partida of partidas) {
      const partidasRequest = await repository.delete(
        partida.id
      )
      if (partidasRequest.success) {
        console.log(`Delete of partida successful`)
      } else {
        console.log(
          `Delete of bull failed: ${partidasRequest.exception}`
        )
      }
    }

    const partidaRequest = await repository.delete(
      selectedPartida
    )
    if (partidaRequest.success) {
      console.log(`Delete of bull successful`)
      this.setState({
        selectedData: this.state.selectedData.filter((item) => item.id !== selectedPartida),
      })
    } else {
      console.log(
        `Delete of bull failed: ${partidaRequest.exception}`
      )
    }

    this.setState({ deleteConfirmationOpened: false })
  }

  async handleEditFarms(partidaId) {
    const partidasRepository = new Repository(AVAILABLE_ENTITIES.PARTIDA)
    //TODO: Change login info source after offline login is implemented
    const partidas = await partidasRepository.get()

    if (partidas.success) {
      let formatedBulls = partidas.response.filter(
        x => x.fazendaId && x.partidaOriginalId === partidaId
      )

      let farms = formatedBulls.map(fb => fb.fazendaId)

      let toEditFarms = [];
      this.props.farms.filter(f => farms.indexOf(f.id) >= 0 && toEditFarms.push(f))

      this.setState({ toEditFarms })
    }
  }


  handleSwitchChange = (event) => {
    this.setState({ checked: event.target.checked })
    this.setState({ partida: null, codPartida: null, toEditCodPartida: null, toEditPartida: null })
  }

  @track({ action: 'Salvou uma partida' })
  async handleSave() {
    if (!this.state.farms || (!this.state.partida && !this.state.codPartida)) {
      return
    }

    let originalPayload = {
      id: null,
      partidaOriginal: true,
      touroId: this.props.bull.id,
      data: !this.state.checked ? moment(this.state.partida).add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null,
      codigo: this.state.checked ? this.state.codPartida : null,
      fazendaId: null
    }

    const repository = new Repository(AVAILABLE_ENTITIES.PARTIDA)
    const createFirstBullRequest = await repository.post(originalPayload)

    if (createFirstBullRequest.success) {
      this.setState({ selectedData: [...this.state.selectedData, createFirstBullRequest.response] })

      for (let farm of this.state.farms) {
        let payload = {
          ...originalPayload,
          id: null,
          partidaOriginal: false,
          fazendaId: farm.id,
          partidaOriginalId: createFirstBullRequest.response.id
        }

        const createRequest = await repository.post(payload)
        if (createRequest.success) {
          console.log('Bull created successfully')
          this.setState({ codPartida: null, partida: null, createConfirmation: true, farms: [] })
        } else {
          console.log(
            `There was an error creating bull: ${createRequest.exception}`
          )
        }
      }
    } else {
      console.log(
        `There was an error creating bull: ${createFirstBullRequest.exception}`
      )
    }
  }

  onEnterPress = (e) => {
    if (this.props.user.roles[0].name !== 'Cliente (somente visualização)') {
      if (e.keyCode == 13 && e.shiftKey == false) {
        e.preventDefault()
        this.handleSave()
      }
    }
  }

  renderTitle() {
    return (
      <Fragment>
        {!this.state.toEditBull ? "Cadastrar partidas" : "Editar partida"}
        <br />
        Touro: {this.props.bull?.nome}
        <br />
        {this.state.toEditBull && `
        Partida: ${this.state.toEditBull.codigo === "false" || !this.state.toEditBull.codigo ? moment(this.state.toEditBull.data).format('DD/MM/YYYY') : this.state.toEditBull.codigo}`}
      </Fragment>
    )
  }

  getTableColumnData() {
    return [
      {
        name: 'Tipo de Partida ',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        grid: 2,
        label: (row) => row.codigo === "false" || row.codigo === null ? 'Data' : 'Código'
      },
      {
        name: 'Partida ',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        grid: 2,
        label: (row) => row.codigo === "false" || row.codigo === null ? moment(row.data).format('DD/MM/YYYY') : row.codigo
      },
      {
        type: CELL_TYPES.BUTTON_ARR,
        buttons: (row) =>
          _.compact([
            {
              icon: <EditIcon />,
              label: 'Editar',
              onClick: () => {
                this.handleEditFarms(row.id)
                this.setState({
                  toEditBull: row,
                  toEditCodPartida: row.codigo !== "false" || !row.codigo ? row.codigo : null,
                  toEditPartida: row.codigo === "false" || !row.codigo ? row.data : null,
                  checked: row.codigo === "false" || !row.codigo ? false : true,
                })
              },
            },
            {
              icon: <DeleteIcon />,
              label: 'Excluir',
              onClick: () => {
                this.setState({
                  selectedPartida: row.id,
                  deleteConfirmationOpened: true,
                })
              },
              disabled: this.props.user.roles[0].name === 'Cliente (somente visualização)'
            },
          ]),
      },
    ];
  }

  getButtons() {
    return [
      {
        onClick: () =>
          this.backLocation(),
        label: `Voltar ${this.props.batchId != 'none' ? 'ao manejo' : ''}`,
      }]
  }


  backLocation() {
    if (this.props.batchId && this.props.batchId.length > 1) {
      this.props.history.push(`/${this.props.management}/${this.props.batchId.map(id => id).join(';')}/redirected/true`)
    }
    else if (this.props.batchId != 'none' && this.props.management != 'none') {
      this.props.history.push(`/${this.props.management}/${this.props.batchId}/redirected/true`)
    } else {
      this.props.history.push(`/bulls/create-for-all/batch/${this.props.batchId}/management/${this.props.management}`)
    }

  }

  render() {
    return (
      <Container>
        <TopBar title={this.props.bull?.nome} />
        <TitleBar title={this.renderTitle()} buttons={this.getButtons()} />
        <MainContainer>
          {!this.state.toEditBull ? (
            <div className="grid d0-grid-form">
              <div className="grid-item p-12 d-4">
                {!this.state.checked ? (
                  <DateField
                    id='partida'
                    label='Data da partida'
                    value={this.state.partida || null}
                    onChange={this.datefieldDefaultOnChange('partida')}
                    onKeyDown={this.onEnterPress}
                    error={
                      !_.isEmpty(this.state.partida_error) ||
                      !this.isDateNullOrValid(this.state.partida)
                    }
                    helperText={this.state.partida_error}
                  />
                ) : (
                  <TextField
                    id='codPartida'
                    value={this.state.codPartida || null}
                    onChange={this.textfieldDefaultOnChange}
                    label='Código da partida'
                    onKeyDown={this.onEnterPress}
                    error={
                      !_.isEmpty(this.state.codPartida_error)
                    }
                    helperText={this.state.codPartida_error}
                  />
                )}
                <CustomSwitch
                  fontSize='1.2em'
                  onHandleChange={this.handleSwitchChange}
                  checked={this.state.checked}
                  text='Cadastrar como código'
                ></CustomSwitch>
              </div>
              <div className="grid-item p-12 d-8">
                <Autocomplete
                  multiple
                  id='farms'
                  options={this.state.selectedFarms}
                  value={this.state.farms}
                  onChange={(e, val) => {
                    if (!_.find(this.state.farms)) {
                      this.setState({
                        farms: val
                      })
                    }
                    this.autocompleteDefaultOnChange('farms')(e, val)
                  }}
                  onInputChange={
                    (e) => {
                      if (e.target.value != null && e.target.value != '') {
                        this.setState({
                          selectedFarms: this.props.farms.filter(f => f.nome.toLowerCase().indexOf(e.target?.value.toLowerCase()) >= 0)
                        })
                      } else {
                        this.setState({
                          selectedFarms: this.props.farms
                        })
                      }
                    }
                  }
                  // error={this.state.farms.length == 0}
                  onKeyDown={this.state.farms && this.onEnterPress}
                  filterOptions={options =>
                    options.filter(
                      x => !_.find(this.state.farms, { id: x.id })
                    )
                  }
                  getOptionLabel={option => option.nome}
                  label='Fazendas'
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={option.nome}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </div>

              <div className="grid-item p-12 p-display-flex p-justify-space-between">
                <Button
                  style={{ height: '50px' }}
                  label='cancelar'
                  color={COLORS.GREY}
                  disabled={this.state.loading}
                  onClick={this.onCancel}

                />
                <Button
                  style={{ height: '50px' }}
                  label='Salvar'
                  disabled={this.state.loading || (!this.state.checked && (this.state.partida == null || !_.isEmpty(this.state.partida_error) ||
                    !this.isDateNullOrValid(this.state.partida))) || (this.state.checked && (this.state.codPartida == null || this.state.codPartida == '')) || this.props.user.roles[0].name === 'Cliente (somente visualização)'}
                  onClick={this.handleSave}
                  startIcon={<SaveIcon />}
                />
              </div>
            </div>
          ) :
            (
              <div className="grid d0-grid-form">
                <div className="grid-item p-12 d-4">
                  {!this.state.checked ? (
                    <DateField
                      id='toEditPartida'
                      label='Data da partida'
                      value={this.state.toEditPartida || null}
                      onChange={this.datefieldDefaultOnChange('toEditPartida')}
                      onKeyDown={this.onEnterPress}
                      error={
                        !_.isEmpty(this.state.toEditPartida_error) ||
                        !this.isDateNullOrValid(moment(this.state.toEditPartida))
                      }
                      helperText={this.state.toEditPartida_error}
                    />
                  ) : (
                    <TextField
                      id='toEditCodPartida'
                      value={this.state.toEditCodPartida || null}
                      onChange={this.textfieldDefaultOnChange}
                      label='Código da partida'
                      onKeyDown={this.onEnterPress}
                      error={
                        !_.isEmpty(this.state.toEditCodPartida_error)
                      }
                      helperText={this.state.toEditCodPartida_error}
                    />
                  )}
                  <CustomSwitch
                    fontSize='1.2em'
                    onHandleChange={this.handleSwitchChange}
                    checked={this.state.checked}
                    text='Cadastrar como código'
                  ></CustomSwitch>
                </div>

                <div className="grid-item p-12 d-8">
                  <Autocomplete
                    multiple
                    id='toEditfarms'
                    options={this.state.selectedFarmsToEdit}
                    value={this.state.toEditFarms}
                    onChange={(e, val) => {
                      if (!_.find(this.state.toEditFarms)) {
                        this.setState({
                          toEditFarms: val
                        })
                      }
                      this.autocompleteDefaultOnChange('toEditFarms')(e, val)
                    }}
                    onInputChange={
                      (e) => {
                        if (e.target.value != null && e.target.value != '') {
                          this.setState({
                            selectedFarmsToEdit: this.props.farms.filter(f => f.nome.toLowerCase().indexOf(e.target?.value.toLowerCase()) >= 0)
                          })
                        } else {
                          this.setState({
                            selectedFarmsToEdit: this.props.farms
                          })
                        }
                      }
                    }
                    // error={this.state.toEditFarms.length == 0}
                    onKeyDown={this.state.toEditFarms && this.onEnterPress}
                    filterOptions={options =>
                      options.filter(
                        x => !_.find(this.state.toEditFarms, { id: x.id })
                      )
                    }
                    getOptionLabel={option => option.nome}
                    label='Fazendas'
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.nome}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </div>
                <div className="grid-item p-12 p-display-flex p-justify-space-between">
                  <Button
                    style={{ height: '50px' }}
                    color={COLORS.GREY}
                    label='cancelar'
                    disabled={this.state.loading}
                    onClick={() => this.setState({ toEditBull: null, toEditPartida: null, toEditCodPartida: null, checked: false })}
                  />
                  <Button
                    style={{ height: '50px' }}
                    label='salvar'
                    disabled={this.state.loading || (!this.state.checked && (this.state.toEditPartida == null || !_.isEmpty(this.state.toEditPartida_error) ||
                      !this.isDateNullOrValid(moment(this.state.toEditPartida)))) || (this.state.checked && (this.state.toEditCodPartida == null || this.state.toEditCodPartida == '')) || this.props.user.roles[0].name === 'Cliente (somente visualização)'}
                    onClick={this.handleEdit}
                    startIcon={<SaveIcon />}
                  />
                </div>
              </div>
            )}
          {
            !this.state.toEditBull && (
              <MainContainer
                hasSearchWithoutFocus
                removeMarginTop
                searchLabel='Buscar'
                handleSearch={this.handleSearch}
                skipVersion={true}
              >
                <ResponsiveTable
                  columns={this.getTableColumnData()}
                  data={this.state.selectedData}
                  noDataMessage='Ainda não existe nenhuma partida deste touro!'
                  onSort={(sortedData) => this.setState({ selectedData: sortedData })}
                />
                <Prompt
                  visible={verify()}
                  title='Página duplicada!'
                  message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
                    Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
                  buttons={[
                    {
                      autoFocus: false
                    },
                  ]}
                />
                <Prompt
                  visible={this.state.deleteConfirmationOpened}
                  title='Confirmar Exclusão'
                  message='Você está prestes a deletar esta partida. Esta ação não poderá ser desfeita.'
                  buttons={[
                    {
                      label: 'Cancelar',
                      onClick: () =>
                        this.setState({ deleteConfirmationOpened: false })
                    },
                    {
                      label: 'Confirmar',
                      onClick: () => this.handleDelete(this.state.selectedPartida)
                    }
                  ]}
                />
              </MainContainer>
            )
          }

        </MainContainer>

        <Prompt
          visible={this.state.searchError}
          title={'Matriz já cadastrada em outro(s) lotes'}
          message={
            this.state.searchError
              ? this.state.searchError
              : 'Erro desconhecido'
          }
          secondaryMessage={
            'Para não ver este aviso, desmarque a opção "Avisar sobre matrizes repetidas".'
          }
          buttons={[
            {
              label: 'Cancelar',
              autoFocus: false,
              onClick: () => this.setState({ searchError: false }),
            },
            {
              label: 'Salvar',
              onClick: () => {
                this.onSave()
              },
            },
          ]}
        />

        <Prompt
          visible={this.state.createConfirmation}
          title={'Sucesso!'}
          message={
            'Partida cadastrada com sucesso para a(s) fazenda(s) selecionada(s).'
          }

          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ createConfirmation: false }),
            }

          ]}
        />

        <Prompt
          visible={this.state.editConfirmation}
          title={'Sucesso!'}
          message={
            'Partida editada com sucesso.'
          }

          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ editConfirmation: false }),
            }

          ]}
        />
      </Container >
    )
  }
}

async function getFarms(user) {
  if (!user) {
    return []
  }

  let repository = new Repository(AVAILABLE_ENTITIES.FARMS)
  let farmsData = await repository.get()

  if (farmsData.success) {
    let farms = farmsData.response
    return farms
  }
  return []
}


const propsFromDatabase = async (props) => {
  const bullId = props.match.params.bullId.split(';')
  const batchId = props.match.params.batchId.split(';')
  const management = props.match.params.management.split(';')

  const loggedUser = await getLoggedUser()
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    loggedUser.userId
  )

  const farms = await getFarms(user)

  const partidas = await utils.getWithParam(
    AVAILABLE_ENTITIES.PARTIDA,
    'bull_id',
    Q.oneOf(bullId)
  )

  const bull = await utils.getWithParam(
    AVAILABLE_ENTITIES.BULLS_NEW,
    'id',
    bullId[0]
  )

  return {
    requiredFields: [
      'codVaca',
      'responsavelId',
      'dispositivoIntravaginal',
      'numero_Uso_Disp',
      'racaMatriz',
      'ordemMatriz',
    ],
    batchId,
    bull: bull[0],
    partidas: partidas.filter((item) => item.partidaOriginalId === null),
    estacaoMonta: await utils.getDomainValuesBy('Estação de Monta'),
    management,
    farms,
    user
  }
}

export default utils.withPropsFromDatabase(propsFromDatabase, withRouter(Partidas))
