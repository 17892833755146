import * as CONSTANTS from './constants'

export const changeSyncView = payload => {
  return async dispatch => {
    dispatch({
      type: CONSTANTS.CHANGE_CURRENT_SYNC_VIEW,
      payload
    })
  }
}
