import { makeStyles } from "@material-ui/core";

import { sendData } from '../../syncWorker';

export const useStyles = makeStyles(() => ({
  dropzone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '60vh',
    border: '2px dashed #bcbcbc',
    backgroundColor: '#ececec',
    color: '#999',
    marginBottom: '1rem',
  },
}))

export const processPayload = async (payload, token) => {
  if (payload.constructor.name === "Array") {
    for (const item of payload) {
      await processPayload(item, token);
    }
  } else {
    await sendData(payload, token);
  }
}

export const hasProgerarExtension = (file) => {
  if (!file.name?.includes(".progerar")) {
    return {
      code: "dont_accept_file",
      message: `Extensão de arquivo inválida. Extensão aceita: .progerar`
    };
  }

  return null
}