import {
  makeStyles,
  useMediaQuery
} from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  accordion: {
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    '&:last-child': {
      borderRadius: theme.spacing(1),
    },
    '& .MuiAccordionSummary-root': {
      backgroundColor: '#ea5d0d',
      color: theme.palette.primary.contrastText,
      borderRadius: theme.spacing(1),
    },
    '& .Mui-expanded': {
      borderRadius: theme.spacing(1, 1, 0, 0),
    },
    '& .MuiAccordionSummary-expandIcon': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(1, 0),
    },
    '& .MuiAccordionDetails-root': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.grey[100],
      padding: 0,
      borderRadius: theme.spacing(0, 0, 1, 1),
    },
    '& ul': {
      listStyle: 'none',
      padding: 0,
    },
    '& li': {
      cursor: 'pointer',
      color: theme.palette.text.primary,
      fontSize: '1rem',
      padding: theme.spacing(1.8),
      '&:hover': {
        textDecoration: 'underline',
        color: '#ea5d0d',
      },
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
      },
    },
  },
}))

export const itens = [
  {
    id: 1,
    title: 'Login',
    videos: [
      {
        id: 1,
        title: 'Primeiro login',
        link: 'https://www.youtube.com/embed/yaeBfhsImko',
        linkMobile: 'https://www.youtube.com/embed/fTuM7QW92dw',
      },
      {
        id: 2,
        title: 'Recuperação de senha',
        link: 'https://www.youtube.com/embed/s_7MWet0UM8',
        linkMobile: 'https://www.youtube.com/embed/m8O0Tdmtdow',
      },
    ],
  },
  {
    id: 2,
    title: 'Tela Inicial',
    videos: [
      {
        id: 3,
        title: 'Introdução aos módulos',
        link: 'https://www.youtube.com/embed/3L3lweI4gbs',
        linkMobile: 'https://www.youtube.com/embed/UdVqO37vF90',
      },
      {
        id: 4,
        title: 'Atualizar versão',
        link: 'https://www.youtube.com/embed/SK6gtHTDHEk',
        linkMobile: 'https://www.youtube.com/embed/zo3MCM_WEug',
      },
      {
        id: 5,
        title: 'Backup',
        link: 'https://www.youtube.com/embed/Ino49-8YrRk',
        linkMobile: 'https://www.youtube.com/embed/-hNV3k2lLEc',
      },
    ],
  },
  {
    id: 3,
    title: 'Sincronização',
    videos: [
      {
        id: 6,
        title: 'Introdução, modo offline/online, importância',
        link: 'https://www.youtube.com/embed/4u-HPmxd9uo',
        linkMobile: 'https://www.youtube.com/embed/hOrhFBGAd1U',
      },
      {
        id: 7,
        title: 'Envio de dados, acompanhamento',
        link: 'https://www.youtube.com/embed/Ud5LcYGHrps',
        linkMobile: 'https://www.youtube.com/embed/HJqnnf6Zmk8',
      },
      {
        id: 8,
        title: 'Recebimento de dados geral e por fazenda/lote',
        link: 'https://www.youtube.com/embed/TegiOpEpANE',
        linkMobile: 'https://www.youtube.com/embed/1asi_CZKWIo',
      },
      {
        id: 9,
        title: 'Limpeza do banco de dados',
        link: 'https://www.youtube.com/embed/nU_sYYuRbvg',
        linkMobile: 'https://www.youtube.com/embed/buasGvP8Od8',
      },
    ],
  },
  {
    id: 4,
    title: 'Usuários',
    videos: [
      {
        id: 10,
        title: 'Criação e perfis',
        link: 'https://www.youtube.com/embed/FL4iK80pO1A',
        linkMobile: 'https://www.youtube.com/embed/zsVwxOZDE5U',
      },
    ],
  },
  {
    id: 5,
    title: 'Protocolos',
    videos: [
      {
        id: 11,
        title: 'Protocolos padrões',
        link: 'https://www.youtube.com/embed/pDNsoN0j_aY',
        linkMobile: 'https://www.youtube.com/embed/0_CcJz7HsMI',
      },
      {
        id: 12,
        title: 'Criando um novo protocolo',
        link: 'https://www.youtube.com/embed/Rfcvi64JVGY',
        linkMobile: 'https://www.youtube.com/embed/xSTUQzIH8NY',
      },
      {
        id: 13,
        title: 'Edição e exclusão de protocolos (regras)',
        link: 'https://www.youtube.com/embed/Ey6D4aSp40o',
        linkMobile: 'https://www.youtube.com/embed/w0W2Fr1vT-s',
      },
    ],
  },
  {
    id: 6,
    title: 'Touro e partida',
    videos: [
      {
        id: 14,
        title: 'Cadastro de Touro',
        link: 'https://www.youtube.com/embed/XTYL4KiWAL8',
        linkMobile: 'https://www.youtube.com/embed/xZTKizzrOGw',
      },
      {
        id: 15,
        title: 'Cadastro de partida',
        link: 'https://www.youtube.com/embed/3wyU1YwzxBg',
        linkMobile: 'https://www.youtube.com/embed/1YFoCalKzWc',
      },
      {
        id: 16,
        title: 'Inclusão de partidas em fazendas',
        link: 'https://www.youtube.com/embed/qSP2n5uUZ9g',
        linkMobile: 'https://www.youtube.com/embed/xCkcUE3im2s',
      },
    ],
  },
  {
    id: 7,
    title: 'Estações de monta',
    videos: [
      {
        id: 17,
        title: 'Introdução',
        link: 'https://www.youtube.com/embed/z2IXS9Q8om8',
        linkMobile: 'https://www.youtube.com/embed/VRyjFsFx2uo',
      },
      {
        id: 18,
        title:
          'Ferramenta de inicialização de estação de monta com reaproveitamento de fazendas',
        link: 'https://www.youtube.com/embed/OOkYNMNwaHA',
        linkMobile: 'https://www.youtube.com/embed/AUlHNZgOKx8',
      },
    ],
  },
  {
    id: 8,
    title: 'Fazenda',
    videos: [
      {
        id: 19,
        title: 'Introdução, relatórios e exportação',
        link: 'https://www.youtube.com/embed/pUz8SuxzvJw',
        linkMobile: 'https://www.youtube.com/embed/s8006ZnWt0s',
      },
      {
        id: 20,
        title: 'Cadastro de fazenda',
        link: 'https://www.youtube.com/embed/eNnbLB6-Q3Y',
        linkMobile: 'https://www.youtube.com/embed/6pm7ubrmioE',
      },
      {
        id: 21,
        title: 'Edição',
        link: 'https://www.youtube.com/embed/UO8GTvRuUII',
        linkMobile: 'https://www.youtube.com/embed/GzYlyqR54Zw',
      },
      {
        id: 22,
        title: 'Compartilhamento',
        link: 'https://www.youtube.com/embed/K_NAMvS0vWQ',
        linkMobile: 'https://www.youtube.com/embed/aWiRzOE6xP4',
      },
    ],
  },
  {
    id: 9,
    title: 'Retiro',
    videos: [
      {
        id: 23,
        title: 'Introdução, relatórios e exportação',
        link: 'https://www.youtube.com/embed/RWIISTBpIkg',
        linkMobile: 'https://www.youtube.com/embed/_mBCBLhD9aU',
      },
      {
        id: 24,
        title: 'Criação e edição',
        link: 'https://www.youtube.com/embed/n9CvihgyMj4',
        linkMobile: 'https://www.youtube.com/embed/Zbj2_V5uAYg',
      },
    ],
  },
  {
    id: 10,
    title: 'Lote',
    videos: [
      {
        id: 25,
        title: 'Criação, relatórios e exportação',
        link: 'https://www.youtube.com/embed/rP7_OTIUH6w',
        linkMobile: 'https://www.youtube.com/embed/u42_IPLFT_A',
      },
      {
        id: 26,
        title: 'Edição e exclusão',
        link: 'https://www.youtube.com/embed/sibkho68aPE',
        linkMobile: 'https://www.youtube.com/embed/zg-qfe9XfeE',
      },
      {
        id: 27,
        title: 'Mesclagem de lotes',
        link: 'https://www.youtube.com/embed/3qDvUZIxNRc',
        linkMobile: 'https://www.youtube.com/embed/-LL7PS_WzUY',
      },
    ],
  },
  {
    id: 11,
    title: 'Manejos',
    videos: [
      {
        id: 29,
        title: 'Introdução e etapas',
        link: 'https://www.youtube.com/embed/z9CNXPy4bgQ',
        linkMobile: 'https://www.youtube.com/embed/7Gyjw5u5uv4',
      },
      {
        id: 30,
        title: 'D0',
        link: 'https://www.youtube.com/embed/J4cxFmuSbPc',
        linkMobile: 'https://www.youtube.com/embed/kSh_y1SiwoI',
      },
      {
        id: 31,
        title: 'D0/IATF',
        link: 'https://www.youtube.com/embed/uRyhIJVXKKU',
        linkMobile: 'https://www.youtube.com/embed/4ymoAZWTYdA',
      },
      {
        id: 32,
        title: 'DNs',
        link: 'https://www.youtube.com/embed/a0lkxlqz3tI',
        linkMobile: 'https://www.youtube.com/embed/yvrP0AQRq_o',
      },
      {
        id: 33,
        title: 'IATF',
        link: 'https://www.youtube.com/embed/5CQeO4ma8Xg',
        linkMobile: 'https://www.youtube.com/embed/PUAR984T3Ho',
      },
      {
        id: 34,
        title: 'DG',
        link: 'https://www.youtube.com/embed/5xgXbCNsDWU',
        linkMobile: 'https://www.youtube.com/embed/hUdYIlLGTO8',
      },
      {
        id: 35,
        title: 'Ressinc',
        link: 'https://www.youtube.com/embed/i6dq_4n35LU',
        linkMobile: 'https://www.youtube.com/embed/qIZXhMeDKs0',
      },
      {
        id: 36,
        title: 'DG/DN',
        link: 'https://www.youtube.com/embed/avPA3Ld_xB0',
        linkMobile: 'https://www.youtube.com/embed/sJq1NBIjad4',
      },
      {
        id: 37,
        title: 'DG/D0',
        link: 'https://www.youtube.com/embed/P0Fbhi1NGGc',
        linkMobile: 'https://www.youtube.com/embed/zt0dughjVyw',
      },
      {
        id: 38,
        title: 'DG Final',
        link: 'https://www.youtube.com/embed/AEkWfW0wYxc',
        linkMobile: 'https://www.youtube.com/embed/ycr4q3WEjvQ',
      },
      {
        id: 39,
        title: 'Funcionalidade de preenchimento automático',
        link: 'https://www.youtube.com/embed/ctBtdjWatV8',
        linkMobile: 'https://www.youtube.com/embed/q-IqRcjkOAU',
      },
      {
        id: 40,
        title: 'Cadastro de partida na IATF',
        link: 'https://www.youtube.com/embed/UVw10pA4AkQ',
        linkMobile: 'https://www.youtube.com/embed/_QSCVAe0iRA',
      },
    ],
  },
  {
    id: 12,
    title: 'Visualização de dados',
    videos: [
      {
        id: 41,
        title: 'Introdução, regras',
        link: 'https://www.youtube.com/embed/pPkJTiXl6yU',
        linkMobile: 'https://www.youtube.com/embed/rW3PfCIYqsk',
      },
      {
        id: 42,
        title: 'Relatórios',
        link: 'https://www.youtube.com/embed/ml-0F45Lw2k',
        linkMobile: 'https://www.youtube.com/embed/reiyozLSiZw',
      },
      {
        id: 43,
        title: 'Exportação',
        link: 'https://www.youtube.com/embed/AJRUnpuWe6A',
        linkMobile: 'https://www.youtube.com/embed/tRljkkStCXI',
      },
    ],
  },
]

export const SelectedVideoToPage = (page, hasCorral = false) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const pageToIndexMap = {
    '/': 1,
    '/users': 3,
    '/protocols': 4,
    '/bulls/create-for-all/batch/none/management/none': 5,
    '/farms/estacaodemonta': 6,
    '/farms/create': 7,
  }
  const index = pageToIndexMap[page]

  if (page?.includes('/farms/') && page?.includes('/corrals/')) {
    return isSmallScreen
      ? itens[10].videos[0].linkMobile
      : itens[10].videos[0].link
  } else if (
    page?.includes('/farms/') &&
    !page?.includes('/estacaodemonta') &&
    !page?.includes('/create') &&
    !page?.includes('/corrals/') &&
    !hasCorral
  ) {
    return isSmallScreen
      ? itens[10].videos[0].linkMobile
      : itens[10].videos[0].link
  }
  else if (
    page?.includes('/farms/') &&
    !page?.includes('/estacaodemonta') &&
    !page?.includes('/create') &&
    !page?.includes('/corrals/') &&
    hasCorral
  ) {
    return isSmallScreen
      ? itens[8].videos[0].linkMobile
      : itens[8].videos[0].link
  }
  else if (
    page?.includes('/bulls/create-for-all/batch')
  ) {
    return isSmallScreen
      ? itens[5].videos[0].linkMobile
      : itens[5].videos[0].link
  } else if (index !== undefined) {
    return isSmallScreen
      ? itens[index].videos[0].linkMobile
      : itens[index].videos[0].link
  } else {
    return null
  }
}
