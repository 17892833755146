const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition)();

let isOn = false;


const SpeechRekognition = async (props) => {
  let result = null;

  if (!isOn) {
    result = await (new Promise((resolve, reject) => {
      recognition.lang = 'pt-BR';
      recognition.interimResults = false;
      recognition.maxAlternatives = 5;

      recognition.onresult = (e) => {
        recognition.stop();
        resolve(e.results[0][0].transcript);
      };

      recognition.onerror = (e) => {
        isOn = false;
        console.log("There was some error: ", e)
        if (e.error === "no-speech") {
          resolve(null)
        } else {
          reject(`There was some error:  ${e.error}`)
        }
      }

      recognition.onsoundend = function () {
        console.log('Sound has stopped being received');
        isOn = false;
      }


      try {
        recognition.start();
        isOn = true;
      } catch (e) {
        console.warn("SpeechRecognitionError", e);
      }

    }));

  } else {
    console.log("SpeechRecognition is already on");
  }

  return result;
}


export default SpeechRekognition;
