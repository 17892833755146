import * as CONSTANTS from './constants'

const initialState = {
  loggedIn: true,
  loginError: null,
  logged: null,
  shouldAcceptEULA: false,
  token: null
}

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.USER_LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loginError: null
      }
    case CONSTANTS.USER_LOGGED:
      return {
        ...state,
        logged: action.data
      }
    case CONSTANTS.USER_LOGIN_FAIL:
      return {
        ...state,
        loggedIn: false,
        loginError: action.error
      }
    case CONSTANTS.USER_LOGOUT:
      return {
        ...state,
        loggedIn: false,
        loginError: null
      }
    case CONSTANTS.SET_SHOULD_ACCEPT_EULA:
      return {
        ...state,
        shouldAcceptEULA: true,
        token: action.token
      }
    case CONSTANTS.DISMISS_SHOULD_ACCEPT_EULA:
      return {
        ...state,
        shouldAcceptEULA: false,
        token: null
      }
    default:
      return state
  }
}
