import React, { Component } from 'react'
import uuid from 'react-uuid'
import Grid from '@material-ui/core/Grid'
import MaterialTextField from '@material-ui/core/TextField'

import { REQUIRED_FIELD_MESSAGES } from '../../utils/formHelper'

function textfieldDefaultOnChange(e) {
  const inputValue = e.target.value
  const isEmpty = inputValue.trim() === ''
  const isWhitespaceOnly = /^\s*$/.test(inputValue.trim())

  this.setState({
    [e.target.name]: (isEmpty || isWhitespaceOnly) ? null : inputValue,
    [`${e.target.name}_error`]:
      (isEmpty ||
        isWhitespaceOnly ||
        this.missingRequiredFieldChecker(e.target.name, e.target.value)) &&
      REQUIRED_FIELD_MESSAGES.DEFAULT,
  })
}

function textfieldOnChangeWithCustomMessage(
  message = REQUIRED_FIELD_MESSAGES.DEFAULT
) {
  return (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}_error`]:
        this.missingRequiredFieldChecker(e.target.name, e.target.value) &&
        message,
    })
  }
}

class TextField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.id || uuid(),
    }
    this.onChange = this.onChange.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  onChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  onKeyDown(e) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
  }

  render() {
    const style = {
      width: '100%',
      backgroundColor: 'white',
      ...this.props.style,
    }
    const textfield = (
      <MaterialTextField
        autoComplete='off'
        id={this.state.id}
        name={this.state.id}
        label={this.props.label}
        variant='outlined'
        type={this.props.type || 'text'}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        value={this.props.value || ''}
        style={style}
        error={this.props.error}
        helperText={this.props.helperText}
        disabled={this.props.disabled}
        size={this.props.size}
        inputRef={this.props.inputRef}
        autoFocus={this.props.autoFocus}
        inputProps={{ tabIndex: this.props.tabIndex }}
      />
    )
    if (this.props.withGrid) {
      return (
        <Grid item xs={this.props.withGrid}>
          {textfield}
        </Grid>
      )
    }
    return textfield
  }
}

export { textfieldDefaultOnChange, textfieldOnChangeWithCustomMessage }

export default TextField
