/* eslint-disable consistent-return */
export class LocalStorageHelper {
  static add(key: string, value: any): void | Error {
    if (!key) {
      return new Error('Key not found')
    }
    if (!value) {
      return new Error('Value not found')
    }

    localStorage.setItem(key, value)
  }

  static get = (key: string): any => localStorage.getItem(key)

  static remove = (key: string): void => localStorage.removeItem(key)
}
