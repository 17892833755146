import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import { FAZENDA, LOTE, RETIRO, formatName } from '../../utils/formHelper'
import React, { Component } from 'react'

import Alert from '@material-ui/lab/Alert'
import BatchForm from './components/BatchForm'
import Container from '@material-ui/core/Container'
import MainContainer from '../../components/MainContainer'
import Prompt from './../../components/Prompt'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { getLoggedUser } from '../../redux/auth/actions'
import track from 'react-tracking'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

@track(() => ({ page: 'Edição de Lote', date: new Date() }))
class BatchUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: null
    }

    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onCancel() {
    this.props.farm.retiro
      ? this.props.history.push(
        `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
      )
      : this.props.history.push(`/farms/${this.props.farm.id}`)
  }

  @track((props, state, batch) => ({ action: 'Editou o lote', value: batch[0].nomeLote }))
  async onSubmit(payload) {
    const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)
    if (this.props.batch.nomeLote !== payload.nomeLote) {
      const result = await this.alredyExist(payload, repository)
      if (result.alredyExist) {
        window.scrollTo(0, 0)

        this.setState({
          errorMessage: result.message
        })

        return
      }
    }

    const updateRequest = await repository.update(payload)
    if (updateRequest.success) {
      console.log('Batch Updated!')
      if (this.props.farm.retiro) {
        this.props.history.push(
          `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
        )
      } else {
        this.props.history.push(`/farms/${this.props.farm.id}`)
      }
    } else {
      console.warn(
        `Ooops... there was an error updating batch ${this.props.batch.id}: ${updateRequest.exception}`
      )
    }
  }

  async alredyExist(payload, repository) {
    let batches = await repository.getByParam('corral_id', this.props.corral.id)
    let verifyBatches = false

    _.each(batches.response, batch => {
      if (batch.nomeLote === payload.nomeLote) {
        verifyBatches = true
      }
    })

    if (verifyBatches) {
      return {
        alredyExist: true,
        message: 'Já existe um lote com esse nome!'
      }
    }
    return { alredyExist: false, message: null }
  }

  render() {
    const initialValues = {
      ..._.pick(this.props.batch, [
        'nomeLote',
        'dataPartosInicio',
        'dataPartosFim',
        'usuarioId',
        'protocoloId',
        'dataIatf',
        'periodoManejo',
        'progesteronaPosIATF',
        'vacinaReprodutivaLote',
        'dispositivo',
        'numeroUsoDispositivo',
        'racaMatriz',
        'ordemMatriz',
        'idadeMatriz',
        'mineral',
        'momentoSuplementacaoNovilhas',
        'momentoSuplementacaoVacas',
        'racaBezerro',
        'sexoBezerro',
        'inducaoPuberdade',
        'metodoInducaoPuberdade',
        'retiroId',
        'isResync',
        'horaManejo'
      ]),
      nextDay: this.props.nextDay,
      prevDay: this.props.prevDay,
      iatfCount: this.props.iatfCount
    }
    const { errorMessage } = this.state

    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={formatName(this.props.batch.nomeLote, LOTE)}
          paths={[
            {
              route: `/farms/${this.props.farm.id}`,
              label: formatName(this.props.farm.nome, FAZENDA)
            },
            ...(this.props.paths || [])
          ]}
        />

        <MainContainer>
          {errorMessage && (
            <Alert
              severity='error'
              style={{
                width: '100%',
                marginTop: '1em'
              }}
            >
              {errorMessage}
            </Alert>
          )}
          <BatchForm
            initialValues={initialValues}
            onCancel={this.onCancel}
            onSubmit={this.onSubmit}
            farm={this.props.farm}
            corral={this.props.corral}
            user={this.props.user}
            protocols={this.props.protocolos}
            relatedUsers={this.props.relatedUsers}
            batch={this.props.batch}
          />
          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false
              },
            ]}
          />
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async props => {
  const user = await getLoggedUser()
  const days = await utils.getDomainValuesBy('Dias')
  const batch = await utils.getElement(
    AVAILABLE_ENTITIES.BATCHES,
    props.match.params.id
  )

  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    props.match.params.farmId
  )
  const corral = await utils.getElement(
    AVAILABLE_ENTITIES.CORRALS,
    props.match.params.corralId
  )

  let paths = [
    {
      label: formatName(batch.nomeLote, LOTE)
    }
  ]

  if (farm.retiro) {
    paths.unshift({
      route: `/farms/${farm.id}/corrals/${corral.id}`,
      label: formatName(corral.nome, RETIRO)
    })
  }
  let batchList = [batch]

  let currentBatch = _.last(batchList)

  const protocol = await utils.getElement(
    AVAILABLE_ENTITIES.PROTOCOLS,
    currentBatch.protocoloId
  )

  const protocolDays = protocol
    ? _.sortBy(
      _.uniq(
        protocol.managementProtocols.map(managementProtocol =>
          _.find(days, { id: managementProtocol.day })
        )
      ),
      [
        dia =>
          dia.valor.startsWith('D') ? parseInt(dia.valor.substring(1)) : 100
      ]
    )
    : []
  const vacas = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    currentBatch.id
  )


  const currentDay =
    protocolDays[_.findIndex(protocolDays, { id: currentBatch.dias }) + 1]

  const prevDay =
    protocolDays[_.findIndex(protocolDays, { id: currentBatch.dias })]

  let currentIatf = -1
  console.warn(vacas)
  _.forEach(vacas, vaca => {
    if (vaca.iatf > currentIatf) {
      currentIatf = vaca.iatf
    }
  })

  return {
    batch,
    protocolos: _.sortBy(
      (await utils.getAll(AVAILABLE_ENTITIES.PROTOCOLS)).filter(
        x => x.veterinary_id === user.userId || !x.veterinary_id
      ),
      [protocolo => protocolo.name.toLowerCase()]
    ),
    farm,
    nextDay: currentDay,
    prevDay: prevDay,

    relatedUsers: _.sortBy(
      await utils.getWithRelation(
        AVAILABLE_ENTITIES.USERS,
        'farms__rel__users',
        'farm_id',
        props.match.params.farmId
      ),
      [user => user.nomeCompleto.toLowerCase()]
    ),
    user,
    corral,
    paths,
    iatfCount: currentIatf === -1 ? '-' : currentIatf + 1
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(BatchUpdate)
)
