import { CELL_TYPES } from '../../components/ResponsiveTable'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Prompt from '../../components/Prompt'
import React from 'react'
import { SERVICES_PORTS_MAP } from '../../redux/api/constants'
import _ from 'lodash'
import axios from 'axios'
import { getLoggedUser } from '../../redux/auth/actions'

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}:${SERVICES_PORTS_MAP['domains']}/api`,
})

export const fetchData = async () => {
  const { token } = await getLoggedUser()
  const params = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const {
    data: { responseList: domainType },
  } = await api.get('/TipoDominio', params)

  return {
    domainType: sortDomainTypeToValue(
      domainType.map((item) => ({ ...item, valor: item.nome }))
    ),
  }
}

export const getDomainTypeTableData = (setActions, setFormData) => {
  return [
    {
      name: 'Nome',
      type: CELL_TYPES.TEXT,
      sortBy: true,
      label: (row) => row.nome,
    },
    {
      name: '',
      type: CELL_TYPES.TEXT,
      label: () => '',
    },
    {
      type: CELL_TYPES.BUTTON_ARR,
      buttons: (row) =>
        _.compact([
          {
            icon: <EditIcon />,
            label: 'Editar',
            onClick: () => {
              setActions({ domainTypeEdit: true })
              setFormData(row)
            },
          },
          {
            icon: <DeleteIcon />,
            label: 'Excluir',
            onClick: () => {
              setActions({ domainTypeDelete: true })
              setFormData({ id: row.id })
            },
          },
        ]),
    },
  ]
}

export const getModals = (actions, setActions, formData, handleDelete) => {
  if (actions.domainTypeModalAfterSubmitOrEdit) {
    return (
      <Prompt
        visible={actions.domainTypeModalAfterSubmitOrEdit}
        title='Aviso importante!'
        message='Para que as alterações tenham efeito, é necessário sincronizar os dados do ProGerar. Sincronize na tela inicial do ProGerar.'
        buttons={[
          {
            label: 'Ok',
            onClick: () => setActions({}),
          },
        ]}
      />
    )
  } else if (actions.domainTypeDelete) {
    return (
      <Prompt
        visible={actions.domainTypeDelete}
        title='Deletar Tipo Dominio'
        message='Ao fazer isso o seu Tipo Dominio vai ser excluído do ProGerar. Tem certeza que deseja continuar?'
        buttons={[
          {
            label: 'Fechar',
            onClick: () => setActions({}),
          },
          {
            label: 'Deletar',
            onClick: () => {
              handleDelete('/TipoDominio', formData.id)
              setActions({})
            },
          },
        ]}
      />
    )
  }
}

export const sortDomainTypeToValue = (array) => {
  return array.sort((a, b) => {
    if (a.nome.toLowerCase() > b.nome.toLowerCase()) {
      return 1
    }

    if (a.nome.toLowerCase() < b.nome.toLowerCase()) {
      return -1
    }

    return 0
  })
}
