import { date, field, readonly, relation } from '@nozbe/watermelondb/decorators'
import { Model } from '@nozbe/watermelondb'

export const modelToDTOMapping = {
  identical: [
    'codVaca',
    'codLeitorBastao',
    'observacaoDG',
    'observacao_DG_Final',
    'omit_from_dg_final'
  ],
  renamed: [
    {
      dtoName: 'peso_EM',
      modelName: 'peso_EM',
      isNumber: true
    },
    {
      dtoName: 'dataPartoIndividual',
      modelName: 'dataPartoIndividual',
      isDate: true
    },
    {
      dtoName: 'dataProcesso',
      modelName: 'dataProcesso_at',
      isDate: true
    },
    {
      dtoName: 'iatf',
      modelName: 'iatf',
      isNumber: true,
      numberType: 'INTEGER',
    },
    {
      dtoName: 'dataDg',
      modelName: 'dataDG_at',
      isDate: true
    },
    {
      dtoName: 'dataDgFinal',
      modelName: 'dataDGFinal_at',
      isDate: true
    },
    {
      dtoName: 'pesoBezerro',
      modelName: 'pesoBezerro',
      isNumber: true
    },
    {
      dtoName: 'observacaoD0',
      modelName: 'observacao'
    },
    {
      dtoName: 'diasGestacaoTouro',
      modelName: 'diasGestacaoTouro',
      isNumber: true,
      numberType: 'INTEGER'
    },
    {
      dtoName: 'perdaGestacao',
      modelName: 'perdaGestacao',
    },
  ],
  references: [
    {
      dtoName: 'ecc',
      modelName: 'ecc_id'
    },
    {
      dtoName: 'racaMatriz',
      modelName: 'racaMatriz_id'
    },
    {
      dtoName: 'ordemMatriz',
      modelName: 'ordemMatriz_id'
    },
    {
      dtoName: 'racaBezerro',
      modelName: 'racaBezerro_id'
    },
    {
      dtoName: 'sexoBezerro',
      modelName: 'sexoBezerro_id'
    },
    {
      dtoName: 'idade_Nov_Pri',
      modelName: 'idade_Nov_Pri_id'
    },
    {
      dtoName: 'dispositivoIntravaginal',
      modelName: 'dispositivo_id'
    },
    {
      dtoName: 'numero_Uso_Disp',
      modelName: 'numero_Uso_Disp_id'
    },
    {
      dtoName: 'condicaoDG',
      modelName: 'condicaoDG_id'
    },
    {
      dtoName: 'tipoPrenhe',
      modelName: 'tipoPrenhe_id'
    },
    {
      dtoName: 'tipoPrenheFinal',
      modelName: 'tipoPrenheFinal_id'
    },
    {
      dtoName: 'clVazia',
      modelName: 'clVazia_id'
    },
    {
      dtoName: 'clD0',
      modelName: 'clDzero_id'
    },
    {
      dtoName: 'eccDG',
      modelName: 'eccDG_id'
    },
    {
      dtoName: 'condicaoDGFinal',
      modelName: 'condicaoDGFinal_id'
    },
    {
      dtoName: 'ecC_Final',
      modelName: 'ecc_final_id'
    },
    {
      dtoName: 'loteId',
      modelName: 'batch_id'
    },
    {
      dtoName: 'responsavelId',
      modelName: 'responsavel_id'
    },
    {
      dtoName: 'dzeroPai',
      modelName: 'parentDZero_id'
    },
    {
      dtoName: 'dzeroOriginal',
      modelName: 'originalDZero_id'
    },
    {
      dtoName: 'clFinal',
      modelName: 'clFinal_id'
    },
  ]
}

export default class D0 extends Model {
  static table = 'd0s'

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('codVaca') codVaca
  @field('codLeitorBastao') codLeitorBastao
  @date('dataPartoIndividual') dataPartoIndividual
  @field('peso_EM') pesoEm
  @field('observacao') observacaoD0
  @date('dataProcesso_at') dataProcessoAt
  @field('iatf') iatf
  @field('observacaoDG') observacaoDG
  @date('dataDG_at') dataDGAt
  @date('dataDGFinal_at') dataDGFinalAt
  @field('observacao_DG_Final') observacaoDGFinal
  @field('pesoBezerro') pesoBezerro
  @field('diasGestacaoTouro') diasGestacaoTouro
  @field('perdaGestacao') perdaGestacao

  //Foreign Relations - Domain Values
  @relation('domain_values', 'ecc_id') ecc
  @relation('domain_values', 'racaMatriz_id') racaMatriz
  @relation('domain_values', 'ordemMatriz_id') ordemMatriz
  @relation('domain_values', 'racaBezerro_id') racaBezerro
  @relation('domain_values', 'sexoBezerro_id') sexoBezerro
  @relation('domain_values', 'idade_Nov_Pri_id') idadeNovPri
  @relation('domain_values', 'dispositivo_id') dispositivo
  @relation('domain_values', 'numero_Uso_Disp_id') numeroUsoDisp
  @relation('domain_values', 'condicaoDG_id') condicaoDG
  @relation('domain_values', 'tipoPrenhe_id') tipoPrenhe
  @relation('domain_values', 'tipoPrenheFinal_id') tipoPrenheFinal
  @relation('domain_values', 'clVazia_id') CLVazia
  @relation('domain_values', 'clDzero_id') CLD0
  @relation('domain_values', 'eccDG_id') eccDG
  @relation('domain_values', 'condicaoDGFinal_id') condicaoDGFinal
  @relation('domain_values', 'ecc_final_id') eccFinal
  @relation('domain_values', 'clFinal_id') CLFinal

  //Foreign Relations
  @relation('users', 'responsavel_id') responsavelId
  @relation('batches', 'batch_id') batch
  @relation('d0s', 'parentDZero_id') parentDZero
  @relation('d0s', 'originalDZero_id') originalDZero

  @field('omit_from_dg_final') omitFromDGFinal
}
