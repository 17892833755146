import React from 'react'

import Button from './material/Button'

type CardToChangeCowsProps = {
  text: string
  buttonText: string
  onClick: () => void
}

const CardToChangeCowsContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '0.6rem',
  border: '1px solid #ea5d0d',
  borderRadius: 8,
  padding: '0.6rem',
}

const CardToChangeCows = ({
  text,
  buttonText,
  onClick,
}: CardToChangeCowsProps) => (
  <div style={CardToChangeCowsContainer}>
    {text}
    <Button secondary small onClick={onClick} label={buttonText} />
  </div>
)

export default CardToChangeCows
