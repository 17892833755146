import { date, field, readonly, relation } from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'

export const modelToDTOMapping = {
  identical: ['nomeLote', 'mineral', 'isResync', 'isFinalize', 'horaManejo', 'processoAtual'],
  renamed: [
    {
      dtoName: 'dataPartosInicio',
      modelName: 'dataPartosInicio_at',
      isDate: true
    },
    {
      dtoName: 'dataPartosFim',
      modelName: 'dataPartosFim_at',
      isDate: true
    },
    {
      dtoName: 'dataIatf',
      modelName: 'dataIatf_at',
      isDate: true
    }
  ],
  references: [
    {
      dtoName: 'dispositivo',
      modelName: 'dispositivo_id'
    },
    {
      dtoName: 'reservedBy',
      modelName: 'reserved_by'
    },
    {
      dtoName: 'matrizBloqueada',
      modelName: 'Matriz_Bloqueada'
    },
    {
      dtoName: 'numeroUsoDispositivo',
      modelName: 'numeroUsoDispositivo_id'
    },
    {
      dtoName: 'racaMatriz',
      modelName: 'racaMatriz_id'
    },
    {
      dtoName: 'ordemMatriz',
      modelName: 'ordemMatriz_id'
    },
    {
      dtoName: 'idadeMatriz',
      modelName: 'idadeMatriz_id'
    },
    {
      dtoName: 'racaBezerro',
      modelName: 'racaBezerro_id'
    },
    {
      dtoName: 'sexoBezerro',
      modelName: 'sexoBezerro_id'
    },

    {
      dtoName: 'inducaoPuberdade',
      modelName: 'inducaoPuberdade_id'
    },
    {
      dtoName: 'metodoInducaoPuberdade',
      modelName: 'metodoInducaoPuberdade_id'
    },
    {
      dtoName: 'suplementacao',
      modelName: 'suplementacao_id'
    },
    {
      dtoName: 'momentoSuplementacaoNovilhas',
      modelName: 'momentoSuplementacaoNovilhas_id'
    },
    {
      dtoName: 'momentoSuplementacaoVacas',
      modelName: 'momentoSuplementacaoVacas_id'
    },
    {
      dtoName: 'progesteronaPosIATF',
      modelName: 'progesteronaPosIATF_id'
    },
    {
      dtoName: 'vacinaReprodutivaLote',
      modelName: 'vacinaReprodutivaLote_id'
    },
    {
      dtoName: 'periodoManejo',
      modelName: 'periodoManejo_id'
    },
    {
      dtoName: 'dias',
      modelName: 'dias_id'
    },
    {
      dtoName: 'tipoResync',
      modelName: 'tipoResync_id'
    },
    {
      dtoName: 'diaInicioResync',
      modelName: 'diaInicioResync_id'
    },
    {
      dtoName: 'usuarioId',
      modelName: 'user_id'
    },
    {
      dtoName: 'retiroId',
      modelName: 'corral_id'
    },
    {
      dtoName: 'protocoloId',
      modelName: 'protocol_id'
    },
    {
      dtoName: 'parentBatchId',
      modelName: 'parentBatch_id'
    },
    {
      dtoName: 'originalBatchId',
      modelName: 'originalBatch_id'
    }
  ]
}

export default class Batches extends Model {
  static table = 'batches'

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted
  @field('priority') priority

  //Fields - DTO
  @field('nomeLote') nomeLote
  @date('dataPartosInicio_at') dataPartosInicio
  @field('horaManejo') horaManejo
  @date('dataPartosFim_at') dataPartosFim
  @date('dataIatf_at') dataIatfAt
  @field('mineral') mineral
  @field('isResync') isResync
  @field('isFinalize') isFinalize
  @field('Matriz_Bloqueada') matrizBloqueada
  @field('processoAtual') processoAtual
  @field('reserved_by') reservedBy

  //Foreign Relations - Domain Values
  @relation('domain_values', 'dispositivo_id') dispositivo
  @relation('domain_values', 'numeroUsoDispositivo_id') numeroUsoDispositivo
  @relation('domain_values', 'racaMatriz_id') racaMatriz
  @relation('domain_values', 'ordemMatriz_id') ordemMatriz
  @relation('domain_values', 'idadeMatriz_id') idadeMatriz
  @relation('domain_values', 'racaBezerro_id') racaBezerro
  @relation('domain_values', 'sexoBezerro_id') sexoBezerro
  @relation('domain_values', 'inducaoPuberdade_id') inducaoPuberdade
  @relation('domain_values', 'metodoInducaoPuberdade_id') metodoInducaoPuberdade
  @relation('domain_values', 'suplementacao_id') suplementacao
  @relation('domain_values', 'momentoSuplementacaoNovilhas_id')
  momentoSuplementacaoNovilhas
  @relation('domain_values', 'momentoSuplementacaoVacas_id')
  momentoSuplementacaoVacas
  @relation('domain_values', 'progesteronaPosIATF_id') progesteronaPosIATF
  @relation('domain_values', 'vacinaReprodutivaLote_id') castleMaster
  @relation('domain_values', 'periodoManejo_id') periodoManejo
  @relation('domain_values', 'dias_id') dias
  @relation('domain_values', 'tipoResync_id') tipoResync
  @relation('domain_values', 'diaInicioResync_id') diaInicioResync

  @relation('users', 'user_id') user
  @relation('corrals', 'corral_id') corral
  @relation('protocols', 'protocol_id') protocol
  @relation('batches', 'parentBatch_id') parentBatch
  @relation('batches', 'originalBatch_id') originalBatch
}
