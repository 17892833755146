import { date, field, readonly, relation } from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'

export const modelToDTOMapping = {
  identical: [],
  renamed: [],
  references: [
    {
      dtoName: 'idFazenda',
      modelName: 'farm_id'
    },
    {
      dtoName: 'idUsuario',
      modelName: 'user_id'
    }
  ]
}

export default class FarmUsers extends Model {
  static table = 'farms__rel__users'
  static associations = {
    farms: { type: 'belongs_to', key: 'farm_id' },
    users: { type: 'belongs_to', key: 'user_id' }
  }

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Foreign Relations
  @relation('farms', 'farm_id') farm
  @relation('users', 'user_id') user
}
