import Autocomplete, {
  autocompleteDefaultOnChange,
  autocompleteDefaultOnChangeSavingID,
  autocompleteDomainValueOptionLabelSavingID,
} from '../components/material/Autocomplete'
import React, { Component, Fragment } from 'react'

import Alert from '@material-ui/lab/Alert'
import Button from './material/Button'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import MicIcon from '@material-ui/icons/Mic'
import FilterListIcon from '@material-ui/icons/FilterList';
import SpeechRecognition from './SpeechRecognition'
import TextField from '@material-ui/core/TextField'
import { version } from '../version'
import Prompt from './Prompt'
import CardToChangeCows from './CardToChangeCows'

class MainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showingChangeCowsToBatchModal: false,
    }
    this.handleVoiceRecognition = this.handleVoiceRecognition.bind(this)

    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)
    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
  }

  async handleVoiceRecognition() {
    try {
      const resposta = await SpeechRecognition()
      if (resposta !== null) {
        this.setState({ busca: resposta })

        const evt = { target: { name: 'busca', id: 'busca', value: resposta } }
        this.props.handleSearch(evt)
        document.getElementById('busca').value = resposta
        document
          .getElementById('busca-label')
          .classList.add('MuiFormLabel-filled')
        document
          .getElementById('busca-label')
          .classList.add('MuiInputLabel-shrink')
      }
    } catch (e) {
      console.log(e)
    }
  }

  renderTopInfo() {
    return (
      (this.props.containerTitle || this.props.containerSubtitle) && (
        <Grid
          container
          className='app-main-container-top'
          style={{ padding: 0 }}
        >
          {this.props.containerTitle && (
            <Grid item xs={5} md={9}>
              <h3>{this.props.containerTitle}</h3>
            </Grid>
          )}
          {this.props.containerSubtitle && (
            <Grid
              item
              xs={7}
              className='p-flex-1 p-display-flex p-justify-flex-end'
            >
              <h3>{this.props.containerSubtitle}</h3>
            </Grid>
          )}
        </Grid>
      )
    )
  }

  renderSearch({
    hasSearch,
    hasSearchWithoutFocus,
    hasSearchWithFilter,
    useVoiceRecognition,
    hasSearchWithFilterButtton,
    alert,
    includeDivider,
    focusSearch,
  }) {
    return (
      this.renderSimpleSearch(
        hasSearch,
        useVoiceRecognition,
        alert,
        includeDivider,
        focusSearch
      ) ||
      this.renderSearchWithoutFocus(
        hasSearchWithoutFocus,
        useVoiceRecognition,
        alert,
        includeDivider
      ) ||
      this.renderSearchWithFilter(
        hasSearchWithFilter,
        hasSearchWithFilterButtton,
        useVoiceRecognition,
        alert,
        includeDivider
      )
      ||
      this.renderSearchWithFilterButtton(
        hasSearchWithFilterButtton,
        useVoiceRecognition,
        alert,
        includeDivider
      )
    )
  }

  renderSimpleSearch(hasSearch, voice, alert, includeDivider, focusSearch) {
    const breakpoints = `p-${voice ? '9' : '12'} t-${voice ? '10' : '12'} d-${voice ? '11' : '12'
      }`
    return (
      hasSearch && (
        <div className='grid'>
          <div className={'grid-item p-margin-bottom-2 ' + breakpoints}>
            {focusSearch && document.getElementById('busca').focus()}
            {this.renderSearchInput()}
          </div>
          {voice && this.renderVoiceRecognitionButton(3, 2, 1)}
          {alert && this.renderAlert(alert)}
          {includeDivider && <Divider style={{ marginTop: '1em' }} />}
        </div>
      )
    )
  }

  renderSearchWithoutFocus(
    hasSearchWithoutFocus,
    voice,
    alert,
    includeDivider
  ) {
    const breakpoints = `p-${voice ? '10' : '12'} t-${voice ? '10' : '12'} d-${voice ? '11' : '12'
      }`
    return (
      hasSearchWithoutFocus && (
        <div className='grid'>
          <div className={'grid-item p-margin-bottom-2 ' + breakpoints}>
            {this.renderSearchInput(true)}
          </div>
          {voice && this.renderVoiceRecognitionButton(2, 2, 1)}
          {alert && this.renderAlert(alert)}
          {includeDivider && <Divider style={{ marginTop: '1em' }} />}
        </div>
      )
    )
  }

  renderSearchWithFilter(hasSearchWithFilter, filterButtton, voice, alert, includeDivider) {
    const inputbreakpoint = ` p-${voice || filterButtton ? '10' : '12'} t-${voice || filterButtton ? '5' : '6'
      } d-${voice || filterButtton ? '9' : '10'}`
    const filterbreakpoint = ` p-${voice || filterButtton ? '12' : '12'}  t-${voice || filterButtton ? '5' : '6'
      } d-${voice || filterButtton ? '2' : '2'}`

    return (
      hasSearchWithFilter && (
        <div className='grid'>
          <div className={'grid-item p-margin-bottom-2' + inputbreakpoint}>
            {this.renderSearchInput()}
          </div>
          {voice && this.renderVoiceRecognitionButton(2, 2, 1)}

          <div className={'grid-item p-margin-bottom-2' + filterbreakpoint}>
            {this.renderSearchFilter()}
          </div>

          {filterButtton && (
            <div className={'grid-item p-2 t-2 d-1'}
              style={{ textAlign: 'right' }}
            >
              <Button
                secondary
                tabIndex={this.props.hasValue ? -1 : null}
                small
                style={{
                  height: '100%',
                  maxHeight: '54px',
                  width: '100%',
                }}
                onClick={this.props.handleButtonFilter}
                label={<FilterListIcon />}
              />
            </div>
          )}

          {alert && this.renderAlert(alert)}
          {includeDivider && <Divider style={{ marginTop: '1em' }} />}
        </div>
      )
    )
  }


  renderSearchWithFilterButtton(hasSearchWithFilterButtton, voice, alert, includeDivider) {
    const inputbreakpoint = ` p-${voice ? '10' : '12'} t-${voice ? '5' : '6'
      } d-${voice ? '9' : '10'}`
    const filterbreakpoint = ` p-${voice ? '12' : '12'}  t-${voice ? '5' : '6'
      } d-${voice ? '2' : '2'}`

    return (
      hasSearchWithFilterButtton && (
        <div className='grid'>
          <div className={'grid-item p-margin-bottom-2' + inputbreakpoint}>
            {this.renderSearchInput()}
          </div>
          {voice && this.renderVoiceRecognitionButton(2, 2, 1)}

          <div className={'grid-item p-margin-bottom-2' + filterbreakpoint}>
            <Button
              secondary
              tabIndex={this.props.hasValue ? -1 : null}
              label={'Filtros'}
              small
              style={{
                height: '100%',
                maxHeight: '54px',
                width: '100%',
              }}
              onClick={this.props.handleButtonFilter}
              startIcon={<FilterListIcon />}
            />
          </div>
          {alert && this.renderAlert(alert)}
          {includeDivider && <Divider style={{ marginTop: '1em' }} />}
        </div>
      )
    )
  }

  renderVoiceRecognitionButton(xs = 12, sm = 12, md = 12) {
    return (
      <div
        className={`grid-item p-${xs} t-${sm} d-${md}`}
        style={{ textAlign: 'right' }}
      >
        <Button
          secondary
          tabIndex={this.props.hasValue ? -1 : null}
          small
          style={{
            height: '100%',
            maxHeight: '54px',
            width: '100%',
          }}
          onClick={this.handleVoiceRecognition}
          label={<MicIcon />}
          className='search-voice-icon'
        />
      </div>
    )
  }

  renderAlert(alert) {
    return (
      <>
        <Alert
          icon={false}
          style={{
            width: '100%',
            marginTop: '1em',
            marginBottom: '1em',
            backgroundColor: 'rgb(234, 93, 13)',
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          {alert}
        </Alert>
        {this.props.hasChangeCowsToBatch &&
          !this.props.alert?.includes("Há mais de um animal registrado com esse ID.") &&
          this.props.cowsValues?.length === 1 && (
            <CardToChangeCows
              text='Deseja mover essa matriz para esse lote?'
              buttonText='Mover'
              onClick={() =>
                this.props.changeCowsToBatch(this.props.cowsValues[0])
              }
            />
          )}
        {this.props.hasChangeCowsToBatch &&
          this.props.alert?.includes("Há mais de um animal registrado com esse ID.") &&
          this.props.cowsValues?.length >= 1 && (
            <>
              <CardToChangeCows
                text='Deseja mover alguma dessas matrizes?'
                buttonText='Clique aqui'
                onClick={() =>
                  this.setState({ showingChangeCowsToBatchModal: true })
                }
              />
              <Prompt
                visible={this.state.showingChangeCowsToBatchModal}
                title='Animais registados com esse ID:'
                message={this.props.cowsValues?.map((item) => (
                  <CardToChangeCows
                    key={item.id}
                    text={`Deseja mover a ${item.codVaca} do Lote ${item.nomeLote} para esse lote?`}
                    buttonText='Mover'
                    onClick={() => this.props.changeCowsToBatch(item)}
                  />
                ))}
                buttons={[
                  {
                    label: 'Cancelar',
                    onClick: () =>
                      this.setState({ showingChangeCowsToBatchModal: false }),
                  },
                ]}
              />
            </>
          )}
      </>
    )
  }

  renderSearchInput(disableAutoFocus) {
    return (
      <TextField
        autoComplete='off'
        autoFocus={!disableAutoFocus}
        id='busca'
        label={this.props.searchLabel || 'Buscar'}
        name='busca'
        type='text'
        value={this.state.busca || ''}
        onFocus={() => {
          this.props.clearSearch && this.setState({ busca: '' })
        }}
        variant='outlined'
        size='medium'
        onChange={(e, value) => {
          this.setState({ busca: e.target.value })
          this.props.handleSearch(e, value)
        }}
        style={
          this.props.removeMarginTop
            ? { display: 'block', marginTop: '-32px' }
            : { display: 'block' }
        }
      />
    )
  }

  renderSearchFilter() {
    return (
      <Autocomplete
        id={`filter`}
        options={this.props.filterProps}
        onChange={(e, value) => {
          this.autocompleteDefaultOnChange('filterProps')
          this.props.handleFilter(value)
        }}
        value={this.props.defaultFilterValue || null}
        getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
          this.props.filterProps
        )}
        useDefaultOptionSelected
        label={this.props.filterLabel}
      />
    )
  }

  render() {
    return (
      <Fragment>
        <Container className='app-main-container' style={this.props.customStyle}>
          {this.renderTopInfo()}
          {this.props.preSearchChildren}
          {this.renderSearch(this.props)}

          <section className='app-main-container-content'>
            {this.props.children}
          </section>
        </Container>

        <div className='hidewhenprinting'>
          <Container
            style={{
              marginTop: '2em',
              borderRadius: this.props.borderRadius
                ? this.props.borderRadius
                : 20,
              marginBottom: '3em',
              textAlign: 'center',
              color: '#CCC',
              fontSize: '12px',
            }}
          >
            {!this.props.skipVersion && <>{`Versão: ${version}`}</>}
            <br />
          </Container>
        </div>
      </Fragment>
    )
  }
}

export default MainContainer
