import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import Button from './material/Button'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '4px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: 'rgb(234,93,13)',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

export default function DropdownButton({ ...props }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label={props.label}
        style={{ marginRight: 10 }}
        endIcon={
          window.innerWidth > 767 && (
            <ArrowDropDownIcon
              style={{
                marginLeft: 6,
                marginRight: -10,
              }}
            />
          )
        }
      ></Button>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.buttons.map((button) => (
          <StyledMenuItem
            key={button.label}
            onClick={() => {
              handleClose()
              button.onClick()
            }}
          >
            <ListItemText primary={button.label} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  )
}
