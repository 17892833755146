import * as api from '../../redux/api/actions.js'

import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'

import Alert from '@material-ui/lab/Alert'
import Backdrop from '@material-ui/core/Backdrop'
import Box from '@material-ui/core/Box'
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import LoginBackground from '../../assets/img/login-background.jpg'
import TextField from '@material-ui/core/TextField'
import { ThemeProvider } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import ZoetisLogo from '../../assets/img/logo_corte.png'
import { connect } from 'react-redux'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import { forgotPassword } from '../../redux/auth/actions.js'
import { isValidEmail } from '../../redux/common/utils'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#EA5D0D'
    }
  }
})

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      error: false,
      errorMessage: '',
      isDisconnected: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = event => {
    const {
      target: { name, value }
    } = event
    this.setState(() => ({ [name]: value }))
  }

  async handleSubmit() {
    this.handleConnectionChange()
    const result = await this.props.forgotPassword(this.state)
    if (result.hasErrors) {
      this.setState({ errorMessage: result.title })
    } else {
      this.props.history.push('/login')
    }
  }

  canSubmit() {
    return isValidEmail(this.state.email)
  }

  keyHandler = e => {
    if (e.key === 'Enter' && this.canSubmit() && this.state.online) {
      this.handleSubmit()
    }
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline'
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors'
        })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing)
            })
          })
          .catch(() => this.setState({ isDisconnected: true }))
      }, 2000)
      return
    }

    return this.setState({ isDisconnected: true })
  }

  render() {
    const { isDisconnected, errorMessage } = this.state

    if (this.props.loading) {
      return (
        <Backdrop open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid
          container
          style={{
            backgroundColor: 'rgba(234, 234, 234, 0.27)'
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{
              // backgroundColor: '#EA5D0D',
              backgroundImage: `url(${LoginBackground})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundBlendMode: 'multiply',
              backgroundPositionX: '-5vw'
            }}
          />
          <Grid item xs={12} md={6}>
            <Container maxWidth='xs'>
              <Typography
                component='div'
                style={{ marginTop: '100px', height: '100vh' }}
              >
                <img
                  src={ZoetisLogo}
                  alt='Zoetis Gerar'
                  style={{
                    width: '100%'
                  }}
                />

                {this.props.success && (
                  <Alert
                    severity='success'
                    style={{
                      marginTop: '1em'
                    }}
                  >
                    O email para recuperação de senha foi enviado. Caso não
                    receba, clique em enviar novamente!
                  </Alert>
                )}

                {this.props.error && (
                  <Alert
                    severity='error'
                    style={{
                      marginTop: '1em'
                    }}
                  >
                    {errorMessage}
                  </Alert>
                )}

                {isDisconnected && (
                  <Alert
                    severity='error'
                    style={{
                      marginTop: '1em'
                    }}
                  >
                    Não é possível utilizar este recurso sem internet. Retorne
                    quando houver conexão com a internet!
                  </Alert>
                )}

                <Container disableGutters>
                  <Container
                    disableGutters
                    style={{
                      marginTop: '1em'
                    }}
                  >
                    <TextField
                      autoComplete='off'
                      id='login-form-email'
                      label='E-mail'
                      name='email'
                      variant='outlined'
                      type='text'
                      onChange={this.handleChange}
                      style={{
                        width: '100%',
                        backgroundColor: 'white'
                      }}
                      required
                      onKeyUp={this.keyHandler}
                      value={this.state.email}
                      error={this.state.error}
                      helperText={this.state.errorMessage}
                    />
                  </Container>

                  <Container
                    disableGutters
                    style={{
                      marginTop: '1em'
                    }}
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      disableElevation
                      size='large'
                      style={{
                        width: '100%'
                      }}
                      disabled={!this.canSubmit()}
                      onClick={this.handleSubmit}
                    >
                      Enviar
                    </Button>
                  </Container>

                  <Box
                    fontWeight='fontWeightLight'
                    style={{
                      marginTop: '1em',
                      textAlign: 'center'
                    }}
                  >
                    <Link
                      to='/login'
                      style={{
                        color: '#333',
                        textDecoration: 'none'
                      }}
                    >
                      voltar
                    </Link>
                  </Box>
                </Container>
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </ThemeProvider>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: params => dispatch(forgotPassword(params))
  }
}

const mapStateToProps = state => ({
  loading: api.getApiLoading(state, `Account/ForgotPassword`),
  error: api.getApiError(state, `Account/ForgotPassword`) || false,
  success: api.getApiResult(state, `Account/ForgotPassword`) || false
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ForgotPassword))
