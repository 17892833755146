/* eslint-disable react/react-in-jsx-scope */
import _ from 'lodash'
import moment from 'moment'
import { saveAs } from 'file-saver'

import { LocalStorageHelper } from '../../utils/localStorageHelper'
import { TimeoutError, timeoutFetch } from '../api/utils'
import { getLoggedUser, refreshToken } from '../auth/actions'
import { api } from '../api/actions'
import * as API_CONSTANTS from '../api/constants'
import * as CONSTANTS from './constants'

const typeToRouteMapping = {
  [CONSTANTS.USER]: 'Usuario',
  [CONSTANTS.FARM]: 'Fazenda',
  [CONSTANTS.CORRAL]: 'Retiro',
  [CONSTANTS.BATCH]: 'Lote',
}

const requestFiles = async (
  fileType,
  id,
  name,
  estacaoMonta,
  fields,
  isRetry = true
) => {
  const targetRoute = typeToRouteMapping[fileType]
  var route = `Exportacao/exportacaoMatrizGeralPor${targetRoute}`

  const now = moment.utc().format('YYYYMMDD')
  const filename = `ProGERAR-${name}-${now}.xlsx`

  const authentication = await getLoggedUser()

  let url = api('reports', route);

  if (isRetry) {
    url = `${process.env.REACT_APP_BASE_URL}:${API_CONSTANTS.SERVICES_PORTS_MAP['reports']}/api/${route}`
  }

  let params = {
    method: 'post',
    headers: {
      Authorization: `Bearer ${authentication.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ Id: id, Fields: fields, EstacaoMonta: estacaoMonta })
  };

  timeoutFetch(url, params, 9000000)
    .catch((error) => {
      if (error instanceof TimeoutError) {
        if (!isRetry) {
          return requestFiles(fileType, id, name, estacaoMonta, true)
        }
        alert('Não foi possível processar sua requisição. Tente novamente.')
      } else {
        console.error(error)
      }
    })
    .then(async (response) => {
      if (!response) return null
      if (response.status === 401) {
        const data = await refreshToken()
        if (!_.isEmpty(data) && data.refreshed && !isRetry) {
          return requestFiles(fileType, id, name, estacaoMonta, true)
        }
      } else if (response.status >= 300) {
        const newError = JSON.parse(await response.text())
        throw new Error(newError.mensagem)
      } else if (response.status === 200) {
        return response.blob()
      }
    })
    .then((blob) => {
      saveAs(blob, filename)
    })
    .catch((error) => {
      if (!isRetry) {
        return requestFiles(fileType, id, name, estacaoMonta, true)
      }
      if (String(error) !== 'Error: As tabelas estão sendo atualizadas!') {
        LocalStorageHelper.add(
          'modalErrorText',
          'Houve um problema na geração da exportação, por favor, verifique se as informações lançadas foram enviadas com pelo menos 24h de antecedência e tente novamente mais tarde'
        )

        LocalStorageHelper.add(
          'modalTitleErrorText',
          targetRoute
        )
      }
      else {
        LocalStorageHelper.add(
          'modalErrorText',
          'No momento nosso servidor está atualizando as informações que geram os relatórios e a exportação. Por favor, tente novamente mais tarde. Este processo é automático e acontece cinco vezes ao dia, que são das 6h às 7h, 12h às 13h, 15h às 16h, 19h às 20h e 22h às 23h'
        )
      }
      window.location.reload(true)
    })
}

const requestImportDataTemplate = async (farmId, farmName, isRetry = true, setLoading) => {
  setLoading(true);

  const farmNameSlug = farmName.replace(/ /g, '_');
  const route = `Exportacao/GerarPlanilhaTemplate?farmId=${farmId}`;
  const authentication = await getLoggedUser();
  const now = moment.utc().format('YYYYMMDD');
  const filename = `ProGerar-importacao-manejos-${farmNameSlug}-${now}.xlsx`;

  let url = api('reports', route);

  if (isRetry) {
    url = `${process.env.REACT_APP_BASE_URL}:${API_CONSTANTS.SERVICES_PORTS_MAP['reports']}/api/${route}`;
  }

  let params = {
    method: 'get',
    headers: {
      Authorization: `Bearer ${authentication.token}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await timeoutFetch(url, params, 9000000);

    if (response.status === 401) {
      const data = await refreshToken();
      if (!_.isEmpty(data) && data.refreshed && !isRetry) {
        return requestImportDataTemplate(farmId);
      }
    } else if (response.status >= 300) {
      const newError = JSON.parse(await response.text());
      throw new Error(newError.mensagem);
    } else if (response.status === 200) {
      const blob = await response.blob();
      saveAs(blob, filename);
    }
  } catch (error) {
    console.error(error);
    if (!isRetry) {
      return requestImportDataTemplate(farmId);
    }
    alert('Não foi possível processar sua requisição. Tente novamente.');
  } finally {
    setLoading(false);
  }
};

export function getUserFile(userId, selectedFieldsOptionsExport, userName, estacaoMonta) {
  return requestFiles(CONSTANTS.USER, userId, userName, estacaoMonta, selectedFieldsOptionsExport)
}

export function getFarmFile(farmId, selectedFieldsOptionsExport, farmName) {
  return requestFiles(CONSTANTS.FARM, farmId, farmName, null, selectedFieldsOptionsExport)
}

export function getCorralFile(corralId, selectedFieldsOptionsExport, corralName) {
  return requestFiles(CONSTANTS.CORRAL, corralId, corralName, null, selectedFieldsOptionsExport)
}

export function getBatchFile(batchId, selectedFieldsOptionsExport, batchName) {
  return requestFiles(CONSTANTS.BATCH, batchId, batchName, null, selectedFieldsOptionsExport)
}

export function getImportDataTemplate(farmId, farmName, setLoading) {
  return requestImportDataTemplate(farmId, farmName, true, setLoading)
}

const requestReport = async (reportType, id, isRecursive = true) => {
  const targetRoute = typeToRouteMapping[reportType]
  const route = `RelatorioGrafico/getRelatorioPor${targetRoute}?${targetRoute}Id=${id}`

  const authentication = await getLoggedUser()
  const url = `${process.env.REACT_APP_BASE_URL}:${API_CONSTANTS.SERVICES_PORTS_MAP['reports']}/api/${route}`
  let params = {
    method: 'get',
    headers: {
      Authorization: `Bearer ${authentication.token}`,
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Accept-Charset': 'utf-8',
    },
    credentials: 'include',
  }

  return timeoutFetch(url, params, 90000)
    .catch((error) => {
      if (error instanceof TimeoutError) {
        alert(
          'Não foi possível processar sua requisição. Tente novamente mais tarde!'
        )
        window.location.assign('/')
      } else {
        console.error(error)
      }
      return error
    })
    .then(async (response) => {
      if (!response) return null
      if (response.status === 401) {
        const data = await refreshToken()
        if (!_.isEmpty(data) && data.refreshed && isRecursive) {
          return requestReport(reportType, id, false)
        }
      } else if (response.status >= 300) {
        const newError = JSON.parse(await response.text())
        throw new Error(newError.mensagem)
      } else {
        const data = JSON.parse(await response.text())

        return data.response && data.response.relatorios
      }
    })
    .catch((error) => {
      if (String(error) !== 'Error: As tabelas estão sendo atualizadas!') {
        LocalStorageHelper.add(
          'modalErrorText',
          'Houve um problema na geração do relatório, por favor, verifique se as informações lançadas foram enviadas com pelo menos 24h de antecedência e tente novamente mais tarde'
        )

        LocalStorageHelper.add(
          'modalTitleErrorText',
          targetRoute
        )

      } else {
        LocalStorageHelper.add(
          'modalErrorText',
          'No momento nosso servidor está atualizando as informações que geram os relatórios e a exportação. Por favor, tente novamente mais tarde. Este processo é automático e acontece cinco vezes ao dia, que são das 6h às 7h, 12h às 13h, 15h às 16h, 19h às 20h e 22h às 23h'
        )
      }
      window.location.assign('/')
    })
}

export function getFarmReport(farmId) {
  return requestReport(CONSTANTS.FARM, farmId)
}

export function getCorralReport(corralId) {
  return requestReport(CONSTANTS.CORRAL, corralId)
}

export function getBatchReport(batchId) {
  return requestReport(CONSTANTS.BATCH, batchId)
}
