import React, { useState } from 'react';

const TextFileReader = () => {
  const handleFileChosen = async (file) => {
    const reader = new FileReader();
    
    localStorage.removeItem('fileData');
    localStorage.removeItem('fileDataName');
    localStorage.removeItem('filePageOpen');

    reader.onload = async (event) => {
      const text = event.target.result;

      localStorage.setItem('fileData', text);
      localStorage.setItem('fileDataName', file.name);
      localStorage.setItem('filePageOpen', true);
    };

    reader.readAsText(file);
    window.location.reload();
  };

  return (
    <div>
      <input hidden accept=".progerar" multiple type="file" onChange={(event) => handleFileChosen(event.target.files[0])} />
    </div>
  );
};

export default TextFileReader;