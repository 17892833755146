/* eslint-disable array-callback-return */
import React from 'react'
import { Q } from '@nozbe/watermelondb'
import moment from 'moment'
import _ from 'lodash'

import { AVAILABLE_DTO_MAPPINGS, AVAILABLE_ENTITIES } from './constants'
import { LOTE, formatName } from '../../utils/formHelper'
import { toDTO } from '../resources/autoMapping'
import Repository from '../infra/repository'
import database from '../index'

const MSG = {
  NOT_FOUND: 'Matriz não encontrada.',
  PREGNANT: 'Matriz prenhe em DG anterior.',
  MORE_THAN_ONE_INSIDE_BATCH:
    'Existe mais de uma matriz que atende o critério de busca diagnosticadas como prenhe em DG anterior.',
  NOT_IN_BATCH: (codVaca, nomeLote, protocolName, dia) =>
    `A matriz ${codVaca} não faz parte deste Lote. Este animal se encontra no ${formatName(
      nomeLote,
      LOTE
    )} com o Protocolo ${protocolName} e no manejo ${dia}.`,
  MORE_THAN_ONE_OUTSIDE_BATCH: (cowCode) =>
    `A matriz ${cowCode} não faz parte deste Lote. Há mais de um animal registrado com esse ID.`,
}

export async function getFromLocalStorage(key) {
  return database.adapter.getLocal(key)
}

export async function setInLocalStorage(key, value) {
  return database.adapter.setLocal(key, value)
}

export async function removeFromLocalStorage(key) {
  return database.adapter.removeLocal(key)
}

export async function getDomainValuesBy(domainTypeName) {
  const valuesRepository = new Repository(AVAILABLE_ENTITIES.DOMAIN_VALUES)
  const domainValues = await valuesRepository.getByRelation(
    'domain_types',
    'nome',
    domainTypeName
  )
  if (domainValues.success) {
    //console.log(domainValues.response)
    return domainValues.response
  }
  console.warn(
    `There was a problem retrieving DomainValues for DomainType ${domainTypeName}: ${domainValues.exception}`
  )
  return []
}

export async function getDomainValuesById(domainTypeId) {
  const valuesRepository = new Repository(AVAILABLE_ENTITIES.DOMAIN_VALUES)
  const domainValues = await valuesRepository.getByParam(
    'id',
    domainTypeId
  )
  if (domainValues.success) {
    //console.log(domainValues.response)
    return domainValues.response
  }
  console.warn(
    `There was a problem retrieving DomainValues for DomainType ${domainTypeId}: ${domainValues.exception}`
  )
  return []
}

export async function getDomainValueByDomainValueParent(parentId) {
  const valuesRepository = new Repository(AVAILABLE_ENTITIES.DOMAIN_VALUES)
  const domainValues = await valuesRepository.getByParam(
    'valor_dominio_pai_id',
    parentId
  )
  if (domainValues.success) {
    //console.log(domainValues.response)
    return domainValues.response
  }
  console.warn(
    `There was a problem retrieving DomainValues with parentId ${parentId}: ${domainValues.exception}`
  )
  return []
}

export async function getChildren(parent) {
  const children = await Promise.all(
    parent.map((element) => getDomainValueByDomainValueParent(element.id))
  )
  return _.compact(_.flattenDeep(children))
}

export async function getElement(entity, id) {
  const repository = new Repository(entity)
  const request = await repository.getById(id)
  if (request.success) {
    return request.response
  }
  return null
}

export async function getAll(entity) {
  const repository = new Repository(entity)
  const request = await repository.get()
  if (request.success) {
    return request.response
  }
  return []
}

export async function getWithParam(entity, column, param) {
  const repository = new Repository(entity)
  const request = await repository.getByParam(column, param)
  if (request.success) {
    return request.response
  }
  return []
}

export async function getWithParamWithoutDeletedTrue(entity, column, param) {
  const repository = new Repository(entity)
  const request = await repository.getByParamDeleted(column, param)
  if (request.success) {
    return request.response
  }
  return []
}

export async function getWithRelation(entity, relation, column, param) {
  const repository = new Repository(entity)
  const request = await repository.getByRelation(relation, column, param)
  if (request.success) {
    return request.response
  }
  return []
}

export const withPropsFromDatabase = (
  retrievePropsFunction,
  WrappedComponent
) => {
  return class extends React.Component {
    UNSAFE_componentWillMount() {
      retrievePropsFunction(this.props).then((dbProps) =>
        this.setState({ ...dbProps })
      )
    }

    render() {
      const newProps = {
        ...this.props,
        ...this.state,
      }
      return (
        this.state !== null &&
        this.state !== {} && <WrappedComponent {...newProps} />
      )
    }
  }
}

export async function farmWideSearch(cowCode, selectedBatchesIds, farmId) {
  let result = {
    data: null,
    msg: null,
  }

  // 1. Check all corrals that compose given farm
  const corrals = await getWithParam(
    AVAILABLE_ENTITIES.CORRALS,
    'farm_id',
    farmId
  )

  // 2. Use previous information to retrieve all batches from that farm
  const batches = await getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'corral_id',
    Q.oneOf(corrals.map((corral) => corral.id))
  )

  // 3. Isolate originalBatches of selectedBatchesIds, so we can proper partition cows into "in-batch" and "out-batch"
  const originalBatches = selectedBatchesIds.map((batchId) => {
    const batch = _.find(batches, { id: batchId })
    if (batch.originalBatchId !== null) {
      return batch.originalBatchId
    }
    return batchId
  })

  // 4. Retrieve all batches related to the original ones we isolated
  const relatedBatches = _.filter(
    batches,
    (batch) =>
      originalBatches.indexOf(batch.id) >= 0 ||
      originalBatches.indexOf(batch.originalBatchId) >= 0
  )

  // 5. Retrieve all cows from that farm that are possible candidates: (omit_from_dg_final === false)
  const cowsCollection = database.collections.get(
    AVAILABLE_ENTITIES.D0S.toLowerCase()
  )

  const cows = (
    await cowsCollection
      .query(
        Q.where('batch_id', Q.oneOf(batches.map((batch) => batch.id))),
        Q.where('codVaca', Q.like(cowCode)),
        Q.where('isDeleted', false),
        Q.where('omit_from_dg_final', false)
      )
      .fetch()
  ).map((entity) =>
    toDTO(AVAILABLE_DTO_MAPPINGS[AVAILABLE_ENTITIES.D0S], entity)
  )

  // 6. If no result is given, we can return now
  if (cows.length === 0) {
    result.msg = MSG.NOT_FOUND
    return result
  }

  // 7. Separate cows according to their respective batches (if it belongs to any of related batches or not)
  const [cowsInBatch, cowsOutBatch] = _.partition(cows, (cow) =>
    _.find(relatedBatches, { id: cow.loteId })
  )

  // 8. Evaluate if there's any cow in relatedBatches (this case has precedence)
  if (cowsInBatch.length > 0) {
    result.data = cowsInBatch
    let pregCount = 0
    const prenheDG = (await getDomainValuesBy('Condição DG')).filter(
      (x) => x.valor.toLowerCase().indexOf('prenhe') > 0
    )
    const prenheDGFinal = (await getDomainValuesBy('Condição DG Final')).filter(
      (x) => x.valor.toLowerCase().indexOf('prenhe') > 0
    )
    for (const cow of cowsInBatch.filter(
      (cow) => selectedBatchesIds.indexOf(cow.loteId) === -1
    )) {
      const isPrenhe =
        prenheDG.find((x) => x.id === cow.condicaoDG) ||
        prenheDGFinal.find((x) => x.id === cow.condicaoDGFinal)

      if (isPrenhe) {
        pregCount++
      }

      if (pregCount >= 2) {
        break
      }
    }

    if (pregCount > 0) {
      result.msg =
        pregCount === 1 ? MSG.PREGNANT : MSG.MORE_THAN_ONE_INSIDE_BATCH
      return result
    }
  }

  const filteredCowsOutBatch = []

  cowsOutBatch.sort((a, b) => a.iatf - b.iatf)

  for (let cob of cowsOutBatch) {
    cob.firstId = cob.dzeroOriginal || cob.id
    if (filteredCowsOutBatch.length > 0) {
      filteredCowsOutBatch.map((fcob, index) => {
        if (fcob.firstId === cob.firstId) {
          if (cob.iatf > fcob.iatf) {
            filteredCowsOutBatch.splice(index)
          }
        }
      })
    }
    filteredCowsOutBatch.push(cob)
  }

  // 9. Evaluate cases eventually found outside the selected batches
  if (filteredCowsOutBatch.length > 0) {
    if (filteredCowsOutBatch.length === 1) {
      const cow = filteredCowsOutBatch[0]
      const batch = _.find(batches, { id: cow.loteId })
      const protocol = await getElement(
        AVAILABLE_ENTITIES.PROTOCOLS,
        batch.protocoloId
      )

      let dia = !_.isEmpty(cow.condicaoDG)
        ? 'DG'
        : !_.isEmpty(cow.condicaoDGFinal)
          ? 'DG Final'
          : ''

      if (_.isEmpty(dia)) {
        const days = await getDomainValuesBy('Dias')
        const day = days.find((x) => x.id === batch.dias)
        dia = day ? day.valor : 'D0'
      }

      result.msg = MSG.NOT_IN_BATCH(
        cow.codVaca,
        batch.nomeLote,
        protocol.name,
        dia
      )
      result.data = [cow]
    } else {
      result.data = filteredCowsOutBatch
        .filter((item) => item.iatf === 0)
        .map((i) => {
          const lotName = batches
            .filter((batch) => batch.id === i.loteId)
            .map((t) => t.nomeLote)[0]

          return {
            ...i,
            nomeLote: lotName,
          }
        })
      result.msg = MSG.MORE_THAN_ONE_OUTSIDE_BATCH(cowCode)
    }
  }

  return result
}

export async function findFromAllCows(cowCode, batchId, farmId) {
  let result = {
    data: null,
    msg: null,
  }

  const corrals = await getWithParam(
    AVAILABLE_ENTITIES.CORRALS,
    'farm_id',
    farmId
  )

  const batches = await getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'corral_id',
    Q.oneOf(corrals.map((x) => x.id))
  )

  const batchesIds = batches.map((x) => x.id)

  const relatedBatches = _.filter(
    batches,
    (candidate) => candidate.originalBatchId === batchId
  )
  const relatedBatchesIds = relatedBatches.map((x) => x.id)

  const cowsCollection = database.collections.get(
    AVAILABLE_ENTITIES.D0S.toLowerCase()
  )

  const cowsEntities = await cowsCollection
    .query(
      Q.where('batch_id', Q.oneOf(batchesIds)),
      Q.where('codVaca', Q.like('%' + cowCode + '%')),
      Q.where('isDeleted', false),
      Q.where('omit_from_dg_final', false)
    )
    .fetch()

  const allFilteredCows = cowsEntities.map((entity) => ({
    ...toDTO(AVAILABLE_DTO_MAPPINGS[AVAILABLE_ENTITIES.D0S], entity),
  }))

  // Not Found
  if (allFilteredCows.length === 0) {
    result.msg = MSG.NOT_FOUND
    return result
  }

  const [cowsInBatch, cowsOutBatch] = _.partition(
    allFilteredCows,
    (x) => x.loteId === batchId || relatedBatchesIds.includes(x.loteId)
  )

  // Found in Batch
  if (cowsInBatch.length > 0) {
    result.data = cowsInBatch

    const unique = _.uniqBy(cowsInBatch, 'codVaca')

    if (unique.length === 1 && unique[0].loteId !== batchId) {
      // Pregnancy verification
      const cow = unique[0]

      const prenheDG = (await getDomainValuesBy('Condição DG')).filter(
        (x) => x.valor.toLowerCase().indexOf('prenhe') > 0
      )
      const prenheDGFinal = (
        await getDomainValuesBy('Condição DG Final')
      ).filter((x) => x.valor.toLowerCase().indexOf('prenhe') > 0)

      const isPrenhe =
        prenheDG.find((x) => x.id === cow.condicaoDG) ||
        prenheDGFinal.find((x) => x.id === cow.condicaoDGFinal)

      if (isPrenhe) {
        result.msg = MSG.PREGNANT
      }
    }

    return result
  }

  // Found Outside only
  if (cowsOutBatch.length > 0) {
    const unique = _.uniqBy(cowsOutBatch, 'codVaca')

    if (unique.length === 1) {
      const cow = unique[0]
      const batch = batches.find((x) => x.id === cow.loteId)
      const protocol = await getElement(
        AVAILABLE_ENTITIES.PROTOCOLS,
        batch.protocoloId
      )

      let dia = !_.isEmpty(cow.condicaoDG)
        ? 'DG'
        : !_.isEmpty(cow.condicaoDGFinal)
          ? 'DG Final'
          : ''

      if (_.isEmpty(dia)) {
        const days = await getDomainValuesBy('Dias')
        const day = days.find((x) => x.id === batch.dias)
        dia = day ? day.valor : 'D0'
      }

      result.msg = MSG.NOT_IN_BATCH(
        cow.codVaca,
        batch.nomeLote,
        protocol.name,
        dia
      )
    } else {
      result.msg = MSG.MORE_THAN_ONE_OUTSIDE_BATCH(cowCode)
    }
  }

  return result
}

export function toMoment(date) {
  if (_.isEmpty(date) && !_.isNumber(date)) {
    return null
  }
  let m = moment.utc(date)
  if (!m.isValid()) {
    m = moment.utc(Number(date))
  }
  if (m.isValid()) {
    return m
  }
  return null
}

export function fromMoment(m) {
  if (!m) {
    return null
  }
  if (m.isValid()) {
    return m.valueOf()
  }
  return null
}
