export const idTypePregnancyDGFinalByName = (
  typePregnancy: any[],
  name: string
): string | null => {
  const idTypePregnancyDGFinal = typePregnancy.filter((c) => c.valor === name)

  if (idTypePregnancyDGFinal.length > 0) {
    return idTypePregnancyDGFinal[0].id
  }

  return null
}

export const idConditionDGFinalByName = (
  conditionDGFinal: any[],
  name: string
): string | null => {
  const idConditionDGFinal = conditionDGFinal.filter((c) => c.valor === name)

  if (idConditionDGFinal.length > 0) {
    return idConditionDGFinal[0].id
  }

  return null
}

export const idConditionDGByName = (
  conditionDG: any[],
  name: string
): string | null => {
  const idConditionDG = conditionDG.filter((c) => c.valor === name)

  if (idConditionDG.length > 0) {
    return idConditionDG[0].id
  }

  return null
}
