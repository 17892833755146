import * as CONSTANTS from './constants'


const initialState = {
  list: [],
  selected: null,
  roles: [],
}

export default function reduce (state = initialState, action) {
    switch (action.type) {
        case CONSTANTS.LIST_USER:
            return {
                ...state,
                list: action.data
            }
        case CONSTANTS.SELECT_USER:
            return {
                ...state,
                selected: action.data
            }
        case CONSTANTS.LIST_ROLES:
            return {
                ...state,
                roles: action.data
            }
        default:
            return state;
    }
}