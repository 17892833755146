/* eslint-disable no-extend-native */
import './styles/customstyle.scss'

import React, { PureComponent } from 'react'
import uuid from 'react-uuid'
import { Legend } from 'recharts'
import { Chart } from 'react-google-charts'

const DEFAULT_OPACITY = 1
const HIDDEN_OPACITY = 0.3

//NORMAL

// const COLOR_PALLETE = [
//   '#ff9f0f',
//   '#e8730e',
//   '#ff661d',
//   '#e8330e',
//   '#ff150f',
//   '#0096af',
// ]

const COLOR_PALLETE_PRIMARY = [
  '#F65C00',
  '#F68A4A',
  '#F6A97B',
  '#F6C8AC',
  '#F6D7C5',
  '#F6E7DD',
]

const COLOR_PALLETE_SECONDARY = ['#005470', '#00594E', '#502C1E']

const COLOR_PALLETE_TERCIARY = ['#6FB1C8', '#6BA43A', '#C31C4A']

//SHADES

// const COLOR_PALLETE = [
//   '#ff6920',
//   '#e55e1c',
//   '#cc5419',
//   '#b24916',
//   '#993f13',
//   '#7f3410'
// ]

//TINTS

// const COLOR_PALLETE = [
//   '#ff6920',
//   '#ff7836',
//   '#ff874c',
//   '#ff9662',
//   '#ffa579',
//   '#ffb48f'
// ]

//MONOCROMATIC

// const COLOR_PALLETE = [
//   '#ec4d00',
//   '#ff5806',
//   '#ff6920',
//   '#ff7a3a',
//   '#ff8b53',
//   '#ff9c6d'
// ]

const MAX_CHART_WIDTH = 1280

const TOOLTIP_TYPES = {
  ABSOLUTE: 'ABSOLUTE',
  PERCENTAGE: 'PERCENTAGE',
  OCCURRENCE: 'OCCURRENCE',
}

const TOOLTIP_FUNCTIONS = {
  ABSOLUTE: (value, name, props) => [
    value,
    `IATF ${parseInt(name.split('.')[1])}`,
  ],
  PERCENTAGE: (value, name, props) => [
    `${value}%`,
    `IATF ${parseInt(name.split('.')[1])}`,
  ],
  OCCURRENCE: (value, name, props) => [
    value,
    `Ocorrência${value > 1 ? 's' : ''}`,
  ],
}

const SUPPORTED_CHARTS = {
  SIMPLE_CHART: 'SIMPLE_CHART',
  DOUBLE_CHART: 'DOUBLE_CHART',
}

// class RotatedAxisTick extends PureComponent {
//   render() {
//     const { x, y, stroke, payload } = this.props
//     return (
//       <g transform={`translate(${x},${y})`}>
//         <text
//           x={0}
//           y={0}
//           dy={16}
//           textAnchor='end'
//           fill='#666'
//           transform='rotate(-30)'
//           style={{ fontSize: 12 }}
//         >
//           {payload.value}
//         </text>
//       </g>
//     )
//   }
// }

class GoogleChart extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedChart: null,
      id: uuid(),
      firstChartType: 'ColumnChart',
      seccondChartType: 'ColumnChart',
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)

    this.getOpacityFor = this.getOpacityFor.bind(this)
    this.dataRefactoring = this.dataRefactoring.bind(this)

    this.renderLegend = this.renderLegend.bind(this)
    this.renderChart = this.renderChart.bind(this)
    this.getWidth = this.getWidth.bind(this)
  }

  handleMouseEnter(o) {
    const { dataKey } = o
    this.setState({
      selectedChart: dataKey.split('.')[1],
    })
  }

  handleMouseLeave(o) {
    this.setState({
      selectedChart: null,
    })
  }

  getOpacityFor(label) {
    return this.state.selectedChart === null ||
      this.state.selectedChart === label
      ? DEFAULT_OPACITY
      : HIDDEN_OPACITY
  }

  renderLegend() {
    let wrapperStyle = {
      paddingTop: 20,
    }
    if (this.props.data.length > 3) {
      wrapperStyle.bottom = -20
    }
    return (
      <Legend
        align='center'
        verticalAlign='bottom'
        layout='horizontal'
        formatter={(value, entry, index) =>
          this.props.skipIATFLabel
            ? 'Ocorrência'
            : `IATF ${parseInt(value.split('.')[1]) + 1}`
        }
        wrapperStyle={wrapperStyle}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      />
    )
  }

  getWidth() {
    if (this.props.width) {
      if (this.props.width >= MAX_CHART_WIDTH) {
        return MAX_CHART_WIDTH * this.props.widthFactor
      }
      return this.props.width * this.props.widthFactor
    }
    return 0
  }

  returnGraphPosition(yAxisLabel) {
    return yAxisLabel === this.props.configuration.yAxisLabel
      ? 'First'
      : 'Seccond'
  }

  checkGraphPosition(position) {
    return position === 'First'
  }

  switchChartType(position, value) {
    this.checkGraphPosition(position)
      ? this.setState({ firstChartType: value })
      : this.setState({ seccondChartType: value })
  }

  getFormatedLabel(dataType, label, replace) {
    if (label === 0) {
      return null
    }

    //tratamento de casas decimais abaixo
    // label = dataType === 'PERCENTAGE' ? ((label % 1) === 0 ? `${label}%` : `${label.toFixed(1)}%`) : label

    label = dataType === 'PERCENTAGE' ? `${label.toFixed(1)}%` : label

    label = label.toString()
    label = label.replace('.', ',')

    return label
  }

  // getIatfLabel(data) {
  //   this.props.data.map(d => console.log(d.xAxis))
  // }
  dataRefactoring(data) {
    let refactoredData = data.map(
      (m) =>
        Object.values(m.series)
          .map((ov) => ov.yAxis)
          .reduce((a, b) => a + b, 0) /
        Object.values(m.series).map((ov) => ov.yAxis).length
    )
    let i = 0
    for (let formatedData of data) {
      formatedData.series = { 0: { yAxis: refactoredData[i] } }
      i++
    }

    return this.props.data
  }

  renderChart({
    xAxisLabel,
    yAxisLabel,
    shouldRenderLegend,
    tooltipFormatter,
    dataType,
    yAxisKey,
  }) {
    let allData = this.props.data
    const graphPosition = this.returnGraphPosition(yAxisLabel)

    let formatedData = [[xAxisLabel]]

    let labels = []
    let novilhas = []
    let novilhasPrecoces = []
    let primiparas = []
    let primiparasPrecoces = []
    let secundiparas = []
    let secundiparasPrecoces = []
    let multiparas = []
    let solteiras = []
    let naoInformadas = []
    let chartAfterSorting = []

    if (
      xAxisLabel === 'Touro' ||
      xAxisLabel === 'Inseminador' ||
      xAxisLabel === 'Protocolo'
    ) {
      allData = this.dataRefactoring(this.props.data)
    }

    for (let data of allData) {
      let dataSeries = null
      if (yAxisKey === 'yAxis') {
        dataSeries = Object.values(data.series).map((d) => d.yAxis)
      } else {
        dataSeries = Object.values(data.series).map((d) => d.yAxis2)
      }
      dataSeries = dataSeries.reduce(
        (res, current) => [
          ...res,
          current,
          this.getFormatedLabel(dataType, current),
        ],
        []
      )
      let dataLength = dataSeries.length
      formatedData.push([data.xAxis, ...dataSeries])
      labels.push(dataLength)
    }

    let maxLabels = Math.max(...labels) / 2

    for (let i = 1; i <= maxLabels; i++) {
      formatedData[0].push(this.props.skipIATFLabel ? `Valor` : `IATF ${i}`)
    }

    Array.prototype.insert = function (index, item) {
      this.splice(index, 0, item)
    }

    for (let i = 2; i <= maxLabels * 2; i += 2) {
      formatedData[0].insert(i, { role: 'annotation' })
    }

    for (let i = 1; i < formatedData.length; i++) {
      while (formatedData[i].length < formatedData[0].length) {
        formatedData[i].push(0, null)
      }
    }

    if (formatedData[0].filter((fd) => fd === 'Ordem').length > 0) {
      formatedData.map(
        (two) => two.indexOf('Novilhas') >= 0 && novilhas.push(two)
      )
      formatedData.map(
        (two) => two.indexOf('Primíparas') >= 0 && primiparas.push(two)
      )
      formatedData.map(
        (two) => two.indexOf('Secundíparas') >= 0 && secundiparas.push(two)
      )
      formatedData.map(
        (two) => two.indexOf('Multíparas') >= 0 && multiparas.push(two)
      )
      formatedData.map(
        (two) => two.indexOf('Solteiras') >= 0 && solteiras.push(two)
      )
      formatedData.map(
        (two) => two.indexOf('Novilhas Precoces') >= 0 && novilhasPrecoces.push(two)
      )
      formatedData.map(
        (two) => two.indexOf('Primíparas Precoces') >= 0 && primiparasPrecoces.push(two)
      )
      formatedData.map(
        (two) => two.indexOf('Secundíparas Precoces') >= 0 && secundiparasPrecoces.push(two)
      )
      formatedData.map(
        (two) => two.indexOf('Não informado') >= 0 && naoInformadas.push(two)
      )

      chartAfterSorting.push(
        formatedData[0],
        ...novilhas,
        ...primiparas,
        ...secundiparas,
        ...multiparas,
        ...solteiras,
        ...novilhasPrecoces,
        ...primiparasPrecoces,
        ...secundiparasPrecoces,
        ...naoInformadas
      )

      formatedData = chartAfterSorting
    }

    return (
      <>
        {/* <ComposedChart
          width={this.getWidth()}
          height={this.props.data.length > 3 ? 400 : 300}
          data={this.props.data}
          margin={{
            top: 30,
            right: 30,
            left: 30,
            bottom: 30
          }}
          syncId={`charts_${this.state.id}`}
        >
          <CartesianGrid strokeDasharray='3 3' />
          {this.props.data.length > 3 ? (
            <XAxis
              dataKey='xAxis'
              height={100}
              interval={0}
              tick={<RotatedAxisTick />}
            >
              <Label value={xAxisLabel} position='insideBottom' offset={-70} />
            </XAxis>
          ) : (
            <XAxis dataKey='xAxis' interval={0}>
              <Label value={xAxisLabel} position='insideBottom' offset={-5} />
            </XAxis>
          )}
          <YAxis orientation='left'>
            <Label value={yAxisLabel} position='center' angle={-90} dx={-30} />
          </YAxis>
          <Tooltip formatter={tooltipFormatter} />
          {shouldRenderLegend && this.renderLegend()}
          {_.times(this.props.configuration.seriesCount, index => (
            <Bar
              key={uuid()}
              fillOpacity={this.getOpacityFor(`${index}`)}
              dataKey={`series.${index}.${yAxisKey}`}
              fill={COLOR_PALLETE[index % COLOR_PALLETE.length]}
              isAnimationActive={false}
            />
          ))}
        </ComposedChart> */}
        <div class='hidewhenprinting'>
          <Chart
            width={this.getWidth()}
            margin={{
              top: 30,
              right: 30,
              left: 30,
              bottom: 30,
            }}
            height={this.props.data.length > 3 ? 400 : 350}
            chartType={
              this.checkGraphPosition(graphPosition)
                ? this.state.firstChartType
                : this.state.seccondChartType
            }
            loader={<div>Loading Chart</div>}
            data={formatedData}
            formatters={[
              {
                type: 'PatternFormat',
                column: [1, 2],
                options: '{1}',
              },
            ]}
            options={{
              titleTextStyle: {
                type: 'string',
                seriesType: 'bars',
                series: { 5: { type: 'line' } },
              },
              legend: this.props.skipIATFLabel && { position: 'none' },
              annotations: {
                textStyle: {
                  fontSize: 12,
                  bold: true,
                  // The color of the text.
                  color: '#595757',
                },
                alwaysOutside: true,

                auraColor: ' none ',
                color: '#871b47',
                // The color of the text outline.
              },
              vAxis: {
                title: yAxisLabel,
                format: dataType === 'PERCENTAGE' ? "#'%'" : '#',
                alwaysOutside: true,
                minValue: 0,
              },
              hAxis: { title: xAxisLabel },
              // Material design options
              colors: COLOR_PALLETE_PRIMARY,
              COLOR_PALLETE_SECONDARY,
              COLOR_PALLETE_TERCIARY,
              //bar: { groupWidth: "85%" }
            }}
          />
        </div>
        <div class='onlywhenprinting'>
          <Chart
            width={1100}
            margin={{
              top: 30,
              right: 30,
              left: 30,
              bottom: 30,
            }}
            height={this.props.data.length > 3 ? 400 : 350}
            chartType={
              this.checkGraphPosition(graphPosition)
                ? this.state.firstChartType
                : this.state.seccondChartType
            }
            loader={<div>Loading Chart</div>}
            data={formatedData}
            formatters={[
              {
                type: 'PatternFormat',
                column: [1, 2],
                options: '{1}',
              },
            ]}
            options={{
              titleTextStyle: {
                type: 'string',
                seriesType: 'bars',
                series: { 5: { type: 'line' } },
              },
              legend: this.props.skipIATFLabel && { position: 'none' },
              annotations: {
                textStyle: {
                  fontSize: 12,
                  bold: true,
                  // The color of the text.
                  color: '#595757',
                },
                alwaysOutside: true,

                auraColor: ' none ',
                color: '#871b47',
                // The color of the text outline.
              },
              vAxis: {
                title: yAxisLabel,
                format: dataType === 'PERCENTAGE' ? "#'%'" : '#',
                alwaysOutside: true,
                minValue: 0,
              },
              hAxis: { title: xAxisLabel },
              // Material design options
              colors: COLOR_PALLETE_PRIMARY,
              COLOR_PALLETE_SECONDARY,
              COLOR_PALLETE_TERCIARY,
              //bar: { groupWidth: "85%" }
            }}
          />
        </div>
      </>
    )
  }

  render() {
    return (
      <div style={{ margin: '0 40px 80px 40px' }}>
        {this.renderChart({
          xAxisLabel: this.props.configuration.xAxisLabel,
          yAxisLabel: this.props.configuration.yAxisLabel,
          shouldRenderLegend:
            this.props.configuration.type === SUPPORTED_CHARTS.SIMPLE_CHART,
          tooltipFormatter: this.props.skipIATFLabel
            ? TOOLTIP_FUNCTIONS[TOOLTIP_TYPES.OCCURRENCE]
            : TOOLTIP_FUNCTIONS[this.props.configuration.tooltipFormatter],
          yAxisKey: 'yAxis',
          dataType: this.props.configuration.tooltipFormatter,
        })}
        {this.props.configuration.type === SUPPORTED_CHARTS.DOUBLE_CHART &&
          (this.props.configuration.yAxis2Label !== 'Distribuição (%)' ||
            this.props.configuration.xAxisLabel === 'Cio' ||
            this.props.configuration.xAxisLabel === 'Temperamento') &&
          this.renderChart({
            xAxisLabel: this.props.configuration.xAxisLabel,
            yAxisLabel: this.props.configuration.yAxis2Label,
            shouldRenderLegend: true,
            tooltipFormatter:
              TOOLTIP_FUNCTIONS[this.props.configuration.tooltipFormatter2],
            yAxisKey: 'yAxis2',
            dataType: this.props.configuration.tooltipFormatter2,
          })}
      </div>
    )
  }
}

export default GoogleChart
