import { Model, Q } from '@nozbe/watermelondb'
import {
  date,
  field,
  lazy,
  readonly,
  relation
} from '@nozbe/watermelondb/decorators'

export const modelToDTOMapping = {
  identical: [
    'nomeProprietario',
    'nome',
    'inscricaoEstadual',
    'pais',
    'estado',
    'municipio'
  ],
  renamed: [
    {
      dtoName: 'tamanho',
      modelName: 'tamanho',
      isNumber: true
    },
    {
      dtoName: 'retiro',
      modelName: 'temRetiro'
    },
    {
      dtoName: 'inicioEstacaoMonta',
      modelName: 'inicioEstacaoMonta_at',
      isDate: true
    },
    {
      dtoName: 'fimEstacaoMonta',
      modelName: 'fimEstacaoMonta_at',
      isDate: true
    }
  ],
  references: [
    {
      dtoName: 'sistemaProducao',
      modelName: 'sistemaProducao_id'
    },
    {
      dtoName: 'usuarioId',
      modelName: 'user_id'
    },
    {
      dtoName: 'estacaoMonta',
      modelName: 'estacaoMonta_id'
    }
  ]
}

export default class Farms extends Model {
  static table = 'farms'
  static associations = {
    farms__rel__users: { type: 'has_many', foreignKey: 'farm_id' }
  }

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('nomeProprietario') nomeProprietario
  @field('nome') nome
  @field('inscricaoEstadual') inscricaoEstadual
  @field('pais') pais
  @field('estado') estado
  @field('municipio') municipio
  @field('tamanho') tamanho
  @field('temRetiro') temRetiro
  @date('inicioEstacaoMonta_at') inicioEstacaoMontaAt
  @date('fimEstacaoMonta_at') fimEstacaoMontaAt

  //Foreign Relations - Domain Values
  @relation('domain_values', 'sistemaProducao_id') sistemaProducao
  @relation('domain_values', 'estacaoMonta_id') estacaoMonta

  //Foreign Relations
  @relation('users', 'user_id') responsibleVet

  //Lazy Fields/Methods
  @lazy vets = this.collections
    .get('users')
    .query(
      Q.on('farms__rel__users', 'farm_id', this.id),
      Q.on('farms__rel__users', 'isDeleted', false),
      Q.where('isDeleted', false)
    )
}
