import * as api from '../../redux/api/actions.js'

import React, { Component } from 'react'
import ResponsiveTable, { CELL_TYPES } from '../../components/ResponsiveTable'

import AddIcon from '@material-ui/icons/Add'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import EditIcon from '@material-ui/icons/Edit'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt.js'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import TrainingsButton from '../Trainings/TrainingsButton'
import _ from 'lodash'
import { connect } from 'react-redux'
import { sortList } from '../../utils/helper'
import track from 'react-tracking'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'
import Autocomplete, {
  autocompleteDefaultOnChange
} from './../../components/material/Autocomplete';
import { findEstado } from '../../utils/formHelper.js'
import TextField from '../../components/material/TextField'
import Button, { COLORS } from '../../components/material/Button'
import DeleteIcon from '@material-ui/icons/Delete';
import { ESTADOS, ESTADOS_E_CIDADES } from '../../utils/constants'

@track(() => ({ page: 'Usuários List', date: new Date() }))
class UserList extends Component {
  constructor(props) {
    super(props)

    const sortedUsers =
      props.users && props.users.length > 0
        ? sortList(props.users, 'nomeCompleto')
        : []
    this.state = {
      users: sortedUsers,
      orderedUsers: sortedUsers,
      filtersVisible: false,
      filters: {
        estadoFilter: null,
        cidadeFilter: '',
        emailFilter: ''
      }
    }

    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.props.list()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.users &&
      this.props.users.length > 0 &&
      prevProps.users.length !== this.props.users.length
    ) {
      const sortedUsers = _.sortBy(this.props.users, [
        user => user.nomeCompleto?.toLowerCase()
      ])

      this.setState({
        users: sortedUsers,
        orderedUsers: sortedUsers
      })
    }
  }

  componentDidMount() { }

  handleSearch(e) {
    const val = e.target.value
    this.setState({
      users: _.isEmpty(val)
        ? this.state.orderedUsers
        : this.state.orderedUsers.filter(
          x => x.nomeCompleto?.toLowerCase().indexOf(val?.toLowerCase()) >= 0
        )
    })
  }

  handleToggleFilters = () => {
    this.setState({ filtersVisible: !this.state.filtersVisible });
    this.cleanFilters();
  };

  applyFilters() {
    const { estadoFilter, cidadeFilter, emailFilter } = this.state.filters;
    let filteredUsers = this.state.orderedUsers;

    // Filtrar pelo estado
    if (estadoFilter) {
      filteredUsers = filteredUsers.filter(
        (data) => data.estado === estadoFilter.name
      );
    } else {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          cidadeFilter: ''
        }
      }))
    }

    // Filtrar pela cidade
    if (!_.isEmpty(cidadeFilter)) {
      filteredUsers = filteredUsers.filter(
        (data) => data.municipio === cidadeFilter
      );
    }

    // Filtrar pelo email
    if (emailFilter) {
      filteredUsers = filteredUsers.filter(
        (data) => data.email === emailFilter
      );
    }

    this.setState({ users: filteredUsers });
  }

  cleanFilters() {
    this.setState({
      filters: {
        estadoFilter: null,
        cidadeFilter: '',
        emailFilter: ''
      }
    }, this.applyFilters);
  }

  @track((props, state, user) => ({ action: 'Clicou no botao de Editar usuário', value: user[0].id }))
  handleEditUser = (row) => this.props.history.push(`/users/${row.id}`)

  getTableColumnsData() {
    return [
      {
        name: 'Nome Completo',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: row => row.nomeCompleto
      },
      {
        name: 'Município/UF',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: row =>
          `${row.municipio && row.estado
            ? `${row.municipio}/${row.estado}`
            : ''
          }`
      },
      {
        name: 'E-mail',
        type: CELL_TYPES.TEXT,
        label: row => row.email.indexOf('@usuariosememail.com') >= 0 ? 'Não Possui' : row.email
      },
      {
        type: CELL_TYPES.ICON_BUTTON,
        icon: row => <EditIcon style={{ fontSize: 23 }} />,
        label: row => 'Editar',
        onClick: row => () => this.handleEditUser(row)
      }
    ];
  }

  @track({ action: 'Entrou na página de novo usuário' })
  handleNewUser = () => this.props.history.push('/users/create/')

  render() {
    if (this.props.loading) {
      return (
        <Backdrop open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )
    }

    return (
      <Container className="page-users">
        <TrainingsButton page='/users' />
        <TopBar title='' />
        <TitleBar
          title='Usuários'
          buttons={[
            {
              onClick: this.handleNewUser,
              icon: <AddIcon />,
              label: 'Novo Usuário'
            }
          ]}
          paths={[
            {
              label: 'Usuários'
            }
          ]}
        />
        <MainContainer
          searchLabel='Buscar'
          hasSearchWithFilterButtton
          handleSearch={this.handleSearch}
          handleButtonFilter={this.handleToggleFilters}
        >
          {this.props.error && (
            <Container
              disableGutters
              style={{
                marginBottom: '2rem'
              }}
            >
            </Container>
          )}
          {this.state.filtersVisible && (
            <div className='grid grid-batch-form'>
              <div className='grid-item p-12 header-info-left'>
                <p className='p-font-weight-700'>Outros Filtros</p>
              </div>
              <div className="grid-item p-12 t-6 d-4">
                <Autocomplete
                  id='estadoFilter'
                  options={ESTADOS}
                  getOptionLabel={(option) => {
                    if (_.has(option, 'name')) {
                      return option.name
                    }
                    const opt = findEstado(option)
                    return (opt && opt.name) || ''
                  }}
                  label='Estado'
                  value={this.state.filters.estadoFilter || ''}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChange('estadoFilter')
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        estadoFilter: value
                      }
                    }), this.applyFilters);
                  }}
                />
              </div>
              <div className="grid-item p-12 t-6 d-4">
                <Autocomplete
                  id='municipioFilter'
                  label='Município'
                  options={
                    this.state.filters.estadoFilter
                      ? ESTADOS_E_CIDADES.find(
                        (e) =>
                          e.name === this.state.filters.estadoFilter.name ||
                          e.id === this.state.filters.estadoFilter.id
                      )?.cidades || []
                      : []
                  }
                  disabled={!this.state.filters.estadoFilter}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChange('municipioFilter')
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        cidadeFilter: value
                      }
                    }), this.applyFilters);
                  }}
                />
              </div>
              <div className="grid-item p-12 t-6 d-4">
                <TextField
                  id='email'
                  label='E-mail'
                  value={this.state.filters.emailFilter || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        emailFilter: value
                      }
                    }), this.applyFilters);
                  }}
                />
              </div>
              <div className="grid-item p-12 p-display-flex p-justify-space-between">
                <Button
                  color={COLORS.GREY}
                  label={'Limpar'}
                  type='button'
                  startIcon={<DeleteIcon />}
                  onClick={() => { this.cleanFilters() }}
                />
              </div>
            </div>
          )}
          <ResponsiveTable
            columns={this.getTableColumnsData()}
            data={this.state.users}
            onSort={(sortedData) => this.setState({ users: sortedData })}
          />
          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false
              },
            ]}
          />
        </MainContainer>
      </Container>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  list: () => api.get(dispatch, 'users', `Account`)
})

const mapStateToProps = state => ({
  loading: api.getApiLoading(state, `Account`),
  users: api.getApiLastSuccessfulResult(state, `Account`) || [],
  error: api.getApiError(state, `Account/`)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserList))
