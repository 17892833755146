/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Container, Box, useMediaQuery } from '@material-ui/core'

import TitleBar from '../../components/TitleBar'
import MainContainer from '../../components/MainContainer'
import TopBar from '../../components/TopBar'
import Accordion from './Accordion'
import * as D from './data'
import TrainingsButton from './TrainingsButton'

const Trainings = () => {
  const [selectedVideo, setSelectedVideo] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const selectedVideoToPage = D.SelectedVideoToPage(
    location.state?.page,
    location.state?.hasCorral
  )

  useEffect(() => {
    setSelectedVideo(selectedVideoToPage)
  }, [selectedVideoToPage])

  return (
    <Container>
      <TrainingsButton
        data={{
          title: 'Entre em contato pelo WhatsApp',
          link: 'https://wa.me/5511943110553',
          color: '#30d24e',
          isWhatsApp: true,
        }}
      />
      <TopBar title='Treinamentos' />
      <TitleBar
        title='Treinamentos'
        buttons={
          location.state?.page && [
            {
              onClick: () => history.push(location.state.page),
              label: `Voltar`,
            },
          ]
        }
        paths={[
          {
            label: 'Treinamentos',
          },
        ]}
      />
      <MainContainer>
        <Box display='flex' flexDirection={isSmallScreen ? 'column' : 'row'}>
          {/* Iframe section */}
          {selectedVideo && (
            <Box
              flex={isSmallScreen ? 'none' : 3}
              p={1}
              style={{ borderRadius: '8px' }}
            >
              <iframe
                title='Video de treinamento'
                width='100%'
                height='663'
                style={{ margin: 'auto', borderRadius: '8px' }}
                src={selectedVideo}
                allowFullScreen
              />
            </Box>
          )}

          {/* Accordion List section */}
          <Box flex={isSmallScreen ? 'none' : 1} p={1}>
            {D.itens.map((item) => (
              <Accordion
                key={item.id}
                item={item}
                selectedVideo={selectedVideo}
                setSelectedVideo={setSelectedVideo}
              />
            ))}
          </Box>
        </Box>
      </MainContainer>
    </Container>
  )
}

export default Trainings
