import Button, { COLORS } from './../../../components/material/Button'
import React, { Component } from 'react'
import TextField, {
  textfieldDefaultOnChange
} from './../../../components/material/TextField'
import { missingRequiredFieldChecker, requiredFieldsEvaluator } from '../../../utils/formHelper'

import _ from 'lodash'
import { withPropsFromDatabase } from '../../../database/resources/utils'
import { withRouter } from 'react-router-dom'

class CorralForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props.initialValues
    }

    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  onEnterPress = e => {
    if (e.keyCode == 13 && e.shiftKey == false && !(this.props.user.roles[0]?.name === 'Cliente (somente visualização)')) {
      e.preventDefault()
      this.onSubmit()
    }
  }

  onSubmit() {
    let requiredFieldsState = this.requiredFieldsEvaluator()

    let payload = {
      ...this.props.corral,
      nome: this.state.nome,
      fazendaId: this.props.farm.id
    }
    if (requiredFieldsState !== null) {
      return this.setState(requiredFieldsState)
    }

    if (this.props.onSubmit) {
      this.props.onSubmit(payload)
    }
  }

  render() {
    return (
      <div className="grid p-margin-top-2">
        <div className="grid-item p-12">
          <TextField
            id='nome'
            label='Nome do Retiro'
            onChange={this.textfieldDefaultOnChange}
            onKeyDown={this.onEnterPress}
            value={this.state.nome}
            error={!_.isEmpty(this.state.nome_error)}
            helperText={this.state.nome_error}
            autoFocus
          />
        </div>

        <div className="grid-item p-12 p-display-flex p-justify-space-between p-margin-top-4 p-margin-bottom-5">
          <Button
            color={COLORS.GREY}
            label='Cancelar'
            onClick={this.onCancel}
          />

          <Button
            label='Salvar'
            onClick={this.onSubmit}
            containerStyle={{ textAlign: 'right' }}
            disabled={this.props.user.roles[0]?.name === 'Cliente (somente visualização)'}
          />
        </div>
      </div>
    )
  }
}

const propsFromDatabase = async props => ({
  requiredFields: ['nome']
})

export default withPropsFromDatabase(
  propsFromDatabase,
  withRouter(CorralForm)
)
