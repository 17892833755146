import '../../../components/styles/customstyle.scss'

import React, { Component } from 'react'

import ProGERARLogo from '../../../assets/img/logo_corte.png'
import ZoetisLogo from '../../../assets/img/logozoetis.png'
import moment from 'moment'

class Cover extends Component {
  render() {
    const now = moment()
    return (
      <React.Fragment>
        <div
          className='onlywhenprinting'
          style={{
            width: '100%',
            margin: '0 auto',
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            color: 'rgb(102, 102, 102)',
            fontWeight: 700,
            textAlign: 'center'
          }}
        >
          <img
            src={ProGERARLogo}
            style={{ width: '60%', marginTop: '100px' }}
          />
          <div style={{ fontSize: '48px', marginTop: '200px' }}>
            {this.props.farm}
            {this.props.corral && (
              <React.Fragment>
                <br />
                {this.props.corral}
              </React.Fragment>
            )}
            {this.props.batch && (
              <React.Fragment>
                <br />
                {this.props.batch}
              </React.Fragment>
            )}
          </div>
          <div style={{ fontSize: '32px', marginTop: '100px' }}>
            Estação de Monta
            <br />
            01/01/{now.format('YYYY')}
            <br />
            31/12/{now.format('YYYY')}
          </div>
          <div
            style={{ fontSize: '24px', fontWeight: 400, marginTop: '100px' }}
          >
            Emitido por {this.props.user} em{' '}
            {now.format('DD/MM/YYYY [às] HH:mm')}
          </div>
          <img src={ZoetisLogo} style={{ width: '15%', bottom: '3%', right: '5%', position: 'absolute' }} />
        </div>
        <div className='pagebreak' />
      </React.Fragment>
    )
  }
}

export default Cover
