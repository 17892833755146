import { AVAILABLE_ENTITIES, utils } from '../../database'
import { FAZENDA, formatName } from './../../utils/formHelper'
import React, { Component } from 'react'
import ResponsiveTable, { CELL_TYPES } from '../../components/ResponsiveTable'

import AddIcon from '@material-ui/icons/Add'
import { Container } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import MainContainer from '../../components/MainContainer'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import Autocomplete, {
  autocompleteDefaultOnChange,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField
} from '../../components/material/Autocomplete'
import TextField from '../../components/material/TextField'
import Button, { COLORS } from '../../components/material/Button'
import DeleteIcon from '@material-ui/icons/Delete'

class BullList extends Component {
  constructor(props) {
    super(props)

    let sortedBulls = props.bulls
      .sort(function (a, b) {
        if (a.nome.toLowerCase() < b.nome.toLowerCase()) {
          return -1
        }
        if (a.nome.toLowerCase() > b.nome.toLowerCase()) {
          return 1
        }
        return 0
      })
      .map(bull => {
        const raca = _.find(this.props.racas, { id: bull.raca })
        const central = _.find(this.props.centrais, { id: bull.central })
        return {
          ...bull,
          raca: raca && raca.valor,
          central: central ? central.valor : bull.centralValue
        }
      })

    this.state = {
      allBulls: sortedBulls,
      selectedBulls: sortedBulls,
      filtersVisible: false,
      filters: {
        racaFilter: null,
        centralFilter: null,
        observacaoFilter: ''
      }
    }

    this.autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField =
      autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField.bind(this)
    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
  }

  handleSearch(e) {
    const val = e.target.value
    if (_.isEmpty(val)) {
      this.setState({ selectedBulls: this.state.allBulls })
    } else {
      const selectedBulls = this.state.allBulls.filter(
        x => x.nome.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      this.setState({ selectedBulls })
    }
  }

  handleToggleFilters = () => {
    this.setState({ filtersVisible: !this.state.filtersVisible });
    this.cleanFilters();
  };

  applyFilters() {
    const { racaFilter, centralFilter, observacaoFilter } = this.state.filters;
    let selectedData = this.state.bulls;

    // Filtrar por Raça
    if (!_.isEmpty(racaFilter)) {
      selectedData = selectedData.filter(
        (data) => data.raca === racaFilter.id
      );
    }

    // Filtrar por Central
    if (centralFilter) {
      selectedData = selectedData.filter(
        (data) => data.central === centralFilter.id
      );
    }

    // Filtrar pela Observação
    if (!_.isEmpty(observacaoFilter)) {
      selectedData = this.state.selectedData.filter(
        (x) =>
          x['observacao'] && x['observacao'].toLowerCase().indexOf(observacaoFilter.toLowerCase()) >= 0
      );
    }

    this.setState({ selectedData });
  }

  cleanFilters() {
    this.setState({
      filters: {
        racaFilter: null,
        centralFilter: null,
        observacaoFilter: ''
      }
    }, this.applyFilters);
  }

  getTableColumnsData() {
    return [
      {
        name: 'Touro',
        type: CELL_TYPES.LINK,
        label: row => row.nome,
        sortBy: true,
        onClick: row => {
          return () =>
            this.props.history.push(
              `/farms/${this.props.farm.id}/bulls/${row.id}`
            )
        }
      },
      {
        name: 'Raça',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: row => row.raca
      },
      {
        name: 'Central',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: row => row.central
      },
      {
        name: 'Partida',
        type: CELL_TYPES.TEXT,
        label: row =>
          row.partida ? row.partida.format('DD/MM/YYYY') : ''
      },
      {
        name: 'Observação',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: row => row.observacao
      },
      {
        type: CELL_TYPES.BUTTON_ARR,
        buttons: row =>
          _.compact([
            {
              type: CELL_TYPES.BUTTON,
              icon: <EditIcon />,
              label: 'Editar',
              onClick: () => {
                this.props.history.push(
                  `/farms/${this.props.farm.id}/bulls/${row.id}/update`
                )
              }
            },

          ])
      }
    ]
  }

  render() {
    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title='Sêmen'
          buttons={[
            {
              onClick: () =>
                this.props.history.push(
                  `/farms/${this.props.farm.id}/bulls/create`
                ),
              icon: <AddIcon />,
              label: 'Sêmen'
            }
          ]}
          paths={[
            {
              route: `/farms/${this.props.farm.id}`,
              label: formatName(this.props.farm.nome, FAZENDA)
            },
            {
              label: `Sêmen`
            }
          ]}
        />

        <MainContainer
          hasSearchWithFilterButtton
          handleSearch={this.handleSearch}
          handleButtonFilter={this.handleToggleFilters}
        >
          {this.state.filtersVisible && (
            <div className='grid grid-batch-form'>
              <div className='grid-item p-12 header-info-left'>
                <p className='p-font-weight-700'>Outros Filtros</p>
              </div>
              <div className="grid-item p-12 t-6 d-4">
                <Autocomplete
                  id='racaFilter'
                  options={this.props.racas}
                  value={this.state.filters.racaFilter}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChange('racaFilter')
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        racaFilter: value
                      }
                    }), this.applyFilters);
                  }}
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.racas
                  )}
                  label='Raça'
                />
              </div>
              <div className="grid-item p-12 t-6 d-4">
                <Autocomplete
                  id='centralFilter'
                  options={this.props.centrais}
                  value={this.state.filters.centralFilter || ''}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChange('centralFilter')
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        centralFilter: value
                      }
                    }), this.applyFilters);
                  }}
                  getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField(
                    this.props.centrais
                  )}
                  label='Central'
                />
              </div>
              <div className="grid-item p-12 t-6 d-4">
                <TextField
                  id='observacaoFilter'
                  label='Observação'
                  value={this.state.filters.observacaoFilter || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        observacaoFilter: value
                      }
                    }), this.applyFilters);
                  }}
                />
              </div>
              <div className="grid-item p-12 p-display-flex p-justify-space-between">
                <Button
                  color={COLORS.GREY}
                  label={'Limpar'}
                  type='button'
                  startIcon={<DeleteIcon />}
                  onClick={() => this.cleanFilters()}
                />
              </div>
            </div>
          )}
          <ResponsiveTable
            className="table-bulls"
            columns={this.getTableColumnsData()}
            data={this.state.selectedBulls}
            onSort={(sortedData) => this.setState({ selectedBulls: sortedData })}
          />
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async props => ({
  farm: await utils.getElement(AVAILABLE_ENTITIES.FARMS, props.match.params.id),
  bulls: await utils.getWithParam(
    // AVAILABLE_ENTITIES.BULLS,
    AVAILABLE_ENTITIES.BULLS_NEW,
    'farm_id',
    props.match.params.id
  ),
  racas: await utils.getDomainValuesBy('Raça do Touro'),
  centrais: await utils.getDomainValuesBy('Centrais')
})

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(BullList)
)
