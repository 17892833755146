import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import { FAZENDA, formatName } from '../../utils/formHelper'
import React, { Component } from 'react'

import BullForm from './components/BullForm'
import { Container } from '@material-ui/core'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

class BullCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onCancel() {
    this.props.history.push(`/farms/${this.props.farm.id}/bulls`)
  }

  async onSubmit(payload) {
    // const repository = new Repository(AVAILABLE_ENTITIES.BULLS)
    const repository = new Repository(AVAILABLE_ENTITIES.BULLS_NEW)
    const createRequest = await repository.post(payload)
    if (createRequest.success) {
      console.log('Bull created successfully')
      this.props.history.push(`/farms/${this.props.farm.id}/bulls`)
    } else {
      console.log(
        `There was an error creating bull: ${createRequest.exception}`
      )
    }
  }

  render() {
    const initialValues = {
      nome: null,
      raca: null,
      central: null,
      centralValue: null,
      partida: null,
      observacao: null
    }
    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={`Novo Sêmen`}
          paths={[
            {
              route: `/farms/${this.props.farm.id}`,
              label: formatName(this.props.farm.nome, FAZENDA)
            },
            {
              route: `/farms/${this.props.farm.id}/bulls`,
              label: 'Sêmen'
            },
            {
              label: `Novo Sêmen`
            }
          ]}
        />
        <Prompt
          visible={verify()}
          title='Página duplicada!'
          message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
          buttons={[
            {
              autoFocus: false
            },
          ]}
        />
        <MainContainer containerTitle='Novo Sêmen'>
          <Container disableGutters>
            <BullForm
              initialValues={initialValues}
              onCancel={this.onCancel}
              onSubmit={this.onSubmit}
              farm={this.props.farm}
            />
          </Container>
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async props => ({
  farm: await utils.getElement(AVAILABLE_ENTITIES.FARMS, props.match.params.id)
})

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(BullCreate)
)
