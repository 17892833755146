import * as sync from '../../../src/syncWorker'

import {
  ANIMAL_DIED,
  ANIMAL_DISCARDED,
  ANIMAL_NOT_PRESENT_TO_MANAGEMENT,
  FOUND_DEVICE_INSIDE_COW,
} from '../../utils/constants'
import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import Autocomplete, {
  autocompleteDefaultOnChangeSavingID,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteDomainValueOptionLabelSavingIDUsingDescription,
  autocompleteOptionLabelUsingCustomFieldSavingID,
  autocompleteOptionLabelUsingCustomHandlerSavingID,
} from '../../components/material/Autocomplete'
import Button, { COLORS } from '../../components/material/Button'
import {
  FAZENDA,
  LOTE,
  REQUIRED_FIELD_MESSAGES,
  RETIRO,
  formatName,
  isDateNullOrValid,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from '../../utils/formHelper'
import React, { Component, Fragment } from 'react'
import TextField, {
  textfieldDefaultOnChange,
} from '../../components/material/TextField'

import { ANIMAL_CREATED_AFTER_D0 } from '../../utils/constants'
import AddIcon from '@material-ui/icons/Add'
import { CELL_TYPES } from './../../components/PaginatedTable'
import { Container } from '@material-ui/core'
import CustomSwitch from '../../components/CustomSwitch'
import DateField from '../../components/material/DateField'
import DeleteIcon from '@material-ui/icons/Delete'
import Divider from '@material-ui/core/Divider'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import { LocalStorageHelper } from '../../utils/localStorageHelper'
import MainContainer from '../../components/MainContainer'
import MicIcon from '@material-ui/icons/Mic'
import Prompt from '../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import ResponsiveTable from './../../components/ResponsiveTable'
import SaveIcon from '@material-ui/icons/Save'
import SpeechRecognition from '../../components/SpeechRecognition'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { eccSpeechRecognition } from '../../utils/eccSpeechRecognition'
import { getLoggedUser } from '../../redux/auth/actions'
import moment from 'moment'
import track from 'react-tracking'
import { verify } from '../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'
import { sortList } from '../../utils/helper'

const obsOptions = [
  FOUND_DEVICE_INSIDE_COW,
  ANIMAL_NOT_PRESENT_TO_MANAGEMENT,
  ANIMAL_DIED,
  ANIMAL_DISCARDED,
]

@track(() => ({ page: 'D0/IATF', date: new Date() }))
class D0IATF extends Component {
  constructor(props) {
    super(props)
    let sortedCows = sortList(props.vacas, 'codVaca')

    this.state = {
      ...props.initialValues,
      count: this.props.vacas.length,
      showingFinalizeConfirmation: false,
      batchesIds: props.batches.map((batch) => batch.id),
      loading: false,
      allCows: sortedCows,
      iatfs: this.props.vacasIatf,
      searchError: false,
      checked: true,
      toEditCow: false,
      cowEdited: null,
      selectedCows: sortedCows,
      deleteConfirmationOpened: false,
      selectedCow: null,
      clD0: this.props.clD0.filter((x) => x.valor === 'Não Avaliado')[0].id,
      partidaId: null,
      inseminadorId: null,
      confirmedBackupDownload: true,
      showingNoBackupConfirmation: false,
      ecc: null,
      cio: null,
      gnhr: null,
      temperamento: null,
      observacao: null,
      enterSubmit: false,
      dataPartoIndividual: null,
      focusDate: false,
      lastSaveOrEditCowId: ''
    }

    this.codVaca = React.createRef()
    this.codLeitorBastao = React.createRef()

    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
    this.autocompleteOptionLabelUsingCustomFieldSavingID =
      autocompleteOptionLabelUsingCustomFieldSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingIDUsingDescription =
      autocompleteDomainValueOptionLabelSavingIDUsingDescription.bind(this)
    this.autocompleteOptionLabelUsingCustomHandlerSavingID =
      autocompleteOptionLabelUsingCustomHandlerSavingID.bind(this)

    this.onSave = this.onSave.bind(this)
    this.editCow = this.editCow.bind(this)
    this.onClean = this.onClean.bind(this)
    this.fullClean = this.fullClean.bind(this)
    this.deleteCow = this.deleteCow.bind(this)
    this.finalize = this.finalize.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.renderBullLabel = this.renderBullLabel.bind(this)

    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.renderTitle = this.renderTitle.bind(this)
    this.isDateNullOrValid = isDateNullOrValid.bind(this)
  }

  renderBullLabel(partida, short) {
    const bull = this.props.bulls.find((bull) => bull.id === partida.touroId)

    if (!bull) {
      return ''
    }

    if (short) {
      const str = `${bull.nome}${partida.data
        ? ' - ' + moment(partida.data).format('DD/MM/YYYY')
        : ' - ' + partida.codigo}`;

      const truncatedStr = str.length > 25 ? str.slice(0, 25) + '...' : str;

      return truncatedStr || '';
    }

    return (
      `${bull.nome}${partida.data
        ? ' - ' + moment(partida.data).format('DD/MM/YYYY')
        : ' - ' + partida.codigo
      } ${_.isEmpty(bull.observacao) ? '' : '(' + bull.observacao + ')'}` || ''
    )
  }

  @track((props, state, cow) => ({ action: 'Deletou uma Matriz', value: cow[0] }))
  async deleteCow(selectedCow) {
    if (selectedCow === null) return

    const repository = new Repository(AVAILABLE_ENTITIES.D0S)
    const request = await repository.delete(selectedCow)

    const newState = {
      deleteConfirmationOpened: false,
      selectedCow: null,
      selectedCowCode: null,
    }

    if (request.success) {
      console.log(`Delete of cow ${selectedCow} successful`)
      newState.allCows = this.state.selectedCows.filter(
        (x) => x.id !== selectedCow
      )
      newState.selectedCows = this.state.selectedCows.filter(
        (x) => x.id !== selectedCow
      )
      this.setState({
        count: this.state.count - 1,
      })
      const iatfs = await utils.getWithParam(
        AVAILABLE_ENTITIES.IATFS,
        'vacaId',
        selectedCow
      )

      for (let iatf of iatfs) {
        let iatfRequest = await repository.delete(iatf.id)
        if (!iatfRequest.success) {
          console.log(iatfRequest.exception)
        }
      }
    } else {
      console.log(
        `Delete of cow ${selectedCow} failed: ${request.exception}`
      )
    }

    this.setState(newState)
  }

  async refreshIatfs() {
    const batchIds = this.props.match.params.id.split(';')
    const iatfs = _.filter(
      await utils.getWithParam(
        AVAILABLE_ENTITIES.IATFS,
        'batch_id',
        Q.oneOf(batchIds)
      )
    )
    this.setState({
      iatfs,
    })
  }

  handleData() {
    this.state.selectedCows.map(
      (m) =>
        (m.iatfId = this.state.iatfs.filter((b) => b.vacaId === m.id)[0]?.id)
    )

    this.state.selectedCows.map(
      (m) =>
      (m.inseminador = this.props.usuarios.filter(
        (u) =>
          u.id ===
          this.state.iatfs.filter((i) => i.vacaId === m.id)[0]?.usuarioId
      )[0]?.nomeCompleto)
    )

    this.state.selectedCows.map(
      (m) =>
      (m.inseminadorId = this.props.usuarios.filter(
        (u) =>
          u.id ===
          this.state.iatfs.filter((i) => i.vacaId === m.id)[0]?.usuarioId
      )[0]?.id)
    )

    this.state.selectedCows.map((m) => {
      const partidas = this.props.partidas.filter(
        (p) =>
          p.id ===
          this.state.iatfs.filter((i) => i.vacaId === m.id)[0]?.partidaId
      )

      if (partidas.length > 0) {
        m.partida = this.renderBullLabel(partidas[0], true)
      } else {
        m.partida = ''
      }

      return m;
    })


    this.state.selectedCows.map(
      (m) =>
      (m.partidaId = this.props.partidas.filter(
        (p) =>
          p.id ===
          this.state.iatfs.filter((i) => i.vacaId === m.id)[0]?.partidaId
      )[0]?.id)
    )

    this.state.selectedCows.map(
      (m) =>
        (m.gnhr = this.state.iatfs.filter((b) => b.vacaId === m.id)[0]?.gnhr)
    )

    this.state.selectedCows.map(
      (m) =>
      (m.cio = this.state.iatfs.filter(
        (b) => b.vacaId === m.id
      )[0]?.cioRetirada)
    )

    this.state.selectedCows.map(
      (m) =>
      (m.temperamento = this.state.iatfs.filter(
        (b) => b.vacaId === m.id
      )[0]?.temperamento)
    )

    this.state.selectedCows.map(
      (m) =>
      (m.observacao = this.state.iatfs.filter(
        (b) => b.vacaId === m.id
      )[0]?.observacao)
    )

    return this.state.selectedCows
  }

  @track((props, state) => ({ action: 'Editou uma matriz', value: state.lastSaveOrEditCowId }))
  async editCow() {
    let errorInputRequired = false
    const cowSelected = this.state.cowEdited

    let payload = {}
    try {
      this.setState({ loading: true })
      payload = {
        ...cowSelected,
        ..._.pick(this.state, [
          'codVaca',
          'codLeitorBastao',
          'idade_Nov_Pri',
          'responsavelId',
          'dispositivoIntravaginal',
          'dataPartoIndividual',
          'numero_Uso_Disp',
          'racaMatriz',
          'ordemMatriz',
          'clD0',
        ]),
        partidaId: this.state.partidaId,
        inseminadorId: this.state.inseminadorId,
        ecc: this.state.ecc,
        cio: this.state.cio,
        gnhr: this.state.gnhr,
        temperamento: this.state.temperamento,
        observacao: this.state.observacao,
      }
      payload.peso_EM = parseInt(this.state.peso_EM) || null
      payload.loteId = this.props.batch.id
      payload.dataProcesso = moment.utc()
      payload.omit_from_dg_final = false
      payload.iatf = 0

      if (this.props.batch.dias !== '') {
        payload.observacaoDG = ANIMAL_CREATED_AFTER_D0
      }

      let newAllCows = this.state.allCows
      let allIatfs = this.state.iatfs
      let allCowsIds = []

      newAllCows.map((x) => allCowsIds.push(x.id))

      let filtedIatf = allIatfs.filter((item) => item.id !== cowSelected.iatfId)

      let pos = allCowsIds.indexOf(cowSelected.id)

      newAllCows.splice(pos, 1)

      let iatfUpdated = {
        id: payload?.iatfId,
        vacaId: payload?.id,
        loteId: payload?.loteId,
        observacao: payload?.observacao,
        ecc: payload?.ecc,
        gnhr: payload?.gnhr,
        cioRetirada: payload?.cio,
        usuarioId: payload?.inseminadorId,
        partidaId: payload?.partidaId,
        temperamento: payload?.temperamento,
      }

      let requiredFieldsState = this.requiredFieldsEvaluator()
      let validDates = this.isDateNullOrValid(this.state.dataPartoIndividual)

      if (
        (Object.keys(requiredFieldsState || {}).length <= 0 || !validDates) &&
        !Object.keys(requiredFieldsState || {}).includes('codVaca_error')
      ) {
        errorInputRequired = false
        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.update(payload)
        if (createReq.success) {
          console.log('D0 registered successfully')

          newAllCows.push(createReq.response)
          sortList(newAllCows, 'codVaca')

          const iatfRepository = new Repository(AVAILABLE_ENTITIES.IATFS)
          const createRequest = await iatfRepository.update(iatfUpdated)

          filtedIatf.push(createRequest.response)

          this.setState({
            toEditCow: false,
            allCows: newAllCows,
            iatfs: filtedIatf,
            focusDate: false,
            dataPartoIndividual: null,
            lastSaveOrEditCowId: createReq.response.id
          })

          if (!createRequest.success) {
            console.log(
              `There was a problem trying to create entry for cow ${payload.id}: ${createRequest.exception}`
            )
          }
        } else {
          console.log(
            `There was an error trying to create D0: ${createReq.exception}`
          )
        }
      } else if (
        !Object.keys(requiredFieldsState || {}).includes('codVaca_error') &&
        (this.state.observacao === 'Animal morreu' ||
          this.state.observacao === 'Matriz Descarte' ||
          this.state.observacao === 'Animal não compareceu ao manejo')
      ) {
        errorInputRequired = false
        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.update(payload)
        if (createReq.success) {
          console.log('D0 registered successfully')

          newAllCows.push(createReq.response)
          sortList(newAllCows, 'codVaca')

          const iatfRepository = new Repository(AVAILABLE_ENTITIES.IATFS)
          const createRequest = await iatfRepository.update(iatfUpdated)

          filtedIatf.push(createRequest.response)

          this.setState({
            toEditCow: false,
            allCows: newAllCows,
            iatfs: filtedIatf,
            focusDate: false,
            dataPartoIndividual: null,
            lastSaveOrEditCowId: createReq.response.id
          })

          if (!createRequest.success) {
            console.log(
              `There was a problem trying to create entry for cow ${payload.id}: ${createRequest.exception}`
            )
          }
        } else {
          console.log(
            `There was an error trying to create D0: ${createReq.exception}`
          )
        }
      } else if (
        Object.keys(requiredFieldsState || {}).includes('codVaca_error') &&
        (!Object.keys(requiredFieldsState || {}).includes(
          'inseminadorId_error'
        ) ||
          !Object.keys(requiredFieldsState || {}).includes(
            'partidaId_error'
          )) &&
        payload.codLeitorBastao
      ) {
        errorInputRequired = false
        payload.codVaca = payload.codLeitorBastao

        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.update(payload)
        if (createReq.success) {
          console.log('D0 registered successfully')

          newAllCows.push(createReq.response)
          sortList(newAllCows, 'codVaca')

          const iatfRepository = new Repository(AVAILABLE_ENTITIES.IATFS)
          const createRequest = await iatfRepository.update(iatfUpdated)

          filtedIatf.push(createRequest.response)

          this.setState({
            toEditCow: false,
            allCows: newAllCows,
            iatfs: filtedIatf,
            focusDate: false,
            dataPartoIndividual: null,
            lastSaveOrEditCowId: createReq.response.id
          })

          if (!createRequest.success) {
            console.log(
              `There was a problem trying to create entry for cow ${payload.id}: ${createRequest.exception}`
            )
          }
        } else {
          console.log(
            `There was an error trying to create D0: ${createReq.exception}`
          )
        }
      } else if (
        Object.keys(requiredFieldsState || {}).includes('codVaca_error') &&
        (this.state.observacao === 'Animal morreu' ||
          this.state.observacao === 'Matriz Descarte' ||
          this.state.observacao === 'Animal não compareceu ao manejo') &&
        payload.codLeitorBastao
      ) {
        errorInputRequired = false
        payload.codVaca = payload.codLeitorBastao

        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.update(payload)
        if (createReq.success) {
          console.log('D0 registered successfully')

          newAllCows.push(createReq.response)
          sortList(newAllCows, 'codVaca')

          const iatfRepository = new Repository(AVAILABLE_ENTITIES.IATFS)
          const createRequest = await iatfRepository.update(iatfUpdated)

          filtedIatf.push(createRequest.response)

          this.setState({
            toEditCow: false,
            allCows: newAllCows,
            iatfs: filtedIatf,
            focusDate: false,
            dataPartoIndividual: null,
            lastSaveOrEditCowId: createReq.response.id
          })

          if (!createRequest.success) {
            console.log(
              `There was a problem trying to create entry for cow ${payload.id}: ${createRequest.exception}`
            )
          }
        } else {
          console.log(
            `There was an error trying to create D0: ${createReq.exception}`
          )
        }
      } else {
        if (payload.codLeitorBastao) {
          this.setState({
            codVaca_error: null,
            inseminadorId_error: requiredFieldsState.inseminadorId_error,
            partidaId_error: requiredFieldsState.partidaId_error,
          })
          errorInputRequired = true
        } else {
          this.setState(requiredFieldsState)
          errorInputRequired = true
        }
      }
    } catch (exception) {
      console.log(`There was an error trying to create D0: ${exception}`)
    } finally {
      this.setState({ loading: false, searchError: false })
      if (!errorInputRequired) {
        this.onClean()
        this.codVaca.current.focus()
      }
    }
  }

  @track((props, state) => ({ action: 'Criou uma matriz', value: state.lastSaveOrEditCowId }))
  async onSave() {
    let errorInputRequired = false

    let payload = {}
    try {
      this.setState({ loading: true })
      payload = {
        ..._.pick(this.state, [
          'codVaca',
          'codLeitorBastao',
          'idade_Nov_Pri',
          'responsavelId',
          'dataPartoIndividual',
          'dispositivoIntravaginal',
          'numero_Uso_Disp',
          'racaMatriz',
          'ordemMatriz',
          'clD0',
        ]),
        ecc: this.state.ecc,
      }
      payload.peso_EM = parseInt(this.state.peso_EM)
      payload.loteId = this.props.batch.id
      payload.dataProcesso = moment.utc()
      payload.omit_from_dg_final = false
      payload.observacaoDG = (this.state.observacao === 'Animal morreu' || this.state.observacao === 'Matriz Descarte') ? '[N/A]' : null
      payload.iatf = 0

      if (this.props.batch.dias !== '') {
        payload.observacaoDG = ANIMAL_CREATED_AFTER_D0
      }

      let requiredFieldsState = this.requiredFieldsEvaluator()
      let validDates = this.isDateNullOrValid(this.state.dataPartoIndividual)

      if (
        (Object.keys(requiredFieldsState || {}).length <= 0 || !validDates) &&
        !Object.keys(requiredFieldsState || {}).includes('codVaca_error')
      ) {
        errorInputRequired = false

        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.post(payload)
        if (createReq.success) {
          console.log('D0 registered successfully')
          this.setState({
            count: this.state.count + 1,
            focusDate: false,
            dataPartoIndividual: null,
            lastSaveOrEditCowId: createReq.response.id
          })
          const newState = {}

          this.state.selectedCows.unshift(createReq.response)
          newState.selectedCows = this.state.selectedCows

          this.setState(sortList(newState.selectedCows, 'codVaca'))

          let iatfPayload = {
            vacaId: createReq.response.id,
            loteId: createReq.response.loteId,
            observacao: this.state.observacao,
            ecc: this.state.ecc,
            gnhr: this.state.gnhr,
            cioRetirada: this.state.cio,
            usuarioId: this.state.inseminadorId,
            partidaId: this.state.partidaId,
            temperamento: this.state.temperamento,
          }

          const iatfRepository = new Repository(AVAILABLE_ENTITIES.IATFS)
          const createRequest = await iatfRepository.post(iatfPayload)
          this.refreshIatfs()

          if (!createRequest.success) {
            console.log(
              `There was a problem trying to create entry for cow ${payload.id}: ${createRequest.exception}`
            )
          }
        } else {
          console.log(
            `There was an error trying to create D0: ${createReq.exception}`
          )
        }
      } else if (
        !Object.keys(requiredFieldsState || {}).includes('codVaca_error') &&
        (this.state.observacao === 'Animal morreu' ||
          this.state.observacao === 'Matriz Descarte' ||
          this.state.observacao === 'Animal não compareceu ao manejo')
      ) {
        errorInputRequired = false

        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.post(payload)
        if (createReq.success) {
          console.log('D0 registered successfully')
          this.setState({
            count: this.state.count + 1,
            focusDate: false,
            dataPartoIndividual: null,
            lastSaveOrEditCowId: createReq.response.id
          })
          const newState = {}

          this.state.selectedCows.unshift(createReq.response)
          newState.selectedCows = this.state.selectedCows

          this.setState(sortList(newState.selectedCows, 'codVaca'))

          let iatfPayload = {
            vacaId: createReq.response.id,
            loteId: createReq.response.loteId,
            observacao: this.state.observacao,
            ecc: this.state.ecc,
            gnhr: this.state.gnhr,
            cioRetirada: this.state.cio,
            usuarioId: this.state.inseminadorId,
            partidaId: this.state.partidaId,
            temperamento: this.state.temperamento,
          }
          const iatfRepository = new Repository(AVAILABLE_ENTITIES.IATFS)
          const createRequest = await iatfRepository.post(iatfPayload)
          this.refreshIatfs()

          if (!createRequest.success) {
            console.log(
              `There was a problem trying to create entry for cow ${payload.id}: ${createRequest.exception}`
            )
          }
        } else {
          console.log(
            `There was an error trying to create D0: ${createReq.exception}`
          )
        }
      } else if (
        Object.keys(requiredFieldsState || {}).includes('codVaca_error') &&
        (!Object.keys(requiredFieldsState || {}).includes(
          'inseminadorId_error'
        ) ||
          !Object.keys(requiredFieldsState || {}).includes(
            'partidaId_error'
          )) &&
        payload.codLeitorBastao
      ) {
        errorInputRequired = false
        payload.codVaca = payload.codLeitorBastao

        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.post(payload)
        if (createReq.success) {
          console.log('D0 registered successfully')
          this.setState({
            count: this.state.count + 1,
            focusDate: false,
            dataPartoIndividual: null,
            lastSaveOrEditCowId: createReq.response.id
          })
          const newState = {}

          this.state.selectedCows.unshift(createReq.response)
          newState.selectedCows = this.state.selectedCows

          this.setState(sortList(newState.selectedCows, 'codVaca'))

          let iatfPayload = {
            vacaId: createReq.response.id,
            loteId: createReq.response.loteId,
            observacao: this.state.observacao,
            ecc: this.state.ecc,
            gnhr: this.state.gnhr,
            cioRetirada: this.state.cio,
            usuarioId: this.state.inseminadorId,
            partidaId: this.state.partidaId,
            temperamento: this.state.temperamento,
          }
          const iatfRepository = new Repository(AVAILABLE_ENTITIES.IATFS)
          const createRequest = await iatfRepository.post(iatfPayload)
          this.refreshIatfs()

          if (!createRequest.success) {
            console.log(
              `There was a problem trying to create entry for cow ${payload.id}: ${createRequest.exception}`
            )
          }
        } else {
          console.log(
            `There was an error trying to create D0: ${createReq.exception}`
          )
        }
      } else if (
        Object.keys(requiredFieldsState || {}).includes('codVaca_error') &&
        (this.state.observacao === 'Animal morreu' ||
          this.state.observacao === 'Matriz Descarte' ||
          this.state.observacao === 'Animal não compareceu ao manejo') &&
        payload.codLeitorBastao
      ) {
        errorInputRequired = false
        payload.codVaca = payload.codLeitorBastao

        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.post(payload)
        if (createReq.success) {
          console.log('D0 registered successfully')
          this.setState({
            count: this.state.count + 1,
            focusDate: false,
            dataPartoIndividual: null,
            lastSaveOrEditCowId: createReq.response.id
          })
          const newState = {}

          this.state.selectedCows.unshift(createReq.response)
          newState.selectedCows = this.state.selectedCows

          this.setState(sortList(newState.selectedCows, 'codVaca'))

          let iatfPayload = {
            vacaId: createReq.response.id,
            loteId: createReq.response.loteId,
            observacao: this.state.observacao,
            ecc: this.state.ecc,
            gnhr: this.state.gnhr,
            cioRetirada: this.state.cio,
            usuarioId: this.state.inseminadorId,
            partidaId: this.state.partidaId,
            temperamento: this.state.temperamento,
          }
          const iatfRepository = new Repository(AVAILABLE_ENTITIES.IATFS)
          const createRequest = await iatfRepository.post(iatfPayload)
          this.refreshIatfs()

          if (!createRequest.success) {
            console.log(
              `There was a problem trying to create entry for cow ${payload.id}: ${createRequest.exception}`
            )
          }
        } else {
          console.log(
            `There was an error trying to create D0: ${createReq.exception}`
          )
        }
      } else {
        if (payload.codLeitorBastao) {
          this.setState({
            codVaca_error: null,
            inseminadorId_error: requiredFieldsState.inseminadorId_error,
            partidaId_error: requiredFieldsState.partidaId_error,
          })
          errorInputRequired = true
        } else {
          this.setState(requiredFieldsState)
          errorInputRequired = true
        }
      }
    } catch (exception) {
      console.log(`There was an error trying to create D0: ${exception}`)
    } finally {
      this.setState({ loading: false, searchError: false, toEditCow: false })
      if (!errorInputRequired) {
        this.onClean()

        if (
          payload.codLeitorBastao &&
          payload.codVaca === payload.codLeitorBastao
        ) {
          this.codLeitorBastao.current.focus()
        } else {
          this.codVaca.current.focus()
        }
      }
    }
  }

  handleSearch(e) {
    const val = e.target.value

    if (_.isEmpty(val)) {
      this.setState({ selectedCows: this.state.allCows })
    } else {
      const filtedCodVaca = this.state.allCows.filter(
        (x) => x.codVaca?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      const filtedCodLeitorBastao = this.state.allCows.filter(
        (x) => x.codLeitorBastao?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      const filtedAllData = filtedCodVaca.concat(filtedCodLeitorBastao)

      const selectedCows = [...new Set(filtedAllData)]

      this.setState({ selectedCows })
    }
  }

  handleSwitchChange = (event) => {
    this.setState({ checked: event.target.checked })
  }

  async handleSave() {
    if (this.state.checked && this.state.codVaca != null) {
      const val = this.state.codVaca
      let searchError = null
      if (this.state.selectedCows.map((m) => m.codVaca).indexOf(val) >= 0) {
        this.setState({
          searchError:
            'Já existe uma matriz com esse nome cadastrada nesse lote.',
        })
        return
      } else {
        const outOfBatch = await utils.farmWideSearch(
          val,
          this.state.batchesIds,
          this.props.farm.id
        )

        if (outOfBatch.msg) {
          searchError = outOfBatch.msg
          if (searchError === 'Matriz não encontrada.') {
            this.onSave()
            return
          }
          if (searchError.indexOf('não faz parte deste Lote') >= 0) {
            searchError = searchError.replace(
              `A matriz ${this.state.codVaca} não faz parte deste Lote.`,
              ``
            )
          }
          this.setState({
            searchError,
          })
        } else {
          this.onSave()
        }
      }
    } else {
      this.onSave()
    }
  }

  onClean(success) {
    this.setState({
      codVaca: success && null,
      codLeitorBastao: null,
      cio: null,
      gnhr: null,
      temperamento: null,
      observacao: null,
      peso_EM: null,
      clD0: this.props.clD0.filter((x) => x.valor === 'Não Avaliado')[0].id,
      ..._.pick(this.props.initialValues, [
        'idade_Nov_Pri',
        'responsavelId',
        'racaMatriz',
        'ordemMatriz',
      ]),
    })
  }

  onEnterPress = (e) => {
    if (this.props.user.roles[0].name !== 'Cliente (somente visualização)') {
      if (
        e.key === 'Enter' &&
        e.shiftKey === false &&
        this.state.enterSubmit === false
      ) {
        e.preventDefault()

        this.setState({ enterSubmit: true })
      }

      if (
        e.key === 'Enter' &&
        e.shiftKey === false &&
        this.state.enterSubmit &&
        !this.state.toEditCow
      ) {
        e.preventDefault()

        return this.handleSave()
      }

      if (
        e.key === 'Enter' &&
        e.shiftKey === false &&
        this.state.enterSubmit &&
        this.state.toEditCow
      ) {
        e.preventDefault()

        return this.editCow()
      }
    }
  }

  fullClean() {
    this.setState({
      codVaca: null,
      codLeitorBastao: null,
      peso_EM: null,
      idade_Nov_Pri: null,
      responsavelId: null,
      dispositivoIntravaginal: null,
      dataPartoIndividual: null,
      numero_Uso_Disp: null,
      racaMatriz: null,
      ordemMatriz: null,
      clD0: null,
    })
  }

  @track({ action: 'Finalizou o D0/IATF' })
  async finalize() {
    const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)
    let success = true
    for (const batch of this.props.batches) {
      const updateRequest = await repository.update({
        id: batch.id,
        dias: this.props.currentDay.id,
      })
      if (!updateRequest.success) {
        success = false
        console.log(
          `There was an error finalizing IATF for batch ${batch.id}: ${updateRequest.exception}`
        )
      }
    }
    if (success) {
      console.log('IATF Successfully ended')
      const uniqueCorrals = _.uniq(
        this.props.corrals.map((corral) => corral.id)
      )
      if (this.props.farm.retiro && uniqueCorrals.length === 1) {
        LocalStorageHelper.add('reportExportWarning', true)
        this.props.history.push(
          `/farms/${this.props.farm.id}/corrals/${uniqueCorrals[0]}`
        )
      } else {
        this.props.history.push(`/farms/${this.props.farm.id}`)
      }
    }

    sync.downloadProofOfManagement({
      values: ['fazendas', 'lotes', 'd0s', 'iatfs', 'touros', 'partidas', 'fazendaUsuarios'],
      text: 'Comprovante de manejo D0_IATF - ' +
        formatName(this.props.batch.nomeLote, LOTE) +
        ' - ' +
        formatName(this.props.farm.nome, FAZENDA) +
        ' - ' +
        moment.utc().valueOf() +
        '.progerar',
      batchIds: this.props.match.params.id.split(';')
    })
  }

  renderTitle() {
    return (
      <Fragment>
        Protocolo: {this.props.protocol.name}
        <br />
        Manejo: D0/IATF
      </Fragment>
    )
  }

  renderColumnLabel(row, rowItem, prop, label) {
    return (
      this.props[prop] &&
      row[rowItem] &&
      this.props[prop].find((x) => x.id === row[rowItem])[label]
    )
  }

  getTableColumnData() {
    return [
      {
        name: 'Matriz (ID)',
        type: CELL_TYPES.TEXT,
        grid: 2,
        label: (row) => row.codVaca,
      },
      {
        name: 'Inseminador',
        type: CELL_TYPES.TEXT,
        label: (row) => row.inseminador,
      },
      {
        name: 'Sêmen',
        type: CELL_TYPES.TEXT,
        label: (row) => row.partida,
      },
      {
        name: 'Raça',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(row, 'racaMatriz', 'racaMatriz', 'descricao'),
      },
      {
        name: 'Ordem',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'ordemMatriz',
            'ordemMatriz',
            'descricao'
          ),
      },
      {
        name: 'Dispositivo',
        type: CELL_TYPES.TEXT,
        grid: 2,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'dispositivoIntravaginal',
            'implantes',
            'valor'
          ),
      },
      {
        name: 'Uso',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'numero_Uso_Disp',
            'usoImplantes',
            'valor'
          ),
      },
      {
        name: 'Idade',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(row, 'idade_Nov_Pri', 'idadeMatriz', 'valor'),
      },
      {
        name: 'CL',
        type: CELL_TYPES.TEXT,
        label: (row) => this.renderColumnLabel(row, 'clD0', 'clD0', 'valor'),
      },
      {
        type: CELL_TYPES.BUTTON_ARR,
        buttons: (row) =>
          _.compact([
            {
              icon: <EditIcon />,
              label: 'Editar',
              disabled: !row.iatfId ? true : false,
              onClick: () => {
                this.codVaca.current.focus()
                this.setState({
                  cowEdited: row,
                  toEditCow: true,
                  focusDate: row?.dataPartoIndividual ? true : false,
                  selectedCow: row?.id,
                  codVaca:
                    row.codVaca === row?.codLeitorBastao ? null : row?.codVaca,
                  codLeitorBastao: row?.codLeitorBastao,
                  partidaId: row?.partidaId,
                  inseminadorId: row?.inseminadorId,
                  peso_EM: row?.peso_EM,
                  clD0: row?.clD0,
                  ecc: row?.ecc,
                  cio: row?.cio,
                  gnhr: row?.gnhr,
                  temperamento: row?.temperamento,
                  observacao: row?.observacao,
                  idade_Nov_Pri: row?.idade_Nov_Pri,
                  racaMatriz: row?.racaMatriz,
                  ordemMatriz: row?.ordemMatriz,
                  dispositivoIntravaginal: row?.dispositivoIntravaginal,
                  dataPartoIndividual: row?.dataPartoIndividual,
                  numero_Uso_Disp: row?.numero_Uso_Disp,
                  responsavelId: row?.responsavelId,
                })
              },
            },
            {
              icon: <DeleteIcon />,
              label: 'Excluir',
              onClick: () =>
                this.setState({
                  deleteConfirmationOpened: true,
                  selectedCow: row.id,
                  selectedCowCode: row.codVaca,
                }),
              disabled: this.props.user.roles[0].name === 'Cliente (somente visualização)'
            },
          ]),
      },
    ]
  }

  render() {
    const extraPath = this.props.farm.retiro && {
      route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`,
      label: formatName(this.props.corral.nome, RETIRO),
    }

    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={this.renderTitle()}
          buttons={[
            {
              onClick: () => {
                const newState = { showingIncompleteError: true }
                this.setState(newState)
              },
              icon: <AddIcon />,
              label: 'Cadastrar Sêmen',
              disabled: this.props.user.roles[0].name === 'Cliente (somente visualização)'
            },
            {
              onClick: () =>
                this.setState({ showingFinalizeConfirmation: true }),
              icon: <DoneIcon />,
              label: 'Finalizar',
              disabled: this.props.user.roles[0].name === 'Cliente (somente visualização)'
            },
          ]}
          paths={_.compact([
            {
              route: `/farms/${this.props.farm.id}`,
              label: formatName(this.props.farm.nome, FAZENDA),
            },
            extraPath,
            {
              route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}/batches/${this.props.batch.id}`,
              label: formatName(this.props.batch.nomeLote, LOTE),
            },
            {
              label: 'D0',
            },
          ])}
        />

        <MainContainer>
          <div className='grid d0-grid-form'>
            <div className='grid-item p-12 t-6 d0-grid-form-top-info-left'>
              <h3>TOTAL DE MATRIZES: {this.state.count}</h3>
            </div>

            <div className='grid-item p-12 t-6 p-display-flex d0-grid-form-top-info-right'>
              <CustomSwitch
                onHandleChange={this.handleSwitchChange}
                checked={this.state.checked}
                text='Avisar sobre matrizes repetidas'
              ></CustomSwitch>
            </div>

            <Divider
              style={{
                // marginTop: '2em',
                marginBottom: '1.25rem',
                width: '100%',
                height: '2px',
              }}
            />

            <div className='grid-item p-10 d-3'>
              <TextField
                id='codVaca'
                label='ID1/Matriz'
                inputRef={this.codVaca}
                autoFocus
                disabled={this.state.loading}
                onChange={(e) => {
                  this.textfieldDefaultOnChange(e)
                  this.setState({ enterSubmit: true })
                }}
                onKeyDown={this.onEnterPress}
                value={this.state.codVaca || null}
                error={!_.isEmpty(this.state.codVaca_error)}
                helperText={this.state.codVaca_error}
              />
            </div>

            <div className='grid-item p-2 d-1'>
              <Button
                secondary
                small
                tabIndex='-1'
                style={{
                  height: '55px',
                  width: '100%',
                }}
                onClick={async () => {
                  try {
                    const resposta = await SpeechRecognition()
                    if (resposta !== null)
                      this.setState({
                        codVaca: resposta,
                        codVaca_error:
                          _.isEmpty(resposta) &&
                          REQUIRED_FIELD_MESSAGES.DEFAULT,
                      })
                  } catch (e) {
                    console.log(e)
                  }
                }}
                label={<MicIcon />}
                containerStyle={{ textAlign: 'center' }}
              />
            </div>

            <div className='grid-item p-12 d-2'>
              <TextField
                id='codLeitorBastao'
                label='ID2/Leitor de bastão'
                disabled={this.state.loading}
                onChange={(e) => {
                  this.textfieldDefaultOnChange(e)
                  this.setState({ enterSubmit: true, codVaca_error: null })
                }}
                inputRef={this.codLeitorBastao}
                onKeyDown={this.onEnterPress}
                value={this.state.codLeitorBastao || null}
              />
            </div>

            <div className='grid-item p-12 d-3'>
              <Autocomplete
                id='semen'
                label='Sêmen'
                options={this.props.partidas}
                value={this.state.partidaId || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID('partidaId')}
                onKeyDown={this.state.partidaId && this.onEnterPress}
                getOptionLabel={this.autocompleteOptionLabelUsingCustomHandlerSavingID(
                  this.props.partidas,
                  this.renderBullLabel
                )}
                error={!_.isEmpty(this.state.partidaId_error)}
                useDefaultOptionSelected
              />
            </div>

            <div className='grid-item p-12 d-3'>
              <Autocomplete
                id='inseminadorId'
                options={this.props.usuarios}
                value={this.state.inseminadorId || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'inseminadorId'
                )}
                onKeyDown={this.state.inseminadorId && this.onEnterPress}
                getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                  this.props.usuarios,
                  'nomeCompleto'
                )}
                error={!_.isEmpty(this.state.inseminadorId_error)}
                useDefaultOptionSelected
                label='Inseminador'
              />
            </div>

            <div className='grid-item p-10 t-4 d-2'>
              <TextField
                id='peso_EM'
                label='Peso Inicial'
                type='number'
                onChange={(e) => {
                  this.textfieldDefaultOnChange(e)
                  this.setState({ enterSubmit: true })
                }}
                onKeyDown={this.onEnterPress}
                value={this.state.peso_EM || null}
                error={!_.isEmpty(this.state.peso_EM_error)}
                helperText={this.state.peso_EM_error}
              />
            </div>

            <div className='grid-item p-2 t-2 d-1'>
              <Button
                secondary
                small
                tabIndex='-1'
                style={{
                  height: '55px',
                  width: '100%',
                }}
                onClick={async () => {
                  const resposta = await SpeechRecognition()
                  if (resposta !== null)
                    this.setState({
                      peso_EM: resposta,
                      peso_EM_error: null,
                    })
                }}
                label={<MicIcon />}
                containerStyle={{ textAlign: 'center' }}
              />
            </div>

            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                id='clD0'
                label='Presença de CL'
                options={this.props.clD0}
                value={this.state.clD0 || null}
                onChange={this.autocompleteDefaultOnChangeSavingID('clD0')}
                onKeyDown={this.state.clD0 && this.onEnterPress}
                onFocus={() => this.setState({ enterSubmit: false })}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.clD0
                )}
                useDefaultOptionSelected
              />
            </div>

            <div className='grid-item p-10 d-2'>
              <Autocomplete
                id='ecc'
                label='ECC'
                options={this.props.ecc}
                value={this.state.ecc || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID('ecc')}
                onKeyDown={this.state.ecc && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.ecc
                )}
                useDefaultOptionSelected
              />
            </div>

            <div className='grid-item p-2 d-1'>
              <Button
                secondary
                small
                tabIndex='-1'
                style={{
                  height: '55px',
                  width: '100%',
                }}
                onClick={async () => {
                  try {
                    const answerToUser = eccSpeechRecognition(
                      await SpeechRecognition()
                    )

                    if (answerToUser !== null) {
                      this.setState({
                        ecc: answerToUser,
                      })
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }}
                label={<MicIcon />}
                containerStyle={{ textAlign: 'center' }}
              />
            </div>

            <div className='grid-item p-12 d-2'>
              <Autocomplete
                id='cio'
                label='Cio'
                options={this.props.cio}
                value={this.state.cio || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID('cio')}
                onKeyDown={this.state.cio && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.cio
                )}
                useDefaultOptionSelected
              />
            </div>

            <div className='grid-item p-12 d-2'>
              <Autocomplete
                id='gnhr'
                label='GnRH'
                options={this.props.gnhr}
                value={this.state.gnhr || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID('gnhr')}
                onKeyDown={this.state.gnhr && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.gnhr
                )}
                useDefaultOptionSelected
              />
            </div>

            <div className='grid-item p-12 d-3'>
              <Autocomplete
                id='temperamento'
                label='Temperamento'
                options={this.props.temperamento}
                value={this.state.temperamento || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'temperamento'
                )}
                onKeyDown={this.state.temperamento && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.temperamento
                )}
                useDefaultOptionSelected
              />
            </div>

            {this.state.focusDate ? (
              <div className='grid-item p-12 p-offset-3 t-6 d-4'>
                <DateField
                  id='dataPartoIndividual'
                  label='Data do parto individual'
                  value={this.state.dataPartoIndividual || null}
                  onChange={(e) => this.setState({ dataPartoIndividual: e })}
                  onBlur={() => {
                    if (!this.state.dataPartoIndividual) {
                      this.setState({ focusDate: false })
                    }
                  }}
                  onKeyDown={this.onEnterPress}
                  onClickCaptureButton={() =>
                    this.setState({ dataPartoIndividual: new Date() })
                  }
                  error={
                    !this.isDateNullOrValid(this.state.dataPartoIndividual)
                  }
                  helperText={this.state.dataPartoIndividual_error}
                />
              </div>
            ) : (
              <div className='grid-item p-12 p-offset-3 t-6 d-4'>
                <DateField
                  initialValues={[
                    this.state.dataPartosInicio,
                    this.state.dataPartosFim,
                  ]}
                  label='Data do parto'
                  onFocus={() => this.setState({ focusDate: true })}
                  ranged
                />
              </div>
            )}

            <div className='grid-item p-12 t-12 d-5'>
              <Autocomplete
                id={`observacao`}
                label='Observação'
                options={obsOptions}
                onFocus={() => this.setState({ enterSubmit: false })}
                onKeyDown={this.onEnterPress}
                onChange={(e, value) => {
                  if (
                    value === ANIMAL_NOT_PRESENT_TO_MANAGEMENT ||
                    value === ANIMAL_DIED ||
                    value === ANIMAL_DISCARDED
                  ) {
                    this.setState({
                      usuarioId: null,
                      partidaId: null,
                      inseminadorId: null,
                      partidaId_error: null,
                      inseminadorId_error: null,
                    })
                  }
                  this.setState({
                    observacao: value,
                  })
                }}
                value={this.state.observacao || null}
                openField
              />
            </div>

            <Divider
              style={{
                marginBottom: '1.5rem',
                width: '100%',
                height: '2px',
              }}
            />

            <div className='grid-item p-12 p-offset-3 t-4 d-2'>
              <Autocomplete
                id='idade_Nov_Pri'
                options={this.props.idadeMatriz}
                value={this.state.idade_Nov_Pri || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'idade_Nov_Pri'
                )}
                onKeyDown={this.state.idade_Nov_Pri && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.idadeMatriz
                )}
                useDefaultOptionSelected
                label='Idade (Meses)'
                error={!_.isEmpty(this.state.idade_Nov_Pri_error)}
                helperText={this.state.idade_Nov_Pri_error}
              />
            </div>

            <div className='grid-item p-12 t-4 d-2'>
              <Autocomplete
                id='racaMatriz'
                options={this.props.racaMatriz}
                value={this.state.racaMatriz || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'racaMatriz'
                )}
                onKeyDown={this.state.racaMatriz && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
                  this.props.racaMatriz
                )}
                useDefaultOptionSelected
                label='Raça da Matriz'
                error={!_.isEmpty(this.state.racaMatriz_error)}
                helperText={this.state.racaMatriz_error}
              />
            </div>

            <div className='grid-item p-12 t-4 d-2'>
              <Autocomplete
                id='ordemMatriz'
                options={this.props.ordemMatriz}
                value={this.state.ordemMatriz || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'ordemMatriz'
                )}
                onKeyDown={this.state.ordemMatriz && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
                  this.props.ordemMatriz
                )}
                useDefaultOptionSelected
                label='Ordem da Matriz'
                error={!_.isEmpty(this.state.ordemMatriz_error)}
                helperText={this.state.ordemMatriz_error}
              />
            </div>

            <div className='grid-item p-12 p-offset-3 t-6 d-2'>
              <Autocomplete
                id='dispositivoIntravaginal'
                options={this.props.implantes}
                value={this.state.dispositivoIntravaginal || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'dispositivoIntravaginal'
                )}
                onKeyDown={
                  this.state.dispositivoIntravaginal && this.onEnterPress
                }
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.implantes
                )}
                useDefaultOptionSelected
                label='Dispositivo Intravaginal'
                error={!_.isEmpty(this.state.dispositivoIntravaginal_error)}
                helperText={this.state.dispositivoIntravaginal_error}
              />
            </div>

            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                id='numero_Uso_Disp'
                options={this.props.usoImplantes}
                value={this.state.numero_Uso_Disp || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'numero_Uso_Disp'
                )}
                onKeyDown={this.state.numero_Uso_Disp && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.usoImplantes
                )}
                useDefaultOptionSelected
                label='Uso do Dispositivo'
                error={!_.isEmpty(this.state.numero_Uso_Disp_error)}
                helperText={this.state.numero_Uso_Disp_error}
              />
            </div>

            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                id='responsavelId'
                options={this.props.usuarios}
                value={this.state.responsavelId || null}
                onFocus={() => this.setState({ enterSubmit: false })}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'responsavelId'
                )}
                onKeyDown={this.state.responsavelId && this.onEnterPress}
                getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                  this.props.usuarios,
                  'nomeCompleto'
                )}
                useDefaultOptionSelected
                label='Responsável'
                error={!_.isEmpty(this.state.responsavelId_error)}
                helperText={this.state.responsavelId_error}
              />
            </div>

            <div
              className='grid-item p-12 p-display-flex p-justify-space-between'
              style={{ marginTop: '11px' }}
            >
              {this.state.toEditCow === true ? (
                <Button
                  color={COLORS.GREY}
                  label='Cancelar'
                  onClick={() => {
                    this.onClean()
                    this.setState({
                      toEditCow: false,
                      focusDate: false,
                      dataPartoIndividual: null,
                    })
                  }}
                />
              ) : (
                <Button
                  color={COLORS.GREY}
                  label='Limpar'
                  onClick={this.onClean}
                  startIcon={<DeleteIcon />}
                />
              )}
              <Button
                label='Salvar'
                disabled={
                  this.state.loading ||
                  !!this.state.codVaca_error ||
                  !!this.state.partidaId_error ||
                  !!this.state.inseminadorId_error ||
                  this.props.user.roles[0].name === 'Cliente (somente visualização)'
                }
                onClick={!this.state.toEditCow ? this.handleSave : this.editCow}
                // onClick={this.handleSave}
                startIcon={<SaveIcon />}
                containerStyle={{ textAlign: 'right' }}
                style={{ marginLeft: '8px' }}
              />
            </div>
          </div>

          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false,
              },
            ]}
          />

          <Prompt
            visible={this.state.showingIncompleteError}
            title='Aviso!'
            message='Tenha certeza que salvou todas as informações preenchidas antes de continuar.'
            buttons={[
              {
                label: 'CANCELAR',
                onClick: () => this.setState({ showingIncompleteError: false }),
              },
              {
                label: 'CONTINUAR',
                onClick: () => {
                  this.props.history.push(
                    `/bulls/create-for-all/batch/${this.props.batchIds[0]}/management/d0iatf`
                  )
                },
              },
            ]}
          />

          <Prompt
            visible={this.state.showingFinalizeConfirmation}
            title='Deseja Finalizar?'
            message='Uma vez finalizado os dados não salvos serão perdidos.'
            buttons={[
              {
                label: 'Não',
                onClick: () =>
                  this.setState({
                    showingFinalizeConfirmation: false,
                    confirmedBackupDownload: true,
                  }),
              },
              {
                label: 'Sim',
                onClick: this.state.confirmedBackupDownload
                  ? () => this.finalize(true)
                  : () =>
                    this.setState({
                      showingFinalizeConfirmation: false,
                      showingNoBackupConfirmation: true,
                    }),
              },
            ]}
          />

          <Prompt
            visible={this.state.showingNoBackupConfirmation}
            title='Atenção!'
            message='O comprovante de manejo pode ser necessário durante um eventual cenário de perda de dados. Não baixar o comprovante poderá dificultar a recuperação das informações desse manejo. Deseja continuar mesmo assim?'
            buttons={[
              {
                label: 'Cancelar',
                onClick: () =>
                  this.setState({
                    showingNoBackupConfirmation: false,
                    showingFinalizeConfirmation: true,
                  }),
              },
              {
                label: 'Sim',
                onClick: () => this.finalize(false),
              },
            ]}
          />
          <Prompt
            visible={this.state.searchError}
            title={'Matriz já cadastrada em outro(s) lotes'}
            message={
              this.state.searchError
                ? this.state.searchError
                : 'Erro desconhecido'
            }
            secondaryMessage={
              'Para não ver este aviso, desmarque a opção "Avisar sobre matrizes repetidas".'
            }
            buttons={[
              {
                label: 'Cancelar',
                autoFocus: false,
                onClick: () => this.setState({ searchError: false }),
              },
              {
                label: 'Salvar',
                onClick: () => {
                  this.onSave()
                },
              },
            ]}
          />
          {!this.state.toEditCow && (
            <>
              <Divider
                style={{
                  width: '100%',
                  height: '2px',
                  marginBottom: '-22px',
                }}
              />
              <MainContainer
                containerTitle={`Matrizes`}
                hasSearchWithoutFocus
                searchLabel='Buscar'
                handleSearch={this.handleSearch}
              >
                <ResponsiveTable
                  columns={this.getTableColumnData()}
                  data={this.handleData()}
                  noDataMessage='Ainda não existe nenhuma vaca neste lote!'
                  toDataExport={this.props}
                />
                <Prompt
                  visible={this.state.deleteConfirmationOpened}
                  title='Confirmar Exclusão'
                  message={`Deseja realmente excluir esta matriz (${this.state.selectedCowCode})?`}
                  buttons={[
                    {
                      label: 'Não',
                      onClick: () =>
                        this.setState({ deleteConfirmationOpened: false }),
                    },
                    {
                      label: 'Sim',
                      onClick: () => this.deleteCow(this.state.selectedCow),
                    },
                  ]}
                />
              </MainContainer>
            </>
          )}
        </MainContainer>
      </Container>
    )
  }
}

async function getFarms(user, isAdministrador) {
  if (!user) {
    return []
  }

  const repository = new Repository(AVAILABLE_ENTITIES.FARMS)
  const farmsData = isAdministrador
    ? await repository.getDeleted()
    : await repository.getByParamDeleted('user_id', user.id)
  const relatedFarmsData = isAdministrador
    ? { success: true, response: [] }
    : await repository.getDeletedByRelation(
      'farms__rel__users',
      'user_id',
      user.id
    )

  if (farmsData.success && relatedFarmsData.success) {
    return _.uniqBy(
      farmsData.response.concat(relatedFarmsData.response),
      (farm) => farm.id
    )
  }
  return []
}

async function parseD0Info(protocol, d0, tipoManejo) {
  let ret = {
    implant: '',
    benzoato: '',
    luteolise: '',
    progesterona: '',
  }
  if (protocol && protocol.managementProtocols) {
    const d0s = _.filter(
      protocol.managementProtocols,
      (managementProtocol) => managementProtocol.day === d0.id
    )
    const benzoato = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Estímulo Ovulatório'
    )
    const luteolise = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Luteólise'
    )
    const progesterona = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Progesterona Injetável'
    )

    _.each(d0s, async (d0) => {
      if (d0.implantId) {
        ret.implant = (
          await utils.getElement(AVAILABLE_ENTITIES.DOMAIN_VALUES, d0.implantId)
        ).valor
      }
      const hormone = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        d0.hormoneTypeId
      )
      const dosage = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        d0.hormoneDosageId
      )

      const dosageValue = dosage ? dosage.valor : d0.hormoneDosageValue

      switch (d0.managementId) {
        case benzoato.id:
          ret.benzoato = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        case luteolise.id:
          ret.luteolise = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        case progesterona.id:
          ret.progesterona = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        default:
      }
    })
  }
  return ret
}

const propsFromDatabase = async (props) => {
  const loggedUser = await getLoggedUser()
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    loggedUser.userId
  )

  const isAdministrator = _.find(
    loggedUser.roles,
    (el) => el === 'Administrador'
  )

  const batchIds = props.match.params.id.split(';')

  const batches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'id',
    Q.oneOf(batchIds)
  )

  const batch = await utils.getElement(
    AVAILABLE_ENTITIES.BATCHES,
    props.match.params.id
  )

  const corral = await utils.getElement(
    AVAILABLE_ENTITIES.CORRALS,
    batch.retiroId
  )

  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    corral.fazendaId
  )

  const usuarios = await utils.getWithRelation(
    AVAILABLE_ENTITIES.USERS,
    'farms__rel__users',
    'farm_id',
    corral.fazendaId
  )

  await getFarms(user, isAdministrator)

  const partidas = await utils.getWithParam(
    AVAILABLE_ENTITIES.PARTIDA,
    'farm_id',
    farm.id)

  const bulls = await utils.getWithParam(
    AVAILABLE_ENTITIES.BULLS_NEW,
    'id',
    Q.oneOf(partidas.map(f => f.touroId))
  )

  const corrals = await utils.getWithParam(
    AVAILABLE_ENTITIES.CORRALS,
    'id',
    Q.oneOf(_.uniq(batches.map((batch) => batch.retiroId)))
  )

  const temperamento = _.sortBy(await utils.getDomainValuesBy('Temperamento'), [
    (temperamento) => temperamento.valor.trim().toLowerCase() !== 'andando',
    (temperamento) => temperamento.valor.trim().toLowerCase() !== 'marchando',
    (temperamento) => temperamento.valor.trim().toLowerCase() !== 'correndo',
  ])

  const dias = await utils.getDomainValuesBy('Dias')
  const protocol =
    batch.protocoloId &&
    (await utils.getElement(AVAILABLE_ENTITIES.PROTOCOLS, batch.protocoloId))

  const protocolDays = protocol
    ? _.sortBy(
      _.uniq(
        protocol.managementProtocols.map((managementProtocol) =>
          _.find(dias, { id: managementProtocol.day })
        )
      ),
      [
        (dia) =>
          dia.valor.startsWith('D') ? parseInt(dia.valor.substring(1)) : 100,
      ]
    )
    : []
  const currentDay = _.last(protocolDays)

  const d0 = _.find(dias, { valor: 'D0' })
  const tipoManejo = await utils.getDomainValuesBy('Tipo de Manejo')
  const d0Info = await parseD0Info(protocol, d0, tipoManejo)

  const vacas = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    props.match.params.id
  )

  const vacasIatf = _.filter(
    await utils.getWithParam(
      AVAILABLE_ENTITIES.IATFS,
      'batch_id',
      Q.oneOf(batchIds)
    )
  )

  return {
    requiredFields: [
      'codVaca',
      'responsavelId',
      'dispositivoIntravaginal',
      'numero_Uso_Disp',
      'racaMatriz',
      'ordemMatriz',
      'inseminadorId',
      'partidaId',
    ],
    batchIds,
    batches,
    vacasIatf,
    batch,
    bulls,
    partidas,
    corral,
    corrals,
    currentDay,
    temperamento,
    farm,
    usuarios: usuarios ? usuarios : [],
    protocol,
    vacas,
    ecc: _.sortBy(await utils.getDomainValuesBy('ECC'), [
      (ecc) => parseFloat(ecc.valor),
    ]),
    gnhr: await utils.getDomainValuesBy('GnRH'),
    cio: await utils.getDomainValuesBy('Cio'),
    ordemMatriz: await utils.getDomainValuesBy('Ordem da Matriz Predominante'),
    racaMatriz: await utils.getDomainValuesBy('Raça da Matriz predominante'),
    racaBezerro: await utils.getDomainValuesBy('Raça do bezerro'),
    sexoBezerro: await utils.getDomainValuesBy('Sexo do Bezerro'),
    idadeMatriz: _.sortBy(await utils.getDomainValuesBy('Idade da Matriz'), [
      (idade) => parseInt(idade.valor.split(' ')[0]),
    ]),
    implantes: _.sortBy(await utils.getDomainValuesBy('Dispositivo'), [
      (implant) => !implant.valor.toLowerCase().startsWith('zoetis'),
      (implant) => implant.valor.toLowerCase(),
    ]),
    usoImplantes: _.sortBy(
      await utils.getDomainValuesBy('Tipo de Implante Predominante'),
      ['valor']
    ),
    clD0: _.sortBy(await utils.getDomainValuesBy('CL'), [
      (cl) => !cl.valor.toLowerCase().startsWith('sim'),
      (cl) => !cl.valor.toLowerCase().startsWith('não'),
      (cl) => cl.valor.toLowerCase(),
    ]),
    initialValues: {
      ...d0Info,
      ordemMatriz: batch.ordemMatriz,
      racaMatriz: batch.racaMatriz,
      idade_Nov_Pri: batch.idadeMatriz,
      dispositivoIntravaginal: batch.dispositivo,
      dataPartosInicio: batch.dataPartosInicio,
      dataPartosFim: batch.dataPartosFim,
      numero_Uso_Disp: batch.numeroUsoDispositivo,
      responsavelId: batch.usuarioId,
    },
    d0,
    user: await utils.getElement(
      AVAILABLE_ENTITIES.USERS,
      (
        await getLoggedUser()
      ).userId
    ),
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(D0IATF)
)
