import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { useHistory, withRouter } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import SaveIcon from '@material-ui/icons/Save'

import { utils } from '../../database'
import { getLoggedUser } from '../../redux/auth/actions'
import { sendData } from '../../syncWorker'
import Prompt from '../../components/Prompt'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import MainContainer from '../../components/MainContainer'
import Button, { COLORS } from '../../components/material/Button'
import * as D from './data'

const ReceiptReleases = (props) => {
  const [fileRejections, setFileRejections] = useState([])
  const [files, setFiles] = useState([])
  const history = useHistory()

  const onDrop = useCallback((acceptedFiles) => {
    setFiles([...acceptedFiles])
  }, [])

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections: DropzoneFileRejections,
  } = useDropzone({
    onDrop,
    multiple: false,
    validator: D.hasProgerarExtension,
  })

  const classes = D.useStyles()

  const uploadFile = async () => {
    if (acceptedFiles.length === 0) {
      return
    }

    try {
      const reader = new FileReader()

      reader.onload = async (event) => {
        const content = event.target.result
        const payloads = JSON.parse(content)

        if (payloads.constructor.name === 'Array') {
          for (const payload of payloads) {
            await D.processPayload(payload, props.token)
          }
        } else {
          await sendData(payloads, props.token)
        }
      }

      reader.readAsText(files[0])

      setFiles([])
    } catch (error) {
      console.error('Erro ao enviar o conteúdo do arquivo', error)
    }
  }

  useEffect(() => {
    setFileRejections(DropzoneFileRejections)
  }, [DropzoneFileRejections])

  return (
    <Container>
      <TopBar title='Lançamento de comprovantes' />
      <TitleBar
        title='Lançamento de comprovantes'
        buttons={[
          {
            onClick: () => history.push('/lancamentos-do-administrador'),
            label: 'Voltar',
          },
        ]}
        paths={[
          {
            route: '/lancamentos-do-administrador',
            label: 'Lançamentos do administrador',
          },
          {
            label: 'Lançamento de comprovantes',
          },
        ]}
      />
      <MainContainer>
        <div {...getRootProps()} className={classes.dropzone}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Solte o arquivo aqui</p>
          ) : (
            <p>Arraste e solte um arquivo aqui ou clique para procurar</p>
          )}
        </div>
        {files.length > 0 && (
          <aside>
            <h4>Arquivo: {files[0].path}</h4>
          </aside>
        )}
        <div className='grid-item p-12 p-display-flex p-justify-space-between'>
          {files.length === 0 ? (
            <div />
          ) : (
            <Button
              color={COLORS.GREY}
              label='Remover arquivo'
              onClick={() => setFiles([])}
            />
          )}
          <Button
            label='Enviar'
            disabled={files.length === 0 || fileRejections.length > 0}
            onClick={uploadFile}
            startIcon={<SaveIcon />}
            containerStyle={{ textAlign: 'right' }}
          />
        </div>
      </MainContainer>

      <Prompt
        visible={fileRejections.length > 0}
        title='Parece que você está tentando enviar um arquivo inválido ou mais de um arquivo!'
        message='Por favor, verifique se o arquivo que você está tentando enviar é um arquivo válido e se você está tentando enviar apenas um arquivo.'
        buttons={[
          {
            label: 'Ok',
            onClick: () => setFileRejections([]),
          },
        ]}
      />
    </Container>
  )
}

const propsFromDatabase = async () => {
  const { token } = await getLoggedUser()

  return {
    token,
  }
}

export default connect()(
  utils.withPropsFromDatabase(propsFromDatabase, withRouter(ReceiptReleases))
)
