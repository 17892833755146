import { createTable, addColumns } from '@nozbe/watermelondb/Schema/migrations'

export default [
  {
    toVersion: 2,
    steps: [
      createTable({
        name: 'users',
        columns: [
          {
            name: 'nomeCompleto',
            type: 'string'
          },
          {
            name: 'email',
            type: 'string'
          },
          {
            name: 'cpf',
            type: 'string'
          },
          {
            name: 'estado',
            type: 'string'
          },
          {
            name: 'municipio',
            type: 'string'
          },
          {
            name: 'created_by_user_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'password',
            type: 'string'
          },
          {
            name: 'salt',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 3,
    steps: [
      createTable({
        name: 'farms',
        columns: [
          {
            name: 'nomeProprietario',
            type: 'string'
          },
          {
            name: 'nome',
            type: 'string'
          },
          {
            name: 'inscricaoEstadual',
            type: 'string',
            isOptional: true
          },
          {
            name: 'pais',
            type: 'string'
          },
          {
            name: 'estado',
            type: 'string',
            isOptional: true
          },
          {
            name: 'municipio',
            type: 'string'
          },
          {
            name: 'tamanho',
            type: 'number',
            isOptional: true
          },
          {
            name: 'temRetiro',
            type: 'boolean'
          },
          {
            name: 'inicioEstacaoMonta_at',
            type: 'number'
          },
          {
            name: 'fimEstacaoMonta_at',
            type: 'number'
          },
          {
            name: 'sistemaProducao_id',
            type: 'string'
          },
          {
            name: 'user_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 4,
    steps: [
      createTable({
        name: 'farms__rel__users',
        columns: [
          {
            name: 'farm_id',
            type: 'string'
          },
          {
            name: 'user_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 5,
    steps: [
      createTable({
        name: 'protocols',
        columns: [
          {
            name: 'name',
            type: 'string'
          },
          {
            name: 'veterinary_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      }),
createTable({
        name: 'management_protocols',
        columns: [
          {
            name: 'day_id',
            type: 'string'
          },
          {
            name: 'implant_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'management_id',
            type: 'string'
          },
          {
            name: 'hormoneType_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'hormoneDosage_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'hormoneDosage_value',
            type: 'string',
            isOptional: true
          },
          {
            name: 'protocol_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 6,
    steps: [
      createTable({
        name: 'batches',
        columns: [
          {
            name: 'nomeLote',
            type: 'string'
          },
          {
            name: 'dataPartosInicio_at',
            type: 'number',
            isOptional: true
          },
          {
            name: 'dataPartosFim_at',
            type: 'number',
            isOptional: true
          },
          {
            name: 'dataIatf_at',
            type: 'number'
          },
          {
            name: 'mineral',
            type: 'string'
          },
          {
            name: 'isResync',
            type: 'boolean'
          },
          {
            name: 'isFinalize',
            type: 'boolean'
          },
          {
            name: 'dispositivo_id',
            type: 'string'
          },
          {
            name: 'numeroUsoDispositivo_id',
            type: 'string'
          },
          {
            name: 'racaMatriz_id',
            type: 'string'
          },
          {
            name: 'ordemMatriz_id',
            type: 'string'
          },
          {
            name: 'idadeMatriz_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'racaBezerro_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'sexoBezerro_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'inducaoPuberdade_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'metodoInducaoPuberdade_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'suplementacao_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'momentoSuplementacaoNovilhas_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'momentoSuplementacaoVacas_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'progesteronaPosIATF_id',
            type: 'string'
          },
          {
            name: 'vacinaReprodutivaLote_id',
            type: 'string'
          },
          {
            name: 'periodoManejo_id',
            type: 'string'
          },
          {
            name: 'dias_id',
            type: 'string'
          },
          {
            name: 'tipoResync_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'diaInicioResync_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'protocol_id',
            type: 'string'
          },
          {
            name: 'user_id',
            type: 'string'
          },
          {
            name: 'corral_id',
            type: 'string'
          },
          {
            name: 'parentBatch_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'originalBatch_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 7,
    steps: [
      createTable({
        name: 'd0s',
        columns: [
          {
            name: 'codVaca',
            type: 'string'
          },
          {
            name: 'codLeitorBastao',
            type: 'string',
            isOptional: true
          },
          {
            name: 'dataPartoIndividual',
            type: 'number',
            isOptional: true
          },
          {
            name: 'peso_EM',
            type: 'number',
            isOptional: true
          },
          {
            name: 'observacao',
            type: 'string',
            isOptional: true
          },
          {
            name: 'dataProcesso_at',
            type: 'number'
          },
          {
            name: 'iatf',
            type: 'number'
          },
          {
            name: 'observacaoDG',
            type: 'string',
            isOptional: true
          },
          {
            name: 'dataDG_at',
            type: 'number',
            isOptional: true
          },
          {
            name: 'dataDGFinal_at',
            type: 'number',
            isOptional: true
          },
          {
            name: 'observacao_DG_Final',
            type: 'string',
            isOptional: true
          },
          {
            name: 'pesoBezerro',
            type: 'number',
            isOptional: true
          },
          {
            name: 'ecc_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'racaMatriz_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'ordemMatriz_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'racaBezerro_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'sexoBezerro_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'idade_Nov_Pri_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'dispositivo_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'numero_Uso_Disp_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'condicaoDG_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'tipoPrenhe_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'clVazia_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'eccDG_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'condicaoDGFinal_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'ecc_final_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'responsavel_id',
            type: 'string'
          },
          {
            name: 'batch_id',
            type: 'string'
          },
          {
            name: 'omit_from_dg_final',
            type: 'boolean'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 8,
    steps: [
      createTable({
        name: 'dns',
        columns: [
          {
            name: 'vacaId',
            type: 'string'
          },
          {
            name: 'observacao',
            type: 'string',
            isOptional: true
          },
          {
            name: 'dia_id',
            type: 'string'
          },
          {
            name: 'batch_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 9,
    steps: [
      createTable({
        name: 'bulls',
        columns: [
          {
            name: 'Cod_Touro',
            type: 'string',
            isOptional: true
          },
          {
            name: 'nome',
            type: 'string'
          },
          {
            name: 'observacao',
            type: 'string',
            isOptional: true
          },
          {
            name: 'partida_at',
            type: 'number',
            isOptional: true
          },
          {
            name: 'central_value',
            type: 'string',
            isOptional: true
          },
          {
            name: 'raca_id',
            type: 'string'
          },
          {
            name: 'central_id',
            type: 'string'
          },
          {
            name: 'farm_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 10,
    steps: [
      createTable({
        name: 'iatfs',
        columns: [
          {
            name: 'vacaId',
            type: 'string'
          },
          {
            name: 'observacao',
            type: 'string'
          },
          {
            name: 'ecc_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'gnhr_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'cioRetirada_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'temperamento_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'bull_id',
            type: 'string'
          },
          {
            name: 'batch_id',
            type: 'string'
          },
          {
            name: 'user_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 11,
    steps: [
      createTable({
        name: 'domain_types',
        columns: [
          {
            name: 'nome',
            type: 'string'
          },
          {
            name: 'descricao',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      }),
createTable({
        name: 'domain_values',
        columns: [
          {
            name: 'valor',
            type: 'string'
          },
          {
            name: 'descricao',
            type: 'string',
            isOptional: true
          },
          {
            name: 'valor_dominio_pai_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'domain_type_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 12,
    steps: [
      createTable({
        name: 'roles',
        columns: [
          {
            name: 'name',
            type: 'string'
          },
          {
            name: 'description',
            type: 'string'
          },
          {
            name: 'backend_id',
            type: 'string'
          },
          {
            name: 'user_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 13,
    steps: [
      createTable({
        name: 'corrals',
        columns: [
          {
            name: 'nome',
            type: 'string'
          },
          {
            name: 'farm_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 14,
    steps: [
      addColumns({
        table: 'd0s',
        columns: [
          {
            name: 'clDzero_id',
            type: 'string',
            isOptional: true
          }
        ]
      })
    ]
  },
{
    toVersion: 15,
    steps: [
      createTable({
        name: 'terms',
        columns: [
          {
            name: 'type',
            type: 'string'
          },
          {
            name: 'version',
            type: 'string'
          },
          {
            name: 'accepted_at',
            type: 'number'
          },
          {
            name: 'backend_identifier',
            type: 'string'
          },
          {
            name: 'ip_address',
            type: 'string'
          },
          {
            name: 'replaced_by',
            type: 'string'
          },
          {
            name: 'user_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 16,
    steps: [
      addColumns({
        table: 'd0s',
        columns: [
          {
            name: 'parentDZero_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'originalDZero_id',
            type: 'string',
            isOptional: true
          }
        ]
      })
    ]
  },
{
    toVersion: 17,
    steps: [
      addColumns({
        table: 'd0s',
        columns: [
          {
            name: 'diasGestacaoTouro',
            type: 'number',
            isOptional: true
          }
        ]
      })
    ]
  },
{
    toVersion: 18,
    steps: [
      addColumns({
        table: 'd0s',
        columns: [
          {
            name: 'perdaGestacao',
            type: 'boolean',
            isOptional: true
          }
        ]
      })
    ]
  },
{
    toVersion: 19,
    steps: [
      addColumns({
        table: 'd0s',
        columns: [
          {
            name: 'clFinal_id',
            type: 'string',
            isOptional: true
          }
        ]
      })
    ]
  },
{
    toVersion: 20,
    steps: [
      addColumns({
        table: 'd0s',
        columns: [
          {
            name: 'tipoPrenheFinal_id',
            type: 'string',
            isOptional: true
          }
        ]
      })
    ]
  },
{
    toVersion: 21,
    steps: [
      addColumns({
        table: 'farms',
        columns: [
          {
            name: 'estacaoMonta_id',
            type: 'string',
            isOptional: true
          }
        ]
      })
    ]
  },
{
    toVersion: 22,
    steps: [
      addColumns({
        table: 'batches',
        columns: [
          {
            name: 'reserved_by',
            type: 'string',
            isOptional: true
          }
        ]
      })
    ]
  },
{
    toVersion: 23,
    steps: [
      createTable({
        name: 'partidaTouro',
        columns: [
          {
            name: 'codigo',
            type: 'string',
            isOptional: true
          },
          {
            name: 'partida_at',
            type: 'number',
            isOptional: true
          },
          {
            name: 'bull_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 24,
    steps: [
      addColumns({
        table: 'bulls',
        columns: [
          {
            name: 'usuario_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'touro_original_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'partida_original_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'cod_partida',
            type: 'string',
            isOptional: true
          }
        ]
      })
    ]
  },
{
    toVersion: 25,
    steps: [
      addColumns({
        table: 'batches',
        columns: [
          {
            name: 'horaManejo',
            type: 'string',
            isOptional: true
          }
        ]
      })
    ]
  },
{
    toVersion: 26,
    steps: [
      addColumns({
        table: 'batches',
        columns: [
          {
            name: 'Matriz_Bloqueada',
            type: 'string',
            isOptional: true
          }
        ]
      })
    ]
  },
{
    toVersion: 27,
    steps: [
      createTable({
        name: 'partida',
        columns: [
          {
            name: 'data',
            type: 'string',
            isOptional: true
          },
          {
            name: 'codigo',
            type: 'string',
            isOptional: true
          },
          {
            name: 'partida_original_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'partida_original',
            type: 'boolean',
            isOptional: true
          },
          {
            name: 'farm_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'bull_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 28,
    steps: [
      createTable({
        name: 'bulls_new',
        columns: [
          {
            name: 'cod_Touro',
            type: 'string',
            isOptional: true
          },
          {
            name: 'nome',
            type: 'string'
          },
          {
            name: 'observacao',
            type: 'string',
            isOptional: true
          },
          {
            name: 'central_value',
            type: 'string',
            isOptional: true
          },
          {
            name: 'usuario_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'raca_id',
            type: 'string'
          },
          {
            name: 'central_id',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 29,
    steps: [
      createTable({
        name: 'users',
        columns: [
          {
            name: 'nomeCompleto',
            type: 'string'
          },
          {
            name: 'email',
            type: 'string'
          },
          {
            name: 'cpf',
            type: 'string'
          },
          {
            name: 'estado',
            type: 'string'
          },
          {
            name: 'municipio',
            type: 'string'
          },
          {
            name: 'zoetisId',
            type: 'string',
            isOptional: true
          },
          {
            name: 'created_by_user_id',
            type: 'string',
            isOptional: true
          },
          {
            name: 'password',
            type: 'string'
          },
          {
            name: 'salt',
            type: 'string'
          },
          {
            name: 'created_at',
            type: 'number'
          },
          {
            name: 'updated_at',
            type: 'number'
          },
          {
            name: 'isDeleted',
            type: 'boolean'
          }
        ]
      })
    ]
  },
{
    toVersion: 30,
    steps: [
      addColumns({
        table: 'batches',
        columns: [
          {
            name: 'processoAtual',
            type: 'string',
            isOptional: true
          }
        ]
      })
    ]
  }
]