/* global fetch */

const DEFAULT_TIMEOUT = 10000

export function getParsedActionEndpoint(url) {
  let endpoint = url ? url.replace(/\//gi, '.').toLowerCase() : ''
  return endpoint.includes('?')
    ? endpoint.substring(0, endpoint.indexOf('?'))
    : endpoint
}

export class TimeoutError extends Error {
  constructor(stack, url, timeout) {
    super()
    this.message = 'TIMEOUT'
  }
}

export function timeoutFetch(url, options, timeout = DEFAULT_TIMEOUT) {
  let st = new Error()
  return Promise.race([
    fetch(url, options),
    new Promise((resolve, reject) =>
      setTimeout(
        () => reject(new TimeoutError(st.stack, url, timeout)),
        timeout
      )
    )
  ])
}
