import { FIELD_TYPE, REQUIRED_FIELD_MESSAGES } from '../../utils/formHelper'
import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import MaterialAutocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'
import uuid from 'react-uuid'

function stripDiacritics(string) {
  return typeof string.normalize !== 'undefined'
    ? string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    : string
}

const autocompleteDefaultOptionLabel = (option) => option
const autocompleteDomainValueOptionLabel = (option) => option.valor
const autocompleteDomainValueOptionLabelUsingDescription = (option) =>
  option.descricao
const autocompleteDomainValueOptionLabelSavingID = (options) => {
  return (option) => {
    if (_.has(option, 'valor')) {
      return option.valor
    }
    const opt = _.find(options, { id: option })
    return (opt && opt.valor) || ''
  }
}

const autocompleteDomainValueOptionLabelSavingIDUsingDescription = (
  options
) => {
  return (option) => {
    if (_.has(option, 'valor')) {
      return option.descricao
    }
    const opt = _.find(options, { id: option })
    return (opt && opt.descricao) || ''
  }
}

const autocompleteOptionLabelUsingCustomField = (field) => {
  return (option) => option && option[field]
}

const autocompleteOptionLabelUsingCustomHandler = (handler) => {
  return (option) => handler(option)
}

const autocompleteOptionLabelUsingCustomFieldSavingID = (options, field) => {
  return (option) => {
    if (_.has(option, field)) {
      return option[field]
    }
    const opt = _.find(options, { id: option })
    return (opt && opt[field]) || ''
  }
}

const autocompleteOptionLabelUsingCustomHandlerSavingID = (
  options,
  handler
) => {
  return (option) => {
    if (_.has(option, 'id')) {
      return handler(option)
    }
    const opt = _.find(options, { id: option })
    return handler(opt) || ''
  }
}

const autocompleteDefaultOptionSelected = (option, value) => option.id === value

function autocompleteDefaultOnChange(
  field,
  message = REQUIRED_FIELD_MESSAGES.DEFAULT
) {
  return (e, value) => {
    this.setState({
      [field]: value,
      [`${field}_error`]:
        this.missingRequiredFieldChecker(field, value) && message,
    })
  }
}

function autocompleteDefaultOnChangeSavingID(
  field,
  message = REQUIRED_FIELD_MESSAGES.DEFAULT
) {
  return (e, value) => {
    this.setState({
      [field]: value && value.id,
      [`${field}_error`]:
        this.missingRequiredFieldChecker(field, value, FIELD_TYPE.USING_ID) &&
        message,
    })
  }
}

const autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField = (
  options,
  field = 'valor'
) => {
  return (option) => {
    if (_.has(option, field)) {
      return option[field]
    }
    const opt = _.find(options, { id: option })
    return (opt && opt[field]) || option || ''
  }
}

function autocompleteDefaultOnChangeSavingIDWhenOpenField(
  field,
  message = REQUIRED_FIELD_MESSAGES.DEFAULT
) {
  return (e, value) => {
    this.setState({
      [field]: value && value.id,
      [`${field}_value`]: (value && value.valor) || value,
      [`${field}_error`]:
        this.missingRequiredFieldChecker(field, value, FIELD_TYPE.DEFAULT) &&
        message,
    })
  }
}

class Autocomplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.id || uuid(),
    }
    this.onChange = this.onChange.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.onInputChange = this.onChange.bind(this)
    this.defaulOnInputChangeForOpenField =
      this.defaulOnInputChangeForOpenField.bind(this)
    this.filterOptionsForOpenField = this.filterOptionsForOpenField.bind(this)
  }

  onChange(e, value) {
    if (this.props.onChange) {
      this.props.onChange(e, value)
    }
  }

  onFocus(e, value) {
    if (this.props.onFocus) {
      this.props.onFocus(e, value)
    }
  }

  onInputChange(e, value) {
    if (this.props.onInputChange) {
      this.props.onChange(e, value)
    }
  }

  onSelect(e, value) {
    if (this.props.onSelect) {
      this.props.onSelect(e, value)
    }
  }

  onKeyDown(e, value) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e, value)
    }
  }

  defaulOnInputChangeForOpenField(e, value, reason) {
    if (reason === 'input') {
      this.onChange(e, value)
    }
  }

  filterOptionsForOpenField(value) {
    return (options, state) => {
      let { inputValue, getOptionLabel } = state
      inputValue = inputValue || value
      let input = stripDiacritics(inputValue ? inputValue.toLowerCase() : '')
      return options.filter((option) => {
        let candidate = stripDiacritics(getOptionLabel(option).toLowerCase())
        return candidate.indexOf(input) > -1
      })
    }
  }

  render() {
    const autocomplete = (
      <MaterialAutocomplete
        freeSolo={this.props.openField}
        disableClearable={this.props.disableClearable}
        multiple={this.props.multiple}
        id={this.state.id}
        options={this.props.options}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onSelect={this.onSelect}
        onKeyDown={this.onKeyDown}
        getOptionLabel={
          this.props.getOptionLabel || autocompleteDefaultOptionLabel
        }
        getOptionSelected={
          this.props.getOptionSelected ||
          (this.props.useDefaultOptionSelected &&
            autocompleteDefaultOptionSelected)
        }
        defaultValue={this.props.defaultValue}
        size={this.props.size}
        disabled={this.props.disabled}
        onInputChange={
          this.props.openField
            ? this.defaulOnInputChangeForOpenField
            : this.props.onInputChange
        }
        filterOptions={
          this.props.openField
            ? this.filterOptionsForOpenField(this.props.value)
            : this.props.filterOptions
        }
        value={this.props.value}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            autoComplete='off'
            {...params}
            size={this.props.size}
            error={this.props.error}
            helperText={this.props.helperText}
            variant='outlined'
            label={this.props.label}
            style={{ width: '100%', backgroundColor: 'white' }}
            inputRef={this.props.inputRef}
            autoFocus={this.props.autoFocus}
            inputProps={{ ...params.inputProps, tabIndex: this.props.tabIndex }}
          />
        )}
        renderTags={this.props.renderTags}
      />
    )
    if (this.props.withGrid) {
      return (
        <Grid item xs={this.props.withGrid}>
          {autocomplete}
        </Grid>
      )
    }
    return autocomplete
  }
}

export {
  autocompleteDefaultOptionLabel,
  autocompleteDomainValueOptionLabel,
  autocompleteDomainValueOptionLabelUsingDescription,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteDomainValueOptionLabelSavingIDUsingDescription,
  autocompleteOptionLabelUsingCustomHandler,
  autocompleteOptionLabelUsingCustomHandlerSavingID,
  autocompleteOptionLabelUsingCustomField,
  autocompleteOptionLabelUsingCustomFieldSavingID,
  autocompleteDefaultOptionSelected,
  autocompleteDefaultOnChange,
  autocompleteDefaultOnChangeSavingID,
  autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField,
  autocompleteDefaultOnChangeSavingIDWhenOpenField,
}
export default Autocomplete
