import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Container from '@material-ui/core/Container'

import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import { getLoggedUser } from '../../redux/auth/actions.js'
import { verify } from '../../utils/verifyDuplicatePages'
import MainContainer from '../../components/MainContainer'
import Prompt from './../../components/Prompt'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import FarmForm from './components/FarmForm'
import track from 'react-tracking'
import TrainingsButton from '../Trainings/TrainingsButton'

@track(() => ({ page: 'Nova Fazenda', date: new Date() }))
class FarmCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.createDefaultCorral = this.createDefaultCorral.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  async createDefaultCorral(retiro, farmId) {
    if (!retiro) {
      const repository = new Repository(AVAILABLE_ENTITIES.CORRALS)

      await repository.post({
        nome: 'DEFAULT',
        fazendaId: farmId
      })
    }
  }

  onCancel() {
    this.props.history.push('/')
  }

  @track((props, state, farm) => ({ action: 'Criou uma nova Fazenda', value: farm[0] }))
  async onSubmit(payload) {
    const repository = new Repository(AVAILABLE_ENTITIES.FARMS)
    const farmUserRepository = new Repository(AVAILABLE_ENTITIES.FARM_USERS)
    const createReq = await repository.post(payload)
    if (createReq.success) {
      const farm = createReq.response
      let success = true
      await Promise.all(
        payload.veterinarios.map(async user => {
          const req = await farmUserRepository.post({
            idFazenda: farm.id,
            idUsuario: user.id
          })
          if (!req.success) {
            console.log(
              `Error while creating relation of farm ${farm.id} with user ${user.id}: ${req.exception}`
            )
            success = false
          }
        }),
        await this.createDefaultCorral(payload.retiro, farm.id)
      )
      if (success) {
        console.log('Farm created successfully')
        this.props.history.push('/')
      }
    } else {
      console.log(
        `There was an error trying to create farm: ${createReq.exception}`
      )
    }
  }

  componentDidUpdate() { }

  render() {
    const initialValues = {
      nomeProprietario: null,
      nome: null,
      inscricaoEstadual: null,
      pais: 'Brasil',
      estado: null,
      municipio: null,
      tamanho: null,
      retiro: null,
      inicioEstacaoMonta: null,
      fimEstacaoMonta: null,
      estacaoMonta: null,
      veterinarios: [this.props.user]
    }

    return (
      <Container>
        <TopBar title='' />
        <TitleBar
          title='Nova Fazenda'
          paths={[
            {
              label: 'Nova Fazenda'
            }
          ]}
        />
        <TrainingsButton page="/farms/create" />
        <MainContainer>
          <FarmForm
            initialValues={initialValues}
            onCancel={this.onCancel}
            onSubmit={this.onSubmit}
            user={this.props.user}
          />

          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false
              },
            ]}
          />

        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async props => ({
  user: await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    (await getLoggedUser()).userId
  )
})

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(FarmCreate)
)
