import { idECC } from './idsHelper'

export const eccSpeechRecognition = (answer: string | null) => {
  if (
    answer === '1.' ||
    answer === 'Um.' ||
    answer === '1' ||
    answer === 'Um'
  ) {
    return idECC.one
  }
  if (
    answer === '1 e 25.' ||
    answer === 'Um ponto 25.' ||
    answer === '1,25.' ||
    answer === '1 e 25' ||
    answer === 'Um ponto 25' ||
    answer === '1,25'
  ) {
    return idECC.oneTwentyFive
  }
  if (
    answer === '1.5.' ||
    answer === '1,5.' ||
    answer === 'Um e-mail.' ||
    answer === 'Um e meio.' ||
    answer === '1.5' ||
    answer === '1,5' ||
    answer === 'Um e-mail' ||
    answer === 'Um e meio'
  ) {
    return idECC.oneAndFive
  }
  if (
    answer === '1 e 75.' ||
    answer === '1,75.' ||
    answer === 'Um ponto 75.' ||
    answer === '1 e 75' ||
    answer === '1,75' ||
    answer === 'Um ponto 75'
  ) {
    return idECC.oneAndSeventyFive
  }
  if (
    answer === '2.' ||
    answer === 'Dois.' ||
    answer === '2' ||
    answer === 'Dois'
  ) {
    return idECC.two
  }
  if (
    answer === '2 e 25.' ||
    answer === '2, ponto 25.' ||
    answer === '2,25.' ||
    answer === '2 e 25' ||
    answer === '2, ponto 25' ||
    answer === '2,25'
  ) {
    return idECC.twoTwentyFive
  }
  if (
    answer === '2.5.' ||
    answer === '2 e meio.' ||
    answer === '2 e 5.' ||
    answer === '2,5.' ||
    answer === '2.5' ||
    answer === '2 e meio' ||
    answer === '2 e 5' ||
    answer === '2,5'
  ) {
    return idECC.twoAndFive
  }
  if (
    answer === '2 e 75.' ||
    answer === '2,75.' ||
    answer === '2 pontos 75.' ||
    answer === '2 ponto 75.' ||
    answer === '2 e 75' ||
    answer === '2,75' ||
    answer === '2 pontos 75' ||
    answer === '2 ponto 75'
  ) {
    return idECC.twoAndSeventyFive
  }
  if (
    answer === '3.' ||
    answer === 'Três.' ||
    answer === '3' ||
    answer === 'Três'
  ) {
    return idECC.three
  }
  if (
    answer === '3 e 25.' ||
    answer === '3,25.' ||
    answer === '3, ponto 25.' ||
    answer === '3 e 25' ||
    answer === '3,25' ||
    answer === '3, ponto 25'
  ) {
    return idECC.threeTwentyFive
  }
  if (
    answer === '3.5.' ||
    answer === '3 e meio.' ||
    answer === '3 e 5.' ||
    answer === '3,5.' ||
    answer === '3.5' ||
    answer === '3 e meio' ||
    answer === '3 e 5' ||
    answer === '3,5'
  ) {
    return idECC.threeAndFive
  }
  if (
    answer === '3 e 75.' ||
    answer === '3, 75.' ||
    answer === '3,75.' ||
    answer === '3, ponto 75.' ||
    answer === '3 e 75' ||
    answer === '3, 75' ||
    answer === '3,75' ||
    answer === '3, ponto 75'
  ) {
    return idECC.threeAndSeventyFive
  }
  if (
    answer === '4.' ||
    answer === 'Quatro.' ||
    answer === '4' ||
    answer === 'Quatro'
  ) {
    return idECC.four
  }
  if (
    answer === '4 e 25.' ||
    answer === '4,25.' ||
    answer === '4, ponto 25.' ||
    answer === '4 e 25' ||
    answer === '4,25' ||
    answer === '4, ponto 25'
  ) {
    return idECC.fourTwentyFive
  }
  if (
    answer === '4.5.' ||
    answer === '4 e meio.' ||
    answer === '4 e 5.' ||
    answer === '4,5.' ||
    answer === '4.5' ||
    answer === '4 e meio' ||
    answer === '4 e 5' ||
    answer === '4,5'
  ) {
    return idECC.fourAndFive
  }
  if (
    answer === '4 e 75.' ||
    answer === '4,75.' ||
    answer === '4, ponto 75.' ||
    answer === '4 e 75' ||
    answer === '4,75' ||
    answer === '4, ponto 75'
  ) {
    return idECC.fourAndSeventyFive
  }
  if (
    answer === '5.' ||
    answer === 'Cinco.' ||
    answer === '5' ||
    answer === 'Cinco'
  ) {
    return idECC.five
  }

  return null
}
