import '../../components/styles/customstyle.scss'

import { utils } from '../../database'
import React, { Component } from 'react'
import { Container, Grid } from '@material-ui/core'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as sync from '../../syncWorker'
import Button from '../../components/material/Button'

class Export extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }

  }

  render() {

    return (
      <Container>
        <div style={{
          width: '100%',
          margin: '50px auto',
          textAlign: 'center'
        }}>
          <Grid item>
            <Button
              key='Fazendas'
              style={{
                marginLeft: '1em'
              }}
              onClick={() => sync.exportDb('fazendas')}
              label='Fazendas'
            />
            <Button
              key='Retiros'
              style={{
                marginLeft: '1em'
              }}
              onClick={() => sync.exportDb('retiros')}
              label='Retiros'
            />
            <Button
              key='Lotes'
              style={{
                marginLeft: '1em'
              }}
              onClick={() => sync.exportDb('lotes')}
              label='Lotes'
            />
            <Button
              key='Protocolos'
              style={{
                marginLeft: '1em'
              }}
              onClick={() => sync.exportDb('protocolos')}
              label='Protocolos'
            />
            <Button
              key='D0s'
              style={{
                marginLeft: '1em'
              }}
              onClick={() => sync.exportDb('d0s')}
              label='D0s'
            />
            <Button
              key='DNs'
              style={{
                marginLeft: '1em'
              }}
              onClick={() => sync.exportDb('dns')}
              label='DNs'
            />
            <Button
              key='IATFs'
              style={{
                marginLeft: '1em'
              }}
              onClick={() => sync.exportDb('iatfs')}
              label='IATFs'
            />
            <Button
              key='Fazenda-Usuários'
              style={{
                marginLeft: '1em'
              }}
              onClick={() => sync.exportDb('fazendaUsuarios')}
              label='Usuários'
            />
            <Button
              key='Sêmens'
              style={{
                marginLeft: '1em'
              }}
              onClick={() => sync.exportDb('touros')}
              label='Sêmens'
            />
            <Button
              key='Tudo'
              style={{
                marginLeft: '1em'
              }}
              onClick={() => sync.downloadBackup()}
              label='Tudo'
            />
          </Grid>
        </div>
      </Container>
    )
  }
}

const propsFromDatabase = async props => {
  return null
}

export default connect(
  null,
)(utils.withPropsFromDatabase(propsFromDatabase, withRouter(Export)))
