import { AVAILABLE_ENTITIES, Repository } from '../../database/'
import React, { Component } from 'react'

import Container from '@material-ui/core/Container'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt'
import ProtocolForm from './components/ProtocolForm'
import TitleBar from './../../components/TitleBar'
import TopBar from './../../components/TopBar'
import _ from 'lodash'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

class ProtocolCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  onCancel() {
    this.props.history.push('/protocols')
  }

  async onSubmit(payload) {
    const protocolsRepository = new Repository(AVAILABLE_ENTITIES.PROTOCOLS)
    const managementProtocolsRepository = new Repository(
      AVAILABLE_ENTITIES.MANAGEMENT_PROTOCOLS
    )

    const createReq = await protocolsRepository.post(payload)
    if (createReq.success) {
      const createdProtocol = createReq.response
      const managementRequests = await Promise.all(
        payload.managementProtocols.map(managementProtocol =>
          managementProtocolsRepository.post({
            ...managementProtocol,
            managementProtocolId: createdProtocol.id
          })
        )
      )
      let success = true
      _.each(managementRequests, request => {
        if (!request.success) {
          success = false
        }
      })
      if (success) {
        console.log(`Protocol Created Successfully`)
        this.props.history.push('/protocols')
      } else {
        console.log(
          'There was an error creating at least one of managementProtocols'
        )
      }
    } else {
      console.log(
        `There was an error creating main protocol: ${createReq.exception}`
      )
    }
  }

  render() {
    return (
      <Container>
        <TopBar title='' />
        <TitleBar
          title='Novo Protocolo'
          paths={[
            {
              route: '/protocols',
              label: 'Protocolos'
            },
            {
              label: 'Novo Protocolo'
            }
          ]}
        />

        <MainContainer>
          <ProtocolForm onCancel={this.onCancel} onSubmit={this.onSubmit} />
        </MainContainer>

        <Prompt
          visible={verify()}
          title='Página duplicada!'
          message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
          buttons={[
            {
              autoFocus: false
            },
          ]}
        />
      </Container>
    )
  }
}

export default withRouter(ProtocolCreate)
