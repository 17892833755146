import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs, Grid } from '@material-ui/core'

import { LocalStorageHelper } from '../utils/localStorageHelper'
import Button from './material/Button'
import Prompt from './Prompt'
import ZoetisLogo from '../assets/img/logozoetis_menu.png'
import DropdownButton from './DropdownButton'

const StyledLink = (props) => (
  <Link
    to={props.to}
    style={{ color: 'rgb(234,93,13)', textDecoration: 'none' }}
  >
    {props.children}
  </Link>
)

class TitleBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      exportModal: true,
    }
    this.generatePaths = this.generatePaths.bind(this)
  }

  generatePaths() {
    if (this.props.paths) {
      if (this.props.paths.length === 1) {
        return <div>{this.props.paths[0].label}</div>
      }
      return this.props.paths.map((path, index) =>
        path.route ? (
          <StyledLink key={`Breadcrumb_path_${index}`} to={path.route}>
            {path.label}
          </StyledLink>
        ) : (
          <div key={`Breadcrumb_path_${index}`}>{path.label}</div>
        )
      )
    }
    return null
  }

  render() {
    return (
      <>
        <section className={'app-title-bar ' + this.props.className}>
          <div
            className='app-title-bar-content'
            style={{ backgroundImage: `url(${ZoetisLogo})` }}
          >
            <Grid
              container
              spacing={3}
              direction='column'
              style={{ padding: '1em' }}
            >
              <Grid item container>
                <Grid item className='p-flex-1'>
                  <Breadcrumbs
                    aria-label='breadcrumb'
                    style={{ fontSize: '1em', minHeight: '36px' }}
                  >
                    <StyledLink to='/'>ProGERAR</StyledLink>
                    {this.generatePaths()}
                  </Breadcrumbs>
                </Grid>

                <Grid item className='app-title-bar-content-buttons'>
                  <div className='app-title-bar-content-buttons-wrapper'>
                    <div className='app-title-bar-content-buttons-wrapper-info'>
                      {this.props.buttons &&
                        this.props.buttons.map((button) => {
                          if (button.hasDropdown) {
                            return (
                              <DropdownButton
                                key={button.label}
                                style={{ ...button.style }}
                                className={button.className}
                                onClick={button.onClick}
                                label={button.label}
                                buttons={button.buttons}
                                disabled={button.disabled}
                              />
                            )
                          }
                          return (
                            <Button
                              key={button.label}
                              style={{ ...button.style }}
                              className={button.className}
                              onClick={button.onClick}
                              startIcon={button.icon}
                              label={button.label}
                              disabled={button.disabled}
                            />
                          )
                        })}
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid item container style={{ justifyContent: 'space-between' }}>
                <Grid item>
                  <div className='app-title-bar-content-title'>
                    {this.props.title}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </section>

        <Prompt
          visible={
            !!LocalStorageHelper.get('modalErrorText') &&
            this.state.exportModal !== null
          }
          title={`Não foi possível obter informações para ${LocalStorageHelper.get('modalTitleErrorText') == 'Fazenda' ? 'esta' : 'este'} ${LocalStorageHelper.get('modalTitleErrorText')}`}
          message={LocalStorageHelper.get('modalErrorText')}
          buttons={[
            {
              label: 'Ok',
              onClick: () => {
                LocalStorageHelper.remove('modalErrorText')
                this.setState({ exportModal: null })
              },
            },
          ]}
        />
      </>
    )
  }
}

export default TitleBar
