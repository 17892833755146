import clearCache from './clearCache'
import { forceSync } from '../syncWorker'

export default function clearStorage(syncStatus: number): void {
  syncStatus != 2 && forceSync(false)

  var firstDBDelete = indexedDB.deleteDatabase('loki.db');
  var seccondDBDelete = indexedDB.deleteDatabase('WatermelonIDBChecker')
  firstDBDelete.onsuccess = () => {
    console.log("Deleted database successfully");
  };
  firstDBDelete.onerror = () => {
    console.log("Couldn't delete database");
  };
  firstDBDelete.onblocked = () => {
    console.log("Couldn't delete database due to the operation being blocked");
  };
  seccondDBDelete.onsuccess = () => {
    console.log("Deleted database successfully");
  };
  seccondDBDelete.onerror = () => {
    console.log("Couldn't delete database");
  };
  seccondDBDelete.onblocked = () => {
    console.log("Couldn't delete database due to the operation being blocked");
  };
  // localStorage.clear();
  clearCache()
}