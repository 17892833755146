import 'babel-polyfill'

import * as serviceWorker from './serviceWorker'

// import { BrowserRouter } from 'react-router-dom'
import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import database from './database'
import { router } from './router.js'
import { startSyncingLoop } from './syncWorker'
import { store } from './store.js'
import '../src/assets/scss/main.scss'
import clearCache from './utils/clearCache'
import { startTrackingLoop } from './utils/tracking'

//import worker from 'workerize-loader?inline!./syncWorker'

//const syncWorker = worker()

/*syncWorker.addEventListener('message', message => {
  const prefix = 'Message from SyncWorker: '
  const data = `${message.data}`
  if (data.includes('[WARN]')) {
    console.warn(prefix, message.data)
  } else if (data.includes('[ERROR]')) {
    console.error(prefix, message.data)
  } else {
    console.log(prefix, message.data)
  }
})*/

startSyncingLoop()
startTrackingLoop()

document.documentElement.lang = 'pt-br'

// render the main component
ReactDOM.render(
  <DatabaseProvider database={database}>
    <Provider store={store}>{router}</Provider>
  </DatabaseProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister()
serviceWorker.register({ onUpdate: () => clearCache() })

