/* eslint-disable no-undef */

import './loginGigya.scss'

import * as api from '../../redux/api/actions.js'

import Autocomplete, { autocompleteDefaultOnChange, autocompleteDefaultOnChangeSavingID } from '../../components/material/Autocomplete.js'
import { Backdrop, CircularProgress, Grid, Typography } from '@material-ui/core'
import EulaDialog, {
  COOKIES_BACKEND_IDENTIFIER,
  COOKIES_NAME,
  PRIVACY_BACKEND_IDENTIFIER,
  PRIVACY_NAME,
  USERS_LICENSE_BACKEND_IDENTIFIER,
  USERS_LICENSE_NAME
} from './eula/EulaDialog'
import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import {
  acceptTerm,
  dismissEula,
  loginWithoutPassword,
  logout
} from '../../redux/auth/actions.js'
import { missingRequiredFieldChecker, requiredFieldsEvaluator } from '../../utils/formHelper.js'

import { Alert } from '@material-ui/lab'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { LocalStorageHelper } from '../../utils/localStorageHelper'
import LoginBackground from '../../assets/img/login-background.jpg'
import Prompt from '../../components/Prompt.js'
import { ThemeProvider } from '@material-ui/styles'
import ZoetisLogo from '../../assets/img/logo_corte.png'
import { connect } from 'react-redux'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import { textfieldDefaultOnChange } from '../../components/material/TextField.js'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#EA5D0D'
    }
  }
})

const termsMapping = {
  [COOKIES_BACKEND_IDENTIFIER]: COOKIES_NAME,
  [PRIVACY_BACKEND_IDENTIFIER]: PRIVACY_NAME,
  [USERS_LICENSE_BACKEND_IDENTIFIER]: USERS_LICENSE_NAME
}

class LoginGigya extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: '',
      email: !!LocalStorageHelper.get('GerarNotFoundUser') ? JSON.parse(LocalStorageHelper.get('GerarNotFoundUser')).email : '',
      userId: !!LocalStorageHelper.get('GerarNotFoundUser') ? JSON.parse(LocalStorageHelper.get('GerarNotFoundUser')).userId : '',
      password: !!LocalStorageHelper.get('GerarNotFoundUser') ? JSON.parse(LocalStorageHelper.get('GerarNotFoundUser')).password : '',
      userName: !!LocalStorageHelper.get('GerarNotFoundUser') ? JSON.parse(LocalStorageHelper.get('GerarNotFoundUser')).userName : '',
      role: '',
      cookiesVersion: null,
      privacyVersion: null,
      usersLicenseVersion: null,
      viewRegisterModal: !!LocalStorageHelper.get('GerarNotFoundUser') && !(!!LocalStorageHelper.get('GerarTermActive')) ? true : false,
      viewUserDeletedModal: !!LocalStorageHelper.get('GerarUserDeleted') ? true : false,
      helperGigyaModal: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.onLoginHandler = this.onLoginHandler.bind(this)
    this.onAcceptTerms = this.onAcceptTerms.bind(this)

    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)
    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.autocompleteDefaultOnChangeSavingID = autocompleteDefaultOnChangeSavingID.bind(
      this
    )
  }

  onGigyaServiceReady() {
    gigya.accounts.showScreenSet({
      screenSet: 'RegistrationLogin',
      startScreen: 'gigya-login-screen',
      lang: 'pt',
      containerID: 'container-gigya-login',
      onSubmit: (event) => {
        this.setState({
          password: event.formModel.password,
        }, () => {
          gigya.socialize.addEventHandlers({
            onLogin: this.onLoginHandler
          });
        });
      }
    });
  }


  onLoginHandler(eventObj) {
    let formatedObj = {
      userId: eventObj.user.UID,
      login: eventObj.user.email,
      password: this.state.password == '' ? '123456789' : this.state.password,
      tipo: 1,
      helperText: '',
      error: false,
      cookiesVersion: null,
      privacyVersion: null,
      usersLicenseVersion: null,
      name: eventObj.user.firstName + ' ' + eventObj.user.lastName,
    }
    this.setState({
      email: eventObj.user.email || eventObj.profile.email,
      userId: eventObj.user.UID || eventObj.profile.UID,
      userName: eventObj.user.firstName + ' ' + eventObj.user.lastName,
    })

    this.props.login(formatedObj)
  }

  UNSAFE_componentWillMount() {
    this.props.logout()
  }

  componentDidMount() {
    this.onGigyaServiceReady()
  }

  handleSubmit() {
    //Setando os valores para usuario => email || cpf
    let valor = this.state.login

    localStorage.setItem('L - ' + new Date(), JSON.stringify({ email: this.state.login }))
    // valor = valor.replace('.', '').replace('.', '').replace('-', '');
    this.setState({ login: valor })

    this.props.login({
      login: this.state.email,
      password: this.state.password,
      userId: this.state.userId,
      userName: this.state.userName
    })

    LocalStorageHelper.remove('GerarTermActive')
    LocalStorageHelper.remove('GerarNotFoundUser')
  }

  async onAcceptTerms() {
    this.props.dismissEula()
    const token = this.props.token
    let success = true
    for (const BACKEND_IDENTIFIER of [
      COOKIES_BACKEND_IDENTIFIER,
      PRIVACY_BACKEND_IDENTIFIER,
      USERS_LICENSE_BACKEND_IDENTIFIER
    ]) {
      const termAccepted = await this.props.acceptTerm({
        type: BACKEND_IDENTIFIER,
        token
      })

      if (termAccepted) {
        this.setState({ [termsMapping[BACKEND_IDENTIFIER]]: termAccepted })
      } else {
        success = false
      }
    }
    if (success) {
      this.handleSubmit()
    }
  }

  render() {
    if (this.props.loggedIn) return <Redirect to='/' />
    if (this.props.showLoading) {
      return (
        <Backdrop open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )
    }

    const rolesOptions = [
      { id: "9c13e4c0-c204-4be7-9381-caa97afb3c3b", name: "Inseminador" },
      { id: "a8454122-2d9a-4cea-b610-e76163cd7774", name: "Veterinário" },
      { id: "92aa6422-c4b3-4d52-aa43-7d5ef7bfa7f1", name: "Estagiário" },
      { id: "adb7f205-7d82-4b5c-91f8-8e4bc8a6fa3a", name: "Cliente (somente visualização)" },
    ]

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid container className='page-login'>
          <Grid item lg={6} md={0} className="page-login-form" style={{ backgroundImage: `url(${LoginBackground})` }} />
          <Grid item md={12} lg={6}>
            <Container maxWidth='xs'>
              <Typography
                component='div'
                style={{ height: '100vh', marginTop: '100px' }}
              >
                <img
                  src={ZoetisLogo}
                  alt='Zoetis Gerar'
                  className="p-full-width"
                  style={{ width: '100%' }}
                />

                {this.props.error && (
                  <Alert severity='error' style={{ marginTop: '1em' }}>
                    {this.props.error.title}
                  </Alert>
                )}

                <Container disableGutters>
                  <div id='container-gigya-login' className='loginGigya' />
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}>

                    <Typography
                      component='div'
                      style={{
                        marginTop: '14px',
                        fontSize: '14px',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: '#f87d33',
                      }}
                      onClick={() => this.setState({ helperGigyaModal: true })}
                    >
                      Está com problemas para fazer o Login?
                    </Typography>
                  </div>
                </Container>
              </Typography>
            </Container>
          </Grid>
        </Grid>
        <Container disableGutters>
          <EulaDialog
            visible={this.props.shouldAcceptEULA}
            onAccept={this.onAcceptTerms}
          />
        </Container>
        <Prompt
          visible={this.state.helperGigyaModal}
          title='Não perca seu acesso, fique atento às mudanças no seu login!'
          message="Alteramos nosso processo de autenticação. Para não perder o seu acesso ao ProGerar, por favor, clique na opção 'Esqueci minha senha'. Na próxima etapa informe o seu e-mail utilizado no ProGerar, você receberá um e-mail com instruções para inserir a senha nova e utilizá-la daqui pra frente ao entrar na aplicação. Caso tenha dúvidas ou problemas, entre em contato com nossa equipe de atendimento, estaremos à disposição."
          buttons={[
            {
              label: 'Fechar',
              onClick: () => this.setState({ helperGigyaModal: false })
            },
          ]}
        />
        <Prompt
          visible={this.state.viewRegisterModal}
          title='Parece que você é novo no ProGerar. Por favor, preencha o Perfil para continuar.'
          message={
            <div>
              <div className="p-12 d-4 p-margin-top-2 grid-item">
                <Autocomplete
                  id='role'
                  label='Perfil'
                  options={rolesOptions}
                  onChange={this.autocompleteDefaultOnChangeSavingID('role')}
                  getOptionLabel={option => {
                    if (_.has(option, 'name')) {
                      return option.name
                    }
                    const opt = _.find(rolesOptions, { id: option })
                    return (opt && opt.name) || ''
                  }}
                  useDefaultOptionSelected
                  value={this.state.role || ''}
                />
              </div>
            </div>
          }
          buttons={[
            {
              label: 'Cancelar',
              onClick: () => {
                LocalStorageHelper.remove('GerarNotFoundUser')
                this.setState({ viewRegisterModal: false })
              }
            },
            {
              label: 'Salvar',
              onClick: () => {
                const payload = {
                  email: this.state.email,
                  password: this.state.password,
                  rolesIds: [this.state.role],
                  userId: this.state.userId,
                  userName: this.state.userName,
                }

                this.props.login(payload)
                this.setState({ viewRegisterModal: false })
                LocalStorageHelper.remove('GerarNotFoundUser')
              }
            },
          ]}
        />
        <Prompt
          visible={this.state.viewUserDeletedModal}
          title='Usuário excluído'
          message='O seu usuário foi excluído do ProGerar. Por favor, entre em contato com a nossa equipe de atendimento para mais informações.'
          buttons={[
            {
              label: 'Fechar',
              onClick: () => {
                LocalStorageHelper.remove('GerarUserDeleted')
                this.setState({ viewUserDeletedModal: false })
              }
            },
          ]}
        />
      </ThemeProvider>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: params => window.location.pathname === '/login' && dispatch(loginWithoutPassword(params)),
    logout: params => dispatch(logout()),
    acceptTerm: ({ type, token }) => dispatch(acceptTerm({ type, token })),
    dismissEula: () => dispatch(dismissEula())
  }
}

const mapStateToProps = state => ({
  error: api.getApiError(state, `Authentication/LoginGigya`) || false,
  success: api.getApiResult(state, `Authentication/LoginGigya`) || false,
  loading: api.getApiLoading(state, `Authentication/LoginGigya`),
  showLoading: state.screen.loading,
  loggedIn: state.auth.loggedIn,
  shouldAcceptEULA: state.auth.shouldAcceptEULA,
  token: state.auth.token
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginGigya))
