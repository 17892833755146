/* eslint-disable no-undef */
import * as api from '../../redux/api/actions.js'
import * as sync from '../../../src/syncWorker'

import { withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AVAILABLE_ENTITIES } from '../../database'
import _ from 'lodash'
import { getLoggedUser } from '../../redux/auth/actions'
import { utils } from '../../database'
import Prompt from '../../components/Prompt.js'
import hasInternet from '../../utils/recognizeInternetConnection'

class RegisterGigya extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: null,
      isZoetisIdModalOpen: true,
      zoetisId: this.props.user.zoetisId,
      noConnection: false,
      hasError: false,
      text: '',
      checked: false,
    }
  }

  async onSubmit(payload) {
    await this.props.create(payload).then((result) => {
      if (!result?.hasErrors) {
        if (!this.props.isAdministrator) {
          alert("Usuário criado com sucesso!")
          this.props.history.push('/users')
          this.initSync()
        } else {
          this.props.history.push('/users')
        }
      } else {
        this.setState({
          hasError: true,
          text: result.title
        })
      }
    }
    )
  }

  async initSync() {
    if (hasInternet()) {
      await sync.forceSync(false, false).then(() => this.props.history.push('/users'))

    } else {
      this.setState({ noConnection: true })
    }
  }


  onGigyaServiceReady() {
    gigya.accounts.showScreenSet({
      screenSet: 'RegistrationLogin',
      startScreen: 'gigya-register-screen',
      lang: 'pt',
      containerID: 'container-gigya-register',
      onSubmit: async (event) => {
        let role = event.formModel.data.qualificationType
        let rolesIds = []
        this.props.availableRoles.map(x => {
          role == 'Vets' && x.name == 'Veterinário' && rolesIds.push(x.id)
          role == 'Farmers' && x.name == 'Inseminador' && rolesIds.push(x.id)
          role == 'Intern' && x.name == 'Estagiário' && rolesIds.push(x.id)
          role == 'Customer' && x.name == 'Cliente (somente visualização)' && rolesIds.push(x.id)
        })

        let payload = {
          email: event.formModel.email,
          nomeCompleto: `${event.formModel.profile.firstName} ${event.formModel.profile.lastName}`,
          rolesIds,
          superiorId: this.props.user.id,
          zoetisId: event.formModel.data.dmrId,
        }

        this.setState({ data: payload })
      }
    })
    gigya.socialize.addEventHandlers({
      onAfterResponse: (eventObj) => {
        this.setState({
          data: {
            ...this.state.data,
            id: eventObj.response.user.UID,
            password: eventObj.filteredParams.password
          }
        })

        this.onSubmit(this.state.data)
      },
    })
  }

  UNSAFE_componentWillMount() {
    this.props.getAvailableRoles()
  }

  componentDidMount() {
    this.onGigyaServiceReady()
  }

  render() {
    return (
      <div>
        <div id='container-gigya-register' style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: 'center',
        }} />
        {!!this.state.zoetisId && (
          <Prompt
            visible={this.state.isZoetisIdModalOpen}
            title='Número da conta Zoetis!'
            message={`Para se cadastrar, você precisa do número da conta Zoetis. Aqui está: ${this.state.zoetisId}. Clique em "Copiar" e cole no campo "Número da conta Zoetis".`}
            buttons={[
              {
                label: 'Fechar',
                onClick: () => this.setState({ isZoetisIdModalOpen: false }),
              },
              {
                label: 'Copiar',
                onClick: () => {
                  navigator.clipboard.writeText(this.state.zoetisId)
                  this.setState({ isZoetisIdModalOpen: false })
                },
              },
            ]}
          />
        )}

        <Prompt
          visible={this.state.hasError}
          title='Erro ao inserir usuário'
          message={this.state.text}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ hasError: false })
            }
          ]}
        />

        <Prompt
          visible={this.state.noConnection}
          title='Não é possível realizar esta ação!'
          message={`Você está sem internet no momento. Verifique sua conexão e tente novamente mais tarde.`}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ noConnection: false })
            }
          ]}
        />
      </div>
    )
  }
}

const propsFromDatabase = async (props) => {
  const loggedUser = await getLoggedUser()
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    loggedUser.userId
  )
  const isAdministrator = _.find(
    loggedUser.roles,
    (el) => el === 'Administrador'
  )

  return {
    user,
    isAdministrator
  }
}

const mapDispatchToProps = dispatch => {
  return {
    create: async params => api.post(dispatch, 'users', `Account/RegisterGigya`, params),
    getAvailableRoles: () => api.get(dispatch, 'users', `Profile/`)
  }
}

const mapStateToProps = state => ({
  loading:
    api.getApiLoading(state, `Account/RegisterGigya`) ||
    api.getApiLoading(state, `Profile/`),
  availableRoles: api.getApiResult(state, `Profile/`) || [],
  error: api.getApiError(state, `Account/RegisterGigya`),
  success: api.getApiResult(state, `Account/RegisterGigya`)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.withPropsFromDatabase(propsFromDatabase, withRouter(RegisterGigya)))
