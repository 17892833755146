import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  boxMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.8, 1.6),
    backgroundColor: '#ea5d0d',
    borderRadius: 8,
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#ff7f00',
    },
  },
}))

export const itens = [
  {
    id: 1,
    title: 'Lançamento de valores domínio',
    route: '/lancamentos-de-valores-dominio'
  },
  {
    id: 1,
    title: 'Lançamento de tipos domínio',
    route: '/lancamentos-de-tipos-dominio'
  },
  {
    id: 2,
    title: 'Lançamento de comprovantes',
    route: '/lancamentos-de-comprovantes'
  },
]