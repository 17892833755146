import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { connect } from 'react-redux'
import _ from 'lodash'
import { read, utils as xlsxUtils } from 'xlsx'
import { useHistory, withRouter } from 'react-router-dom'
import { CircularProgress, Container } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'

import { getImportDataTemplate } from '../../redux/reports/actions'
import hasInternet from '../../utils/recognizeInternetConnection'
import { LocalStorageHelper } from '../../utils/localStorageHelper'
import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import TitleBar from '../../components/TitleBar'
import MainContainer from '../../components/MainContainer'
import TopBar from '../../components/TopBar'
import Button, { COLORS } from '../../components/material/Button'
import Prompt from '../../components/Prompt'
import TourButton from '../../components/TourButton'
import * as D from './data'

const ImportData = (props) => {
  const [databaseProps, setDatabaseProps] = useState(props)
  const [fileRejections, setFileRejections] = useState([])
  const [files, setFiles] = useState([])
  const [isUploadedFile, setIsUploadedFile] = useState(false)
  const [fileData, setFileData] = useState({
    userData: [],
    data: [],
  })
  const [errorsInSheetData, setErrorsInSheetData] = useState([])
  const [errorsMessage, setErrorsMessage] = useState({
    title: '',
    message: '',
  })
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const classes = D.useStyles()

  const onDrop = useCallback(
    (acceptedFiles) => {
      const currentFile = acceptedFiles[0]
      setFiles([...acceptedFiles])
      setIsUploadedFile(false)

      const reader = new FileReader()

      reader.onload = (e) => {
        const data = e.target.result
        const workbook = read(data, { type: 'binary' })

        const importUserData = workbook.SheetNames[0]
        const importData = workbook.SheetNames[1]

        const userDataSheet = workbook.Sheets[importUserData]
        const dataSheet = workbook.Sheets[importData]

        const userJsonData = xlsxUtils.sheet_to_json(userDataSheet)
        const jsonData = xlsxUtils.sheet_to_json(dataSheet)

        const formattedUserData = D.formatDataToCorrectType(userJsonData)
        const formattedData = D.formatSecondSheetData(jsonData)

        const { hasAllRequiredFields, hasDayError } = D.hasAllRequiredFields(
          userJsonData,
          formattedUserData,
          databaseProps?.d0Day,
          databaseProps?.dnsDays,
          databaseProps?.iatfDay,
          databaseProps?.batch.isFinalize,
          databaseProps?.passDay,
          databaseProps?.currentDay
        )

        if (hasAllRequiredFields.length > 0) {
          setErrorsInSheetData(hasAllRequiredFields)
        }

        if (hasDayError.title !== '' && hasDayError.message !== '') {
          setErrorsMessage(hasDayError)
        }

        if (formattedUserData.length === 0) {
          setErrorsMessage({
            title: 'Sem dados para importar',
            message: 'Preencha os dados para importar',
          })
          return
        }

        setFileData({
          userData: formattedUserData,
          data: formattedData,
        })
      }

      reader.readAsBinaryString(currentFile)
    },
    [
      databaseProps.currentDay,
      databaseProps.d0Day,
      databaseProps.dnsDays,
      databaseProps.batch.isFinalize,
      databaseProps.passDay,
      databaseProps.iatfDay,
    ]
  )

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections: DropzoneFileRejections,
  } = useDropzone({
    onDrop,
    multiple: false,
    validator: D.hasXlsOrXlsxExtension,
    accept: ['.xls', '.xlsx'], // Define the accepted file formats
    maxSize: 1000 * 1024, // 1MB
  })

  const getCows = async () => {
    const d0Repository = new Repository(AVAILABLE_ENTITIES.D0S)

    const cowsReq = await d0Repository.getByParam(
      'batch_id',
      history.location.state.batchId
    )

    return cowsReq.response
  }

  const processD0 = async (item) => {
    const d0Repository = new Repository(AVAILABLE_ENTITIES.D0S)
    const batchRepository = new Repository(AVAILABLE_ENTITIES.BATCHES)

    const d0Payload = D.d0PayloadMapper(
      item,
      fileData.data,
      history.location.state.batchId
    )
    const createD0Req = await d0Repository.post(d0Payload)

    await batchRepository.update({
      id: history.location.state.batchId,
      dias: databaseProps?.d0Day.id,
    })

    return createD0Req.response
  }

  const processDNs = async (item, cowId) => {
    const dnRepository = new Repository(AVAILABLE_ENTITIES.DNS)
    const batchRepository = new Repository(AVAILABLE_ENTITIES.BATCHES)

    for (const [index, dnDay] of databaseProps?.dnsDays.entries()) {
      const dnPayload = D.dnPayloadMapper(
        item,
        history.location.state.batchId,
        cowId,
        dnDay.id,
        index + 1
      )

      await dnRepository.post(dnPayload)
      await batchRepository.update({
        id: history.location.state.batchId,
        dias: dnDay.id,
      })
    }
  }

  const processIATF = async (item, cowId) => {
    const iatfRepository = new Repository(AVAILABLE_ENTITIES.IATFS)
    const batchRepository = new Repository(AVAILABLE_ENTITIES.BATCHES)

    const iatfPayload = D.iatfPayloadMapper(
      item,
      fileData.data,
      history.location.state.batchId,
      cowId
    )

    await iatfRepository.post(iatfPayload)
    await batchRepository.update({
      id: history.location.state.batchId,
      dias: databaseProps?.iatfDay.id,
    })
  }

  const processDG = async (item, cow) => {
    const d0Repository = new Repository(AVAILABLE_ENTITIES.D0S)
    const batchRepository = new Repository(AVAILABLE_ENTITIES.BATCHES)

    const dgPayload = D.dgPayloadMapper(item, fileData.data, cow)

    const updateD0Req = await d0Repository.update(dgPayload)

    await batchRepository.update({
      id: history.location.state.batchId,
      isFinalize: true,
    })

    return updateD0Req.response
  }

  const processDGFinal = async (item, cow) => {
    const d0Repository = new Repository(AVAILABLE_ENTITIES.D0S)

    const dgFinalPayload = D.dgFinalPayloadMapper(item, fileData.data, cow)

    await d0Repository.update(dgFinalPayload)
  }

  const formatDataToCorrectType = async (userData) => {
    setLoading(true)

    for (const [index, item] of userData.entries()) {
      if (!!item.D0 && !item.IATF && !item.DG && !item.DGFinal) {
        await processD0(item)
      } else if (!!item.D0 && !item.IATF && (!!item.DG || !!item.DGFinal)) {
        await processD0(item)
      } else if (
        !!item.D0 &&
        !!item.IATF &&
        !item.DG &&
        (!item.DGFinal || !!item.DGFinal)
      ) {
        const response = await processD0(item)

        await processDNs(item, response.id)
        await processIATF(item, response.id)
      } else if (!!item.D0 && !!item.IATF && !!item.DG && !item.DGFinal) {
        const response = await processD0(item)

        await processDNs(item, response.id)
        await processIATF(item, response.id)
        await processDG(item, response)
      } else if (!!item.D0 && !!item.IATF && !!item.DG && !!item.DGFinal) {
        const response = await processD0(item)

        await processDNs(item, response.id)
        await processIATF(item, response.id)

        const updatedResponse = await processDG(item, response)

        await processDGFinal(item, updatedResponse)
      } else if (!item.D0 && !!item.IATF && !item.DG && !item.DGFinal) {
        const response = await getCows()

        await processDNs(item, response[index].id)
        await processIATF(item, response[index].id)
      } else if (!item.D0 && !!item.IATF && !!item.DG && !item.DGFinal) {
        const response = await getCows()

        await processDNs(item, response[index].id)
        await processIATF(item, response[index].id)
        await processDG(item, response[index])
      } else if (!item.D0 && !!item.IATF && !!item.DG && !!item.DGFinal) {
        const response = await getCows()

        await processDNs(item, response[index].id)
        await processIATF(item, response[index].id)

        const updatedResponse = await processDG(item, response[index])

        await processDGFinal(item, updatedResponse)
      } else if (!item.D0 && !item.IATF && !!item.DG && !item.DGFinal) {
        const response = await getCows()

        await processDG(item, response[index])
      } else if (!item.D0 && !item.IATF && !!item.DG && !!item.DGFinal) {
        const response = await getCows()

        const updatedResponse = await processDG(item, response[index])

        await processDGFinal(item, updatedResponse)
      } else if (!item.D0 && !item.IATF && !item.DG && !!item.DGFinal) {
        const response = await getCows()

        await processDGFinal(item, response[index])
      }
    }

    setLoading(false)
  }

  const uploadFile = async () => {
    if (acceptedFiles.length === 0) {
      return
    }

    try {
      await formatDataToCorrectType(fileData.userData)

      setFiles([])
      setIsUploadedFile(true)
    } catch (error) {
      setIsUploadedFile(false)
      setErrorsMessage({
        title: 'Erro ao enviar o arquivo',
        message: 'Erro ao enviar o arquivo',
      })
      console.error('Erro ao enviar o conteúdo do arquivo', error)
    }
  }

  useEffect(() => {
    ; (async () => {
      if (isUploadedFile) {
        const updatedProps = await propsFromDatabase(props)

        setDatabaseProps(updatedProps)
      }
    })()
  }, [isUploadedFile, props])

  useEffect(() => {
    if (DropzoneFileRejections.length === 0) return

    const errors = D.sheetErrorsMessageMapper(
      DropzoneFileRejections[0].errors[0].code
    )

    setErrorsMessage(errors)
    setFileRejections(DropzoneFileRejections)
  }, [DropzoneFileRejections])

  return (
    <Container>
      {loading && (
        <div className={classes.loadingCircularProgress}>
          <CircularProgress style={{ color: '#ea5d0d' }} />
        </div>
      )}
      <TopBar title='Importar Dados de um lote' />
      <TourButton
        tour={D.tour}
        config={{
          onDestroyed: () =>
            !!LocalStorageHelper.get('importDataTourFinished')
              ? {}
              : LocalStorageHelper.add('importDataTourFinished', true),
          allowClose: LocalStorageHelper.get('importDataTourFinished') || false,
        }}
        openWhenEnterInPage
      />
      <TitleBar
        title='Importar Dados de um lote'
        buttons={[
          {
            onClick: () => {
              if (!hasInternet()) {
                setErrorsMessage({
                  title: 'Erro ao baixar o arquivo',
                  message:
                    'Parece que você está sem internet, tente novamente quando estiver conectado.',
                })

                return
              }

              getImportDataTemplate(
                history.location.state.farmId,
                history.location.state.farmName,
                setLoading
              )
            },
            className: 'download-file-button',
            label: `Baixar modelo de importação`,
          },
          {
            onClick: () => history.goBack(),
            className: 'back-button',
            label: `Voltar`,
          },
        ]}
        paths={[
          {
            label: 'Importar Dados de um lote',
          },
        ]}
      />
      <MainContainer>
        <div
          {...getRootProps()}
          className={`dropzone-area ${classes.dropzone}`}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Solte o arquivo aqui</p>
          ) : (
            <p>Arraste e solte um arquivo aqui ou clique para procurar</p>
          )}
        </div>
        {files.length > 0 && (
          <aside>
            <h4>Arquivo: {files[0].path}</h4>
          </aside>
        )}
        <div className='grid-item p-12 p-display-flex p-justify-space-between'>
          {files.length === 0 ? (
            <div />
          ) : (
            <Button
              color={COLORS.GREY}
              label='Remover arquivo'
              onClick={() => setFiles([])}
            />
          )}
          <Button
            label='Enviar'
            disabled={files.length === 0 || fileRejections.length > 0}
            onClick={uploadFile}
            startIcon={<SaveIcon />}
            containerStyle={{ textAlign: 'right' }}
            className='upload-file-button'
          />
        </div>
      </MainContainer>

      <Prompt
        visible={fileRejections.length > 0 || errorsMessage.title !== ''}
        title={errorsMessage.title}
        message={errorsMessage.message}
        buttons={[
          {
            label: 'Ok',
            onClick: () => {
              setFileRejections([])
              setFiles([])
              setErrorsMessage({
                title: '',
                message: '',
              })
            },
          },
        ]}
      />

      <Prompt
        visible={errorsInSheetData.length > 0}
        title='Parece que seu arquivo possui erros!'
        options={errorsInSheetData.map((item) => {
          return `${item.message} na linha ${item.line} do arquivo`
        })}
        buttons={[
          {
            label: 'Ok',
            onClick: () => {
              setErrorsInSheetData([])
              setFiles([])
            },
          },
        ]}
      />
    </Container>
  )
}

const propsFromDatabase = async (props) => {
  const days = await utils.getDomainValuesBy('Dias')

  const batch = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'id',
    props.location.state.batchId
  )
  const protocol = await utils.getElement(
    AVAILABLE_ENTITIES.PROTOCOLS,
    batch[0].protocoloId
  )

  const protocolDays = protocol
    ? _.sortBy(
      _.uniq(
        protocol.managementProtocols.map((managementProtocol) =>
          _.find(days, { id: managementProtocol.day })
        )
      ),
      [
        (dia) =>
          dia.valor.startsWith('D') ? parseInt(dia.valor.substring(1)) : 100,
      ]
    )
    : []
  const passDay = protocolDays[_.findIndex(protocolDays, { id: batch[0].dias })]

  const currentDay =
    protocolDays[_.findIndex(protocolDays, { id: batch[0].dias }) + 1]

  const dnsDays = protocolDays.slice(1, -1)
  const d0Day = protocolDays[0]
  const iatfDay = protocolDays[protocolDays.length - 1]

  return {
    d0Day,
    dnsDays,
    iatfDay,
    passDay,
    currentDay,
    batch: batch[0],
  }
}

export default connect()(
  utils.withPropsFromDatabase(propsFromDatabase, withRouter(ImportData))
)
