/* eslint-disable no-unused-expressions */

import 'moment/locale/pt-br'

import { FIELD_TYPE, REQUIRED_FIELD_MESSAGES } from '../../utils/formHelper'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import MomentUtils from '@date-io/moment'
import clsx from 'clsx'
import moment from 'moment'
import uuid from 'react-uuid'
import withStyles from '@material-ui/core/styles/withStyles'

function datefieldDefaultOnChange(
  field,
  message = REQUIRED_FIELD_MESSAGES.DEFAULT
) {
  return (value) => {
    this.setState({
      [field]: value,
      [`${field}_error`]:
        this.missingRequiredFieldChecker(field, value, FIELD_TYPE.DATE) &&
        message,
    })
  }
}

function hoursfieldDefaultOnChange(
  field,
  message = REQUIRED_FIELD_MESSAGES.DEFAULT
) {
  return (value) => {
    this.setState({
      [field]: value,
      [`${field}_error`]:
        this.missingRequiredFieldChecker(field, value, FIELD_TYPE.DATE) &&
        message,
    })
  }
}

function datefieldRangedDefaultOnChange(
  field,
  message = REQUIRED_FIELD_MESSAGES.DEFAULT
) {
  return (values) => {
    if (values && values.length > 2) {
      this.setState({
        [`${field}_error`]:
          this.missingRequiredFieldChecker(
            field,
            values,
            FIELD_TYPE.DATE_RANGE
          ) && message,
      })
    } else if (values && values.length === 2) {
      this.setState({
        [`${field}_start`]: values[0],
        [`${field}_end`]: values[1],
        [`${field}_error`]:
          this.missingRequiredFieldChecker(
            field,
            values,
            FIELD_TYPE.DATE_RANGE
          ) && message,
      })
    } else {
      this.setState({
        [`${field}_start`]: values,
        [`${field}_end`]: values,
        [`${field}_error`]:
          this.missingRequiredFieldChecker(field, values, FIELD_TYPE.DATE) &&
          message,
      })
    }
  }
}

class DateField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.id || uuid(),
      begin:
        props.initialValues &&
          props.initialValues[0] &&
          props.initialValues[0].isValid &&
          props.initialValues[0].isValid()
          ? props.initialValues[0]
          : undefined,
      end: props.initialValues
        ? props.initialValues[1] &&
          props.initialValues[1].isValid &&
          props.initialValues[1].isValid()
          ? props.initialValues[1]
          : props.initialValues[0] &&
            props.initialValues[0].isValid &&
            props.initialValues[0].isValid()
            ? props.initialValues[0]
            : undefined
        : undefined,
    }
    this.onChange = this.onChange.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)

    this.renderDay = this.renderDay.bind(this)
    this.onClear = this.onClear.bind(this)
    this.onClose = this.onClose.bind(this)
    this.labelFunc = this.labelFunc.bind(this)
    this.utils = new MomentUtils({
      locale: 'pt-br',
    })

    moment.locale('pt-br')
  }

  renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
    const min = Math.min(this.state.begin, this.state.end || this.state.hover)
    const max = Math.max(this.state.begin, this.state.end || this.state.hover)

    return React.cloneElement(dayComponent, {
      onClick: (e) => {
        e.stopPropagation()
        if (!this.state.begin) {
          this.setState({
            begin: day,
          })
        } else if (!this.state.end) {
          const val = [this.state.begin, day].sort(function (a, b) {
            if (a.valueOf() < b.valueOf()) {
              return -1
            }
            if (a.valueOf() > b.valueOf()) {
              return 1
            }
            return 0
          })
          this.setState({
            begin: val[0],
            end: val[1],
          })
        } else {
          this.setState({
            begin: day,
            end: undefined,
          })
        }
      },
      onMouseEnter: (e) => this.setState({ hover: day }),
      className: clsx(this.props.classes.day, {
        [this.props.classes.hidden]: dayComponent.props.hidden,
        [this.props.classes.current]: dayComponent.props.current,
        [this.props.classes.dayDisabled]: dayComponent.props.disabled,
        [this.props.classes.daySelected]:
          min && max ? day >= min && day <= max : dayComponent.props.current,
        [this.props.classes.beginCap]: this.utils.isSameDay(day, min),
        [this.props.classes.endCap]: this.utils.isSameDay(day, max),
        [this.props.classes.soloItem]:
          min || max
            ? this.utils.isSameDay(day, min) && this.utils.isSameDay(day, max)
            : dayComponent.props.current,
      }),
    })
  }

  onClear() {
    this.setState({
      begin: undefined,
      end: undefined,
      hover: undefined,
    })
  }

  onClose() {
    if (this.props.ranged) {
      if (this.state.begin && this.state.end) {
        if (this.props.onChange) {
          this.props.onChange(
            [this.state.begin, this.state.end].sort(function (a, b) {
              if (a.valueOf() < b.valueOf()) {
                return -1
              }
              if (a.valueOf() > b.valueOf()) {
                return 1
              }
              return 0
            })
          )
        }
      } else if (this.state.begin) {
        this.setState(
          {
            end: this.state.begin,
          },
          this.props.onChange(this.state.begin)
        )
      } else {
        this.setState({
          begin: undefined,
          end: undefined,
        })
      }
      if (this.props.onClose) {
        this.props.onClose()
      }
    }
    //onChange([begin, end].sort())
    //if(props.onClose) props.onClose()
  }

  labelFunc(date, invalid) {
    //props.labelFunc ? ....
    const ptbrLocale = moment.localeData('pt-br')
    return date && this.state.begin && this.state.end
      ? `${this.utils.format(
        this.state.begin,
        ptbrLocale._longDateFormat.L
      )} - ${this.utils.format(this.state.end, ptbrLocale._longDateFormat.L)}`
      : ''
  }

  onChange(e) {
    if (this.props.onChange) {
      e != null
        ? e._isValid
          ? (e._d = new Date(e._d.toDateString()))
          : null
        : null
      this.props.onChange(e)
    }
  }

  onKeyDown(e) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
  }

  onFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }

  onBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }

  render() {
    const datefield = (
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        style={{ width: 'auto' }}
        locale='pt-br'
      >
        <KeyboardDatePicker
          autoComplete='off'
          variant='outlined'
          id={this.state.id}
          label={this.props.label}
          format='DD/MM/YYYY'
          value={this.props.ranged ? this.state.begin : this.props.value}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          KeyboardButtonProps={{
            'aria-label': 'Alterar',
            tabIndex: this.props.tabIndex,
            onFocus: this.onFocus,
            onClickCapture: this.props.onClickCaptureButton
          }}
          inputVariant='outlined'
          style={{
            marginTop: '0',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
          InputProps={{
            style: { backgroundColor: 'white', width: '100%' },
          }}
          inputProps={{ tabIndex: this.props.tabIndex }}
          cancelLabel={'CANCELAR'}
          okLabel='Selecionar'
          disabled={this.props.disabled}
          error={this.props.error}
          helperText={this.props.helperText}
          //Props for DateRange
          renderDay={this.props.ranged ? this.renderDay : this.props.renderDay}
          onClear={this.props.ranged ? this.onClear : this.props.onClear}
          onClose={this.props.ranged ? this.onClose : this.props.onClose}
          labelFunc={this.props.ranged ? this.labelFunc : this.props.labelFunc}
        />
      </MuiPickersUtilsProvider>
    )
    if (this.props.withGrid) {
      return (
        <Grid item xs={this.props.withGrid}>
          {datefield}
        </Grid>
      )
    }
    return datefield
  }
}

export const styles = (theme) => ({
  day: {
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
    margin: 0,
    width: '40px',
    borderRadius: '0',
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  current: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  daySelected: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  dayDisabled: {
    pointerEvents: 'none',
    color: theme.palette.text.hint,
  },
  beginCap: {
    borderRadius: '50% 0 0 50%',
  },
  endCap: {
    borderRadius: '0 50% 50% 0',
  },
  soloItem: {
    borderRadius: '50% 50% 50% 50%',
  },
})

export { datefieldDefaultOnChange, datefieldRangedDefaultOnChange, hoursfieldDefaultOnChange }

export default withStyles(styles)(DateField)
