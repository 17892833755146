import { CELL_TYPES } from '../../components/ResponsiveTable'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Prompt from '../../components/Prompt'
import React from 'react'
import { SERVICES_PORTS_MAP } from '../../redux/api/constants'
import _ from 'lodash'
import axios from 'axios'
import { getLoggedUser } from '../../redux/auth/actions'
import { utils } from '../../database'

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}:${SERVICES_PORTS_MAP['domains']}/api`,
})

export const fetchData = async () => {
  const { token } = await getLoggedUser()
  const params = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const {
    data: { responseList: domainValue },
  } = await api.get('/ValorDominio', params)
  const {
    data: { responseList: domainType },
  } = await api.get('/TipoDominio', params)

  const domainValueWithTypeName = domainValue.map((item) => ({
    ...item,
    tipoDominioNome: domainType.find((type) => type.id === item.tipoDominioId)
      ?.nome,
    valorDominioPaiIdNome: domainValue
      .filter((value) => !value.valorDominioPaiId)
      .find((value) => value.id === item.valorDominioPaiId)?.valor,
  }))

  return {
    domainValue: sortDomainValueToTypeDomainName(domainValueWithTypeName),
    domainType: sortDomainTypeToValue(
      domainType.map((item) => ({ ...item, valor: item.nome }))
    ),
  }
}

export const getDomainValueTableData = (setActions, setFormData) => {
  return [
    {
      name: 'Valor',
      type: CELL_TYPES.TEXT,
      sortBy: true,
      label: (row) => row.valor,
    },
    {
      name: 'Dominio Tipo Nome',
      sortBy: true,
      type: CELL_TYPES.TEXT,
      label: (row) => row.tipoDominioNome,
    },
    {
      type: CELL_TYPES.BUTTON_ARR,
      buttons: (row) =>
        _.compact([
          {
            icon: <EditIcon />,
            label: 'Editar',
            onClick: () => {
              setActions({ domainValueEdit: true })
              setFormData(row)
            },
          },
          {
            icon: <DeleteIcon />,
            label: 'Excluir',
            onClick: () => {
              setActions({ domainValueDelete: true })
              setFormData({ id: row.id })
            },
          },
        ]),
    },
  ]
}

export const getModals = (actions, setActions, formData, handleDelete) => {
  if (actions.domainValueModalAfterSubmitOrEdit) {
    return (
      <Prompt
        visible={actions.domainValueModalAfterSubmitOrEdit}
        title='Aviso importante!'
        message='Para que as alterações tenham efeito, é necessário sincronizar os dados do ProGerar. Sincronize na tela inicial do ProGerar.'
        buttons={[
          {
            label: 'Ok',
            onClick: () => setActions({}),
          },
        ]}
      />
    )
  } else if (actions.domainValueDelete) {
    return (
      <Prompt
        visible={actions.domainValueDelete}
        title='Deletar Valor Dominio'
        message='Ao fazer isso o seu Valor Dominio vai ser excluído do ProGerar. Tem certeza que deseja continuar?'
        buttons={[
          {
            label: 'Fechar',
            onClick: () => setActions({}),
          },
          {
            label: 'Deletar',
            onClick: () => {
              handleDelete('/ValorDominio', formData.id)
              setActions({})
            },
          },
        ]}
      />
    )
  }

}

export const addtipoDominioNomeInArray = async (
  domainTypeArray,
  domainTypeValue
) => {
  const response = await utils.getDomainValuesBy(domainTypeValue)

  return response.map((item) => ({
    ...item,
    tipoDominioNome: domainTypeArray.find(
      (type) => type.id === item.tipoDominioId
    )?.nome,
  }))
}

export const sortDomainValueToTypeDomainName = (array) => {
  return array.sort((a, b) => {
    const nomeA = a.tipoDominioNome?.toLowerCase()
    const nomeB = b.tipoDominioNome?.toLowerCase()

    if (nomeA && !nomeB) {
      return -1 // Move a para cima (antes de b)
    }

    if (!nomeA && nomeB) {
      return 1 // Move b para cima (antes de a)
    }

    if (nomeA > nomeB) {
      return 1
    }

    if (nomeA < nomeB) {
      return -1
    }

    return 0
  })
}

export const sortDomainTypeToValue = (array) => {
  return array.sort((a, b) => {
    if (a.nome.toLowerCase() > b.nome.toLowerCase()) {
      return 1
    }

    if (a.nome.toLowerCase() < b.nome.toLowerCase()) {
      return -1
    }

    return 0
  })
}
