import '../../components/styles/customstyle.scss'
import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import React, { Component } from 'react'
import { Container, Grid } from '@material-ui/core'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Button from '../../components/material/Button'
import * as sync from '../../../src/syncWorker'
import { USUARIO, formatName } from '../../utils/formHelper'
import { getLoggedUser } from '../../redux/auth/actions'
import { logout } from '../../redux/auth/actions'
import clearStorage from '../../utils/clearStorage'

class Refresh extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }

  }

  refresh() {
    sync.refreshApplication().then(res => this.props.history.push("/"))
  }


  render() {

    return (
      <Container>
        <div style={{ marginTop: '20px' }}>
          <Button label="refresh" onClick={() => this.refresh()} />
        </div>

      </Container>
    )
  }
}


const mapDispatchToProps = dispatch => {
  return {
    logout: async () => await dispatch(logout()),
    loggedUser: async () => await getLoggedUser(),
    user: async () => await utils.getElement(
      AVAILABLE_ENTITIES.USERS,
      loggedUser.userId
    )
  }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Refresh))
