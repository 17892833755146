import React, { Component } from 'react'

import ReactHtmlParser from 'react-html-parser'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getTerm } from '../../../../redux/auth/actions'
import { utils } from '../../../../database'

class Terms extends Component {
  constructor (props) {
    super(props)
    this.outerDiv = React.createRef()
  }

  componentDidMount () {
    if (this.props.onHeight) {
      this.props.onHeight(this.outerDiv.current.clientHeight)
    }
  }

  render () {
    return (
      <div ref={this.outerDiv} style={{ fontFamily: 'Helvetica' }}>
        {ReactHtmlParser(this.props.conteudo || this.props.localData)}
      </div>
    )
  }
}

const propsFromDatabase = async props => {
  const remoteData = await props.requestTerm(props.type)
  const storedData = await utils.getFromLocalStorage(props.name)
  const localVersion = !_.isEmpty(storedData) ? JSON.parse(storedData) : {}
  const remoteVersion = !_.isEmpty(remoteData)
    ? _.pick(remoteData, ['conteudo', 'versao'])
    : {}
  return {
    ...localVersion,
    ...remoteVersion
  }
}

const mapDispatchToProps = dispatch => ({
  requestTerm: async type => dispatch(getTerm(type))
})

export default connect(
  null,
  mapDispatchToProps
)(utils.withPropsFromDatabase(propsFromDatabase, Terms))
