const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
const CPF_REGEX = /^([0-9]{3})\.([0-9]{3})\.([0-9]{3})-([0-9]{2})$|^([0-9]{11})$/
const NUMBERS_REGEX = /^[0-9]+$/

export function isValidEmail (email) {
  return EMAIL_REGEX.test(email)
}

export function isValidCPF (cpf) {
  return CPF_REGEX.test(cpf)
}

export function containsOnlyNumber (text) {
  return NUMBERS_REGEX.test(text)
}
