import * as api from '../../redux/api/actions.js'

import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'

import Alert from '@material-ui/lab/Alert'
import Backdrop from '@material-ui/core/Backdrop'
import Box from '@material-ui/core/Box'
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import LoginBackground from '../../assets/img/login-background.jpg'
import TextField from '@material-ui/core/TextField'
import { ThemeProvider } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import ZoetisLogo from '../../assets/img/logo_corte.png'
import { connect } from 'react-redux'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import qsParser from 'query-string'
import { resetPassword } from '../../redux/auth/actions.js'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#EA5D0D'
    }
  }
})

class ResetPassword extends Component {
  constructor(props) {
    super(props)

    const { email, token, invite } = qsParser.parse(this.props.location.search)

    this.state = {
      email: email,
      invite: invite,
      token: token.replace(/\+/g, '%2B').replace(/\//g, '%2F'),
      newpassword: '',
      confirmPassword: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = event => {
    const {
      target: { name, value }
    } = event
    this.setState(() => ({ [name]: value }))
  }

  async handleSubmit() {
    const result = await this.props.resetPassword(this.state)
    if (result.hasErrors) {
      this.setState({ errorMessage: result.title })
    } else {
      this.props.history.push('/login')
    }
  }

  canSubmit() {
    return (
      this.state.email &&
      this.state.token &&
      this.state.password &&
      this.state.confirmPassword &&
      this.state.password === this.state.confirmPassword
    )
  }

  render() {
    if (this.props.loading) {
      return (
        <Backdrop open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid
          container
          style={{
            backgroundColor: 'rgba(234, 234, 234, 0.27)'
          }}
        >
          <Grid
            item
            xs={6}
            style={{
              // backgroundColor: '#EA5D0D',
              backgroundImage: `url(${LoginBackground})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundBlendMode: 'multiply',
              backgroundPositionX: '-5vw'
            }}
          />
          <Grid item xs={6}>
            <Container maxWidth='xs'>
              <Typography
                component='div'
                style={{ height: '100vh', marginTop: '100px' }}
              >
                <img
                  src={ZoetisLogo}
                  alt='Zoetis Gerar'
                  style={{
                    width: '100%'
                  }}
                />

                {this.props.error && (
                  <Alert
                    severity='error'
                    style={{
                      marginTop: '1em'
                    }}
                  >
                    {this.props.error.title}
                  </Alert>
                )}

                {this.props.success && (
                  <Alert
                    severity='success'
                    style={{
                      marginTop: '1em'
                    }}
                  >
                    {Boolean(this.state.invite) === true
                      ? 'Senha definida com sucesso!'
                      : 'Senha alterada com sucesso!'}
                  </Alert>
                )}

                <Container disableGutters>
                  <Container
                    disableGutters
                    style={{
                      marginTop: '1em'
                    }}
                  >
                    <h4 style={{ marginBottom: 12 }}>
                      {' '}
                      {Boolean(this.state.invite) === true
                        ? 'Primeiro Acesso'
                        : 'Recuperação de Senha'}{' '}
                    </h4>
                    <TextField
                      id='login-form-password'
                      label='Nova senha'
                      name='password'
                      variant='outlined'
                      type='password'
                      onChange={this.handleChange}
                      style={{
                        width: '100%',
                        backgroundColor: 'white'
                      }}
                    />
                  </Container>
                  <Container
                    disableGutters
                    style={{
                      marginTop: '1em'
                    }}
                  >
                    <TextField
                      id='login-form-confirm-password'
                      label='Confirmar Senha'
                      name='confirmPassword'
                      variant='outlined'
                      type='password'
                      onChange={this.handleChange}
                      style={{
                        width: '100%',
                        backgroundColor: 'white'
                      }}
                    />
                  </Container>
                  <Container
                    disableGutters
                    style={{
                      marginTop: '1em'
                    }}
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      disableElevation
                      size='large'
                      style={{
                        width: '100%'
                      }}
                      disabled={!this.canSubmit()}
                      onClick={this.handleSubmit}
                    >
                      Enviar
                    </Button>
                    <Box
                      fontWeight='fontWeightLight'
                      style={{
                        marginTop: '1em',
                        textAlign: 'center'
                      }}
                    >
                      <Link
                        to='/login'
                        style={{
                          color: '#333',
                          textDecoration: 'none'
                        }}
                      >
                        voltar
                      </Link>
                    </Box>
                  </Container>
                </Container>
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </ThemeProvider>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: params => dispatch(resetPassword(params))
  }
}

const mapStateToProps = state => ({
  loading: api.getApiLoading(state, `Account/ResetPassword`),
  error: api.getApiError(state, `Account/ResetPassword`) || false,
  success: api.getApiResult(state, `Account/ResetPassword`) || false
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResetPassword))
