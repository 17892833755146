import { AVAILABLE_ENTITIES, utils } from '../../database/'
import React, { Component } from 'react'

import Container from '@material-ui/core/Container'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt'
import ProtocolForm from './components/ProtocolForm'
import TitleBar from './../../components/TitleBar'
import TopBar from './../../components/TopBar'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

class Protocol extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.onCancel = this.onCancel.bind(this)
  }

  onCancel() {
    this.props.history.push('/protocols')
  }

  render() {
    return (
      <Container>
        <TopBar title='' />
        <TitleBar
          title={this.props.protocol.name}
          paths={[
            {
              route: '/protocols',
              label: 'Protocolos'
            },
            {
              label: this.props.protocol.name
            }
          ]}
        />

        <MainContainer>
          <ProtocolForm
            onCancel={this.onCancel}
            onSubmit={this.onSubmit}
            protocol={this.props.protocol}
            viewOnly
          />
        </MainContainer>
        <Prompt
          visible={verify()}
          title='Página duplicada!'
          message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
          buttons={[
            {
              autoFocus: false
            },
          ]}
        />
      </Container>
    )
  }
}

const propsFromDatabase = async props => ({
  protocol: await utils.getElement(
    AVAILABLE_ENTITIES.PROTOCOLS,
    props.match.params.id
  )
})

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(Protocol)
)
