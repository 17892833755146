import './styles/customstyle.scss'

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Label,
  Legend,
  Scatter,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import React, { Fragment, PureComponent } from 'react'

import _ from 'lodash'
import uuid from 'react-uuid'

const DEFAULT_OPACITY = 1
const HIDDEN_OPACITY = 0.3
const COLOR_PALLETE = [
  '#ff9f0f',
  '#e8730e',
  '#ff661d',
  '#e8330e',
  '#ff150f',
  '#0096af'
]

const MAX_CHART_WIDTH = 1280

const TOOLTIP_TYPES = {
  ABSOLUTE: 'ABSOLUTE',
  PERCENTAGE: 'PERCENTAGE',
  OCCURRENCE: 'OCCURRENCE'
}

const TOOLTIP_FUNCTIONS = {
  ABSOLUTE: (value, name, props) => [
    value,
    `IATF ${parseInt(name.split('.')[1])}`
  ],
  PERCENTAGE: (value, name, props) => [
    `${value}%`,
    `IATF ${parseInt(name.split('.')[1])}`
  ],
  OCCURRENCE: (value, name, props) => [
    value,
    `Ocorrência${value > 1 ? 's' : ''}`
  ]
}

const SUPPORTED_CHARTS = {
  SIMPLE_CHART: 'SIMPLE_CHART',
  DOUBLE_CHART: 'DOUBLE_CHART'
}

class RotatedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor='end'
          fill='#666'
          transform='rotate(-30)'
          style={{ fontSize: 12 }}
        >
          {payload.value}
        </text>
      </g>
    )
  }
}

class Chart extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedChart: null,
      id: uuid()
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.getOpacityFor = this.getOpacityFor.bind(this)

    this.renderLegend = this.renderLegend.bind(this)
    this.renderChart = this.renderChart.bind(this)
    this.getWidth = this.getWidth.bind(this)
  }

  handleMouseEnter(o) {
    const { dataKey } = o
    this.setState({
      selectedChart: dataKey.split('.')[1]
    })
  }

  handleMouseLeave(o) {
    this.setState({
      selectedChart: null
    })
  }

  getOpacityFor(label) {
    return this.state.selectedChart === null ||
      this.state.selectedChart === label
      ? DEFAULT_OPACITY
      : HIDDEN_OPACITY
  }

  renderLegend() {
    let wrapperStyle = {
      paddingTop: 20
    }
    if (this.props.data.length > 3) {
      wrapperStyle.bottom = -20
    }
    return (
      <Legend
        align='center'
        verticalAlign='bottom'
        layout='horizontal'
        formatter={(value, entry, index) =>
          this.props.skipIATFLabel
            ? 'Ocorrência'
            : `IATF ${parseInt(value.split('.')[1]) + 1}`
        }
        wrapperStyle={wrapperStyle}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      />
    )
  }

  getWidth() {
    if (this.props.width) {
      if (this.props.width >= MAX_CHART_WIDTH) {
        return MAX_CHART_WIDTH * this.props.widthFactor
      }
      return this.props.width * this.props.widthFactor
    }
    return 0
  }

  renderChart({
    xAxisLabel,
    yAxisLabel,
    shouldRenderLegend,
    tooltipFormatter,
    yAxisKey
  }) {
    return (
      <ComposedChart
        width={this.getWidth()}
        height={this.props.data.length > 3 ? 400 : 300}
        data={this.props.data}
        margin={{
          top: 30,
          right: 30,
          left: 30,
          bottom: 30
        }}
        syncId={`charts_${this.state.id}`}
      >
        <CartesianGrid strokeDasharray='3 3' />
        {this.props.data.length > 3 ? (
          <XAxis
            dataKey='xAxis'
            height={100}
            interval={0}
            tick={<RotatedAxisTick />}
          >
            <Label value={xAxisLabel} position='insideBottom' offset={-70} />
          </XAxis>
        ) : (
          <XAxis dataKey='xAxis' interval={0}>
            <Label value={xAxisLabel} position='insideBottom' offset={-5} />
          </XAxis>
        )}
        <YAxis orientation='left'>
          <Label value={yAxisLabel} position='center' angle={-90} dx={-30} />
        </YAxis>
        <Tooltip formatter={tooltipFormatter} />
        {shouldRenderLegend && this.renderLegend()}
        {_.times(this.props.configuration.seriesCount, index => (
          <Bar
            key={uuid()}
            fillOpacity={this.getOpacityFor(`${index}`)}
            dataKey={`series.${index}.${yAxisKey}`}
            fill={COLOR_PALLETE[index % COLOR_PALLETE.length]}
            isAnimationActive={false}
          />
        ))}
      </ComposedChart>
    )
  }

  render() {
    return (
      <div className="chart-item">
        {this.renderChart({
          xAxisLabel: this.props.configuration.xAxisLabel,
          yAxisLabel: this.props.configuration.yAxisLabel,
          shouldRenderLegend:
            this.props.configuration.type === SUPPORTED_CHARTS.SIMPLE_CHART,
          tooltipFormatter: this.props.skipIATFLabel
            ? TOOLTIP_FUNCTIONS[TOOLTIP_TYPES.OCCURRENCE]
            : TOOLTIP_FUNCTIONS[this.props.configuration.tooltipFormatter],
          yAxisKey: 'yAxis'
        })}
        {this.props.configuration.type === SUPPORTED_CHARTS.DOUBLE_CHART &&
          this.renderChart({
            xAxisLabel: this.props.configuration.xAxisLabel,
            yAxisLabel: this.props.configuration.yAxis2Label,
            shouldRenderLegend: true,
            tooltipFormatter:
              TOOLTIP_FUNCTIONS[this.props.configuration.tooltipFormatter2],
            yAxisKey: 'yAxis2'
          })}
      </div>
    )
  }
}

export default Chart
