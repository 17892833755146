/* global localStorage */

import * as CONSTANTS from './constants'
import * as api from '../api/actions'

import { END_LOADING, START_LOADING } from '../screen/constants'
import { TimeoutError, timeoutFetch } from './../api/utils'
import database, { AVAILABLE_ENTITIES, Repository } from '../../database'

import { Q } from '@nozbe/watermelondb'
import _ from 'lodash'
import crypto from 'crypto-js'
import { forceSync } from '../../syncWorker'
import moment from 'moment'
import { LocalStorageHelper } from '../../utils/localStorageHelper'
import { store } from '../../store'
import uuid from 'react-uuid'

const hsp = 'If you want to keep a secret, you must also hide it from yourself' // George Orwell, 1984

const SYNC_URLS = {
  REFRESH_TOKEN: window.location.pathname === '/login'
    ? `${process.env.REACT_APP_BASE_URL_GATEWAY}/login-register/api/Authentication/LoginGigya`
    : `${process.env.REACT_APP_BASE_URL_GATEWAY}/login-register/api/Authentication/RefreshToken`
}

export const RESET_PASSWORD_SUCESS = () => ({
  type: CONSTANTS.RESET_PASSWORD_SUCESS
})

export const RESET_PASSWORD_FAIL = error => ({
  type: CONSTANTS.RESET_PASSWORD_SUCESS,
  error
})

export const USER_LOGIN_SUCCESS = () => ({
  type: CONSTANTS.USER_LOGIN_SUCCESS
})

export const USER_LOGIN_FAIL = error => ({
  type: CONSTANTS.USER_LOGIN_FAIL,
  error
})

export const FORGOT_PASSWORD_SUCESS = () => ({
  type: CONSTANTS.FORGOT_PASSWORD_SUCESS
})

export const FORGOT_PASSWORD_FAIL = error => ({
  type: CONSTANTS.FORGOT_PASSWORD_FAIL,
  error
})

export const USER_LOGOUT = () => ({
  type: CONSTANTS.USER_LOGOUT
})

export const SHOW_EULA = token => ({
  type: CONSTANTS.SET_SHOULD_ACCEPT_EULA,
  token
})

export const DISMISS_EULA = () => ({
  type: CONSTANTS.DISMISS_SHOULD_ACCEPT_EULA
})

const startLoading = () => ({ type: START_LOADING })
const stopLoading = () => ({ type: END_LOADING })

export async function setLoggedUser({ userId, token, roles, pass, sessionId, isGigya = false, isNewPartida = false }) {
  await database.adapter.setLocal(CONSTANTS.LOGGED_USER_ID, userId)
  await database.adapter.setLocal(CONSTANTS.LOGGED_USER_ROLES, roles || [])
  if (token) {
    await database.adapter.setLocal(CONSTANTS.LOGGED_USER_TOKEN, token)
  }
  await database.adapter.setLocal(CONSTANTS.LOGGED_USER_PASS, pass)
  await database.adapter.setLocal(CONSTANTS.LOGGED_USER_SESSION_ID, sessionId)
  if (isGigya) {
    await database.adapter.setLocal(CONSTANTS.LOGGED_USER_GIGYA, isGigya)
  }
  await database.adapter.setLocal(CONSTANTS.LOGGED_USER_NEW_PARTIDA, isNewPartida)
}

export async function clearLoggedInfo() {
  await database.adapter.removeLocal(CONSTANTS.LOGGED_USER_ID)
  await database.adapter.removeLocal(CONSTANTS.LOGGED_USER_TOKEN)
  await database.adapter.removeLocal(CONSTANTS.LOGGED_USER_ROLES)
  await database.adapter.removeLocal(CONSTANTS.LOGGED_USER_PASS)
}

export async function verifyConnectionUser() {
  const userId = await database.adapter.getLocal(CONSTANTS.LOGGED_USER_ID)
  const pass = await database.adapter.getLocal(CONSTANTS.LOGGED_USER_PASS)
  const user = await database.collections.get('users').find(userId)

  let params = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      password: pass,
      email: user.email,
      cpf: user.cpf
    })
  }

  await timeoutFetch(SYNC_URLS.REFRESH_TOKEN, params, 10000)
    .catch(error => {
      if (error instanceof TimeoutError) {
        return false
      } else {
        return false
      }
    })
    .then(async response => {
      if (response.status === 200) {
        let result = JSON.parse(await response.text())
        if (result.token) {
          await database.adapter.setLocal(CONSTANTS.LOGGED_USER_TOKEN, result.token)
          return true
        }
      }
    })

  return false

}

export async function getLoggedUser() {
  const userId = await database.adapter.getLocal(CONSTANTS.LOGGED_USER_ID)
  let token = await database.adapter.getLocal(CONSTANTS.LOGGED_USER_TOKEN)
  const roles =
    (await database.adapter.getLocal(CONSTANTS.LOGGED_USER_ROLES)) || []
  const pass = await database.adapter.getLocal(CONSTANTS.LOGGED_USER_PASS)
  const sessionId = await database.adapter.getLocal(CONSTANTS.LOGGED_USER_SESSION_ID)
  const isGigya = await database.adapter.getLocal(CONSTANTS.LOGGED_USER_GIGYA)
  const isNewPartida = await database.adapter.getLocal(CONSTANTS.LOGGED_USER_NEW_PARTIDA)

  if (!token && userId && pass) {
    token = await refreshLoggedUser(userId, pass)
  }

  return { userId, token, pass, sessionId, roles, isGigya, isNewPartida }
}

export async function refreshLoggedUser(userId, pass) {

  const user = await database.collections
    .get('users')
    .find(userId)

  let params = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      password: pass,
      email: user.email,
      cpf: user.cpf
    })
  }

  await timeoutFetch(SYNC_URLS.REFRESH_TOKEN, params, 15000)
    .catch(error => {
      if (error instanceof TimeoutError) {
        console.warn('Timeout trying to refresh token. Skipping')
      } else {
        console.warn(`Error trying to refresh token: ${error}`)
      }
    })
    .then(async response => {
      if (response.status === 200) {
        let result = JSON.parse(await response.text())
        if (result.token) {
          await database.adapter.setLocal(CONSTANTS.LOGGED_USER_TOKEN, result.token)
        }
      }
    })

  const token = await database.adapter.getLocal(CONSTANTS.LOGGED_USER_TOKEN)

  return token
}

const loginData = args => {
  const data = { ...args }

  if (args.login.indexOf('@') >= 0) {
    data.email = args.login
  } else {
    data.cpf = args.login
  }

  return data
}

export const login = (args, isRefresh = false) => {
  let cpf = args.login
    .replace('.', '')
    .replace('.', '')
    .replace('-', '')
  let novoargs =
    args.tipo === 0
      ? { cpf: cpf, password: args.password }
      : { email: args.login, password: args.password }

  // console.log(args)
  // console.log(`le args ${JSON.stringify(novoargs)}`)
  return async dispatch => {
    dispatch(startLoading())
    dispatch(DISMISS_EULA())
    //1. Try to fetch user locally
    const repository = new Repository(AVAILABLE_ENTITIES.USERS)
    let userReq
    if (novoargs.cpf) {
      userReq = await repository.getByParam('cpf', novoargs.cpf)
    } else {
      userReq = await repository.getByParam('email', novoargs.email)
    }

    let solvedLocally = false

    // console.log(novoargs)
    // console.log(userReq)

    if (
      userReq.success &&
      userReq.response &&
      userReq.response.length > 0 &&
      userReq.response[0].password
    ) {
      const user = userReq.response[0]
      //1.1. Local entry with stored password found. Let's check password

      if (
        crypto.HmacSHA512(novoargs.password + user.salt, hsp).toString() ===
        user.password
      ) {
        solvedLocally = true
        console.log(`User detected in local database`)
        await setLoggedUser({
          userId: user.id,
          roles: user.roles ? user.roles.map(role => role.name) : [],
          pass: args.password
        })
        dispatch(stopLoading())
        dispatch(USER_LOGIN_SUCCESS())
      }
    }

    //Could not resolve locally, try online
    if (!solvedLocally) {
      try {
        const data = await api.post(
          dispatch,
          'auth',
          isRefresh ? 'Authentication/RefreshToken' : 'Authentication/Login',
          novoargs
        )
        if ((!data.termoCookie || !data.termoPrivacy || !data.termoUso) && data.token) {
          await clearLoggedInfo()
          dispatch(SHOW_EULA(data.token))
          dispatch(stopLoading())
          dispatch(USER_LOGIN_FAIL('Você precisa aceitar os termos de uso!'))
          return
        }
        await setLoggedUser({
          userId: data.id,
          token: data.token,
          roles: data.roles.map(role => role.name),
          pass: args.password,
          sessionId: uuid(),
          // isGigya: false,
          isNewPartida: true,
        })
        const collection = database.collections.get('users')
        const rolesCollection = database.collections.get('roles')
        const termsCollection = database.collections.get('terms')
        let record
        try {
          record = await collection.find(data.id)
        } catch (error) { }
        console.log(record)
        const salt = record
          ? record.salt
          : await crypto.lib.WordArray.random(16).toString()
        const password = await crypto
          .HmacSHA512(novoargs.password + salt, hsp)
          .toString()

        const policies = [
          'COOKIES_POLICY',
          'PRIVACY_POLICY',
          'END_USER_LICENSE'
        ]
        let policiesVersions = {}
        for (const key of policies) {
          const policy = await database.adapter.getLocal(key)
          const version = policy && JSON.parse(policy).localVersion
          if (version) {
            policiesVersions[key] = version
          }
        }

        if (record) {
          //Update record
          try {
            await database.action(async () => {
              const roles = await rolesCollection
                .query(Q.where('user_id', data.id))
                .fetch()
              let terms = _.filter(
                await termsCollection
                  .query(Q.where('user_id', data.id))
                  .fetch(),
                term => term.replacedBy === null
              )

              const toDelete = _.filter(
                roles,
                role => !_.find(data.roles, el => el.name === role.name)
              )
              const toInsert = _.filter(
                data.roles,
                role => !_.find(roles, el => el.name === role.name)
              )

              record = await collection.find(data.id)

              await database.batch(
                record.prepareUpdate(user => {
                  user._raw.salt = salt
                  user._raw.password = password
                  user._raw.email = data.email
                  user._raw.cpf = data.cpf
                }),
                ...toDelete.map(entry =>
                  entry.prepareUpdate(role => {
                    role._raw.isDeleted = true
                  })
                ),
                ...toInsert.map(newData =>
                  rolesCollection.prepareCreate(entry => {
                    entry._raw.user_id = data.id
                    entry._raw.name = newData.name
                    entry._raw.backend_id = newData.id
                    entry._raw.description = newData.description
                  })
                )
              )

              for (const key of policies) {
                if (policiesVersions[key]) {
                  const currentPolicy = _.find(terms, { type: key })
                  if (currentPolicy) {
                    if (currentPolicy.version !== policiesVersions[key]) {
                      const newEntry = await termsCollection.create(entry => {
                        entry._raw.type = key
                        entry._raw.version = policiesVersions[key]
                        entry._raw.accepted_at = moment.utc().valueOf()
                        if (args[key] && args[key].response) {
                          entry._raw.ip_address = args[key].response.ip
                          entry._raw.backend_identifier =
                            args[key].response.termoID
                        }
                        entry._raw.user_id = data.id
                      })
                      await currentPolicy.update(entry => {
                        entry._raw.replaced_by = newEntry.id
                      })
                    }
                  } else {
                    await termsCollection.create(entry => {
                      entry._raw.type = key
                      entry._raw.version = policiesVersions[key]
                      entry._raw.accepted_at = moment.utc().valueOf()
                      if (args[key] && args[key].response) {
                        entry._raw.ip_address = args[key].response.ip
                        entry._raw.backend_identifier =
                          args[key].response.termoID
                      }
                      entry._raw.user_id = data.id
                    })
                  }
                }
              }
            })
            console.log(`Record updated!`)
            console.log(await collection.find(data.id))
            // await forceSync(true, false)
            args.isNewPassword === true ? await forceSync(false, true) : await forceSync(true, false)
            dispatch(DISMISS_EULA())
            dispatch(stopLoading())
            dispatch(USER_LOGIN_SUCCESS())
          } catch (error) {
            console.log(`Error trying to update record of user: ${data.id}`)
            await clearLoggedInfo()
            dispatch(DISMISS_EULA())
            dispatch(stopLoading())
            dispatch(USER_LOGIN_FAIL(error))
          }
        } else {
          try {
            await database.action(async () => {
              await database.batch(
                collection.prepareCreate(user => {
                  user._raw.id = data.id
                  user._raw.email = data.email
                  user._raw.cpf = data.cpf
                  user._raw.nomeCompleto = data.nomeCompleto
                  user._raw.zoetisId = data.zoetisId
                  user._raw.salt = salt
                  user._raw.password = password
                }),
                ...data.roles.map(role =>
                  rolesCollection.prepareCreate(entry => {
                    entry._raw.name = role.name
                    entry._raw.backend_id = role.id
                    entry._raw.description = role.description
                    entry._raw.user_id = data.id
                  })
                ),
                ..._.compact(
                  policies.map(key => {
                    if (policiesVersions[key]) {
                      return termsCollection.prepareCreate(entry => {
                        entry._raw.type = key
                        entry._raw.version = policiesVersions[key]
                        entry._raw.accepted_at = moment.utc().valueOf()
                        if (args[key] && args[key].response) {
                          entry._raw.ip_address = args[key].response.ip
                          entry._raw.backend_identifier =
                            args[key].response.termoID
                        }
                        entry._raw.user_id = data.id
                      })
                    }
                    return null
                  })
                )
              )
            })
            console.log(`Record created!`)
            console.log(await collection.find(data.id))
            // await forceSync(true, false)
            args.isNewPassword === true ? await forceSync(false, true) : await forceSync(true, false)
            dispatch(stopLoading())
            dispatch(USER_LOGIN_SUCCESS())
          } catch (error) {
            console.log(
              `There was an error trying to create user record: ${data.id}`
            )
            await clearLoggedInfo()
            dispatch(stopLoading())
            dispatch(USER_LOGIN_FAIL(error))
          }
        }
      } catch (error) {
        !isRefresh && await clearLoggedInfo()
        dispatch(stopLoading())
        !isRefresh && dispatch(USER_LOGIN_FAIL(error))
      }
    }
  }
}

export const loginWithoutPassword = args => {
  let novoargs = {
    email: args.login || args.email,
    password: args.password,
    userId: args.userId,
    userName: args.name || args.userName,
    rolesIds: args.rolesIds,
  }

  return async dispatch => {
    dispatch(startLoading())
    dispatch(DISMISS_EULA())

    //1. Try to fetch user locally

    let solvedLocally = false

    //Could not resolve locally, try online
    if (!solvedLocally) {
      try {
        // debugger
        const data = await api.post(
          dispatch,
          'auth',
          'Authentication/LoginGigya',
          novoargs
        )

        if ((!data.termoCookie || !data.termoPrivacy || !data.termoUso) && data.token) {
          await clearLoggedInfo()
          dispatch(SHOW_EULA(data.token))
          dispatch(stopLoading())
          dispatch(USER_LOGIN_FAIL('Você precisa aceitar os termos de uso!'))

          LocalStorageHelper.add('GerarNotFoundUser', JSON.stringify(novoargs))
          LocalStorageHelper.add('GerarTermActive', true)

          return
        }

        if (data.title === "Usuario não possui perfil") {
          await clearLoggedInfo()
          dispatch(stopLoading())
          dispatch(USER_LOGIN_FAIL("Usuario não possui perfil"))
          LocalStorageHelper.add('GerarNotFoundUser', JSON.stringify(novoargs))

          setTimeout(() => {
            window.location.reload()
          }, 500)

          return
        }

        if (data.title === "Usuario deletado!") {
          await clearLoggedInfo()
          dispatch(stopLoading())
          dispatch(USER_LOGIN_FAIL("Usuario deletado!"))
          LocalStorageHelper.add('GerarUserDeleted', true)

          setTimeout(() => {
            window.location.reload()
          }, 500)

          return
        }

        if (data.title === "Object reference not set to an instance of an object.") {
          await clearLoggedInfo()
          dispatch(stopLoading())
          dispatch(USER_LOGIN_FAIL("Object reference not set to an instance of an object."))

          setTimeout(() => {
            window.location.reload()
          }, 500)

          return
        }

        await setLoggedUser({
          userId: data.id,
          token: data.token,
          roles: data.roles.map(role => role.name),
          pass: args.password,
          sessionId: uuid(),
          isGigya: true,
          isNewPartida: true,
        })
        const collection = database.collections.get('users')
        const rolesCollection = database.collections.get('roles')
        const termsCollection = database.collections.get('terms')
        let record
        try {
          record = await collection.find(data.id)
        } catch (error) { }
        const salt = record
          ? record.salt
          : await crypto.lib.WordArray.random(16).toString()
        const password = await crypto
          .HmacSHA512(novoargs.password + salt, hsp)
          .toString()

        const policies = [
          'COOKIES_POLICY',
          'PRIVACY_POLICY',
          'END_USER_LICENSE'
        ]
        let policiesVersions = {}
        for (const key of policies) {
          const policy = await database.adapter.getLocal(key)
          const version = policy && JSON.parse(policy).localVersion
          if (version) {
            policiesVersions[key] = version
          }
        }

        if (record) {
          //Update record
          try {
            await database.action(async () => {
              const roles = await rolesCollection
                .query(Q.where('user_id', data.id))
                .fetch()
              let terms = _.filter(
                await termsCollection
                  .query(Q.where('user_id', data.id))
                  .fetch(),
                term => term.replacedBy === null
              )

              const toDelete = _.filter(
                roles,
                role => !_.find(data.roles, el => el.name === role.name)
              )
              const toInsert = _.filter(
                data.roles,
                role => !_.find(roles, el => el.name === role.name)
              )

              record = await collection.find(data.id)

              await database.batch(
                record.prepareUpdate(user => {
                  user._raw.salt = salt
                  user._raw.password = password
                  user._raw.email = data.email
                  user._raw.cpf = data.cpf
                }),
                ...toDelete.map(entry =>
                  entry.prepareUpdate(role => {
                    role._raw.isDeleted = true
                  })
                ),
                ...toInsert.map(newData =>
                  rolesCollection.prepareCreate(entry => {
                    entry._raw.user_id = data.id
                    entry._raw.name = newData.name
                    entry._raw.backend_id = newData.id
                    entry._raw.description = newData.description
                  })
                )
              )

              for (const key of policies) {
                if (policiesVersions[key]) {
                  const currentPolicy = _.find(terms, { type: key })
                  if (currentPolicy) {
                    if (currentPolicy.version !== policiesVersions[key]) {
                      const newEntry = await termsCollection.create(entry => {
                        entry._raw.type = key
                        entry._raw.version = policiesVersions[key]
                        entry._raw.accepted_at = moment.utc().valueOf()
                        if (args[key] && args[key].response) {
                          entry._raw.ip_address = args[key].response.ip
                          entry._raw.backend_identifier =
                            args[key].response.termoID
                        }
                        entry._raw.user_id = data.id
                      })
                      await currentPolicy.update(entry => {
                        entry._raw.replaced_by = newEntry.id
                      })
                    }
                  } else {
                    await termsCollection.create(entry => {
                      entry._raw.type = key
                      entry._raw.version = policiesVersions[key]
                      entry._raw.accepted_at = moment.utc().valueOf()
                      if (args[key] && args[key].response) {
                        entry._raw.ip_address = args[key].response.ip
                        entry._raw.backend_identifier =
                          args[key].response.termoID
                      }
                      entry._raw.user_id = data.id
                    })
                  }
                }
              }
            })
            console.log(`Record updated!`)
            console.log(await collection.find(data.id))
            await forceSync(true, false)
            dispatch(DISMISS_EULA())
            dispatch(stopLoading())
            dispatch(USER_LOGIN_SUCCESS())
          } catch (error) {
            console.log(`Error trying to update record of user: ${data.id}`)
            await clearLoggedInfo()
            dispatch(DISMISS_EULA())
            dispatch(stopLoading())
            dispatch(USER_LOGIN_FAIL(error))
          }
        } else {
          try {
            await database.action(async () => {
              await database.batch(
                collection.prepareCreate(user => {
                  user._raw.id = data.id
                  user._raw.email = data.email
                  user._raw.cpf = data.cpf
                  user._raw.nomeCompleto = data.nomeCompleto
                  user._raw.zoetisId = data.zoetisId
                  user._raw.salt = salt
                  user._raw.password = password
                }),
                ...data.roles.map(role =>
                  rolesCollection.prepareCreate(entry => {
                    entry._raw.name = role.name
                    entry._raw.backend_id = role.id
                    entry._raw.description = role.description
                    entry._raw.user_id = data.id
                  })
                ),
                ..._.compact(
                  policies.map(key => {
                    if (policiesVersions[key]) {
                      return termsCollection.prepareCreate(entry => {
                        entry._raw.type = key
                        entry._raw.version = policiesVersions[key]
                        entry._raw.accepted_at = moment.utc().valueOf()
                        if (args[key] && args[key].response) {
                          entry._raw.ip_address = args[key].response.ip
                          entry._raw.backend_identifier =
                            args[key].response.termoID
                        }
                        entry._raw.user_id = data.id
                      })
                    }
                    return null
                  })
                )
              )
            })
            console.log(`Record created!`)
            console.log(await collection.find(data.id))
            await forceSync(true, false)
            dispatch(stopLoading())
            dispatch(USER_LOGIN_SUCCESS())
          } catch (error) {
            console.log(
              `There was an error trying to create user record: ${data.id}`
            )
            await clearLoggedInfo()
            dispatch(stopLoading())
            dispatch(USER_LOGIN_FAIL(error))
          }
        }
      } catch (error) {
        await clearLoggedInfo()
        dispatch(stopLoading())
        dispatch(USER_LOGIN_FAIL(error))
      }
    }
  }
}

export const logout = () => {
  return async dispatch => {
    await clearLoggedInfo()
    dispatch(USER_LOGOUT())
  }
}

export const forgotPassword = args => {
  let novoargs = { email: args.email }
  return async dispatch => {
    let result = null
    await api
      .put(dispatch, 'auth', 'Account/ForgotPassword', novoargs)
      .then(async resp => {
        result = resp
        await dispatch(FORGOT_PASSWORD_SUCESS())
      })
      .catch(async err => {
        result = err
        await dispatch(FORGOT_PASSWORD_FAIL(err))
      })
    return result
  }
}

export const resetPassword = args => {
  let novoargs = {
    email: args.email,
    password: args.password,
    confirmPassword: args.confirmPassword,
    token: args.token
  }
  let result = null

  return async dispatch => {
    await api
      .put(dispatch, 'auth', 'Account/ResetPassword', novoargs)
      .then(async _ => {
        // OFFLINE MODE: Set new password for locally stored user entry, if it exists
        const repository = new Repository(AVAILABLE_ENTITIES.USERS)
        const userReq = await repository.getByParam('email', novoargs.email)
        if (userReq.success) {
          const user = userReq.response
          await repository.update({ id: user.id, password: novoargs.password })
        }
        dispatch(RESET_PASSWORD_SUCESS())
      })
      .catch(async err => {
        result = err
        dispatch(RESET_PASSWORD_FAIL(err))
      })
    return result
  }
}

export const getTerm = type => {
  return async dispatch => {
    try {
      const result = await api.get(
        dispatch,
        'auth',
        `Termo/ObterAtivo?tipo=${type}`
      )
      if (!result.hasErrors) {
        const toSaveData = {
          localData: result.conteudo,
          localVersion: result.versao
        }
        let key = ''
        switch (type) {
          case 'C':
            key = 'COOKIES_POLICY'
            break
          case 'P':
            key = 'PRIVACY_POLICY'
            break
          case 'U':
            key = 'END_USER_LICENSE'
            break
          default:
        }
        if (!_.isEmpty(key)) {
          await database.adapter.setLocal(key, JSON.stringify(toSaveData))
          return result
        }
      } else {
        console.warn(
          `There were errors trying to fetch term ${type}. (${result.title}/${result.errors})`
        )
        return null
      }
    } catch (error) {
      console.warn(`There was an error fetching term ${type}: ${error}`)
      return null
    }
  }
}

export const acceptTerm = ({ type, token }) => {
  return async dispatch => {
    try {
      //TODO: Refactor redux/api/actions to isolate request part to be reused here
      let params = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          tipo: type
        })
      }

      let url = `${process.env.REACT_APP_BASE_URL_GATEWAY}/login-register/api/Termo/Aceitar`

      return timeoutFetch(url, params)
        .catch(err => {
          let message =
            err instanceof TimeoutError
              ? `Timeout trying to accept term ${type}`
              : `There was an error trying to accept term ${type}: ${err}`
          console.warn(message)
          return false
        })
        .then(async response => {
          if (!response) return null
          if (response.status < 300) {
            return JSON.parse(await response.text())
          }
          console.warn(
            `Attempt to accept term ${type} failed. Received unexpected status ${response.status}`
          )
          return false
        })
    } catch (error) {
      console.warn(`There was an error trying to accept term ${type}: ${error}`)
      return false
    }
  }
}

export const dismissEula = () => {
  return async dispatch => {
    dispatch(DISMISS_EULA())
  }
}

export async function refreshToken() {
  const loggedUserInfo = await getLoggedUser()
  const URL = window.location.pathname === '/login'
    ? `${process.env.REACT_APP_BASE_URL_GATEWAY}/login-register/api/Authentication/LoginGigya`
    : `${process.env.REACT_APP_BASE_URL_GATEWAY}/login-register/api/Authentication/RefreshToken`
  const TIMEOUT = 15000

  let data = {
    refreshed: false
  }

  const user = await database.collections
    .get('users')
    .find(loggedUserInfo.userId)

  if (!user) {
    return data
  }

  let params = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      password: loggedUserInfo.pass,
      email: user.email,
      cpf: user.cpf
    })
  }

  // console.log(`Fetching new token for user ${loggedUserInfo.userId}`)
  await timeoutFetch(URL, params, TIMEOUT)
    .catch(error => {
      if (error instanceof TimeoutError) {
        console.warn('Timeout trying to refresh token. Skipping')
      } else {
        console.warn(`Error trying to refresh token: ${error}`)
      }
    })
    .then(async response => {
      let result = JSON.parse(await response.text())
      if (response.status === 200) {
        if (_.isArray(result.responseList)) {
          result = result.responseList
        } else {
          result = {
            ...(!_.isEmpty(result.response) ? result.response : {}),
            token: result.token,
            errors: result.errors || {},
            status: result.status,
            title: result.title || result.mensagem,
            hasErrors:
              !_.isEmpty(result.errors) ||
              (_.has(result, 'sucesso') && !result.sucesso)
          }

          data.refreshed = true
          data.userId = loggedUserInfo.userId
          data.pass = loggedUserInfo.pass
          data.token = result.token

          console.log(`Saving updated token`)
          await database.adapter.setLocal(
            CONSTANTS.LOGGED_USER_TOKEN,
            data.token
          )
          console.log(`Token saved`)
        }
      } else if (response.status === 400 || response.status === 401 || response.status === 403) {
        if (result.mensagem === "Senha incorreta!") {
          // logout()

          store.dispatch({
            type: CONSTANTS.USER_LOGIN_FAIL,
            error: "Senha incorreta!",
          })
        }
      }
    })

  return data
}
