import { combineReducers } from 'redux'

import api from './redux/api/reducer'
import auth from './redux/auth/reducer'
import batch from './redux/batch/reducer'
import dzero from './redux/dzero/reducer'
import farm from './redux/farm/reducer'
import protocol from './redux/protocol/reducer'
import screen from './redux/screen/reducer'
import sync from './redux/sync/reducer'
import user from './redux/user/reducer'

export const reducers = combineReducers({
  api,
  auth,
  farm,
  screen,
  user,
  protocol,
  batch,
  dzero,
  sync
})
