import React, { Component } from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'

class LoadingOverlay extends Component {
  render() {
    return (
      this.props.loading && (
        <div
          style={{
            zIndex: 100000,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // backgroundColor: '#E5E5E5'
          }}
        >
          <Backdrop open>
            <CircularProgress color='inherit' />
          </Backdrop>
        </div>
      )
    )
  }
}

const mapStateToProps = state => ({
  loading: state.screen.loading
})

export default connect(mapStateToProps, null)(LoadingOverlay)
