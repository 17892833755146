import '../../../components/styles/customstyle.scss'

import React, { Component } from 'react'

import ProGERARLogo from '../../../assets/img/logo_corte.png'
import ZoetisLogo from '../../../assets/img/logozoetis.png'
import moment from 'moment'

class NewCover extends Component {
  render() {
    const now = moment()
    return (
      <React.Fragment>
        {/* <div
          className='onlywhenprinting'
          style={{
            width: '100%',
            margin: '0 auto',
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            color: 'rgb(102, 102, 102)',
            fontWeight: 700,
            textAlign: 'center',
            height: '700px'
          }}
        >
          <div className='titleBox'>
            <div>
              {this.props.farm}
              {this.props.corral && (
                <React.Fragment>
                  <br />
                  {this.props.corral}
                </React.Fragment>
              )}
              {this.props.batch && (
                <React.Fragment>
                  <br />
                  {this.props.batch}
                </React.Fragment>
              )}
              <br />
              <span style={{ fontSize: '30px' }}>Proprietário: {this.props.proprietario}</span>
            </div>
          </div>

          <div className='middleContent'>

            <div
              style={{ fontSize: '30px' }}
            >
              Estação de Monta
              <br />
              <div style={{ fontWeight: 400 }}>
                {this.props.inicioEM}
                <br />
                {this.props.fimEM}
              </div>
            </div>


            <div
              style={{ fontSize: '30px', fontWeight: 400 }}
            >
              Relatório emitido pelo ProGerar - Zoetis
              <br />
              Responsável:  {this.props.user}
              <br />
              {now.format('DD/MM/YYYY [às] HH:mm')}
            </div>
          </div>

          <div className='logos'>
            <div className='progerarLogo'>
              <img
                src={ProGERARLogo}
                style={{ width: '300px' }}
              />
            </div>

          </div>

        </div> */}

        <div
          className='onlywhenprinting'
          style={{
            width: '100%',
            margin: '0 auto',
            height: '1300px',

          }}
        >
          <div
            style={{
              width: '100%',
              margin: '0 auto',
              height: '400px',
              textAlign: 'center',
              fontWeight: 700,

            }}
          >
            <br />
            <br />
            <div
              style={{
                fontSize: '60px',
                width: '100%'
              }}
            >
              {this.props.farm}
            </div>

            {this.props.corral && (
              <div
                style={{
                  fontSize: '55px',
                  width: '100%'

                }}
              >
                {this.props.corral}
              </div>
            )}

            {this.props.batch && (
              <div
                style={{
                  fontSize: '55px',
                  width: '100%'

                }}
              >
                {this.props.batch}
              </div>
            )}

            <div
              style={{
                fontSize: '40px',
                width: '100%'
              }}
            >
              Proprietário: {this.props.proprietario}
            </div>
          </div>
          <div
            style={{
              width: '100%',
              margin: '0 auto',
              height: '750px',
              textAlign: 'center',

            }}
          >
            <br />
            <br />
            <div
              style={{
                fontSize: '40px',
                width: '100%',
                fontWeight: 500
              }}
            >
              Estação de Monta
            </div>

            <div style={{
              fontSize: '35px',
              width: '100%',
              fontWeight: 400
            }}>
              {this.props.inicioEM}
              <br />
              {this.props.fimEM}
            </div>

            <br />
            <br />
            <br />
            <br />
            <div
              style={{
                fontSize: '35px',
                fontWeight: 400
              }}
            >
              Relatório emitido pelo ProGerar - Zoetis
              <br />
              Responsável:  {this.props.user}
              <br />
              {now.format('DD/MM/YYYY [às] HH:mm')}
            </div>

          </div>
          <div
            style={{
              width: '100%',
              margin: '0 auto',
              height: '150px',
              textAlign: 'center',
              fontWeight: 700,

            }}
          >
            <br />
            <img
              src={ProGERARLogo}
              style={{ width: '400px' }}
            />
          </div>
        </div>

        <div className='pagebreak' />
      </React.Fragment>
    )
  }
}

export default NewCover
