import { date, field, readonly, relation } from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'
import _ from 'lodash'

export const modelToDTOMapping = {
  identical: ['vacaId', 'observacao'],
  renamed: [],
  references: [
    {
      dtoName: 'dia',
      modelName: 'dia_id'
    },
    {
      dtoName: 'loteId',
      modelName: 'batch_id'
    }
  ]
}

export default class DNs extends Model {
  static table = 'dns'

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('vacaId') vacaId
  @field('observacao') observacao

  //Foreign Relations - Domain Values
  @relation('domain_values', 'dia_id') dia

  //Foreign Relations
  @relation('batches', 'batch_id') batch
}
