import { track } from 'react-tracking'

import { getLoggedUser } from '../redux/auth/actions.js'
import { TimeoutError } from '../redux/api/utils.js'
import { LocalStorageHelper } from './localStorageHelper'
import { getDifferenceAboutStorageAndCurrentDate } from './dates'
import { sleep } from '../syncWorker.js'
import App from '../App.js'

declare const window: any

const SLEEP_TIME = 1000 * 60 * 60 * 1 //1h
const TRACKING_URL = `${process.env.REACT_APP_BASE_URL_GATEWAY}/sync/api/api/tracking`

// functional component usage
export const TrackedApp = track(
  // app-level tracking data
  { app: 'ProGerar' },

  // top-level options
  {
    // custom dispatch to console.log in addition to pushing to dataLayer[]
    dispatch: (data) => {
      let trackedData = JSON.parse(
        LocalStorageHelper.get('trackedData') || '[]'
      )
      trackedData.push(data)

      LocalStorageHelper.add('trackedData', JSON.stringify(trackedData))
      ;(window.dataLayer = window.dataLayer || []).push(data)
    },
  }
)(App)

export const startTrackingLoop = async () => {
  await sleep(SLEEP_TIME)
  avoidConcurrentTracking()
}

async function avoidConcurrentTracking() {
  if (
    getDifferenceAboutStorageAndCurrentDate('lastSendTrackedData') &&
    !!LocalStorageHelper.get('trackedData')
  ) {
    await sendTrackedData()
  }
  await sleep(SLEEP_TIME)
  avoidConcurrentTracking()
}

export const sendTrackedData = async () => {
  const loggedUserInfo = await getLoggedUser()
  const data = {
    userId: loggedUserInfo.userId,
    sessionId: loggedUserInfo.sessionId,
    data: JSON.parse(LocalStorageHelper.get('trackedData')) || [],
  }
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${loggedUserInfo.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }

  await fetch(TRACKING_URL, params)
    .catch((err) => {
      if (err instanceof TimeoutError) {
        console.log(
          'Timeout trying to send changes. Will try again in next cycle'
        )
      } else {
        console.log(`There was an error trying to send sync object: ${err}`)
      }
      return false
    })
    .then(async (response: any) => {
      if (response.status >= 400) {
        return sendTrackedData()
      } else if (response.status >= 200 && response.status <= 299) {
        LocalStorageHelper.remove('trackedData')
        LocalStorageHelper.add('lastSendTrackedData', new Date())

        return true
      }
      return false
    })
}
