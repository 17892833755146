import * as api from '../../redux/api/actions.js'
import * as userActions from '../../redux/user/actions.js'

import { AVAILABLE_ENTITIES, Repository } from '../../database'
import React, { Component } from 'react'

import Alert from '@material-ui/lab/Alert'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt.js'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import UserForm from './components/UserForm'
import _ from 'lodash'
import { connect } from 'react-redux'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'
import track from 'react-tracking'

@track(() => ({ page: 'Usuários Form', date: new Date() }))
class UserUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      roles: [],
      loading: true,
      deleteConfirmationOpened: false
    }
    this.handleDelete = this.handleDelete.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)

    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onCancel() {
    this.props.history.push('/users')
  }

  async onSubmit(payload) {
    const repository = new Repository(AVAILABLE_ENTITIES.USERS)
    const update = await this.props.update(_.clone(payload))

    if (update.id) {
      const updateReq = await repository.update(_.clone(payload))
      if (!updateReq.success) {
        console.log(
          `There was an error trying to update user ${payload.id}: ${updateReq.exception}`
        )
      }
      this.props.history.push('/users')
    }

  }

  @track((props) => ({ action: 'Deletou o usuário', value: props.match.params.id }))
  async handleDelete() {
    await this.props.delete({ id: this.props.match.params.id })
    const repository = new Repository(AVAILABLE_ENTITIES.USERS)
    const updateReq = await repository.delete(this.props.match.params.id)
    if (updateReq.success) {
      this.props.history.push('/users')
    }
    this.setState({
      deleteConfirmationOpened: false
    })
  }

  confirmDelete() {
    this.setState({
      deleteConfirmationOpened: true
    })
  }

  UNSAFE_componentWillMount() {
    Promise.all([
      this.props.getAvailableRoles(),
      this.props.retrieve(this.props.match.params.id)
    ]).then(_ =>
      this.setState({
        ...this.props.user,
        loading: false
      })
    )
  }

  render() {
    if (this.props.loading || this.state.loading) {
      return (
        <Backdrop open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )
    }

    return (
      <Container>
        <Prompt
          visible={verify()}
          title='Página duplicada!'
          message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
          buttons={[
            {
              autoFocus: false
            },
          ]}
        />
        <TopBar title='' />
        <TitleBar
          title={this.state.nomeCompleto}
          buttons={[
            {
              onClick: this.confirmDelete,
              label: 'Deletar'
            }
          ]}
          paths={[
            {
              route: '/users',
              label: 'Usuários'
            },
            {
              label: this.state.nomeCompleto
            }
          ]}
        />
        <MainContainer>
          {this.props.error && (
            <Container
              disableGutters
              style={{
                marginBottom: '2rem'
              }}
            >
              <Alert
                severity='error'
                style={{
                  marginTop: '1em'
                }}
              >
                {this.props.error.title}
              </Alert>
            </Container>
          )}

          <UserForm
            initialValues={{
              ...this.props.user,
              role:
                this.props.user.roles.length > 0
                  ? this.props.user.roles[0].id
                  : ''
            }}
            onCancel={this.onCancel}
            onSubmit={this.onSubmit}
            roles={this.props.availableRoles}
            user={this.props.user}
          />

          <Prompt
            visible={this.state.deleteConfirmationOpened}
            title='Confirmar Exclusão'
            message='Você está prestes a deletar este usuário. Esta ação não poderá ser desfeita.'
            buttons={[
              {
                label: 'Cancelar',
                onClick: () =>
                  this.setState({ deleteConfirmationOpened: false })
              },
              {
                label: 'Confirmar',
                onClick: this.handleDelete
              }
            ]}
          />
        </MainContainer>
      </Container>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    update: async data =>
      api.put(dispatch, 'users', `Account/`, _.clone(data)),
    delete: ({ id }) => {
      api.del(dispatch, 'users', `Account/${id}`)
    },
    retrieve: id => dispatch(userActions.getUser({ id })), // api.get(dispatch, 'users', `Account/${id}`),
    getAvailableRoles: () => dispatch(userActions.getRoles()) // api.get(dispatch, 'users', `Profile/`)
  }
}

const mapStateToProps = state => ({
  loading:
    state.screen.loading ||
    api.getApiLoading(state, `Account/roles`) ||
    api.getApiLoading(state, `Account/`),
  user: state.user.selected,
  availableRoles: state.user.roles,
  success:
    api.getApiResult(state, `Account/roles`) ||
    api.getApiResult(state, `Account/`),
  error:
    api.getApiError(state, `Account/roles`) ||
    api.getApiError(state, `Account/`)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserUpdate))
