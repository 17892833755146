import { ComposedBatch } from '../models/batch.model'

export function currentDayIsD0(row: ComposedBatch): boolean {
  return (
    !row.pastDGFinal &&
    (!row.dias || row.dias === '') &&
    !(row.isResync && !row.isPrecoce)
  )
}

export function currentDayIsD0IATF(row: ComposedBatch): boolean {
  return currentDayIsD0(row) && !row.isResync
}

export function currentDayIsD0Ressinc(row: ComposedBatch): boolean {
  return (
    !row.pastDGFinal &&
    (!row.dias || row.dias === '') &&
    row.isResync &&
    row.isPrecoce
  )
}

export function currentDayIsDN(row: ComposedBatch): boolean {
  return (
    !row.pastDGFinal &&
    (!!row.dias || row.dias !== '') &&
    !(row.isLastDay || row.hasIatf) &&
    !!row.nextDay &&
    !(row.isResync && row.isPrecoce && !row.parentPastDG)
  )
}

export function currentDayIsIatf(row: ComposedBatch): boolean {
  return !row.pastDGFinal && (row.isLastDay || row.hasIatf)
}

export function currentDayIsRessinc(row: ComposedBatch): boolean {
  return (
    !row.pastDGFinal && row.dias != null && row.nextDay == null
    // !row.pastDGFinal && row.dias != null && row.nextDay == null && !row.pastDG
  )
}

export function currentDayIsDG(row: ComposedBatch): boolean {
  return (
    !row.pastDGFinal &&
    (!!row.dias || row.dias !== '') &&
    !row.nextDay &&
    !row.pastDG
  )
}

export function currentDayIsDGFinal(row: ComposedBatch): boolean {
  return (
    !row.pastDGFinal &&
    (!!row.dias || row.dias !== '') &&
    !row.nextDay &&
    row.pastDG
  )
}

export function currentDayIsDGDN(row: ComposedBatch): boolean {
  return (
    !row.pastDGFinal &&
    (!!row.dias || row.dias !== '') &&
    row.nextDay !== null &&
    row.isResync &&
    row.isPrecoce &&
    !row.parentPastDG &&
    !row.isFinalize
  )
}

export function currentDayIsDGD0(row: ComposedBatch): boolean {
  return (
    !row.pastDGFinal &&
    (!row.dias || row.dias === '') &&
    row.isResync &&
    !row.isPrecoce
  )
}

export function managementsOver(row: ComposedBatch): boolean {
  return row.pastDGFinal
}
