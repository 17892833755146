import CookiesPolicy, {
  BACKEND_IDENTIFIER as COOKIES_BACKEND_IDENTIFIER,
  NAME as COOKIES_NAME
} from './CookiesPolicy'
import PrivacyPolicy, {
  BACKEND_IDENTIFIER as PRIVACY_BACKEND_IDENTIFIER,
  NAME as PRIVACY_NAME
} from './PrivacyPolicy'
import React, { Component } from 'react'
import UsersLicense, {
  BACKEND_IDENTIFIER as USERS_LICENSE_BACKEND_IDENTIFIER,
  NAME as USERS_LICENSE_NAME
} from './UsersLicense'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export {
  COOKIES_BACKEND_IDENTIFIER,
  COOKIES_NAME,
  PRIVACY_BACKEND_IDENTIFIER,
  PRIVACY_NAME,
  USERS_LICENSE_BACKEND_IDENTIFIER,
  USERS_LICENSE_NAME
}

class EulaDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cookiesHeight: 0,
      usersHeight: 0,
      privacyHeight: 0,
      title: 'POLÍTICA DE COOKIES',
      shouldDisableButton: true,
      checked: false
    }

    this.dialogRef = React.createRef()
    this.cookiesRef = React.createRef()
    this.usersRef = React.createRef()
    this.privacyRef = React.createRef()

    this.handleScroll = this.handleScroll.bind(this)
    this.onAccept = this.onAccept.bind(this)
    this.onToggle = this.onToggle.bind(this)
  }

  handleScroll() {
    const y = this.dialogRef.current && this.dialogRef.current.scrollTop
    const contentHeight =
      this.state.cookiesHeight +
      this.state.usersHeight +
      this.state.privacyHeight
    const title = y
      ? y > this.state.cookiesHeight
        ? y > this.state.cookiesHeight + this.state.usersHeight
          ? 'POLÍTICA DE PRIVACIDADE'
          : 'TERMOS DE USO (“TERMO”)'
        : 'POLÍTICA DE COOKIES'
      : 'POLÍTICA DE COOKIES'
    const shouldDisableButton = !y || y === 0 || y < 0.9 * contentHeight
    this.setState({
      title,
      shouldDisableButton
    })
  }

  onAccept() {
    if (this.props.onAccept) {
      this.props.onAccept()
    }
    this.setState({
      shouldDisableButton: true
    })
  }

  onToggle(event) {
    const newState = !this.state.checked
    if (this.props.onToggle) {
      this.props.onToggle(newState)
    }
    this.setState({
      checked: newState
    })
  }

  render() {
    let color =
      this.state.shouldDisableButton || !this.state.checked
        ? '#666'
        : 'rgb(234, 93, 13)'
    return (
      <Dialog
        // disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth='xl'
        aria-labelledby='confirmation-dialog-title'
        open={this.props.visible}
        scroll='paper'
        style={{ maxWidth: '1200px', margin: 'auto' }}
        onScroll={this.handleScroll}
      >
        <DialogTitle id={`title`} style={{ color: '#666' }}>
          {this.state.title}
        </DialogTitle>
        <DialogContent
          ref={this.dialogRef}
          style={{ margin: '1em', fontSize: 16 }}
        >
          <CookiesPolicy
            ref={this.cookiesRef}
            onHeight={height => this.setState({ cookiesHeight: height })}
          />
          <UsersLicense
            ref={this.usersRef}
            onHeight={height => this.setState({ usersHeight: height })}
          />
          <PrivacyPolicy
            ref={this.privacyRef}
            onHeight={height => this.setState({ privacyHeight: height })}
          />
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.checked}
                onChange={this.onToggle}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disabled={this.state.shouldDisableButton}
                style={{ color: 'rgb(234, 93, 13)' }}
              />
            }
            label='Eu li e aceito os termos de utilização.'
          />
          <Button
            key={`accept_button`}
            autoFocus
            onClick={this.onAccept}
            color='primary'
            style={{ color, fontSize: 16 }}
            disabled={this.state.shouldDisableButton || !this.state.checked}
          >
            ACEITO
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EulaDialog
