import React, { Component } from 'react'

import Terms from './components/Terms'

export const NAME = 'COOKIES_POLICY'
export const BACKEND_IDENTIFIER = 'C'

class CookiesPolicy extends Component {
  render () {
    return (
      <Terms
        type={BACKEND_IDENTIFIER}
        name={NAME}
        onHeight={this.props.onHeight}
      />
    )
  }
}

export default CookiesPolicy
