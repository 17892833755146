import _ from 'lodash'

import { D0 } from '../../../models/d0.model'
import { idClOfEmpty, idConditionDG } from '../../../utils/idsHelper'

export const statisticsToDG = (savedCows: D0[]) => {
  const statistics = savedCows.reduce(
    (acc: any, cow) => {
      const conditionsMap: any = {
        [idConditionDG.pregnant]: 'prenhes',
        [idConditionDG.pregnantDiscard]: 'descartesPrenhas',
        [idConditionDG.emptyDiscard]: 'descartesVazias',
        [idConditionDG.empty]: 'vazias',
      }
      const clOfEmptyMap: any = {
        [idClOfEmpty.no]: 'clNao',
        [idClOfEmpty.yes]: 'clSim',
        [idClOfEmpty.notEvaluated]: 'clNaoAvaliado',
      }
      const { condicaoDG, clVazia } = cow
      const condictions = conditionsMap[condicaoDG!] || condicaoDG
      const cls = clOfEmptyMap[clVazia!] || clVazia

      if (!acc.hasOwnProperty(condictions)) {
        acc[condictions] = 0
      }

      if (!acc.hasOwnProperty(cls)) {
        acc[cls] = 0
      }

      acc[condictions]++
      acc[cls]++

      return acc
    },
    {
      prenhes: 0,
      vazias: 0,
      descartesPrenhas: 0,
      descartesVazias: 0,
      clNao: 0,
      clSim: 0,
      clNaoAvaliado: 0,
    }
  )
  const totalCows = savedCows.length
  const emptyAndDiscardCows = savedCows.filter(
    (item) =>
      item.condicaoDG === idConditionDG.empty ||
      item.condicaoDG === idConditionDG.emptyDiscard
  ).length

  const prenhesTotal = statistics.prenhes + statistics.descartesPrenhas
  const vaziasTotal = statistics.vazias + statistics.descartesVazias

  const calculatePercentage = (value: number, total: number) => {
    let percentage = (value * 100) / total

    if (_.isNaN(percentage)) {
      return 0
    }

    return percentage.toFixed(2)
  }

  return {
    prenhesTotal,
    prenhesTotalPercentage: calculatePercentage(prenhesTotal, totalCows),
    vaziasTotal,
    vaziasTotalPercentage: calculatePercentage(vaziasTotal, totalCows),
    descartesPrenhas: statistics.descartesPrenhas,
    descartesVazias: statistics.descartesVazias,
    clNaoAvaliado: statistics.clNaoAvaliado,
    clNotEvaluatedPercentage: calculatePercentage(
      statistics.clNaoAvaliado,
      emptyAndDiscardCows
    ),
    clNao: statistics.clNao,
    clNoPercentage: calculatePercentage(statistics.clNao, emptyAndDiscardCows),
    clSim: statistics.clSim,
    clYesPercentage: calculatePercentage(statistics.clSim, emptyAndDiscardCows),
  }
}
