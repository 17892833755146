import * as CONSTANTS from './constants'


const initialState = {
  vacas: null,
  vaca: null,
};

export default function reduce (state = initialState, action) {
    switch (action.type) {
        case CONSTANTS.LIST_VACAS:
            return {
                ...state,
                vacas: action.data
            }
        case CONSTANTS.GET_VACA:
            return {
                ...state,
                vaca: action.data
            }
        default:
            return state;
    }
}