import React, { Component } from 'react'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import MaterialButton from '@material-ui/core/Button'

const COLOR_STYLES = {
  WHITE: {
    backgroundColor: '#FFF',
    border: '1px solid rgb(227, 227, 227)',
    color: 'gray'
  },
  GREY: { backgroundColor: '#666' },
  ORANGE: { backgroundColor: 'rgb(234, 93, 13)' },
  DISABLED: { backgroundColor: '#dadada' }
}

const COLORS = _.reduce(
  Object.keys(COLOR_STYLES),
  function (prev, curr) {
    return {
      ...prev,
      [curr]: curr
    }
  },
  {}
)

class Button extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const disabledStyle = this.props.disabled && COLOR_STYLES.DISABLED
    const style = {
      ...COLOR_STYLES[this.props.color || COLORS.ORANGE],
      ...disabledStyle,
      ...this.props.style
    }

    const button = (
      <MaterialButton
        variant='contained'
        color={this.props.secondary ? 'secondary' : 'primary'}
        disableElevation
        size={this.props.small ? 'small' : 'large'}
        onClick={this.props.onClick}
        tabIndex={this.props.tabIndex}
        style={style}
        type={this.props.type}
        startIcon={this.props.startIcon}
        endIcon={this.props.endIcon}
        disabled={this.props.disabled}
        className={this.props.className}
      >
        {this.props.label}
      </MaterialButton>
    )
    if (this.props.withGrid) {
      return (
        <Grid
          item
          xs={this.props.withGrid}
          style={{ ...this.props.containerStyle }}
        >
          {button}
        </Grid>
      )
    }
    return button
  }
}

export { COLORS }

export default Button
