import React, { Component } from 'react'
import uuid from 'react-uuid'
import track from 'react-tracking'
import _ from 'lodash'

import { withPropsFromDatabase } from '../../../database/resources/utils'
import { ESTADOS, ESTADOS_E_CIDADES } from '../../../utils/constants'
import {
  findEstado,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from './../../../utils/formHelper'
import Autocomplete, {
  autocompleteDefaultOnChangeSavingID,
  autocompleteDefaultOnChange,
} from '../../../components/material/Autocomplete'
import Button, { COLORS } from '../../../components/material/Button'
import TextField, {
  textfieldDefaultOnChange,
} from '../../../components/material/TextField'

@track(() => ({ page: 'Usuários Form', date: new Date() }))
class UserForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props.initialValues,
      loading: false,
      roles: [],
    }

    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)
    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)

    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  onEnterPress = (e) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault()
      this.onSubmit()
    }
  }

  @track((props, state) =>
    state.id
      ? { action: 'Editou um usuário', value: state.id }
      : { action: 'Criou um novo usuário', value: state.nomeCompleto }
  )
  onSubmit() {
    this.setState({ loading: true })
    let requiredFieldsState = this.requiredFieldsEvaluator()
    if (
      requiredFieldsState !== null ||
      this.state.nomeCompleto == null ||
      this.state.estado == null ||
      this.state.municipio == null ||
      this.state.role == null
    ) {
      this.setState(requiredFieldsState)
    } else {
      let payload = {
        ...this.props.user,
        ..._.pick(this.state, ['nomeCompleto', 'email', 'municipio']),
        estado: findEstado(this.state.estado).name,
        rolesIds: [this.state.role],
      }

      if (this.props.user === null) {
        payload.email = `${uuid()}@usuariosememail.com`
      }

      if (this.props.user) {
        payload.usuarioId = this.props.user.id
      }

      if (this.props.onSubmit) {
        this.props.onSubmit(payload)
      }
    }

    this.setState({ loading: false })
  }

  render() {
    return (
      <div className='grid'>
        <div
          className={`p-12 p-margin-top-2 grid-item ${this.props.user !== null ? 'd-6' : 'd-12'
            }`}
        >
          <TextField
            id='nomeCompleto'
            label='Nome Completo'
            onChange={this.textfieldDefaultOnChange}
            onKeyDown={this.onEnterPress}
            value={this.state.nomeCompleto || ''}
            error={!_.isEmpty(this.state.nomeCompleto_error)}
            helperText={this.state.nomeCompleto_error}
          />
        </div>

        {this.props.user !== null && (
          <div className='p-12 d-6 p-margin-top-2 grid-item'>
            <TextField
              id='email'
              label='E-mail'
              onChange={this.textfieldDefaultOnChange}
              onKeyDown={this.onEnterPress}
              type='email'
              value={
                this.state.email
                  ? this.state.email.indexOf('@usuariosememail.com') >= 0
                    ? 'Não Possui'
                    : this.state.email
                  : ''
              }
              helperText={this.state.email_error}
              disabled
            />
          </div>
        )}

        <div className='p-12 t-4 d-4 p-margin-top-2 grid-item'>
          <Autocomplete
            id='estado'
            options={ESTADOS}
            onChange={this.autocompleteDefaultOnChangeSavingID('estado')}
            onKeyDown={this.state.estado && this.onEnterPress}
            getOptionLabel={(option) => {
              if (_.has(option, 'name')) {
                return option.name
              }
              const opt = findEstado(option)
              return (opt && opt.name) || ''
            }}
            useDefaultOptionSelected
            label='Estado'
            value={this.state.estado || ''}
            error={!_.isEmpty(this.state.estado_error)}
            helperText={this.state.estado_error}
          />
        </div>

        <div className='p-12 t-8 d-4 p-margin-top-2 grid-item'>
          <Autocomplete
            id='municipio'
            label='Município'
            options={
              this.state.estado
                ? ESTADOS_E_CIDADES.filter(
                  (e) =>
                    e.name === this.state.estado ||
                    e.id === this.state.estado
                )[0].cidades
                : []
            }
            disabled={!this.state.estado}
            onChange={this.autocompleteDefaultOnChange('municipio')}
            onKeyDown={this.onEnterPress}
            value={this.state.municipio}
            error={!_.isEmpty(this.state.municipio_error)}
            helperText={this.state.municipio_error}
          />
        </div>

        <div className='p-12 d-4 p-margin-top-2 grid-item'>
          <Autocomplete
            id='role'
            options={this.props.roles.filter(
              (x) => x.name !== 'Visualizador'
            )}
            onChange={this.autocompleteDefaultOnChangeSavingID('role')}
            onKeyDown={this.state.role && this.onEnterPress}
            getOptionLabel={(option) => {
              if (_.has(option, 'name')) {
                return option.name
              }
              const opt = _.find(this.props.roles, { id: option })
              return (opt && opt.name) || ''
            }}
            useDefaultOptionSelected
            label='Perfil'
            value={this.state.role || ''}
            error={!_.isEmpty(this.state.role_error)}
            helperText={this.state.role_error}
          />
        </div>

        <div className='p-12 p-margin-top-2 p-display-flex p-justify-space-between grid-item'>
          <Button
            color={COLORS.GREY}
            label='Cancelar'
            onClick={this.onCancel}
          />
          <Button
            label='Salvar'
            disabled={this.props.loading || this.state.loading}
            onClick={this.onSubmit}
          />
        </div>
      </div>
    )
  }
}

const propsFromDatabase = async (props) => {
  return {
    requiredFields: [
      'nomeCompleto',
      'estado',
      'municipio',
      'role',
    ],
  }
}

export default withPropsFromDatabase(propsFromDatabase, UserForm)
