import { AVAILABLE_ENTITIES, utils } from '../../../database'
import Autocomplete, {
  autocompleteDefaultOnChange,
  autocompleteDefaultOnChangeSavingID,
  autocompleteDefaultOnChangeSavingIDWhenOpenField,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField
} from '../../../components/material/Autocomplete'
import Button, { COLORS } from '../../../components/material/Button'
import {
  CONDITIONAL_REQUIRED_FIELD_TYPE,
  NOT_NULL,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator
} from './../../../utils/formHelper'
import React, { Component } from 'react'
import ResponsiveTable, { CELL_TYPES } from '../../../components/ResponsiveTable'
import TextField, {
  textfieldDefaultOnChange
} from '../../../components/material/TextField'

import AddIcon from '@material-ui/icons/Add'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Prompt from '../../../components/Prompt'
import RemoveIcon from '@material-ui/icons/Remove'
import _ from 'lodash'
import { getLoggedUser } from '../../../redux/auth/actions'
import track from 'react-tracking'

@track(() => ({ page: 'Protocolos Form', date: new Date() }))
class ProtocolForm extends Component {
  constructor(props) {
    super(props)

    let initialValues = {}
    if (this.props.protocol) {
      const protocol = this.props.protocol
      const d0s = _.filter(
        protocol.managementProtocols,
        managementProtocol => managementProtocol.day === this.props.d0.id
      )

      const implant = _.find(d0s, d0 => d0.implantId !== null)
      const benzoato = _.find(
        d0s,
        d0 => d0.managementId === this.props.benzoatoEstradiol.id
      )
      const luteolise = _.find(
        d0s,
        d0 => d0.managementId === this.props.luteolise.id
      )

      const progesterona = _.find(
        d0s,
        d0 => d0.managementId === this.props.progesterona.id
      )

      let hasRemocaoBezerros = false
      let hasIatf = false
      let hasRetirada = false
      let iatfDay = null

      _.each(props.protocol.managementProtocols, managementProtocol => {
        if (managementProtocol.managementId === props.retirada.id) {
          hasRetirada = true
        } else if (
          managementProtocol.managementId === props.remocaoBezerros.id
        ) {
          hasRemocaoBezerros = true
        } else if (managementProtocol.managementId === props.iatf.id) {
          hasIatf = true
          iatfDay = _.find(props.days, { id: managementProtocol.day }).id
        }
      })

      initialValues = {
        id: protocol.id,
        name: protocol.name,
        d0Implant: implant && implant.id,
        d0benzoatoEstradiol: benzoato && benzoato.id,
        d0luteolise: luteolise && luteolise.id,
        d0progesterona: progesterona && progesterona.id,
        veterinaryId: protocol.veterinaryId,
        implantId: implant && implant.implantId,
        benzoatoEstradiol: benzoato && benzoato.hormoneTypeId,
        BEDosages: benzoato
          ? _.filter(
            this.props.dosagens,
            dosagem => dosagem.valorDominioPaiId === benzoato.hormoneTypeId
          )
          : [],
        benzoatoEstradiolDosage: benzoato && benzoato.hormoneDosageId,
        benzoatoEstradiolDosage_value: benzoato && benzoato.hormoneDosageValue,
        luteolise: luteolise && luteolise.hormoneTypeId,
        LDosages:
          luteolise && luteolise
            ? _.filter(
              this.props.dosagens,
              dosagem => dosagem.valorDominioPaiId === luteolise.hormoneTypeId
            )
            : [],
        luteoliseDosage: luteolise && luteolise.hormoneDosageId,
        luteoliseDosage_value: luteolise && luteolise.hormoneDosageValue,
        showLuteolise: luteolise || false,
        progesterona: progesterona && progesterona.hormoneTypeId,
        PIDosages: progesterona
          ? _.filter(
            this.props.dosagens,
            dosagem =>
              dosagem.valorDominioPaiId === progesterona.hormoneTypeId
          )
          : [],
        progesteronaDosage: progesterona && progesterona.hormoneDosageId,
        progesteronaDosage_value:
          progesterona && progesterona.hormoneDosageValue,
        showProgesterona: progesterona || false,
        hasIatf,
        hasRemocaoBezerros,
        hasRetirada,
        iatfDay,
        tabulatedManagementProtocols: _.sortBy(
          _.filter(
            protocol.managementProtocols,
            managementProtocol => managementProtocol.implantId == null
          ).map(managementProtocol => {
            const day = _.find(this.props.days, { id: managementProtocol.day })
            const management = _.find(this.props.tiposManejo, {
              id: managementProtocol.managementId
            })
            const hormoneType = _.find(this.props.hormonios, {
              id: managementProtocol.hormoneTypeId
            })
            const hormoneDosage = _.find(this.props.dosagens, {
              id: managementProtocol.hormoneDosageId
            })

            return {
              ..._.pick(managementProtocol, [
                'id',
                'day',
                'managementId',
                'hormoneTypeId',
                'hormoneDosageId'
              ]),
              hormoneDosageId_value: managementProtocol.hormoneDosageValue,
              day_label: day && day.valor,
              managementId_label: management && management.valor,
              hormoneTypeId_label: (hormoneType && hormoneType.valor) || '',
              hormoneDosageId_label:
                (hormoneDosage && hormoneDosage.valor) ||
                managementProtocol.hormoneDosageValue ||
                ''
            }
          }),
          [
            protocol =>
              protocol.day_label.startsWith('D')
                ? parseInt(protocol.day_label.substring(1))
                : 100,
            'managementId_label'
          ]
        )
      }
    }

    this.state = {
      BEDosages: [],
      LDosages: [],
      PIDosages: [],
      showLuteolise: false,
      showProgesterona: false,
      tabulatedManagementProtocols: [],
      products: [],
      dosages: [],
      iatfDay: null,
      ...initialValues
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)
    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.autocompleteDefaultOnChangeSavingID = autocompleteDefaultOnChangeSavingID.bind(
      this
    )
    this.autocompleteDomainValueOptionLabelSavingID = autocompleteDomainValueOptionLabelSavingID.bind(
      this
    )
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)

    this.filterProducts = this.filterProducts.bind(this)
    this.filterDosages = this.filterDosages.bind(this)
    this.removeProtocol = this.removeProtocol.bind(this)
    this.addProtocol = this.addProtocol.bind(this)

    this.filterManagements = this.filterManagements.bind(this)

    this.autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField = autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField.bind(
      this
    )
    this.autocompleteDefaultOnChangeSavingIDWhenOpenField = autocompleteDefaultOnChangeSavingIDWhenOpenField.bind(
      this
    )
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  @track((props, state) => (
    state.id
      ? { action: 'Editou um protocolo', value: state.id }
      : state.hasIatf
        ? { action: 'Criou um novo protocolo', value: state.name }
        : { action: 'Erro ao criar um protocolo', value: 'O protocolo precisa ter uma entrada de IATF.' }
  ))
  onSubmit() {
    if (!this.state.hasIatf) {
      this.setState({
        showDialog: true,
        dialogMessage: 'O protocolo precisa ter uma entrada de IATF.'
      })
    } else if (
      this.state.day &&
      this.state.managementId &&
      ((this.state.hormoneTypeId &&
        (this.state.hormoneDosageId || this.state.hormoneDosageId_value)) ||
        this.state.managementId === this.props.iatf.id ||
        this.state.managementId === this.props.retirada.id ||
        this.state.managementId === this.props.remocaoBezerros.id)
    ) {
      this.setState({
        showDialog: true,
        dialogMessage:
          'Há um manejo na adição de manejos que não foi adicionado. Clique no botão de adicionar “+” para inseri-lo ao protocolo.'
      })
    } else {
      const iatfDay = this.state.iatfDay
      const lastDay = _.last(this.state.tabulatedManagementProtocols).day
      if (iatfDay !== lastDay) {
        this.setState({
          showDialog: true,
          dialogMessage: 'O IATF precisa ser o último item da lista de manejos.'
        })
      } else {
        let d0Managements = []
        if (this.state.implantId) {
          d0Managements.push({
            // id: this.state.d0Implant,
            day: this.props.d0.id,
            implantId: this.state.implantId
          })
        }
        _.each(['benzoatoEstradiol', 'luteolise', 'progesterona'], key => {
          if (!_.isEmpty(this.state[key])) {
            d0Managements.push({
              // id: this.state[`d0${key}`],
              day: this.props.d0.id,
              managementId: this.props[key].id,
              hormoneTypeId: this.state[key],
              hormoneDosageId: this.state[`${key}Dosage`],
              hormoneDosageValue: this.state[`${key}Dosage_value`]
            })
          }
        })
        let payload = {
          // ...this.props.protocol,
          name: this.state.name,
          veterinaryId: this.props.isAdmin ? null : this.props.user.id,
          managementProtocols: d0Managements.concat(
            this.state.tabulatedManagementProtocols.map(item => ({
              ..._.pick(item, [
                // 'id',
                'day',
                'managementId',
                'hormoneTypeId',
                'hormoneDosageId'
              ]),
              hormoneDosageValue: item.hormoneDosageId_value
            }))
          )
        }

        let requiredFieldsState = this.requiredFieldsEvaluator()

        if (requiredFieldsState !== null) {
          this.setState(requiredFieldsState)
        } else {
          if (this.props.onSubmit) {
            this.props.onSubmit(payload)
          }
        }
      }
    }
  }

  onEnterPress = e => {
    if (this.props.user.roles[0].name !== 'Cliente (somente visualização)') {
      if (e.keyCode == 13 && e.shiftKey == false) {
        e.preventDefault()
        this.onSubmit()
      }
    }
  }

  componentDidUpdate() { }

  filterProducts(value) {
    this.setState({
      products:
        value && value.id
          ? _.sortBy(
            _.filter(
              this.props.hormonios,
              hormonio => hormonio.valorDominioPaiId === value.id
            ),
            [
              hormonio => !hormonio.valor.toLowerCase().startsWith('zoetis'),
              hormonio => hormonio.valor.toLowerCase()
            ]
          )
          : [],
      dosages: []
    })
  }

  filterDosages({ target, value }) {
    this.setState({
      [target]:
        value && value.id
          ? _.sortBy(
            _.filter(
              this.props.dosagens,
              dosagem => dosagem.valorDominioPaiId === value.id
            ),
            [dosage => dosage.valor.toLowerCase()]
          )
          : []
    })
  }

  filterManagements() {
    let managements = this.props.managements
    if (this.state.hasIatf) {
      managements = _.filter(
        managements,
        management => management.id !== this.props.iatf.id
      )
    }
    if (this.state.hasRetirada) {
      managements = _.filter(
        managements,
        management => management.id !== this.props.retirada.id
      )
    }

    if (this.state.day == this.props.d0.id) {
      managements = _.filter(
        managements,
        management =>
          management.valor == 'Progesterona Injetável' ||
          management.valor == 'Luteólise'
      )
    }

    if (this.state.day != this.props.d0.id) {
      managements = _.filter(
        managements,
        management => management.valor != 'Progesterona Injetável'
      )
    }

    if (this.state.hasRemocaoBezerros) {
      managements = _.filter(
        managements,
        management => management.id !== this.props.remocaoBezerros.id
      )
    }
    return managements
  }

  removeProtocol(index) {
    this.setState({
      luteolise: null,
      luteolise_error: null,
      luteoliseDosage: null,
      luteoliseDosage_error: null,
      progesterona: null,
      progesterona_error: null,
      progesteronaDosage: null,
      progesteronaDosage_error: null
    })

    let tabulatedManagementProtocols = this.state.tabulatedManagementProtocols
    let toRemove = tabulatedManagementProtocols[index]
    if (
      this.state.tabulatedManagementProtocols.filter(
        managementProtocol =>
          managementProtocol.day_label != 'D0' ||
          managementProtocol.managementId_label != 'Estímulo Ovulatório'
      ).length != this.state.tabulatedManagementProtocols.length
    ) {
      toRemove = tabulatedManagementProtocols[index + 1]
      tabulatedManagementProtocols.splice(index + 1, 1)
    } else {
      tabulatedManagementProtocols.splice(index, 1)
    }

    let isIatf = toRemove.managementId === this.props.iatf.id
    let isRetirada = toRemove.managementId === this.props.retirada.id
    let isRemocaoBezerros =
      toRemove.managementId === this.props.remocaoBezerros.id
    this.setState({
      tabulatedManagementProtocols,
      hasIatf: isIatf ? false : this.state.hasIatf,
      iatfDay: isIatf ? null : this.state.iatfDay,
      hasRetirada: isRetirada ? false : this.state.hasRetirada,
      hasRemocaoBezerros: isRemocaoBezerros
        ? false
        : this.state.hasRemocaoBezerros
    })
  }

  addProtocol() {
    let tabulatedManagementProtocols = this.state.tabulatedManagementProtocols
    const day = _.find(this.props.days, { id: this.state.day })
    const management = _.find(this.props.tiposManejo, {
      id: this.state.managementId
    })
    const hormoneType = _.find(this.props.hormonios, {
      id: this.state.hormoneTypeId
    })
    const hormoneDosage = _.find(this.props.dosagens, {
      id: this.state.hormoneDosageId
    })

    const isIatf = management.id === this.props.iatf.id
    const isRetirada = management.id === this.props.retirada.id
    const isRemocaoBezerros = management.id === this.props.remocaoBezerros.id

    tabulatedManagementProtocols.push({
      ..._.pick(this.state, [
        'day',
        'managementId',
        'hormoneTypeId',
        'hormoneDosageId',
        'hormoneDosageId_value'
      ]),
      day_label: day && day.valor,
      managementId_label: management && management.valor,
      hormoneTypeId_label: (hormoneType && hormoneType.valor) || '',
      hormoneDosageId_label:
        (hormoneDosage && hormoneDosage.valor) ||
        this.state.hormoneDosageId_value ||
        ''
    })
    tabulatedManagementProtocols = _.sortBy(tabulatedManagementProtocols, [
      protocol =>
        protocol.day_label.startsWith('D')
          ? parseInt(protocol.day_label.substring(1))
          : 100,
      'managementId_label'
    ])
    this.setState({
      tabulatedManagementProtocols,
      managementId: null,
      hormoneTypeId: null,
      hormoneDosageId: null,
      hormoneDosageId_value: null,
      products: [],
      dosages: [],
      day: isIatf ? null : this.state.day,
      hasIatf: isIatf || this.state.hasIatf,
      hasRetirada: isRetirada || this.state.hasRetirada,
      hasRemocaoBezerros: isRemocaoBezerros || this.state.hasRemocaoBezerros,
      iatfDay: isIatf ? day.id : this.state.iatfDay
    })
  }

  getIcon() {
    return !(
      this.state.day &&
      this.state.managementId &&
      ((this.state.hormoneTypeId &&
        (this.state.hormoneDosageId ||
          this.state.hormoneDosageId_value)) ||
        this.state.managementId === this.props.iatf.id ||
        this.state.managementId === this.props.retirada.id ||
        this.state.managementId ===
        this.props.remocaoBezerros.id)
    )
  }

  render() {
    let tableColumns = [
      {
        name: 'Dia do Manejo',
        type: CELL_TYPES.TEXT,
        label: row => row.day_label,
        grid: 2
      },
      {
        name: 'Tipo de Manejo',
        type: CELL_TYPES.TEXT,
        label: row => row.managementId_label,
        grid: 4
      },
      {
        name: 'Produto',
        type: CELL_TYPES.TEXT,
        label: row => row.hormoneTypeId_label,
        grid: 3
      },
      {
        name: 'Dosagem (mL)',
        type: CELL_TYPES.TEXT,
        label: row => row.hormoneDosageId_label,
        grid: this.props.viewOnly ? 3 : 2
      }
    ]

    if (!this.props.viewOnly) {
      tableColumns.push({
        type: CELL_TYPES.HOLLOW_BUTTON,
        icon: row => <RemoveIcon />,
        onClick: (row, index) => {
          return () => this.removeProtocol(index)
        },
        grid: 1
      })
    }

    return (
      <Container disableGutters>
        <div className="grid protocol-form-grid">
          <div className="p-12">
            <TextField
              id='name'
              label='Nome do Protocolo'
              onChange={this.textfieldDefaultOnChange}
              onKeyDown={this.onEnterPress}
              value={this.state.name || ''}
              error={!_.isEmpty(this.state.name_error)}
              helperText={this.state.name_error}
              disabled={this.props.viewOnly}
              withGrid={12}
            />
          </div>

          <div className="p-12 t-1 d-2">
            <h2 style={{
              lineHeight: '1.43',
              fontSize: '1.5em',
              display: 'block',
              marginBlockStart: '0.83em',
              marginBlockEnd: '0.83em',
            }}>D0</h2>
          </div>

          <div className="p-12 t-3 d-4 p-display-flex p-align-items-center">
            <h4>Dispositivo Intravaginal</h4>
          </div>

          <div className="p-12 t-8 d-6">
            <Autocomplete
              id='implantId'
              options={this.props.implants}
              value={this.state.implantId || ''}
              onChange={this.autocompleteDefaultOnChangeSavingID('implantId')}
              onKeyDown={this.state.implantId && this.onEnterPress}
              getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                this.props.implants
              )}
              useDefaultOptionSelected
              label='Dispositivo Intravaginal'
              disabled={this.props.viewOnly}
              error={!_.isEmpty(this.state.implantId_error)}
              helperText={this.state.implantId_error}
            />
          </div>

          <div className="p-2 t-1 d-2">
            <h2>&nbsp;</h2>
          </div>

          <div className="p-12 t-3 d-4 p-display-flex p-align-items-center">
            <h4>Estímulo Ovulatório</h4>
          </div>

          <div className="p-12 t-4 d-3">
            <Autocomplete
              id='benzoatoEstradiol'
              options={this.props.benzoatoEstradiolHormonios}
              value={this.state.benzoatoEstradiol || ''}
              onChange={(e, value) => {
                this.autocompleteDefaultOnChangeSavingID('benzoatoEstradiol')(
                  e,
                  value
                )
                this.setState({
                  benzoatoEstradiolDosage: null,
                  benzoatoEstradiolDosage_value: null,
                  benzoatoEstradiolDosage_error: null
                })
                this.filterDosages({ target: 'BEDosages', value })
              }}
              onKeyDown={
                this.state.benzoatoEstradiol && this.onEnterPress
              }
              getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                this.props.benzoatoEstradiolHormonios
              )}
              useDefaultOptionSelected
              label='Estímulo Ovulatório'
              disabled={this.props.viewOnly}
              error={!_.isEmpty(this.state.benzoatoEstradiol_error)}
              helperText={this.state.benzoatoEstradiol_error}
            />
          </div>

          <div className="p-12 t-4 d-3">
            <Autocomplete
              id='benzoatoEstradiolDosage'
              options={this.state.BEDosages}
              value={
                this.state.benzoatoEstradiolDosage ||
                this.state.benzoatoEstradiolDosage_value ||
                ''
              }
              onChange={this.autocompleteDefaultOnChangeSavingIDWhenOpenField(
                'benzoatoEstradiolDosage'
              )}
              onKeyDown={
                (this.state.benzoatoEstradiolDosage ||
                  this.state.benzoatoEstradiolDosage_value)
                && this.onEnterPress
              }
              getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField(
                this.state.BEDosages
              )}
              useDefaultOptionSelected
              label='Dosagem (mL)'
              disabled={this.props.viewOnly}
              error={!_.isEmpty(this.state.benzoatoEstradiolDosage_error)}
              helperText={this.state.benzoatoEstradiolDosage_error}
              openField
            />
          </div>

          <Container>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          </Container>

          {!this.props.viewOnly && (
            <>
              <div className="p-12 t-6 d-2">
                <Autocomplete
                  id='day'
                  options={
                    this.state.hasIatf
                      ? this.props.days.slice(
                        0,
                        _.findIndex(this.props.days, { id: this.state.iatfDay })
                      )
                      : this.props.days
                  }
                  value={this.state.day || ''}
                  onChange={this.autocompleteDefaultOnChangeSavingID('day')}
                  onKeyDown={this.state.day && this.onEnterPress}
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.days
                  )}
                  useDefaultOptionSelected
                  label='Dia do Manejo'
                  error={!_.isEmpty(this.state.day_error)}
                  helperText={this.state.day_error}
                />
              </div>

              <div className="p-12 t-6 d-4">
                <Autocomplete
                  id='managementId'
                  options={this.filterManagements()}
                  value={this.state.managementId || ''}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChangeSavingID('managementId')(
                      e,
                      value
                    )
                    this.setState({
                      hormoneTypeId: null,
                      hormoneTypeId_error: null,
                      hormoneDosageId: null,
                      hormoneDosageId_value: null,
                      hormoneDosageId_error: null
                    })
                    this.filterProducts(value)
                  }}
                  onKeyDown={this.state.managementId && this.onEnterPress}
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.managements
                  )}
                  useDefaultOptionSelected
                  label='Tipo de Manejo'
                  error={!_.isEmpty(this.state.managementId_error)}
                  helperText={this.state.managementId_error}
                />
              </div>

              <div className="p-12 t-5 d-3">
                <Autocomplete
                  id='hormoneTypeId'
                  options={this.state.products}
                  value={this.state.hormoneTypeId || ''}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChangeSavingID('hormoneTypeId')(
                      e,
                      value
                    )
                    this.setState({
                      hormoneDosageId: null,
                      hormoneDosageId_value: null,
                      hormoneDosageId_error: null
                    })
                    this.filterDosages({ target: 'dosages', value })
                  }}
                  onKeyDown={this.state.hormoneTypeId && this.onEnterPress}
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.state.products
                  )}
                  disabled={
                    this.state.managementId === this.props.iatf.id ||
                    this.state.managementId === this.props.retirada.id ||
                    this.state.managementId === this.props.remocaoBezerros.id
                  }
                  useDefaultOptionSelected
                  label='Produto'
                  error={!_.isEmpty(this.state.hormoneTypeId_error)}
                  helperText={this.state.hormoneTypeId_error}
                />

              </div>

              <div className="p-12 t-5 d-2">
                <Autocomplete
                  id='hormoneDosageId'
                  options={this.state.dosages}
                  value={
                    this.state.hormoneDosageId ||
                    this.state.hormoneDosageId_value ||
                    ''
                  }
                  onChange={this.autocompleteDefaultOnChangeSavingIDWhenOpenField(
                    'hormoneDosageId'
                  )}
                  onKeyDown={
                    (this.state.hormoneDosageId || this.state.hormoneDosageId_value)
                    && this.onEnterPress
                  }
                  getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField(
                    this.state.dosages
                  )}
                  disabled={
                    this.state.managementId === this.props.iatf.id ||
                    this.state.managementId === this.props.retirada.id ||
                    this.state.managementId === this.props.remocaoBezerros.id
                  }
                  useDefaultOptionSelected
                  label='Dosagem (mL)'
                  error={!_.isEmpty(this.state.hormoneDosageId_error)}
                  helperText={this.state.hormoneDosageId_error}
                  openField
                />
              </div>

              <div className="p-12 t-2 d-1">
                <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton onClick={this.addProtocol} disabled={this.getIcon()}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </div>
            </>
          )}

          <Container disableGutters style={{ marginTop: '2em' }}>
            <ResponsiveTable
              hasTopPagination={false}
              className="table-protocol-form"
              columns={tableColumns}
              data={this.state.tabulatedManagementProtocols.filter(
                managementProtocol =>
                  managementProtocol.day_label != 'D0' ||
                  managementProtocol.managementId_label != 'Estímulo Ovulatório'
              )}
              skipPagination
              onSort={(sortedData) => this.setState({ tabulatedManagementProtocols: sortedData })}
            />
          </Container>
          <Container disableGutters style={{ marginTop: '2em' }}>
            <Grid container style={{ marginTop: '1em' }}>
              <Button
                color={COLORS.GREY}
                label={this.props.viewOnly ? 'Voltar' : 'Cancelar'}
                onClick={this.onCancel}
                withGrid={6}
              />
              {!this.props.viewOnly && (
                <Button
                  label='Salvar'
                  onClick={this.onSubmit}
                  withGrid={6}
                  containerStyle={{ textAlign: 'right' }}
                  disabled={
                    this.props.user.roles[0].name === 'Cliente (somente visualização)'
                  }
                />
              )}
            </Grid>
          </Container>
          <Container disableGutters>
            <Prompt
              visible={this.state.showDialog}
              title='Erro'
              message={this.state.dialogMessage}
              buttons={[
                {
                  label: 'OK',
                  onClick: () => this.setState({ showDialog: false })
                }
              ]}
            />
          </Container>
        </div>
      </Container>
    )
  }
}

async function isAdmin() {
  const loggedUser = await getLoggedUser()
  return _.find(loggedUser.roles, el => el === 'Administrador')
}

const propsFromDatabase = async props => {
  const tiposManejo = _.sortBy(
    await utils.getDomainValuesBy('Tipo de Manejo'),
    [manejo => manejo.valor.toLowerCase()]
  )
  const hormonios = await utils.getChildren(tiposManejo)

  const benzoatoEstradiol = _.find(tiposManejo, {
    valor: 'Estímulo Ovulatório'
  })
  const benzoatoEstradiolHormonios = _.sortBy(
    _.filter(hormonios, {
      valorDominioPaiId: benzoatoEstradiol.id
    }),
    [
      benzoato => !benzoato.valor.toLowerCase().startsWith('zoetis'),
      benzoato => benzoato.valor.toLowerCase()
    ]
  )

  const luteolise = _.find(tiposManejo, { valor: 'Luteólise' })
  const luteoliseHormonios = _.sortBy(
    _.filter(hormonios, {
      valorDominioPaiId: luteolise.id
    }),
    [
      lut => !lut.valor.toLowerCase().startsWith('zoetis'),
      lut => lut.valor.toLowerCase()
    ]
  )

  const progesterona = _.find(tiposManejo, { valor: 'Progesterona Injetável' })
  const progesteronaHormonios = _.sortBy(
    _.filter(hormonios, {
      valorDominioPaiId: progesterona.id
    }),
    [
      prog => !prog.valor.toLowerCase().startsWith('zoetis'),
      prog => prog.valor.toLowerCase()
    ]
  )

  const allDays = await utils.getDomainValuesBy('Dias')

  const is_admin = await isAdmin()

  return {
    requiredFields: ['name', 'implantId', 'benzoatoEstradiol'],
    openFields: [
      'benzoatoEstradiolDosage',
      'luteoliseDosage',
      'progesteronaDosage'
    ],
    conditionalRequiredFields: [
      {
        field: 'luteoliseDosage',
        dependsOn: 'luteolise',
        value: NOT_NULL,
        type: CONDITIONAL_REQUIRED_FIELD_TYPE.DEPENDENT_ON_FIELD
      },
      {
        field: 'progesteronaDosage',
        dependsOn: 'progesterona',
        value: NOT_NULL,
        type: CONDITIONAL_REQUIRED_FIELD_TYPE.DEPENDENT_ON_FIELD
      }
    ],
    implants: _.sortBy(await utils.getDomainValuesBy('Dispositivo'), [
      implant => !implant.valor.toLowerCase().startsWith('zoetis'),
      implant => implant.valor.toLowerCase()
    ]),
    tiposManejo,
    hormonios,
    dosagens: await utils.getChildren(hormonios),
    benzoatoEstradiol,
    benzoatoEstradiolHormonios,
    luteolise,
    luteoliseHormonios,
    progesterona,
    progesteronaHormonios,
    days: _.sortBy(
      allDays.filter(dia => dia.valor !== 'IATF'),
      [
        dia =>
          dia.valor.startsWith('D') ? parseInt(dia.valor.substring(1)) : 100
      ]
    ),
    d0: _.find(allDays, { valor: 'D0' }),
    managements: _.sortBy(
      tiposManejo.filter(manejo => manejo.valor, ['valor'])
    ),
    iatf: _.find(tiposManejo, tipoManejo => tipoManejo.valor === 'IATF'),
    retirada: _.find(
      tiposManejo,
      tipoManejo => tipoManejo.valor === 'Retirada do Dispositivo'
    ),
    remocaoBezerros: _.find(
      tiposManejo,
      tipoManejo => tipoManejo.valor === 'Remoção de Bezerros'
    ),
    user: await utils.getElement(
      AVAILABLE_ENTITIES.USERS,
      (await getLoggedUser()).userId
    ),
    isAdmin: is_admin
  }
}

export default utils.withPropsFromDatabase(propsFromDatabase, ProtocolForm)
