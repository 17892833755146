import * as api from '../../redux/api/actions.js'

import EulaDialog, {
  COOKIES_BACKEND_IDENTIFIER,
  COOKIES_NAME,
  PRIVACY_BACKEND_IDENTIFIER,
  PRIVACY_NAME,
  USERS_LICENSE_BACKEND_IDENTIFIER,
  USERS_LICENSE_NAME
} from './eula/EulaDialog'
import { Link, Redirect, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import {
  acceptTerm,
  dismissEula,
  login,
  logout
} from '../../redux/auth/actions.js'

import Alert from '@material-ui/lab/Alert'
import Backdrop from '@material-ui/core/Backdrop'
import Box from '@material-ui/core/Box'
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import LoginBackground from '../../assets/img/login-background.jpg'
import TextField from '@material-ui/core/TextField'
import { ThemeProvider } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import ZoetisLogo from '../../assets/img/logo_corte.png'
import { connect } from 'react-redux'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#EA5D0D'
    }
  }
})

const termsMapping = {
  [COOKIES_BACKEND_IDENTIFIER]: COOKIES_NAME,
  [PRIVACY_BACKEND_IDENTIFIER]: PRIVACY_NAME,
  [USERS_LICENSE_BACKEND_IDENTIFIER]: USERS_LICENSE_NAME
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: '',
      password: '',
      tipo: 1,
      helperText: '',
      error: false,
      cookiesVersion: null,
      privacyVersion: null,
      usersLicenseVersion: null
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.onAcceptTerms = this.onAcceptTerms.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.props.logout()
  }

  handleChange = event => {
    const {
      target: { name, value }
    } = event

    this.setState(() => ({ [name]: value }))
  }

  handleBlur = e => {
    let usuarioEmailCPF = e.target.value
    let regexpNumber = new RegExp('^[0-9]{11}$')
    let regexEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')
    if (regexpNumber.test(usuarioEmailCPF)) {
      let valor = this.cpfMask(e.target.value)
      this.setState({ login: valor, helperText: '', error: false, tipo: 0 })
    } else if (regexEmail.test(usuarioEmailCPF)) {
      this.setState({
        login: usuarioEmailCPF,
        helperText: '',
        error: false,
        tipo: 1
      })
    } else {
      this.setState({
        login: usuarioEmailCPF,
        helperText: 'E-mail invalido!',
        error: true,
        tipo: 1
      })
    }
  }

  handleSubmit() {
    //Setando os valores para usuario => email || cpf
    let valor = this.state.login

    localStorage.setItem('L - ' + new Date(), JSON.stringify({ email: this.state.login }))
    // valor = valor.replace('.', '').replace('.', '').replace('-', '');
    this.setState({ login: valor })

    this.props.login(this.state)
  }

  onEnterPress = e => {
    if (e.keyCode == 13 && e.shiftKey == false && e.target.value != '') {
      e.preventDefault()
      this.handleSubmit()
    }
  }

  async onAcceptTerms() {
    this.props.dismissEula()
    const token = this.props.token
    let success = true
    for (const BACKEND_IDENTIFIER of [
      COOKIES_BACKEND_IDENTIFIER,
      PRIVACY_BACKEND_IDENTIFIER,
      USERS_LICENSE_BACKEND_IDENTIFIER
    ]) {
      const termAccepted = await this.props.acceptTerm({
        type: BACKEND_IDENTIFIER,
        token
      })
      if (termAccepted) {
        this.setState({ [termsMapping[BACKEND_IDENTIFIER]]: termAccepted })
      } else {
        success = false
      }
    }
    if (success) {
      this.handleSubmit()
    }
  }

  canSubmit() {
    return this.state.login && this.state.password
  }

  cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }

  render() {
    if (this.props.loggedIn) return <Redirect to='/' />
    if (this.props.showLoading) {
      return (
        <Backdrop open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )
    }
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid container className='page-login'>
          <Grid item lg={6} md={0} className="page-login-form" style={{ backgroundImage: `url(${LoginBackground})` }} />
          <Grid item md={12} lg={6}>
            <Container maxWidth='xs'>
              <Typography
                component='div'
                style={{ height: '100vh', marginTop: '100px' }}
              >
                <img
                  src={ZoetisLogo}
                  alt='Zoetis Gerar'
                  className="p-full-width"
                  style={{ width: '100%' }}
                />

                {this.props.error && (
                  <Alert severity='error' style={{ marginTop: '1em' }}>
                    {this.props.error.title}
                  </Alert>
                )}

                <Container disableGutters>
                  <Container disableGutters style={{ marginTop: '1em' }}>
                    <TextField
                      id='login'
                      label='E-mail'
                      name='login'
                      variant='outlined'
                      type='text'
                      error={this.state.error}
                      helperText={this.state.helperText}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      value={this.state.login.toLowerCase().replace(/ /g, "")}
                      style={{ width: '100%', backgroundColor: 'white' }}
                    />
                  </Container>

                  <Container disableGutters style={{ marginTop: '1em' }}>
                    <TextField
                      id='login-form-password'
                      label='Senha'
                      name='password'
                      variant='outlined'
                      type='password'
                      onChange={this.handleChange}
                      onKeyDown={this.onEnterPress}
                      style={{
                        width: '100%',
                        backgroundColor: 'white'
                      }}
                    />
                  </Container>

                  <Container disableGutters style={{ marginTop: '1em' }}>
                    <Button
                      variant='contained'
                      color='primary'
                      //disableElevation
                      size='large'
                      style={{
                        width: '100%'
                      }}
                      disabled={!this.canSubmit() || this.state.error}
                      onClick={this.handleSubmit}
                    >
                      Entrar
                    </Button>
                  </Container>

                  {/* <Box
                    fontWeight='fontWeightLight'
                    style={{ marginTop: '1em', textAlign: 'center' }}
                  >
                    <Link
                      to='/forgot-password'
                      style={{ color: '#333', textDecoration: 'none' }}
                    >
                      Esqueci a senha
                    </Link>
                  </Box> */}
                </Container>
              </Typography>
            </Container>
          </Grid>
        </Grid>
        <Container disableGutters>
          <EulaDialog
            visible={this.props.shouldAcceptEULA}
            onAccept={this.onAcceptTerms}
          />
        </Container>
      </ThemeProvider>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: params => dispatch(login(params)),
    logout: params => dispatch(logout()),
    acceptTerm: ({ type, token }) => dispatch(acceptTerm({ type, token })),
    dismissEula: () => dispatch(dismissEula())
  }
}

const mapStateToProps = state => ({
  error: api.getApiError(state, `Authentication/Login`) || false,
  success: api.getApiResult(state, `Authentication/Login`) || false,
  loading: api.getApiLoading(state, `Authentication/Login`),
  showLoading: state.screen.loading,
  loggedIn: state.auth.loggedIn,
  shouldAcceptEULA: state.auth.shouldAcceptEULA,
  token: state.auth.token
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
