import React, { Component } from 'react'

import Terms from './components/Terms'

export const NAME = 'END_USER_LICENSE'
export const BACKEND_IDENTIFIER = 'U'

class UsersLicense extends Component {
  render () {
    return (
      <Terms
        type={BACKEND_IDENTIFIER}
        name={NAME}
        onHeight={this.props.onHeight}
      />
    )
  }
}

export default UsersLicense
