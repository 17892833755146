import React, { Component } from 'react'
import _ from 'lodash'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

import Button, { COLORS } from './material/Button'

import './styles/mobileTable.scss'

const DEFAULTS = {
  PAGE: 0,
  ROWS_PER_PAGE: 10,
}

export const CELL_TYPES = {
  BUTTON: 'BUTTON',
  BUTTON_ARR: 'BUTTON_ARR',
  BUTTON_ICON_ARR: 'BUTTON_ICON_ARR',
  LINK: 'LINK',
  TEXT: 'TEXT',
  TEXT_CENTER: 'TEXT_CENTER',
  HOLLOW_BUTTON: 'HOLLOW_BUTTON',
  ICON_BUTTON: 'ICON_BUTTON',
  FIELD: 'FIELD',
}

class PaginatedTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: this.props.page || DEFAULTS.PAGE,
      rowsPerPage: this.props.rowsPerPage || DEFAULTS.ROWS_PER_PAGE,
    }
    this.renderCell = this.renderCell.bind(this)
    this.changePage = this.changePage.bind(this)
  }

  changePage(event, page) {
    this.setState({ page })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        page: this.props.page || DEFAULTS.PAGE,
      })
    }
  }

  handleLabelFlags(label) {
    if (label?.indexOf('{bold}') >= 0) {
      return <b>{label.replace('{bold} ', '')}</b>
    }
    if (label?.indexOf('{white}') >= 0) {
      return <b style={{ color: 'white' }}>{label.replace('{white}', '')}</b>
    }
    return label
  }

  async exportData(data) {
    const str = JSON.stringify(data)
    const bytes = new TextEncoder().encode(str)
    const blob = new Blob([bytes], {
      type: 'application/json;charset=utf-8',
    })

    const newHandle = await window.showSaveFilePicker()
    const writableStream = await newHandle.createWritable()
    await writableStream.write(blob)
    await writableStream.close()
  }

  renderCell(
    type,
    { label, onClick, buttons, icon, field, useGridSystem, disabled },
    columnName,
    index
  ) {
    switch (type) {
      case CELL_TYPES.BUTTON:
        return (
          <TableCell key={`${columnName}_${index}`} align='right'>
            <Button
              style={useGridSystem ? { width: '100%' } : undefined}
              small
              onClick={onClick}
              label={label}
              disabled={disabled}
            />
          </TableCell>
        )
      case CELL_TYPES.LINK:
        return (
          <TableCell
            key={`${columnName}_${index}`}
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              textDecorationColor: 'rgb(234, 93, 13)',
              width: this.props.columnWidth,
            }}
            onClick={onClick}
          >
            {label}
          </TableCell>
        )
      case CELL_TYPES.BUTTON_ARR:
        return (
          <TableCell key={`${columnName}_${index}`} align='right'>
            {buttons.map((button, bIndex) =>
              button.icon ? (
                <Tooltip
                  key={`${columnName}_${index}_${bIndex}`}
                  title={button.label}
                  arrow
                  placement='top'
                >
                  <IconButton
                    style={{
                      padding: '8px',
                      marginLeft: '0.2em',
                    }}
                    onClick={button.onClick}
                  >
                    {button.icon}{' '}
                  </IconButton>
                </Tooltip>
              ) : (
                <Button
                  key={`${columnName}_${index}_${bIndex}`}
                  small
                  style={{
                    marginRight: '0.3em',
                    marginLeft: '0.8em',
                    padding: '10px',
                    borderRadius: '300px',
                    height: '32px',
                  }}
                  onClick={button.onClick}
                  label={button.label}
                  disabled={button.disabled}
                />
              )
            )}
          </TableCell>
        )
      case CELL_TYPES.ICON_BUTTON:
        return (
          <TableCell key={`${columnName}_${index}`} align='right'>
            <Tooltip title={label} arrow placement='top'>
              <IconButton key={`${columnName}_${index}`} onClick={onClick}>
                {icon}
              </IconButton>
            </Tooltip>
          </TableCell>
        )
      case CELL_TYPES.HOLLOW_BUTTON:
        return (
          <TableCell key={`${columnName}_${index}`} align='right'>
            {<IconButton onClick={onClick}>{icon}</IconButton>}
          </TableCell>
        )
      case CELL_TYPES.FIELD:
        return <TableCell key={`${columnName}_${index}`}>{field}</TableCell>
      case CELL_TYPES.TEXT_CENTER:
        return (
          <TableCell key={`${columnName}_${index}`} align='center'>
            {this.handleLabelFlags(label)}
          </TableCell>
        )
      default:
        return (
          <TableCell
            style={{ width: this.props.columnWidth }}
            key={`${columnName}_${index}`}
          >
            {this.handleLabelFlags(label)}
          </TableCell>
        )
    }
  }

  render() {
    let useGridSystem = false
    let proportions = {}

    _.each(this.props.columns, (column, index) => {
      if (column.grid) {
        useGridSystem = true
        proportions[index] =
          column.grid &&
          column.grid > 0 &&
          `${Math.floor((100 * column.grid) / 12)}%`
      }
    })

    const data = !this.props.skipPagination
      ? this.props.data.slice(
        this.state.page * this.state.rowsPerPage,
        (this.state.page + 1) * this.state.rowsPerPage
      )
      : this.props.data

    const style = {
      borderRadius: 6,
      ...this.props.style,
    }

    return (
      <TableContainer component={Paper} style={style}>
        <Table
          aria-label='custom pagination table'
          size={this.props.size}
          style={{ padding: '2em' }}
        >
          <TableHead style={{ backgroundColor: '#e3e3e3' }}>
            <TableRow style={{ fontWeight: 'bold' }}>
              {this.props.columns.map((column, index) => {
                switch (column.type) {
                  case CELL_TYPES.TEXT_CENTER:
                    return column.name ? (
                      <TableCell
                        width={useGridSystem ? proportions[index] : undefined}
                        key={`${column.name}_${index}`}
                        align='center'
                      >
                        {column.name}
                      </TableCell>
                    ) : (
                      <TableCell
                        width={useGridSystem ? proportions[index] : undefined}
                        key={`${column.name}_${index}`}
                        align='center'
                      />
                    )
                  default:
                    return column.name ? (
                      <TableCell
                        width={useGridSystem ? proportions[index] : undefined}
                        key={`${column.name}_${index}`}
                        style={{ width: this.props.columnWidth }}
                      >
                        {column.name}
                      </TableCell>
                    ) : (
                      <TableCell
                        width={useGridSystem ? proportions[index] : undefined}
                        key={`${column.name}_${index}`}
                      />
                    )
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.data.length > 0 ? (
              data.map((row, i) => {
                return (
                  <TableRow key={i}>
                    {this.props.columns.map((column) =>
                      this.renderCell(
                        column.type,
                        {
                          label: column.label ? column.label(row, i) : '',
                          onClick: column.onClick && column.onClick(row, i),
                          buttons: column.buttons
                            ? column.buttons(row, i)
                            : [],
                          icon: column.icon && column.icon(row, i),
                          field: column.field && column.field(row, i),
                          disabled:
                            column.disabled && column.disabled(row, i),
                          useGridSystem,
                        },
                        column.name,
                        i
                      )
                    )}
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={this.props.columns.length} align='center'>
                  <Typography variant='h6'>
                    {this.props.noDataMessage}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          {!this.props.skipPagination && (
            <TableFooter>
              <TableRow>
                <TableCell align='left'>
                  <Button
                    color={COLORS.WHITE}
                    startIcon={<ArrowDownwardIcon />}
                    onClick={() => this.exportData(this.props.toDataExport)}
                    withGrid={12}
                    style={{
                      minWidth: '5px',
                      padding: '2px 0px 2px 12px',
                      width: '30px',
                      display: 'none',
                    }}
                  />
                </TableCell>
                <TablePagination
                  colSpan={this.props.columns.length}
                  count={this.props.data.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.changePage}
                  rowsPerPageOptions={[this.state.rowsPerPage]}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : to
                    }`
                  }
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    )
  }
}

export default PaginatedTable
