import './styles/syncview.scss'

import React, { Component, Fragment } from 'react'

import ArrowIcon from './../assets/icons/arrow-up.svg'
import ArrowIconWaiting from './../assets/icons/arrow-up-waiting.svg'
import ArrowIconError from './../assets/icons/arrow-up-error.svg'
import CheckIcon from './../assets/icons/check.svg'
import BoxIcon from './../assets/icons/box-data.svg'
import BoxIconWaiting from './../assets/icons/box-data-waiting.svg'
import BoxIconError from './../assets/icons/box-data-error.svg'
import { Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import hasInternet from './../utils/recognizeInternetConnection'
import SyncProblemIcon from '@material-ui/icons/Error'
import { changeSyncView } from '../redux/sync/actions'

class SyncView extends Component {
  constructor(props) {
    super(props)
  }

  errorSync = () => (
    <button
      style={{ background: 'none', border: 'none' }}
    >
      <Tooltip title='Dados não enviados' arrow>
        <div style={{ display: 'grid' }} className='syncViewError'>
          <div style={{ position: 'absolute', top: '6px', left: '10px' }}>
            <img
              style={{ position: 'absolute' }}
              src={ArrowIconError}
              width='29'
            />
            {/* <SyncProblemIcon
              style={{ fontSize: '27px', position: 'absolutee', top: '5px', left: '43px' }}
            ></SyncProblemIcon> */}
            <SyncProblemIcon
              style={{ fontSize: '30px', color: 'rgba(255, 34, 5, 0.877)', position: 'absolute', top: '1px', left: '40px' }}
            ></SyncProblemIcon>
            <img
              style={{ position: 'absolute' }}
              src={BoxIconError}
              width='29'
            />
          </div>
        </div>
      </Tooltip>
    </button>
  )

  loadingSync = () => (
    <Tooltip title='Enviando dados...' arrow>
      <div style={{ display: 'grid' }} className='syncView'>
        <div style={{ position: 'absolute', top: '6px', left: '7.5px' }}>
          <img
            style={{ fill: 'green', position: 'absolute' }}
            className='syncViewPushIcon'
            src={ArrowIconWaiting}
            width='29'
          />
          <img
            style={{ position: 'absolute' }}
            src={BoxIconWaiting}
            width='29'
          />
        </div>
      </div>
    </Tooltip>
  )

  successSync = () => (
    <Tooltip title='Dados enviados' arrow>
      <div style={{ display: 'grid' }} className='syncViewSuccess'>
        <div style={{ position: 'absolute', top: '6px', left: '10px' }}>
          <img
            style={{ position: 'absolute' }}
            src={ArrowIcon}
            width='29'
          />
          <img
            style={{ position: 'absolute', top: '5px', left: '43px' }}
            src={CheckIcon}
            width='27'
          />
          <img
            style={{ position: 'absolute' }}
            src={BoxIcon}
            width='29'
          />
        </div>
      </div>
    </Tooltip>
  )

  getCurrentSyncStatus() {
    if (this.props.currentSync == 0) {
      return this.errorSync()
    }
    if (this.props.currentSync == 1) {
      return this.loadingSync()
    }
    if (this.props.currentSync == 2 && hasInternet()) {
      return this.successSync()
    }
    if (!hasInternet()) {
      return this.errorSync()
    }
  }

  render() {
    return <Fragment> {this.getCurrentSyncStatus()} </Fragment>
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeSyncView: payload => dispatch(changeSyncView(payload))
  }
}

const mapStateToProps = state => ({
  currentSync: state.sync.currentSync
})

export default connect(mapStateToProps, mapDispatchToProps)(SyncView)
