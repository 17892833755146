import '../../components/styles/customstyle.scss'

import { AVAILABLE_ENTITIES, utils } from './../../database'
import { FAZENDA, LOTE, RETIRO, formatName } from './../../utils/formHelper'
import React, { Component } from 'react'
import { endLoading, startLoading } from './../../redux/screen/actions'
import {
  getBatchReport,
  getCorralReport,
  getFarmReport
} from './../../redux/reports/actions'

import { CELL_TYPES } from './../../components/PaginatedTable'
import Chart from '../../components/Chart'
import { Container } from '@material-ui/core'
import Cover from './components/Cover'
import MainContainer from '../../components/MainContainer'
import ResponsiveTable from './../../components/ResponsiveTable'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getLoggedUser } from './../../redux/auth/actions'
import uuid from 'react-uuid'
import { withRouter } from 'react-router-dom'

const MIN_CHART_WIDTH = 1000;
const PREVISAO_PARTOS = 'Previsão de Partos'

const propsCandidate = {
  grafico: {
    data: [
      {
        xAxis: 'A',
        series: {
          0: { yAxis: 4000, yAxis2: 5 },
          1: { yAxis: 2400, yAxis2: 12 },
          2: { yAxis: 3600, yAxis2: 8 },
          3: { yAxis: 1200, yAxis2: 0 },
          4: { yAxis: 2000, yAxis2: 10 }
        }
      },
      {
        xAxis: 'B',
        series: {
          0: { yAxis: 3000, yAxis2: 5 },
          1: { yAxis: 2400, yAxis2: 12 },
          2: { yAxis: 3600, yAxis2: 8 },
          3: { yAxis: 1200, yAxis2: 0 },
          4: { yAxis: 2000, yAxis2: 10 }
        }
      },
      {
        xAxis: 'C',
        series: {
          0: { yAxis: 2000, yAxis2: 5 },
          1: { yAxis: 2400, yAxis2: 12 },
          2: { yAxis: 3600, yAxis2: 8 },
          3: { yAxis: 1200, yAxis2: 0 },
          4: { yAxis: 2000, yAxis2: 10 }
        }
      },
      {
        xAxis: 'D',
        series: {
          0: { yAxis: 1000, yAxis2: 5 },
          1: { yAxis: 2400, yAxis2: 12 },
          2: { yAxis: 3600, yAxis2: 8 },
          3: { yAxis: 1200, yAxis2: 0 },
          4: { yAxis: 2000, yAxis2: 10 }
        }
      },
      {
        xAxis: 'E',
        series: {
          0: { yAxis: 2500, yAxis2: 5 },
          1: { yAxis: 2400, yAxis2: 12 },
          2: { yAxis: 3600, yAxis2: 8 },
          3: { yAxis: 1200, yAxis2: 0 },
          4: { yAxis: 2000, yAxis2: 10 }
        }
      }
    ],
    configuration: {
      title: 'Taxa de Waddafuh',
      seriesCount: 5,
      xAxisLabel: 'Lote',
      yAxisLabel: 'Taxa de Prenhez',
      yAxis2Label: 'Numero',
      tooltipFormatter: 'PERCENTAGE',
      tooltipFormatter2: 'ABSOLUTE',
      type: 'DOUBLE_CHART'
    }
  },
  title: 'Le Grafico',
  tabela: {
    columns: [
      'Numero da IATF',
      'ECC Inicial',
      'Total de Matrizes',
      'Total com DG',
      'Total de Prenhes',
      'Taxa de Prenhez à IATF',
      'Distribuição'
    ],
    data: _.times(20, index => [
      index * 7,
      index * 7 + 1,
      index * 7 + 2,
      index * 7 + 3,
      index * 7 + 4,
      index * 7 + 5,
      index * 7 + 6
    ])
  }
}

const payload = _.times(3, index => propsCandidate)

class Report extends Component {
  constructor(props) {
    super(props)

    this.state = {
      width: window.innerWidth > 1000 ? window.innerWidth : MIN_CHART_WIDTH,
      height: window.innerHeight
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)

    props.endLoading()
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    console.log(this.mainContainer)
    this.setState({
      width: window.innerWidth > 1000 ? window.innerWidth : MIN_CHART_WIDTH,
      height: window.innerHeight
    })
  }

  getTableColumnsData(content) {
    return content.tabela.columns.map((column, index) => ({
      name: column,
      type: (column == 'Lote' || column == 'Touro' || column == 'Inseminador') ? CELL_TYPES.TEXT : CELL_TYPES.TEXT_CENTER,
      label: row => row[index]
    }))
  }

  viewData(data) {
    console.log(data)
  }

  render() {
    const farm = this.props.farm
      ? formatName(this.props.farm.nome, FAZENDA)
      : ''
    const corral =
      this.props.farm &&
      this.props.farm.retiro &&
      this.props.corral &&
      formatName(this.props.corral.nome, RETIRO)
    const batch =
      this.props.batch && formatName(this.props.batch.nomeLote, LOTE)

    const title = batch || corral || farm

    let paths = [
      {
        label: farm,
        route: `/farms/${this.props.farm.id}`
      }
    ]

    if (this.props.farm.retiro && corral) {
      paths.push({
        label: corral,
        route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
      })
    }

    if (batch) {
      paths.push({
        label: batch,
        route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}/batches/${this.props.batch.id}`
      })
    }

    paths.push({
      label: 'Relatórios'
    })

    return (
      <Container>
        <TopBar className='hidewhenprinting' title={title} />
        <TitleBar
          className='hidewhenprinting'
          title={`Relatórios`}
          buttons={[
            {
              label: 'Imprimir',
              onClick: () => window.print()
            }
          ]}
          paths={paths}
        />

        <MainContainer>
          <Cover
            user={
              this.props.user ? this.props.user.nomeCompleto : 'Nome de Usuário'
            }
            farm={farm}
            corral={corral}
            batch={batch}
          />
          {this.props.data.map((content, index) => (
            <React.Fragment key={uuid()}>
              <div key={uuid()} className='marginTop' />
              {content.title && (
                <h1 style={{ margin: '0 40px 40px 40px' }}>{content.title}</h1>
              )}
              {content.grafico && (
                <Chart
                  key={uuid()}
                  width={this.state.width}
                  widthFactor={0.85}
                  data={content.grafico.data}
                  configuration={content.grafico.configuration}
                  skipIATFLabel={content.title === PREVISAO_PARTOS}
                />
              )}
              {content.tabela && (
                <div className='printableTableContainer'>
                  <ResponsiveTable
                    columns={this.getTableColumnsData(content)}
                    data={content.tabela.data}
                    skipPagination
                    size='small'
                    style={{ marginBottom: '80px' }}
                  />
                </div>
              )}
              {index !== this.props.data.length - 1 && (
                <div key={uuid()} className='pagebreak' />
              )}
            </React.Fragment>
          ))}
        </MainContainer>
      </Container>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading())
})

const propsFromDatabase = async props => {
  props.startLoading()
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    (await getLoggedUser()).userId
  )

  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    props.match.params.farmId
  )
  const corral =
    props.match.params.corralId &&
    (await utils.getElement(
      AVAILABLE_ENTITIES.CORRALS,
      props.match.params.corralId
    ))
  const batch =
    props.match.params.batchId &&
    (await utils.getElement(
      AVAILABLE_ENTITIES.BATCHES,
      props.match.params.batchId
    ))
  let data = []
  if (batch) {
    data = await getBatchReport(batch.id)
  } else if (corral) {
    data = await getCorralReport(corral.id)
  } else {
    data = await getFarmReport(farm.id)
  }

  return {
    user,
    farm,
    corral,
    batch,
    data,
    error: data instanceof Error
  }
}

export default connect(
  null,
  mapDispatchToProps
)(utils.withPropsFromDatabase(propsFromDatabase, withRouter(Report)))
