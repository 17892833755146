import 'typeface-roboto'

import React, { useEffect } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  withRouter,
} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import { TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { connect } from 'react-redux'
import clsx from 'clsx'

import { AVAILABLE_ENTITIES, utils } from './database'
import { getLoggedUser, login } from './redux/auth/actions.js'
import * as api from './redux/api/actions.js'
import Prompt from './components/Prompt.js'
import Batch from './pages/batches/Batch.js'
import BatchCreate from './pages/batches/Create.js'
import BatchMerge from './pages/batches/Merge.js'
import BatchUpdate from './pages/batches/Update.js'
import Bull from './pages/bulls/Bull.js'
import BullCreate from './pages/bulls/Create.js'
import BullCreateForAll from './pages/bulls/CreateForAll.js'
import BullList from './pages/bulls/List.js'
import BullUpdate from './pages/bulls/Update.js'
import Corral from './pages/corrals/Corral'
import CorralCreate from './pages/corrals/Create.js'
import CorralUpdate from './pages/corrals/Update.js'
import D0 from './pages/IATF/D0'
import D0AfterResync from './pages/IATF/D0AfterResync'
import D0IATF from './pages/IATF/D0IATF'
import D0Resync from './pages/IATF/D0Resync'
import DGFinal from './pages/IATF/DGFinal'
import DN from './pages/IATF/DN'
import EstacaodeMonta from './pages/EstacaoDeMontaOtimizada'
import Farm from './pages/farms/Farm.js'
import FarmCreate from './pages/farms/Create.js'
import FarmUpdate from './pages/farms/Update'
import Home from './pages/Home'
import IATF from './pages/IATF/IATF'
import DG from './pages/IATF/DG'
import NewReport from './pages/new-reports/NewReport'
import Partidas from './pages/partidas/Partida.js'
import Protocol from './pages/protocols/Protocol'
import ProtocolCreate from './pages/protocols/Create'
import ProtocolUpdate from './pages/protocols/Update'
import ProtocolsList from './pages/protocols/List'
import Report from './pages/reports/Report'
import Resync from './pages/IATF/Resync'
import UserCreate from './pages/Users/Create.js'
import UserList from './pages/Users/List.js'
import UserUpdate from './pages/Users/Update.js'
import LoadingOverlay from './components/LoadingOverlay'
import SyncView from './components/SyncView'
import BackgrounImage from './assets/img/background.jpg'
import Trainings from './pages/Trainings/Training.js'
import AdminReleasesList from './pages/AdminReleasesList/AdminReleasesList.js'
import AdminReceiptReleases from './pages/AdminReceiptReleases/AdminReceiptReleases.js'
import AdminAddValueDomain from './pages/AdminAddValueDomain/AdminAddValueDomain.js'
import AdminAddTypeDomain from './pages/AdminAddTypeDomain/AdminAddTypeDomain.js'
import ImportData from './pages/ImportData/ImportData'
import DGRessinc from './pages/IATF/DGRessinc'
import { verify } from './utils/verifyDuplicatePages'

const drawerWidth = 240

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderColor: '#EA5D0D',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  right: {
    position: 'absolute',
    height: '100%',
    right: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    border: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    borderColor: '#EA5D0D',
    backgroundColor: '#EA5D0D',
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  selectFarm: {
    marginRight: '2em',
    lineHeight: '50%',
    // marginTop: -20,
  },
}))

function App({ logged, title, ...props }) {
  const classes = useStyles()
  const theme = useTheme()
  const [open] = React.useState(true)
  const { location } = useHistory()
  const [newPassword, setNewPassword] = React.useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div
      className='app-wrapper-bg'
      style={{ backgroundImage: 'url(' + BackgrounImage + ')' }}
    >
      <LoadingOverlay />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container className={classes.root} disableGutters>
          <CssBaseline />

          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <Switch>
              {/* Home / Farm List */}
              <Route exact path='/' component={Home} />
              {/* Estação de monta otimizada */}
              <Route
                exact
                path='/farms/estacaodemonta'
                component={EstacaodeMonta}
              />
              {/* Farms */}
              <Route exact path='/farms/create' component={FarmCreate} />
              <Route exact path='/farms/:id' component={Farm} />
              <Route exact path='/farms/:id/update' component={FarmUpdate} />
              <Route exact path='/farms/:farmId/report' component={NewReport} />
              <Route
                exact
                path='/farms/:farmId/new-report'
                component={Report}
              />
              {/* Users */}
              <Route exact path='/users' component={UserList} />
              <Route exact path='/users/create' component={UserCreate} />
              <Route exact path='/users/:id' component={UserUpdate} />
              {/* Protocols */}
              <Route exact path='/protocols' component={ProtocolsList} />
              <Route
                exact
                path='/protocols/create'
                component={ProtocolCreate}
              />
              <Route exact path='/protocols/:id' component={Protocol} />
              <Route
                exact
                path='/protocols/:id/update'
                component={ProtocolUpdate}
              />
              {/* Bulls */}
              <Route exact path='/farms/:id/bulls' component={BullList} />
              <Route
                exact
                path='/farms/:id/bulls/create'
                component={BullCreate}
              />
              <Route exact path='/farms/:id/bulls/:bullId' component={Bull} />
              <Route
                exact
                path='/farms/:id/bulls/:bullId/update'
                component={BullUpdate}
              />
              <Route
                exact
                path='/bulls/create-for-all/batch/:batchId/management/:management'
                component={BullCreateForAll}
              />
              {/* Corrals */}
              <Route
                exact
                path='/farms/:farmId/corrals/create'
                component={CorralCreate}
              />
              <Route
                exact
                path='/farms/:farmId/corrals/:id'
                component={Corral}
              />
              <Route
                exact
                path='/farms/:farmId/corrals/:id/update'
                component={CorralUpdate}
              />
              <Route
                exact
                path='/farms/:farmId/corrals/:corralId/report'
                component={NewReport}
              />
              <Route
                exact
                path='/farms/:farmId/corrals/:corralId/new-report'
                component={Report}
              />
              {/* Batches */}
              <Route
                exact
                path='/farms/:farmId/corrals/:corralId/mergeBatches'
                component={BatchMerge}
              />
              <Route
                exact
                path='/farms/:farmId/corrals/:corralId/batches/:id/update'
                component={BatchUpdate}
              />
              <Route
                exact
                path='/farms/:farmId/corrals/:corralId/batches/create'
                component={BatchCreate}
              />
              <Route
                exact
                path='/farms/:farmId/corrals/:corralId/batches/:id'
                component={Batch}
              />
              <Route
                exact
                path='/farms/:farmId/corrals/:corralId/batches/:batchId/report'
                component={NewReport}
              />
              <Route
                exact
                path='/farms/:farmId/corrals/:corralId/batches/:batchId/new-report'
                component={Report}
              />
              {/* Cadastro de partidas */}
              <Route
                exact
                path='/partidas/:bullId/batch/:batchId/management/:management'
                component={Partidas}
              />
              {/* Importar dados */}
              <Route exact path='/importar-dados' component={ImportData} />
              {/* Treinamentos */}
              <Route exact path='/treinamentos' component={Trainings} />
              {/* Administrador lancamentos*/}
              <Route
                exact
                path='/lancamentos-do-administrador'
                component={AdminReleasesList}
              />
              {/* Adicionar Valor Dominio */}
              <Route
                exact
                path='/lancamentos-de-valores-dominio'
                component={AdminAddValueDomain}
              />
              {/* Adicionar Tipo Dominio */}
              <Route
                exact
                path='/lancamentos-de-tipos-dominio'
                component={AdminAddTypeDomain}
              />
              {/* Recuperar dados do comprovante */}
              <Route
                exact
                path='/lancamentos-de-comprovantes'
                component={AdminReceiptReleases}
              />
              {/* Processes */}
              <Route exact path='/D0IATF/:id' component={D0IATF} />
              <Route
                exact
                path='/D0IATF/:id/redirected/:redirected'
                component={D0IATF}
              />
              <Route exact path='/D0/:id' component={D0} />
              <Route exact path='/D0Resync/:id' component={D0Resync} />
              <Route
                exact
                path='/D0AfterResync/:id'
                component={D0AfterResync}
              />
              <Route exact path='/DN/:id' component={DN} />
              <Route exact path='/DG/:id/' component={DG} />
              <Route exact path='/DGRessinc/:id/' component={DGRessinc} />
              <Route exact path='/DGFinal/:id' component={DGFinal} />
              <Route exact path='/IATF/:id' component={IATF} />
              <Route
                exact
                path='/IATF/:id/redirected/:redirected'
                component={IATF}
              />
              <Route exact path='/Resync/:id' component={Resync} />
              <Redirect to='/' />
            </Switch>
            <Container style={{ marginTop: '4rem', marginBottom: '4rem' }}>
              {' '}
            </Container>
          </main>
        </Container>
        <Box displayPrint='none'>
          <SyncView />
        </Box>
        <Prompt
          visible={!props?.loggedIn && props?.loginError === 'Senha incorreta!'}
          title='Detectamos uma mudança na senha associada à sua conta em outro dispositivo. Para continuar desfrutando dos nossos serviços, solicitamos que insira a sua senha atual no campo abaixo.'
          message={
            <div>
              {props?.error && (
                <Alert severity='error' style={{ marginTop: '1em' }}>
                  {props?.error.title}
                </Alert>
              )}
              <div className='p-12 d-4 p-margin-top-2 grid-item'>
                <TextField
                  id='new-password'
                  label='Senha'
                  name='password'
                  variant='outlined'
                  type='password'
                  onChange={(e) => setNewPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (
                      e.key === 'Enter' &&
                      e.shiftKey === false &&
                      e.target.value !== ''
                    ) {
                      e.preventDefault()

                      props.login({
                        login: props?.user.email,
                        password: newPassword,
                        isNewPassword: true,
                      })
                    }
                  }}
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                  }}
                />
              </div>
            </div>
          }
          buttons={[
            {
              label: 'Salvar',
              onClick: () =>
                props?.login({
                  login: props?.user.email,
                  password: newPassword,
                  isNewPassword: true,
                }),
            },
          ]}
        />

        <Prompt
          visible={verify()}
          title='Página duplicada!'
          message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
          buttons={[
            {
              autoFocus: false,
            },
          ]}
        />
      </ThemeProvider>
    </div>
  )
}

const propsFromDatabase = async (props) => {
  const loggedUser = await getLoggedUser()
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    loggedUser.userId
  )

  return {
    user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (params) => dispatch(login(params, true)),
  }
}

const mapStateToProps = (state) => {
  return {
    error: api.getApiError(state, `Authentication/RefreshToken`) || false,
    success: api.getApiResult(state, `Authentication/RefreshToken`) || false,
    loggedIn: state.auth.loggedIn,
    loginError: state.auth.loginError,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.withPropsFromDatabase(propsFromDatabase, withRouter(App)))
