import * as CONSTANTS from './constants'

import { TimeoutError, getParsedActionEndpoint, timeoutFetch } from './utils.js'
import {
  USER_LOGOUT,
  clearLoggedInfo,
  getLoggedUser,
  refreshToken
} from '../auth/actions.js'

import _ from 'lodash'
import asyncify from 'async/asyncify'
import camelize from 'camelcase-keys'
import retry from 'async/retry'
import { saveAs } from 'file-saver'

const { LOADING, RESULT, ERROR, LAST_SUCCESSFUL_RESULT } = CONSTANTS.STATE_TYPES
const DEFAULT_RETRIES = 1

const BASE_ENDPOINT = process.env.REACT_APP_BASE_URL_GATEWAY

export const api = (service, route) =>
  `${BASE_ENDPOINT}/${CONSTANTS.SERVICES_GROUPS_MAP[service]}/api/${route}`

export class AuthenticationError extends Error { }
export class ServerError extends Error { }

export const SERVER_ERROR = error => ({
  type: CONSTANTS.SERVER_ERROR,
  error
})

export const NETWORK_ERROR = error => ({
  type: CONSTANTS.NETWORK_ERROR,
  error
})

export const HIDE_ERROR = id => ({
  type: CONSTANTS.HIDE_ERROR,
  id
})

export const HIDE_ALL_ERRORS = () => ({
  type: CONSTANTS.HIDE_ALL_ERRORS
})

export const setAppError = errorMessage => {
  const error = {}
  error.message = errorMessage
  return {
    type: CONSTANTS.APP_ERROR,
    error
  }
}

const parseJSONResponse = async response => {
  let data = {}
  const text = await response.text()
  if (!_.isEmpty(text)) {
    try {
      data = JSON.parse(text)
    } catch (e) {
      console.log(e)
    }
  }

  return camelize(data)
}

const performRequest = async (
  dispatch,
  method,
  service,
  url,
  body,
  shouldRefreshToken = true
) => {
  dispatch({
    type: CONSTANTS.API_CLEAR,
    url
  })

  dispatch({
    type: CONSTANTS.API_LOADING,
    url
  })

  const authentication = await getLoggedUser()
  let params = {
    method,
    headers: {
      Authorization: `Bearer ${authentication.token}`,
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    cache: 'no-store'
  }

  console.log(params)

  if (!_.isEmpty(body)) {
    params.body = JSON.stringify(body)
  }

  return timeoutFetch(api(service, url), params)
    .catch(error => {
      if (error instanceof TimeoutError) {
        dispatch(NETWORK_ERROR(error))
      } else {
        dispatch(SERVER_ERROR(error))
      }

      dispatch({
        type: CONSTANTS.API_ERROR,
        url,
        error
      })
    })
    .then(async response => {
      if (!response) return null

      if (response.status === 401 && shouldRefreshToken) {
        const refreshed = await refreshToken()
        if (refreshed.refreshed) {
          return performRequest(dispatch, method, service, url, body, false)
        }
      }

      if (response.status >= 401) {
        await clearLoggedInfo()
        dispatch(USER_LOGOUT('Your session token has expired'))
        Promise.reject('Your session token has expired')
      }

      let result = await parseJSONResponse(response)

      if (_.isArray(result.responseList)) {
        result = result.responseList
      } else {
        result = {
          ...(!_.isEmpty(result.response) ? result.response : {}),
          token: result.token,
          errors: camelize(result.errors || {}),
          status: result.status,
          title: result.title || result.mensagem,
          hasErrors:
            !_.isEmpty(result.errors) ||
            (_.has(result, 'sucesso') && !result.sucesso)
        }
      }

      if (result.hasErrors) {
        dispatch({
          type: CONSTANTS.API_ERROR,
          url,
          result
        })
      } else {
        dispatch({
          type: CONSTANTS.API_SUCCESS,
          url,
          result
        })
      }

      return result
    })
}

export async function post(dispatch, service, url, body) {
  return performRequest(dispatch, 'post', service, url, body)
}

export async function del(dispatch, service, url, body) {
  return performRequest(dispatch, 'delete', service, url, body)
}

export async function put(dispatch, service, url, body) {
  return performRequest(dispatch, 'put', service, url, body)
}

export async function get(dispatch, service, url) {
  return performRequest(dispatch, 'get', service, url)
}

export function getApiState(state, endpoint, type) {
  return state.api[`${getParsedActionEndpoint(endpoint)}.${type}`]
}

export function getApiLoading(state, endpoint) {
  return getApiState(state, endpoint, LOADING)
}

export function getApiError(state, endpoint) {
  return getApiState(state, endpoint, ERROR)
}

export function getApiResult(state, endpoint) {
  return getApiState(state, endpoint, RESULT)
}

export function getApiLastSuccessfulResult(state, endpoint) {
  return getApiState(state, endpoint, LAST_SUCCESSFUL_RESULT)
}
