import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

// import App from './App.js'
import CookiesPolicy from './pages/auth/eula/CookiesPolicy'
import ForgotPassword from './pages/auth/ForgotPassword.js'
import Login from './pages/auth/Login.js'
import PrivacyPolicy from './pages/auth/eula/PrivacyPolicy'
import PrivateRoute from './PrivateRoute.js'
import React from 'react'
import ResetPassword from './pages/auth/ResetPassword.js'
import Export from './pages/export/Export.js'
import UsersLicense from './pages/auth/eula/UsersLicense'
import Backup from './pages/backup/Backup.js'
import Refresh from './pages/refresh/Refresh.js'
import LoginGigya from './pages/auth/LoginGigya.js'
// import RegisterGigya from './pages/auth/RegisterGigya.js'
import { TrackedApp } from './utils/tracking'

export const router = (
  <Router onUpdate={() => window.scrollTo(0, 0)}>
    <Switch>
      <Route path='/cookies' component={CookiesPolicy} />
      <Route path='/userslicense' component={UsersLicense} />
      <Route path='/privacy' component={PrivacyPolicy} />
      <Route path='/forgot-password' component={ForgotPassword} />
      <Route path='/reset-password' component={ResetPassword} />
      <Route path='/export' component={Export} />
      <Route path='/backup-and-exit' component={Backup} />
      <Route path='/hard-refresh' component={Refresh} />
      {/* <Route exact path='/register-gigya' component={RegisterGigya} /> */}
      <Route exact path='/loginAntigo' component={Login} />
      <Route exact path='/login' component={LoginGigya} />
      <PrivateRoute route={window.location.pathname} component={TrackedApp} />
    </Switch>
  </Router>
)
