import Autocomplete, {
  autocompleteDefaultOnChangeSavingID,
  autocompleteDefaultOnChangeSavingIDWhenOpenField,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField
} from './../../../components/material/Autocomplete'
import Button, { COLORS } from './../../../components/material/Button'
import {
  REQUIRED_FIELD_MESSAGES,
  isDateNullOrValid,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator
} from './../../../utils/formHelper'
import DateField, {
  datefieldDefaultOnChange
} from './../../../components/material/DateField'
import React, { Component } from 'react'
import TextField, {
  textfieldDefaultOnChange,
  textfieldOnChangeWithCustomMessage
} from './../../../components/material/TextField'

import _ from 'lodash'
import { utils } from '../../../database'

class BullForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props.initialValues,
      central_value: props.initialValues && props.initialValues.centralValue
    }

    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.autocompleteDefaultOnChangeSavingID = autocompleteDefaultOnChangeSavingID.bind(
      this
    )
    this.autocompleteDomainValueOptionLabelSavingID = autocompleteDomainValueOptionLabelSavingID.bind(
      this
    )
    this.datefieldDefaultOnChange = datefieldDefaultOnChange.bind(this)
    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.textfieldOnChangeWithCustomMessage = textfieldOnChangeWithCustomMessage.bind(
      this
    )
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)

    this.autocompleteDefaultOnChangeSavingIDWhenOpenField = autocompleteDefaultOnChangeSavingIDWhenOpenField.bind(
      this
    )
    this.autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField = autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField.bind(
      this
    )
    this.isDateNullOrValid = isDateNullOrValid.bind(this)


  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  onEnterPress = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      this.onSubmit()
    }
  }

  onSubmit() {
    let payload = {
      ...this.props.bull,
      ..._.pick(this.state, [
        'nome',
        'raca',
        'central',
        'partida',
        'observacao',
        'Cod_Touro'
      ]),
      fazendaId: this.props.farm.id,
      centralValue: this.state.central_value
    }

    let requiredFieldsState = this.requiredFieldsEvaluator()
    let validDates = this.isDateNullOrValid(this.state.partida)

    if (requiredFieldsState !== null || !validDates) {
      this.setState(requiredFieldsState)
    } else {
      if (this.props.onSubmit) {
        this.props.onSubmit(payload)
      }
    }
  }

  render() {
    return (
      <div className="grid bull-grid-form">
        <div className="grid-item p-12 t-6">
          <TextField
            id='nome'
            label='Touro'
            onChange={this.textfieldDefaultOnChange}
            onKeyDown={this.onEnterPress}
            value={this.state.nome}
            error={!_.isEmpty(this.state.nome_error)}
            helperText={this.state.nome_error}
            disabled={this.props.viewOnly}
            autoFocus
          />
        </div>

        <div className="grid-item p-12 t-6">
          <Autocomplete
            id='raca'
            options={this.props.racas}
            value={this.state.raca}
            onChange={this.autocompleteDefaultOnChangeSavingID('raca')}
            onKeyDown={this.state.raca && this.onEnterPress}
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.racas
            )}
            useDefaultOptionSelected
            label='Raça'
            error={!_.isEmpty(this.state.raca_error)}
            helperText={this.state.raca_error}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12 t-6">
          <Autocomplete
            id='central'
            options={this.props.centrais}
            value={this.state.central || this.state.central_value || ''}
            onChange={this.autocompleteDefaultOnChangeSavingIDWhenOpenField(
              'central'
            )}
            onKeyDown={this.state.central && this.onEnterPress}
            getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField(
              this.props.centrais
            )}
            useDefaultOptionSelected
            label='Central'
            error={!_.isEmpty(this.state.central_error)}
            helperText={this.state.central_error}
            disabled={this.props.viewOnly}
            openField
          />
        </div>

        <div className="grid-item p-12 t-6">
          <DateField
            id='partida'
            label='Partida'
            value={this.state.partida || null}
            onChange={this.datefieldDefaultOnChange(
              'partida',
              REQUIRED_FIELD_MESSAGES.ONE_OF_MANY
            )}
            onKeyDown={this.onEnterPress}
            error={
              !_.isEmpty(this.state.partida_error) ||
              !this.isDateNullOrValid(this.state.partida)
            }
            helperText={this.state.partida_error}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12">
          <TextField
            id='observacao'
            label='Observação'
            onChange={this.textfieldOnChangeWithCustomMessage(
              REQUIRED_FIELD_MESSAGES.ONE_OF_MANY
            )}
            onKeyDown={this.onEnterPress}
            value={this.state.observacao}
            error={!_.isEmpty(this.state.observacao_error)}
            helperText={this.state.observacao_error}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12 p-display-flex p-justify-space-between">
          <Button color={COLORS.GREY} label={this.props.viewOnly ? 'Voltar' : 'Cancelar'} onClick={this.onCancel} />
          {!this.props.viewOnly && (
            <Button label='Salvar' onClick={this.onSubmit} />
          )}
        </div>
      </div>
    )
  }
}

const propsFromDatabase = async props => {
  const allRacas = await utils.getDomainValuesBy('Raça do Touro')
  let racas = []

  const aberdeen = _.find(allRacas, raca =>
    raca.valor.toLowerCase().includes('aberdeen angus')
  )
  const nelore = _.find(allRacas, raca => raca.valor.toLowerCase() === 'nelore')
  const braford = _.find(allRacas, raca =>
    raca.valor.toLowerCase().includes('braford')
  )
  const brangus = _.find(allRacas, raca =>
    raca.valor.toLowerCase().includes('brangus')
  )
  const hereford = _.find(allRacas, raca =>
    raca.valor.toLowerCase().includes('hereford')
  )

  racas = _.compact([aberdeen, nelore, braford, brangus, hereford])
  const ids = racas.map(raca => raca.id)

  racas = _.compact(
    racas.concat(
      _.sortBy(
        _.filter(allRacas, raca => !_.find(ids, id => id === raca.id)),
        [raca => raca.valor.toLowerCase()]
      )
    )
  )
  return {
    racas,
    requiredFields: ['nome', 'raca', 'central'],
    centrais: _.sortBy(await utils.getDomainValuesBy('Centrais'), [
      central => central.valor.toLowerCase()
    ])
  }
}

export default utils.withPropsFromDatabase(propsFromDatabase, BullForm)
