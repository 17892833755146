import * as CONSTANTS from './constants'

export const startLoading = dispatch => {
  return async dispatch => {
    dispatch({ type: CONSTANTS.START_LOADING })
  }
}

export const endLoading = dispatch => {
  return async dispatch => {
    dispatch({ type: CONSTANTS.END_LOADING })
  }
}
