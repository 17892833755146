import React from 'react'
import { Box, Container, IconButton } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import MainContainer from '../../components/MainContainer'
import * as D from './data'

const AdminReleasesList = () => {
  const history = useHistory()

  const classes = D.useStyles()

  return (
    <Container>
      <TopBar title='Lançamentos do administrador' />
      <TitleBar
        title='Lançamentos do administrador'
        paths={[
          {
            label: 'Lançamentos do administrador',
          },
        ]}
      />
      <MainContainer>
        {/* Menu List section */}
        <Box p={1}>
          {D.itens.map((item) => (
            <Link
              key={item.id}
              to={item.route}
              style={{ textDecoration: 'none' }}
            >
              <Box className={classes.boxMenu}>
                {item.title}
                <IconButton
                  style={{ color: 'white' }}
                  onClick={() => history.push(item.route)}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            </Link>
          ))}
        </Box>
      </MainContainer>
    </Container>
  )
}

export default AdminReleasesList
