/* eslint-disable no-unused-expressions */
import '../../components/styles/customstyle.scss'

import React, { Component } from 'react'
import track from 'react-tracking'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import { Container, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import fnsformat from 'date-fns/format'
import ptBr from 'date-fns/locale/pt-BR'

import { AVAILABLE_ENTITIES, utils } from '../../database'
import { endLoading, startLoading } from '../../redux/screen/actions'
import { getLoggedUser } from '../../redux/auth/actions'
import {
  getBatchReport,
  getCorralReport,
  getFarmReport,
} from '../../redux/reports/actions'
import { FAZENDA, LOTE, RETIRO, formatName } from '../../utils/formHelper'
import { verify } from '../../utils/verifyDuplicatePages'
import PaginatedTable, { CELL_TYPES } from '../../components/PaginatedTable'

import GoogleChart from '../../components/GoogleChart'
import MainContainer from '../../components/MainContainer'
import NewCover from './components/NewCover'
import Prompt from '../../components/Prompt'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import ZoetisLogo from '../../assets/img/logozoetis.png'
import { LocalStorageHelper } from '../../utils/localStorageHelper'

const PREVISAO_PARTOS = 'Previsão de Partos'
const TAXA_PRENHEZ = 'Taxa de Prenhez à IATF'
const TAXA_IATF_TOURO = 'Taxa de Prenhez à IATF por Touro'
const TAXA_IATF_INSEMINADOR = 'Taxa de Prenhez à IATF por Inseminador'
const TAXA_FINAL_EM = 'Taxa de Prenhez ao Final da Estação de Monta'
const TAXA_IATF_PROTOCOLO = 'Taxa de Prenhez à IATF por Protocolo'

@track(() => ({ page: 'Relatórios', date: new Date() }))
class NewReport extends Component {
  constructor(props) {
    super(props)

    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      chooseReportsModalIsOpen: false,
      isFiltered: false,
      data:
        this.props.data?.map((item, i) => ({
          ...item,
          visibility: '',
          newIndex: i + 1,
        })) ?? [],
      allReportsToSaveOrPrint: this.props.titlesToReports,
      selectedReportsToSaveOrPrint:
        JSON.parse(LocalStorageHelper.get(this.props.localStorageKey)) ??
        this.props.titlesToReports,
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.handleDataTable = this.handleDataTable.bind(this)

    props.endLoading()

    this.handleDataTable()
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)

    if (!!!LocalStorageHelper.get(this.props.localStorageKey)) {
      LocalStorageHelper.add(
        this.props.localStorageKey,
        JSON.stringify(this.props.titlesToReports)
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  validateDataTables(data) {
    return data.title === '' || data.title === ''
  }

  validateDataGraphs(data) {
    return (
      data.title === 'Taxa de Prenhez à IATF de Acordo com o ECC Inicial'
      // data.title == 'Taxa de Prenhez à IATF por Lote',
    )
  }

  handleDataTable() {
    this?.props?.data?.map(
      (m) => this.validateDataTables(m) && (m.tabela = null)
    )
    this?.props?.data?.map(
      (m) => this.validateDataGraphs(m) && (m.grafico = null)
    )
  }

  handleReportsVisibility() {
    let data =
      this.state.selectedReportsToSaveOrPrint.length > 0
        ? this.state.selectedReportsToSaveOrPrint
        : this.state.data

    this.setState({
      data: this.state.data.map((item) => {
        for (let i = 0; i < data.length; i++) {
          if (item.title === data[i]) {
            return {
              ...item,
              newIndex: i + 1,
              visibility: '',
            }
          }
        }

        return {
          ...item,
          newIndex: 0,
          visibility: 'hidewhenprinting',
        }
      }),
      isFiltered: data.length !== this.state.data.length ? true : false,
    })

    setTimeout(() => {
      this.handlePrint()
    }, 1000)
  }

  @track({ action: 'Clicou no botao de imprimir' })
  handlePrint() {
    window.print()
  }

  handleGraphicsPage(data) {
    return data ? 20 : 45
  }

  handleTablePage(data, maxLength) {
    return data.length >= maxLength ? 1 : (maxLength = 0)
  }

  render() {
    let pageBreak = 0
    let maxLength = 0
    const farm = this.props.farm
      ? formatName(this.props.farm.nome, FAZENDA)
      : ''

    const proprietario = this.props.farm
      ? this.props.farm.nomeProprietario
      : 'Nome Proprietário'
    const inicioEM = this.props.farm
      ? fnsformat(this.props.farm.inicioEstacaoMonta._i, 'dd/MM/yyyy', {
        locale: ptBr,
      })
      : ''
    const fimEM = this.props.farm
      ? fnsformat(this.props.farm.fimEstacaoMonta._i, 'dd/MM/yyyy', {
        locale: ptBr,
      })
      : ''
    const corral =
      this.props.farm &&
      this.props.farm.retiro &&
      this.props.corral &&
      formatName(this.props.corral.nome, RETIRO)
    const batch =
      this.props.batch && formatName(this.props.batch.nomeLote, LOTE)

    const title = batch || corral || farm

    let paths = [
      {
        label: farm,
        route: `/farms/${this.props.farm.id}`,
      },
    ]

    if (this.props.farm.retiro && corral) {
      paths.push({
        label: corral,
        route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`,
      })
    }

    if (batch) {
      paths.push({
        label: batch,
        route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}/batches/${this.props.batch.id}`,
      })
    }

    paths.push({
      label: 'Relatórios',
    })

    return (
      <Container>
        <TopBar className='hidewhenprinting' title={title} />
        <TitleBar
          className='hidewhenprinting'
          title={`Relatórios ${this.props.title}`}
          buttons={[
            {
              label: 'Imprimir/Salvar',
              onClick: () =>
                this.setState({
                  chooseReportsModalIsOpen: true,
                }),
            },
          ]}
          paths={paths}
        />

        <MainContainer>
          <NewCover
            user={
              this.props.user ? this.props.user.nomeCompleto : 'Nome de Usuário'
            }
            inicioEM={inicioEM}
            fimEM={fimEM}
            farm={farm}
            corral={corral}
            batch={batch}
            proprietario={proprietario}
          />
          {this?.state?.data
            ?.sort((a, b) => {
              if (a.newIndex === 0 && b.newIndex === 0) {
                return 0
              } else if (a.newIndex === 0) {
                return 1
              } else if (b.newIndex === 0) {
                return -1
              } else {
                return a.newIndex - b.newIndex
              }
            })
            ?.map((content, index) => (
              <div
                key={uuid()}
                className={`
                ${content.newIndex === 0 ? 'hidewhenprinting' : ''}
                ${this.state.isFiltered &&
                    content.newIndex ===
                    this.state.selectedReportsToSaveOrPrint.length
                    ? 'pagenobreak'
                    : 'pagebreak'
                  }
                `}
              >
                {index === 0 && (
                  <div style={{ height: '60px', width: '100%' }}></div>
                )}
                {index !== 0 && <div key={uuid()} className='marginTop' />}
                <span key={uuid()} className='span onlywhenprinting'>
                  {index + 2 + pageBreak}
                </span>
                <div key={uuid()} className='marginTop' />
                {content.title && (
                  <h1
                    className={content.visibility}
                    style={{
                      margin: '0 40px 40px 40px',
                      textAlign: 'center',
                      fontSize: 22,
                      textDecorationLine: 'underline',
                    }}
                  >
                    {content.title}
                  </h1>
                )}
                {content.grafico && (
                  <div className={content.visibility}>
                    <GoogleChart
                      key={uuid()}
                      width={this.state.onPrint ? 1200 : this.state.width}
                      widthFactor={0.85}
                      data={content.grafico.data}
                      configuration={content.grafico.configuration}
                      skipIATFLabel={
                        content.title === PREVISAO_PARTOS ||
                        content.title === TAXA_PRENHEZ ||
                        content.title === TAXA_IATF_TOURO ||
                        content.title === TAXA_IATF_INSEMINADOR ||
                        content.title.indexOf(TAXA_FINAL_EM) !== -1 ||
                        content.title.indexOf(TAXA_IATF_PROTOCOLO) !== -1 ||
                        content.title === TAXA_IATF_PROTOCOLO
                      }
                    />
                  </div>
                )}
                {content.tabela && (
                  <div
                    className={`printableTableContainer ${content.visibility}`}
                  >
                    <PaginatedTable
                      columns={content?.tabela?.columns?.map(
                        (column, index) => ({
                          name: column,
                          type:
                            column === 'Lote' ||
                              column === 'Touro' ||
                              column === 'Inseminador'
                              ? CELL_TYPES.TEXT
                              : CELL_TYPES.TEXT_CENTER,
                          label: (row) => row[index],
                        })
                      )}
                      data={content.tabela.data}
                      skipPagination
                      size='small'
                      style={{
                        margin: 'auto',
                        marginBottom: '80px',
                        width: '90%',
                      }}
                    />
                  </div>
                )}
                <div style={{ visibility: 'hidden' }}>
                  {(maxLength = this.handleGraphicsPage(content.grafico))}
                </div>
                <div style={{ visibility: 'hidden' }}>
                  {
                    (pageBreak += this.handleTablePage(
                      content.tabela.data,
                      maxLength
                    ))
                  }
                </div>
              </div>
            ))}
          <Prompt
            visible={this.state.chooseReportsModalIsOpen}
            title='Escolha os relatórios que deseja imprimir ou salvar'
            className='hidewhenprinting'
            message={
              <div className='grid-item p-12 d-3 hidewhenprinting'>
                <Autocomplete
                  multiple
                  id='allReportsToSaveOrPrint'
                  defaultValue={
                    JSON.parse(
                      LocalStorageHelper.get(this.props.localStorageKey)
                    ) ?? this.state.allReportsToSaveOrPrint
                  }
                  onChange={(_, value) => {
                    this.setState({
                      selectedReportsToSaveOrPrint: value.map((item) => item),
                    })
                    LocalStorageHelper.add(
                      this.props.localStorageKey,
                      JSON.stringify(value)
                    )
                  }}
                  filterSelectedOptions
                  options={this.state.allReportsToSaveOrPrint}
                  getOptionLabel={(option) => option}
                  style={{ width: '100%', backgroundColor: 'white' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Relatórios'
                      variant='outlined'
                    />
                  )}
                />
              </div>
            }
            leftButton={{
              label: 'Redefinir',
              onClick: () => {
                this.setState({
                  selectedReportsToSaveOrPrint: this.props.titlesToReports,
                  chooseReportsModalIsOpen: false,
                })
                LocalStorageHelper.add(
                  this.props.localStorageKey,
                  JSON.stringify(this.props.titlesToReports)
                )
              },
            }}
            buttons={[
              {
                label: 'Fechar',
                onClick: () =>
                  this.setState({ chooseReportsModalIsOpen: false }),
              },
              {
                label: 'Imprimir/Salvar',
                onClick: () => {
                  this.setState({
                    chooseReportsModalIsOpen: false,
                    isFiltered: true,
                  })
                  this.handleReportsVisibility()
                },
              },
            ]}
          />
          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
              Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false,
              },
            ]}
          />
        </MainContainer>
        <div className='fixedZoetisLogo '>
          <img
            className='onlywhenprinting'
            style={{ width: '80px' }}
            src={ZoetisLogo}
            alt='Logo'
          />
        </div>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
})

const propsFromDatabase = async (props) => {
  props.startLoading()
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    (
      await getLoggedUser()
    ).userId
  )

  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    props.match.params.farmId
  )
  const corral =
    props.match.params.corralId &&
    (await utils.getElement(
      AVAILABLE_ENTITIES.CORRALS,
      props.match.params.corralId
    ))
  const batch =
    props.match.params.batchId &&
    (await utils.getElement(
      AVAILABLE_ENTITIES.BATCHES,
      props.match.params.batchId
    ))
  let data = []
  let title = ''
  let localStorageKey = ''
  if (batch) {
    data = await getBatchReport(batch.id)
    title = 'por Lote'
    localStorageKey = 'ProGerarSavedFieldsToSaveOrPrintLoteReport'
  } else if (corral) {
    data = await getCorralReport(corral.id)
    title = 'por Retiro'
    localStorageKey = 'ProGerarSavedFieldsToSaveOrPrintRetiroReport'
  } else {
    data = await getFarmReport(farm.id)
    title = 'por Fazenda'
    localStorageKey = 'ProGerarSavedFieldsToSaveOrPrintFazendaReport'
  }

  const titlesToReports = data.map((report) => report.title)

  return {
    user,
    farm,
    corral,
    batch,
    data,
    title,
    localStorageKey,
    titlesToReports,
    error: data instanceof Error,
  }
}

export default connect(
  null,
  mapDispatchToProps
)(utils.withPropsFromDatabase(propsFromDatabase, withRouter(NewReport)))
