import { date, field, readonly, relation } from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'

export const modelToDTOMapping = {
  identical: ['nome', 'observacao', 'cod_Touro'],
  renamed: [
    {
      dtoName: 'centralValue',
      modelName: 'central_value'
    }
  ],
  references: [
    {
      dtoName: 'raca',
      modelName: 'raca_id'
    },
    {
      dtoName: 'central',
      modelName: 'central_id'
    },
    {
      dtoName: 'usuarioId',
      modelName: 'usuario_id'
    },
  ]
}

export default class BullsNew extends Model {
  static table = 'bulls_new'

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('nome') nome
  @field('cod_Touro') cod_Touro
  @field('observacao') observacao
  @field('central_value') centralValue
  @field('usuario_id') usuarioId

  //Foreign Relations - Domain Values
  @relation('domain_values', 'raca_id') raca
  @relation('domain_values', 'central_id') central
}
