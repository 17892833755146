import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

const CustomizedSwitch = withStyles({
  switchBase: {
    color: 'rgb(229, 229, 229)',
    '&$checked': {
      color: 'rgb(234, 93, 13)',
    },
    '&$checked + $track': {
      backgroundColor: 'rgb(234, 93, 13)',
    },
  },
  checked: {},
  track: {},
})(Switch)

export default function CustomSwitch(props) {
  return (
    <div
      style={{
        fontSize: props.fontSize ? props.fontSize : '18px',
      }}
    >
      <CustomizedSwitch
        checked={props.checked}
        onChange={props.onHandleChange}
        name='checked'
        tabIndex={props.tabIndex}
      />
      <span style={{ verticalAlign: 'middle' }}>{props.text}</span>
    </div>
  )
}
