import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { getLoggedUser } from './redux/auth/actions'
import { utils } from './database'

const PrivateRoute = ({ component: Component, logged, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (rest.route === "/loginAntigo") {
        return logged && logged.userId ? (
          <Component logged {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/loginAntigo", state: { from: props.location } }}
          />
        );
      }
      return logged && logged.userId ? (
        <Component logged {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      );
    }}
  />
)


const propsFromDatabase = async props => ({
  logged: await getLoggedUser()
})

export default utils.withPropsFromDatabase(propsFromDatabase, PrivateRoute)
