import {
  children,
  date,
  field,
  readonly,
  relation
} from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'

export const modelToDTOMapping = {
  identical: ['name'],
  renamed: [],
  references: [
    {
      dtoName: 'veterinaryId',
      modelName: 'veterinary_id'
    }
  ],
  children: [
    {
      name: 'managementProtocols',
      type: 'MANAGEMENT_PROTOCOLS'
    }
  ]
}

export default class Protocols extends Model {
  static table = 'protocols'
  static associations = {
    management_protocols: { type: 'has_many', foreignKey: 'protocol_id' }
  }

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('name') name

  //Foreign Relations
  @relation('users', 'veterinary_id') veterinary

  //Lazy Fields/Methods
  @children('management_protocols') managementProtocols
}
