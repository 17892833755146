import { Model, Q } from '@nozbe/watermelondb'
import {
  children,
  date,
  field,
  lazy,
  readonly,
  relation
} from '@nozbe/watermelondb/decorators'

export const modelToDTOMapping = {
  identical: [
    'nomeCompleto',
    'email',
    'cpf',
    'estado',
    'municipio',
    'password',
    'salt',
    'zoetisId',
  ],
  renamed: [],
  references: [
    {
      dtoName: 'createdBy',
      modelName: 'created_by_user_id'
    }
  ],
  children: [
    {
      name: 'roles',
      type: 'ROLES'
    },
    {
      name: 'terms',
      type: 'TERMS'
    }
  ]
}

export default class Users extends Model {
  static table = 'users'
  static associations = {
    farms__rel__users: { type: 'has_many', foreignKey: 'user_id' },
    roles: { type: 'has_many', foreignKey: 'user_id' },
    terms: { type: 'has_many', foreignKey: 'user_id' }
  }

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('nomeCompleto') nomeCompleto
  @field('email') email
  @field('cpf') cpf
  @field('estado') estado
  @field('municipio') municipio
  @field('zoetisId') zoetisId

  //Foreign Relations
  @relation('users', 'created_by_user_id') createdByUserId

  //Fields - Internal
  @field('password') password
  @field('salt') salt

  //Lazy Fields/Methods
  @children('roles') roles
  @children('terms') terms

  @lazy relatedFarms = this.collections
    .get('farms')
    .query(
      Q.on('farms__rel__users', 'user_id', this.id),
      Q.where('isDeleted', false),
      Q.on('farms__rel__users', 'isDeleted', false)
    )
}
