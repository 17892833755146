import { date, field, readonly, relation } from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'
import _ from 'lodash'

export const modelToDTOMapping = {
  identical: ['valor', 'descricao'],
  renamed: [],
  references: [
    {
      dtoName: 'valorDominioPaiId',
      modelName: 'valor_dominio_pai_id'
    },
    {
      dtoName: 'tipoDominioId',
      modelName: 'domain_type_id'
    }
  ]
}

export default class DomainValues extends Model {
  static table = 'domain_values'
  static associations = {
    domain_values: { type: 'belongs_to', key: 'valor_dominio_pai_id' },
    domain_types: { type: 'belongs_to', key: 'domain_type_id' }
  }

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('valor') valor
  @field('descricao') descricao

  //Foreign Relations - Domain Values
  @relation('domain_values', 'valor_dominio_pai_id') valorDominioPai

  //Foreign Relations
  @relation('domain_types', 'domain_type_id') domainType
}
