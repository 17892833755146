import { TimeoutError, timeoutFetch } from './redux/api/utils'
import database, {
  AVAILABLE_DTO_MAPPINGS,
  AVAILABLE_ENTITIES,
  Repository,
  fromDTO,
  toDTO,
  utils,
} from './database'
import { getLoggedUser, refreshToken } from './redux/auth/actions'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { CHANGE_CURRENT_SYNC_VIEW } from './redux/sync/constants'
import { Q } from '@nozbe/watermelondb'
import _ from 'lodash'
import hasInternet from '../src/utils/recognizeInternetConnection'
import { prepareMarkAsSynced } from '@nozbe/watermelondb/sync/impl/helpers'
import { store } from './store'
import uuid from 'react-uuid'

// const SYNC_PORTS = {
//   PULL: 93,
//   PUSH: 88,
//   AUTH: 81,
// }

// const SYNC_PULL_GROUP = 'sync-client'
const SYNC_PUSH_GROUP = 'sync'
const LOGIN_GROUP = 'login-register'

// const SYNC_TIMEOUT = 15000 //Incresed due to high response times by backend seen recently
const SYNC_TIMEOUT = 1000 * 60 * 5 //5 min //Incresed due to high response times by backend seen recently
// const EXTRA_TIMEOUT = 120000
const EXTRA_TIMEOUT = 1000 * 60 * 5 //5 min

const REACT_APP_BASE_URL_GATEWAY = process.env.REACT_APP_BASE_URL_GATEWAY
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
const REACT_APP_BASE_URL_PULL_GATEWAY_VERITY =
  process.env.REACT_APP_BASE_URL_PULL_GATEWAY_VERITY
const REACT_APP_BASE_URL_PUSH_GATEWAY_VERITY =
  process.env.REACT_APP_BASE_URL_PUSH_GATEWAY_VERITY
const REACT_APP_TOGGLE_GATEWAY_VERITY =
  process.env.REACT_APP_TOGGLE_GATEWAY_VERITY

const SYNC_URLS = {
  PULL: `${REACT_APP_BASE_URL}/api/Sync`,
  HALF_PULL: `${REACT_APP_BASE_URL}/half-sync`,
  PULL_GATEWAY_VERITY: `${REACT_APP_BASE_URL_PULL_GATEWAY_VERITY}/sincronizar`,
  PUSH: `${REACT_APP_BASE_URL_GATEWAY}/${SYNC_PUSH_GROUP}/api/api/Queue`,
  PUSH_GATEWAY_VERITY: `${REACT_APP_BASE_URL_PUSH_GATEWAY_VERITY}/enviar`,
  REFRESH_TOKEN: `${REACT_APP_BASE_URL_GATEWAY}/${LOGIN_GROUP}/api/Authentication/Login`,
}

const SLEEP_TIME = 30000
const PREFIX = '[SYNC-WORKER]'
const LOG_TYPES = {
  LOG: '[LOG]',
  WARN: '[WARN]',
  ERROR: '[ERROR]',
}

const SYNC_OBJECT_TO_TABLE = {
  usuarios: 'users',
  tipoDominios: 'domain_types',
  valorDominios: 'domain_values',
  fazendas: 'farms',
  protocolos: 'protocols',
  lotes: 'batches',
  d0s: 'd0s',
  dns: 'dns',
  iatfs: 'iatfs',
  fazendaUsuarios: 'farms__rel__users',
  // touros: 'bulls',
  touros: 'bulls_new',
  partidas: 'partida',
  retiros: 'corrals',
}

const SYNC_OBJECT_TO_TABLE_ONLY_BATCH = {
  lotes: 'batches',
  d0s: 'd0s',
  dns: 'dns',
  iatfs: 'iatfs',
}

const toDTOMapping = (name) => {
  return name === 'farms__rel__users' ? 'FARM_USERS' : name.toUpperCase()
}

/*
const sendMessage = (message, type) => {
  postMessage(`${PREFIX}${type} ${message}`)
}
*/

const sendMessage = (message, type) => {
  const logMessage = `${PREFIX}${type} ${message}`
  switch (type) {
    case LOG_TYPES.WARN:
      console.warn(logMessage)
      break
    case LOG_TYPES.ERROR:
      console.error(logMessage)
      break
    case LOG_TYPES.LOG:
      console.log(logMessage)
      break
    default:
      console.log(logMessage)
  }
}

const log = (message) => {
  sendMessage(message, LOG_TYPES.LOG)
}

const warn = (message) => {
  sendMessage(message, LOG_TYPES.WARN)
}

const warnPush = (message) => {
  alterSyncStatus(0)
  sendMessage(message, LOG_TYPES.WARN)
}

const warnPull = (message) => {
  alert(
    'Ocorreu um erro durante a sincronização. Por favor, tente novamente mais tarde.'
  )
  sendMessage(message, LOG_TYPES.WARN)
}

const error = (message) => {
  sendMessage(message, LOG_TYPES.ERROR)
}

let currentStatus

export const alterSyncStatus = (payload) => {
  currentStatus = payload
  store.dispatch({
    type: CHANGE_CURRENT_SYNC_VIEW,
    payload: payload,
  })
}

export const sleep = async (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

let isForcedSync = false
let isSyncing = false

export const forceSync = async (isOnlyPull = false, isOnlyPush = true) => {
  if (!isSyncing) {
    isForcedSync = true
    try {
      await sync(isOnlyPull, isOnlyPush)
    } catch (e) {
      warnPull(`There was an error trying to force sync: ${e}`)
    }
    isForcedSync = false
  } else {
    //TODO: Solve this loop in another way (this is so we can away for forceSync so it guarantee anyway that a sync was made and finished, so we can dispatch endLoading)
    await sleep(SLEEP_TIME / 10)
    if (isSyncing) {
      return forceSync(false)
    }
  }
}

export const startSyncingLoop = async () => {
  await sleep(SLEEP_TIME)
  avoidConcurrentSync()
}

async function avoidConcurrentSync() {
  if (!isForcedSync && !isSyncing) {
    await sync()
  }
  await sleep(SLEEP_TIME)
  avoidConcurrentSync()
}

async function sync(isOnlyPull = false, isOnlyPush = true) {
  if (!hasInternet()) {
    alterSyncStatus(0)
    return
  }

  log('Checking For New Things To Sync')
  if (database) {
    let loggedUserInfo = await getLoggedUser()
    if (_.isEmpty(loggedUserInfo) || _.isEmpty(loggedUserInfo.userId)) {
      log('No logged user detected. Skipping...')
    } else {
      isSyncing = true
      isOnlyPush && alterSyncStatus(1)

      // Push Changes
      log('Pushing Changes')
      var mustPull = await push(loggedUserInfo.token, isOnlyPull)

      //await pullChanges(require('./syncData.json'))

      //Workaround to get a possibly updated token in case we refreshed during pull due a 401 error
      //until we propagate the refresh token thing to push as well
      loggedUserInfo = await getLoggedUser()

      if (mustPull && !isOnlyPush) {
        log('Pulling Changes')
        await pull(loggedUserInfo)
      }

      isSyncing = false

      if (isSyncing == false && currentStatus != 0) {
        alterSyncStatus(2)
      }
    }
  } else {
    warn('Database not initialized')
  }
}

async function oldPull(loggedUserInfo, isRetry = false, forceRessinc = false) {
  const lastPulledAt = await database.adapter.getLocal(
    `${loggedUserInfo.userId}_lastPulledAt`
  )
  // const lastPulledAtAMinuteAgo = moment.utc(
  //   Date.parse(lastPulledAt).valueOf() - 3600000
  // ).toISOString()
  const token = loggedUserInfo.token

  let params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  let url = `${SYNC_URLS.PULL}${lastPulledAt && !forceRessinc ? `?lastPulledAt=${lastPulledAt}` : ''
    }`

  // if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
  //   url = `${SYNC_URLS.PULL_GATEWAY_VERITY}${
  //     lastPulledAt ? `?lastPulledAt=${lastPulledAt}` : ''
  //   }`
  // }

  log(`URL: ${url}`)
  log(`Fetching changes using token ${token}`)

  // Pull Changes
  await timeoutFetch(url, params, isForcedSync ? EXTRA_TIMEOUT : SYNC_TIMEOUT)
    .catch((err) => {
      if (err instanceof TimeoutError) {
        warnPull(
          'Timeout trying to fetch changes. Will try again in next cycle'
        )
      } else {
        warnPull(`There was an error trying to fetch sync object: ${error}`)
      }
    })
    .then(async (response) => {
      if (!response) {
        if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
          return pull(loggedUserInfo, true)
        }
        return null
      }
      if (response.status == 401) {
        const data = await refreshToken()
        if (!_.isEmpty(data) && data.refreshed && !isRetry) {
          return pull(
            {
              userId: data.userId,
              token: data.token,
              pass: data.pass,
            },
            true
          )
        }
      } else if (response.status >= 400 && response.status !== 401) {
        warnPull(`Received status: ${response.status}`)
        if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
          return pull(loggedUserInfo, true)
        }
      } else {
        const content = JSON.parse(await response.text())
        if (content.sucesso) {
          log('New content received! Updating Database')
          await pullChanges(content.response)

          //TODO: Only update lastPulletAt if pullChanges was successful
          log(`LastPulletAt: ${content.response.lastPulledAt}`)
          await database.adapter.setLocal(
            `${loggedUserInfo.userId}_lastPulledAt`,
            content.response.lastPulledAt
          )
          localStorage.setItem('lastSyncPull', new Date())
          alert(
            'Sincronização realizada com Sucesso! Seus dados foram atualizados.'
          )
        } else {
          warnPull(`Request unsuccessful. Reason: ${content.mensagem}`)
        }
      }
    })
}

async function pull(loggedUserInfo, isRetry = false, forceRessinc = false) {
  const lastPulledAt = await database.adapter.getLocal(
    `${loggedUserInfo.userId}_lastPulledAt`
  )
  // const lastPulledAtAMinuteAgo = moment.utc(
  //   Date.parse(lastPulledAt).valueOf() - 3600000
  // ).toISOString()
  const token = loggedUserInfo.token

  let body = JSON.stringify(lastPulledAt ? {
    lastPulledAt
  } : {})

  let params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body
  }
  let url = `${SYNC_URLS.HALF_PULL}${lastPulledAt && !forceRessinc ? `?lastPulledAt=${lastPulledAt}` : ''
    }`

  // if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
  //   url = `${SYNC_URLS.PULL_GATEWAY_VERITY}${
  //     lastPulledAt ? `?lastPulledAt=${lastPulledAt}` : ''
  //   }`
  // }

  log(`URL: ${url}`)
  log(`Fetching changes using token ${token}`)

  // Pull Changes
  await timeoutFetch(url, params, isForcedSync ? EXTRA_TIMEOUT : SYNC_TIMEOUT)
    .catch((err) => {
      if (err instanceof TimeoutError) {
        warnPull(
          'Timeout trying to fetch changes. Will try again in next cycle'
        )
      } else {
        warnPull(`There was an error trying to fetch sync object: ${error}`)
      }
    })
    .then(async (response) => {
      if (!response) {
        if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
          return pull(loggedUserInfo, true)
        }
        return null
      }
      if (response.status == 401) {
        const data = await refreshToken()
        if (!_.isEmpty(data) && data.refreshed && !isRetry) {
          return pull(
            {
              userId: data.userId,
              token: data.token,
              pass: data.pass,
            },
            true
          )
        }
      } else if (response.status >= 400 && response.status !== 401) {
        warnPull(`Received status: ${response.status}`)
        if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
          return pull(loggedUserInfo, true)
        }
      } else {
        const content = JSON.parse(await response.text())
        if (content.sucesso) {
          log('New content received! Updating Database')
          await pullChanges(content.response)

          //TODO: Only update lastPulletAt if pullChanges was successful
          log(`LastPulletAt: ${content.response.lastPulledAt}`)
          await database.adapter.setLocal(
            `${loggedUserInfo.userId}_lastPulledAt`,
            content.response.lastPulledAt
          )
          localStorage.setItem('lastSyncPull', new Date())
          // alert(
          //   'Sincronização realizada com Sucesso! Seus dados foram atualizados.'
          // )
        } else {
          warnPull(`Request unsuccessful. Reason: ${content.mensagem}`)
        }
      }
    })
}

const downloadFarmsModified = (fazendasBaixadas, localStorageFarms) => {
  fazendasBaixadas.map((farm) => {
    localStorageFarms.map((df) => {
      if (df.id === farm.id) {
        df.lastPulledAt = farm.lastPulledAt
      } else {
        let downloadFarmsIds
        downloadFarmsIds = []
        localStorageFarms.map((downFarm) => downloadFarmsIds.push(downFarm.id))
        if (downloadFarmsIds.indexOf(farm.id) < 0) {
          localStorageFarms.push(farm)
        }
      }
    })
  })
  return localStorageFarms
}

export async function pullFarms(
  farms,
  loggedUserInfo = {},
  isRetry = false,
  forceRessinc = false,
  isRemoveLastPulledAtFarm = false,
  batchesId = []
) {
  loggedUserInfo = await getLoggedUser()
  const lastPulledAt = await database.adapter.getLocal(
    `${loggedUserInfo.userId}_lastPulledAt`
  )
  // const lastPulledAtAMinuteAgo = moment.utc(
  //   Date.parse(lastPulledAt).valueOf() - 3600000
  // ).toISOString()
  const token = loggedUserInfo.token

  let lastPulletAtFazendas = {}

  await farms.map(async (f) => {
    await database.adapter.getLocal(
      `${f}_lastPulledAt`
    ).then(res => {
      lastPulletAtFazendas[f] = res
    })
  })

  let fazendasBaixadas = []
  let body = ''

  farms.map((f) => {
    fazendasBaixadas.push({
      id: f,
      lastPulledAt: lastPulletAtFazendas[f] && !isRemoveLastPulledAtFarm ? lastPulletAtFazendas[f] : null,
      // lastPulledAt: lastPulletAtFazendas[f] ? lastPulletAtFazendas[f] : null
    })
  }
  )

  if (batchesId.length > 0) {
    body = JSON.stringify(lastPulledAt ? {
      lastPulledAt,
      fazendasBaixadas,
      lotesBaixados: batchesId
    } : {})
  } else {
    body = JSON.stringify(lastPulledAt ? {
      lastPulledAt,
      fazendasBaixadas,
    } : {})
  }


  let params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body
  }

  let url = `${SYNC_URLS.HALF_PULL}${lastPulledAt && !forceRessinc ? `?lastPulledAt=${lastPulledAt}` : ''
    }`

  // if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
  //   url = `${SYNC_URLS.PULL_GATEWAY_VERITY}${
  //     lastPulledAt ? `?lastPulledAt=${lastPulledAt}` : ''
  //   }`
  // }

  log(`URL: ${url}`)
  log(`Fetching changes using token ${token}`)

  // Pull Changes
  await timeoutFetch(url, params, isForcedSync ? EXTRA_TIMEOUT : SYNC_TIMEOUT)
    .catch((err) => {
      if (err instanceof TimeoutError) {
        warnPull(
          'Timeout trying to fetch changes. Will try again in next cycle'
        )
      } else {
        warnPull(`There was an error trying to fetch sync object: ${error}`)
      }
    })
    .then(async (response) => {
      if (!response) {
        if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
          return pullFarms(farms, loggedUserInfo, true, forceRessinc, isRemoveLastPulledAtFarm, batchesId)
        }
        return null
      }
      if (response.status == 401) {
        const data = await refreshToken()
        if (!_.isEmpty(data) && data.refreshed && !isRetry) {
          return pullFarms(
            farms,
            {
              userId: data.userId,
              token: data.token,
              pass: data.pass,
            },
            true,
            forceRessinc,
            isRemoveLastPulledAtFarm,
            batchesId
          )
        }
      } else if (response.status >= 400 && response.status !== 401) {
        warnPull(`Received status: ${response.status}`)
        if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
          return pullFarms(farms, loggedUserInfo, true, forceRessinc, isRemoveLastPulledAtFarm, batchesId)
        }
      } else {
        const content = JSON.parse(await response.text())
        if (content.sucesso) {
          log('New content received! Updating Database')
          if (batchesId.length > 0) {
            await pullChanges(content.response, true)
          } else {
            await pullChanges(content.response)
          }

          //TODO: Only update lastPulletAt if pullChanges was successful
          log(`LastPulletAt: ${content.response.lastPulledAt}`)
          await database.adapter.setLocal(
            `${loggedUserInfo.userId}_lastPulledAt`,
            content.response.lastPulledAt
          )

          await farms.map(async (f) => {
            await database.adapter.setLocal(
              `${f}_lastPulledAt`,
              content.response.lastPulledAt
            )
          })

          if (JSON.parse(localStorage.getItem('downloadFarms'))) {
            let downloadFarms = (JSON.parse(localStorage.getItem('downloadFarms')))
            if (downloadFarms.length > 0 && downloadFarms.indexOf(null) < 0) {
              downloadFarms = downloadFarmsModified(fazendasBaixadas, downloadFarms)
            }
            localStorage.setItem('lastSyncPull', new Date())
            localStorage.removeItem('downloadFarms')

            localStorage.setItem('downloadFarms', JSON.stringify(downloadFarms))
          } else {
            localStorage.setItem('downloadFarms', JSON.stringify(fazendasBaixadas))
          }
        } else {
          warnPull(`Request unsuccessful. Reason: ${content.mensagem}`)
        }
      }
    })
}

async function createPayloadFor(entry, value, firstPass = true, verb = 'POST') {
  let mapping =
    AVAILABLE_DTO_MAPPINGS[
    firstPass ? toDTOMapping(SYNC_OBJECT_TO_TABLE[value]) : value
    ]
  let json = toDTO(mapping, entry)
  if (mapping.children) {
    for (const child of mapping.children) {
      //console.warn(entry)
      json[child.name] = await Promise.all(
        (await entry[child.name].fetch())
          .filter((element) => !element.isDeleted)
          .map(
            async (element) =>
              await createPayloadFor(element, child.type, false)
          )
      )
    }
  }

  if (mapping.renamed) {
    for (const field of mapping.renamed) {
      if (field.isDate) {
        json[field.dtoName] = utils.fromMoment(json[field.dtoName])
      }
    }
  }

  if (firstPass) {
    let payload = {
      verbo: verb,
      json: {
        ...json,
        createdAt: json.createdAt.valueOf(),
        updatedAt: json.updatedAt.valueOf(),
      },
    }

    return payload
  } else {
    return {
      ...json,
      createdAt: json.createdAt.valueOf(),
      updatedAt: json.updatedAt.valueOf(),
    }
  }
}

function normalizeEntityName(name) {
  if (name === 'fazendaUsuarios') return 'fazenda_usuario'
  return name.substring(0, name.length - 1) //remove 's'
}

async function backupOnLocalStorage(payload) {
  await getLoggedUser().then((res) => {
    let todayDate = new Date()
    todayDate.setHours(0, 0, 0, 0)
    let formatedTodayDate = 'Registros - ' + todayDate + ` - ${res.userId}`

    let currentPayload = localStorage.getItem(formatedTodayDate)

    if (currentPayload) {
      localStorage.setItem(
        formatedTodayDate,
        `${currentPayload},${JSON.stringify(payload)}`
      )
    } else {
      localStorage.setItem(formatedTodayDate, JSON.stringify(payload))
    }
  })

  // localStorage.setItem(`${new Date()}`, JSON.stringify(payload));
}

async function push(token, isOnlyPull = false) {
  let loggedUser = await (await getLoggedUser()).userId
  var mustPull = true
  for (let value of [
    'fazendas',
    'retiros',
    'protocolos',
    'lotes',
    'd0s',
    'dns',
    'iatfs',
    'fazendaUsuarios',
    'touros',
    'partidas',
  ]) {
    await database.action(async () => {
      const collection = database.collections.get(SYNC_OBJECT_TO_TABLE[value])
      const entityEntry = await database.collections
        .get('domain_values')
        .query(
          Q.on('domain_types', 'nome', 'entidade'),
          Q.where('valor', normalizeEntityName(value))
        )
        .fetch()
      const notSyncedQuery = Q.where('_status', Q.notEq('synced'))
      const toSync = await collection.query(notSyncedQuery).fetch()

      const updatedIds = _.compact(
        toSync.map((x) => x.syncStatus === 'updated' && x.id)
      )

      let toMarkAsSynced = []
      const toSyncSplit = _.chunk(toSync)

      var contentPayload = new Array()
      var entries = new Array()

      for (const toSyncPartition of toSyncSplit) {
        await Promise.all(
          toSyncPartition.map(async (entry) => {
            const verb = updatedIds.includes(entry.id) && !entry.priority
              ? 'PUT'
              : updatedIds.includes(entry.id) && entry.priority
                ? 'PRIORITY'
                : 'POST'

            contentPayload.push(
              await createPayloadFor(entry, value, true, verb)
            )

            entries.push(entry)
          })
        )
      }
      if (contentPayload.length > 0) {
        let payload = {
          tipoId: entityEntry[0].id,
          loggedUser,
          contents: contentPayload
        }

        //!isOnlyPull && await backupOnLocalStorage(payload)

        const dataSent = isOnlyPull ? true : await sendData(payload, token)

        if (dataSent) {
          log('Data sent. Marking as synced!')
          for (const item of entries) {
            toMarkAsSynced.push(item)
          }
        } else {
          mustPull = false
        }
      }

      // Remove possibly updated entries after process started (they will be synced in the next cycle)
      toMarkAsSynced = _.compact(
        await Promise.all(
          toMarkAsSynced.map(async (entry) => {
            const localEntry = await collection.find(entry.id)
            const entryUpdatedAt = utils.fromMoment(
              utils.toMoment(entry._raw.updated_at)
            )
            const localEntryUpdatedAt = utils.fromMoment(
              utils.toMoment(localEntry._raw.updated_at)
            )
            if (
              localEntry &&
              _.isNumber(entryUpdatedAt) &&
              _.isNumber(localEntryUpdatedAt) &&
              entryUpdatedAt - localEntryUpdatedAt >= 0
            ) {
              return entry
            } else {
              return null
            }
          })
        )
      )

      await database.batch(
        ...toMarkAsSynced.map((entry) => prepareMarkAsSynced(entry))
      )
    })
  }
  return mustPull
}

export async function downloadProofOfManagement({ values, text, batchIds }) {
  const loggedUser = (await getLoggedUser()).userId;
  let comprovante = '';

  const allValues = [
    'fazendas',
    'retiros',
    'protocolos',
  ].concat(values);

  // Função para lidar com a ação do banco de dados
  async function handleDatabaseAction(value) {
    const collection = database.collections.get(SYNC_OBJECT_TO_TABLE[value]);
    const entityEntry = await database.collections
      .get('domain_values')
      .query(
        Q.on('domain_types', 'nome', 'entidade'),
        Q.where('valor', normalizeEntityName(value))
      )
      .fetch();

    const valuesInBatch = Q.or(
      Q.where('batch_id', Q.oneOf(batchIds)),
      Q.where('id', Q.oneOf(batchIds))
    );
    const notSyncedQuery = Q.where('_status', Q.notEq('synced'));
    const combinedQuery = Q.or(valuesInBatch, notSyncedQuery);

    const toSync = await collection.query(combinedQuery).fetch();
    const updatedIds = _.compact(toSync.map((x) => x.syncStatus === 'updated' && x.id));
    const toSyncSplit = _.chunk(toSync);

    let contentPayload = [];

    for (const toSyncPartition of toSyncSplit) {
      await Promise.all(
        toSyncPartition.map(async (entry) => {
          const verb = updatedIds.includes(entry.id) && !entry.priority
            ? 'PUT'
            : updatedIds.includes(entry.id) && entry.priority
              ? 'PRIORITY'
              : 'POST';
          const payload = await createPayloadFor(entry, value, true, verb);
          contentPayload.push(payload);
        })
      );
    }

    if (contentPayload.length > 0) {
      let payload = {
        tipoId: entityEntry[0].id,
        loggedUser,
        contents: contentPayload,
      };
      const str = JSON.stringify(payload);
      comprovante = comprovante !== '' ? `[${comprovante},${str}]` : str;
    }

    return comprovante;
  }

  // Loop through values and call the function
  for (let value of allValues) {
    comprovante = await handleDatabaseAction(value);
  }

  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(comprovante)
  );
  element.setAttribute('download', text);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}


// export async function baixarComprovante(text) {
//   let loggedUser = await (await getLoggedUser()).userId
//   let comprovante = ''
//   for (let value of [
//     'fazendas',
//     'retiros',
//     'protocolos',
//     'lotes',
//     'd0s',
//     'dns',
//     'iatfs',
//     'fazendaUsuarios',
//     'touros',
//     'partidas',
//   ]) {
//     await database.action(async () => {
//       const collection = database.collections.get(SYNC_OBJECT_TO_TABLE[value])
//       const entityEntry = await database.collections
//         .get('domain_values')
//         .query(
//           Q.on('domain_types', 'nome', 'entidade'),
//           Q.where('valor', normalizeEntityName(value))
//         )
//         .fetch()
//       const notSyncedQuery = Q.where('_status', Q.notEq('synced'))

//       const toSync = await collection.query(notSyncedQuery).fetch()

//       const updatedIds = _.compact(
//         toSync.map((x) => x.syncStatus === 'updated' && x.id)
//       )

//       const toSyncSplit = _.chunk(toSync)

//       var contentPayload = new Array()
//       var entries = new Array()

//       for (const toSyncPartition of toSyncSplit) {
//         await Promise.all(
//           toSyncPartition.map(async (entry) => {
//             const verb = updatedIds.includes(entry.id) && !entry.priority
//               ? 'PUT'
//               : updatedIds.includes(entry.id) && entry.priority
//                 ? 'PRIORITY'
//                 : 'POST'

//             contentPayload.push(
//               await createPayloadFor(entry, value, true, verb)
//             )

//             entries.push(entry)
//           })
//         )
//       }

//       if (contentPayload.length > 0) {
//         let payload = {
//           tipoId: entityEntry[0].id,
//           loggedUser,
//           contents: contentPayload,
//         }

//         const str = JSON.stringify(payload)
//         comprovante != '' ? comprovante = `[${comprovante},${str}]` : comprovante = str

//         //!isOnlyPull && await backupOnLocalStorage(payload)
//       } else {
//         return
//       }
//     })
//   }
//   var element = document.createElement('a')
//   element.setAttribute(
//     'href',
//     'data:text/plain;charset=utf-8,' + encodeURIComponent(comprovante)
//   )
//   if (text) {
//     element.setAttribute('download', text)
//   } else {
//     element.setAttribute('download', 'backup.progerar')
//   }

//   element.style.display = 'none'
//   document.body.appendChild(element)

//   element.click()

//   document.body.removeChild(element)
// }

export async function sendData(data, token, isRetry = false) {
  let params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  let url = `${SYNC_URLS.PUSH}`

  // if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
  //   url = `${SYNC_URLS.PUSH_GATEWAY_VERITY}`
  // }

  return timeoutFetch(url, params)
    .catch((err) => {
      let message = ''
      if (err instanceof TimeoutError) {
        message = 'Timeout trying to send changes. Will try again in next cycle'
        warnPush(message)
      } else {
        message = `There was an error trying to send sync object: ${error}`
        warnPush(message)
      }
      return false
    })
    .then(async (response) => {

      if (!response) {
        if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
          return sendData(data, token, true)
        }
        warnPush(`There was an error trying to send sync object`)
        return null
      }
      if (response.status === 401) {
        warnPush(`There was an error trying to send sync object`)
        const dataToken = await refreshToken()

        if (!_.isEmpty(dataToken) && dataToken.refreshed && !isRetry) {
          return sendData(data, token, true)
        }
      }
      if (response.status >= 400 && response.status !== 401) {
        warnPush(`Received status: ${response.status}`)
        if (REACT_APP_TOGGLE_GATEWAY_VERITY && !isRetry) {
          return sendData(data, token, true)
        }
      } else if (response.status >= 200 && response.status <= 299) {
        return true
      }
      return false
    })
}

//TODO: Check if we can manually modify updatedAt
//TODO: Solve Delete
async function pullChanges(syncObject, isOnlyBatch = false) {
  let values = []

  if (isOnlyBatch) {
    values = ['d0s', 'dns', 'iatfs', 'lotes']
  } else {
    values = [
      'usuarios',
      'tipoDominios',
      'valorDominios',
      'fazendas',
      'retiros',
      'protocolos',
      'lotes',
      'd0s',
      'dns',
      'iatfs',
      'fazendaUsuarios',
      'touros',
      'partidas',
    ]
  }
  for (const value of values) {
    await database.action(async () => {
      const collection = database.collections.get(isOnlyBatch
        ? SYNC_OBJECT_TO_TABLE_ONLY_BATCH[value]
        : SYNC_OBJECT_TO_TABLE[value]
      )
      const existingEntries = await collection
        .query(
          Q.where('id', Q.oneOf(syncObject[value].map((element) => element.id)))
        )
        .fetch()

      const toInsert = _.filter(syncObject[value], function (element) {
        return !_.find(existingEntries, { id: element.id })
      })

      const syncedEntries = _.filter(
        existingEntries,
        (entry) => entry._raw._status === 'synced'
      )

      await database.batch(
        ...existingEntries
          .filter(
            (entry) =>
              utils.fromMoment(
                utils.toMoment(
                  _.find(syncObject[value], { id: entry.id }).updatedAt
                )
              ) > utils.fromMoment(utils.toMoment(entry._raw.updated_at))
          )
          .map((entry) =>
            entry.prepareUpdate((entry) => {
              fromDTO(
                AVAILABLE_DTO_MAPPINGS[
                toDTOMapping(SYNC_OBJECT_TO_TABLE[value])
                ],
                _.find(syncObject[value], (val) => val.id === entry.id),
                entry,
                true
              )
            })
          ),
        ...toInsert.map((entry) =>
          collection.prepareCreate((newEntry) => {
            newEntry._raw.id = entry.id
            newEntry._raw._status = 'synced'
            newEntry._raw._changed = ''
            fromDTO(
              AVAILABLE_DTO_MAPPINGS[toDTOMapping(SYNC_OBJECT_TO_TABLE[value])],
              entry,
              newEntry,
              true
            )
          })
        )
      )

      await database.batch(
        ...syncedEntries.map((entry) => prepareMarkAsSynced(entry))
      )

      if (value === 'protocolos') {
        const managementProtocolsObject = _.flattenDeep(
          syncObject[value].map((protocol) => {
            _.each(protocol.managementProtocols, function (element) {
              element.managementProtocolId =
                element.managementProtocolId || protocol.id
            })
            return protocol.managementProtocols
          })
        )

        // Solve ManagementProtocols
        const managementCollection = database.collections.get(
          'management_protocols'
        )

        const existingManagementEntries = await managementCollection
          .query(
            Q.where(
              'id',
              Q.oneOf(managementProtocolsObject.map((entry) => entry.id))
            )
          )
          .fetch()

        const toInsertManagementEntries = _.filter(
          managementProtocolsObject,
          function (element) {
            return !_.find(existingManagementEntries, { id: element.id })
          }
        )

        const syncedManagementEntries = _.filter(
          existingManagementEntries,
          (entry) => entry._raw._status === 'synced'
        )

        await database.batch(
          ...existingManagementEntries
            .filter(
              (entry) =>
                utils.fromMoment(
                  utils.toMoment(
                    _.find(managementProtocolsObject, { id: entry.id })
                      .updatedAt
                  )
                ) > utils.fromMoment(utils.toMoment(entry._raw_updated_at))
            )
            .map((entry) =>
              entry.prepareUpdate((entry) =>
                fromDTO(
                  AVAILABLE_DTO_MAPPINGS.MANAGEMENT_PROTOCOLS,
                  _.find(managementProtocolsObject, { id: entry.id }),
                  entry,
                  true
                )
              )
            ),
          ...toInsertManagementEntries.map((entry) =>
            managementCollection.prepareCreate((newEntry) => {
              newEntry._raw.id = entry.id
              newEntry._raw._status = 'synced'
              fromDTO(
                AVAILABLE_DTO_MAPPINGS.MANAGEMENT_PROTOCOLS,
                entry,
                newEntry,
                true
              )
            })
          )
        )

        //TODO: There's an edge case here, when entry was updated after it was fetched a couple of lines above
        await database.batch(
          ...syncedManagementEntries.map((entry) => prepareMarkAsSynced(entry))
        )
      }

      if (value === 'usuarios') {
        const rolesCollection = database.collections.get('roles')
        const rolesObject = _.flattenDeep(
          syncObject[value].map((user) =>
            user.roles.map((element) => ({
              backend_id: element.id,
              name: element.name,
              description: element.description,
              userId: user.id,
            }))
          )
        )

        const existingRolesEntries = await rolesCollection
          .query(
            Q.where(
              'user_id',
              Q.oneOf(_.uniq(rolesObject.map((entry) => entry.userId)))
            )
          )
          .fetch()

        const syncedRolesEntries = _.filter(
          existingRolesEntries,
          (entry) => entry._raw._status === 'synced'
        )

        const rolesToInsert = _.filter(
          rolesObject,
          (element) =>
            !_.find(existingRolesEntries, {
              backend_id: element.backend_id,
              '_raw.user_id': element.userId,
            })
        )

        let rolesToUpdate = []
        let rolesToDelete = []

        _.each(existingRolesEntries, (entry) => {
          if (
            _.find(rolesObject, {
              backend_id: entry.backend_id,
              userId: entry._raw.user_id,
            })
          ) {
            rolesToUpdate.push(entry)
          } else {
            rolesToDelete.push(entry)
          }
        })

        await database.batch(
          ...rolesToUpdate.map((entry) =>
            entry.prepareUpdate((entry) =>
              fromDTO(
                AVAILABLE_DTO_MAPPINGS.ROLES,
                _.find(rolesObject, {
                  backend_id: entry.backend_id,
                  userId: entry._raw.user_id,
                }),
                entry,
                true
              )
            )
          ),
          ...rolesToInsert.map((entry) =>
            rolesCollection.prepareCreate((newEntry) => {
              newEntry._raw.id = uuid()
              newEntry._raw._status = 'synced'
              fromDTO(AVAILABLE_DTO_MAPPINGS.ROLES, entry, newEntry, true)
            })
          ),
          ...rolesToDelete.map((entry) =>
            entry.prepareUpdate((entry) => (entry.isDeleted = true))
          )
        )

        //TODO: There's an edge case here, when entry was updated after it was fetched a couple of lines above
        await database.batch(
          ...syncedRolesEntries.map((entry) => prepareMarkAsSynced(entry))
        )
      }
    })
  }
}

//TODO: Find a better way to define these parameters (besides hardcoding them)
// async function dbDomainValueIDLookup(
//   reference,
//   domainValue,
//   useParentID = false
// ) {
//   const collection = database.collections.get('domain_values')
//   const entries = await collection
//     .query(
//       useParentID
//         ? Q.on('domain_values', 'valor', reference)
//         : Q.on('domain_types', 'nome', reference),
//       Q.where('valor', domainValue)
//     )
//     .fetch()
//   if (entries && entries.length > 0) {
//     return entries[0].id
//   }
//   return null
// }

export async function refreshApplication() {
  let now = new Date()
  let lastRefresh = localStorage.getItem('Last Refresh')
  let cooldown = 60
  let loggedUserInfo = await getLoggedUser()

  if (lastRefresh) {
    let minuteDiference = Math.ceil(
      Math.abs(new Date(lastRefresh) - now) / 60000
    )
    if (minuteDiference >= cooldown) {
      await pull(loggedUserInfo, false, true).then((res) => {
        localStorage.setItem('Last Refresh', now)
        window.location.reload(true)
      })
      return
    } else {
      return `Você só pode forçar a sincronização a cada uma hora, faltam ${cooldown - minuteDiference
        } minutos.`
    }
  } else {
    await pull(loggedUserInfo, false, true).then((res) => {
      localStorage.setItem('Last Refresh', now)
      window.location.reload(true)
      return
    })
  }
}

export async function downloadBackup(text = null, selectedEstacaoDeMonta) {
  const zip = new JSZip();
  const farmsRepository = new Repository(AVAILABLE_ENTITIES.FARMS)
  const downloadFarms = JSON.parse(localStorage.getItem('downloadFarms'))
  const { response: farms } = !!selectedEstacaoDeMonta
    ? await farmsRepository.getByParam('estacaoMonta_id', selectedEstacaoDeMonta)
    : await farmsRepository.get()

  const filtedFarms = farms.filter((farm) => {
    return downloadFarms.some((df) => df.id === farm.id)
  })

  const entitiesToFarm = [
    'fazendas',
    'retiros',
    'lotes',
    'd0s',
    'dns',
    'iatfs',
    'fazendaUsuarios',
  ]

  const ortherEntities = [
    'protocolos',
    'touros',
    'partidas',
  ]

  for (const farm of filtedFarms) {
    const fazendaFolder = zip.folder(`backup_fazenda_${farm.nome.replaceAll("/", "_")}`); // Cria uma pasta para cada fazenda
    const ortherThings = zip.folder(`Outros`);

    for (const entitie of entitiesToFarm) {
      const payload = await mountPayloadDBToFarm(entitie, farm.id); // Função que retorna os dados de uma entidade para uma fazenda específica
      const str = JSON.stringify(payload);
      fazendaFolder.file(`${entitie}.progerar`, str); // Adiciona o arquivo JSON para cada entidade na pasta da fazenda
    }

    for (const ortherEntitie of ortherEntities) {
      const payload = await mountPayloadDB(ortherEntitie); // Função que retorna os dados de uma entidade
      const str = JSON.stringify(payload);
      ortherThings.file(`${ortherEntitie}.progerar`, str); // Adiciona o arquivo JSON para cada entidade na pasta da fazenda
    }
  }

  const zipContent = await zip.generateAsync({ type: 'blob' });
  saveAs(zipContent, text || 'backup.zip');
}

export async function exportDb(value) {
  var payload = await mountPayloadDB(value)

  if (payload) {
    const str = JSON.stringify(payload)
    const bytes = new TextEncoder().encode(str)
    const blob = new Blob([bytes], {
      type: 'application/json;charset=utf-8',
    })

    const newHandle = await window.showSaveFilePicker()
    const writableStream = await newHandle.createWritable()
    await writableStream.write(blob)
    await writableStream.close()
  } else {
    alert('Não há dados a ser exportados')
  }
}

export async function mountPayloadDBToFarm(value, farmId) {
  let loggedUser = await (await getLoggedUser()).userId
  var contentPayload = []
  var entries = []

  const corralsRepository = new Repository(AVAILABLE_ENTITIES.CORRALS)
  const { response: corrals } = await corralsRepository.getByParam(
    'farm_id',
    farmId
  )
  const batchesRepository = new Repository(AVAILABLE_ENTITIES.BATCHES)
  const { response: batches } = await batchesRepository.getByParam(
    'corral_id',
    Q.oneOf(corrals.map((corral) => corral.id))
  )

  const collection = database.collections.get(SYNC_OBJECT_TO_TABLE[value]);
  const entityEntry = await database.collections
    .get('domain_values')
    .query(
      Q.on('domain_types', 'nome', 'entidade'),
      Q.where('valor', normalizeEntityName(value))
    )
    .fetch();

  const valuesToFarm = Q.or(
    Q.where('id', Q.eq(farmId)),
    Q.where('farm_id', Q.eq(farmId)),
    Q.where('id', Q.oneOf(corrals.map((corral) => corral.id))),
    Q.where('corral_id', Q.oneOf(corrals.map((corral) => corral.id))),
    Q.where('id', Q.oneOf(batches.map((batch) => batch.id))),
    Q.where('batch_id', Q.oneOf(batches.map((batch) => batch.id))),
  );
  const deletedIsNotTrue = Q.where('isDeleted', Q.notEq(true));
  const combinedQuery = Q.and(valuesToFarm, deletedIsNotTrue);


  const result = await collection.query(combinedQuery).fetch()

  const toSyncSplit = _.chunk(result)

  for (const toSyncPartition of toSyncSplit) {
    await Promise.all(
      toSyncPartition.map(async (entry) => {
        const verb = 'POST'

        contentPayload.push(await createPayloadFor(entry, value, true, verb))

        entries.push(entry)
      })
    )
  }

  if (contentPayload.length > 0) {
    let payload = {
      tipoId: entityEntry[0].id,
      loggedUser,
      contents: contentPayload,
    }
    return payload
  } else {
    return null
  }
}

export async function mountPayloadDB(value) {
  let loggedUser = await (await getLoggedUser()).userId
  var contentPayload = []
  var entries = []

  const entityEntry = await database.collections
    .get('domain_values')
    .query(
      Q.on('domain_types', 'nome', 'entidade'),
      Q.where('valor', normalizeEntityName(value))
    )
    .fetch();

  const result = await database.collections
    .get(SYNC_OBJECT_TO_TABLE[value])
    .query(Q.where('id', Q.like('%-%')))
    .fetch()

  const toSyncSplit = _.chunk(result)

  for (const toSyncPartition of toSyncSplit) {
    await Promise.all(
      toSyncPartition.map(async (entry) => {
        const verb = 'POST'

        contentPayload.push(await createPayloadFor(entry, value, true, verb))

        entries.push(entry)
      })
    )
  }

  if (contentPayload.length > 0) {
    let payload = {
      tipoId: entityEntry[0].id,
      loggedUser,
      contents: contentPayload,
    }
    return payload
  } else {
    return null
  }
}
