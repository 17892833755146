import * as CONSTANTS from './constants'


const initialState = {
  farm_batches: null,
  selected: null,
}

export default function reduce (state = initialState, action) {
    switch (action.type) {
        case CONSTANTS.LIST_FARM_BACTHES:
            return {
                ...state,
                farm_batches: action.data
            }
        case CONSTANTS.SELECT_BATCH:
            return {
                ...state,
                selected: action.data
            };
        default:
            return state;
    }
}