/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { AspectRatioRounded } from '@material-ui/icons'
import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'



const TourButton = ({ tour, config, openWhenEnterInPage }) => {
  const buttonStyle = {
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: '1px solid #ea5d0d',
    position: 'fixed',
    bottom: '76px',
    right: '20px',
    zIndex: 1000,
  }

  const driverObj = driver({
    showProgress: true,
    doneBtnText: 'Fechar',
    closeBtnText: 'Fechar',
    nextBtnText: 'Próximo',
    prevBtnText: 'Anterior',
    progressText: '{{current}} de {{total}}',
    ...config,
    steps: tour,
  })

  useEffect(() => {
    if (openWhenEnterInPage) {
      driverObj.drive()
    }
  }, [openWhenEnterInPage])

  return (
    <Tooltip title="Ver Tour" arrow>
      <IconButton
        onClick={() => driverObj.drive()}
        style={buttonStyle}
      >
        <AspectRatioRounded
          style={{ height: '1rem', width: '1rem', color: '#ea5d0d' }}
        />
      </IconButton>
    </Tooltip>
  )
}

export default TourButton
