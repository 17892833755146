import { Q } from '@nozbe/watermelondb'
import _ from 'lodash'

import { AVAILABLE_ENTITIES, utils } from '../database'
import { sortList } from './helper'
import { Batch, ComposedBatch } from '../models/batch.model'
import { Corral } from '../models/corral.model'
import { Farm } from '../models/farm.model'
import { ANIMAL_DIED } from './constants'

export async function getBatchesByFarm(
  farm: Farm,
  isAllCorrals?: boolean
): Promise<ComposedBatch[]> {
  const corrals: Corral[] = await utils.getWithParam(
    AVAILABLE_ENTITIES.CORRALS,
    'farm_id',
    farm.id
  )

  const corral: Corral = corrals[0]

  if (isAllCorrals) {
    return getBatchesByCorral(corrals)
  }
  return getBatchesByCorral(corral?.id)
}

export async function getBatchesByCorral(
  corralId: any
): Promise<ComposedBatch[]> {
  let batches: Batch[] = []

  if (typeof corralId === 'object') {
    batches = await utils.getWithParam(
      AVAILABLE_ENTITIES.BATCHES,
      'corral_id',
      Q.oneOf(corralId.map((corral: any) => corral.id))
    )
  } else {
    batches = await utils.getWithParam(
      AVAILABLE_ENTITIES.BATCHES,
      'corral_id',
      corralId
    )
  }

  let filteredBatches: Batch[] = _.filter(batches, (batch: any) =>
    _.isEmpty(batch.originalBatchId)
  )

  if (batches.length > 0) {
    const days = await utils.getDomainValuesBy('Dias')
    const tipoResync = await utils.getDomainValuesBy('Tipo de Ressincronização')
    const manejos = await utils.getDomainValuesBy('Tipo de Manejo')
    const iatf = _.find(manejos, { valor: 'IATF' })

    const superPrecoce = _.find(tipoResync, { valor: 'Super Precoce' }).id
    const precoce = _.find(tipoResync, { valor: 'Precoce' }).id

    filteredBatches = await Promise.all(
      filteredBatches.map(async (batch: any) => {
        const relatedBatches = _.filter(
          batches,
          (candidate: any) => candidate.originalBatchId === batch.id
        )

        let batchList = [batch]
        let next = _.find(relatedBatches, {
          parentBatchId: _.last(batchList).id,
        })
        while (next) {
          batchList.push(next)
          next = _.find(relatedBatches, { parentBatchId: next.id })
        }

        let currentBatch: Batch = _.last(batchList)

        const isPrecoce =
          currentBatch.tipoResync === superPrecoce ||
          currentBatch.tipoResync === precoce

        const protocol = await utils.getElement(
          AVAILABLE_ENTITIES.PROTOCOLS,
          currentBatch.protocoloId
        )
        if (!protocol) {
          return batch
        }
        const protocolDays: any[] = protocol
          ? _.sortBy(
              _.uniq(
                protocol.managementProtocols.map((managementProtocol: any) =>
                  _.find(days, { id: managementProtocol.day })
                )
              ),
              [
                (dia: any) =>
                  dia.valor.startsWith('D')
                    ? parseInt(dia.valor.substring(1))
                    : 100,
              ]
            )
          : []

        const lastDoneDay = _.find(days, { id: currentBatch.dias })
        const lastDoneDayHasIatf =
          lastDoneDay &&
          _.find(
            _.filter(protocol.managementProtocols, { day: lastDoneDay.id }),
            {
              managementId: iatf.id,
            }
          )
        const currentDay: any =
          protocolDays[_.findIndex(protocolDays, { id: currentBatch.dias }) + 1]
        const isLastDay =
          currentDay && currentDay.id === _.last(protocolDays).id
        const hasIatf =
          currentDay &&
          _.find(
            _.filter(protocol.managementProtocols, { day: currentDay.id }),
            {
              managementId: iatf.id,
            }
          )
        const vacas = await utils.getWithParam(
          AVAILABLE_ENTITIES.D0S,
          'batch_id',
          currentBatch.id
        )
        let vacasDG = _.filter(
          await utils.getWithParam(
            AVAILABLE_ENTITIES.D0S,
            'batch_id',
            currentBatch.id
          ),
          (vaca: any) => !vaca.omit_from_dg_final
        )
        let currentIatf = -1
        let pastDG = false
        let pastDGFinal = false
        let countCowsWithDG = 0
        let countCowsWithDGFinal = 0
        _.forEach(vacas, (vaca: any) => {
          if (vaca.iatf > currentIatf) {
            currentIatf = vaca.iatf
          }

          if (
            (vaca.condicaoDG !== null && vaca.condicaoDG.length > 0) ||
            (vaca.observacaoDG !== null &&
              (vaca.observacaoDG.length > 0 || vaca.observacaoDG === '[N/A]'))
          ) {
            countCowsWithDG++
          }
          if (
            (vaca.condicaoDGFinal !== null &&
              vaca.condicaoDGFinal.length > 0) ||
            (vaca.observacao_DG_Final !== null &&
              (vaca.observacao_DG_Final.length > 0 ||
                vaca.observacao_DG_Final === '[N/A]'))
          ) {
            countCowsWithDGFinal++
          }
        })

        if (
          countCowsWithDG ===
            vacasDG.filter((item) => item.observacaoD0 !== ANIMAL_DIED)
              .length &&
          vacasDG.length !== 0 &&
          currentBatch.isFinalize
        ) {
          pastDG = true
        }
        if (countCowsWithDGFinal === vacasDG.length && vacasDG.length !== 0) {
          pastDGFinal = true
        }

        let parentPastDG = false
        let countCowsWithParentDG = 0
        if (currentBatch.parentBatchId !== null) {
          const previousCows = _.filter(
            await utils.getWithParam(
              AVAILABLE_ENTITIES.D0S,
              'batch_id',
              currentBatch.parentBatchId
            ),
            (vaca: any) => !vaca.omit_from_dg_final
          )
          _.forEach(previousCows, (vaca: any) => {
            if (
              (vaca.condicaoDG !== null && vaca.condicaoDG.length > 0) ||
              (vaca.observacaoDG !== null &&
                (vaca.observacaoDG.length > 0 || vaca.observacaoDG === '[N/A]'))
            ) {
              countCowsWithParentDG++
            }
            if (pastDG && pastDGFinal) {
              return false
            }
          })

          if (
            countCowsWithParentDG === previousCows.length &&
            previousCows.length !== 0 &&
            currentBatch.isFinalize
          ) {
            parentPastDG = true
          }
        }

        return {
          ...currentBatch,
          originalBatch: batch,
          isPrecoce,
          protocolName: protocol.name,
          nextDay: currentDay && currentDay.valor,
          lastDoneDay,
          lastDoneDayHasIatf,
          isLastDay,
          hasIatf,
          pastDG,
          pastDGFinal,
          parentPastDG,
          iatfCount: currentIatf === -1 ? '-' : currentIatf + 1,
        }
      })
    )
  }
  let batchesOrdenadas = sortList(filteredBatches, 'nomeLote')

  return batchesOrdenadas
}

export async function getBatchesByBatchIds(
  batchIds: any
): Promise<ComposedBatch[]> {
  let batches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'id',
    Q.oneOf(batchIds)
  )

  if (batches.length > 0) {
    const days = await utils.getDomainValuesBy('Dias')
    const tipoResync = await utils.getDomainValuesBy('Tipo de Ressincronização')
    const manejos = await utils.getDomainValuesBy('Tipo de Manejo')
    const iatf = _.find(manejos, { valor: 'IATF' })

    const superPrecoce = _.find(tipoResync, { valor: 'Super Precoce' }).id
    const precoce = _.find(tipoResync, { valor: 'Precoce' }).id

    batches = await Promise.all(
      batches.map(async (batch: any) => {
        const isPrecoce =
          batch.tipoResync === superPrecoce || batch.tipoResync === precoce

        const protocol = await utils.getElement(
          AVAILABLE_ENTITIES.PROTOCOLS,
          batch.protocoloId
        )
        if (!protocol) {
          return batch
        }

        const protocolDays: any[] = protocol
          ? _.sortBy(
              _.uniq(
                protocol.managementProtocols.map((managementProtocol: any) =>
                  _.find(days, { id: managementProtocol.day })
                )
              ),
              [
                (dia: any) =>
                  dia.valor.startsWith('D')
                    ? parseInt(dia.valor.substring(1))
                    : 100,
              ]
            )
          : []

        const lastDoneDay = _.find(days, { id: batch.dias })
        const lastDoneDayHasIatf =
          lastDoneDay &&
          _.find(
            _.filter(protocol.managementProtocols, { day: lastDoneDay.id }),
            { managementId: iatf.id }
          )

        const currentDay: any =
          protocolDays[_.findIndex(protocolDays, { id: batch.dias }) + 1]
        const isLastDay =
          currentDay && currentDay.id === _.last(protocolDays).id
        const hasIatf =
          currentDay &&
          _.find(
            _.filter(protocol.managementProtocols, { day: currentDay.id }),
            { managementId: iatf.id }
          )
        const vacas = await utils.getWithParam(
          AVAILABLE_ENTITIES.D0S,
          'batch_id',
          batch.id
        )
        let vacasDG = _.filter(
          await utils.getWithParam(
            AVAILABLE_ENTITIES.D0S,
            'batch_id',
            batch.id
          ),
          (vaca: any) => !vaca.omit_from_dg_final
        )
        let currentIatf = -1
        let pastDG = false
        let pastDGFinal = false
        let countCowsWithDG = 0
        let countCowsWithDGFinal = 0
        _.forEach(vacas, (vaca: any) => {
          if (vaca.iatf > currentIatf) {
            currentIatf = vaca.iatf
          }
          if (
            (vaca.condicaoDG !== null && vaca.condicaoDG.length > 0) ||
            (vaca.observacaoDG !== null &&
              (vaca.observacaoDG.length > 0 || vaca.observacaoDG === '[N/A]'))
          ) {
            countCowsWithDG++
          }
          if (
            (vaca.condicaoDGFinal !== null &&
              vaca.condicaoDGFinal.length > 0) ||
            (vaca.observacao_DG_Final !== null &&
              (vaca.observacao_DG_Final.length > 0 ||
                vaca.observacao_DG_Final === '[N/A]'))
          ) {
            countCowsWithDGFinal++
          }
        })

        if (
          countCowsWithDG ===
            vacasDG.filter((item) => item.observacaoD0 !== ANIMAL_DIED)
              .length &&
          vacasDG.length !== 0 &&
          batch.isFinalize
        ) {
          pastDG = true
        }
        if (countCowsWithDGFinal === vacasDG.length && vacasDG.length !== 0) {
          pastDGFinal = true
        }

        let parentPastDG = false
        let countCowsWithParentDG = 0
        if (batch.parentBatchId !== null) {
          const previousCows = await utils.getWithParam(
            AVAILABLE_ENTITIES.D0S,
            'batch_id',
            batch.parentBatchId
          )
          _.forEach(previousCows, (vaca: any) => {
            if (
              (vaca.condicaoDG !== null && vaca.condicaoDG.length > 0) ||
              (vaca.observacaoDG !== null &&
                (vaca.observacaoDG.length > 0 || vaca.observacaoDG === '[N/A]'))
            ) {
              countCowsWithParentDG++
            }
          })

          if (
            countCowsWithParentDG === previousCows.length &&
            previousCows.length !== 0 &&
            batch.isFinalize
          ) {
            parentPastDG = true
          }
        }

        return {
          ...batch,
          isPrecoce,
          protocolName: protocol.name,
          nextDay: currentDay && currentDay.valor,
          lastDoneDay,
          lastDoneDayHasIatf,
          isLastDay,
          hasIatf,
          pastDG,
          pastDGFinal,
          parentPastDG,
          iatfCount: currentIatf === -1 ? '-' : currentIatf + 1,
        }
      })
    )
    return batches
  }
  return []
}
