import * as serviceWorker from '../serviceWorker'

export default function clearCache(): void {
  // if ('caches' in window) {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name)
    })
    serviceWorker.unregister()
    serviceWorker.register()
  })

  setTimeout(function () {
    window.location.reload()
  }, 500)
  // }
}
