export const AUTH = 'AUTH'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGGED = 'USER_LOGGED'
export const USER_LOGOUT = 'USER_LOGOUT'
export const FORGOT_PASSWORD_SUCESS = 'FORGOT_PASSWORD_SUCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'
export const RESET_PASSWORD_SUCESS = 'RESET_PASSWORD_SUCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const RACAS_SUCESS = 'RACAS_SUCESS'
export const RACAS_FAIL = 'RACAS_FAIL'

export const LOGGED_USER_ID = 'LOGGED_USER_ID'
export const LOGGED_USER_TOKEN = 'LOGGED_USER_TOKEN'
export const LOGGED_USER_ROLES = 'LOGGED_USER_ROLES'
export const LOGGED_USER_PASS = 'LOGGED_USER_PASS'
export const LOGGED_USER_SESSION_ID = 'LOGGED_USER_SESSION_ID'
export const LOGGED_USER_GIGYA = 'LOGGED_USER_GIGYA'
export const LOGGED_USER_NEW_PARTIDA = 'LOGGED_USER_NEW_PARTIDA'

export const SET_SHOULD_ACCEPT_EULA = 'SET_SHOULD_ACCEPT_EULA'
export const DISMISS_SHOULD_ACCEPT_EULA = 'DISMISS_SHOULD_ACCEPT_EULA'
