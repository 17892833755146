import { ESTADOS } from './constants'
import _ from 'lodash'

export const NOT_NULL = 'NOT_NULL'

export const USUARIO = 'USUARIO'
export const FAZENDA = 'FAZENDA'
export const RETIRO = 'RETIRO'
export const LOTE = 'LOTE'

export const CONDITIONAL_REQUIRED_FIELD_TYPE = {
  DEPENDENT_ON_FIELD: 'DEPENDENT_ON_FIELD',
  ONE_OF_MANY: 'ONE_OF_MANY',
}

export const REQUIRED_FIELD_MESSAGES = {
  DEFAULT: 'Este campo é obrigatório',
  ONE_OF_MANY: 'Um desses campos precisa ser preenchido',
}

export const FIELD_TYPE = {
  DATE: 'DATE',
  DATE_RANGE: 'DATE_RANGE',
  USING_ID: 'USING_ID',
  DEFAULT: 'DEFAULT',
}

export function isDateNullOrValid(date) {
  if (!_.isEmpty(date)) {
    return date.isValid()
  }
  return true
}

export function requiredFieldsEvaluator() {
  let missingRequiredFields = []

  _.each(this.props.requiredFields, (field) => {
    if (this.state[field]) {
      if (this.state[field].isValid) {
        if (!this.state[field].isValid()) {
          missingRequiredFields.push(field)
        }
      } else {
        if (
          _.isEmpty(this.state[field]) &&
          _.isEmpty(this.state[`${field}_value`])
        ) {
          missingRequiredFields.push(field)
        }
      }
    } else if (
      this.props.openFields &&
      this.props.openFields.indexOf(field) >= 0
    ) {
      if (
        _.isEmpty(this.state[field]) &&
        _.isEmpty(this.state[`${field}_value`])
      ) {
        missingRequiredFields.push(field)
      }
    } else {
      missingRequiredFields.push(field)
    }
  })

  _.each(this.props.conditionalRequiredFields, (condition) => {
    switch (condition.type) {
      case CONDITIONAL_REQUIRED_FIELD_TYPE.DEPENDENT_ON_FIELD:
        const basicCondition =
          condition.value === NOT_NULL
            ? !_.isEmpty(this.state[condition.dependsOn])
            : condition.excludeValue
              ? this.state[condition.dependsOn] !== condition.value
              : this.state[condition.dependsOn] === condition.value

        if (basicCondition) {
          if (this.state[condition.field]) {
            if (this.state[condition.field].isValid) {
              if (this.state[condition.field].isValid()) {
                missingRequiredFields.push(condition.field)
              }
            } else if (
              _.isEmpty(this.state[condition.field]) &&
              _.isEmpty(this.state[`${condition.field}_value`])
            ) {
              missingRequiredFields.push(condition.field)
            }
          } else if (
            this.props.openFields &&
            this.props.openFields.indexOf(condition.field) >= 0
          ) {
            if (
              _.isEmpty(this.state[condition.field]) &&
              _.isEmpty(this.state[`${condition.field}_value`])
            ) {
              missingRequiredFields.push(condition.field)
            }
          } else {
            missingRequiredFields.push(condition.field)
          }
        }
        break
      case CONDITIONAL_REQUIRED_FIELD_TYPE.ONE_OF_MANY:
        let allEmpty = true
        _.each(condition.fields, (conditionalField) => {
          if (this.state[conditionalField]) {
            if (this.state[conditionalField].isValid) {
              if (this.state[conditionalField].isValid()) {
                allEmpty = false
              }
            } else if (
              !_.isEmpty(this.state[conditionalField]) ||
              !_.isEmpty(this.state[`${conditionalField}_value`])
            ) {
              allEmpty = false
            }
          } else if (
            this.props.openFields &&
            this.props.openFields.indexOf(conditionalField) >= 0
          ) {
            if (
              !_.isEmpty(this.state[conditionalField]) ||
              !_.isEmpty(this.state[`${conditionalField}_value`])
            ) {
              allEmpty = false
            }
          }
        })
        if (allEmpty) {
          missingRequiredFields = missingRequiredFields.concat(condition.fields)
        }
        break
      default:
        missingRequiredFields.push(condition.field)
    }
  })

  let newState = null

  if (missingRequiredFields.length > 0) {
    //console.warn(`There are some fields that are required!`)
    //console.warn(missingRequiredFields)
    newState = {
      ..._.reduce(
        missingRequiredFields,
        (accumulator, value) => ({
          ...accumulator,
          [`${value}_error`]: REQUIRED_FIELD_MESSAGES.DEFAULT,
        }),
        {}
      ),
    }
  }

  return newState
}

export function missingRequiredFieldChecker(
  field,
  value,
  type = FIELD_TYPE.DEFAULT
) {
  let isEmpty = false

  switch (type) {
    case FIELD_TYPE.DATE:
      isEmpty = !value || !value.isValid || !value.isValid()
      break
    case FIELD_TYPE.DATE_RANGE:
      isEmpty =
        value.length !== 2 ||
        !value[0] ||
        !value[0].isValid ||
        !value[0].isValid() ||
        !value[1] ||
        !value[1].isValid ||
        !value[1].isValid()
      break
    case FIELD_TYPE.USING_ID:
      isEmpty = _.isEmpty(value) || _.isEmpty(value.id)
      break
    default:
      isEmpty = _.isEmpty(value)
  }

  if (isEmpty) {
    if (
      this.props.requiredFields &&
      this.props.requiredFields.indexOf(field) >= 0
    ) {
      return true
    } else if (this.props.conditionalRequiredFields) {
      const condition = _.find(this.props.conditionalRequiredFields, {
        field,
      })
      if (condition) {
        switch (condition.type) {
          case CONDITIONAL_REQUIRED_FIELD_TYPE.ONE_OF_MANY:
            let allEmpty = true
            _.each(
              condition.fields.filter((f) => f !== field),
              (f) => {
                if (this.state[f]) {
                  if (this.state[f].isValid) {
                    if (this.state[f].isValid()) {
                      allEmpty = false
                    } else if (!_.isEmpty(this.state[f])) {
                      allEmpty = false
                    }
                  }
                }
              }
            )
            return allEmpty
          case CONDITIONAL_REQUIRED_FIELD_TYPE.DEPENDENT_ON_FIELD:
            return condition.excludeValue
              ? this.state[condition.dependsOn] !== condition.value
              : this.state[condition.dependsOn] === condition.value
          default:
            return true
        }
      }
    }
  }
  return false
}

// export const formatName = (name, type) => {
//   const ucFirstType = type.charAt(0).toUpperCase() + type.toLowerCase().slice(1)
//   return `${!name.toLowerCase().startsWith(type.toLowerCase()) ? `${ucFirstType} ` : ''
//     }${name}`
// }

export const formatName = (nameOrArrayToNames, type) => {
  const ucFirstType = type.charAt(0).toUpperCase() + type.toLowerCase().slice(1);

  // Transforma em array se for uma string
  const namesArray = Array.isArray(nameOrArrayToNames) ? nameOrArrayToNames : [nameOrArrayToNames];

  // Itera sobre cada nome e aplica a lógica de formatação
  const formattedNames = namesArray.map(name => {
    return `${!name.toLowerCase().startsWith(type.toLowerCase()) ? `${ucFirstType} ` : ''}${name}`;
  });

  // Retorna um único nome ou um array de nomes formatados
  return Array.isArray(nameOrArrayToNames) ? formattedNames : formattedNames[0];
}

export const findEstado = (estado) => {
  return _.find(ESTADOS, (e) => e.id === estado || e.name === estado)
}
