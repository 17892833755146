/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import * as D from './data'

const Accordion = ({ item, selectedVideo, setSelectedVideo }) => {
  const [expandedAccordion, setExpandedAccordion] = useState(null)
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const classes = D.useStyles()

  const selectedVideoStyle = {
    color: '#ea5d0d',
    textDecoration: 'underline',
  }

  const isVideoSelected = (video) =>
    video.linkMobile === selectedVideo || video.link === selectedVideo

  const handleAccordionChange = (accordionId) => (_, isExpanded) => {
    setExpandedAccordion(isExpanded ? accordionId : null)
  }

  const handleVideoClick = (videoLink) => {
    setSelectedVideo(videoLink)
  }

  useEffect(() => {
    // Find the accordion that should be expanded based on initiallySelectedVideo
    const matchingAccordion = D.itens.find((item) => {
      const matchingVideo = item.videos.find(
        (video) => isVideoSelected(video)
      )
      return matchingVideo !== undefined
    })

    setExpandedAccordion(matchingAccordion ? matchingAccordion.id : null)
  }, [selectedVideo])

  return (
    <MuiAccordion
      className={classes.accordion}
      expanded={expandedAccordion === item.id}
      onChange={handleAccordionChange(item.id)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h6'>{item.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ul>
          {item.videos.map((video) => (
            <li
              key={video.id}
              onClick={() =>
                handleVideoClick(isSmallScreen ? video.linkMobile : video.link)
              }
              style={isVideoSelected(video) ? selectedVideoStyle : {}}
            >
              {video.title}
            </li>
          ))}
        </ul>
      </AccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion
