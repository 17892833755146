import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import { FAZENDA, formatName } from './../../utils/formHelper'
import React, { Component } from 'react'

import Alert from '@material-ui/lab/Alert'
import Container from '@material-ui/core/Container'
import CorralForm from './components/CorralForm'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt'
import TitleBar from './../../components/TitleBar'
import TopBar from './../../components/TopBar'
import _ from 'lodash'
import { getLoggedUser } from '../../redux/auth/actions'
import track from 'react-tracking'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

@track(() => ({ page: 'Novo Retiro', date: new Date() }))
class CorralCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: null
    }

    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onCancel() {
    this.props.history.push(`/farms/${this.props.farm.id}`)
  }

  @track((props, state, corral) => ({ action: 'Criou um novo retiro', value: corral[0].nome }))
  async onSubmit(payload) {
    const repository = new Repository(AVAILABLE_ENTITIES.CORRALS)
    const result = await this.alredyExist(payload, repository)

    if (result.alredyExist) {
      window.scrollTo(0, 0)

      this.setState({
        errorMessage: result.message
      })

      return
    }

    const createReq = await repository.post(payload)
    if (createReq.success) {
      console.log('Corral created successfully')
      this.props.history.push(`/farms/${this.props.farm.id}`)
    } else {
      console.log(
        `There was an error trying to create farm: ${createReq.exception}`
      )
    }
  }

  async alredyExist(payload, repository) {
    let corrals = await repository.getByParam('farm_id', this.props.farm.id)
    let verifyCorrals = false

    _.each(corrals.response, corral => {
      if (corral.nome === payload.nome) {
        verifyCorrals = true
      }
    })

    if (verifyCorrals) {
      return {
        alredyExist: true,
        message: 'Já existe um retiro com esse nome!'
      }
    }
    return { alredyExist: false, message: null }
  }

  getPaths() {
    return [{
      route: `/farms/${this.props.farm.id}`,
      label: formatName(this.props.farm.nome, FAZENDA)
    },
    {
      label: 'Novo Retiro'
    }]
  }

  render() {
    const initialValues = {
      nome: null
    }
    const { errorMessage } = this.state
    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar title='Novo Retiro' paths={this.getPaths()} />
        <Prompt
          visible={verify()}
          title='Página duplicada!'
          message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
          buttons={[
            {
              autoFocus: false
            },
          ]}
        />
        <MainContainer>
          {errorMessage && (
            <Alert
              severity='error'
              style={{
                width: '100%',
                marginTop: '1em'
              }}
            >
              {errorMessage}
            </Alert>
          )}

          <CorralForm
            initialValues={initialValues}
            farm={this.props.farm}
            onCancel={this.onCancel}
            onSubmit={this.onSubmit}
            user={this.props.user}
          />
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async props => ({
  farm: await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    props.match.params.farmId
  ),
  user: await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    (
      await getLoggedUser()
    ).userId
  )
})

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(CorralCreate)
)
