import * as CONSTANTS from './constants'

const initialState = {
  currentSync: 1
}

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.CHANGE_CURRENT_SYNC_VIEW:
      return {
        ...state,
        currentSync: action.payload
      }
    default:
      return state
  }
}
