import { date, field, readonly, relation } from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'
import _ from 'lodash'

export const modelToDTOMapping = {
  identical: ['nome', 'observacao', 'Cod_Touro'],
  renamed: [
    {
      dtoName: 'partida',
      modelName: 'partida_at',
      isDate: true
    },
    {
      dtoName: 'centralValue',
      modelName: 'central_value'
    }
  ],
  references: [
    {
      dtoName: 'raca',
      modelName: 'raca_id'
    },
    {
      dtoName: 'central',
      modelName: 'central_id'
    },
    {
      dtoName: 'fazendaId',
      modelName: 'farm_id'
    },
    {
      dtoName: 'usuarioId',
      modelName: 'usuario_id'
    },
    {
      dtoName: 'partidaOriginalId',
      modelName: 'partida_original_id'
    },
    {
      dtoName: 'codPartida',
      modelName: 'cod_partida'
    },
    {
      dtoName: 'touroOriginalId',
      modelName: 'touro_original_id'
    }
  ]
}

export default class Bulls extends Model {
  static table = 'bulls'

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('nome') nome
  @field('Cod_Touro') Cod_Touro
  @field('observacao') observacao
  @date('partida_at') partida
  @field('central_value') centralValue
  @field('cod_partida') codPartida
  @field('usuario_id') usuarioId
  @field('touro_original_id') touroOriginalId
  @field('partida_original_id') partidaOriginalId

  //Foreign Relations - Domain Values
  @relation('domain_values', 'raca_id') raca
  @relation('domain_values', 'central_id') central

  //Foreign Relations
  @relation('farms', 'farm_id') farm
}
