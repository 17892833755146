export const LIST_FARMS = 'LIST_FARMS';
export const SELECT_FARM = 'SELECT_FARM';
export const LIST_BATCHES = 'LIST_BATCHES';
export const LIST_RACAS_MAT = 'LIST_RACAS_MAT';
export const LIST_ORDEM = 'LIST_ORDEM';
export const LIST_RACAS_BEZ = 'LIST_RACAS_BEZ';
export const LIST_SEXO_BEZ = 'LIST_SEXO_BEZ';
export const LIST_IDADE_MAT = 'LIST_IDADE_MAT';
export const LIST_DEVICE = 'LIST_DEVICE';
export const SELECT_BATCH = 'SELECT_BATCH';
export const LIST_PROTOCOLS = 'LIST_PROTOCOLS';
export const LIST_TOUROS = 'LIST_TOUROS';
