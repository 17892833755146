/* eslint-disable use-isnan */
/* eslint-disable array-callback-return */

import * as sync from '../../../src/syncWorker'

import {
  ANIMAL_DIED,
  ANIMAL_NOT_PRESENT_TO_MANAGEMENT,
} from '../../utils/constants'
import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import Autocomplete, {
  autocompleteDomainValueOptionLabelSavingID,
} from '../../components/material/Autocomplete'
import { Container, Grid } from '@material-ui/core'
import {
  FAZENDA,
  LOTE,
  REQUIRED_FIELD_MESSAGES,
  RETIRO,
  formatName,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from '../../utils/formHelper'
import React, { Component, Fragment } from 'react'
import ResponsiveTable, { CELL_TYPES } from '../../components/ResponsiveTable'

import Button from '../../components/material/Button'
import DoneIcon from '@material-ui/icons/Done'
import MainContainer from '../../components/MainContainer'
import MicIcon from '@material-ui/icons/Mic'
import Prompt from '../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import SpeechRecognition from '../../components/SpeechRecognition'
import TextField from '../../components/material/TextField'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { getBatchesByBatchIds } from '../../utils/batches'
import { getLoggedUser } from '../../redux/auth/actions'
import moment from 'moment'
import { sortList } from '../../utils/helper'
import track from 'react-tracking'
import { verify } from '../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

const obsOptions = [ANIMAL_NOT_PRESENT_TO_MANAGEMENT, ANIMAL_DIED]

@track(() => ({ page: 'D0/Resync', date: new Date() }))
class D0Resync extends Component {
  constructor(props) {
    super(props)

    const codVacas = _.reduce(
      props.vacas,
      (obj, vaca) => ({
        ...obj,
        [vaca.id]: vaca.codVaca,
      }),
      {}
    )
    const codLeitorBastao = _.reduce(
      props.vacas,
      (obj, vaca) => ({
        ...obj,
        [vaca.id]: vaca.codLeitorBastao,
      }),
      {}
    )

    this.state = {
      ..._.reduce(
        props.vacas,
        (obj, vaca) => ({
          ...obj,
          [vaca.id]: {
            ...vaca,
            dispositivoIntravaginal:
              vaca.dispositivoIntravaginal || props.initialValues.implantId,
          },
        }),
        {}
      ),
      codVacas,
      codLeitorBastao,
      selectedVacas: _.keys(codVacas),
      savedCows: [],
      usoForAll: null,
      showingFinalizeConfirmation: false,
      confirmedBackupDownload: true,
      showingNoBackupConfirmation: false,
      saveAllConfirm: false,
      searchValue: '',
    }

    props.vacas.map(
      (v) =>
        v.dispositivoIntravaginal != null && this.state.savedCows.push(v.id)
    )

    this.handleSearch = this.handleSearch.bind(this)
    this.saveCow = this.saveCow.bind(this)
    this.finalize = this.finalize.bind(this)

    this.handleManejosHeader = this.handleManejosHeader.bind(this)
    this.renderField = this.renderField.bind(this)
    this.renderCowLabel = this.renderCowLabel.bind(this)

    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)

    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)

    this.customAutocompleteHandler = this.customAutocompleteHandler.bind(this)

    this.renderTitle = this.renderTitle.bind(this)
  }

  customAutocompleteHandler(field, cow, required = false) {
    return (e, value) => {
      this.setState({
        [cow.id]: {
          ...cow,
          [field]: value && value.id,
          [`${field}_error`]: required
            ? _.isEmpty(value) || _.isEmpty(value.id)
              ? REQUIRED_FIELD_MESSAGES.DEFAULT
              : null
            : null,
        },
        savedCows: this.state.savedCows.filter((element) => element !== cow.id),
      })
    }
  }

  @track({ action: 'Finalizou o D0/Ressinc' })
  async finalize(downloadBackup) {
    let success = true

    const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)

    for (const batch of this.props.batches) {
      if (
        !batch.pastDGFinal &&
        (!batch.dias || batch.dias === '') &&
        batch.isResync &&
        batch.isPrecoce
      ) {
        const updateRequest = await repository.update({
          id: batch.id,
          dias: this.props.d0.id,
        })
        if (!updateRequest.success) {
          success = false
          console.log(
            `There was an error finalizing D0 for batch ${batch.id}: ${updateRequest.exception}`
          )
        }
      }
    }

    if (success) {
      console.log('D0 Resync Successfully ended')
      const uniqueCorrals = _.uniq(
        this.props.corrals.map((corral) => corral.id)
      )
      if (this.props.farm.retiro && uniqueCorrals.length === 1) {
        this.props.history.push(
          `/farms/${this.props.farm.id}/corrals/${uniqueCorrals[0]}`
        )
      } else {
        this.props.history.push(`/farms/${this.props.farm.id}`)
      }
    }

    sync.downloadProofOfManagement({
      values: ['lotes', 'd0s'],
      text: 'Comprovante de manejo D0 Ressinc - ' +
        formatName(this.props.batches.map((item) => item.nomeLote), LOTE) +
        ' - ' +
        formatName(this.props.farm.nome, FAZENDA) +
        ' - ' +
        moment.utc().valueOf() +
        '.progerar',
      batchIds: this.props.match.params.id.split(';')
    })
  }

  aplyForAll() {
    sortList(
      _.values(_.pick(this.state, this.state.selectedVacas)),
      'codVaca'
    ).map((row) => {
      if (this.state.savedCows.indexOf(row.id) < 0) {
        this.setState({
          [row.id]: {
            ...row,
            numero_Uso_Disp: this.state.usoForAll.id,
            numero_Uso_Disp_error:
              !_.isEmpty(this.state.usoForAll) &&
                !_.isEmpty(this.state.usoForAll.id)
                ? null
                : REQUIRED_FIELD_MESSAGES.DEFAULT,
          },
        })
      }
    })
  }

  async saveAll() {
    for (const cow of this.props.vacas) {
      if (this.state.savedCows.indexOf(cow.id) === -1) {
        await this.saveCow(cow.id)
      }
    }
    this.setState({ saveAllConfirm: false })
  }

  @track((props, state, cowId) => ({ action: 'Salvou a D0/Ressinc de uma matriz', value: cowId[0] }))
  async saveCow(id) {
    const cow = this.state[id]
    let success = true
    if (cow) {
      this.setState({
        savedCows: [...this.state.savedCows, cow.id],
      })
      let payload = _.pick(cow, [
        'id',
        'observacaoD0',
        'peso_EM',
        'ecc',
        'clD0',
        'dispositivoIntravaginal',
        'numero_Uso_Disp',
      ])

      payload.dataProcesso = moment.utc()

      let missingRequiredFields = false
      const requiredFields = ['dispositivoIntravaginal', 'numero_Uso_Disp']
      let state = {
        ..._.reduce(
          requiredFields,
          (accumulator, field) => {
            if (_.isEmpty(payload[field])) {
              missingRequiredFields = true
              return {
                ...accumulator,
                [`${field}_error`]: REQUIRED_FIELD_MESSAGES.DEFAULT,
              }
            }
            return accumulator
          },
          {}
        ),
      }

      if (missingRequiredFields) {
        this.setState({
          [cow.id]: {
            ...this.state[cow.id],
            ...state,
          },
          savedCows: this.state.savedCows.filter((x) => x !== cow.id),
        })
      } else {
        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const updateRequest = await repository.update(_.clone(payload))
        if (!updateRequest.success) {
          console.log(
            `There was a problem trying to update entry for cow ${cow.id}: ${updateRequest.exception}`
          )
          success = false
        }

        if (!success) {
          this.setState({
            [cow.id]: state,
            savedCows: this.state.savedCows.filter((x) => x !== cow.id),
          })
        }
      }
    }
    return success
  }

  onEnterPress = (id, e) => {
    if (this.props.user.roles[0].name !== 'Cliente (somente visualização)') {
      if (!(this.state.savedCows.indexOf(id) >= 0)) {
        if (e.key === 'Enter' && e.shiftKey === false) {
          e.preventDefault()
          this.saveCow(id)
        }
      }
    }
  }

  handleSearch(e) {
    const val = e.target.value
    const filtedCodVaca = _.isEmpty(val)
      ? _.keys(this.state.codVacas)
      : _.keys(
        _.reduce(
          this.state.codVacas,
          (result, value, key) => {
            return value?.toLowerCase().indexOf(val.toLowerCase()) >= 0
              ? {
                ...result,
                [key]: value,
              }
              : result
          },
          {}
        )
      )
    const filtedCodLeitorBastao = _.isEmpty(val)
      ? _.keys(this.state.codLeitorBastao)
      : _.keys(
        _.reduce(
          this.state.codLeitorBastao,
          (result, value, key) => {
            return value?.toLowerCase().indexOf(val.toLowerCase()) >= 0
              ? {
                ...result,
                [key]: value,
              }
              : result
          },
          {}
        )
      )
    const filtedAllData = filtedCodVaca.concat(filtedCodLeitorBastao)
    const selectedVacas = [...new Set(filtedAllData)]

    this.setState({
      selectedVacas,
      searchValue: selectedVacas.length > 0 ? e?.target?.value : '',
    })
  }

  handleManejosHeader() {
    return (
      <div className='grid d0-resync-grid-form'>
        <div className='grid-item p-12 t-6 d0-grid-form-top-info-left'>
          <h2>Manejos e Produtos</h2>
          <ul>
            <li>
              <strong>Dispositivo Intravaginal:</strong>{' '}
              {this.props.initialValues.implant}
            </li>
            <li>
              <strong>Estímulo Ovulatório:</strong>{' '}
              {this.props.initialValues.benzoato}
            </li>
            {!_.isEmpty(this.props.initialValues.luteolise) && (
              <li>
                <strong>Luteólise:</strong> {this.props.initialValues.luteolise}
              </li>
            )}
            {!_.isEmpty(this.props.initialValues.progesterona) && (
              <li>
                <strong>Progesterona Injetável:</strong>{' '}
                {this.props.initialValues.progesterona}
              </li>
            )}
          </ul>
        </div>

        <div
          className='grid-item p-12 t-6 p-display-flex d0-grid-form-top-info-right'
          style={{ padding: 0 }}
        >
          <h3 style={{ fontSize: 20 }}>
            TOTAL DE MATRIZES: {this.state.savedCows.length}/
            {this.props.vacas ? this.props.vacas.length : 0}
          </h3>
        </div>

        <div className='grid-item p-12 p-padding-vertical-1' style={{ margin: 0 }}>
          <hr />
        </div>
      </div>
    )
  }

  renderField(row, index) {
    return (
      <div className='grid d0-resync-grid-form'>
        <div className='grid-item p-12 d-4'>
          <Autocomplete
            id={`dispositivoIntravaginal_${index}`}
            options={this.props.implantes}
            value={this.state[row.id].dispositivoIntravaginal || null}
            onChange={this.customAutocompleteHandler(
              'dispositivoIntravaginal',
              row,
              true
            )}
            onKeyDown={
              this.state[row.id].dispositivoIntravaginal &&
              ((e) => this.onEnterPress(row.id, e))
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.implantes
            )}
            useDefaultOptionSelected
            label='Dispositivo Intravaginal'
            error={!_.isEmpty(this.state[row.id].dispositivoIntravaginal_error)}
            helperText={this.state[row.id].dispositivoIntravaginal_error}
          />
        </div>

        <div className='grid-item p-12 d-3'>
          <Autocomplete
            id={`numero_Uso_Disp_${index}`}
            options={this.props.usoImplantes}
            value={this.state[row.id].numero_Uso_Disp || null}
            onChange={this.customAutocompleteHandler(
              'numero_Uso_Disp',
              row,
              true
            )}
            onKeyDown={
              this.state[row.id].numero_Uso_Disp &&
              ((e) => this.onEnterPress(row.id, e))
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.usoImplantes
            )}
            useDefaultOptionSelected
            label='Uso'
            error={!_.isEmpty(this.state[row.id].numero_Uso_Disp_error)}
            helperText={this.state[row.id].numero_Uso_Disp_error}
          />
        </div>

        <div className='grid-item p-12 d-2'>
          <Autocomplete
            id={`ecc_${index}`}
            options={this.props.ecc}
            value={this.state[row.id].ecc || null}
            onChange={this.customAutocompleteHandler('ecc', row)}
            onKeyDown={
              this.state[row.id].ecc && ((e) => this.onEnterPress(row.id, e))
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.ecc
            )}
            useDefaultOptionSelected
            label='ECC'
            error={!_.isEmpty(this.state[row.id].ecc_error)}
            helperText={this.state[row.id].ecc_error}
          />
        </div>

        <div className='grid-item p-10 d-2'>
          <TextField
            id={`peso_EM_${index}`}
            label='Peso Inicial'
            type='number'
            onChange={(e) => {
              const value = e.target.value
              this.setState({
                [row.id]: {
                  ...row,
                  peso_EM: parseInt(value),
                },
                savedCows: this.state.savedCows.filter((cow) => cow !== row.id),
              })
            }}
            onKeyDown={(e) => this.onEnterPress(row.id, e)}
            value={this.state[row.id].peso_EM || null}
            error={!_.isEmpty(this.state[row.id].peso_EM_error)}
            helperText={this.state[row.id].peso_EM_error}
          />
        </div>

        <div className='grid-item p-2 d-1'>
          <Button
            secondary
            small
            style={{ height: '4.2em', width: '100%' }}
            onClick={async () => {
              try {
                const resposta = await SpeechRecognition()
                if (resposta !== null && resposta !== NaN)
                  this.setState({
                    [row.id]: {
                      ...row,
                      peso_EM: resposta,
                      peso_EM_error: null,
                    },
                  })
              } catch (e) {
                console.log(e)
              }
            }}
            label={<MicIcon />}
          />
        </div>

        <div className='grid-item p-12 d-4'>
          <Autocomplete
            id={`clD0_${index}`}
            label='Presença de CL'
            options={this.props.clD0}
            value={
              this.state[row.id].clD0 == null
                ? this.props.clD0.filter((c) => c.valor === 'Não Avaliado')[0]
                  .id
                : this.state[row.id].clD0
            }
            onChange={this.customAutocompleteHandler('clD0', row)}
            onKeyDown={
              this.state[row.id].clD0 && ((e) => this.onEnterPress(row.id, e))
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.clD0
            )}
            useDefaultOptionSelected
          />
        </div>

        <div className='grid-item p-12 d-6'>
          <Autocomplete
            id={`observacaoD0_${index}`}
            options={obsOptions}
            onChange={(e, value) => {
              this.setState({
                [row.id]: {
                  ...row,
                  observacaoD0: value,
                },
                savedCows: this.state.savedCows.filter((cow) => cow !== row.id),
              })
            }}
            onKeyDown={
              this.state[row.id].observacaoD0 &&
              ((e) => this.onEnterPress(row.id, e))
            }
            label='Observação'
            value={this.state[row.id].observacaoD0 || null}
            openField
          />
        </div>

        <div className='grid-item p-12 d-2 p-display-flex p-justify-flex-end confirm-btn-wrapper'>
          <Button
            label='OK'
            onClick={() =>
              this.state.savedCows.indexOf(row.id) >= 0
                ? console.log(`There's nothing to save`)
                : this.saveCow(row.id)
            }
            disabled={this.state.savedCows.indexOf(row.id) >= 0 || this.props.user.roles[0].name === 'Cliente (somente visualização)'}
          />
        </div>
      </div>
    )
  }

  renderTitle() {
    return (
      <Fragment>
        Protocolo: {this.props.protocol.name}
        <br />
        Manejo: D0
      </Fragment>
    )
  }

  renderCowLabel(row, index) {
    if (this.props.isMerging) {
      return (
        <Grid container>
          <Grid item xs={12}>
            {row.lote}
          </Grid>
          <Grid item xs={12}>
            {row.codVaca}
          </Grid>
        </Grid>
      )
    } else {
      return row.codVaca
    }
  }

  getButtons() {
    return [
      {
        onClick: () => {
          const newState =
            this.state.savedCows.length === this.props.vacas.length
              ? {
                showingFinalizeConfirmation: true,
              }
              : {
                showingIncompleteError: true,
              }
          this.setState(newState)
        },
        icon: <DoneIcon />,
        label: 'Finalizar',
        disabled: this.props.user.roles[0].name === 'Cliente (somente visualização)'
      },
    ]
  }

  getPaths() {
    const uniqueCorrals = _.uniq(this.props.corrals.map((corral) => corral.id))
    const extraPath = this.props.farm.retiro &&
      uniqueCorrals.length === 1 && {
      route: `/farms/${this.props.farm.id}/corrals/${uniqueCorrals[0]}`,
      label: formatName(
        _.find(this.props.corrals, { id: uniqueCorrals[0] }).nome,
        RETIRO
      ),
    }

    const batchPath =
      this.props.batches.length === 1
        ? {
          route: `/farms/${this.props.farm.id}/corrals/${this.props.batches[0].retiroId
            }/batches/${this.props.batches[0].originalBatchId
              ? this.props.batches[0].originalBatchId
              : this.props.batches[0].id
            }`,
          label: formatName(this.props.batches[0].nomeLote, LOTE),
        }
        : {
          label: 'Vários Lotes',
        }

    return _.compact([
      {
        route: `/farms/${this.props.farm.id}`,
        label: formatName(this.props.farm.nome, FAZENDA),
      },
      extraPath,
      batchPath,
      {
        label: 'D0',
      },
    ])
  }

  getTableColumns() {
    return [
      {
        name: 'Matriz (ID)',
        type: CELL_TYPES.FIELD,
        field: (row, index) => this.renderCowLabel(row, index),
        grid: 3,
      },
      {
        name: 'Manejo',
        type: CELL_TYPES.FIELD,
        field: (row, index) => this.renderField(row, index),
        grid: 9,
      },
    ]
  }

  getTableColumnsData() {
    return sortList(
      _.values(_.pick(this.state, this.state.selectedVacas)),
      'codVaca'
    )
  }

  render() {
    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={this.renderTitle()}
          buttons={this.getButtons()}
          paths={this.getPaths()}
        />
        <MainContainer
          hasSearch
          hasValue={this.state.searchValue !== '' ? true : false}
          handleSearch={this.handleSearch}
          useVoiceRecognition
          preSearchChildren={this.handleManejosHeader()}
          includeDivider
        >
          <div className='grid grid-dg'>
            <div className='grid-item p-12 t-3 p-margin-bottom-3'>
              <Autocomplete
                id={`usoDispositivo_all`}
                options={this.props.usoImplantes}
                onChange={(e, value) => {
                  this.setState({
                    usoForAll: value,
                  })
                }}
                value={this.state.usoForAll || null}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.usoDispositivos
                )}
                label='Uso'
                tabIndex={this.state.searchValue !== '' ? -1 : null}
              />
            </div>

            <div className='grid-item p-12 t-7 d-4'>
              <Button
                label='Preencher Todas'
                style={{
                  width: '48%',
                  height: '46px',
                  marginTop: '10px',
                  padding: 0,
                }}
                onClick={() => this.aplyForAll()}
                tabIndex={this.state.searchValue !== '' ? -1 : null}
              />
              <Button
                label='Salvar todas'
                style={{
                  width: '48%',
                  marginLeft: '4%',
                  height: '46px',
                  marginTop: '10px',
                }}
                disabled={
                  this.state.savedCows.length === this.props.vacas.length || this.props.user.roles[0].name === 'Cliente (somente visualização)'
                }
                onClick={() => this.setState({ saveAllConfirm: true })}
                tabIndex={this.state.searchValue !== '' ? -1 : null}
              />
            </div>

            <div className='d-12 p-12'>
              <ResponsiveTable
                columns={this.getTableColumns()}
                data={this.getTableColumnsData()}
                rowsPerPage={10}
                className='table-d0-resync'
              />
            </div>
          </div>

          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false,
              },
            ]}
          />

          <Prompt
            visible={this.state.saveAllConfirm}
            title='Confirmação:'
            message={`Deseja realmente salvar todas?`}
            buttons={[
              {
                label: 'Não',
                onClick: () =>
                  this.setState({
                    saveAllConfirm: false,
                  }),
              },
              {
                label: 'Sim',
                onClick: () => this.saveAll(),
              },
            ]}
          />

          <Prompt
            visible={this.state.showingFinalizeConfirmation}
            title='Deseja Finalizar?'
            message='Uma vez finalizado os dados não salvos serão perdidos.'
            buttons={[
              {
                label: 'Não',
                onClick: () =>
                  this.setState({
                    showingFinalizeConfirmation: false,
                    confirmedBackupDownload: true,
                  }),
              },
              {
                label: 'Sim',
                onClick: this.state.confirmedBackupDownload
                  ? () => this.finalize(true)
                  : () =>
                    this.setState({
                      showingFinalizeConfirmation: false,
                      showingNoBackupConfirmation: true,
                    }),
              },
            ]}
          />

          <Prompt
            visible={this.state.showingNoBackupConfirmation}
            title='Atenção!'
            message='O comprovante de manejo pode ser necessário durante um eventual cenário de perda de dados. Não baixar o comprovante poderá dificultar a recuperação das informações desse manejo. Deseja continuar mesmo assim?'
            buttons={[
              {
                label: 'Cancelar',
                onClick: () =>
                  this.setState({
                    showingNoBackupConfirmation: false,
                    showingFinalizeConfirmation: true,
                  }),
              },
              {
                label: 'Sim',
                onClick: () => this.finalize(false),
              },
            ]}
          />

          <Prompt
            visible={this.state.showingIncompleteError}
            title='Erro'
            message='Existem matrizes que não foram salvas.'
            buttons={[
              {
                label: 'OK',
                onClick: () => this.setState({ showingIncompleteError: false }),
              },
            ]}
          />
        </MainContainer>
      </Container>
    )
  }
}

async function parseD0Info(protocol, d0, tipoManejo) {
  let ret = {
    implant: '',
    implantId: null,
    benzoato: '',
    luteolise: '',
    progesterona: '',
  }
  if (protocol && protocol.managementProtocols) {
    const d0s = _.filter(
      protocol.managementProtocols,
      (managementProtocol) => managementProtocol.day === d0.id
    )
    const benzoato = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Estímulo Ovulatório'
    )
    const luteolise = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Luteólise'
    )
    const progesterona = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Progesterona Injetável'
    )

    _.each(d0s, async (d0) => {
      if (d0.implantId) {
        let implantName = await utils.getElement(
          AVAILABLE_ENTITIES.DOMAIN_VALUES,
          d0.implantId
        )
        ret.implant = implantName.valor
        ret.implantId = d0.implantId
      }
      const hormone = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        d0.hormoneTypeId
      )
      const dosage = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        d0.hormoneDosageId
      )

      const dosageValue = dosage ? dosage.valor : d0.hormoneDosageValue

      switch (d0.managementId) {
        case benzoato.id:
          ret.benzoato = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        case luteolise.id:
          ret.luteolise = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        case progesterona.id:
          ret.progesterona = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        default:
      }
    })
  }
  return ret
}

const propsFromDatabase = async (props) => {
  const batchIds = props.match.params.id.split(';')

  const batches = await getBatchesByBatchIds(batchIds)

  const corrals = await utils.getWithParam(
    AVAILABLE_ENTITIES.CORRALS,
    'id',
    Q.oneOf(_.uniq(batches.map((batch) => batch.retiroId)))
  )

  const isMerging = batchIds.length > 1

  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    corrals[0].fazendaId
  )

  const usuarios = await utils.getWithRelation(
    AVAILABLE_ENTITIES.USERS,
    'farms__rel__users',
    'farm_id',
    corrals[0].fazendaId
  )

  const dias = await utils.getDomainValuesBy('Dias')
  const protocol =
    batches[0].protocoloId &&
    (await utils.getElement(
      AVAILABLE_ENTITIES.PROTOCOLS,
      batches[0].protocoloId
    ))
  const d0 = _.find(dias, { valor: 'D0' })
  const tipoManejo = await utils.getDomainValuesBy('Tipo de Manejo')
  const d0Info = await parseD0Info(protocol, d0, tipoManejo)
  return {
    batches,
    corrals,
    farm,
    isMerging,
    usuarios: usuarios ? usuarios : [],
    protocol,
    vacas: (
      await utils.getWithParam(
        AVAILABLE_ENTITIES.D0S,
        'batch_id',
        Q.oneOf(batchIds)
      )
    ).map((vaca) => ({
      ...vaca,
      lote: _.find(batches, { id: vaca.loteId }).nomeLote,
    })),
    ecc: _.sortBy(await utils.getDomainValuesBy('ECC'), [
      (ecc) => parseFloat(ecc.valor),
    ]),
    ordemMatriz: await utils.getDomainValuesBy('Ordem da Matriz Predominante'),
    racaMatriz: await utils.getDomainValuesBy('Raça da Matriz predominante'),
    racaBezerro: await utils.getDomainValuesBy('Raça do bezerro'),
    sexoBezerro: await utils.getDomainValuesBy('Sexo do Bezerro'),
    idadeMatriz: _.sortBy(await utils.getDomainValuesBy('Idade da Matriz'), [
      (idade) => parseInt(idade.valor.split(' ')[0]),
    ]),
    clD0: _.sortBy(await utils.getDomainValuesBy('CL'), [
      (cl) => !cl.valor.toLowerCase().startsWith('sim'),
      (cl) => !cl.valor.toLowerCase().startsWith('não'),
      (cl) => cl.valor.toLowerCase(),
    ]),
    implantes: _.sortBy(await utils.getDomainValuesBy('Dispositivo'), [
      (implant) => !implant.valor.toLowerCase().startsWith('zoetis'),
      (implant) => implant.valor.toLowerCase(),
    ]),
    usoImplantes: _.sortBy(
      await utils.getDomainValuesBy('Tipo de Implante Predominante'),
      ['valor']
    ),
    initialValues: d0Info,
    d0,
    user: await utils.getElement(
      AVAILABLE_ENTITIES.USERS,
      (
        await getLoggedUser()
      ).userId
    ),
    tipoResync: await utils.getDomainValuesBy('Tipo de Ressincronização'),
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(D0Resync)
)
