import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Tooltip,
  Checkbox,
  TablePagination,
} from '@material-ui/core'

import Button from './material/Button'

import './styles/mobileCard.scss'

type DataProps = {
  values: any[]
  title: string
  noDataMessage: string
}

type ActionProps = {
  icon?: React.ReactNode
  label: string
  disabled?: boolean
  checked?: boolean
  onClick: () => void
}

type MobileCardProps = {
  data: DataProps
}

const PER_PAGE = 10

const MobileCard = ({ data }: MobileCardProps) => {
  const [page, setPage] = useState(0)
  const [paginatedData, setPaginatedData] = useState<any[]>([])

  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage)
  }

  useEffect(() => {
    setPage(0)
  }, [data.values])

  useEffect(() => {
    const startIndex = page * PER_PAGE
    const endIndex = (page + 1) * PER_PAGE

    setPaginatedData(data.values.slice(startIndex, endIndex))
  }, [data.values, page])

  return (
    <>
      {paginatedData.length === 0 ? (
        <div className='card'>
          <h2>{data.noDataMessage}</h2>
        </div>
      ) : (
        paginatedData.map((item, index) => (
          <div className='card' key={index}>
            <div className='card-header'>
              <div className='checkbox'>
                {item?.Actions?.filter(
                  (action: ActionProps) =>
                    !Object.keys(action).includes('icon') &&
                    Object.keys(action).includes('checked')
                ).map((action: ActionProps) => (
                  <Checkbox
                    onClick={action.onClick}
                    checked={action.checked}
                    disabled={action.disabled}
                    color='primary'
                  />
                ))}
              </div>
              <div
                className={
                  !!item?.Actions?.find((action: ActionProps) =>
                    action.label.includes('Entrar')
                  )
                    ? 'card-title'
                    : 'card-title-without-link'
                }
                onClick={() =>
                  item?.Actions?.find((action: ActionProps) =>
                    action.label.includes('Entrar')
                  ).onClick()
                }
              >
                <h5>{data?.title}</h5>
                {Object.entries(item)
                  .filter(([key]) => key === data.title && key !== 'Actions')
                  .map(([_, value]: any) => (
                    <p
                      className={
                        !!item?.Actions?.find((action: ActionProps) =>
                          action.label.includes('Entrar')
                        )
                          ? 'title-link'
                          : ''
                      }
                    >
                      {value}
                    </p>
                  ))}
              </div>
            </div>
            <div className='card-body'>
              {Object.entries(item)
                .filter(([key]) => key !== data.title && key !== 'Actions')
                .map(([key, value]: any) => (
                  <div>
                    <h5>{key}</h5>
                    <p>{value}</p>
                  </div>
                ))}
            </div>
            <div className='card-footer'>
              <div className='manejos'>
                {item?.Actions?.filter(
                  (action: ActionProps) =>
                    !Object.keys(action).includes('icon') &&
                    !Object.keys(action).includes('checked')
                ).map((action: ActionProps, index: number) => {
                  const lastChild = item?.Actions?.filter(
                    (i: ActionProps) => !Object.keys(i).includes('icon')
                  ).length
                  return (
                    <Button
                      small
                      style={{
                        borderRadius: '300px',
                        height: '32px',
                        padding: '10px',
                        margin: '5px',
                        background:
                          !action.disabled && !(index + 1 === lastChild)
                            ? 'white'
                            : 'null',
                        color:
                          !action.disabled && !(index + 1 === lastChild)
                            ? 'rgb(234 93 13)'
                            : 'null',
                        border:
                          !action.disabled && !(index + 1 === lastChild)
                            ? '1px solid rgb(234 93 13)'
                            : 'null',
                      }}
                      onClick={() => action.onClick()}
                      label={action.label}
                      disabled={action.disabled}
                    />
                  )
                })}
              </div>

              {item?.Actions?.filter(
                (action: ActionProps) =>
                  Object.keys(action).includes('icon') &&
                  !Object.keys(action).includes('checked') &&
                  !action.label.includes('Entrar')
              ).map((action: any) => (
                <Tooltip
                  title={action.disabled ? 'Desabilitado' : action.label}
                  arrow
                  placement='top'
                >
                  <IconButton
                    style={{
                      color: action.disabled && '#cecece',
                      padding: '10px',
                    }}
                    onClick={!action.disabled && action.onClick}
                  >
                    {action.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </div>
          </div>
        ))
      )}
      <TablePagination
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
        }}
        count={data.values.length}
        rowsPerPage={PER_PAGE}
        page={page}
        rowsPerPageOptions={[PER_PAGE]}
        onPageChange={handlePageChange}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : to}`
        }
      />
    </>
  )
}

export default MobileCard
