import { Model, Q } from '@nozbe/watermelondb'
import {
  action,
  date,
  field,
  readonly,
  relation
} from '@nozbe/watermelondb/decorators'

import _ from 'lodash'

export const modelToDTOMapping = {
  identical: ['nome'],
  renamed: [],
  references: [
    {
      dtoName: 'fazendaId',
      modelName: 'farm_id'
    }
  ]
}

export default class Corrals extends Model {
  static table = 'corrals'

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('nome') nome

  //Foreign Relations
  @relation('farms', 'farm_id') farm
}
