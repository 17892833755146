import * as CONSTANTS from './constants';


const initialState = {
    loading: false
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.START_LOADING:
        return {
            ...state,
            loading: true,
        }
    case CONSTANTS.END_LOADING:
        return {
            ...state,
            loading: false,
        }
   default:
      return state;
  }
}
