import { date, field, readonly, relation } from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'

export const modelToDTOMapping = {
  identical: ['type', 'version', 'backend_identifier', 'ip_address'],
  renamed: [
    {
      dtoName: 'acceptedAt',
      modelName: 'accepted_at',
      isDate: true
    }
  ],
  references: [
    {
      dtoName: 'replacedBy',
      modelName: 'replaced_by'
    },
    {
      dtoName: 'userId',
      modelName: 'user_id'
    }
  ]
}

export default class Terms extends Model {
  static table = 'terms'

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('type') type
  @field('version') version
  @field('backend_identifier') backend_identifier
  @field('ip_address') ip_address
  @date('accepted_at') acceptedAt

  //Foreign Relations
  @relation('terms', 'replaced_by') replacedBy
  @relation('users', 'user_id') userId
}
