import { date, field, readonly, relation } from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'
import _ from 'lodash'

export const modelToDTOMapping = {
  identical: ['vacaId', 'observacao'],
  renamed: [],
  references: [
    {
      dtoName: 'ecc',
      modelName: 'ecc_id'
    },
    {
      dtoName: 'gnhr',
      modelName: 'gnhr_id'
    },
    {
      dtoName: 'cioRetirada',
      modelName: 'cioRetirada_id'
    },
    {
      dtoName: 'temperamento',
      modelName: 'temperamento_id'
    },
    {
      dtoName: 'partidaId',
      modelName: 'bull_id'
    },
    {
      dtoName: 'loteId',
      modelName: 'batch_id'
    },
    {
      dtoName: 'usuarioId',
      modelName: 'user_id'
    }
  ]
}

export default class IATFs extends Model {
  static table = 'iatfs'

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('vacaId') vacaId
  @field('observacao') observacao

  //Foreign Relations - Domain Values
  @relation('domain_values', 'ecc_id') ecc
  @relation('domain_values', 'gnhr_id') gnhr
  @relation('domain_values', 'cioRetirada_id') cioRetirada
  @relation('domain_values', 'temperamento_id') temperamento

  //Foreign Relations
  @relation('bulls', 'bull_id') bull
  // @relation('bulls_New', 'bull_id') bull
  @relation('batches', 'batch_id') batch
  @relation('users', 'user_id') user
}
