import React, { useState } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'

import { Button } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'
import { LocalStorageHelper } from '../utils/localStorageHelper'
import LogoZoetis from '../assets/img/logo_corte.png'
import Prompt from '../components/Prompt'
import { changeSyncView } from '../redux/sync/actions'
import clearCache from './../utils/clearCache'
import clearStorage from './../utils/clearStorage'
import { connect } from 'react-redux'
import { getLoggedUser } from '../redux/auth/actions'
import hasInternet from './../utils/recognizeInternetConnection'
import { logout } from '../redux/auth/actions'
import { updateNotes } from '../utils/notesHelper'
import { useTracking } from 'react-tracking'
import { utils } from '../database'

const TopBar = (props) => {
  const [modal, setModal] = useState(false)
  const [notesVisible, setNotesVisible] = useState(true)
  const [semenButton, setSemenButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const { trackEvent } = useTracking()

  let rota = location

  return (
    <header className={`app-header app-header-fixed ${props.className || ''}`}>
      <div className='app-header-container'>
        <div className='app-header-container-logo'>
          <img
            onClick={() => {
              trackEvent({ action: 'Clicou na Logo do ProGerar' })
              history.push('/')
            }}
            className='p-cursor-pointer'
            src={LogoZoetis}
            alt='Zoetis Gerar'
            width='125'
          />
        </div>

        <div className='app-header-container-menu'>
          <input type='checkbox' id='trigger-mobile' />
          <menu className='app-header-container-menu-items'>
            {
              <>
                <Button
                  onClick={() => {
                    setLoading(true)
                    trackEvent({ action: 'Clicou em atualizar versão' })
                    LocalStorageHelper.add('updateVersion', true)
                    clearCache()
                  }}
                  disabled={!(props.currentSync === 2 && hasInternet())}
                  style={
                    !(props.currentSync === 2 && hasInternet())
                      ? { color: '#999' }
                      : undefined
                  }
                >
                  Atualizar versão
                </Button>
                <span className='app-header-container-menu-items-divider'></span>
              </>
            }
            <Button onClick={() => history.push('/treinamentos')}>
              TREINAMENTOS
            </Button>
            <span className='app-header-container-menu-items-divider'></span>

            <Button
              onClick={() => {
                trackEvent({ action: 'Entrou na página de usuários' })
                history.push('/users')
              }}
              disabled={!(props.currentSync === 2 && hasInternet()) || props.loggedUser.roles[0] === 'Cliente (somente visualização)'}
              style={
                !(props.currentSync === 2 && hasInternet())
                  ? { color: '#999' }
                  : undefined
              }
            >
              USUÁRIOS
            </Button>

            <Button
              onClick={() => {
                trackEvent({ action: 'Entrou na página de protocolo' })
                history.push('/protocols')
              }}
            >
              PROTOCOLOS
            </Button>

            {!(
              rota.pathname.indexOf('IATF') >= 0 ||
              rota.pathname.indexOf('iatf') >= 0
            ) && (
                <Button
                  onClick={() => {
                    trackEvent({ action: 'Entrou na página de sêmen' })
                    if (!!props.loggedUser?.isNewPartida) {
                      history.push(
                        '/bulls/create-for-all/batch/none/management/none'
                      )
                    } else {
                      setSemenButton(true)
                    }
                  }}
                >
                  SÊMEN
                </Button>
              )}
            {props.loggedUser?.roles.includes("Administrador") && (
              <>
                <span className='app-header-container-menu-items-divider'></span>
                <Button
                  onClick={() => {
                    trackEvent({ action: 'Entrou na página de listagem de lancamentos do administrador' })
                    history.push('/lancamentos-do-administrador')
                  }}
                >
                  Admin
                </Button>
              </>
            )}
            <span className='app-header-container-menu-items-divider'></span>
            <Button
              onClick={async () => {
                trackEvent({ action: 'Saiu do ProGerar' })
                setModal(true)
              }}
              disabled={!(props.currentSync === 2 && hasInternet())}
              style={
                !(props.currentSync === 2 && hasInternet())
                  ? { color: '#999' }
                  : undefined
              }
            >
              SAIR
            </Button>
          </menu>
          <label
            className='app-header-container-menu-items-mask'
            htmlFor='trigger-mobile'
          ></label>
        </div>
      </div>
      <Prompt
        visible={modal}
        title='Atenção!'
        message={`Antes de sair certifique-se que seus dados foram sincronizados. Caso você esteja offline, clique em cancelar e assim que possível conecte-se a rede para enviar os dados para o servidor.`}
        buttons={[
          {
            label: 'Cancelar',
            onClick: () => setModal(false),
          },
          {
            label: 'Sair',
            onClick: async () =>
              await getLoggedUser().then((res) => {
                localStorage.setItem(
                  `S - ${new Date()}`,
                  JSON.stringify({ userId: res.userId })
                )
                localStorage.removeItem('downloadFarms')
                LocalStorageHelper.remove('selectedEstacaoDeMonta')
                clearStorage()
                props.logout()
                window.location.href = '/login'
                // history.push('/login')
              }),
          },
        ]}
      />

      <Prompt
        visible={semenButton && !(!!props.loggedUser?.isNewPartida)}
        title='Atenção!'
        message='Tivemos alterações importantes na estrutura dos dados de Touro e Partida. Para evitar problemas na sincronização dos seus dados é necessário retornar a tela de login e entrar com seu usuário e senha novamente. Caso tenha dúvidas ou problemas, entre em contato com nossa equipe de atendimento, estaremos à disposição.'
        buttons={[
          {
            label: 'Fechar',
            onClick: () => {
              setSemenButton(false)
            },
          },
          {
            label: 'Ir para o Login',
            onClick: async () =>
              await getLoggedUser().then((res) => {
                localStorage.setItem(
                  `S - ${new Date()}`,
                  JSON.stringify({ userId: res.userId })
                )
                localStorage.removeItem('downloadFarms')
                LocalStorageHelper.remove('selectedEstacaoDeMonta')
                clearStorage()
                props.logout()
                window.location.href = '/login'
              }),
            disabled: !(props.currentSync === 2 && hasInternet()),
          },
        ]}
      />

      <Prompt
        visible={
          !!LocalStorageHelper.get('updateVersion') && notesVisible && !loading
        }
        title={updateNotes.title}
        options={updateNotes.text}
        buttons={[
          {
            label: 'Ok',
            onClick: () => {
              setNotesVisible(false)
              LocalStorageHelper.remove('updateVersion')
            },
          },
        ]}
      />
      {loading && (
        <div
          style={{
            background: 'rgba(0, 0, 0, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            padding: 0,
            margin: 0,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 999,
          }}
        >
          <CircularProgress style={{ color: '#ea5d0d' }} />
        </div>
      )}
    </header>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: async () => await dispatch(logout()),
    user: async () => await dispatch(getLoggedUser()),
    changeSyncView: (payload) => dispatch(changeSyncView(payload)),
  }
}

const propsFromDatabase = async (props) => {
  const loggedUser = await getLoggedUser()

  return {
    loggedUser,
  }
}

const mapStateToProps = (state) => ({
  currentSync: state.sync.currentSync,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.withPropsFromDatabase(propsFromDatabase, withRouter(TopBar)))