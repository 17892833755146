import { children, date, field, readonly } from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'
import _ from 'lodash'

export const modelToDTOMapping = {
  identical: ['nome', 'descricao'],
  renamed: [],
  references: []
}

export default class DomainTypes extends Model {
  static table = 'domain_types'
  static associations = {
    domain_values: { type: 'has_many', foreign_key: 'domain_type_id' }
  }

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('nome') nome
  @field('descricao') descricao

  //Lazy Fields/Methods
  @children('domain_values') domainValues
}
