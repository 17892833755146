/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-direct-mutation-state */

import * as sync from '../../../src/syncWorker'

import {
  ANIMAL_DISCARDED,
  ANIMAL_NOT_INSEMINATED,
  ANIMAL_NOT_PRESENT_TO_MANAGEMENT,
  FOUND_DEVICE_INSIDE_COW,
} from '../../utils/constants'
import { AVAILABLE_ENTITIES, Repository, format, utils } from '../../database'
import Autocomplete, {
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteOptionLabelUsingCustomFieldSavingID,
  autocompleteOptionLabelUsingCustomHandlerSavingID,
} from '../../components/material/Autocomplete'
import {
  FAZENDA,
  LOTE,
  REQUIRED_FIELD_MESSAGES,
  RETIRO,
  formatName,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from '../../utils/formHelper'
import React, { Component, Fragment } from 'react'

import AddIcon from '@material-ui/icons/Add'
import Button from '../../components/material/Button'
import { CELL_TYPES } from './../../components/PaginatedTable'
import { Container } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import MainContainer from '../../components/MainContainer'
import Prompt from './../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import ResponsiveTable from './../../components/ResponsiveTable'
import TextField from '../../components/material/TextField'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { changeCowsToBatchNotes } from '../../utils/notesHelper'
import fnsformat from 'date-fns/format'
import { getLoggedUser } from '../../redux/auth/actions'
import moment from 'moment'
import ptBr from 'date-fns/locale/pt-BR'
import { sortList } from '../../utils/helper'
import track from 'react-tracking'
import { verify } from '../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

const obsOptions = [
  FOUND_DEVICE_INSIDE_COW,
  ANIMAL_NOT_PRESENT_TO_MANAGEMENT,
  ANIMAL_NOT_INSEMINATED,
  ANIMAL_DISCARDED,
]

@track(() => ({ page: 'IATF', date: new Date() }))
class IATF extends Component {
  constructor(props) {
    super(props)

    const codVacas = sortList(props.vacas, 'codVaca')

    const idToCowMap = _.reduce(
      props.vacas,
      (obj, vaca) => ({
        ...obj,
        [vaca.id]: vaca,
      }),
      {}
    )

    this.state = {
      ...idToCowMap,
      codVacas,
      userForAll: null,
      userApplied: null,
      partidaForAll: null,
      partidaApplied: null,
      selectedVacas: codVacas,
      savedCows: [],
      confirmedBackupDownload: true,
      showingNoBackupConfirmation: false,
      showingFinalizeConfirmation: false,
      showingIncompleteError: false,
      registerInseminator: false,
      batchesIds: props.batches.map((batch) => batch.id),
      isntIatfDay: false,
      dateIatf: null,
      iatfWeekDay: null,
      saveAllConfirm: false,
      focusSearch: false,
      searchValue: '',
      clearSearch: null,
      showingChangeCowsToBatchConfirmation: false,
      searchInputCow: [],
      selectedCowToChangeToBatch: null,
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.changeCowsToBatch = this.changeCowsToBatch.bind(this)
    this.saveIatf = this.saveIatf.bind(this)
    this.finalize = this.finalize.bind(this)

    this.handleManejosHeader = this.handleManejosHeader.bind(this)
    this.renderField = this.renderField.bind(this)

    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)

    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
    this.autocompleteOptionLabelUsingCustomFieldSavingID =
      autocompleteOptionLabelUsingCustomFieldSavingID.bind(this)
    this.autocompleteOptionLabelUsingCustomHandlerSavingID =
      autocompleteOptionLabelUsingCustomHandlerSavingID.bind(this)

    this.renderBullLabel = this.renderBullLabel.bind(this)

    this.renderTitle = this.renderTitle.bind(this)

    this.fillWithDefault()
    this.verifyIfItsIatfDay()

    this.mapDeadCows()
  }

  mapDeadCows() {
    this.state.selectedVacas &&
      this.state.selectedVacas.map((v) =>
        v.previousObs?.map((obs) => obs.obs).indexOf('Animal morreu') >= 0 &&
          (!v.observacaoIATF || v.observacaoIATF === ANIMAL_NOT_INSEMINATED)
          ? (v.observacaoIATF = ANIMAL_NOT_INSEMINATED)
          : null
      )
  }

  async verifyIfItsIatfDay() {
    let today = moment.utc(new Date().valueOf()._d).format('DD/MM/YYYY')
    let protocol = await utils.getElement(
      AVAILABLE_ENTITIES.PROTOCOLS,
      this.props.batches[0].protocoloId
    )
    let managementIds = protocol.managementProtocols.map((manejo) => manejo.day)
    let daysManagement = await utils.getWithParam(
      AVAILABLE_ENTITIES.DOMAIN_VALUES,
      'id',
      Q.oneOf(managementIds)
    )
    let dayBatch = daysManagement
      ? parseInt(
        daysManagement
          .sort(
            (a, b) =>
              parseInt(b.valor.replace(/\D/g, '')) -
              parseInt(a.valor.replace(/\D/g, ''))
          )[0]
          .valor.replace(/\D/g, '')
      )
      : 0
    await this.setState({
      dateIatf: moment
        .utc(
          this.props.batches[0].dataIatf._d.valueOf() +
          dayBatch * 24 * 3600 * 1000
        )
        .format('DD/MM/YYYY'),
    })
    await this.setState({
      iatfWeekDay: fnsformat(
        Date.parse(
          moment.utc(
            this.props.batches[0].dataIatf._d.valueOf() +
            dayBatch * 24 * 3600 * 1000
          )
        ),
        'EEEEEEE',
        {
          locale: ptBr,
        }
      ),
    })

    if (this.state.dateIatf !== today) {
      this.setState({ isntIatfDay: true })
    }
  }

  renderBullLabel(partida) {
    const bull = this.props.bulls.find((bull) => bull.id === partida?.touroId)

    if (!bull) {
      return ''
    }

    return (
      `${bull.nome}${partida.data
        ? ' - ' + moment(partida.data).format('DD/MM/YYYY')
        : ' - ' + partida.codigo
      } ${_.isEmpty(bull.observacao) ? '' : '(' + bull.observacao + ')'}` || ''
    )
  }

  @track({ action: 'Finalizou a IATF' })
  async finalize(downloadBackup) {
    const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)
    let success = true
    for (const batch of this.props.batches) {
      const updateRequest = await repository.update({
        id: batch.id,
        dias: this.props.currentDay.id,
      })
      if (!updateRequest.success) {
        success = false
        console.log(
          `There was an error finalizing IATF for batch ${batch.id}: ${updateRequest.exception}`
        )
      }
    }
    if (success) {
      console.log('IATF Successfully ended')
      const uniqueCorrals = _.uniq(
        this.props.corrals.map((corral) => corral.id)
      )
      if (this.props.farm.retiro && uniqueCorrals.length === 1) {
        this.props.history.push(
          `/farms/${this.props.farm.id}/corrals/${uniqueCorrals[0]}`
        )
      } else {
        this.props.history.push(`/farms/${this.props.farm.id}`)
      }
    }

    sync.downloadProofOfManagement({
      values: ['fazendas', 'lotes', 'd0s', 'iatfs', 'touros', 'partidas', 'fazendaUsuarios'],
      text: 'Comprovante de manejo IATF - ' +
        formatName(this.props.batches.map((item) => item.nomeLote), LOTE) +
        ' - ' +
        formatName(this.props.farm.nome, FAZENDA) +
        ' - ' +
        moment.utc().valueOf() +
        '.progerar',
      batchIds: this.props.match.params.id.split(';')
    })
  }

  @track((props, state, cowId) => ({ action: 'Salvou a IATF de uma matriz', value: cowId[0] }))
  async saveIatf(id, saveAll = false) {
    if (this.state.searchValue !== '') {
      this.handleSearch(null, false)
      this.setState({
        focusSearch: true,
        clearSearch: true,
      })
    }

    const cow = this.state[id]
    const propsCow = _.find(this.props.vacas, { id: cow.id })
    let success = true
    if (cow) {
      this.setState({
        savedCows: [...this.state.savedCows, cow.id],
        clearSearch: true
      })

      let payload = {
        vacaId: cow.id,
        loteId: cow.loteId,
        ..._.pick(cow, [
          'gnhr',
          'cioRetirada',
          'temperamento',
          'partidaId',
          'usuarioId',
        ]),
      }
      payload.ecc = cow.eccIATF
      payload.observacao = cow.observacaoIATF

      let missingRequiredFields = false
      const requiredFields =
        _.isEmpty(payload.observacao) ||
          (payload.observacao !== ANIMAL_NOT_PRESENT_TO_MANAGEMENT &&
            payload.observacao !== FOUND_DEVICE_INSIDE_COW &&
            payload.observacao !== ANIMAL_NOT_INSEMINATED &&
            payload.observacao !== ANIMAL_DISCARDED)
          ? ['partidaId', 'usuarioId']
          : []

      let state = {
        ..._.reduce(
          requiredFields,
          (accumulator, field) => {
            if (_.isEmpty(payload[field])) {
              missingRequiredFields = true
              return {
                ...accumulator,
                [`${field}_error`]: REQUIRED_FIELD_MESSAGES.DEFAULT,
              }
            }
            return accumulator
          },
          {}
        ),
      }

      if (missingRequiredFields) {
        this.setState({
          [cow.id]: {
            ...this.state[cow.id],
            ...state
          },
          savedCows: this.state.savedCows.filter((x) => x !== cow.id),
        })
      } else {
        if (saveAll) {
          this.setState({
            focusSearch: false,
            clearSearch: false
          })
        }

        const repository = new Repository(AVAILABLE_ENTITIES.IATFS)
        const existingRequest = await repository.getByParam('vacaId', cow.id)
        if (existingRequest.success) {
          this.setState({
            focusSearch: false,
            clearSearch: false
          })
          const existingEntry = existingRequest.response

          if (existingEntry.length > 0) {
            const d0Repo = new Repository(AVAILABLE_ENTITIES.D0S)
            const updateReq = await d0Repo.update({
              id: cow.id,
              codVaca: cow.codVaca,
              observacaoDG: (cow.observacaoIATF === 'Matriz Descarte' || payload.observacao === 'Matriz Descarte') ? '[N/A]' : null
            })

            if (updateReq.success) {
              console.log(
                `codVaca successfully changed from ${propsCow.codVaca} to ${cow.codVaca} for cow: ${cow.id}`
              )
            }

            const updateRequest = await repository.update({
              ...payload,
              id: existingEntry[0].id,
            })
            if (!updateRequest.success) {
              console.log(
                `There was a problem trying to update entry for cow ${cow.id}: ${updateRequest.exception}`
              )
              success = false
            }
          } else {
            const createRequest = await repository.post(payload)
            if (!createRequest.success) {
              console.log(
                `There was a problem trying to create entry for cow ${cow.id}: ${createRequest.exception}`
              )
              success = false
            }

            const d0Repo = new Repository(AVAILABLE_ENTITIES.D0S)
            const updateReq = await d0Repo.update({
              id: cow.id,
              codVaca: cow.codVaca,
              observacaoDG: (cow.observacaoIATF === 'Matriz Descarte' || payload.observacao === 'Matriz Descarte') ? '[N/A]' : null
            })

            if (updateReq.success) {
              console.log(
                `codVaca successfully changed from ${propsCow.codVaca} to ${cow.codVaca} for cow: ${cow.id}`
              )
            }
          }
        } else {
          console.log(
            `There was a problem trying to search for existing entry for cow: ${cow.id}: ${existingRequest.exception}`
          )
          success = false
        }

        if (
          success &&
          propsCow &&
          propsCow.codVaca !== cow.codVaca &&
          !_.isEmpty(cow.codVaca)
        ) {
          const d0Repo = new Repository(AVAILABLE_ENTITIES.D0S)
          const updateReq = await d0Repo.update({
            id: cow.id,
            codVaca: cow.codVaca,
            observacaoDG: (cow.observacaoIATF === 'Matriz Descarte' || payload.observacao === 'Matriz Descarte') ? '[N/A]' : null
          })
          if (updateReq.success) {
            console.log(
              `codVaca successfully changed from ${propsCow.codVaca} to ${cow.codVaca} for cow: ${cow.id}`
            )
            let codVacas = this.state.codVacas.filter(
              (entry) => entry.id !== cow.id
            )
            this.setState({
              codVacas: [...codVacas, { id: cow.id, codVaca: cow.codVaca }],
            })
          } else {
            console.log(
              `There was a problem updating codVaca for cow: ${cow.id}: ${updateReq.exception}`
            )
            success = false
          }
        }

        if (_.isEmpty(cow.codVaca)) {
          success = false
        }

        if (!success) {
          this.setState({
            [cow.id]: state,
            savedCows: this.state.savedCows.filter((x) => x !== cow.id),
          })
        }
      }
    }
    return success
  }

  async saveAll() {
    for (const cow of this.props.vacas) {
      if (this.state.savedCows.indexOf(cow.id) === -1) {
        await this.saveIatf(cow.id, true)
      }
    }
    this.setState({ saveAllConfirm: false })
  }

  async handleSearch(e, clean) {
    const val = !clean ? e?.target?.value : ''
    const eventVal = val === undefined && ''

    const selectedCodVacas = _.isEmpty(val)
      ? this.state.codVacas
      : this.state.codVacas.filter(
        (entry) =>
          entry.codVaca?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
    const filtedCodLeitorBastao = _.isEmpty(val)
      ? this.state.codVacas
      : this.state.codVacas.filter(
        (entry) =>
          entry.codLeitorBastao?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
    const filtedAllData = selectedCodVacas.concat(filtedCodLeitorBastao)
    const selectedVacas = [...new Set(filtedAllData)]
    let searchError = null
    let searchInputCow = []

    if (selectedVacas.length === 0 && !_.isEmpty(val)) {
      const outOfBatch = await utils.farmWideSearch(
        val,
        this.state.batchesIds,
        this.props.farm.id
      )

      if (outOfBatch.msg) {
        searchError = outOfBatch.msg
        searchInputCow = outOfBatch.data
      }
    }

    this.setState({
      selectedVacas,
      searchError,
      searchInputCow,
      searchValue: selectedVacas.length > 0 ? eventVal : '',
      // searchValue: selectedVacas.length > 0 ? e?.target?.value : '',
    })
  }

  async changeCowsToBatch(cow) {
    try {
      let updatedMatriz = {}

      updatedMatriz = {
        ...cow,
        loteId: this.props.loteId,
        observacaoD0: 'Animal mudou de lote'
      }

      const repository = new Repository(AVAILABLE_ENTITIES.D0S)
      const createReq = await repository.update(updatedMatriz)

      if (createReq.success) {
        console.log('D0 updated successfully')

        this.setState({
          codVacas: [...this.state.codVacas, updatedMatriz],
        })

        setTimeout(() => {
          window.location.reload()
        }, 500)
      } else {
        console.log(
          `There was an error trying to update D0: ${createReq.exception}`
        )
      }
    } catch (exception) {
      console.log(`There was an error trying to update D0: ${exception}`)
    }
  }

  onEnterPress = (id, e) => {
    if (this.props.users[0].roles[0]?.name !== 'Cliente (somente visualização)') {
      if (!(this.state.savedCows.indexOf(id) >= 0)) {
        if (e.key === 'Enter' && e.shiftKey === false) {
          e.preventDefault()
          this.saveIatf(id)
        }
      }
    }
  }

  getIatf(row) {
    const iatfFiltrada = this.props.vacasIatf
      .filter((v) => v.vacaId === row.id)
      .sort(
        (a, b) =>
          new Date(b.updatedAt._d).getTime() -
          new Date(a.updatedAt._d).getTime()
      )[0]

    return iatfFiltrada
  }

  fillWithDefault() {
    let i = 0
    let currentVacaIatf
    let gnhrDefault = this.props.gnhr.filter((x) => x.valor === 'Não')[0].id

    while (i < this.state.selectedVacas.length) {
      currentVacaIatf = this.getIatf(this.state.selectedVacas[i])
      if (currentVacaIatf) {
        this.state.selectedVacas[i].partidaId = currentVacaIatf.partidaId
        this.state.selectedVacas[i].usuarioId = currentVacaIatf.usuarioId
        this.state.selectedVacas[i].eccIATF = currentVacaIatf.ecc
        this.state.selectedVacas[i].cioRetirada = currentVacaIatf.cioRetirada
        this.state.selectedVacas[i].gnhr = currentVacaIatf.gnhr
        this.state.selectedVacas[i].temperamento = currentVacaIatf.temperamento
        this.state.selectedVacas[i].observacaoIATF = currentVacaIatf.observacao

        this.state.savedCows.push(this.state.selectedVacas[i].id)
      } else {
        this.state.selectedVacas[i].gnhr = gnhrDefault
      }

      i++
    }
  }

  handleManejosHeader() {
    return (
      <div className='grid grid-iatf' style={{ padding: 0 }}>
        <div className='grid-item p-12 header-info-right'>
          <h2>
            TOTAL DE MATRIZES: {this.state.savedCows.length}/
            {this.props.vacas ? this.props.vacas.length : 0}
          </h2>
          <hr />
        </div>
      </div>
    )
  }

  renderMatriz(row, index) {
    return (
      <div className='grid' style={{ width: '100%' }}>
        {this.props.isResync ? (
          <>
            {this.props.isMerging && (
              <div className='grid-item p-12'>{row.lote}</div>
            )}
            <div className='grid-item p-12'>{row.codVaca}</div>
          </>
        ) : (
          <div className='p-12'>
            <TextField
              id={`codVaca_${index}`}
              label='ID/Matriz'
              style={{ width: '100%' }}
              onChange={(e) => {
                const value = e.target.value
                this.setState({
                  [row.id]: {
                    ...row,
                    observacaoIATF: this.state[row.id].observacaoIATF,
                    eccIATF: this.state[row.id].eccIATF,
                    gnhr: this.state[row.id].gnhr,
                    cioRetirada: this.state[row.id].cioRetirada,
                    temperamento: this.state[row.id].temperamento,
                    partidaId: this.state[row.id].partidaId,
                    usuarioId: this.state[row.id].usuarioId,
                    codVaca: value,
                    codVaca_error:
                      _.isEmpty(value) && REQUIRED_FIELD_MESSAGES.DEFAULT,
                  },
                  savedCows: this.state.savedCows.filter(
                    (cow) => cow !== row.id
                  ),
                })
              }}
              onKeyDown={(e) => this.onEnterPress(row.id, e)}
              value={this.state[row.id].codVaca || null}
              error={!_.isEmpty(this.state[row.id].codVaca_error)}
              helperText={this.state[row.id].codVaca_error}
              tabIndex={this.state.searchValue !== '' ? -1 : null}
            />
          </div>
        )}
        {row.previousObs &&
          row.previousObs.map((obs, index) => {
            const style = index === 0 ? { marginTop: '1em' } : null
            return (
              <div
                className='grid-item p-12'
                key={`${row.id}_obs_${index}`}
                style={style}
              >
                <b>{obs.manejo}: </b>
                {obs.obs}
              </div>
            )
          })}
      </div>
    )
  }

  renderField(row, index) {
    return (
      <div className='grid grid-iatf'>
        <div className='grid-item p-12 t-6 d-6'>
          <Autocomplete
            id={`semen_${index}`}
            options={this.props.partidas}
            value={this.state[row.id].partidaId || null}
            onChange={(e, value) => {
              this.setState({
                [row.id]: {
                  ...row,
                  observacaoIATF: this.state[row.id].observacaoIATF,
                  eccIATF: this.state[row.id].eccIATF,
                  gnhr: this.state[row.id].gnhr,
                  cioRetirada: this.state[row.id].cioRetirada,
                  temperamento: this.state[row.id].temperamento,
                  partidaId: value && value.id,
                  usuarioId: this.state[row.id].usuarioId,
                  codVaca: this.state[row.id].codVaca,
                  codVaca_error:
                    _.isEmpty(this.state[row.id].codVaca) &&
                    REQUIRED_FIELD_MESSAGES.DEFAULT,
                  eccIATF_error: true
                    ? _.isEmpty(value) || _.isEmpty(value.id)
                      ? REQUIRED_FIELD_MESSAGES.DEFAULT
                      : null
                    : null,
                },
                savedCows: this.state.savedCows.filter(
                  (element) => element !== row.id
                ),
              })
            }}
            onKeyDown={
              (this.state[row.id].partidaId ||
                this.state[row.id].partidaId_value) &&
              this.state[row.id].partidaId.length > 3 &&
              ((e) => this.onEnterPress(row.id, e))
            }
            getOptionLabel={this.autocompleteOptionLabelUsingCustomHandlerSavingID(
              this.props.partidas,
              this.renderBullLabel
            )}
            useDefaultOptionSelected
            label='Sêmen'
            error={!_.isEmpty(this.state[row.id].partidaId_error)}
            helperText={this.state[row.id].partidaId_error}
          />
        </div>

        <div className='grid-item p-12 t-6 d-6'>
          <Autocomplete
            id={`inseminador_${index}`}
            options={this.props.users}
            value={
              this.state.userApplied && !this.state[row.id].usuarioId
                ? this.state.userApplied
                : this.state[row.id].usuarioId || null
            }
            onChange={(e, value) => {
              this.setState({
                [row.id]: {
                  ...row,
                  observacaoIATF: this.state[row.id].observacaoIATF,
                  eccIATF: this.state[row.id].eccIATF,
                  gnhr: this.state[row.id].gnhr,
                  cioRetirada: this.state[row.id].cioRetirada,
                  temperamento: this.state[row.id].temperamento,
                  partidaId: this.state[row.id].partidaId,
                  usuarioId: value && value.id,
                  codVaca: this.state[row.id].codVaca,
                  codVaca_error:
                    _.isEmpty(this.state[row.id].codVaca) &&
                    REQUIRED_FIELD_MESSAGES.DEFAULT,
                  eccIATF_error: true
                    ? _.isEmpty(value) || _.isEmpty(value.id)
                      ? REQUIRED_FIELD_MESSAGES.DEFAULT
                      : null
                    : null,
                },
                savedCows: this.state.savedCows.filter(
                  (element) => element !== row.id
                ),
              })
            }}
            onKeyDown={
              (this.state[row.id].usuarioId ||
                this.state[row.id].usuarioId_value) &&
              this.state[row.id].usuarioId.length > 3 &&
              ((e) => this.onEnterPress(row.id, e))
            }
            getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
              this.props.users,
              'nomeCompleto'
            )}
            useDefaultOptionSelected
            label='Inseminador'
            error={!_.isEmpty(this.state[row.id].usuarioId_error)}
            helperText={this.state[row.id].usuarioId_error}
          />
        </div>

        <div className='grid-item p-12 t-6 d-3'>
          <Autocomplete
            id={`ecc_${index}`}
            options={this.props.ecc}
            value={this.state[row.id].eccIATF || null}
            onChange={(e, value) => {
              this.setState({
                [row.id]: {
                  ...row,
                  observacaoIATF: this.state[row.id].observacaoIATF,
                  eccIATF: value && value.id,
                  gnhr: this.state[row.id].gnhr,
                  cioRetirada: this.state[row.id].cioRetirada,
                  temperamento: this.state[row.id].temperamento,
                  partidaId: this.state[row.id].partidaId,
                  usuarioId: this.state[row.id].usuarioId,
                  codVaca: this.state[row.id].codVaca,
                  codVaca_error:
                    _.isEmpty(this.state[row.id].codVaca) &&
                    REQUIRED_FIELD_MESSAGES.DEFAULT,
                  eccIATF_error: false
                    ? _.isEmpty(value) || _.isEmpty(value.id)
                      ? REQUIRED_FIELD_MESSAGES.DEFAULT
                      : null
                    : null,
                },
                savedCows: this.state.savedCows.filter(
                  (element) => element !== row.id
                ),
              })
            }}
            onKeyDown={
              (this.state[row.id].eccIATF ||
                this.state[row.id].eccIATF_value) &&
              this.state[row.id].eccIATF.length > 3 &&
              ((e) => this.onEnterPress(row.id, e))
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.ecc
            )}
            useDefaultOptionSelected
            label='ECC'
          />
        </div>

        <div className='grid-item p-12 t-6 d-3'>
          <Autocomplete
            id={`cio_${index}`}
            options={this.props.cio}
            value={this.state[row.id].cioRetirada || null}
            onChange={(e, value) => {
              this.setState({
                [row.id]: {
                  ...row,
                  observacaoIATF: this.state[row.id].observacaoIATF,
                  eccIATF: this.state[row.id].eccIATF,
                  gnhr: this.state[row.id].gnhr,
                  cioRetirada: value && value.id,
                  temperamento: this.state[row.id].temperamento,
                  partidaId: this.state[row.id].partidaId,
                  usuarioId: this.state[row.id].usuarioId,
                  codVaca: this.state[row.id].codVaca,
                  codVaca_error:
                    _.isEmpty(this.state[row.id].codVaca) &&
                    REQUIRED_FIELD_MESSAGES.DEFAULT,
                  eccIATF_error: false
                    ? _.isEmpty(value) || _.isEmpty(value.id)
                      ? REQUIRED_FIELD_MESSAGES.DEFAULT
                      : null
                    : null,
                },
                savedCows: this.state.savedCows.filter(
                  (element) => element !== row.id
                ),
              })
            }}
            onKeyDown={
              (this.state[row.id].cioRetirada ||
                this.state[row.id].cioRetirada_value) &&
              this.state[row.id].cioRetirada.length > 3 &&
              ((e) => this.onEnterPress(row.id, e))
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.cio
            )}
            useDefaultOptionSelected
            label='Cio'
          />
        </div>

        <div className='grid-item p-12 t-6 d-3'>
          <Autocomplete
            id={`gnhr_${index}`}
            options={this.props.gnhr}
            value={this.state[row.id].gnhr || null}
            onChange={(e, value) => {
              this.setState({
                [row.id]: {
                  ...row,
                  observacaoIATF: this.state[row.id].observacaoIATF,
                  eccIATF: this.state[row.id].eccIATF,
                  gnhr: value && value.id,
                  cioRetirada: this.state[row.id].cioRetirada,
                  temperamento: this.state[row.id].temperamento,
                  partidaId: this.state[row.id].partidaId,
                  usuarioId: this.state[row.id].usuarioId,
                  codVaca: this.state[row.id].codVaca,
                  codVaca_error:
                    _.isEmpty(this.state[row.id].codVaca) &&
                    REQUIRED_FIELD_MESSAGES.DEFAULT,
                  eccIATF_error: false
                    ? _.isEmpty(value) || _.isEmpty(value.id)
                      ? REQUIRED_FIELD_MESSAGES.DEFAULT
                      : null
                    : null,
                },
                savedCows: this.state.savedCows.filter(
                  (element) => element !== row.id
                ),
              })
            }}
            onKeyDown={
              (this.state[row.id].gnhr || this.state[row.id].gnhr_value) &&
              this.state[row.id].gnhr.length > 3 &&
              ((e) => this.onEnterPress(row.id, e))
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.gnhr
            )}
            useDefaultOptionSelected
            label='GnRH'
          />
        </div>

        <div className='grid-item p-12 t-6 d-3'>
          <Autocomplete
            id={`temperamento_${index}`}
            options={this.props.temperamento}
            value={this.state[row.id].temperamento || null}
            onChange={(e, value) => {
              this.setState({
                [row.id]: {
                  ...row,
                  observacaoIATF: this.state[row.id].observacaoIATF,
                  eccIATF: this.state[row.id].eccIATF,
                  gnhr: this.state[row.id].gnhr,
                  cioRetirada: this.state[row.id].cioRetirada,
                  temperamento: value && value.id,
                  partidaId: this.state[row.id].partidaId,
                  usuarioId: this.state[row.id].usuarioId,
                  codVaca: this.state[row.id].codVaca,
                  codVaca_error:
                    _.isEmpty(this.state[row.id].codVaca) &&
                    REQUIRED_FIELD_MESSAGES.DEFAULT,
                  eccIATF_error: false
                    ? _.isEmpty(value) || _.isEmpty(value.id)
                      ? REQUIRED_FIELD_MESSAGES.DEFAULT
                      : null
                    : null,
                },
                savedCows: this.state.savedCows.filter(
                  (element) => element !== row.id
                ),
              })
            }}
            onKeyDown={
              (this.state[row.id].temperamento ||
                this.state[row.id].temperamento_value) &&
              this.state[row.id].temperamento.length > 3 &&
              ((e) => this.onEnterPress(row.id, e))
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.temperamento
            )}
            useDefaultOptionSelected
            label='Temperamento'
          />
        </div>

        <div className='grid-item p-12 t-12 d-9'>
          <Autocomplete
            id={`obs_${index}`}
            label='Observação'
            options={obsOptions}
            onChange={(e, value) => {
              let state = {
                ...row,
                observacaoIATF: value,
                eccIATF: this.state[row.id].eccIATF,
                gnhr: this.state[row.id].gnhr,
                cioRetirada: this.state[row.id].cioRetirada,
                temperamento: this.state[row.id].temperamento,
                partidaId: this.state[row.id].partidaId,
                usuarioId: this.state[row.id].usuarioId,
                codVaca: this.state[row.id].codVaca,
                codVaca_error:
                  _.isEmpty(this.state[row.id].codVaca) &&
                  REQUIRED_FIELD_MESSAGES.DEFAULT,
              }
              if (
                value === ANIMAL_NOT_PRESENT_TO_MANAGEMENT ||
                value === ANIMAL_NOT_INSEMINATED ||
                value === ANIMAL_DISCARDED
              ) {
                state.partidaId = null
                state.usuarioId = null
                state.usuarioId_error = null
                state.partidaId_error = null
              }
              this.setState({
                [row.id]: state,
                savedCows: this.state.savedCows.filter((cow) => cow !== row.id),
              })
            }}
            onKeyDown={
              (this.state[row.id].observacaoIATF ||
                this.state[row.id].observacaoIATF_value) &&
              this.state[row.id].observacaoIATF.length > 3 &&
              ((e) => this.onEnterPress(row.id, e))
            }
            value={this.state[row.id].observacaoIATF || null}
            openField
          />
        </div>

        <div className='grid-item p-12 d-3 save-btn'>
          <Button
            label='SALVAR'
            onClick={() =>
              this.state.savedCows.indexOf(row.id) >= 0
                ? console.log(`There's nothing to save`)
                : this.saveIatf(row.id)
            }
            disabled={this.state.savedCows.indexOf(row.id) >= 0 || this.props.users[0].roles[0]?.name === 'Cliente (somente visualização)'}
          />
        </div>
      </div>
    )
  }

  aplyForAll() {
    this.state.selectedVacas.map((row, i) => {
      if (this.state.savedCows.indexOf(row.id) < 0) {
        this.setState({
          [row.id]: {
            ...row,
            observacaoIATF: this.state[row.id].observacaoIATF,
            eccIATF: this.state[row.id].eccIATF,
            gnhr: this.state[row.id].gnhr,
            cioRetirada: this.state[row.id].cioRetirada,
            temperamento: this.state[row.id].temperamento,
            partidaId: this.state.partidaForAll
              ? this.state.partidaForAll.id
              : null,
            usuarioId: this.state.userForAll && this.state.userForAll.id,
            codVaca: this.state[row.id].codVaca,
            codVaca_error: _.isEmpty(this.state[row.id].codVaca)
              ? REQUIRED_FIELD_MESSAGES.DEFAULT
              : null,
          },
        })
      }
    })
  }

  renderTitle() {
    return (
      <Fragment>
        Protocolo: {this.props.protocol.name}
        <br />
        Manejo: IATF
      </Fragment>
    )
  }

  getButtons() {
    return [
      {
        onClick: () => {
          const newState = { showingIncompleteError: true }
          this.setState(newState)
        },
        icon: <AddIcon />,
        label: 'Cadastrar Sêmen',
      },
      {
        onClick: () => {
          const newState = {
            showingIncompleteError: true,
            registerInseminator: true,
          }
          this.setState(newState)
        },
        icon: <AddIcon />,
        label: 'Adicionar Inseminador',
      },
      {
        onClick: () => {
          const newState =
            this.state.savedCows.length >= this.props.vacas.length
              ? {
                showingFinalizeConfirmation: true,
              }
              : {
                showingIncompleteError: true,
              }
          this.setState(newState)
        },
        icon: <DoneIcon />,
        disabled: (this.state.savedCows.length >= this.props.vacas.length ? false : true) || this.props.users[0].roles[0]?.name === 'Cliente (somente visualização)',
        label: 'Finalizar',
      },
    ]
  }

  getPaths() {
    const uniqueCorrals = _.uniq(this.props.corrals.map((corral) => corral.id))
    const extraPath = this.props.farm.retiro &&
      uniqueCorrals.length === 1 && {
      route: `/farms/${this.props.farm.id}/corrals/${uniqueCorrals[0]}`,
      label: formatName(
        _.find(this.props.corrals, { id: uniqueCorrals[0] }).nome,
        RETIRO
      ),
    }
    const batchPath =
      this.props.batches.length === 1
        ? {
          route: `/farms/${this.props.farm.id}/corrals/${this.props.batches[0].retiroId
            }/batches/${this.props.batches[0].originalBatchId
              ? this.props.batches[0].originalBatchId
              : this.props.batches[0].id
            }`,
          label: formatName(this.props.batches[0].nomeLote, LOTE),
        }
        : {
          label: 'Vários Lotes',
        }

    return _.compact([
      {
        route: `/farms/${this.props.farm.id}`,
        label: formatName(this.props.farm.nome, FAZENDA),
      },
      extraPath,
      batchPath,
      {
        label: 'IATF',
      },
    ])
  }

  getTableColumnsData() {
    return [
      {
        name: 'Matriz (ID)',
        type: CELL_TYPES.FIELD,
        field: (row, index) => this.renderMatriz(row, index),
        grid: 4,
      },
      {
        name: 'Manejo',
        type: CELL_TYPES.FIELD,
        field: (row, index) => this.renderField(row, index),
        grid: 8,
      },
    ]
  }

  @track((props, state) => (
    state.registerInseminator
      ? { action: 'Entrou na página de Adicionar Inseminador' }
      : { action: 'Entrou na página de Cadastro de Sêmen' }
  ))
  handleAddSemenOrInseminador = () => {
    !this.props.isMerging && !this.state.registerInseminator
      ? this.props.history.push(
        `/bulls/create-for-all/batch/${this.props.batchIds[0]}/management/iatf`
      )
      : !this.props.isMerging && this.state.registerInseminator
        ? this.props.history.push(
          `/farms/${this.props.farm.id}/update`,
          { from: 'manejo page' }
        )
        : this.props.history.push(
          `/bulls/create-for-all/batch/${this.props.batchIds
            .map((batch) => batch)
            .join(';')}/management/iatf`
        )
  }

  render() {
    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={this.renderTitle()}
          buttons={this.getButtons()}
          paths={this.getPaths()}
        />
        <MainContainer
          hasSearch
          hasValue={this.state.searchValue !== '' ? true : false}
          focusSearch={this.state.focusSearch}
          clearSearch={this.state.clearSearch}
          handleSearch={(e) => this.handleSearch(e, false)}
          useVoiceRecognition
          preSearchChildren={this.handleManejosHeader()}
          alert={this.state.searchError}
          cowsValues={this.state.searchInputCow}
          hasChangeCowsToBatch={
            this.state.searchInputCow &&
              !this.props.isResync &&
              this?.state?.searchInputCow[0]?.iatf === 0
              ? true
              : false
          }
          changeCowsToBatch={(item) =>
            this.setState({
              showingChangeCowsToBatchConfirmation: true,
              selectedCowToChangeToBatch: item,
            })
          }
        >
          <div className='grid grid-iatf p-padding-top-1'>
            <div className='grid-item p-12 t-6 d-4'>
              <Autocomplete
                id={`semen_all`}
                options={this.props.partidas}
                value={this.state.partidaForAll || null}
                label='Sêmen'
                getOptionLabel={this.autocompleteOptionLabelUsingCustomHandlerSavingID(
                  this.props.partidas,
                  this.renderBullLabel
                )}
                onChange={(e, value) => {
                  this.setState({
                    partidaForAll: value,
                  })
                }}
                tabIndex={this.state.searchValue !== '' ? -1 : null}
              />
            </div>

            <div className='grid-item p-12 t-6 d-4'>
              <Autocomplete
                id={`inseminador_all`}
                options={this.props.users}
                value={this.state.userForAll || null}
                label='Inseminador'
                onChange={(e, value) => {
                  this.setState({
                    userForAll: value,
                  })
                }}
                getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                  this.props.users,
                  'nomeCompleto'
                )}
                tabIndex={this.state.searchValue !== '' ? -1 : null}
              />
            </div>

            <div className='grid-item p-12 t-7 t-offset-5 d-4'>
              <Button
                label='Preencher Todas'
                style={{
                  width: '48%',
                  height: '46px',
                  marginTop: '10px',
                  padding: 0,
                }}
                onClick={() => this.aplyForAll()}
                tabIndex={this.state.searchValue !== '' ? -1 : null}
              />
              <Button
                label='Salvar todas'
                style={{
                  width: '48%',
                  marginLeft: '4%',
                  height: '46px',
                  marginTop: '10px',
                }}
                disabled={
                  this.state.savedCows.length === this.props.vacas.length || this.props.users[0].roles[0]?.name === 'Cliente (somente visualização)'
                }
                onClick={() => this.setState({ saveAllConfirm: true })}
                tabIndex={this.state.searchValue !== '' ? -1 : null}
              />
            </div>

            <div className='d-12 p-12'>
              <ResponsiveTable
                style={{ marginTop: '30px' }}
                columns={this.getTableColumnsData()}
                data={this.state.selectedVacas}
                rowsPerPage={10}
                className='table-iatf'
              />
            </div>
          </div>

          <Prompt
            visible={this.state.showingChangeCowsToBatchConfirmation}
            title={changeCowsToBatchNotes.title}
            message={changeCowsToBatchNotes.text}
            buttons={[
              {
                label: 'Cancelar',
                onClick: () =>
                  this.setState({
                    showingChangeCowsToBatchConfirmation: false,
                  }),
              },
              {
                label: 'Mover',
                onClick: () =>
                  this.changeCowsToBatch(
                    this.state.selectedCowToChangeToBatch
                  ),
              },
            ]}
          />

          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
              Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false,
              },
            ]}
          />

          <Prompt
            visible={this.state.saveAllConfirm}
            title='Confirmação:'
            message={`Deseja realmente salvar todas?`}
            buttons={[
              {
                label: 'Não',
                onClick: () =>
                  this.setState({
                    saveAllConfirm: false,
                  }),
              },
              {
                label: 'Sim',
                onClick: () => this.saveAll(),
              },
            ]}
          />

          <Prompt
            visible={this.state.showingFinalizeConfirmation}
            title='Deseja Finalizar?'
            message='Uma vez finalizado os dados não salvos serão perdidos.'
            buttons={[
              {
                label: 'Não',
                onClick: () =>
                  this.setState({
                    showingFinalizeConfirmation: false,
                    confirmedBackupDownload: true,
                  }),
              },
              {
                label: 'Sim',
                onClick: this.state.confirmedBackupDownload
                  ? () => this.finalize(true)
                  : () =>
                    this.setState({
                      showingFinalizeConfirmation: false,
                      showingNoBackupConfirmation: true,
                    }),
              },
            ]}
          />

          <Prompt
            visible={this.state.showingNoBackupConfirmation}
            title='Atenção!'
            message='O comprovante de manejo pode ser necessário durante um eventual cenário de perda de dados. Não baixar o comprovante poderá dificultar a recuperação das informações desse manejo. Deseja continuar mesmo assim?'
            buttons={[
              {
                label: 'Cancelar',
                onClick: () =>
                  this.setState({
                    showingNoBackupConfirmation: false,
                    showingFinalizeConfirmation: true,
                  }),
              },
              {
                label: 'Sim',
                onClick: () => this.finalize(false),
              },
            ]}
          />

          <Prompt
            visible={this.state.showingIncompleteError}
            title='Aviso!'
            message='Tenha certeza que salvou todas as informações preenchidas antes de continuar.'
            buttons={[
              {
                label: 'CANCELAR',
                onClick: () => this.setState({ showingIncompleteError: false }),
              },
              {
                label: 'CONTINUAR',
                onClick: this.handleAddSemenOrInseminador
              },
            ]}
          />
          <Prompt
            visible={!this.props.redirected && this.state.isntIatfDay}
            title='Aviso'
            message={`De acordo com o protocolo ${this.props.protocol.name}, a data da IATF está definida para o dia ${this.state.dateIatf} (${this.state.iatfWeekDay}). Deseja realmente realizar a inseminação hoje?`}
            buttons={[
              {
                label: 'Voltar',
                onClick: () =>
                  this.props.corrals[0].nome === 'DEFAULT'
                    ? window.location.assign(`/farms/${this.props.farm.id}`)
                    : window.location.assign(
                      `/farms/${this.props.farm.id}/corrals/${this.props.corrals[0].id}`
                    ),
                autoFocus: false,
              },
              {
                label: 'Sim',
                onClick: () => this.setState({ isntIatfDay: false }),
              },
            ]}
          />
        </MainContainer>
      </Container>
    )
  }
}

async function getFarms(user, isAdministrador) {
  if (!user) {
    return []
  }

  const repository = new Repository(AVAILABLE_ENTITIES.FARMS)
  const farmsData = isAdministrador
    ? await repository.getDeleted()
    : await repository.getByParamDeleted('user_id', user.id)
  const relatedFarmsData = isAdministrador
    ? { success: true, response: [] }
    : await repository.getDeletedByRelation(
      'farms__rel__users',
      'user_id',
      user.id
    )

  if (farmsData.success && relatedFarmsData.success) {
    return _.uniqBy(
      farmsData.response.concat(relatedFarmsData.response),
      (farm) => farm.id
    )
  }
  return []
}

function omit_deadCows(vaca, vacasMortas) {
  let morta = false
  vacasMortas.map((vacaMorta) => {
    if (vacaMorta === vaca.id) {
      morta = true
    }
  })

  if (vaca.omit_from_dg_final || morta) {
    return false
  }
  return true
}

const propsFromDatabase = async props => {
  let deadCows = []

  const loggedUser = await getLoggedUser()
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    loggedUser.userId
  )

  const batchIds = props.match.params.id.split(';')
  const redirected = props.match.params.redirected?.split(';')

  const batches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'id',
    Q.oneOf(batchIds)
  )

  const corrals = await utils.getWithParam(
    AVAILABLE_ENTITIES.CORRALS,
    'id',
    Q.oneOf(_.uniq(batches.map((batch) => batch.retiroId)))
  )

  const isMerging = batchIds.length > 1

  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    corrals[0].fazendaId
  )

  const days = await utils.getDomainValuesBy('Dias')
  const protocol = await utils.getElement(
    AVAILABLE_ENTITIES.PROTOCOLS,
    batches[0].protocoloId
  )
  const protocolDays = protocol
    ? _.sortBy(
      _.uniq(
        protocol.managementProtocols.map((managementProtocol) =>
          _.find(days, { id: managementProtocol.day })
        )
      ),
      [
        (dia) =>
          dia.valor.startsWith('D') ? parseInt(dia.valor.substring(1)) : 100,
      ]
    )
    : []
  const currentDay = _.last(protocolDays)

  const temperamento = _.sortBy(await utils.getDomainValuesBy('Temperamento'), [
    (temperamento) => temperamento.valor.trim().toLowerCase() !== 'andando',
    (temperamento) => temperamento.valor.trim().toLowerCase() !== 'marchando',
    (temperamento) => temperamento.valor.trim().toLowerCase() !== 'correndo',
  ])

  let vacasAtuais = _.filter(
    await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      Q.oneOf(batchIds)
    ),
    (vaca) => !vaca.omit_from_dg_final
  )

  for (const vaca of vacasAtuais) {
    vaca.lote = _.find(batches, { id: vaca.loteId }).nomeLote

    let previousObs = []

    if (!_.isEmpty(vaca.observacaoD0) && vaca.observacaoD0 !== '[N/A]') {
      previousObs.push({
        manejo: 'D0',
        obs: format.sanitizeObs(vaca.observacaoD0)
      })
    }

    const dns = await utils.getWithParam(
      AVAILABLE_ENTITIES.DNS,
      'vacaId',
      vaca.id
    )
    if (dns.length > 0) {
      const sortedObs = _.sortBy(
        _.compact(
          dns.map(
            dn =>
              !_.isEmpty(dn.observacao) &&
              dn.observacao !== '[N/A]' && {
                manejo: _.find(days, { id: dn.dia }).valor,
                obs: format.sanitizeObs(dn.observacao)
              }
          )
        ),
        [
          obs =>
            obs.manejo.startsWith('D') ? parseInt(obs.manejo.substring(1)) : 100
        ]
      )
      previousObs = previousObs.concat(sortedObs)
    }

    if (previousObs.length > 0) {
      vaca.previousObs = previousObs
    }
  }

  vacasAtuais.map((vaca) => {
    let obs = (vaca.previousObs) ?
      vaca.previousObs[vaca.previousObs.length - 1].obs : null
    if ((obs && (obs === 'Animal morreu' || obs === 'Matriz Descarte') && !deadCows.indexOf(vaca.id) >= 0)
      || (vaca.observacaoD0 === 'Animal morreu' || vaca.observacaoD0 === 'Matriz Descarte')
      || (vaca.observacaoDG === 'Animal morreu')) {
      deadCows.push(vaca.id)
    }
  })

  let vacas = _.filter(
    await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      Q.oneOf(batchIds)
    ),
    vaca => omit_deadCows(vaca, deadCows)
  )

  let vacasIatf = _.filter(
    await utils.getWithParam(
      AVAILABLE_ENTITIES.IATFS,
      'batch_id',
      Q.oneOf(batchIds)
    )
  )

  for (const vaca of vacas) {
    vaca.lote = _.find(batches, { id: vaca.loteId }).nomeLote

    let previousObs = []

    if (!_.isEmpty(vaca.observacaoD0) && vaca.observacaoD0 !== '[N/A]') {
      previousObs.push({
        manejo: 'D0',
        obs: format.sanitizeObs(vaca.observacaoD0),
      })
    }

    const dns = await utils.getWithParam(
      AVAILABLE_ENTITIES.DNS,
      'vacaId',
      vaca.id
    )

    if (dns.length > 0) {
      const sortedObs = _.sortBy(
        _.compact(
          dns.map(
            (dn) =>
              !_.isEmpty(dn.observacao) &&
              dn.observacao !== '[N/A]' && {
                manejo: _.find(days, { id: dn.dia }).valor,
                obs: format.sanitizeObs(dn.observacao),
              }
          )
        ),
        [
          (obs) =>
            obs.manejo.startsWith('D')
              ? parseInt(obs.manejo.substring(1))
              : 100,
        ]
      )
      previousObs = previousObs.concat(sortedObs)
    }

    if (previousObs.length > 0) {
      vaca.previousObs = previousObs
    }
  }

  const isAdministrator = _.find(
    loggedUser.roles,
    (el) => el === 'Administrador'
  )

  const farms = await getFarms(user, isAdministrator)

  const partidas = await utils.getWithParam(
    AVAILABLE_ENTITIES.PARTIDA,
    'farm_id',
    farm.id)

  const bulls = await utils.getWithParam(
    AVAILABLE_ENTITIES.BULLS_NEW,
    'id',
    Q.oneOf(partidas.map(f => f.touroId))
  )

  return {
    batchIds,
    redirected,
    vacasIatf,
    batches,
    vacas,
    corrals,
    loteId: batches[0].id,
    isMerging,
    isResync: isMerging || (batches.length === 1 && batches[0].isResync),
    farm,
    protocol,
    users: await utils.getWithRelation(
      AVAILABLE_ENTITIES.USERS,
      'farms__rel__users',
      'farm_id',
      corrals[0].fazendaId
    ),
    bulls,
    partidas,
    currentDay,
    ecc: _.sortBy(await utils.getDomainValuesBy('ECC'), [
      (ecc) => parseFloat(ecc.valor),
    ]),
    gnhr: await utils.getDomainValuesBy('GnRH'),
    cio: await utils.getDomainValuesBy('Cio'),
    temperamento,
  }
}

export default utils.withPropsFromDatabase(propsFromDatabase, withRouter(IATF))
