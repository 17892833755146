import { date, field, readonly, relation } from '@nozbe/watermelondb/decorators'

import { Model } from '@nozbe/watermelondb'

export const modelToDTOMapping = {
  identical: ['data', 'codigo'],
  references: [
    {
      dtoName: 'fazendaId',
      modelName: 'farm_id'
    },
    {
      dtoName: 'touroId',
      modelName: 'bull_id'
    },
    {
      dtoName: 'partidaOriginalId',
      modelName: 'partida_original_id'
    },
    {
      dtoName: 'partidaOriginal',
      modelName: 'partida_original'
    },
  ]
}

export default class Partidas extends Model {
  static table = 'partida'

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields - DTO
  @field('data') data
  @field('codigo') codigo
  // @field('cod_partida') codPartida
  @field('partida_original') partidaOriginal
  @field('partida_original_id') partidaOriginalId

  //Foreign Relations
  @relation('farms', 'farm_id') farm
  @relation('bulls_new', 'bull_id') bull
}
