import * as SCREEN_CONSTANTS from '../screen/constants'
import * as USER_CONSTANTS from './constants'
import * as api from '../api/actions'

import { Q } from '@nozbe/watermelondb'
import _ from 'lodash'
import database from '../../database'

const startLoading = { type: SCREEN_CONSTANTS.START_LOADING }
const stopLoading = { type: SCREEN_CONSTANTS.END_LOADING }

export const listUsers = () => {
  return async dispatch => {
    dispatch(startLoading)

    const user = await api.get(dispatch, 'users', `Account`)

    dispatch({
      type: USER_CONSTANTS.LIST_USER,
      data: user
    })

    dispatch(stopLoading)
  }
}

export const getUser = ({ id }) => {
  return async dispatch => {
    dispatch(startLoading)

    const user = await api.get(dispatch, 'users', `Account/${id}`)

    dispatch({
      type: USER_CONSTANTS.SELECT_USER,
      data: user
    })

    dispatch(stopLoading)
  }
}

export const getRoles = () => {
  return async dispatch => {
    dispatch(startLoading)

    const roles = await api.get(dispatch, 'users', `Profile/`)

    dispatch({
      type: USER_CONSTANTS.LIST_ROLES,
      data: roles
    })

    dispatch(stopLoading)
  }
}
