import { LocalStorageHelper } from './localStorageHelper'

export const getDifferenceAboutStorageAndCurrentDate = (key: string) => {
  const storageDate = LocalStorageHelper.get(key)
  const difference = new Date().valueOf() - new Date(storageDate).valueOf()

  if (difference >= 1000 * 60 * 60 * 24) {
    //24h
    return true
  }
  return false
}
