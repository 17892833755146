export const childbirthPredictionIATF = (dateDG: Date) => {
  const birthDate = new Date(dateDG).setDate(new Date(dateDG).getDate() + 285)
  const formatDate = new Date(birthDate).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })

  if (!dateDG) {
    return ''
  }

  return formatDate
}

export const childbirthPredictionBull = (
  dateDGFinal: Date,
  daysGestationBull: number | null
) => {
  const birthDate = new Date(dateDGFinal).setDate(
    new Date(dateDGFinal).getDate() +
      285 -
      (daysGestationBull === null ? 0 : daysGestationBull)
  )
  const formatDate = new Date(birthDate).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })

  if (!dateDGFinal) {
    return ''
  }

  return formatDate
}
