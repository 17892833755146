import _ from 'lodash'

type getPathsProps = {
  path: {
    farmId: string
    farm: string
    corralId: string
    corral: string
    batchId: string
    batch: string
    hasCorralDefault: boolean
  }
  isMerging: boolean
  label: string
}

export const getPaths = ({ path, isMerging, label }: getPathsProps) =>
  _.compact([
    {
      route: `/farms/${path.farmId}`,
      label: path.farm,
    },
    !path.hasCorralDefault && {
      route: `/farms/${path.farmId}/corrals/${path.corralId}`,
      label: path.corral,
    },
    isMerging
      ? { label: path.batch }
      : {
          route: `/farms/${path.farmId}/corrals/${path.corralId}/batches/${path.batchId}`,
          label: path.batch,
        },
    {
      label,
    },
  ])
