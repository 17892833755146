/* eslint-disable eqeqeq */

import * as batches from '../../utils/batches'
import {
  ALL_FIELDS_TO_EXPORT,
  POWER_BI_FIELDS_TO_EXPORT,
} from '../../utils/constants'
import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import { FAZENDA, RETIRO, formatName } from '../../utils/formHelper'
import React, { Component } from 'react'
import ResponsiveTable, { CELL_TYPES } from '../../components/ResponsiveTable'
import { batch, connect } from 'react-redux'
import {
  currentDayIsD0,
  currentDayIsD0IATF,
  currentDayIsD0Ressinc,
  currentDayIsDG,
  currentDayIsDGD0,
  currentDayIsDGDN,
  currentDayIsDGFinal,
  currentDayIsDN,
  currentDayIsIatf,
  currentDayIsRessinc,
  managementsOver,
} from '../../utils/days'
import { endLoading, startLoading } from '../../redux/screen/actions'

import AddIcon from '@material-ui/icons/Add'
import Autocomplete, {
  autocompleteDefaultOnChange,
  autocompleteDefaultOnChangeSavingID,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteOptionLabelUsingCustomFieldSavingID,
} from '../../components/material/Autocomplete'
import CallMissedOutgoingRoundedIcon from '@material-ui/icons/CallMissedOutgoingOutlined'
import Container from '@material-ui/core/Container'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { LocalStorageHelper } from '../../utils/localStorageHelper'
import MainContainer from '../../components/MainContainer'
import MobileCard from '../../components/MobileCard'
import Prompt from '../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined'
import SyncIcon from '@material-ui/icons/Sync'
import TextField from '../../components/material/TextField'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import TrainingsButton from '../Trainings/TrainingsButton'
import _ from 'lodash'
import { getCorralFile } from '../../redux/reports/actions.js'
import { getLoggedUser } from '../../redux/auth/actions'
import hasInternet from '../../utils/recognizeInternetConnection'
import { idConditionDGFinal } from '../../utils/idsHelper'
import moment from 'moment'
import { pullFarms } from '../../syncWorker'
import { reportExportWarning } from '../../utils/notesHelper'
import track from 'react-tracking'
import { verify } from '../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'
import Button, { COLORS } from '../../components/material/Button'

@track(() => ({
  page: 'Listagem de Lotes - Fazenda com Retiro',
  date: new Date(),
}))
class Corral extends Component {
  constructor(props) {
    super(props)
    const sortedBatches = this.props.batches.sort((a, b) =>
      a.nomeLote.localeCompare(b.nomeLote)
    )

    this.state = {
      allBatches: sortedBatches,
      selectedBatches: sortedBatches,
      deleteConfirmationOpened: false,
      selectedBatch: null,
      selectedParentBatch: null,
      noConnection: false,
      vacas: [],
      d0OutOfDay: false,
      reportExportWarning: true,
      blockingAdvice: null,
      showFields: false,
      allFieldsOptionsExport: ALL_FIELDS_TO_EXPORT,
      selectedFieldsOptionsExport:
        JSON.parse(LocalStorageHelper.get('ProGerarSavedFieldsToExport')) ?? [],
      currentBatch: null,
      backToPreviousRessincConfirmationOpened: false,
      erroModalOpened: false,
      filtersVisible: false,
      filters: {
        iatfFilter: '',
        protocoloId: null,
        processoFilter: ''
      },
      days: []
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.deleteBatch = this.deleteBatch.bind(this)
    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)
    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
    this.autocompleteOptionLabelUsingCustomFieldSavingID =
      autocompleteOptionLabelUsingCustomFieldSavingID.bind(this)

    this.getCowsByBatchId()
    this.getIatfDay()
    this.getProtocols();
    // this.getDays();
  }

  componentDidMount() {
    if (!!!LocalStorageHelper.get('ProGerarSavedFieldsToExport')) {
      LocalStorageHelper.add(
        'ProGerarSavedFieldsToExport',
        JSON.stringify(ALL_FIELDS_TO_EXPORT)
      )
    }
  }

  async getCowsByBatchId() {
    let vacas = await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      Q.oneOf(
        this.props.batches.map((b) =>
          b.originalBatchId ? b.originalBatchId : b.id
        )
      )
    )

    this.setState({ vacas })
  }

  async getIatfDay() {
    const updatedSelectedBatches = this.state.selectedBatches.map(
      async (value, index) => {
        const protocol = await utils.getElement(
          AVAILABLE_ENTITIES.PROTOCOLS,
          this.props.batches[index].protocoloId
        )
        const managementIds = protocol.managementProtocols.map(
          (manejo) => manejo.day
        )
        const daysManagement = await utils.getWithParam(
          AVAILABLE_ENTITIES.DOMAIN_VALUES,
          'id',
          Q.oneOf(managementIds)
        )
        const dayBatch = daysManagement
          ? parseInt(
            daysManagement
              .sort(
                (a, b) =>
                  parseInt(b.valor.replace(/\D/g, '')) -
                  parseInt(a.valor.replace(/\D/g, ''))
              )[0]
              .valor.replace(/\D/g, '')
          )
          : 0

        return {
          ...value,
          dataToIATF: moment
            .utc(
              this.props.batches[index].dataIatf._d.valueOf() +
              dayBatch * 24 * 3600 * 1000
            )
            .format('DD/MM/YYYY'),
        }
      }
    )
    const resolvedSelectedBatches = await Promise.all(
      updatedSelectedBatches
    ).then((values) => values)

    this.setState({ selectedBatches: resolvedSelectedBatches })
  }

  async getProtocols() {
    const protocolsFilter = await utils.getAll(AVAILABLE_ENTITIES.PROTOCOLS);       
    const filteredProtocols = protocolsFilter.filter(
      (protocol) => this.state.allBatches.some((batch) => batch.protocoloId === protocol.id)
    );

    const days = await utils.getDomainValuesBy('Dias');
    let daysValues = [];

    filteredProtocols.forEach((protocol) => {
      protocol.managementProtocols.forEach((managementProtocol) => {
        const dayFound = _.find(days, { id: managementProtocol.day });
        if (dayFound && !daysValues.includes(dayFound.valor)) {
          daysValues.push(dayFound.valor);
        }
      });
    });

    const allDays = [...new Set(daysValues.concat(['DG', 'DG Final', 'IATF']))];

    this.setState({
      days: allDays,
      protocolsFilter: filteredProtocols 
    });

  }

  checkBatchAccuracy(row) {
    let vacaPrenhe = this.props.condicaoDG.filter(
      (cdg) => cdg.valor === 'Prenhe'
    )[0]
    let batchCows = null
    let prenheCows = null

    if (row.originalBatchId != null) {
      batchCows = this.state.vacas.filter(
        (v) => v.loteId === row.originalBatchId
      )
      prenheCows = batchCows.filter((v) => v.condicaoDG === vacaPrenhe.id)
    } else {
      batchCows = this.state.vacas.filter((v) => v.loteId === row.id)
      prenheCows = batchCows.filter((v) => v.condicaoDG === vacaPrenhe.id)
    }

    if (prenheCows.length === batchCows.length) {
      return true
    } else {
      return false
    }
  }

  handleSearch(e) {
    const val = e.target.value
    if (_.isEmpty(val)) {
      this.setState({ selectedBatches: this.state.allBatches })
    } else {
      const selectedBatches = this.state.allBatches.filter(
        (x) => x.nomeLote.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      this.setState({ selectedBatches })
    }
  }

  handleToggleFilters = () => {
    this.setState({ filtersVisible: !this.state.filtersVisible });
    this.cleanFilters();
  };

  applyFilters() {
    const { iatfFilter, protocoloId, processoFilter } = this.state.filters;
    let FiltersBatches = this.state.allBatches;

    // Filtrar por Iatf
    if (!_.isEmpty(iatfFilter)) {    
      FiltersBatches = FiltersBatches.filter(
        (data) => data.iatfCount == iatfFilter
      );
    }

    // Filtrar por Protocolo
    if (protocoloId) {
      FiltersBatches = FiltersBatches.filter(
        (data) => data.protocoloId == protocoloId.id
      );
    }

    // Filtrar pela Processo Atual
    if (!_.isEmpty(processoFilter)) {
      FiltersBatches = FiltersBatches.filter((row) => {
        const processType = row.pastDGFinal
          ? 'DG Final'
          : row.pastDG
            ? 'DG'
            : row.lastDoneDay
              ? row.lastDoneDayHasIatf
                ? 'IATF'
                : row.lastDoneDay.valor
              : '';

        return processType === processoFilter;
      });
    }

    this.setState({ selectedBatches: FiltersBatches });
  }

  cleanFilters() {
    this.setState({
      filters: {
        iatfFilter: '',
        protocoloId: null,
        processoFilter: ''
      }
    }, this.applyFilters);
  }

  @track({ action: 'Clicou no botao de relatório' })
  async verifyConnection() {
    var isConnected = true //await verifyConnectionUser()

    if (isConnected) {
      this.props.history.push(
        `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}/report`
      )
    } else {
      alert(
        'Falha ao conectar. Esta funcionalidade não pode ser acessada no modo offline!'
      )
    }
  }

  @track((props, state, batch) => ({
    action: 'Deletou um Lote',
    value: batch[0],
  }))
  async deleteBatch(selectedBatch) {
    if (selectedBatch === null) return

    const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)

    const request = await repository.delete(selectedBatch)

    const newState = {
      deleteConfirmationOpened: false,
      selectedBatch: null,
      selectedBatchName: null,
    }

    if (request.success) {
      console.log(`Delete of cow ${selectedBatch} successful`)
      newState.allBatches = this.state.allBatches.filter(
        (x) => x.id !== selectedBatch
      )
      newState.selectedBatches = this.state.allBatches.filter(
        (x) => x.id !== selectedBatch
      )
    } else {
      console.log(`Delete of cow ${selectedBatch} failed: ${request.exception}`)
    }

    var currentParentBatch = this.state.selectedParentBatch
    while (currentParentBatch != null || currentParentBatch != undefined) {
      var getNetxtParentBatch = await repository.getById(currentParentBatch)
      const request = await repository.delete(currentParentBatch)
      if (request.success) {
        currentParentBatch = getNetxtParentBatch.response.parentBatchId
      } else {
        console.log(
          `Delete of cow ${selectedBatch} failed: ${request.exception}`
        )
      }
    }

    this.setState(newState)
  }

  @track({ action: 'Clicou no botao de novo lote' })
  handleCreateNewBatch = () =>
    this.props.history.push(
      `/farms/${this.props.match.params.farmId}/corrals/${this.props.match.params.id}/batches/create`
    )

  @track({ action: 'Clicou no botao de mesclar' })
  handleMergeCorral = () => {
    this.props.history.push(
      `/farms/${this.props.match.params.farmId}/corrals/${this.props.match.params.id}/mergeBatches`
    )
  }

  @track({ action: 'Clicou no botao de exportar' })
  handleExport = (selectedFieldsOptionsExport, isPowerBI = false) => {
    if (hasInternet()) {
      getCorralFile(
        this.props.corral.id,
        selectedFieldsOptionsExport,
        isPowerBI
          ? `${formatName(this.props.corral.nome, RETIRO)}-powerbi`
          : formatName(this.props.corral.nome, RETIRO)
      )
    } else {
      this.setState({ noConnection: true })
    }
  }

  @track({ action: 'Clicou no botao de editar' })
  handleEdit = () =>
    this.props.history.push(
      `/farms/${this.props.match.params.farmId}/corrals/${this.props.match.params.id}/update`
    )

  getButtons() {
    return [
      {
        onClick: this.handleCreateNewBatch,
        icon: <AddIcon />,
        label: 'Lote',
      },
      {
        onClick: this.handleMergeCorral,
        label: 'Mesclar lotes',
      },
      {
        onClick: () => this.verifyConnection(),
        label: 'Relatório do Retiro',
      },
      {
        hasDropdown: true,
        buttons: [
          {
            onClick: () => this.handleExport([]),
            label: 'Exportar Retiro',
          },
          {
            onClick: () => this.setState({ showFields: true }),
            label: 'Exportação Personalizada',
          },
          {
            onClick: () => this.handleExport(POWER_BI_FIELDS_TO_EXPORT, true),
            label: 'Exportação para o POWER BI',
          },
        ],
        label: 'Exportação',
      },
      {
        onClick: this.handleEdit,
        label: 'Editar',
      },
    ]
  }

  getPaths() {
    return [
      {
        route: `/farms/${this.props.farm.id}`,
        label: formatName(this.props.farm.nome, FAZENDA),
      },
      {
        label: formatName(this.props.corral.nome, RETIRO),
      },
    ]
  }

  async fetchBatches(parentBatchId, batchRepository) {
    let batches = [];
    let currentParentBatchId = parentBatchId;

    while (currentParentBatchId) {
      const { response } = await batchRepository.getByParam('id', currentParentBatchId);

      if (response && response.length > 0) {
        batches.push(response[0]);
        currentParentBatchId = response[0].parentBatchId;
      } else {
        currentParentBatchId = null;
      }
    }

    return batches;
  }

  async backToPreviousRessinc(batchId, parentBatchId) {
    const batchRepository = new Repository(AVAILABLE_ENTITIES.BATCHES)
    const d0Repository = new Repository(AVAILABLE_ENTITIES.D0S)

    const currentIatf = this.state.currentBatch.iatfCount

    let batches = await this.fetchBatches(parentBatchId, batchRepository);
    batches.push(this.state.currentBatch)

    const allCowsToBatches = this.props.previousVacas.filter((item) => {
      return batches.some((batch) => item.loteId === batch.id);
    });

    try {
      const previousCowsGroupByIatf = allCowsToBatches.reduce(
        (groupByIatf, item) => {
          // Verificando se já existe uma chave para esse valor de iatf
          if (!groupByIatf[`iatf${item.iatf + 1}`]) {
            // Se não existir, criamos uma nova chave para esse valor de iatf
            groupByIatf[`iatf${item.iatf + 1}`] = []
          }
          // Adicionando o objeto ao array correspondente ao valor de iatf
          groupByIatf[`iatf${item.iatf + 1}`].push(item)

          return groupByIatf
        },
        {}
      )

      if (currentIatf === 2) {
        for (const cow of previousCowsGroupByIatf[`iatf${currentIatf - 1}`]) {
          cow.omit_from_dg_final = false

          await d0Repository.update(cow)
        }
      } else {
        const cowsWantToBeTrueOmmit = previousCowsGroupByIatf[
          `iatf${currentIatf - 2}`
        ]
          .filter((item) => {
            return (
              !item.omit_from_dg_final &&
              this.props.allBatchesIds[
                this.state.currentBatch.originalBatch.nomeLote
              ].includes(item.loteId)
            )
          })
          .map((item) => {
            return item.codVaca
          })

        for (const cow of previousCowsGroupByIatf[`iatf${currentIatf - 1}`]) {
          cow.omit_from_dg_final = cowsWantToBeTrueOmmit.includes(cow.codVaca)

          await d0Repository.update(cow)
        }
      }

      await batchRepository.delete(batchId, true)

      setTimeout(function () {
        window.location.reload()
      }, 1500)
    } catch (error) {
      console.log(error)

      this.props.endLoading()
      this.setState({ erroModalOpened: true })
    }
  }

  async SyncBatch(batches) {
    if (hasInternet()) {
      this.props.startLoading()
      await pullFarms([this.props.farm.id], {}, false, false, true, batches)
      this.props.endLoading()
    } else {
      this.setState({ noConnection: true })
    }

    if (hasInternet()) {
      setTimeout(function () {
        window.location.reload()
      }, 1500)
    }
  }

  @track((props, state, row) => ({
    action: 'Clicou no nome do lote, listagem de matrizes',
    value: row[0].id,
  }))
  handleEntryInBatch = (row) =>
    this.props.history.push(
      `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}/batches/${row.originalBatch.id}`
    )

  @track((props, state, row) => ({
    action: 'Entrou no Incluir Matriz',
    value: row[0].id,
  }))
  handleEntryInPageD0AfterResync = (row) =>
    this.props.history.push(`/D0AfterResync/${row.originalBatchId}`)

  @track((props, state, row) => ({
    action: 'Entrou na D0/IATF',
    value: row[0].id,
  }))
  handleEntryInPageD0IATF = (row) =>
    this.props.history.push(`/D0IATF/${row.id}`)

  @track((props, state, row) => ({ action: 'Entrou na D0', value: row[0].id }))
  handleEntryInPageD0 = (row) => {
    currentDayIsD0(row)
      ? this.props.history.push(`/D0/${row.id}`)
      : this.setState({
        selectedRowId: row.id,
        d0OutOfDay: true,
      })
  }

  @track((props, state, row) => ({
    action: 'Entrou na D0/Ressinc',
    value: row[0].id,
  }))
  handleEntryInPageD0Ressinc = (row) =>
    this.props.history.push(`/D0Resync/${row.id}`)

  @track((props, state, row) => ({ action: 'Entrou na DN', value: row[0].id }))
  handleEntryInPageDN = (row) => this.props.history.push(`/DN/${row.id}`)

  @track((props, state, row) => ({
    action: 'Entrou na IATF',
    value: row[0].id,
  }))
  handleEntryInPageIATF = (row) => this.props.history.push(`/IATF/${row.id}`)

  @track((props, state, row) => ({
    action: 'Entrou na Ressinc',
    value: row[0].id,
  }))
  handleEntryInPageRessinc = (row) =>
    this.props.history.push(`/Resync/${row.id}`)

  @track((props, state, row) => ({
    action: 'Entrou na DG',
    value: row[0].id,
    manejo: row[1],
  }))
  handleEntryInPageDG = (row, manejo) =>
    this.props.history.push(`/DG/${row.id}`, {
      manejo,
      farm: {
        id: this.props.farm.id,
        label: formatName(this.props.farm.nome, FAZENDA)
      },
      retiro: {
        id: this.props.corral.id,
        label: formatName(this.props.corral.nome, RETIRO)
      }
    })

  handleEntryInPageDGRessinc = (row, manejo) => {
    this.props.history.push(`/DGRessinc/${row.id}`, {
      manejo,
      farm: {
        id: this.props.farm.id,
        label: formatName(this.props.farm.nome, FAZENDA)
      },
      retiro: {
        id: this.props.corral.id,
        label: formatName(this.props.corral.nome, RETIRO)
      }
    })
  }

  @track((props, state, row) => ({
    action: 'Entrou na DG Final',
    value: row[0].id,
  }))
  handleEntryInPageDGFinal = (row) =>
    this.props.history.push(`/DGFinal/${row.id}`)

  @track((props, state, row) => ({
    action: 'Clicou no botao de editar Lote',
    value: row[0].id,
  }))
  handleEditBatch = (row) =>
    this.props.history.push(
      `/farms/${this.props.farm.id}/corrals/${row.retiroId}/batches/${row.id}/update`
    )

  getTableColumns() {
    return [
      {
        name: 'Lote',
        type: CELL_TYPES.LINK,
        sortBy: true,
        label: (row) => row.nomeLote,
        onClick: (row) => () => this.handleEntryInBatch(row),
      },
      {
        name: 'Protocolo',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: (row) => `${row.protocolName}`,
      },
      {
        name: 'IATF',
        type: CELL_TYPES.TEXT,
        label: (row) => row.iatfCount,
      },
      {
        name: 'Processo Atual',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: (row) =>
          row.pastDGFinal
            ? 'DG Final'
            : row.pastDG
              ? 'DG'
              : row.lastDoneDay
                ? row.lastDoneDayHasIatf
                  ? 'IATF'
                  : row.lastDoneDay.valor
                : '',
      },
      {
        name: 'Matrizes',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          row.originalBatchId
            ? this.props.allVacas.filter((v) => v.loteId === row.id).length
            : this.state.vacas.filter((v) => v.loteId === row.id).length,
      },
      {
        type: CELL_TYPES.FLOAT_BUTTONS,
        buttons: (row) =>
          !row.originalBatch?.matrizBloqueada
            ? !row.reservedBy ||
              (row.reservedBy && row.reservedBy === this.props.user.id)
              ? _.compact([
                //Incluir Matriz apos Ressinc
                row.isResync && {
                  label: 'Incluir Matriz',
                  onClick: () => this.handleEntryInPageD0AfterResync(row),
                },
                //D0/IATF
                currentDayIsD0IATF(row) && {
                  label: 'D0/IATF',
                  onClick: () => this.handleEntryInPageD0IATF(row),
                },
                //D0
                (currentDayIsD0(row) ||
                  currentDayIsDN(row) ||
                  currentDayIsIatf(row) ||
                  currentDayIsRessinc(row) ||
                  currentDayIsDGFinal(row)) &&
                !row.isResync && {
                  label: 'D0',
                  onClick: () => this.handleEntryInPageD0(row),
                },
                //DG/D0
                currentDayIsDGD0(row) && {
                  label: 'DG/D0',
                  onClick: () => this.handleEntryInPageDGRessinc(row, 'DG/D0'),
                },
                //D0Ressinc
                (currentDayIsD0Ressinc(row) ||
                  currentDayIsDGDN(row) ||
                  currentDayIsDN(row) ||
                  currentDayIsIatf(row) ||
                  currentDayIsRessinc(row) ||
                  currentDayIsDGFinal(row)) &&
                row.isResync &&
                row.isPrecoce && {
                  label: 'D0',
                  onClick: () => this.handleEntryInPageD0Ressinc(row),
                },
                //DN
                currentDayIsDN(row) && {
                  label: `${row.nextDay}`,
                  onClick: () => this.handleEntryInPageDN(row),
                },
                //IATF
                (currentDayIsIatf(row) ||
                  currentDayIsRessinc(row) ||
                  currentDayIsDGFinal(row)) && {
                  label: 'IATF',
                  onClick: () => this.handleEntryInPageIATF(row),
                },
                //RESSINC
                currentDayIsRessinc(row) && {
                  label: 'Ressinc',
                  disabled:
                    this.state.vacas.length > 0
                      ? this.checkBatchAccuracy(row)
                      : false,
                  onClick: () => this.handleEntryInPageRessinc(row),
                },
                //DGFinal
                currentDayIsDG(row) && {
                  label: `DG Final`,
                  onClick: () => this.handleEntryInPageDGFinal(row),
                },
                //DG
                (currentDayIsDG(row) || currentDayIsDGFinal(row)) && {
                  label: `DG`,
                  onClick: () => this.handleEntryInPageDG(row, 'DG'),
                },
                //DGFinal
                (currentDayIsDGFinal(row) || managementsOver(row)) && {
                  label: `DG Final`,
                  onClick: () => this.handleEntryInPageDGFinal(row),
                },
                //DG/DN
                currentDayIsDGDN(row) && {
                  label: `DG/${row.nextDay}`,
                  onClick: () => this.handleEntryInPageDGRessinc(row, `DG/${row.nextDay}`),
                },
                row.isResync &&
                !row.pastDGFinal && {
                  icon: (
                    <SwapHorizontalCircleOutlinedIcon
                      style={{ fontSize: 23 }}
                    />
                  ),
                  label: 'Voltar para Ressinc anterior',
                  onClick: () => {
                    if (!hasInternet()) {
                      this.setState({ noConnection: true })
                      return
                    }

                    this.setState({
                      backToPreviousRessincConfirmationOpened: true,
                      selectedBatch: row.id,
                      currentBatch: row,
                      selectedBatchName: row.nomeLote,
                      selectedParentBatch: row.parentBatchId,
                    })
                  }
                },
                {
                  icon: <SyncIcon style={{ fontSize: 23 }} />,
                  label: 'Sincronizar',
                  onClick: () => this.SyncBatch([row.originalBatch.id]),
                },
                {
                  icon: <EditIcon style={{ fontSize: 23 }} />,
                  label: 'Editar',
                  onClick: () => this.handleEditBatch(row),
                },
                {
                  icon: <DeleteIcon style={{ fontSize: 23 }} />,
                  label: 'Excluir',
                  onClick: () => {
                    this.setState({
                      deleteConfirmationOpened: true,
                      selectedBatch: row.id,
                      selectedBatchName: row.nomeLote,
                      selectedParentBatch: row.parentBatchId,
                    })
                  },
                  disabled:
                    this.props.user.roles[0].name ===
                    'Cliente (somente visualização)',
                },
              ])
              : _.compact([
                {
                  label: `Lote Reservado`,
                  disabled: true,
                  onClick: () => this.handleEntryInPageD0IATF(row),
                },
                row.isResync &&
                !row.pastDGFinal && {
                  icon: (
                    <SwapHorizontalCircleOutlinedIcon
                      style={{ fontSize: 23 }}
                    />
                  ),
                  label: 'Voltar para Ressinc anterior',
                  onClick: () => {
                    if (!hasInternet()) {
                      this.setState({ noConnection: true })
                      return
                    }

                    this.setState({
                      backToPreviousRessincConfirmationOpened: true,
                      selectedBatch: row.id,
                      currentBatch: row,
                      selectedBatchName: row.nomeLote,
                      selectedParentBatch: row.parentBatchId,
                    })
                  }
                },
                {
                  icon: <SyncIcon style={{ fontSize: 23 }} />,
                  label: 'Sincronizar',
                  onClick: () => this.SyncBatch([row.originalBatch.id]),
                },
                {
                  icon: <EditIcon style={{ fontSize: 23 }} />,
                  label: 'Editar',
                  onClick: () => this.handleEditBatch(row),
                },
                {
                  icon: <DeleteIcon style={{ fontSize: 23 }} />,
                  label: 'Excluir',
                  onClick: () => {
                    this.setState({
                      deleteConfirmationOpened: true,
                      selectedBatch: row.id,
                      selectedBatchName: row.nomeLote,
                      selectedParentBatch: row.parentBatchId,
                    })
                  },
                  disabled:
                    this.props.user.roles[0].name ===
                    'Cliente (somente visualização)',
                },
              ])
            : _.compact([
              {
                label: `Lote Bloqueado`,
                onClick: () => this.setState({ blockingAdvice: row }),
              },
              row.isResync &&
              !row.pastDGFinal && {
                icon: (
                  <SwapHorizontalCircleOutlinedIcon
                    style={{ fontSize: 23 }}
                  />
                ),
                label: 'Voltar para Ressinc anterior',
                onClick: () => {
                  if (!hasInternet()) {
                    this.setState({ noConnection: true })
                    return
                  }

                  this.setState({
                    backToPreviousRessincConfirmationOpened: true,
                    selectedBatch: row.id,
                    currentBatch: row,
                    selectedBatchName: row.nomeLote,
                    selectedParentBatch: row.parentBatchId,
                  })
                }
              },
              {
                icon: <SyncIcon style={{ fontSize: 23 }} />,
                label: 'Sincronizar',
                onClick: () => this.SyncBatch([row.originalBatch.id]),
              },
              {
                icon: <EditIcon style={{ fontSize: 23 }} />,
                label: 'Editar',
                onClick: () => this.handleEditBatch(row),
              },
              {
                icon: <DeleteIcon style={{ fontSize: 23 }} />,
                label: 'Excluir',
                onClick: () => {
                  this.setState({
                    deleteConfirmationOpened: true,
                    selectedBatch: row.id,
                    selectedBatchName: row.nomeLote,
                    selectedParentBatch: row.parentBatchId,
                  })
                },
                disabled:
                  this.props.user.roles[0].name ===
                  'Cliente (somente visualização)',
              },
            ]),
      },
    ]
  }

  getPrenhez() {
    const allCows = [
      ...new Map(
        this.state.vacas
          .filter((v) => v.omit_from_dg_final === false)
          .concat(
            this.props.allVacas.filter((v) => v.omit_from_dg_final === false)
          )
          .map((item) => [item['id'], item])
      ).values(),
    ]

    const percentageToPrenhe = this.state.selectedBatches.map((item) => {
      if (item.originalBatchId) {
        const total = allCows.filter(
          (v) =>
            (v.loteId === item.originalBatchId || v.loteId === item.id) &&
            v.condicaoDGFinal !== null
        ).length
        const prenhes = allCows
          .filter(
            (v) =>
              (v.loteId === item.originalBatchId || v.loteId === item.id) &&
              v.condicaoDGFinal !== null
          )
          .filter(
            (i) =>
              i.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
              i.condicaoDGFinal === idConditionDGFinal.pregnantBull
          ).length

        return Math.floor((prenhes / total) * 100 * 100) / 100 || 0
      } else {
        const total = allCows.filter(
          (v) => v.loteId === item.id && v.condicaoDGFinal !== null
        ).length
        const prenhes = allCows
          .filter((v) => v.loteId === item.id && v.condicaoDGFinal !== null)
          .filter(
            (i) =>
              i.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
              i.condicaoDGFinal === idConditionDGFinal.pregnantBull
          ).length

        return Math.floor((prenhes / total) * 100 * 100) / 100 || 0
      }
    })

    return percentageToPrenhe
  }

  getMobileData() {
    return {
      title: 'Lote',
      values: this.state.selectedBatches.map((item, index) => ({
        Lote: item.nomeLote,
        Protocolo: item.protocolName,
        'Data da IATF': item.dataToIATF,
        IATF: item.iatfCount,
        '% Prenhez': `${this.getPrenhez()[index]}%`,
        'Processo Atual': item.pastDGFinal
          ? 'DG Final'
          : item.pastDG
            ? 'DG'
            : item.lastDoneDay
              ? item.lastDoneDayHasIatf
                ? 'IATF'
                : item.lastDoneDay.valor
              : '-',
        Matrizes: item.originalBatchId
          ? this.props.allVacas.filter((v) => v.loteId === item.id).length
          : this.state.vacas.filter((v) => v.loteId === item.id).length,
        Actions: !item.originalBatch?.matrizBloqueada
          ? !item.reservedBy ||
            (item.reservedBy && item.reservedBy === this.props.user.id)
            ? _.compact([
              item.isResync && {
                label: 'Incluir Matriz',
                onClick: () => this.handleEntryInPageD0AfterResync(item),
              },
              currentDayIsD0IATF(item) && {
                label: 'D0/IATF',
                onClick: () => this.handleEntryInPageD0IATF(item),
              },
              //D0
              (currentDayIsD0(item) ||
                currentDayIsDN(item) ||
                currentDayIsIatf(item) ||
                currentDayIsRessinc(item) ||
                currentDayIsDGFinal(item)) &&
              !item.isResync && {
                label: 'D0',
                onClick: () => this.handleEntryInPageD0(item),
              },
              //DG/D0
              currentDayIsDGD0(item) &&
              item.isResync &&
              !item.isPrecoce && {
                label: 'DG/D0',
                onClick: () => this.handleEntryInPageDGRessinc(item, 'DG/D0'),
              },
              //D0Ressinc
              (currentDayIsD0Ressinc(item) ||
                currentDayIsDGDN(item) ||
                currentDayIsDN(item) ||
                currentDayIsIatf(item) ||
                currentDayIsRessinc(item) ||
                currentDayIsDGFinal(item)) &&
              item.isResync &&
              item.isPrecoce && {
                label: 'D0',
                onClick: () => this.handleEntryInPageD0Ressinc(item),
              },
              //DN
              currentDayIsDN(item) && {
                label: `${item.nextDay}`,
                onClick: () => this.handleEntryInPageDN(item),
              },
              //IATF
              (currentDayIsIatf(item) ||
                currentDayIsRessinc(item) ||
                currentDayIsDGFinal(item)) && {
                label: 'IATF',
                onClick: () => this.handleEntryInPageIATF(item),
              },
              //RESSINC
              currentDayIsRessinc(item) && {
                label: 'Ressinc',
                disabled:
                  this.state.vacas.length > 0
                    ? this.checkBatchAccuracy(item)
                    : false,
                onClick: () => this.handleEntryInPageRessinc(item),
              },
              //DGFinal
              currentDayIsDG(item) && {
                label: `DG Final`,
                onClick: () => this.handleEntryInPageDGFinal(item),
              },
              //DG
              (currentDayIsDG(item) || currentDayIsDGFinal(item)) && {
                label: `DG`,
                onClick: () => this.handleEntryInPageDG(item, 'DG'),
              },
              //DGFinal
              (currentDayIsDGFinal(item) || managementsOver(item)) && {
                label: `DG Final`,
                onClick: () => this.handleEntryInPageDGFinal(item),
              },
              //DG/DN
              currentDayIsDGDN(item) && {
                label: `DG/${item.nextDay}`,
                onClick: () => this.handleEntryInPageDGRessinc(item, `DG/${item.nextDay}`),
              },
              {
                icon: (
                  <CallMissedOutgoingRoundedIcon style={{ fontSize: 23 }} />
                ),
                label: 'Entrar na Lote',
                onClick: () => this.handleEntryInBatch(item),
              },
              item.isResync &&
              !item.pastDGFinal && {
                icon: (
                  <SwapHorizontalCircleOutlinedIcon
                    style={{ fontSize: 23 }}
                  />
                ),
                label: 'Voltar para Ressinc anterior',
                onClick: () => {
                  if (!hasInternet()) {
                    this.setState({ noConnection: true })
                    return
                  }

                  this.setState({
                    backToPreviousRessincConfirmationOpened: true,
                    selectedBatch: item.id,
                    currentBatch: item,
                    selectedBatchName: item.nomeLote,
                    selectedParentBatch: item.parentBatchId,
                  })
                }
              },
              {
                icon: <SyncIcon style={{ fontSize: 23 }} />,
                label: 'Sincronizar',
                onClick: () => this.SyncBatch([item.originalBatch.id]),
              },
              {
                icon: <EditIcon style={{ fontSize: 23 }} />,
                label: 'Editar',
                onClick: () => this.handleEditBatch(item),
              },
              {
                icon: <DeleteIcon style={{ fontSize: 23 }} />,
                label: 'Excluir',
                onClick: () => {
                  this.setState({
                    deleteConfirmationOpened: true,
                    selectedBatch: item.id,
                    selectedBatchName: item.nomeLote,
                    selectedParentBatch: item.parentBatchId,
                  })
                },
                disabled:
                  this.props.user.roles[0].name ===
                  'Cliente (somente visualização)',
              },
            ])
            : _.compact([
              {
                icon: (
                  <CallMissedOutgoingRoundedIcon style={{ fontSize: 23 }} />
                ),
                label: 'Entrar na Lote',
                onClick: () => this.handleEntryInBatch(item),
              },
              {
                label: `Lote Reservado`,
                disabled: true,
                onClick: () => this.handleEntryInPageD0IATF(item),
              },
              item.isResync &&
              !item.pastDGFinal && {
                icon: (
                  <SwapHorizontalCircleOutlinedIcon
                    style={{ fontSize: 23 }}
                  />
                ),
                label: 'Voltar para Ressinc anterior',
                onClick: () => {
                  if (!hasInternet()) {
                    this.setState({ noConnection: true })
                    return
                  }

                  this.setState({
                    backToPreviousRessincConfirmationOpened: true,
                    selectedBatch: item.id,
                    currentBatch: item,
                    selectedBatchName: item.nomeLote,
                    selectedParentBatch: item.parentBatchId,
                  })
                }
              },
              {
                icon: <SyncIcon style={{ fontSize: 23 }} />,
                label: 'Sincronizar',
                onClick: () => this.SyncBatch([item.originalBatch.id]),
              },
              {
                icon: <EditIcon style={{ fontSize: 23 }} />,
                label: 'Editar',
                onClick: () => this.handleEditBatch(item),
              },
              {
                icon: <DeleteIcon style={{ fontSize: 23 }} />,
                label: 'Excluir',
                onClick: () => {
                  this.setState({
                    deleteConfirmationOpened: true,
                    selectedBatch: item.id,
                    selectedBatchName: item.nomeLote,
                    selectedParentBatch: item.parentBatchId,
                  })
                },
                disabled:
                  this.props.user.roles[0].name ===
                  'Cliente (somente visualização)',
              },
            ])
          : [
            {
              icon: (
                <CallMissedOutgoingRoundedIcon style={{ fontSize: 23 }} />
              ),
              label: 'Entrar na Lote',
              onClick: () => this.handleEntryInBatch(item),
            },
            {
              label: `Lote Bloqueado`,
              onClick: () => this.setState({ blockingAdvice: item }),
            },
            item.isResync &&
            !item.pastDGFinal && {
              icon: (
                <SwapHorizontalCircleOutlinedIcon
                  style={{ fontSize: 23 }}
                />
              ),
              label: 'Voltar para Ressinc anterior',
              onClick: () => {
                if (!hasInternet()) {
                  this.setState({ noConnection: true })
                  return
                }

                this.setState({
                  backToPreviousRessincConfirmationOpened: true,
                  selectedBatch: item.id,
                  currentBatch: item,
                  selectedBatchName: item.nomeLote,
                  selectedParentBatch: item.parentBatchId,
                })
              }
            },
            {
              icon: <SyncIcon style={{ fontSize: 23 }} />,
              label: 'Sincronizar',
              onClick: () => this.SyncBatch([item.originalBatch.id]),
            },
            {
              icon: <EditIcon style={{ fontSize: 23 }} />,
              label: 'Editar',
              onClick: () => this.handleEditBatch(item),
            },
            {
              icon: <DeleteIcon style={{ fontSize: 23 }} />,
              label: 'Excluir',
              onClick: () => {
                this.setState({
                  deleteConfirmationOpened: true,
                  selectedBatch: item.id,
                  selectedBatchName: item.nomeLote,
                  selectedParentBatch: item.parentBatchId,
                })
              },
              disabled:
                this.props.user.roles[0].name ===
                'Cliente (somente visualização)',
            },
          ],
      })),
      noDataMessage: 'Ainda não existe nenhum lote neste retiro.',
    }
  }

  render() {
    return (
      <Container>
        <TopBar title={formatName(this.props.corral.nome, RETIRO)} />
        <TitleBar
          title={formatName(this.props.corral.nome, RETIRO)}
          buttons={this.getButtons()}
          paths={this.getPaths()}
        />
        <TrainingsButton
          page={`/farms/${this.props.match.params.farmId}/corrals/${this.props.match.params.id}`}
        />
        <MainContainer
          containerTitle={`Lotes`}
          handleSearch={this.handleSearch}
          hasSearchWithFilterButtton
          handleButtonFilter={this.handleToggleFilters}
        >
          {this.state.filtersVisible && (
            <div className='grid grid-batch-form'>
              <div className='grid-item p-12 header-info-left'>
                <p className='p-font-weight-700'>Outros Filtros</p>
              </div>
              <div className="grid-item p-12 t-6 d-4">
                <Autocomplete
                  id='protocolFilter'
                  options={this.state.protocolsFilter}
                  value={this.state.filters.protocoloId || null}
                  getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                    this.state.protocolsFilter,
                    'name'
                  )}
                  label='Protocolo'
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChange('protocolFilter')
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        protocoloId: value
                      }
                    }), this.applyFilters);
                  }}
                />
              </div>
              <div className="grid-item p-12 t-6 d-4">
                <TextField
                  id='iatfFilter'
                  label='IATF'
                  value={this.state.filters.iatfFilter || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        iatfFilter: value
                      }
                    }), this.applyFilters);
                  }}
                />
              </div>
              <div className="grid-item p-12 t-6 d-4">
                <Autocomplete
                  id='processoFilter'
                  options={this.state.days}
                  value={this.state.filters.processoFilter || null}
                  label='Processo Atual'
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChange('processoFilter')
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        processoFilter: value
                      }
                    }), this.applyFilters);
                  }}
                />
              </div>
              <div className="grid-item p-12 p-display-flex p-justify-space-between">
                <Button
                  color={COLORS.GREY}
                  label={'Limpar'}
                  type='button'
                  startIcon={<DeleteIcon />}
                  onClick={() => this.cleanFilters()}
                />
              </div>
            </div>
          )}
          {window.innerWidth > 767 ? (
            <ResponsiveTable
              columns={this.getTableColumns()}
              noDataMessage={`Ainda não existe nenhum lote neste retiro.`}
              data={this.state.selectedBatches}
              toDataExport={this.props}
              className='table-corrals'
              onSort={(sortedData) => this.setState({ selectedBatches: sortedData })}
            />
          ) : (
            <MobileCard data={this.getMobileData()} />
          )}

          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false,
              },
            ]}
          />

          <Prompt
            visible={this.state.showFields}
            title='Escolha os campos que deseja visualizar na exportação'
            className='hidewhenprinting'
            message={
              <div className='grid-item p-12 d-3 hidewhenprinting'>
                <Autocomplete
                  multiple
                  id='allFieldsOptionsExport'
                  defaultValue={
                    JSON.parse(
                      LocalStorageHelper.get('ProGerarSavedFieldsToExport')
                    ) ?? this.state.allFieldsOptionsExport
                  }
                  onChange={(_, value) => {
                    this.setState({
                      selectedFieldsOptionsExport: value.map((item) => item),
                    })
                    LocalStorageHelper.add(
                      'ProGerarSavedFieldsToExport',
                      JSON.stringify(value)
                    )
                  }}
                  filterSelectedOptions
                  options={this.state.allFieldsOptionsExport}
                  getOptionLabel={(option) => option}
                  style={{ width: '100%', backgroundColor: 'white' }}
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' />
                  )}
                />
              </div>
            }
            leftButton={{
              label: 'Redefinir',
              onClick: () => {
                this.setState({
                  selectedFieldsOptionsExport: [],
                  showFields: false,
                })
                LocalStorageHelper.add(
                  'ProGerarSavedFieldsToExport',
                  JSON.stringify(ALL_FIELDS_TO_EXPORT)
                )
              }
            }}
            buttons={[
              {
                label: 'Fechar',
                onClick: () => this.setState({ showFields: false }),
              },
              {
                label: 'Exportar',
                onClick: () => {
                  this.handleExport(this.state.selectedFieldsOptionsExport)
                  this.setState({ showFields: false })
                },
              },
            ]}
          />

          <Prompt
            visible={this.state.deleteConfirmationOpened}
            title='Confirmar Exclusão'
            message={`Deseja realmente excluir o lote (${this.state.selectedBatchName})?`}
            buttons={[
              {
                label: 'Não',
                onClick: () =>
                  this.setState({ deleteConfirmationOpened: false }),
              },
              {
                label: 'Sim',
                onClick: () => this.deleteBatch(this.state.selectedBatch),
              },
            ]}
          />

          <Prompt
            visible={this.state.d0OutOfDay}
            title='D0 já finalizado.'
            message={`Este lote já possui a etapa de D0 finalizada. Caso deseje adicionar novas matrizes, lembre-se de preencher os demais campos nas outras etapas de manejo.`}
            buttons={[
              {
                label: 'Cancelar',
                onClick: () =>
                  this.setState({ d0OutOfDay: false, selectedRowId: null }),
              },
              {
                label: 'Continuar',
                onClick: () =>
                  this.props.history.push(`/D0/${this.state.selectedRowId}`),
              },
            ]}
          />

          <Prompt
            visible={
              !!LocalStorageHelper.get('reportExportWarning') &&
              this.state.reportExportWarning
            }
            title={reportExportWarning.title}
            message={reportExportWarning.text}
            buttons={[
              {
                label: 'Ok',
                onClick: () => {
                  this.setState({ reportExportWarning: false })
                  LocalStorageHelper.remove('reportExportWarning')
                },
              },
            ]}
          />

          <Prompt
            visible={this.state.noConnection}
            title='Não é possível realizar esta ação!'
            message={`Você está sem internet no momento. Verifique sua conexão e tente novamente mais tarde.`}
            buttons={[
              {
                label: 'Ok',
                onClick: () => this.setState({ noConnection: false }),
              },
            ]}
          />

          <Prompt
            visible={this.state.blockingAdvice != null}
            title='Lote bloqueado!'
            message={
              this.state.blockingAdvice &&
              `Este lote se encontra bloqueado devido ao cadastro incompleto da Matriz: ${this.state.vacas.filter(
                (v) =>
                  v.id ==
                  this.state.blockingAdvice.originalBatch?.matrizBloqueada
              )[0].codVaca
              }, para que o lote seja desbloqueado, a matriz em questão deve ser excluida na tela do lote.`
            }
            buttons={[
              {
                label: 'Cancelar',
                onClick: () => this.setState({ blockingAdvice: null }),
              },
              {
                label: 'Ir para o Lote',
                onClick: () =>
                  this.props.history.push(
                    `/farms/${this.props.farm.id}/corrals/${this.state.blockingAdvice.retiroId}/batches/${this.state.blockingAdvice.originalBatch.id}`
                  ),
              },
            ]}
          />

          <Prompt
            visible={this.state.backToPreviousRessincConfirmationOpened}
            title='Confirmação de retorno para Ressinc Anterior'
            message={
              <div>
                <p>
                  Você tem certeza de que deseja retornar à Ressinc do lote:{' '}
                  <span
                    style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                  >
                    {this.state.selectedBatchName}
                  </span>
                  ?{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    (Ao fazer isso seu lote irá voltar para a IATF{' '}
                    {this.state.currentBatch?.iatfCount - 1})
                  </span>
                </p>
                <br />
              </div>
            }
            buttons={[
              {
                label: 'Não',
                onClick: () =>
                  this.setState({
                    backToPreviousRessincConfirmationOpened: false,
                  }),
              },
              {
                label: 'Sim',
                onClick: () => {
                  this.props.startLoading()

                  this.setState({
                    backToPreviousRessincConfirmationOpened: false,
                  })
                  this.backToPreviousRessinc(
                    this.state.selectedBatch,
                    this.state.selectedParentBatch
                  )
                },
              },
            ]}
          />

          <Prompt
            visible={this.state.erroModalOpened}
            title='Algo deu errado!'
            message='Tente novamente mais tarde.'
            buttons={[
              {
                label: 'Ok',
                onClick: () =>
                  this.setState({
                    erroModalOpened: false,
                  }),
              },
            ]}
          />
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async (props) => {
  const corral = await utils.getElement(
    AVAILABLE_ENTITIES.CORRALS,
    props.match.params.id
  )

  const allBatches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'corral_id',
    corral.id
  )

  const batchList = await batches.getBatchesByCorral(props.match.params.id)
  const allVacas = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    Q.oneOf(batchList.map((batch) => batch.id))
  )

  const previousVacas = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    Q.oneOf(allBatches.map((batch) => batch.id))
  )

  const allBatchesIds = allBatches.reduce((groupByBatch, item) => {
    let batchName = item.nomeLote
    if (batchName.includes('- IATF')) {
      batchName = batchName.split(' - IATF')[0]
    }

    groupByBatch[batchName] = groupByBatch[batchName] || []
    groupByBatch[batchName].push(item.id)

    return groupByBatch
  }, {})

  return {
    farm: await utils.getElement(
      AVAILABLE_ENTITIES.FARMS,
      props.match.params.farmId
    ),
    corral: await utils.getElement(
      AVAILABLE_ENTITIES.CORRALS,
      props.match.params.id
    ),
    condicaoDG: _.sortBy(await utils.getDomainValuesBy('Condição DG'), [
      (condicao) => condicao.valor !== 'Prenhe',
      (condicao) => condicao.valor !== 'Vazia',
      (condicao) => condicao.valor !== 'Descarte Prenhe',
      (condicao) => condicao.valor !== 'Descarte Vazia',
    ]),
    batches: batchList,
    previousVacas,
    allBatchesIds,
    allVacas,
    user: await utils.getElement(
      AVAILABLE_ENTITIES.USERS,
      (
        await getLoggedUser()
      ).userId
    ),
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
})

export default connect(
  null,
  mapDispatchToProps
)(utils.withPropsFromDatabase(propsFromDatabase, withRouter(Corral)))
