import { AVAILABLE_ENTITIES, utils } from '../../database'
import { FAZENDA, formatName } from '../../utils/formHelper'
import React, { Component } from 'react'

import BullForm from './components/BullForm'
import { Container } from '@material-ui/core'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash';
import { verify } from './../../utils/verifyDuplicatePages';
import { withRouter } from 'react-router-dom'

class Bull extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.onCancel = this.onCancel.bind(this)
  }

  onCancel() {
    this.props.history.push(`/farms/${this.props.farm.id}/bulls`)
  }

  render() {
    const initialValues = {
      nome: this.props.bull.nome,
      raca: this.props.bull.raca,
      central: this.props.bull.central,
      centralValue: this.props.bull.centralValue,
      partida: this.props.bull.partida,
      observacao: this.props.bull.observacao
    }
    const bullTitle = `${this.props.bull.nome} ${this.props.bull.partida
      ? this.props.bull.partida.format('DD/MM/YYYY')
      : ''
      } ${_.isEmpty(this.props.bull.observacao)
        ? ''
        : '(' + this.props.bull.observacao + ')'
      }`

    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={bullTitle}
          paths={[
            {
              route: `/farms/${this.props.farm.id}`,
              label: formatName(this.props.farm.nome, FAZENDA)
            },
            {
              route: `/farms/${this.props.farm.id}/bulls`,
              label: 'Sêmen'
            },
            {
              label: bullTitle
            }
          ]}
        />

        <Prompt
          visible={verify()}
          title='Página duplicada!'
          message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
          buttons={[
            {
              autoFocus: false
            },
          ]}
        />

        <MainContainer containerTitle={this.props.bull.nome}>
          <Container disableGutters>
            <BullForm
              initialValues={initialValues}
              onCancel={this.onCancel}
              farm={this.props.farm}
              bull={this.props.bull}
              viewOnly
            />
          </Container>
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async props => ({
  farm: await utils.getElement(AVAILABLE_ENTITIES.FARMS, props.match.params.id),
  bull: await utils.getElement(
    // AVAILABLE_ENTITIES.BULLS,
    AVAILABLE_ENTITIES.BULLS_NEW,
    props.match.params.bullId
  )
})

export default utils.withPropsFromDatabase(propsFromDatabase, withRouter(Bull))
