import Button, { COLORS } from './material/Button'
import { IconButton, TablePagination, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Checkbox from '@material-ui/core/Checkbox'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import SwapVertIcon from '@material-ui/icons/SwapVert';
import Tooltip from '@material-ui/core/Tooltip'
import _ from 'lodash'

const DEFAULTS = {
  PAGE: 0,
  ROWS_PER_PAGE: 10,
}

export const CELL_TYPES = {
  NOT_FOUND: 'NOT_FOUND',
  BUTTON: 'BUTTON',
  BUTTON_ARR: 'BUTTON_ARR',
  BUTTON_ICON_ARR: 'BUTTON_ICON_ARR',
  LINK: 'LINK',
  TEXT: 'TEXT',
  TEXT_CENTER: 'TEXT_CENTER',
  HOLLOW_BUTTON: 'HOLLOW_BUTTON',
  ICON_BUTTON: 'ICON_BUTTON',
  CHECKBOX: 'CHECKBOX',
  FIELD: 'FIELD',
  FLOAT_BUTTONS: 'FLOAT_BUTTONS',
}

export default class ResponsiveTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItems: [],
      page: this.props.page || DEFAULTS.PAGE,
      rowsPerPage: this.props.rowsPerPage || DEFAULTS.ROWS_PER_PAGE,
      hideFirstColumn: false,
      hasTopPagination: this.props.hasTopPagination ?? true,
      sortBy: null,
      sortDirection: 'asc'
    }
    this.renderCell = this.renderCell.bind(this)
    this.changePage = this.changePage.bind(this)
  }

  changePage(event, page) {
    this.setState({ page, activeItems: [] })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        page: this.props.page || DEFAULTS.PAGE,
      })
    }
  }

  async exportData(data) {
    const str = JSON.stringify(data)
    const bytes = new TextEncoder().encode(str)
    const blob = new Blob([bytes], {
      type: 'application/json;charset=utf-8',
    })

    const newHandle = await window.showSaveFilePicker()
    const writableStream = await newHandle.createWritable()
    await writableStream.write(blob)
    await writableStream.close()
  }

  toggleVisibility(index) {
    const activeItems = this.state.activeItems.some((i) => i === index)
      ? this.state.activeItems.filter((i) => i !== index)
      : [...this.state.activeItems, index]

    this.setState({ activeItems })
  }

  renderCell(
    type,
    {
      label,
      onClick,
      onClickLink,
      buttons,
      icon,
      field,
      useGridSystem,
      checked,
      disabled,
    },
    columnName,
    index,
    tdIndex
  ) {
    const key = `${columnName}_${index}`
    const visible = this.state.activeItems.includes(index) ? 'visible' : ''
    const params = {
      key,
      onClick,
      onClickLink,
      label,
      useGridSystem,
      checked,
      disabled,
      buttons,
      icon,
      field,
      index,
      visible,
      tdIndex,
    }

    const typesMap = new Map([
      [CELL_TYPES.NOT_FOUND, 'getNotFoundCell'],
      [CELL_TYPES.BUTTON, 'getButtonCell'],
      [CELL_TYPES.LINK, 'getLinkCell'],
      [CELL_TYPES.BUTTON_ARR, 'getButtonArrCell'],
      [CELL_TYPES.ICON_BUTTON, 'getIconButtonCell'],
      [CELL_TYPES.CHECKBOX, 'getCheckboxCell'],
      [CELL_TYPES.HOLLOW_BUTTON, 'getHollowButtonCell'],
      [CELL_TYPES.FIELD, 'getFieldCell'],
      [CELL_TYPES.TEXT_CENTER, 'getTextCenterCell'],
      [CELL_TYPES.FLOAT_BUTTONS, 'getFloatButtonsCell'],
    ])

    return !!typesMap.get(type)
      ? this[typesMap.get(type)](params)
      : this.getDefaultCell(params)
  }

  getNotFoundCell() {
    return (
      <Td colSpan={this.props.columns.length} align='center'>
        <Typography variant='h6'>
          {this.props.noDataMessage || 'Nenhum resultado'}
        </Typography>
      </Td>
    )
  }

  getButtonCell({ key, useGridSystem, onClick, label, disabled, visible }) {
    return (
      <Td key={key} align='right' className={`td-btn ${visible}`}>
        <Button
          style={useGridSystem ? { width: '100%' } : undefined}
          small
          onClick={onClick}
          label={label}
          disabled={disabled}
        />
      </Td>
    )
  }

  getLinkCell({ key, onClick, label, index, tdIndex, visible }) {
    return (
      <Td
        key={key}
        className={`td-link ${visible}`}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          textDecorationColor: 'rgb(234, 93, 13)',
          width: this.props.columnWidth,
        }}
      >
        <span onClick={onClick}>{label}</span>
        {tdIndex === 0 && (
          <span
            className='collapse-icon'
            onClick={() => this.toggleVisibility(index)}
          >
            <KeyboardArrowUpIcon />
          </span>
        )}
      </Td>
    )
  }

  getButtonArrCell({ key, buttons, visible }) {
    let lastChild = buttons.filter((b) => b.icon == null).length
    return (
      <Td key={key} align='right' className={`td-btn-arr ${visible}`}>
        {buttons.map((button, bIndex) =>
          button.icon ? (
            <Tooltip
              key={`${key}_${bIndex}`}
              title={button.disabled ? 'Desabilitado' : button.label}
              arrow
              placement='top'
            >
              <IconButton
                style={{ color: button.disabled && '#cecece', padding: '10px' }}
                onClick={!button.disabled && button.onClick}
                className='td-btn-arr-icon'
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              key={`${key}_${bIndex}`}
              small
              style={{
                borderRadius: '300px',
                height: '32px',
                padding: '10px',
                margin: '5px',
                background:
                  !button.disabled &&
                    button.label !== 'Ressinc' &&
                    !(bIndex + 1 === lastChild)
                    ? 'white'
                    : 'null',
                color:
                  !button.disabled &&
                    button.label !== 'Ressinc' &&
                    !(bIndex + 1 === lastChild)
                    ? 'rgb(234 93 13)'
                    : 'white',
                border:
                  !button.disabled &&
                    button.label !== 'Ressinc' &&
                    !(bIndex + 1 === lastChild)
                    ? '1px solid rgb(234 93 13)'
                    : 'null',
              }}
              onClick={button.onClick}
              label={button.label}
              disabled={button.disabled}
              className='td-btn-arr-btn'
            />
          )
        )}
      </Td>
    )
  }

  getFloatButtonsCell({ key, buttons, visible }) {
    let lastChild = buttons.filter((b) => b.icon == null).length

    return (
      <Td key={key} align='right' className={`td-float-buttons ${visible}`}>
        <label htmlFor={'action_' + key}>
          <span
            className='td-float-buttons-trigger'
            style={{
              padding: '5px 8px',
              borderRadius: '400px',
              border: '1px solid rgb(234 93 13)',
              color: 'rgb(234 93 13)',
            }}
          >
            Manejos
          </span>
          <input type='checkbox' id={'action_' + key} />
          <div>
            {buttons
              .filter(
                (i) =>
                  i.label !== 'Excluir' &&
                  i.label !== 'Editar' &&
                  i.label !== 'Sincronizar' &&
                  i.label !== 'Voltar para Ressinc anterior'
              )
              .map((button, bIndex) =>
                this.renderFloatingIcons(key, bIndex, button, lastChild)
              )}
          </div>
        </label>

        {buttons
          .filter(
            (i) =>
              i.label === 'Excluir' ||
              i.label === 'Editar' ||
              i.label === 'Sincronizar' ||
              i.label === 'Voltar para Ressinc anterior'
          )
          .map((button, bIndex) => this.renderActionIcons(key, bIndex, button))}
      </Td>
    )
  }

  getIconButtonCell({ key, onClick, label, icon, visible, disabled }) {
    return (
      <Td key={`${key}`} align='right' className={`td-icon-btn ${visible}`}>
        <Tooltip title={label} arrow placement='top'>
          <IconButton key={`${key}`} disabled={disabled} onClick={onClick}>
            {icon}
          </IconButton>
        </Tooltip>
      </Td>
    )
  }

  getCheckboxCell({
    key,
    onClick,
    onClickLink,
    checked,
    disabled,
    visible,
    label,
    index,
    tdIndex,
  }) {
    return (
      <Td
        key={`${key}`}
        className={`td-link ${visible}`}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          textDecorationColor: 'rgb(234, 93, 13)',
          width: this.props.columnWidth,
        }}
      >
        <Checkbox
          onClick={onClick}
          checked={checked}
          disabled={disabled}
          color='primary'
          style={{ marginRight: '10px' }}
        />
        <span onClick={onClickLink}>{label}</span>
        {tdIndex === 0 && (
          <span
            className='collapse-icon'
            onClick={() => this.toggleVisibility(index)}
          >
            <KeyboardArrowUpIcon />
          </span>
        )}
      </Td>
    )
  }

  getHollowButtonCell({ key, onClick, icon, visible }) {
    return (
      <Td key={`${key}`} align='right' className={`td-hollow ${visible}`}>
        <IconButton onClick={onClick}>{icon}</IconButton>
      </Td>
    )
  }

  getFieldCell({ key, field, index, tdIndex, visible }) {
    return (
      <Td key={`${key}`} className={`td-field ${visible}`}>
        {field}
        {tdIndex === 0 && (
          <span
            className='collapse-icon'
            onClick={() => this.toggleVisibility(index)}
          >
            <KeyboardArrowUpIcon />
          </span>
        )}
      </Td>
    )
  }

  getTextCenterCell({ key, label, index, tdIndex, visible }) {
    return (
      <Td key={`${key}`} align='center' className={`td-text-center ${visible}`}>
        {label}
        {tdIndex === 0 && (
          <span
            className='collapse-icon'
            onClick={() => this.toggleVisibility(index)}
          >
            <KeyboardArrowUpIcon />
          </span>
        )}
      </Td>
    )
  }

  getDefaultCell({ key, label, index, tdIndex, visible }) {
    return (
      <Td
        key={`${key}`}
        style={{ width: this.props.columnWidth }}
        className={`td-default ${visible}`}
      >
        {label}
        {tdIndex === 0 && (
          <span
            className='collapse-icon'
            onClick={() => this.toggleVisibility(index)}
          >
            <KeyboardArrowUpIcon />
          </span>
        )}
      </Td>
    )
  }

  renderFloatingIcons(key, bIndex, button, lastChild) {
    return (
      <Button
        key={`${key}_${bIndex}`}
        small
        style={{
          borderRadius: '300px',
          height: '32px',
          padding: '10px',
          margin: '5px',
          background:
            !button.disabled && !(bIndex + 1 === lastChild) ? 'white' : 'null',
          color:
            !button.disabled && !(bIndex + 1 === lastChild)
              ? 'rgb(234 93 13)'
              : 'null',
          border:
            !button.disabled && !(bIndex + 1 === lastChild)
              ? '1px solid rgb(234 93 13)'
              : 'null',
        }}
        onClick={button.onClick}
        label={button.label}
        disabled={button.disabled}
        className='td-btn-arr-btn'
      />
    )
  }

  renderActionIcons(key, bIndex, button) {
    return button.icon ? (
      <Tooltip
        key={`${key}_${bIndex}`}
        title={button.disabled ? 'Desabilitado' : button.label}
        arrow
        placement='top'
      >
        <IconButton
          style={{ color: button.disabled && '#cecece', padding: '5px' }}
          onClick={button.disabled ? null : button.onClick}
          className='td-btn-arr-icon'
        >
          {button.icon}
        </IconButton>
      </Tooltip>
    ) : (
      <Button
        key={`${key}_${bIndex}`}
        small
        onClick={button.onClick}
        label={button.label}
        disabled={button.disabled}
        className='td-btn-arr-btn'
      />
    )
  }

  renderTableItem(useGridSystem, row, i) {
    return (
      <Tr key={i}>
        {this.props.columns.map((column, tdIndex) =>
          this.renderCell(
            column.type,
            {
              label: column.label ? column.label(row, i) : '',
              onClick: column.onClick && column.onClick(row, i),
              onClickLink: column.onClickLink && column.onClickLink(row, i),
              buttons: column.buttons ? column.buttons(row, i) : [],
              icon: column.icon && column.icon(row, i),
              field: column.field && column.field(row, i),
              checked: column.checked && column.checked(row, i),
              disabled: column.disabled && column.disabled(row, i),
              useGridSystem,
            },
            column.name,
            i,
            tdIndex
          )
        )}
      </Tr>
    )
  }

  renderTopTablePagination() {
    return (
      <div className='top-table-pagination'>
        <TablePagination
          colSpan={this.props.columns.length}
          count={this.props.data.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.changePage}
          rowsPerPageOptions={[this.state.rowsPerPage]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : to
            }`
          }
        />
      </div>
    )
  }

  renderTableFooter() {
    return (
      <tfoot>
        <tr>
          <td align='left'>
            <Button
              color={COLORS.WHITE}
              startIcon={<ArrowDownwardIcon />}
              onClick={() => this.exportData(this.props.toDataExport)}
              withGrid={12}
              style={{
                minWidth: '5px',
                padding: '2px 0px 2px 12px',
                width: '30px',
                display: 'none',
              }}
            />
          </td>
          <TablePagination
            colSpan={this.props.columns.length}
            count={this.props.data.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.changePage}
            rowsPerPageOptions={[this.state.rowsPerPage]}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : to
              }`
            }
          />
        </tr>
      </tfoot>
    )
  }

  handleSort = (column) => {
    const { sortBy, sortDirection } = this.state;
    let newDirection;

    if (sortBy === column.name) {
      newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      newDirection = 'asc';
    }

    const sortedData = _.orderBy(this.props.data, [row => column.label(row)], [newDirection]);

    this.setState({
      sortBy: column.name,
      sortDirection: newDirection,
    });

    this.props.onSort && this.props.onSort(sortedData);
  };


  render() {
    let useGridSystem = false

    const data = !this.props.skipPagination
      ? this.props.data.slice(
        this.state.page * this.state.rowsPerPage,
        (this.state.page + 1) * this.state.rowsPerPage
      )
      : this.props.data

    return (
      <>
        {this.state.hasTopPagination && this.renderTopTablePagination()}
        <Table
          className={'app-responsive-table ' + this.props.className}
          style={{
            margin: 0,
            borderRadius: '0 0 8px 8px',
          }}
        >
          <Thead>
            <Tr>
              {this.props.columns.map((column, index) => {
                return (
                  <Th
                    key={`${column.name}_${index}`}
                    onClick={() => column.sortBy && this.handleSort(column)}
                    style={{ cursor: column.sortBy ? 'pointer' : 'default' }}
                  >
                    <div className="column-header">
                      {column.name}
                      {column.sortBy && (
                        <SwapVertIcon
                          className='sortable-column'
                          style={{ fontSize: 18, marginLeft: 5 }}
                        />
                      )}
                    </div>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {!this.props.data.length ? (
              <Tr>{this.renderCell('NOT_FOUND', {})}</Tr>
            ) : (
              data.map((row, i) => this.renderTableItem(useGridSystem, row, i))
            )}
          </Tbody>

          {!this.props.skipPagination && this.renderTableFooter()}
        </Table >
      </>
    )
  }
}
