import * as CONSTANTS from './constants'

import { listBatches } from './actions'

const initialState = {
  selected: null,
  batches: [],
  list: [],
  racasmat: [],
  ordems: [],
  racasbez: [],
  sexobez: [],
  idademat: [],
  device: [],
  protocols: [],
  touros: [],
  batchSelected: null
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.LIST_FARMS:
      return {
        ...state,
        list: action.data
      }
    case CONSTANTS.SELECT_FARM:
      return {
        ...state,
        selected: action.data === 'all' ? null : action.data
      }
    case CONSTANTS.LIST_BATCHES:
      return {
        ...state,
        batches: action.data || []
      }
    case CONSTANTS.LIST_RACAS_MAT:
      return {
        ...state,
        racasmat: action.data || []
      }
    case CONSTANTS.LIST_ORDEM:
      return {
        ...state,
        ordems: action.data || []
      }
    case CONSTANTS.LIST_RACAS_BEZ:
      return {
        ...state,
        racasbez: action.data || []
      }
    case CONSTANTS.LIST_SEXO_BEZ:
      return {
        ...state,
        sexobez: action.data || []
      }
    case CONSTANTS.LIST_IDADE_MAT:
      return {
        ...state,
        idademat: action.data || []
      }
    case CONSTANTS.LIST_DEVICE:
      return {
        ...state,
        device: action.data || []
      }
    case CONSTANTS.SELECT_BATCH:
      return {
        ...state,
        batchSelected: action.data
      }
    case CONSTANTS.LIST_PROTOCOLS:
      return {
        ...state,
        protocols: action.data || []
      }
    case CONSTANTS.LIST_TOUROS:
      return {
        ...state,
        touros: action.data || []
      }
    default:
      return state
  }
}
