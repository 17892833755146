import { Model, Q } from '@nozbe/watermelondb'
import {
  action,
  date,
  field,
  readonly,
  relation
} from '@nozbe/watermelondb/decorators'

import _ from 'lodash'

export const modelToDTOMapping = {
  identical: ['name', 'description', 'backend_id'],
  renamed: [],
  references: [
    {
      dtoName: 'userId',
      modelName: 'user_id'
    }
  ]
}

export default class Roles extends Model {
  static table = 'roles'

  //Basic Fields
  @field('id') id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @field('isDeleted') isDeleted

  //Fields
  @field('name') name
  @field('description') description
  @field('backend_id') backend_id

  //Foreign Relations
  @relation('users', 'user_id') user
}
