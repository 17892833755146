/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-unused-expressions */

import * as sync from '../../../src/syncWorker'

import { ANIMAL_DIED, ANIMAL_NOT_PRESENT } from '../../utils/constants'
import { AVAILABLE_ENTITIES, Repository, format, utils } from '../../database'
import Autocomplete, {
  autocompleteDomainValueOptionLabelSavingID,
} from './../../components/material/Autocomplete'
import { Container, Grid } from '@material-ui/core'
import DateField, {
  datefieldDefaultOnChange,
} from './../../components/material/DateField'
import {
  FAZENDA,
  LOTE,
  REQUIRED_FIELD_MESSAGES,
  RETIRO,
  formatName,
  isDateNullOrValid,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from '../../utils/formHelper'
import React, { Component, Fragment } from 'react'
import { idConditionDG, idConditionDGFinal } from '../../utils/idsHelper'
import {
  idConditionDGByName,
  idConditionDGFinalByName,
  idTypePregnancyDGFinalByName,
} from './functions'

import Button from './../../components/material/Button'
import { CELL_TYPES } from './../../components/PaginatedTable'
import DoneIcon from '@material-ui/icons/Done'
import { LocalStorageHelper } from '../../utils/localStorageHelper'
import MainContainer from '../../components/MainContainer'
import Prompt from './../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import ResponsiveTable from './../../components/ResponsiveTable'
import TextField from './../../components/material/TextField'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { changeCowsToBatchNotes } from '../../utils/notesHelper'
import { currentDayIsDG } from '../../utils/days'
import { getBatchesByBatchIds } from '../../utils/batches'
import { getLoggedUser } from '../../redux/auth/actions'
import moment from 'moment'
import { sortList } from '../../utils/helper'
import track from 'react-tracking'
import { verify } from '../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

const obsOptions = [ANIMAL_NOT_PRESENT, ANIMAL_DIED]

@track(() => ({ page: 'DG Final', date: new Date() }))
class DGFinal extends Component {
  constructor(props) {
    super(props)
    const codVacas = _.reduce(
      props.vacas,
      (obj, vaca) => ({
        ...obj,
        [vaca.id]: vaca.codVaca,
      }),
      {}
    )

    this.state = {
      ..._.reduce(
        props.vacas,
        (obj, vaca) => ({
          ...obj,
          [vaca.id]: {
            ...vaca,
            isPrenhe:
              vaca.condicaoDGFinal && vaca.condicaoDGFinal === props.prenhe.id,
            isPrenheIATF:
              vaca.condicaoDGFinal &&
              vaca.condicaoDGFinal === props.prenheIATF.id,
            isVazia:
              vaca.condicaoDGFinal && vaca.condicaoDGFinal === props.vazia.id,
            isDescartePrenhe:
              vaca.condicaoDGFinal &&
              vaca.condicaoDGFinal === props.descartePrenhe.id,
            isDescartePrenheTouro:
              vaca.condicaoDGFinal &&
              vaca.condicaoDGFinal === props.descartePrenheTouro.id,
            isDescarteVazia:
              vaca.condicaoDGFinal &&
              vaca.condicaoDGFinal === props.descarteVazia.id,
          },
        }),
        {}
      ),
      codVacas,
      selectedVacas: _.keys(codVacas),
      allCows: props.vacas,
      vacasSelected: props.vacas,
      prenhes: [],
      prenhesIATF: [],
      vazias: [],
      descartesPrenhe: [],
      descartesPrenheTouro: [],
      descartesVazia: [],
      perdasGestacao: [],
      salvas: [],
      dataDoManejo: null,
      diagnosticoFinalForAll: null,
      vacasCount: props.vacas.length > 0 ? props.vacas.length : 1,
      showingFinalizeConfirmation: false,
      confirmedBackupDownload: true,
      showingNoBackupConfirmation: false,
      showingNoDateError: false,
      batchesIds: props.batches.map((batch) => batch.id),
      dateIatf: null,
      width: window.innerWidth,
      saveAllConfirm: false,
      diagnosticLength: 0,
      searchValue: '',
      showingChangeCowsToBatchConfirmation: false,
      searchInputCow: [],
      selectedCowToChangeToBatch: null,
      focusSearch: false,
      clearSearch: null,
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.changeCowsToBatch = this.changeCowsToBatch.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.finalize = this.finalize.bind(this)

    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)

    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)

    this.renderCowCell = this.renderCowCell.bind(this)
    this.renderHeader = this.renderHeader.bind(this)
    this.renderManejo = this.renderManejo.bind(this)
    this.updateCondition = this.updateCondition.bind(this)
    this.removeFromSave = this.removeFromSave.bind(this)
    this.isDateNullOrValid = isDateNullOrValid.bind(this)
    this.addToStatistics = this.addToStatistics.bind(this)
    this.convertPrenheDescarteToCondicaoDGFinal =
      this.convertPrenheDescarteToCondicaoDGFinal.bind(this)
    this.datefieldDefaultOnChange = datefieldDefaultOnChange.bind(this)
    this.renderTitle = this.renderTitle.bind(this)

    this.props.vacas.map((v) =>
      (v.condicaoDGFinal != null || v.observacao_DG_Final != null) &&
        v.observacao_DG_Final !== 'Animal mudou de lote'
        ? this.addToStatistics(v)
        : this.convertPrenheDescarteToCondicaoDGFinal(v)
    )

    this.getIatfDay()
  }

  componentDidMount() {
    this.updateWidth()
  }

  updateWidth = () => {
    this.setState({
      width: window.innerWidth,
    })
  }

  convertPrenheDescarteToCondicaoDGFinal(vaca) {
    if (
      vaca.condicaoDG &&
      vaca.condicaoDG === idConditionDGByName(this.props.condicaoDG, 'Prenhe')
    ) {
      this.state[vaca.id].condicaoDGFinal = idConditionDGFinalByName(
        this.props.condicaoDGFinal,
        'Prenhe IATF'
      )
      this.state[vaca.id].tipoPrenheFinal = idTypePregnancyDGFinalByName(
        this.props.tipoPrenhe,
        'Normal'
      )
      this.state.prenhesIATF.push(vaca.id)
    }

    if (
      vaca.condicaoDG &&
      vaca.condicaoDG ===
      idConditionDGByName(this.props.condicaoDG, 'Descarte Prenhe')
    ) {
      this.state[vaca.id].condicaoDGFinal = idConditionDGFinalByName(
        this.props.condicaoDGFinal,
        'Descarte Prenhe IATF'
      )
      this.state[vaca.id].tipoPrenheFinal = idTypePregnancyDGFinalByName(
        this.props.tipoPrenhe,
        'Normal'
      )
      this.state.descartesPrenhe.push(vaca.id)
    }

    if (
      vaca.condicaoDG &&
      vaca.condicaoDG ===
      idConditionDGByName(this.props.condicaoDG, 'Descarte Vazia')
    ) {
      this.state[vaca.id].condicaoDGFinal = idConditionDGFinalByName(
        this.props.condicaoDGFinal,
        'Descarte Vazia'
      )
      this.state.descartesPrenhe.push(vaca.id)
    }
  }

  addToStatistics(vaca) {
    this.state.salvas.push(vaca.id)

    vaca.condicaoDGFinal ===
      idConditionDGFinalByName(this.props.condicaoDGFinal, 'Vazia') &&
      this.state.vazias.push(vaca.id)

    vaca.condicaoDGFinal ===
      idConditionDGFinalByName(this.props.condicaoDGFinal, 'Prenhe Touro') &&
      this.state.prenhes.push(vaca.id)

    vaca.condicaoDGFinal ===
      idConditionDGFinalByName(this.props.condicaoDGFinal, 'Descarte Vazia') &&
      this.state.descartesVazia.push(vaca.id)

    vaca.condicaoDGFinal ===
      idConditionDGFinalByName(
        this.props.condicaoDGFinal,
        'Descarte Prenhe IATF'
      ) && this.state.descartesPrenhe.push(vaca.id)

    vaca.condicaoDGFinal ===
      idConditionDGFinalByName(
        this.props.condicaoDGFinal,
        'Descarte Prenhe Touro'
      ) && this.state.descartesPrenheTouro.push(vaca.id)

    vaca.condicaoDGFinal ===
      idConditionDGFinalByName(this.props.condicaoDGFinal, 'Prenhe IATF') &&
      this.state.prenhesIATF.push(vaca.id)

    vaca.perdaGestacao === true && this.state.perdasGestacao.push(vaca.id)
  }

  async getIatfDay() {
    let protocol = await utils.getElement(
      AVAILABLE_ENTITIES.PROTOCOLS,
      this.props.batches[0].protocoloId
    )
    let managementIds = protocol.managementProtocols.map((manejo) => manejo.day)
    let daysManagement = await utils.getWithParam(
      AVAILABLE_ENTITIES.DOMAIN_VALUES,
      'id',
      Q.oneOf(managementIds)
    )
    let dayBatch = daysManagement
      ? parseInt(
        daysManagement
          .sort(
            (a, b) =>
              parseInt(b.valor.replace(/\D/g, '')) -
              parseInt(a.valor.replace(/\D/g, ''))
          )[0]
          .valor.replace(/\D/g, '')
      )
      : 0

    await this.setState({
      dateIatf: moment
        .utc(
          this.props.batches[0].dataIatf._d.valueOf() +
          dayBatch * 24 * 3600 * 1000
        )
        .format('DD/MM/YYYY'),
    })
  }

  async handleSearch(e, clean) {
    // const val = e.target.value
    const val = !clean ? e?.target?.value : ''
    const eventVal = val === undefined && ''

    const selectedCodVacas = _.isEmpty(val)
      ? this.state.allCows
      : this.state.allCows.filter(
        (entry) =>
          entry.codVaca?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
    const filtedCodLeitorBastao = _.isEmpty(val)
      ? this.state.allCows
      : this.state.allCows.filter(
        (entry) =>
          entry.codLeitorBastao?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
    const filtedAllData = selectedCodVacas.concat(filtedCodLeitorBastao)
    const selectedVacas = [...new Set(filtedAllData)]
    let searchError = null
    let searchInputCow = []

    if (selectedVacas.length === 0 && !_.isEmpty(val)) {
      const outOfBatch = await utils.farmWideSearch(
        val,
        this.state.batchesIds,
        this.props.farm.id
      )

      if (outOfBatch.msg) {
        searchError = outOfBatch.msg
        searchInputCow = outOfBatch.data
      }
    }

    this.setState({
      vacasSelected: selectedVacas,
      searchError,
      searchInputCow,
      // searchValue: selectedVacas.length > 0 ? e?.target?.value : '',
      searchValue: selectedVacas.length > 0 ? eventVal : '',
    })
  }

  async changeCowsToBatch(cow) {
    try {
      let updatedMatriz = {}

      updatedMatriz = {
        ...cow,
        loteId: this.props.loteId,
        observacao_DG_Final: 'Animal mudou de lote',
      }

      const repository = new Repository(AVAILABLE_ENTITIES.D0S)
      const createReq = await repository.update(updatedMatriz)

      if (createReq.success) {
        console.log('D0 updated successfully')

        this.setState({
          allCows: [...this.state.allCows, updatedMatriz],
        })

        setTimeout(() => {
          window.location.reload()
        }, 500)
      } else {
        console.log(
          `There was an error trying to update D0: ${createReq.exception}`
        )
      }
    } catch (exception) {
      console.log(`There was an error trying to update D0: ${exception}`)
    }
  }

  @track({ action: 'Finalizou o DG Final' })
  async finalize(downloadBackup) {
    const repository = new Repository(AVAILABLE_ENTITIES.D0S)
    await Promise.all(
      this.props.vacas.map(async (vaca) => {
        const id = vaca.id
        const entry = await repository.getById(id)
        const payload = {
          id,
          dataDgFinal: this.state.dataDoManejo,
        }
        if (!entry.response.observacao_DG_Final) {
          const payloadWithoutObs = {
            id,
            observacao_DG_Final: '[N/A]',
            dataDgFinal: this.state.dataDoManejo,
          }
          return repository.update(payloadWithoutObs)
        }
        return repository.update(payload)
      })
    )

    const uniqueCorrals = _.uniq(this.props.corrals.map((corral) => corral.id))

    if (this.props.farm.retiro && uniqueCorrals.length === 1) {
      LocalStorageHelper.add('reportExportWarning', true)
      this.props.history.push(
        `/farms/${this.props.farm.id}/corrals/${uniqueCorrals[0]}`
      )
    } else {
      this.props.history.push(`/farms/${this.props.farm.id}`)
    }

    sync.downloadProofOfManagement({
      values: ['d0s'],
      text: 'Comprovante de manejo DG FINAL - ' +
        formatName(this.props.batches.map((item) => item.nomeLote), LOTE) +
        ' - ' +
        formatName(this.props.farm.nome, FAZENDA) +
        ' - ' +
        moment.utc().valueOf() +
        '.progerar',
      batchIds: this.props.match.params.id.split(';')
    })
  }

  handleCondicaoDG(cow) {
    let condicaoDG

    if (cow.isPrenheIATF || cow.isDescartePrenhe) {
      condicaoDG = 'Prenhe'
    }

    if (
      cow.isPrenhe ||
      cow.isDescartePrenheTouro ||
      cow.isDescarteVazia ||
      cow.isVazia
    ) {
      condicaoDG = 'Vazia'
    }

    if (condicaoDG) {
      return idConditionDGByName(this.props.condicaoDG, condicaoDG)
    }
  }

  @track((props, state, cowId) => ({
    action: 'Salvou a DG Final de uma matriz',
    value: cowId[0],
  }))
  async handleSave(id) {
    if (this.state.searchValue !== '') {
      this.handleSearch(null, false)
      this.setState({
        focusSearch: true,
        clearSearch: true,
      })
    }

    const vaca = this.state[id]
    let dgVazio = !vaca.condicaoDG ? true : false

    if (vaca !== null) {
      if (
        _.isEmpty(vaca.condicaoDGFinal) &&
        vaca.observacao_DG_Final !== ANIMAL_NOT_PRESENT &&
        vaca.observacao_DG_Final !== ANIMAL_DIED
      ) {
        this.setState({
          [id]: {
            ...vaca,
            condicaoDGFinal_error: REQUIRED_FIELD_MESSAGES.DEFAULT,
          },
        })
      } else if (
        _.isEmpty(vaca.tipoPrenheFinal) &&
        (vaca.isPrenheIATF ||
          vaca.isDescartePrenhe ||
          vaca.isDescartePrenheTouro ||
          vaca.isPrenhe) &&
        vaca.observacao_DG_Final !== ANIMAL_NOT_PRESENT &&
        vaca.observacao_DG_Final !== ANIMAL_DIED
      ) {
        this.setState({
          [id]: {
            ...vaca,
            tipoPrenheFinal_error: REQUIRED_FIELD_MESSAGES.DEFAULT,
          },
        })
      } else {
        if (
          (currentDayIsDG(this.props.batches[0]) &&
            vaca.condicaoDG == null &&
            vaca.observacaoDG == null) ||
          dgVazio
        ) {
          vaca.condicaoDG = this.handleCondicaoDG(vaca)
        }
        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const updateRequest = await repository.update(_.clone(vaca))
        if (updateRequest.success) {
          console.log('Cow updated successfully')
          this.setState({
            focusSearch: false,
            clearSearch: false,
            salvas: [...this.state.salvas, id],
            [id]: {
              ...vaca,
              condicaoDGFinal_error: null,
              tipoPrenheFinal_error: null,
            },
          })
        } else {
          console.log(
            `There was a problem updating cow ${id}:${vaca.codVaca}: ${updateRequest.exception}`
          )
        }
      }
    }
  }

  onEnterPress = (id, e) => {
    if (this.props.user.roles[0].name !== 'Cliente (somente visualização)') {
      if (!(this.state.salvas.indexOf(id) >= 0)) {
        if (e.keyCode === 13 && e.shiftKey === false) {
          e.preventDefault()
          this.handleSave(id)
        }
      }
    }
  }

  getValue(data, diagnosticLength) {
    return `${data} (${diagnosticLength ? this.roundNumber(data, diagnosticLength) : 0
      }%)`
  }

  getDescartes(descartesPrenhe, descartesPrenheTouro, descartesVazia) {
    return `${descartesPrenhe} Prenhe${(descartesPrenhe > 1 ? 's' : '', ' IATF')
      }, ${descartesPrenheTouro} Prenhe${(descartesPrenheTouro > 1 ? 's' : '', ' Touro')
      } e ${descartesVazia} Vazia${descartesVazia > 1 ? 's' : ''}`
  }

  roundNumber(data, diagnosticLength) {
    return Math.floor((data / diagnosticLength) * 100 * 100) / 100
  }

  renderHeader() {
    const diagnosticLength =
      (this.state.salvas.length &&
        [
          ...new Set(
            this.state.prenhesIATF.filter(
              (item) => this.state.salvas.indexOf(item) > -1
            )
          ),
        ]?.length) +
      (this.state.salvas.length &&
        [
          ...new Set(
            this.state.prenhes.filter(
              (item) => this.state.salvas.indexOf(item) > -1
            )
          ),
        ]?.length) +
      (this.state.salvas.length &&
        [
          ...new Set(
            this.state.vazias.filter(
              (item) => this.state.salvas.indexOf(item) > -1
            )
          ),
        ]?.length) +
      (this.state.salvas.length &&
        [
          ...new Set(
            this.state.descartesPrenhe.filter(
              (item) => this.state.salvas.indexOf(item) > -1
            )
          ),
        ]?.length) +
      (this.state.salvas.length &&
        [
          ...new Set(
            this.state.descartesPrenheTouro.filter(
              (item) => this.state.salvas.indexOf(item) > -1
            )
          ),
        ]?.length) +
      (this.state.salvas.length &&
        [
          ...new Set(
            this.state.descartesVazia.filter(
              (item) => this.state.salvas.indexOf(item) > -1
            )
          ),
        ]?.length)

    const totalPrenhesDG = this.state.allCows.filter(
      (item) => item.condicaoDG === '4818de8a-6b5c-493f-be3d-5d2be27a159d'
    )?.length

    return (
      <>
        <div className='grid grid-dg-final p-hide-phone p-hide-tablet'>
          <div className='grid-item p-12 t-6 header-info-left'>
            <ul>
              <li>
                {`Prenhes IATF: ${this.getValue(
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.prenhesIATF.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0,
                  diagnosticLength
                )}`}
              </li>
              <li>
                {`Prenhes Touro: ${this.getValue(
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.prenhes.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0,
                  diagnosticLength
                )}`}
              </li>
              <li>
                {`Prenhes Total: ${this.getValue(
                  (this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.prenhes.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0) +
                  (this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.prenhesIATF.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0),
                  diagnosticLength
                )}`}
              </li>
            </ul>
            <div
              className='p-margin-vertical-1 p-margin-horizontal-2'
              style={{ width: '1px', borderLeft: '1px solid rgb(224 224 224)' }}
            />
            <ul>
              <li>
                {`Vazias: ${this.getValue(
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.vazias.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0,
                  diagnosticLength
                )}`}
              </li>
              <li>
                {`Descartes: ${this.getDescartes(
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.descartesPrenhe.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0,
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.descartesPrenheTouro.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0,
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.descartesVazia.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0
                )}`}
              </li>
              <li>
                {`Perdas de gestação: ${this.getValue(
                  this.props.vacas.length
                    ? [...new Set(this.state.perdasGestacao)].length
                    : 0,
                  totalPrenhesDG
                )}`}
              </li>
            </ul>
          </div>
          <div className='grid-item p-12 t-6 header-info-right'>
            <h2>
              TOTAL DE MATRIZES: {this.state.salvas.length}/
              {this.props.vacas ? this.props.vacas.length : 0}
            </h2>
            <h3>Data da IATF: {this.state.dateIatf}</h3>
          </div>
          <div className='grid-item p-12'>
            <hr />
          </div>
        </div>

        <div className='grid grid-dg-final p-hide-desktop'>
          <div className='grid-item p-12 t-6 header-info-left'>
            <ul>
              <li>
                {`Prenhes IATF: ${this.getValue(
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.prenhesIATF.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0,
                  diagnosticLength
                )}`}
              </li>
              <li>
                {`Prenhes Touro: ${this.getValue(
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.prenhes.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0,
                  diagnosticLength
                )}`}
              </li>
              <li>
                {`Prenhes Total: ${this.getValue(
                  (this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.prenhes.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0) +
                  (this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.prenhesIATF.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0),
                  diagnosticLength
                )}`}
              </li>
              <li>
                {`Vazias: ${this.getValue(
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.vazias.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0,
                  diagnosticLength
                )}`}
              </li>
              <li>
                {`Descartes: ${this.getDescartes(
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.descartesPrenhe.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0,
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.descartesPrenheTouro.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0,
                  this.props.vacas.length
                    ? [
                      ...new Set(
                        this.state.descartesVazia.filter(
                          (item) => this.state.salvas.indexOf(item) > -1
                        )
                      ),
                    ]?.length
                    : 0
                )}`}
              </li>
              <li>
                {`Perdas de gestação: ${this.getValue(
                  this.props.vacas.length
                    ? [...new Set(this.state.perdasGestacao)].length
                    : 0,
                  totalPrenhesDG
                )}`}
              </li>
            </ul>
          </div>
          <div className='grid-item p-12 t-6 header-info-right'>
            <h2>
              TOTAL DE MATRIZES: {this.state.salvas.length}/
              {this.props.vacas ? this.props.vacas.length : 0}
            </h2>
            <h3>Data da IATF: {this.state.dateIatf}</h3>
          </div>
          <div className='grid-item p-12'>
            <hr />
          </div>
        </div>
      </>
    )
  }

  updateCondition(vacaId, value) {
    const vazias = this.state.vazias.filter((x) => x !== vacaId)
    const prenhes = this.state.prenhes.filter((x) => x !== vacaId)
    const prenhesIATF = this.state.prenhesIATF.filter((x) => x !== vacaId)
    const descartesPrenhe = this.state.descartesPrenhe.filter(
      (x) => x !== vacaId
    )
    const descartesPrenheTouro = this.state.descartesPrenheTouro.filter(
      (x) => x !== vacaId
    )
    const descartesVazia = this.state.descartesVazia.filter((x) => x !== vacaId)

    const isVazia = value && value.valor === 'Vazia'
    const isPrenhe = value && value.valor === 'Prenhe Touro'
    const isPrenheIATF = value && value.valor === 'Prenhe IATF'
    const isDescartePrenhe = value && value.valor === 'Descarte Prenhe IATF'
    const isDescartePrenheTouro =
      value && value.valor === 'Descarte Prenhe Touro'
    const isDescarteVazia = value && value.valor === 'Descarte Vazia'

    this.setState({
      vazias: isVazia ? [...vazias, vacaId] : vazias,
      prenhes: isPrenhe ? [...prenhes, vacaId] : prenhes,
      prenhesIATF: isPrenheIATF ? [...prenhesIATF, vacaId] : prenhesIATF,
      descartesPrenhe: isDescartePrenhe
        ? [...descartesPrenhe, vacaId]
        : descartesPrenhe,
      descartesPrenheTouro: isDescartePrenheTouro
        ? [...descartesPrenheTouro, vacaId]
        : descartesPrenheTouro,
      descartesVazia: isDescarteVazia
        ? [...descartesVazia, vacaId]
        : descartesVazia,
    })

    return {
      isVazia,
      isPrenhe,
      isPrenheIATF,
      isDescartePrenhe,
      isDescarteVazia,
      isDescartePrenheTouro,
    }
  }

  checkPrenhez(vaca, newValue) {
    if (
      vaca.condicaoDG === idConditionDG.pregnant &&
      (newValue === idConditionDGFinal.pregnantBull ||
        newValue === idConditionDGFinal.empty ||
        newValue === idConditionDGFinal.emptyDiscard ||
        newValue === idConditionDGFinal.pregnantBullDiscard) &&
      !vaca.perdaGestacao
    ) {
      this.state.perdasGestacao.push(vaca.id)
      this.setState({ perdasGestacao: [...new Set(this.state.perdasGestacao)] })

      return true
    } else if (
      (!vaca.perdaGestacao || vaca.perdaGestacao === true) &&
      this.state.perdasGestacao.includes(vaca.id)
    ) {
      this.state.perdasGestacao.splice(
        this.state.perdasGestacao.indexOf(vaca.id),
        1
      )
      return null
    } else if (
      vaca.perdaGestacao === true &&
      !this.state.perdasGestacao.includes(vaca.id)
    ) {
      this.state.perdasGestacao.push(vaca.id)
      this.setState({ perdasGestacao: [...new Set(this.state.perdasGestacao)] })

      return true
    }
  }

  async saveAll() {
    for (const cow of this.props.vacas) {
      if (this.state.salvas.indexOf(cow.id) === -1) {
        await this.handleSave(cow.id)
      }
    }
    this.setState({ saveAllConfirm: false })
  }

  removeFromSave(vacaId) {
    if (this.state.salvas.indexOf(vacaId) >= 0) {
      this.setState({ salvas: this.state.salvas.filter((x) => x !== vacaId) })
    }
  }

  renderCowCell(vaca, index) {
    const condicao = _.find(this.props.condicaoDG, { id: vaca.condicaoDG })

    let diasGestacao = vaca.diasGestacao ? vaca.diasGestacao : 'N/A'

    return (
      <Fragment>
        <Grid
          container
          spacing={1}
          direction='column'
          alignContent='space-between'
        >
          {this.props.isMerging && (
            <Grid item xs={12}>
              {vaca.lote}
            </Grid>
          )}
          <Grid item style={{ fontSize: 24 }}>
            {vaca.codVaca}
          </Grid>
          {vaca.partidaName && (
            <Grid item xs={12}>
              <b>Sêmen: </b>
              {vaca.partidaName}
            </Grid>
          )}
          <Grid item style={{ marginTop: '1.5em' }}>
            DG Anterior:{' '}
            {condicao
              ? condicao.valor.toLowerCase().includes('prenhe')
                ? `${condicao.valor} (IATF ${vaca.iatf + 1})`
                : condicao.valor
              : 'N/A'}
          </Grid>
          {this.state.vazias.indexOf(vaca.id) >= 0 ||
            this.state.descartesPrenheTouro.indexOf(vaca.id) >= 0 ||
            this.state.prenhes.indexOf(vaca.id) >= 0 ||
            this.state.descartesVazia.indexOf(vaca.id) >= 0 ? null : (
            <Grid item style={diasGestacao > 315 ? { color: '#f5004f' } : {}}>
              Dias de Gestação: {diasGestacao < 0 ? 0 : diasGestacao}
            </Grid>
          )}

          {vaca.previousObs &&
            vaca.previousObs.map((obs, index) => {
              const style = index === 0 ? { marginTop: '3em' } : null
              return (
                <Grid
                  item
                  key={`${vaca.id}_obs_${index}`}
                  xs={12}
                  style={style}
                >
                  <b>{obs.manejo}: </b>
                  {obs.obs}
                </Grid>
              )
            })}
        </Grid>
      </Fragment>
    )
  }

  renderManejo(vaca, index) {
    // const condicao = _.find(this.props.condicaoDG, { id: vaca.condicaoDG })
    return (
      <div className='grid grid-dg-final'>
        <div className='grid-item p-12 t-6 d-4'>
          <Autocomplete
            id={`condicaoDGFinal_${index}`}
            options={this.props.condicaoDGFinal}
            onChange={(e, value) => {
              const {
                isVazia,
                isPrenhe,
                isPrenheIATF,
                isDescartePrenhe,
                isDescarteVazia,
                isDescartePrenheTouro,
              } = this.updateCondition(vaca.id, value)
              // vaca.perdaGestacao
              this.setState({
                [vaca.id]: {
                  ...this.state[vaca.id],
                  condicaoDGFinal: value && value.id,
                  condicaoDGFinal_error:
                    !_.isEmpty(value) && !_.isEmpty(value.id)
                      ? null
                      : REQUIRED_FIELD_MESSAGES.DEFAULT,
                  pesoBezerro: null,
                  tipoPrenheFinal:
                    isVazia || isDescarteVazia
                      ? null
                      : this.props.tipoPrenhe.filter(
                        (tipo) => tipo.valor === 'Normal'
                      )[0].id,
                  tipoPrenheFinal_error: !_.isEmpty(value) && null,
                  isVazia,
                  isPrenhe,
                  isPrenheIATF,
                  isDescartePrenhe,
                  isDescarteVazia,
                  isDescartePrenheTouro,
                  dataDgFinal: moment.utc(),
                  observacao_DG_Final:
                    this.state[vaca.id].observacao_DG_Final ===
                      ANIMAL_NOT_PRESENT ||
                      this.state[vaca.id].observacao_DG_Final === ANIMAL_DIED
                      ? null
                      : this.state[vaca.id].observacao_DG_Final,
                  perdaGestacao: value && this.checkPrenhez(vaca, value.id),
                  clFinal:
                    isVazia || isDescarteVazia
                      ? this.props.clVazia.filter(
                        (x) => x.valor === 'Não Avaliado'
                      )[0].id
                      : null,
                },
              })
              if (!!this.state[vaca.id].condicaoDGFinal) {
                this.setState({
                  diagnosticLength: this.state.diagnosticLength - 1,
                })
              }

              this.removeFromSave(vaca.id)
            }}
            onKeyDown={
              (this.state[vaca.id].condicaoDGFinal ||
                this.state[vaca.id].condicaoDGFinal_value) &&
              this.state[vaca.id].condicaoDGFinal.length > 3 &&
              ((e) => this.onEnterPress(vaca.id, e))
            }
            value={this.state[vaca.id].condicaoDGFinal || null}
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.condicaoDGFinal
            )}
            useDefaultOptionSelected
            label='Diagnóstico Final'
            error={!_.isEmpty(this.state[vaca.id].condicaoDGFinal_error)}
            helperText={this.state[vaca.id].condicaoDGFinal_error}
          />
        </div>

        <div className='grid-item p-12 t-6 d-2'>
          <Autocomplete
            id={`ecC_Final_${index}`}
            options={this.props.ecc}
            onChange={(e, value) => {
              this.setState({
                [vaca.id]: {
                  ...this.state[vaca.id],
                  ecC_Final: value && value.id,
                  dataDgFinal: moment.utc(),
                },
              })
              this.removeFromSave(vaca.id)
            }}
            onKeyDown={
              (this.state[vaca.id].ecC_Final ||
                this.state[vaca.id].ecC_Final_value) &&
              this.state[vaca.id].ecC_Final.length > 3 &&
              ((e) => this.onEnterPress(vaca.id, e))
            }
            value={this.state[vaca.id].ecC_Final || null}
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.ecc
            )}
            useDefaultOptionSelected
            label='ECC Final'
          />
        </div>

        {this.state.vazias.indexOf(vaca.id) >= 0 ||
          this.state.descartesVazia.indexOf(vaca.id) >= 0 ? (
          <div className='grid-item p-12 t-6 d-3'>
            <Autocomplete
              id={`clFinal_${index}`}
              options={this.props.clVazia}
              onChange={(e, value) => {
                this.setState({
                  [vaca.id]: {
                    ...this.state[vaca.id],
                    clFinal: value && value.id,
                    dataDg: moment.utc(),
                  },
                })
                this.removeFromSave(vaca.id)
              }}
              onKeyDown={
                (this.state[vaca.id].clFinal ||
                  this.state[vaca.id].clFinal_value) &&
                this.state[vaca.id].clFinal.length > 3 &&
                ((e) => this.onEnterPress(vaca.id, e))
              }
              value={this.state[vaca.id].clFinal || null}
              getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                this.props.clVazia
              )}
              useDefaultOptionSelected
              label='Presença de CL'
            />
          </div>
        ) : (
          <div className='grid-item p-12 t-6 d-3'>
            <Autocomplete
              id={`tipoPrenheFinal_${index}`}
              options={this.props.tipoPrenhe}
              onChange={(e, value) => {
                this.setState({
                  [vaca.id]: {
                    ...this.state[vaca.id],
                    tipoPrenheFinal: value && value.id,
                    tipoPrenheFinal_error:
                      (_.isEmpty(value) || _.isEmpty(value.id)) &&
                      REQUIRED_FIELD_MESSAGES.DEFAULT,
                    dataDg: moment.utc(),
                  },
                })
                this.removeFromSave(vaca.id)
              }}
              onKeyDown={
                (this.state[vaca.id].tipoPrenheFinal ||
                  this.state[vaca.id].tipoPrenheFinal_value) &&
                this.state[vaca.id].tipoPrenheFinal.length > 3 &&
                ((e) => this.onEnterPress(vaca.id, e))
              }
              value={this.state[vaca.id].tipoPrenheFinal || null}
              getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                this.props.tipoPrenhe
              )}
              useDefaultOptionSelected
              label='Tipo'
              error={!_.isEmpty(this.state[vaca.id].tipoPrenheFinal_error)}
              helperText={this.state[vaca.id].tipoPrenheFinal_error}
            />
          </div>
        )}

        {this.state.prenhes.indexOf(vaca.id) >= 0 ||
          this.state.descartesPrenheTouro.indexOf(vaca.id) >= 0 ? (
          <div className='grid-item p-12 t-6 d-3'>
            <TextField
              id={`diasGestação_${index}`}
              value={this.state[vaca.id].diasGestacaoTouro || null}
              type='number'
              onChange={(event) => {
                const value = event.target.value
                this.setState({
                  [vaca.id]: {
                    ...this.state[vaca.id],
                    diasGestacaoTouro: parseInt(value),
                    dataDgFinal: moment.utc(),
                  },
                })
                this.removeFromSave(vaca.id)
              }}
              onKeyDown={(e) => this.onEnterPress(vaca.id, e)}
              label='Dias de Gestação'
            />
          </div>
        ) : (
          (vaca.diasGestacaoTouro = null)
        )}

        <div className='grid-item p-12 t-6 d-3'>
          <TextField
            id={`pesoBezerro_${index}`}
            value={this.state[vaca.id].pesoBezerro || null}
            type='number'
            onChange={(event) => {
              const value = event.target.value
              this.setState({
                [vaca.id]: {
                  ...this.state[vaca.id],
                  pesoBezerro: parseInt(value),
                  dataDgFinal: moment.utc(),
                },
              })
              this.removeFromSave(vaca.id)
            }}
            onKeyDown={(e) => this.onEnterPress(vaca.id, e)}
            label={'Peso Bezerro'}
          />
        </div>

        <div
          className={
            this.state.prenhes.indexOf(vaca.id) >= 0 ||
              this.state.descartesPrenheTouro.indexOf(vaca.id) >= 0
              ? 'grid-item p-12 t-6 d-7'
              : 'grid-item p-12 t-6 d-10'
          }
        >
          <Autocomplete
            id={`observacao_DG_Final_${index}`}
            options={obsOptions}
            onChange={(e, value) => {
              let state = {
                ...this.state[vaca.id],
                observacao_DG_Final: value,
                dataDgFinal: moment.utc(),
              }
              if (value === ANIMAL_NOT_PRESENT || value === ANIMAL_DIED) {
                state.condicaoDGFinal = null
                state.ecC_Final = null
                state.clFinal = null
                state.tipoPrenheFinal = null
                state.diasGestacaoTouro = null
                state.pesoBezerro = null
                state.condicaoDGFinal_error = null
              }
              this.setState({
                [vaca.id]: state,
              })
              this.removeFromSave(vaca.id)
            }}
            onKeyDown={
              (this.state[vaca.id].observacao_DG_Final ||
                this.state[vaca.id].observacao_DG_Final_value) &&
              this.state[vaca.id].observacao_DG_Final.length > 3 &&
              ((e) => this.onEnterPress(vaca.id, e))
            }
            label='Observações'
            value={this.state[vaca.id].observacao_DG_Final || null}
            openField
          />
        </div>

        <div className='grid-item p-12 t-12 d-2'>
          <Button
            style={{ width: '100%', marginTop: '12px' }}
            label='OK'
            onClick={() =>
              this.state.salvas.indexOf(vaca.id) >= 0
                ? console.log(`There's nothing to save`)
                : this.handleSave(vaca.id)
            }
            disabled={
              this.state.salvas.indexOf(vaca.id) >= 0 ||
              this.props.user.roles[0].name === 'Cliente (somente visualização)'
            }
          />
        </div>
      </div>
    )
  }

  aplyForAll() {
    sortList(
      _.values(_.pick(this.state, this.state.selectedVacas)),
      'codVaca'
    ).map((row) => {
      if (this.state.salvas.indexOf(row.id) < 0) {
        const value = this.state.diagnosticoFinalForAll

        const vazias = this.state.vazias
        const prenhes = this.state.prenhes
        const prenhesIATF = this.state.prenhesIATF
        const descartesPrenhe = this.state.descartesPrenhe
        const descartesVazia = this.state.descartesVazia
        const descartesPrenheTouro = this.state.descartesPrenheTouro

        const isVazia = value && value.valor === 'Vazia'
        const isPrenheIATF = value && value.valor === 'Prenhe IATF'
        const isPrenhe = value && value.valor === 'Prenhe Touro'
        const isDescartePrenhe = value && value.valor === 'Descarte Prenhe IATF'
        const isDescartePrenheTouro =
          value && value.valor === 'Descarte Prenhe Touro'
        const isDescarteVazia = value && value.valor === 'Descarte Vazia'

        vazias.indexOf(row.id) >= 0
          ? !isVazia
            ? vazias.splice(vazias.indexOf(row.id), 1)
            : vazias.push(row.id)
          : isVazia
            ? vazias.push(row.id)
            : null
        prenhes.indexOf(row.id) >= 0
          ? !isPrenhe
            ? prenhes.splice(prenhes.indexOf(row.id), 1)
            : prenhes.push(row.id)
          : isPrenhe
            ? prenhes.push(row.id)
            : null
        prenhesIATF.indexOf(row.id) >= 0
          ? !isPrenheIATF
            ? prenhesIATF.splice(prenhesIATF.indexOf(row.id), 1)
            : prenhesIATF.push(row.id)
          : isPrenheIATF
            ? prenhesIATF.push(row.id)
            : null
        descartesPrenhe.indexOf(row.id) >= 0
          ? !isDescartePrenhe
            ? descartesPrenhe.splice(descartesPrenhe.indexOf(row.id), 1)
            : descartesPrenhe.push(row.id)
          : isDescartePrenhe
            ? descartesPrenhe.push(row.id)
            : null
        descartesVazia.indexOf(row.id) >= 0
          ? !isDescarteVazia
            ? descartesVazia.splice(descartesVazia.indexOf(row.id), 1)
            : descartesVazia.push(row.id)
          : isDescarteVazia
            ? descartesVazia.push(row.id)
            : null
        descartesPrenheTouro.indexOf(row.id) >= 0
          ? !isDescartePrenheTouro
            ? descartesPrenheTouro.splice(
              descartesPrenheTouro.indexOf(row.id),
              1
            )
            : descartesPrenheTouro.push(row.id)
          : isDescartePrenheTouro
            ? descartesPrenheTouro.push(row.id)
            : null
        this.setState({
          [row.id]: {
            ...row,
            condicaoDGFinal:
              this.state.diagnosticoFinalForAll &&
              this.state.diagnosticoFinalForAll.id,
            condicaoDGFinal_error:
              !_.isEmpty(this.state.diagnosticoFinalForAll) &&
                !_.isEmpty(this.state.diagnosticoFinalForAll.id)
                ? null
                : REQUIRED_FIELD_MESSAGES.DEFAULT,
            clFinal:
              (isVazia || isDescarteVazia) &&
              this.props.clVazia.filter((cl) => cl.valor === 'Não Avaliado')[0]
                .id,
            tipoPrenheFinal:
              isVazia || isDescarteVazia
                ? null
                : this.props.tipoPrenhe.filter(
                  (tipo) => tipo.valor === 'Normal'
                )[0].id,
            isVazia,
            isPrenhe,
            isPrenheIATF,
            isDescartePrenhe,
            isDescartePrenheTouro,
            isDescarteVazia,
            omit_from_dg_final: false,
            dataDg: moment.utc(),
            perdaGestacao: value && this.checkPrenhez(row, value.id),
          },
        })
      }
      return null
    })
  }

  renderTitle() {
    return (
      <Fragment>
        Protocolo: {this.props.protocol.name}
        <br />
        Manejo: DG Final
      </Fragment>
    )
  }

  getButtons() {
    return [
      {
        onClick: () => {
          let newState = { showingNoDateError: true }
          if (
            this.state.dataDoManejo != null &&
            this.state.dataDoManejo?.isValid
          ) {
            newState =
              this.state.salvas.length === this.props.vacas.length
                ? {
                  showingFinalizeConfirmation: true,
                }
                : {
                  showingIncompleteError: true,
                }
          }
          this.setState(newState)
        },
        icon: <DoneIcon />,
        label: 'Finalizar',
        disabled:
          this.props.user.roles[0].name === 'Cliente (somente visualização)',
      },
    ]
  }

  getPaths() {
    const uniqueCorrals = _.uniq(this.props.corrals.map((corral) => corral.id))
    // const vacasCount = this.props.vacas.length > 0 ? this.props.vacas.length : 1
    const extraPath = this.props.farm.retiro &&
      uniqueCorrals.length === 1 && {
      route: `/farms/${this.props.farm.id}/corrals/${uniqueCorrals[0]}`,
      label: formatName(
        _.find(this.props.corrals, { id: uniqueCorrals[0] }).nome,
        RETIRO
      ),
    }

    const batchPath =
      this.props.batches.length === 1
        ? {
          route: `/farms/${this.props.farm.id}/corrals/${this.props.batches[0].retiroId
            }/batches/${this.props.batches[0].originalBatchId
              ? this.props.batches[0].originalBatchId
              : this.props.batches[0].id
            }`,
          label: formatName(this.props.batches[0].nomeLote, LOTE),
        }
        : {
          label: 'Vários Lotes',
        }

    return _.compact([
      {
        route: `/farms/${this.props.farm.id}`,
        label: formatName(this.props.farm.nome, FAZENDA),
      },
      extraPath,
      batchPath,
      {
        label: 'DG Final',
      },
    ])
  }

  getTableColumnsData() {
    return [
      {
        name: 'Matriz (ID)',
        type: CELL_TYPES.FIELD,
        field: (vaca, index) => this.renderCowCell(vaca, index),
        grid: 3,
      },
      {
        name: 'Manejo',
        type: CELL_TYPES.FIELD,
        field: (vaca, index) => this.renderManejo(vaca, index),
        grid: 9,
      },
    ]
  }

  getTableData() {
    if (this.state.searchValue !== '') {
      let tableData = []

      this.state.vacasSelected.map(
        (x) => this.state.selectedVacas.indexOf(x.id) >= 0 && tableData.push(x)
      )

      return sortList(tableData, 'codVaca')
    } else {
      return sortList(this.state.vacasSelected, 'codVaca')
    }
  }

  render() {
    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={this.renderTitle()}
          buttons={this.getButtons()}
          paths={this.getPaths()}
        />
        <MainContainer
          hasSearch
          hasValue={this.state.searchValue !== '' ? true : false}
          handleSearch={this.handleSearch}
          useVoiceRecognition
          focusSearch={this.state.focusSearch}
          clearSearch={this.state.clearSearch}
          preSearchChildren={this.renderHeader()}
          alert={this.state.searchError}
          cowsValues={this.state.searchInputCow}
          hasChangeCowsToBatch={
            this.state.searchInputCow &&
              !this.props.isResync &&
              this?.state?.searchInputCow[0]?.iatf === 0
              ? true
              : false
          }
          changeCowsToBatch={(item) =>
            this.setState({
              showingChangeCowsToBatchConfirmation: true,
              selectedCowToChangeToBatch: item,
            })
          }
        >
          <div className='grid grid-dg-final'>
            <div className='grid-item p-12 t-5 d-3'>
              <Autocomplete
                id={`diagnostico_final_all`}
                options={this.props.condicaoDGFinal}
                value={this.state.diagnosticoFinalForAll || null}
                label='Diagnóstico Final'
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.condicaoDG
                )}
                onChange={(e, value) => {
                  this.setState({
                    diagnosticoFinalForAll: value,
                  })
                }}
                tabIndex={this.state.searchValue !== '' ? -1 : null}
              />
            </div>

            <div className='grid-item p-12 t-7 d-4 p-margin-bottom-2'>
              <Button
                label='Preencher Todas'
                style={{
                  width: '48%',
                  height: '46px',
                  marginTop: '10px',
                  padding: 0,
                }}
                disabled={
                  this.state.diagnosticLength === this.props.vacas.length
                }
                onClick={() => {
                  this.aplyForAll()
                  this.setState({
                    diagnosticoFinalForAll: null,
                    diagnosticLength: this.props.vacas.length,
                  })
                }}
                tabIndex={this.state.searchValue !== '' ? -1 : null}
              />
              <Button
                label='Salvar todas'
                style={{
                  width: '48%',
                  marginLeft: '4%',
                  height: '46px',
                  marginTop: '10px',
                }}
                disabled={
                  this.state.salvas.length === this.props.vacas.length ||
                  this.props.user.roles[0].name ===
                  'Cliente (somente visualização)'
                }
                onClick={() => this.setState({ saveAllConfirm: true })}
                tabIndex={this.state.searchValue !== '' ? -1 : null}
              />
            </div>

            <div className='grid-item p-12 t-12 d-3 d-offset-1'>
              <DateField
                id='dataDoManejo'
                label='Data do DG Final'
                value={this.state.dataDoManejo || null}
                onChange={this.datefieldDefaultOnChange('dataDoManejo')}
                error={
                  !_.isEmpty(this.state.dataDoManejo_error) ||
                  !this.isDateNullOrValid(this.state.dataDoManejo)
                }
                helperText={this.state.dataDoManejo_error}
                tabIndex={this.state.searchValue !== '' ? -1 : null}
              />
            </div>

            <div className='d-12 p-12'>
              <ResponsiveTable
                columns={this.getTableColumnsData()}
                data={this.getTableData()}
                className='table-dg-final'
              />
            </div>

            <Prompt
              visible={this.state.showingChangeCowsToBatchConfirmation}
              title={changeCowsToBatchNotes.title}
              message={changeCowsToBatchNotes.text}
              buttons={[
                {
                  label: 'Cancelar',
                  onClick: () =>
                    this.setState({
                      showingChangeCowsToBatchConfirmation: false,
                    }),
                },
                {
                  label: 'Mover',
                  onClick: () =>
                    this.changeCowsToBatch(
                      this.state.selectedCowToChangeToBatch
                    ),
                },
              ]}
            />

            <Prompt
              visible={verify()}
              title='Página duplicada!'
              message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
                Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
              buttons={[
                {
                  autoFocus: false,
                },
              ]}
            />

            <Prompt
              visible={this.state.saveAllConfirm}
              title='Confirmação:'
              message={`Deseja realmente salvar todas?`}
              buttons={[
                {
                  label: 'Não',
                  onClick: () =>
                    this.setState({
                      saveAllConfirm: false,
                    }),
                },
                {
                  label: 'Sim',
                  onClick: () => this.saveAll(),
                },
              ]}
            />

            <Prompt
              visible={this.state.showingFinalizeConfirmation}
              title='Deseja Finalizar?'
              message='Uma vez finalizado não será possível retornar e os dados não salvos serão perdidos.'
              buttons={[
                {
                  label: 'Não',
                  onClick: () =>
                    this.setState({
                      showingFinalizeConfirmation: false,
                      confirmedBackupDownload: true,
                    }),
                },
                {
                  label: 'Sim',
                  onClick: this.state.confirmedBackupDownload
                    ? () => this.finalize(true)
                    : () =>
                      this.setState({
                        showingFinalizeConfirmation: false,
                        showingNoBackupConfirmation: true,
                      }),
                },
              ]}
            />

            <Prompt
              visible={this.state.showingNoBackupConfirmation}
              title='Atenção!'
              message='O comprovante de manejo pode ser necessário durante um eventual cenário de perda de dados. Não baixar o comprovante poderá dificultar a recuperação das informações desse manejo. Deseja continuar mesmo assim?'
              buttons={[
                {
                  label: 'Cancelar',
                  onClick: () =>
                    this.setState({
                      showingNoBackupConfirmation: false,
                      showingFinalizeConfirmation: true,
                    }),
                },
                {
                  label: 'Sim',
                  onClick: () => this.finalize(false),
                },
              ]}
            />

            <Prompt
              visible={this.state.showingNoDateError}
              title='Erro'
              message='Você precisa preencher a data do manejo para finalizar.'
              buttons={[
                {
                  label: 'Ok',
                  onClick: () => this.setState({ showingNoDateError: false }),
                },
              ]}
            />

            <Prompt
              visible={this.state.showingIncompleteError}
              title='Erro'
              message='Existem matrizes que não foram salvas.'
              buttons={[
                {
                  label: 'OK',
                  onClick: () =>
                    this.setState({ showingIncompleteError: false }),
                },
              ]}
            />
          </div>
        </MainContainer>
      </Container>
    )
  }
}

const countPregnancyDays = async (relatedCows, condicaoDg) => {
  const prenhe = _.filter(
    condicaoDg,
    (cond) => cond.valor === 'Prenhe' || cond.valor === 'Descarte Prenhe'
  ).map((cond) => cond.id)
  if (!relatedCows || relatedCows.length < 1) return null
  const pregnantCow = relatedCows.find(
    (cow) => cow.condicaoDG && prenhe.includes(cow.condicaoDG)
  )
  if (!pregnantCow) return null
  try {
    const dzeroBatch = await utils.getElement(
      AVAILABLE_ENTITIES.BATCHES,
      pregnantCow.loteId
    )
    const dzeroProtocol = await utils.getElement(
      AVAILABLE_ENTITIES.PROTOCOLS,
      dzeroBatch.protocoloId
    )
    const iatfManejo = (await utils.getDomainValuesBy('Tipo de Manejo')).find(
      (valor_dominio) => valor_dominio.valor.toLowerCase() === 'iatf'
    )
    const iatfManagement = dzeroProtocol.managementProtocols.find(
      (manejo) => manejo.managementId === iatfManejo.id
    )

    const day = (
      await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        iatfManagement.day
      )
    ).valor.replace(/\D/g, '')
    let iatfDate =
      dzeroBatch.dataIatf.valueOf() + Number(day) * 24 * 3600 * 1000

    let Difference_In_Time = moment.utc().valueOf() - iatfDate
    let pregnancyDays = Math.floor(Difference_In_Time / (1000 * 3600 * 24))

    return pregnancyDays
  } catch (ex) {
    console.error(ex)
    return 'Não Conclusivo'
  }
}

const propsFromDatabase = async (props) => {
  const batchIds = props.match.params.id.split(';')

  const batches = await getBatchesByBatchIds(batchIds)

  const corrals = await utils.getWithParam(
    AVAILABLE_ENTITIES.CORRALS,
    'id',
    Q.oneOf(_.uniq(batches.map((batch) => batch.retiroId)))
  )

  const isMerging = batchIds.length > 1

  let batchList = _.flatten(
    await Promise.all(
      batches.map(async (batch) => {
        if (batch.originalBatchId === null) {
          return batch
        }
        const originalBatch = await utils.getElement(
          AVAILABLE_ENTITIES.BATCHES,
          batch.originalBatchId
        )
        const relatedBatches = await utils.getWithParam(
          AVAILABLE_ENTITIES.BATCHES,
          'originalBatch_id',
          originalBatch.id
        )
        return [originalBatch].concat(relatedBatches)
      })
    )
  )

  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    corrals[0].fazendaId
  )

  const partidas = await utils.getWithParam(
    AVAILABLE_ENTITIES.PARTIDA,
    'farm_id',
    farm.id
  )

  const bulls = await utils.getWithParam(
    AVAILABLE_ENTITIES.BULLS_NEW,
    'id',
    Q.oneOf(partidas.map((f) => f.touroId))
  )

  const days = await utils.getDomainValuesBy('Dias')
  const condicaoDG = _.sortBy(await utils.getDomainValuesBy('Condição DG'), [
    (condicao) => condicao.valor !== 'Prenhe',
    (condicao) => condicao.valor !== 'Vazia',
    (condicao) => condicao.valor !== 'Descarte Prenhe',
    (condicao) => condicao.valor !== 'Descarte Vazia',
  ])
  const condicaoDGFinal = _.sortBy(
    await utils.getDomainValuesBy('Condição DG Final'),
    [
      (condicao) => condicao.valor !== 'Prenhe IATF',
      (condicao) => condicao.valor !== 'Prenhe Touro',
      (condicao) => condicao.valor !== 'Vazia',
      (condicao) => condicao.valor !== 'Descarte Prenhe IATF',
      (condicao) => condicao.valor !== 'Descarte Vazia',
      (condicao) => condicao.valor !== 'Descarte Prenhe Touro',
    ]
  )

  const allVacas = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    Q.oneOf(batchList.map((batch) => batch.id))
  )

  let vacas = _.filter(allVacas, (vaca) => !vaca.omit_from_dg_final)

  for (const vaca of vacas) {
    vaca.lote = _.find(batchList, { id: vaca.loteId }).nomeLote

    const relatedCows = _.sortBy(
      _.filter(
        allVacas,
        (candidate) =>
          candidate.id === vaca.id ||
          (vaca.dzeroOriginal !== null &&
            (candidate.dzeroOriginal === vaca.dzeroOriginal ||
              candidate.id === vaca.dzeroOriginal))
      ),
      [(vaca) => vaca.iatf]
    )
    for (const relatedCow of relatedCows) {
      let previousObs = []

      if (
        !_.isEmpty(relatedCow.observacaoD0) &&
        relatedCow.observacaoD0 !== '[N/A]'
      ) {
        previousObs.push({
          manejo: `IATF ${relatedCow.iatf + 1} - D0`,
          obs: relatedCow.observacaoD0
            ? format.sanitizeObs(relatedCow.observacaoD0)
            : 'Nada Consta',
        })
      }

      const dns = await utils.getWithParam(
        AVAILABLE_ENTITIES.DNS,
        'vacaId',
        relatedCow.id
      )
      if (dns.length > 0) {
        previousObs = previousObs.concat(
          _.sortBy(
            _.compact(
              dns.map(
                (dn) =>
                  !_.isEmpty(dn.observacao) &&
                  dn.observacao !== '[N/A]' && {
                    manejo: `IATF ${relatedCow.iatf + 1} - ${_.find(days, { id: dn.dia }).valor
                      }`,
                    obs: format.sanitizeObs(dn.observacao),
                  }
              )
            ),
            [
              (obs) =>
                obs.manejo.startsWith('D')
                  ? parseInt(obs.manejo.substring(1))
                  : 100,
            ]
          )
        )
      }

      const iatfs = await utils.getWithParam(
        AVAILABLE_ENTITIES.IATFS,
        'vacaId',
        vaca.id
      )

      if (iatfs.length > 0) {
        if (!!iatfs[0].partidaId && iatfs[0].partidaId !== '') {
          const partida = _.find(partidas, { id: iatfs[0].partidaId })
          const bull = !!partida && _.find(bulls, { id: partida.touroId })

          if (!!!bull && !!!partida) {
            vaca.partidaName = null
          } else {
            vaca.partidaName = `${!!bull ? bull.nome : ''} - ${!!partida && (partida?.codigo || moment(partida?.data).format('DD/MM/YYYY') || '')}`
          }
        }

        const sortedObs = _.sortBy(
          _.compact(
            iatfs.map(
              (iatf) =>
                !_.isEmpty(iatf.observacao) &&
                iatf.observacao !== '[N/A]' && {
                  manejo: `IATF ${relatedCow.iatf + 1} - IATF`,
                  obs: format.sanitizeObs(iatf.observacao),
                }
            )
          )
        )
        previousObs = previousObs.concat(sortedObs)
      }

      if (
        !_.isEmpty(relatedCow.observacaoDG) &&
        relatedCow.observacaoDG !== '[N/A]' &&
        relatedCow.observacaoDG !== 'Matriz criada após o D0'
      ) {
        previousObs.push({
          manejo: `IATF ${relatedCow.iatf + 1} - DG`,
          obs: format.sanitizeObs(relatedCow.observacaoDG),
        })
      }

      if (previousObs.length > 0) {
        vaca.previousObs = previousObs
      }
    }
    vaca.diasGestacao = await countPregnancyDays(relatedCows, condicaoDG)
  }

  return {
    user: await utils.getElement(
      AVAILABLE_ENTITIES.USERS,
      (
        await getLoggedUser()
      ).userId
    ),
    batches,
    corrals,
    isMerging,
    loteId: batches[0].id,
    isResync: isMerging || (batches.length === 1 && batches[0].isResync),
    farm,
    protocol: await utils.getElement(
      AVAILABLE_ENTITIES.PROTOCOLS,
      batches[0].protocoloId
    ),
    tipoPrenhe: await utils.getDomainValuesBy('Tipo Prenhe'),
    vacas,
    condicaoDG,
    condicaoDGFinal,
    clVazia: await utils.getDomainValuesBy('CL'),
    prenhe: _.find(condicaoDGFinal, (cond) => cond.valor === 'Prenhe Touro'),
    prenheIATF: _.find(condicaoDGFinal, (cond) => cond.valor === 'Prenhe IATF'),
    vazia: _.find(condicaoDGFinal, (cond) => cond.valor === 'Vazia'),
    descartePrenhe: _.find(
      condicaoDGFinal,
      (cond) => cond.valor === 'Descarte Prenhe IATF'
    ),
    descartePrenheTouro: _.find(
      condicaoDGFinal,
      (cond) => cond.valor === 'Descarte Prenhe Touro'
    ),
    descarteVazia: _.find(
      condicaoDGFinal,
      (cond) => cond.valor === 'Descarte Vazia'
    ),
    ecc: _.sortBy(await utils.getDomainValuesBy('ECC'), [
      (ecc) => parseFloat(ecc.valor),
    ]),
    iatfs: await utils.getWithParam(
      AVAILABLE_ENTITIES.IATFS,
      'vacaId',
      Q.oneOf(vacas.map((vaca) => vaca.id))
    ),
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(DGFinal)
)
