import * as format from './resources/format'
import * as utils from './resources/utils'

import {
  AVAILABLE_DTO_MAPPINGS,
  AVAILABLE_ENTITIES,
  NUMBER_TYPES,
  databaseMigrations
} from './resources/constants'
import { fromDTO, toDTO } from './resources/autoMapping'

import Batches from './models/Batches'
import Bulls from './models/Bulls'
import BullsNew from './models/BullsNew'
import Partida from './models/Partida'
import Corrals from './models/Corrals'
import D0s from './models/D0s'
import DNs from './models/DNs'
import { Database } from '@nozbe/watermelondb'
import DomainTypes from './models/DomainTypes'
import DomainValues from './models/DomainValues'
import FarmUsers from './models/FarmUsers'
import Farms from './models/Farms'
import IATFs from './models/IATFs'
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs'
import ManagementProtocols from './models/ManagementProtocols'
import Protocols from './models/Protocols'
import Repository from './infra/repository'
import Roles from './models/Roles'
import Terms from './models/Terms'
import Users from './models/Users'
import databaseSchema from './infra/schema'

// Export Table Objects to be used elsewhere
export {
  Batches,
  Bulls,
  BullsNew,
  Partida,
  D0s,
  DNs,
  DomainTypes,
  DomainValues,
  FarmUsers,
  Farms,
  IATFs,
  ManagementProtocols,
  Protocols,
  Users,
  Roles,
  Terms,
  Corrals
}

export {
  fromDTO,
  toDTO,
  AVAILABLE_DTO_MAPPINGS,
  AVAILABLE_ENTITIES,
  NUMBER_TYPES,
  Repository
}

// Export migrations description
export { databaseMigrations, databaseSchema }

// Export utility functions
export { utils }
export { format }

// Export as default the collection of tables
export const models = [
  Batches,
  Bulls,
  BullsNew,
  Partida,
  D0s,
  DNs,
  DomainTypes,
  DomainValues,
  FarmUsers,
  Farms,
  IATFs,
  ManagementProtocols,
  Protocols,
  Users,
  Roles,
  Terms,
  Corrals
]

const adapter = new LokiJSAdapter({
  schema: databaseSchema,
  migrations: databaseMigrations,
  useWebWorker: false,
  useIncrementalIndexedDB: true
  // It's recommended you implement this method:
  // onIndexedDBVersionChange: () => {
  //   // database was deleted in another browser tab (user logged out), so we must make sure we delete
  //   // it in this tab as well
  //   if (checkIfUserIsLoggedIn()) {
  //     window.location.reload()
  //   }
  // },
})

const database = new Database({
  adapter,
  modelClasses: [
    Batches,
    Bulls,
    BullsNew,
    Partida,
    D0s,
    DNs,
    DomainTypes,
    DomainValues,
    FarmUsers,
    Farms,
    IATFs,
    ManagementProtocols,
    Protocols,
    Users,
    Roles,
    Terms,
    Corrals
  ],
  actionsEnabled: true
})

export default database
