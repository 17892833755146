import _ from 'lodash'

export function sanitizeObs (obs) {
  if (_.isEmpty(obs)) return ''
  if (obs === '[N/A]') {
    return 'Nada Consta'
  }
  if (obs.includes(']')) {
    // + 1 to remove ] itself
    return obs.substring(_.findIndex(obs, char => char === ']') + 1).trim()
  }
  return obs
}
