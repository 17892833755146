import moment from 'moment'
import { makeStyles } from '@material-ui/core'
import { ErrorCode } from 'react-dropzone'

export const useStyles = makeStyles(() => ({
  dropzone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '60vh',
    border: '2px dashed #bcbcbc',
    backgroundColor: '#ececec',
    color: '#999',
    marginBottom: '1rem',
  },
  loadingCircularProgress: {
    background: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
  },
}))

export const tour = [
  {
    element: '.no-have',
    popover: {
      title: 'Regras para importação',
      description:
        'Para importar os dados do seu lote, você precisa utilizar o modelo de arquivo. Caso você não tenha o modelo de arquivo, você pode baixar clicando no botão "Baixar modelo de importação". É importante que você atualize o modelo de arquivo sempre que incluir ou excluir Semên e/ou Inseminador. E sempre utilizar o modelo da fazenda que você está importando os dados.',
    },
  },
  {
    element: '.download-file-button',
    popover: {
      title: 'Baixar modelo de importação',
      description: 'Clique aqui para baixar o modelo de importação.',
    },
  },
  {
    element: '.back-button',
    popover: {
      title: 'Botão voltar',
      description: 'Clique aqui para voltar para a tela anterior.',
    },
  },
  {
    element: '.dropzone-area',
    popover: {
      title: 'Área de upload de arquivo',
      description:
        'Arraste e solte um arquivo aqui ou clique para procurar. O arquivo deve ser do tipo .xls ou .xlsx.',
    },
  },
  {
    element: '.upload-file-button',
    popover: {
      title: 'Botão enviar dados',
      description: 'Clique aqui para enviar o arquivo.',
    },
  },
]

export const formatDataToCorrectType = (userData) => {
  let formattedData = []

  userData.forEach((item) => {
    let formattedItem = {
      D0: {},
      DN: {},
      IATF: {},
      DG: {},
      DGFinal: {},
    }

    Object.keys(item).forEach((key) => {
      const category = key.split(' - ')[0].replace(/\s+/g, '')

      if (category === 'D0') {
        formattedItem.D0[key] = item[key]
      } else if (category === 'DN1' || category === 'DN2') {
        formattedItem.DN[key] = item[key]
      } else if (category === 'IATF') {
        formattedItem.IATF[key] = item[key]
      } else if (category === 'DG') {
        formattedItem.DG[key] = item[key]
      } else if (category === 'DGFinal') {
        formattedItem.DGFinal[key] = item[key]
      }
    })

    const isD0Empty = Object.values(formattedItem.D0).every(
      (value) => value === null
    )
    const isIatfEmpty = Object.values(formattedItem.IATF).every(
      (value) => value === null
    )
    const isDgEmpty = Object.values(formattedItem.DG).every(
      (value) => value === null
    )
    const isDgFinalEmpty = Object.values(formattedItem.DGFinal).every(
      (value) => value === null
    )

    if (isD0Empty) formattedItem.D0 = null
    if (isIatfEmpty) formattedItem.IATF = null
    if (isDgEmpty) formattedItem.DG = null
    if (isDgFinalEmpty) formattedItem.DGFinal = null

    formattedData.push(formattedItem)
  })

  return formattedData
}

export const formatSecondSheetData = (data) => {
  let formattedData = {}

  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      const [category, subCategory] = key.split(' - ')
      const value = item[key]

      if (!formattedData[category]) {
        formattedData[category] = {}
      }

      if (subCategory === 'Valor') {
        formattedData[category][value] = item[`${category} - ID`]
      }
    })
  })

  return formattedData
}

export const hasAllRequiredFields = (
  data,
  formattedData,
  d0Day,
  dnDays,
  iatfDay,
  passDgDay,
  passDay,
  currentDay
) => {
  let hasAllRequiredFields = []
  let hasDayError = {
    title: '',
    message: '',
  }

  const hasD0InData = formattedData.some((item) => item.D0)
  const hasIatfInData = formattedData.some((item) => item.IATF)
  const hasDgInData = formattedData.some((item) => item.DG)
  const hasDgFinalInData = formattedData.some((item) => item.DGFinal)

  if (hasD0InData && !hasIatfInData && (hasDgInData || hasDgFinalInData)) {
    hasDayError = {
      title: 'Erro ao importar dados',
      message:
        'Não é possível preencher os campos DG ou DG Final sem preencher o campo IATF',
    }
  }

  if (hasD0InData && hasIatfInData && !hasDgInData && hasDgFinalInData) {
    hasDayError = {
      title: 'Erro ao importar dados',
      message:
        'Não é possível preencher o campo DG Final sem preencher o campo DG',
    }
  }

  if (JSON.stringify(d0Day) === JSON.stringify(currentDay)) {
    data.forEach((item, index) => {
      if (
        !item['D0 - ID da Matriz'] &&
        !item['D0 - ID2 da Matriz (leitor de bastão)']
      ) {
        hasAllRequiredFields.push({
          isError: true,
          line: index + 2,
          message:
            'Preencha o campo D0 - ID da Matriz ou o campo D0 - ID2 da Matriz (leitor de bastão)',
        })
      }

      if (!item['D0 - Raça da Matriz']) {
        hasAllRequiredFields.push({
          isError: true,
          line: index + 2,
          message: 'Preencha o campo D0 - Raça da Matriz',
        })
      }

      if (!item['D0 - Ordem da Matriz']) {
        hasAllRequiredFields.push({
          isError: true,
          line: index + 2,
          message: 'Preencha o campo D0 - Ordem da Matriz',
        })
      }

      if (!item['D0 - Dispositivo Intravaginal']) {
        hasAllRequiredFields.push({
          isError: true,
          line: index + 2,
          message: 'Preencha o campo D0 - Dispositivo Intravaginal',
        })
      }

      if (!item['D0 - Uso do Dispositivo']) {
        hasAllRequiredFields.push({
          isError: true,
          line: index + 2,
          message: 'Preencha o campo D0 - Uso do Dispositivo',
        })
      }

      if (!item['D0 - Responsável']) {
        hasAllRequiredFields.push({
          isError: true,
          line: index + 2,
          message: 'Preencha o campo D0 - Responsável',
        })
      }
    })
  }

  if (
    JSON.stringify(dnDays[0] || dnDays[1]) === JSON.stringify(currentDay) ||
    JSON.stringify(iatfDay) === JSON.stringify(currentDay)
  ) {
    data.forEach((item, index) => {
      if (!item['IATF - Sêmen']) {
        hasAllRequiredFields.push({
          isError: true,
          line: index + 2,
          message: 'Preencha o campo IATF - Sêmen',
        })
      }
      if (!item['IATF - Inseminador']) {
        hasAllRequiredFields.push({
          isError: true,
          line: index + 2,
          message: 'Preencha o campo IATF - Inseminador',
        })
      }
    })
  }

  if (!currentDay && !passDgDay) {
    data.forEach((item, index) => {
      if (!item['DG - Diagnóstico']) {
        hasAllRequiredFields.push({
          isError: true,
          line: index + 2,
          message: 'Preencha o campo DG - Diagnóstico',
        })
      }
    })
  }

  if (!currentDay && passDgDay) {
    data.forEach((item, index) => {
      if (!item['DG Final - Diagnóstico Final']) {
        hasAllRequiredFields.push({
          isError: true,
          line: index + 2,
          message: 'Preencha o campo DG Final - Diagnóstico Final',
        })
      }

      if (!item['DG Final - Data do DG Final']) {
        hasAllRequiredFields.push({
          isError: true,
          line: index + 2,
          message: 'Preencha o campo DG Final - Data do DG Final',
        })
      }
    })
  }

  if (passDay?.valor === 'D0' && hasD0InData) {
    hasDayError = {
      title: 'Erro ao importar dados',
      message:
        'Parece que você está tentando importar dados de um D0 que já foi finalizado. Por favor, tire os dados do D0 da planilha e tente novamente.',
    }
  }

  if (JSON.stringify(iatfDay) === JSON.stringify(passDay) && hasIatfInData) {
    hasDayError = {
      title: 'Erro ao importar dados',
      message:
        'Parece que você está tentando importar dados de uma IATF que já foi finalizado. Por favor, tire os dados da IATF da planilha e tente novamente.',
    }
  }

  if (!currentDay && passDgDay && hasDgInData) {
    hasDayError = {
      title: 'Erro ao importar dados',
      message:
        'Parece que você está tentando importar dados de um DG que já foi finalizado. Por favor, tire os dados do DG da planilha e tente novamente.',
    }
  }

  return {
    hasAllRequiredFields,
    hasDayError,
  }
}

export const d0PayloadMapper = (item, data, batchId) => {
  return {
    codVaca: !!item['D0']['D0 - ID da Matriz']
      ? item['D0']['D0 - ID da Matriz']?.toString()
      : item['D0']['D0 - ID2 da Matriz (leitor de bastão)']?.toString(),
    ...(item['D0']['D0 - ID2 da Matriz (leitor de bastão)'] && {
      codLeitorBastao:
        item['D0']['D0 - ID2 da Matriz (leitor de bastão)']?.toString(),
    }),
    observacaoD0: item['D0']['D0 - Observação'] || null,
    ...(data.ECC[item['D0']['D0 - ECC']] && {
      ecc: data.ECC[item['D0']['D0 - ECC']],
    }),
    idade_Nov_Pri: data['Idade (Meses)'][item['D0']['D0 - Idade (Meses)']],
    responsavelId: data['Responsável'][item['D0']['D0 - Responsável']],
    dispositivoIntravaginal:
      data['Dispositivo Intravaginal'][
      item['D0']['D0 - Dispositivo Intravaginal']
      ],
    dataPartoIndividual: item['D0']['D0 - Data de parto individual']
      ? moment.utc(
        Math.round(
          (item['D0']['D0 - Data de parto individual'] - 25569) * 86400 * 1000
        )
      )
      : null,
    numero_Uso_Disp:
      data['Uso do Dispositivo'][item['D0']['D0 - Uso do Dispositivo']],
    racaMatriz: data['Raça da Matriz'][item['D0']['D0 - Raça da Matriz']],
    ordemMatriz: data['Ordem da Matriz'][item['D0']['D0 - Ordem da Matriz']],
    clD0: data['Presença de CL'][item['D0']['D0 - Presença de CL']],
    peso_EM: item['D0']['D0 - Peso Inicial'] || null,
    loteId: batchId,
    dataProcesso: moment.utc(),
    omit_from_dg_final: false,
    iatf: 0,
  }
}

export const dnPayloadMapper = (item, batchId, cowId, dayId, index) => {
  if (index === 1) {
    return {
      vacaId: cowId,
      loteId: batchId,
      dia: dayId,
      observacao: item['DN']['DN1 - Observação'] || '[N/A]',
    }
  } else {
    return {
      vacaId: cowId,
      loteId: batchId,
      dia: dayId,
      observacao: item['DN']['DN2 - Observação (Se houver)'] || '[N/A]',
    }
  }
}

export const iatfPayloadMapper = (item, data, batchId, cowId) => {
  return {
    cioRetirada: data['Cio'][item['IATF']['IATF - Cio']],
    ecc: data.ECC[item['IATF']['IATF - ECC']],
    gnhr: data['GnRH'][item['IATF']['IATF - GnRH']],
    loteId: batchId,
    observacao: item['IATF']['IATF - Observação'],
    partidaId: data['Sêmen'][item['IATF']['IATF - Sêmen']],
    temperamento: data['Temperamento'][item['IATF']['IATF - Temperamento']],
    usuarioId: data['Inseminador'][item['IATF']['IATF - Inseminador']],
    vacaId: cowId,
  }
}

export const dgPayloadMapper = (item, data, cow) => {
  return {
    ...cow,
    clVazia: data['Presença de CL'][item['DG']['DG - Presença de CL']] || null,
    condicaoDG: data['Diagnóstico'][item['DG']['DG - Diagnóstico']],
    eccDG: data.ECC[item['DG']['DG - ECC']] || null,
    tipoPrenhe: data.Tipo[item['DG']['DG - Tipo']] || null,
    observacaoDG: item['DG']['DG - Observação'] || null,
  }
}

export const dgFinalPayloadMapper = (item, data, cow) => {
  return {
    ...cow,
    clFinal:
      data['Presença de CL'][item['DGFinal']['DG Final - Presença de CL']] ||
      null,
    condicaoDGFinal:
      data['Diagnóstico Final'][
      item['DGFinal']['DG Final - Diagnóstico Final']
      ],
    ecC_Final: data.ECC[item['DGFinal']['DG - ECC']] || null,
    pesoBezerro: item['DGFinal']['DG Final - Peso Bezerro'] || null,
    diasGestacaoTouro: item['DGFinal']['DG Final - Dias de Gestação'] || null,
    tipoPrenheFinal: data.Tipo[item['DGFinal']['DG Final - Tipo']] || null,
    observacao_DG_Final: item['DGFinal']['DG Final - Observação'] || '[N/A]',
    dataDgFinal: moment.utc(
      Math.round(
        (item['DGFinal']['DG Final - Data do DG Final'] - 25569) * 86400 * 1000
      )
    ),
  }
}

export const sheetErrorsMessageMapper = (code) => {
  if (code === ErrorCode.FileTooLarge) {
    return {
      title: 'Arquivo muito grande',
      message: 'O arquivo deve ter no máximo 1MB de tamanho',
    }
  } else if (code === ErrorCode.TooManyFiles) {
    return {
      title: 'Muitos arquivos',
      message: 'Devem ser enviados apenas 1 arquivo por vez',
    }
  } else if (code === ErrorCode.FileInvalidType) {
    return {
      title: 'Arquivo inválido',
      message: 'Extensão de arquivo inválida. Extensão aceita: .xlsx ou .xls',
    }
  } else {
    return {
      title: 'Algo deu errado',
      message: 'Ocorreu um erro ao enviar o arquivo. Tente novamente.',
    }
  }
}

export const hasXlsOrXlsxExtension = (file) => {
  if (!file.name?.includes('.xls') || !file.name?.includes('.xlsx')) {
    return {
      code: 'file-invalid-type',
    }
  }

  return null
}
